<template>
  <v-container>
    <h1>История посещений</h1>

    <v-container>
      <v-row class="mr-10">
        <v-col>
          <v-autocomplete
              v-model="groupFilter"
              :items="groups"
              chips
              small-chips
              clearable
              item-text="name"
              item-value="id"
              return-object
              label="Группа"
              color="#36a2eb"
              prepend-icon="groups"
          />
        </v-col>
        <v-col>
          <v-autocomplete
              v-model="disciplineFilter"
              :items="disciplines"
              chips
              small-chips
              clearable
              item-text="name"
              item-value="id"
              return-object
              label="Дисциплина"
              color="#36a2eb"
              prepend-icon="school"
          />
        </v-col>
        <v-col>
          <v-autocomplete
              v-model="teacherFilter"
              :items="teachers"
              chips
              small-chips
              clearable
              item-text="teacher"
              item-value="id"
              return-object
              label="Преподаватель"
              color="#36a2eb"
              prepend-icon="group"
          />
        </v-col>
        <v-col>
          <v-autocomplete
              v-model="studentFilter"
              :items="students"
              chips
              small-chips
              clearable
              item-text="student"
              item-value="id"
              return-object
              label="Студент"
              color="#36a2eb"
              prepend-icon="person"
          />
        </v-col>
        <v-col>
          <v-switch
              v-model="hideSystems"
              label="Не показывать системные"
          />
        </v-col>
      </v-row>
    </v-container>

    <v-data-table
        :loading="loading"
        :headers="headers"
        :items="visits"
        :pagination.sync="pagination"
        :items-per-page="pageSize"
        class="elevation-1"
        hide-default-footer
    >
      <template v-slot:body="{ items }">
      <tbody>
      <tr :class="backgroundColor(item) ? 'style-1' : ''" v-for="(item) in items" :key="item.id">
        <td class="text-center v-data-table__divider">{{ item.id }}</td>
        <td class="text-center v-data-table__divider">{{ formatDate(item.studentVisits.lesson.date) }}</td>
        <td class="text-center v-data-table__divider">{{ item.studentVisits.scheduledLesson.lessonNumbers.timeStart }}</td>
        <td class="text-center v-data-table__divider">{{ item.studentVisits.workload.workPlan.discipline.name }}</td>
        <td class="text-center v-data-table__divider">{{ item.studentVisits.workload.workPlan.group.name }}</td>
        <td class="text-center v-data-table__divider">{{ item.studentVisits.students.student }}</td>
        <td class="text-center v-data-table__divider">{{ formatDateTime(item.date) }}</td>
        <td class="text-center v-data-table__divider">{{ getValComment(item.preVal, item.newVal) }}</td>
        <td class="text-center v-data-table__divider">{{ item.user.username }}</td>
        <td class="text-center v-data-table__divider">
          <span v-if="item.wasFoundInOrion === null">Неизвестно</span>
          <span v-if="item.wasFoundInOrion !== null && item.wasFoundInOrion">Да</span>
          <span v-if="item.wasFoundInOrion !== null && !item.wasFoundInOrion">Нет</span>
        </td>
        <td class="text-center v-data-table__divider">
          <v-btn icon @click="showHistory(item.studentVisits)"><v-icon color="primary">visibility</v-icon></v-btn>
        </td>
      </tr>
      </tbody>
      </template>
    </v-data-table>

    <!-- Пагинация -->
    <v-card-text>
      <v-row>
        <v-col
            cols="1"
            sm="1"
            md="1">
        </v-col>
        <v-col
            cols="2"
            sm="2"
            md="2">
          <v-select
              v-model="pageSize"
              :items="pageSizes"
              label="Количество строк "
              color="#36a2eb"
          >
          </v-select>
        </v-col>
        <v-col>
          <v-pagination
              class="my-4"
              v-model="pagination.page"
              :length="pages"
              total-visible="9"
              color="primary"
          />
        </v-col>
      </v-row>
    </v-card-text>
    <!-- Пагинация-->


    <history
        ref="historyVisit"
    />

  </v-container>
</template>

<script>
import {HTTP} from "@/settings/axios";
import Settings from "@/settings/settings_url";
import date_utils from "@/settings/date_utils";
import History from "@/components/journal/VisitHistory";

export default {
  name: "VisitLog",
  components: {
    History
  },

  data() {
    return {
      headers: [
        {text: 'ID', align: 'center', sortable: true, value: 'id', divider: true, class: "bolt"},
        {text: 'Дата пары', align: 'center', sortable: true, value: 'studentVisits.lesson.date', divider: true, class: "bolt"},
        {text: 'Начало пары', align: 'center', value: 'studentVisits.scheduledLesson.lessonNumbers.timeStart', divider: true, sortable: true, class: "bolt"},
        {text: 'Дисциплина', align: 'center', value: 'studentVisits.workload.workPlan.discipline.name', divider: true, sortable: true, class: "bolt"},
        {text: 'Группа', align: 'center', value: 'studentVisits.workload.workPlan.group.name', divider: true, sortable: true, class: "bolt"},
        {text: 'Студент', align: 'center', value: 'studentVisits.students.student', divider: true, sortable: true, class: "bolt"},
        {text: 'Дата изменения', align: 'center', value: 'date', divider: true, sortable: true, class: "bolt"},
        {text: 'Изменение', align: 'center', value: 'changed', divider: true, sortable: true, class: "bolt"},
        {text: 'Изменен', align: 'center', value: 'user.username', divider: true, sortable: true, class: "bolt"},
        {text: 'Фиксация в Orion?', align: 'center', value: 'wasFoundInOrion', divider: true, sortable: true, class: "bolt"},
        {text: 'Полная история', align: 'center', value: 'data-table-expand', divider: true, sortable: true, class: "bolt"},
      ],
      pageSize: 10,
      totalItems: 0,
      pageSizes: [5, 10, 15, 20, 30, 40, 50, 60, 70, 80, 90, 100],
      pagination: {
        page: 1,
      },

      pages: 1,

      groupFilter: null,
      disciplineFilter: null,
      teacherFilter: null,
      studentFilter: null,
      hideSystems: false,
      groups: [],
      students: [],
      teachers: [],
      disciplines: [],
      visits: [],
      loading: false,
      dialog: false,
    }
  },
  methods: {
    getValComment: function (preVal,  newVal){
      let pval = preVal == 1 ?  "присутствие(1)" : "отсутствие(0)"
      let nVal = newVal == 1 ? "присутствие(1)" : "отсутствие(0)"
      return "С " +  pval + " на " + nVal + ""
    },
    showHistory(item){
      this.$refs.historyVisit.commentDialog = true;
      this.$refs.historyVisit.getComments(item);
    },
    backgroundColor: function (item) {
      if(item.wasFoundInOrion)
        return 'style-1'
      return ''
      // return item.wasFoundInOrion ? 'style-1' : 'style-2'
    },
    getVisits() {
      this.loading = true
      let urlParams = ""
      if(this.disciplineFilter !== null)
        urlParams += "&disciplineId=" + this.disciplineFilter.id

      if(this.studentFilter !== null)
        urlParams += "&studentId=" + this.studentFilter.id

      if(this.groupFilter !== null)
        urlParams += "&groupId=" + this.groupFilter.id

      if(this.teacherFilter !== null) {
        urlParams += "&teacherId=" + this.teacherFilter.id
      }

      HTTP.get(Settings.API.URL_DEFAULT + "/journal-visit-comments/page?page=" + this.pagination.page
      + "&size=" + this.pageSize + "&hideSystem=" + this.hideSystems + urlParams)
          .then((response) => {
            this.visits = response.data.content
            this.totalItems = response.data.totalElements
            this.pages = response.data.totalPages
            if(this.pagination.page > this.pages)
              this.pagination.page = 1
          })
          .finally(() => {
            this.loading = false
          })
    },
    getAllDisciplines() {
      HTTP.get(Settings.API.URL_DEFAULT + "/disciplines")
          .then((response) => {
            this.disciplines = response.data
          });
    },
    getAllGroups() {
      HTTP.get(Settings.API.URL_DEFAULT + "/groups")
          .then((response) => {
            this.groups = response.data
          });
    },
    getAllTeachers() {
      HTTP.get(Settings.API.URL_DEFAULT + "/teachers")
          .then((response) => {
            this.teachers = response.data
          });
    },
    getAllStudents() {
      HTTP.get(Settings.API.URL_DEFAULT + "/students")
          .then((response) => {
            this.students = response.data
          });
    },
    formatDateTime(value) {
      return date_utils.formatDateTime(value)
    },
    formatDate(value) {
      return date_utils.formatDate(value)
    },
  },
  created() {
    this.getAllDisciplines()
    this.getAllGroups()
    this.getAllTeachers()
    this.getAllStudents()
    this.getVisits()
  },
  watch:{
    'pagination.page'() {
      this.getVisits()
    },
    pageSize() {
      this.getVisits()
    },
    teacherFilter() {
      this.getVisits()
    },
    groupFilter() {
      this.getVisits()
    },
    studentFilter() {
      this.getVisits()
    },
    disciplineFilter() {
      this.getVisits()
    },
    hideSystems() {
      this.getVisits()
    }
  },
}
</script>

<style scoped>
.style-1 {
  background-color: rgb(255,0,0)
}
</style>