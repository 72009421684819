<template>
  <div>
    <div v-if="loading" class="progress-barr">
      <div class="progress">
        <v-progress-circular
            :size="100"
            :width="10"
            color="blue"
            indeterminate
        ></v-progress-circular>
      </div>
    </div>
    <v-snackbar
        v-model="snackbar"
        color="error"
        multi-line
        timeout="5000"
    >
      {{ snackbarText }}
      <template v-slot:action="{ attrs }">
        <v-btn
            text
            v-bind="attrs"
            @click="snackbar = false"
        >
          Закрыть
        </v-btn>
      </template>
    </v-snackbar>
    <v-app id="schedule">
      <v-container justify-center>
        <v-flex xs12>
          <v-card>
            <v-card-text>
              <v-layout row wrap>
                <v-flex xs12 md12>
                  <v-card-actions class="justify-center">
                    <h1>Журнал успеваемости - {{ visits.title }}</h1>


                  </v-card-actions>
                  <div v-if="lastUpdate.dateTime" style="color: #9b1f1f" class="d-flex justify-center d-print-none">
                    Информация о посещении последний раз системой БРСО была проверена в {{ lastUpdate.dateTime | time }}
                  </div>
                  <div class="d-print-none">
                    <v-row>
                      <v-col class="d-flex justify-start">
                        <v-btn
                            v-if="isEditCard()"
                            text
                            color="primary"
                            @click="goToLearningActivityEdit()"
                        >
                          <v-icon left>menu_book</v-icon>
                          Карта успеваемости
                        </v-btn>
                      </v-col>
                      <v-col class="d-flex justify-end">
<!--                        <v-btn onclick="window.print()" color="primary">-->
                        <v-tooltip bottom color="#1F6B41">
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                                class="ma-2"
                                v-show="(isAdmin() || isTeacher()) && disciplineId && groupId && halfYearId && yearId"
                                @click="(isAdmin() || isTeacher()) && disciplineId && groupId && halfYearId && yearId ? printCsv() : null"
                                color="#1F6B41"
                                dark
                                v-bind="attrs"
                                v-on="on"
                            >
                              <v-icon left>download</v-icon>
                              Скачать Excel
                            </v-btn>
                          </template>
                          <span>Скачать журнал как Excel файл</span>
                        </v-tooltip>

                        <v-tooltip bottom color="white">
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                                class="ma-2"
                                color="primary"
                                v-bind="attrs"
                                v-on="on"
                                icon
                            >
                              <v-icon>help</v-icon>
                            </v-btn>
                          </template>
                          <v-card class="white elevation-4">
                            <v-card-text>
                            <span class="black--text">
                              <v-icon class="outlined" color="#E0F8FF">circle</v-icon> - Практическое занятие<br>
                              <v-icon class="outlined" color="#E8FFE8">circle</v-icon> - Лабораторное занятие<br>
                              <v-icon class="outlined" color="white">circle</v-icon> - Лекционное занятие и др.
                            </span>
                            </v-card-text>
                          </v-card>
                        </v-tooltip>
                      </v-col>
                    </v-row>
                  </div>
                </v-flex>
                <v-flex xs12 md6>

                </v-flex>
                <v-flex xs12 md6>

                </v-flex>
              </v-layout>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>

            </v-card-actions>
          </v-card>
        </v-flex>
      </v-container>
      <v-container justify-center>
        <v-flex xs12>

          <v-data-table v-if="visits.headers"
                        mobile-breakpoint="0"
              fixed-header
              :height="visits.students.length > 1 ? '85vh' : ''"
              :headers="visits.headers"
              :items="visits.students"
              :items-per-page="80"
              :hide-default-footer="true"
              :hide-default-header="true"
              class="elevation-2"
          >


            <template v-slot:header="{ props }">
              <thead class="v-data-table-header">
              <tr>
                <th class="text-start v-data-table__divider" v-for="head in props.headers" :key="head.value">
                  {{ head.text }}
                  <v-btn
                      class="d-print-none"
                      v-if="head.lessonId != null && isUploadFile(head.text)"
                      icon
                      @click="showDialogUploadFile(head.date, head.lessonId)"
                  >
                    <v-icon small>file_upload</v-icon>
                  </v-btn>
                </th>
              </tr>
              </thead>
            </template>

            <template v-slot:item="item">
              <tr>
                <td :class="getTdClass(item, header)"
                    v-for="header in visits.headers" :key="header.value"
                >
                  <div v-if="item.item[header.value]">
                    <div v-if="shouldHover(item.item[header.value]) && (item.item[header.value].visit == undefined || item.item[header.value].visit == 0) && item.item[header.value].evaluation <= 0">
                      <v-btn class="d-print-none font-normal" block text @click="showDialog(item.item[header.value])">
                        {{ content(item.item[header.value]) }}
                      </v-btn>
                      <span class="d-none d-print-block text-center">
                        {{ content(item.item[header.value]) }}
                      </span>
                    </div>
                    <div v-else-if="!shouldHover(item.item[header.value]) && (item.item[header.value].visit == undefined || item.item[header.value].visit == 0)">
                      {{ content(item.item[header.value]) }}
                    </div>
                    <div v-else>
                      <div v-if="shouldHover(item.item[header.value]) && item.item[header.value].evaluation > 0">
                        <v-btn class="d-print-none font-normal"  block text @click="showDialog(item.item[header.value])">
                          {{ item.item[header.value].evaluation }}
                        </v-btn>
                        <span class="d-none d-print-block text-center">
                          {{ item.item[header.value].evaluation }}
                      </span>
                      </div>
                      <div v-else-if="!shouldHover(item.item[header.value]) && item.item[header.value].evaluation > 0">
                        {{ item.item[header.value].evaluation }}
                      </div>
                      <div v-else>
                        <v-btn class="d-print-none font-normal" block text @click="showDialog(item.item[header.value])"/>
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
            </template>

            <!--            <template v-slot:item="item">-->
            <!--              <tr>-->
            <!--                <td class="td-border-right v-data-table__divider" v-for="header in headers"-->
            <!--                    :key="item.item[header.value].id">-->
            <!--                  {{ item.item[header.value].text }}-->
            <!--                </td>-->
            <!--              </tr>-->
            <!--            </template>-->
          </v-data-table>
        </v-flex>
      </v-container>

      <template v-if=!loading>
        <v-row justify="center">
          <v-dialog
              v-model="dialog"
              width="600px"
              persistent
          >
            <v-toolbar dark color="primary">
              <v-btn
                  icon
                  dark
                  @click="closeDialog()"
              >
                <v-icon>close</v-icon>
              </v-btn>
              <v-card-title>Посещение</v-card-title>
            </v-toolbar>
            <v-card>
              <v-card-title>
                <span class="headline"></span>
              </v-card-title>
                            <v-card-text v-if="visitItem && visitItem.students && visitItem.workload && visitItem.scheduledLesson">
                              <p>Студент - <b> {{ visitItem.students.student }} </b> </p>
                              <p>Дисциплина - <b>{{ visitItem.workload.workPlan.discipline.name }}</b> </p>
                              <p>Тип - <strong :class="getTypeColorByLessonType(visitItem.workload.workPlan.lessonTypes)">
                                {{ visitItem.workload.workPlan.lessonTypes.name }}
                              </strong>
                              </p>
                              <p>Дата - <b>{{ formatFullDate(visitItem.date) }}</b> </p>
                              <p>Время - <b>{{ visitItem.scheduledLesson.lessonNumbers.timeStart }} - {{ visitItem.scheduledLesson.lessonNumbers.timeEnd }}</b></p>
                              <p>Статус - <b> {{ isVisitDialog(visitItem) }} </b> </p>
                                <v-text-field v-if="visits.group.typesEducation === 'SPO' ||  visitItem.isGrade"
                                    v-model="visitItem.evaluation"
                                    :rules="evalRules"
                                    label="Оценка"
                                    required
                                ></v-text-field>
                              <v-alert dismissible :type="alertType" v-model="showAlert">{{ alertMessage }}</v-alert>
                            </v-card-text>
              <v-card-actions>
                <div>
                  <v-btn
                      color="primary"
                      text
                      @click="refreshVisit"
                      :disabled="refreshing || visitItem.visit == 1 || visitItem.visit == 2"
                      :loading="refreshing"
                      v-if="$store.getters.selectedStudent == null || visitItem.students.id === $store.getters.selectedStudent.id"
                  >
                    обновить
                  </v-btn>
                  <v-btn
                      color="blue darken-1"
                      text
                      @click="showHistory(visitItem)"
                  >
                    история
                  </v-btn>

                </div>
                <div>

                  <v-btn v-if="isEditItem(visitItem)"
                         color="red darken-1"
                         text
                         @click="updateVisit(0)"
                  >
                    отсутствует
                  </v-btn>
                  <v-btn v-if="isEditItem(visitItem) "
                         color="green darken-1"
                         text
                         @click="updateVisit(1)"
                  >
                    <div v-if="visitItem.visit == 1 || visitItem.visit == 2">
                      Оценить
                    </div>
                    <div v-else>
                      присутствует
                    </div>
                  </v-btn>
                </div>

              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-row>
      </template>


      <template>
        <v-row justify="center">
          <history
              ref="historyVisit"
              :show-teachers="false"
          />
        </v-row>
      </template>


      <template>
        <v-row justify="center">
          <v-dialog
              v-model="dialogUploadFile"
              width="600px"
              persistent
          >
            <v-card>
              <v-card-title>
                <span class="headline">Загрузить файл</span>
              </v-card-title>

              <v-card-text>
                <template>
                  <v-file-input
                      v-model="files"
                      placeholder="Upload your documents"
                      label="File input"
                      multiple
                      prepend-icon="mdi-paperclip"
                  >
                    <template v-slot:selection="{ text }">
                      <v-chip
                          small
                          label
                          color="primary"
                      >
                        {{ text }}
                      </v-chip>
                    </template>
                  </v-file-input>
                  <!--                                <v-btn-->
                  <!--                                    color="primary"-->
                  <!--                                    dark-->
                  <!--                                    text-->
                  <!--                                    @click="uploadFile"-->
                  <!--                                >-->
                  <!--                                  Загрузить-->
                  <!--                                </v-btn>-->
                </template>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    color="green darken-1"
                    text
                    @click="dialogUploadFile = !dialogUploadFile"
                >
                  cancel
                </v-btn>
                <v-btn
                    color="green darken-1"
                    text
                    @click="uploadFile"
                >
                  ОК
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-row>
      </template>

    </v-app>

  </div>
</template>

<script>

import Settings from "@/settings/settings_url";
import {HTTP} from '@/settings/axios'
import History from "@/components/journal/VisitHistory";
import date_utils from "@/settings/date_utils";

export default {
  name: "Journal",
  props:{
    groupId: Number,
    disciplineId: Number,
    yearId: Number,
    halfYearId: Number
  },
  data() {
    return {
      //editItem: {},
      helpDialog: false,
      refreshing: false,
      loading: false,
      all: true,
      files: [],
      visits:{

      },
      evalRules: [
        value => value >= 0.5 || 'Минимальная оценка 0.5',
        value => value <= 18 || 'Максимальная оценка 18',
      ],
      //title: "",
      //headers: [],
      //students: [],
      //discipline: "",
      //group: "",
      dialog: false,
      commentDialog:  false,
      dialogUploadFile: false,
      lastUpdate: {
        dateTime: null,
      },
      visitItem: {
        date: "",
        evaluation: 0,
        visit: 0,
        students:{
          student: ""
        },
        workload:{
          workPlan:{
            discipline:{
              name: ""
            },
            lessonTypes:{
              name: ""
            }
          },
        }
      },
      dateUploadedFile: "",
      uploadLessonId: 0,
      alertMessage: null,
      showAlert: false,
      alertType: null,

      snackbar: false,
      snackbarText: null,
    }
  },
  filters:{
    time(item){
      return new Date(item).toString().substring(16, 24)
    }
  },
  watch: {
    all: function () {
      this.getJournal()
    },
    yearId: function (){
      this.updateTable()
    },
    halfYearId: function (){
      this.updateTable()
    },
    groupId: function (){
      this.updateTable()
    },
    disciplineId: function (){
      this.updateTable()
    },
  },
  methods: {
    isAdmin(){
      return this.$store.getters.isAdmin
    },
    isTeacher() {
      return this.$store.getters.isTeacher
    },
    isRole(role) {
      if (this.$store.getters.user?.role != null && this.$store.getters.user?.role.length > 0){
        for (let i = 0; i < this.$store.getters.user?.role.length; i++){
          if (
              this.$store.getters.user?.role[i].name === role
          ){
            return true
          }
        }
      } else {
        return  false
      }
    },
    getTypeColorByLessonType(lessonType) {
      if(lessonType.id === 27)
        return 'lab-background'
      else if(lessonType.id === 35)
        return 'practice-background'
      return ''
    },
    getTdClass(item, header) {
      if(!item.item[header.value] || !item.item[header.value].workload || !item.item[header.value].workload.workPlan || !item.item[header.value].workload.workPlan.lessonTypes )
        return 'td-border-right v-data-table__divider'

      if(item.item[header.value].workload.workPlan.lessonTypes.id === 27)
        return 'lab-background td-border-right v-data-table__divider'
      else if(item.item[header.value].workload.workPlan.lessonTypes.id === 35)
        return 'practice-background td-border-right v-data-table__divider'
      else
        return 'td-border-right v-data-table__divider'
    },
    formatFullDate(date) {
      return date_utils.formatFullDate(date)
    },
    shouldHover(item) {
      if(item === undefined || item === null || item.students === undefined)
        return false
      return true
    },
    closeDialog() {
      this.hideAlert()
      this.dialog = false
    },
    hideAlert() {
      this.showAlert = false
      this.alertMessage = ""
      this.alertType = null
    },
    showHistory(item){
      this.$refs.historyVisit.commentDialog = true;
      this.$refs.historyVisit.getComments(item);
      this.$refs.historyVisit.visitItem = item;
    },
    updateTable: function () {
      if (this.groupId != undefined && this.groupId != 0
          && this.disciplineId != undefined && this.disciplineId != 0){
        this.getJournal()
      }
    },
    responseVisitUpdate: function (stud){
      // console.log(stud)
      for (let i = 0; i < this.visits.students.length; i++ ){
        for (const [key, value] of Object.entries(this.visits.students[i])) {
          if (value instanceof Object){
            if (value.id == stud.id){
              //console.log(this.visits.students[i])
              this.visits.students[i][key] = stud
              break
            }
          }
        }
      }
      // this.getStat()
    },
    // getPointsBac(data){
    //
    // },
    // calcSum: async function (data) {
    //   console.log(data)
    //
    //   let spo = false
    //   let sum = 0
    //   const copy = []
    //
    //   if(this.visits.group.typesEducation === 'SPO')
    //     spo = true
    //
    //   for (let i = 0; i < this.visits.students.length; i++ ){
    //     // eslint-disable-next-line no-unused-vars
    //     sum = getPointsBac(data, this.visits.students)
    //     // for (const [key, value] of Object.entries(this.visits.students[i])) {
    //     //   if (value instanceof Object) {
    //     //     if(spo) {
    //     //       sum = 0
    //     //     } else
    //     //       console.log(value)
    //     //       // sum = await this.getPoints(data, value.id)
    //     //   }
    //     }
    //     if(spo && sum != null) {
    //       this.visits.students[i].avgPoints = sum.averagePoints.toFixed(1) + "Б"
    //       this.visits.students[i].sum = sum.visitPercent
    //     } else if (!spo && sum && sum.visitPoint != undefined){
    //       this.visits.students[i].sum = sum.visitPoint.toFixed(1) + "Б"
    //       this.visits.students[i].sum_a = sum.gradePoints.toFixed(1) + "Б"
    //     }
    //     copy.push(this.visits.students[i])
    //     sum = 0
    //   }
    //   this.visits.students = copy
    //
    // },
    isVisitDialog:  function (item) {
      if (item instanceof Object) {
        if (item.visit === 1){
          return "Присутствовал(a)"
        }
        if (item.visit === 2){
          return "Отсутствовал(a) по уважительным причинам"
        }
        return "Отсутствовал(a)"
      }
      return item
    },
    content: function (item) {
      if (item instanceof Object) {
        if (item.visit === 0){
          return "Н"
        }

        if (item.visit === 2){
          return "У"
        }

        if (this.visits.group.typesEducation === 'SPO' && item.visit === 1 && item.evaluation > 0){
          return item.evaluation
        }

        return ""
      }
      return item
    },
    showDialogUploadFile: function (dateUploadedFile, uploadLessonId) {
      if (this.visits.role === "ROLE_TEACHER" || this.visits.role === "ROLE_ADMIN") {
        // console.log(this.visits.role)
          this.dialogUploadFile = true
          this.dateUploadedFile = dateUploadedFile
          this.uploadLessonId = uploadLessonId
      }
    },
    uploadFile: function () {
      let formData = new FormData();
      formData.append('file', this.files[0]);
      formData.append('date', this.dateUploadedFile);
      formData.append('discipline', this.disciplineId);
      formData.append('group', this.groupId);
      formData.append('lessonId', this.uploadLessonId);

      HTTP.post(Settings.API.URL_DEFAULT + Settings.API.URL_REGISTRATION_OF_STUDENT_VISIT + "upload-file", formData, {headers: {'Content-Type': 'multipart/form-data'}})
          .then(() => {
            this.dialogUploadFile = false
            this.getJournal()
          }).catch(() => {
      });
    },
    showDialog: function (item) {
      this.dialog = true
      this.visitItem = item
      console.log(item)
    },
    isUploadFile: function (date){
      if (this.visits.role === "ROLE_TEACHER" || this.visits.role === "ROLE_ADMIN" || this.visits.role === "ROLE_SDK_SPO"
      ) {
        if  (this.visits.role === "ROLE_ADMIN"){
          return true
        }

        if (this.visits.editSv){
          let d1 = new Date(date);
          let d2 = new Date();
          if (this.dateDiffInDays(d1, d2) <= this.visits.editDaySv){  //todo получать с сервака
            return true;
          }
        }

      }
      return false
    },
    isEditCard(){
      if (this.visits.role === "ROLE_TEACHER" || this.visits.role === "ROLE_ADMIN" || this.visits.role === "ROLE_SDK_SPO") {
        return true;
      }
      return false;
    },
    isEditItem: function (item){
      if (item.id !== 0 && item.id !== undefined  && item.id !== null &&
          (this.visits.role === "ROLE_TEACHER" || this.visits.role === "ROLE_ADMIN" || this.visits.role === "ROLE_SDK_SPO")
      ) {
        if  (this.visits.role === "ROLE_ADMIN"){
          return true
        }

        // if (this.visits.editSv){
        //   let d1 = new Date(item.date);
          // let d2 = new Date();
          // if (this.dateDiffInDays(d1, d2) <= this.visits.editDaySv){  //todo получать с сервака
          // if (this.dateDiffInDays(d1, d2) <= 1){  //todo получать с сервака
            return true;
          // }
        // }

      }
      return false
    },
    dateDiffInDays: function(d1, d2) {
      var t2 = d2.getTime();
      var t1 = d1.getTime();
      console.log(d1);
      console.log(d2);
      console.log(parseInt((t2-t1)/(24*3600*1000)));
      return parseInt((t2-t1)/(24*3600*1000));
    },
    // setVisit: function (data) {
    //   if (data.visit === 1) {
    //     this.visitItem.text = "";
    //   } else {
    //     this.visitItem.text = "Н";
    //   }
    // },
    // updateVisit: function () {
    //   this.loading = true
    //   HTTP.put(Settings.API.URL_REGISTRATION_OF_STUDENT_VISIT_UPDATE_VISITS(this.visitItem.id)).then((response) => {
    //     // console.log(response.data)
    //     //this.setVisit(response.data)
    //     this.responseVisitUpdate(response.data)
    //    //this.visitItem =
    //   }).catch(() => {
    //     this.errored = true;
    //     this.getJournal()
    //   }).finally(() => {
    //     this.loading = false
    //     this.dialog = false
    //   });
    // },
    updateVisit: function (visit) {
      // console.log(visit)
      this.loading = true
      let evaluation = this.visitItem.evaluation? this.visitItem.evaluation : 0;
      HTTP.get(Settings.API.URL_DEFAULT + "/registration-of-student/" + this.visitItem.id + "/update-visit?visit=" + visit + "&eval=" + evaluation).then((response) => {
        // console.log(response.data)
        this.responseVisitUpdate(response.data)
      }).catch(() => {
        alert("Недопустимое значение!")
        this.errored = true;
        this.getJournal()
      }).finally(() => {
        this.loading = false
        this.dialog = false
      });
    },
    getPointsSpo: async function (item) {
      let pointVisit = 0;
      // console.log(item)
      await HTTP.get(
          Settings.API.URL_DEFAULT + "/registration-of-student/points-for-spo-evaluation?student=" + item.students.id
            + "&group=" + item.workload.workPlan.group.id
            + "&discipline=" + item.workload.workPlan.discipline.id
            + "&yearId=" + this.yearId
            + "&halfYearId=" + this.halfYearId)
          .then((response) => {
            // console.log(pointVisit)
            pointVisit = response.data
            // console.log(pointVisit)
            // console.log(response.data)
          })
          .catch(() => {
            this.errored = true;
          })
          .finally(() => {
            //this.loading = false
          });
      return pointVisit
    },
    getPoints: async function (item) {
      //this.loading = true
      let pointVisit = 0;
      // console.log(item)
      await HTTP.get(
          Settings.API.URL_REGISTRATION_OF_STUDENT_POINTS_FOR_VISITS(item.students.id, item.workload.workPlan.group.id, item.workload.workPlan.discipline.id, this.yearId, this.halfYearId))
          .then((response) => {
            // console.log(pointVisit)
            pointVisit = response.data
            // console.log(pointVisit)
            // console.log(response.data)
          })
          .catch(() => {
            this.errored = true;
          })
          .finally(() => {
            //this.loading = false
          });
      return pointVisit
    },
    getLastUpdate:  function () {
       HTTP.get(
          Settings.API.URL_DEFAULT + "/schedule/last-update" )
          .then((response) => {
            this.lastUpdate = response.data
          })
          .catch(() => {
          })
          .finally(() => {
          });
    },
    // async getStat() {
    //   await HTTP.get(
    //       Settings.API.URL_DEFAULT + "/students/examination-sheet-stat?group=" + this.groupId +
    //       "&discipline=" + this.disciplineId + "&year=" + this.yearId + "&halfYear=" + this.halfYearId + "&stat=true"
    //   )
    //       .then((response) => {
    //         this.calcSum(response.data)
    //       })
    // },
    printCsv() {
      this.loading = true

      let groupId = this.groupId
      let disciplineId = this.disciplineId
      let followed = this.all ? 0 : 1

      if (groupId == 0 || disciplineId == 0){
        groupId = this.$route.query.group
        disciplineId = this.$route.query.discipline
      }

      if (groupId == 0 || disciplineId == 0 || groupId == undefined || disciplineId == undefined ){
        this.loading = false
        return;
      }

      HTTP.get(Settings.API.URL_DEFAULT + Settings.API.URL_REGISTRATION_OF_STUDENT_VISIT + "journal/print?group=" + groupId +
          "&discipline=" + disciplineId +
          "&yearId=" + this.yearId +
          "&halfYearId=" + this.halfYearId +
          "&followed=" + followed
      )
          .then((response) => {
            if(response.data)
              window.open(Settings.API.URL_DEFAULT + Settings.API.URL_REGISTRATION_OF_STUDENT_VISIT + "get-file?path=" + response.data, '_blank')
            // this.visits = response.data
          })
          .catch(() => {
            this.snackbar = true
            this.snackbarText = 'При печати Excel файла произошла ошибка!'
          })
          .finally(async () => {
            this.loading = false
          });
    },
    getJournal: async function () {
      this.loading = true

      let groupId = this.groupId
      let disciplineId = this.disciplineId

      if (groupId == 0 || disciplineId == 0){
        groupId = this.$route.query.group
        disciplineId = this.$route.query.discipline
      }

      if (groupId == 0 || disciplineId == 0 || groupId == undefined || disciplineId == undefined ){
        this.loading = false
        return;
      }
      await HTTP.get(Settings.API.URL_REGISTRATION_OF_STUDENT_VISIT_JOURNAL(groupId, disciplineId, this.yearId, this.halfYearId, this.all ? 0 : 1))
          .then((response) => {
            this.visits = response.data
            // this.title = response.data.title
            // this.headers = response.data.headers
            // this.students = response.data.students
            // this.role = response.data.role
            // console.log(response.data)
          })
          .catch(() => {
            // this.errored = true;

          })
          .finally(async () => {
            this.loading = false
            // await this.getStat()
          });
    },
    refreshVisit() {
      this.refreshing = true
      // console.log(this.visitItem)
      // console.log("refreshing")
      HTTP.get(Settings.API.URL_DEFAULT + "/registration-of-student/refresh?visitId=" + this.visitItem.id)
          .then((response) => {
            // console.log(response.data)
            this.responseVisitUpdate(response.data)

            this.alertType = "success"
            this.alertMessage = 'Успешно обновлено!'
            this.showAlert = true
      }).catch((err) => {
        // console.log(err)
        this.alertType = "error"
        this.alertMessage = err.response.data
        this.showAlert = true;
      }).finally(() => {
        this.refreshing = false
        // this.dialog = false
      });
    },
    goToLearningActivityEdit(){
      this.$router.push("/learning-Activity/edit?discipline=" + this.disciplineId +
        "&group=" + this.groupId + "&year=" + this.yearId + "&halfYear=" + this.halfYearId
      )
    },
  },
  created() {
    this.getJournal()
    this.getLastUpdate()
  },
  components: {History}
}
</script>

<style>
.mouse-hover-finger {
  cursor: pointer !important;
  margin: 15px 0;
}
.practice-background {
  background-color: #E0F8FF;
}
.lab-background {
  background-color: #E8FFE8;
}
.font-normal {
  font-size: .875rem !important;
}
.v-icon.outlined {
  border: 2px solid black;
  border-radius: 50%;
  height: 23px;
  width: 23px;
}
</style>
