<template>
  <div>

    <div v-if="loading" class="progress-barr">
      <div class="progress">
        <v-progress-circular
            :size="100"
            :width="10"
            color="blue"
            indeterminate
        ></v-progress-circular>
      </div>
    </div>
    <v-app id="schedule">
      <v-container justify-center>
        <v-flex xs12>
          <v-card>
            <v-card-text>
              <v-layout row wrap>
                <v-flex xs12 md12>
                  <v-card-actions class="justify-center">
                    <h1>Контрольные точки - {{ controlPoints.title }}</h1>
                    <v-spacer></v-spacer>

                    <!--                    <v-btn v-if="isEdit()"-->
                    <!--                        class="mr-8"-->
                    <!--                        @click="showDialogNewControlPoints()"-->
                    <!--                        icon>-->
                    <!--                      <v-icon color="rgb(54, 162, 235)">add_circle</v-icon>-->
                    <!--                    </v-btn>-->
                  </v-card-actions>

                </v-flex>
                <v-flex xs12 md6>

                </v-flex>
                <v-flex xs12 md6>

                </v-flex>
              </v-layout>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>

            </v-card-actions>
          </v-card>
        </v-flex>
      </v-container>
      <v-container justify-center>
        <v-flex xs12>
          <v-data-table
              :headers="controlPoints.headers"
              :items="controlPoints.students"
              :items-per-page="80"
              :hide-default-footer="true"
              :hide-default-header="true"
              class="elevation-2"
          >


            <template v-slot:header="{ props }">
              <thead class="v-data-table-header">
              <tr>
                <th class="text-start v-data-table__divider" v-for="head in props.headers" :key="head.value">
                  {{ head.text }}
                  <!--                  <v-btn v-if="isEdit() && head.controlPointId != null"-->
                  <!--                         class="mr-8"-->
                  <!--                         @click="updateControlPoint(head.controlPointId)"-->
                  <!--                         icon>-->
                  <!--                    <v-icon color="rgb(54, 162, 235)">rotate_right</v-icon>-->
                  <!--                  </v-btn>-->
                </th>
              </tr>
              </thead>
            </template>

            <template v-slot:item="item">
              <tr>
                <td class="td-border-right v-data-table__divider" v-for="header in controlPoints.headers"
                    :key="header.value"
                    @click="showDialog(item.item[header.value])">
                  {{ content(item.item[header.value]) }}
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-flex>
      </v-container>

      <template>
        <v-row justify="center">
          <v-dialog
              v-model="dialog"
              width="600px"
              persistent
          >
            <v-card>
              <v-card-title>
                <span class="headline">Редактировать успеваемость</span>
              </v-card-title>

              <v-card-text>
<!--                <p>Студент - <b> {{ controlPointItem.students.student }} </b></p>-->
<!--                <p>Дисциплина - {{ controlPointItem.controlPoints.discipline.name }} </p>-->
<!--                <p>Тип - {{ controlPointItem.controlPoints.name }} </p>-->
                <p>Оценка:</p>
                <v-card-text>
                  <v-text-field
                      v-model="controlPointItem.evaluation"
                      hint="Введите заработанные баллы"
                      :rules="rules"
                      :label="'Максимальный балл -' + maxEvaluation"
                  ></v-text-field>
                </v-card-text>
                <!--                              <p>Оценка - {{ visitItem.evaluation != null ? visitItem.evaluation.toFixed(1): "" }} </p>-->
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    color="green darken-1"
                    text
                    @click="dialog = !dialog"
                >
                  cancel
                </v-btn>
                <v-btn
                    color="red darken-1"
                    text
                    @click="updateEvaluation"
                >
                  Ок
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-row>
      </template>

      <template>
        <v-row justify="center">
          <v-dialog
              width="800px"
              v-model="dialogNewControlPoints"
              persistent
          >
            <v-card>
              <v-card-title>
                <span class="headline">Добавить контрольные точки</span>
              </v-card-title>

              <v-card-text>
                <div v-for="item in controlPointsLms" :key="item.id">
                  {{ item.name }}
                  <v-btn v-if="item.id == null"
                         class="mr-8"
                         @click="addControlPoint(item)"
                         icon>
                    <v-icon color="rgb(54, 162, 235)">add_circle</v-icon>
                  </v-btn>
                </div>

              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    color="green darken-1"
                    text
                    @click="dialogNewControlPoints = !dialogNewControlPoints"
                >
                  cancel
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-row>
      </template>
    </v-app>

  </div>
</template>

<script>

import Settings from "@/settings/settings_url";
import {HTTP} from '@/settings/axios'

export default {
  name: "ControlPointsRetake",
  props: {
    groupId: Number,
    disciplineId: Number
  },
  data() {
    return {
      controlPointItem: {
        students: {
          student: ""
        },
        evaluation: "",
        controlPoints: {
          name: "",

          discipline: {
            name: ""
          }

        }
      },
      loading: false,
      controlPoints: {
        headers: [],
        students: []
      },
      dialog: false,
      dialogNewControlPoints: false,
      maxEvaluation: 0,
      rules: [v => v <= this.maxEvaluation || 'Max ' + this.maxEvaluation + ' Баллов'],
      controlPointsLms: [],
    }
  },
  watch: {
    // all: function () {
    //   this.getJournal()
    // },
    groupId: function () {
      this.updateTable()

    },
    disciplineId: function () {
      this.updateTable()
    },
  },
  methods: {
    updateControlPoint(controlPointId) {
      this.loading = true
      HTTP.get(Settings.API.URL_DEFAULT + "/control-points/" + controlPointId + "/update-grade"
      ).then((response) => {
        console.log(response.data)
      }).catch(() => {
      }).finally(() => {
        this.getControlPoints()
        this.loading = false
      });
    },
    showDialogNewControlPoints() {
      if (this.isEdit()) {
        this.dialogNewControlPoints = true
        this.getAllLmsControlPoints()
      }
    },
    updateTable: function () {
      if (this.groupId != undefined && this.groupId != 0
          && this.disciplineId != undefined && this.disciplineId != 0) {
        this.getControlPoints()
      }
    },
    getControlPoints: async function () {
      let groupId = this.groupId
      let disciplineId = this.disciplineId

      if (groupId == 0 || disciplineId == 0) {
        groupId = this.$route.query.group
        disciplineId = this.$route.query.discipline
      }

      if (groupId == 0 || disciplineId == 0 || groupId == undefined || disciplineId == undefined) {
        return
      }
      this.loading = true
      await HTTP.get(Settings.API.URL_DEFAULT + "/control-points/" +
          "by-group-and-discipline-and-year?groupid=" + groupId + "&disciplineid=" + disciplineId +
          "&year=" + 1 + "&halfYear=" + 2)
          .then((response) => {
            this.controlPoints = response.data
            console.log(this.controlPoints)
          })
          .catch(() => {
            this.errored = true;
          })
          .finally(() => {
            this.calcSum()
            this.loading = false
          });
      // await HTTP.get(Settings.API.URL_CONTROL_POINTS_JOURNAL(groupId, disciplineId, '2021-01-01', '2021-09-31', 0))
      //     .then((response) => {
      //       this.controlPoints = response.data
      //       console.log(this.controlPoints)
      //     })
      //     .catch(() => {
      //       this.errored = true;
      //     })
      //     .finally(() => {
      //       this.calcSum()
      //       this.loading = false
      //     });
    },
    calcSum: function () {
      // eslint-disable-next-line no-unused-vars
      let sum = 0
      const copy = []
      //const getPoints = this.getPoints
      for (let i = 0; i < this.controlPoints.students.length; i++) {
        // eslint-disable-next-line no-unused-vars
        for (const [key, value] of Object.entries(this.controlPoints.students[i])) {
          if (value instanceof Object) {
            sum += value.evaluation != null ? value.evaluation : 0
          }
        }
        this.controlPoints.students[i].sum = sum.toFixed(1) + "Б"
        copy.push(this.controlPoints.students[i])
        sum = 0
      }

      this.controlPoints.students = copy
      console.log(this.controlPoints.students)
    },
    content: function (item) {
      if (item instanceof Object) {
        return item.evaluation != null && item.evaluation != 0 ? item.evaluation : ""
      }
      return item
    },
    isEdit() {
      return this.controlPoints.role === "ROLE_TEACHER" || this.controlPoints.role === "ROLE_ADMIN"
    },
    showDialog: function (item) {
      if (item.id !== 0 && item.id !== undefined && item.id !== null && this.controlPoints.role === "ROLE_TEACHER") {
        // let d1 = new Date(item.date);
        // let d2 = new Date();
        // if (this.dateDiffInDays(d1, d2) < 80){  //todo получать с сервака
        this.getMaxEvaluation(item)
        this.dialog = true
        this.controlPointItem = item
        // }
      }
    },
    getMaxEvaluation: function (item) {
      this.maxEvaluation = item.controlPoints.numberOfPoints
    },

    responseVisitUpdate: function (stud) {
      for (let i = 0; i < this.controlPoints.students.length; i++) {
        for (const [key, value] of Object.entries(this.controlPoints.students[i])) {
          if (value instanceof Object) {
            if (value.id == stud.id) {
              console.log(this.controlPoints.students[i])
              this.controlPoints.students[i][key] = stud
              break
            }
          }
        }
      }
      this.calcSum()
    },

    getAllLmsControlPoints() {
      this.loading = true
      HTTP.get(Settings.API.URL_DEFAULT + "/control-points/all-lms?groupId=" + this.groupId + "&disciplineId=" + this.disciplineId
      ).then((response) => {
        console.log(response.data)
        this.controlPointsLms = response.data
      }).catch(() => {
      }).finally(() => {
        this.loading = false
      });
    },
    addControlPoint(item) {
      this.loading = true
      HTTP.post(Settings.API.URL_DEFAULT + "/control-points", item
      ).then((response) => {
        console.log(response.data)
      }).catch(() => {
      }).finally(() => {
        this.loading = false
        this.getAllLmsControlPoints()
        this.getControlPoints()
      });
    },

    updateEvaluation: function () {
      this.loading = true
      HTTP.put(Settings.API.URL_ASSESSMENT_CONTROL_POINTS_BY_ID(this.controlPointItem.id), this.controlPointItem)
          .then((response) => {
            console.log(response.data)
            this.responseVisitUpdate(response.data)
            //this.evaluationItem.evaluation = response.data.evaluation
          }).catch((err) => {
        console.log(err)
        this.errored = true;
        //this.getJournal()
      }).finally(() => {
        this.loading = false
        this.dialog = false
      });
    }

  },

  created() {
    this.getControlPoints()
  },
  components: {}
}
</script>

<style lang="sass">


</style>
