export default {
    actions: {},
    state: {
        comp_testing_disciplines: [],
    },
    mutations: {
        comp_testing_disciplines(state, comp_testing_disciplines){
            state.comp_testing_disciplines = comp_testing_disciplines;
        },
    },
    getters: {
        comp_testing_disciplines(state){
            return state.comp_testing_disciplines
        },
    }
}