<template>
  <v-container>
    <v-container class="text-center">
      <h1>Администрирование пропускных карт и личных кабинетов LMS</h1>
    </v-container>
    <v-tabs
        v-model="tab"
    >
      <v-tab>
        <v-icon left>
          people
        </v-icon>
        Студенты
      </v-tab>
      <v-tab>
        <v-icon left>
          article
        </v-icon>
        Приказы
      </v-tab>
      <v-tab>
        <v-icon left>
          people
        </v-icon>
        Сотрудники
      </v-tab>
    </v-tabs>
    <v-tabs-items
        v-model="tab"
        :touchless="true"
    >
      <v-tab-item>
        <student-to-orion-linking/>
      </v-tab-item>
      <v-tab-item>
        <order-to-orion-linking/>
      </v-tab-item>
      <v-tab-item>
        <teacher-linking/>
      </v-tab-item>
    </v-tabs-items>
  </v-container>
</template>

<script>
import StudentToOrionLinking from "@/components/admin/OrionLinking/StudentLinking";
import OrderToOrionLinking from "@/components/admin/OrionLinking/OrdersWithStudentToOrionLinking";
import TeacherLinking from "@/components/admin/OrionLinking/TeacherLinking";

export default {
  name: "OrionAdmin",

  components: {
    StudentToOrionLinking,
    OrderToOrionLinking,
    TeacherLinking,
  },

  data() {
    return {
      tab: 0,
    }
  },
}
</script>

<style scoped>

</style>