import moment from "moment";

export default {
    formatDateUnix(value) {
        let template = "YYYY-MM-DD"

        return moment(value).format(template)
    },
    formatDateTime(value) {
        let template = "DD MMM YYYY г., HH:mm:ss"
        moment.locale("ru")

        if(value instanceof Array)
            return moment(value[0]).format(template) + " - " + moment(value[1]).format(template)
        return moment(value).format(template)
    },
    formatDate(value) {
        let template = "DD MMM YYYY г."
        moment.locale("ru")

        if(value instanceof Array)
            return moment(value[0]).format(template) + " - " + moment(value[1]).format(template)
        return moment(value).format(template)
    },
    formatDateShort(value) {
        let template = "DD.MM.YYYY"
        moment.locale("ru")

        if(value instanceof Array)
            return moment(value[0]).format(template) + " - " + moment(value[1]).format(template)
        return moment(value).format(template)
    },
    formatDateTimeShort(value) {
        let template = "DD.MM.YYYY, HH:mm:ss"
        moment.locale("ru")

        if(value instanceof Array)
            return moment(value[0]).format(template) + " - " + moment(value[1]).format(template)
        return moment(value).format(template)
    },
    formatFullDate(value) {
        let template = "DD MMMM YYYY г."
        moment.locale("ru")

        if(value instanceof Array)
            return moment(value[0]).format(template) + " - " + moment(value[1]).format(template)
        return moment(value).format("DD MMMM YYYY г.")
    },
}