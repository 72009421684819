<template>
  <v-container>
    <v-row>
      <v-col/>
      <v-col>
        <v-autocomplete
            label="Форма обучения"
            prepend-icon="school"
            item-text="ru"
            item-value="en"
            v-model="typeOfEducation"
            :items="typesOfEducation"
        />
      </v-col>
      <v-col>
        <v-autocomplete
            :disabled="typeOfEducation === null"
            label="Группа"
            prepend-icon="groups"
            item-text="name"
            item-value="id"
            v-model="selectedGroup"
            :items="groups"
            :loading="groupsLoading"
        />
      </v-col>
      <v-col/>
    </v-row>
    <v-divider/>
    <v-divider/>
    <v-data-table
        class="elevation-1"
        no-data-text="Нет информации!"
        :loading="loading"
        :headers="headers"
        :items="reportRaw"
        hide-default-footer
        :items-per-page="-1"
    >
      <template v-slot:item.statistics="{ item }">
        {{ item.amountPassed }}/{{ item.expectedToPass }}
      </template>
    </v-data-table>
<!--      <teacher-satisfaction-report ref="teacherReport" :report-raw="reportRaw"/>-->
  </v-container>
</template>

<script>
import {HTTP} from "@/settings/axios";
import Settings from "@/settings/settings_url";

export default {
  name: "AdminTeacherSatisfactionByGroup",

  data() {
    return {
      typeOfEducation: null,
      typesOfEducation: [
        {ru: "Магистратура", en: "MAGISTRACY"},
        {ru: "Бакалавриат", en: "BACCALAUREATE"},
        {ru: "СПО", en: "SPO"},
      ],

      selectedGroup: null,
      groups: [],
      groupsLoading: false,

      reportRaw: [],
      loading: false,
      headers: [
        {text: 'Студент', align: 'center', value: 'student.student', filterable: false, divider: true, sortable: true, class: "bolt"},
        {text: 'Пройдено анкет', align: 'center', value: 'statistics', filterable: false, divider: true, sortable: true, class: "bolt"},
      ],
    }
  },
  watch: {
    'typeOfEducation'() {
      if(this.typeOfEducation !== null)
        this.getGroups()
    },
    'selectedGroup'() {
      this.getTeacherSatisfactionReport()
    },
  },
  methods: {
    getTeacherSatisfactionReport() {
      this.loading = true
      // this.$refs.teacherReport.overlay = true

      HTTP.get(Settings.API.URL_DEFAULT + "/testing-form/teacher-satisfaction/report/group" +
          "?groupId=" + this.selectedGroup)
          .then((response) => {
            this.reportRaw = response.data
          })
          .catch(() => {
          })
          .finally(() => {
            this.loading = false
            // this.$refs.teacherReport.overlay = false
          });
    },
    getGroups() {
      this.groupsLoading = true
      HTTP.get(Settings.API.URL_DEFAULT + "/groups/by-education-type" +
          "?typeOfEducation=" + this.typeOfEducation)
          .then((response) => {
            this.groups = response.data
          })
          .catch(() => {
          })
          .finally(() => {
            this.groupsLoading = false
          });
    }
  },
  created() {

  }

}
</script>

<style scoped>
</style>