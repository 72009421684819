<template>
  <div>
    <v-expansion-panels
        v-model="panel"

        multiple
    >
      <v-expansion-panel>
        <v-expansion-panel-header class="letter">РАСПИСАНИЕ ДЛЯ СТУДЕНТОВ</v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-container fluid>
<!--            <v-row align="center">-->

<!--              <v-col-->
<!--                  class="d-flex"-->
<!--                  cols="12"-->
<!--                  sm="2"-->
<!--              >-->
<!--                <v-autocomplete-->
<!--                    v-model="eduform"-->
<!--                    :items="eduforms"-->
<!--                    chips-->
<!--                    small-chips-->
<!--                    clearable-->
<!--                    item-text="name"-->
<!--                    item-value="id"-->
<!--                    label="Форма обучения:"-->
<!--                    color="#36a2eb"-->
<!--                    return-object-->
<!--                ></v-autocomplete>-->
<!--              </v-col>-->
<!--              <v-col-->
<!--                  class="d-flex"-->
<!--                  cols="12"-->
<!--                  sm="1"-->
<!--              ></v-col>-->
<!--              <v-col-->
<!--                  class="d-flex"-->
<!--                  cols="12"-->
<!--                  sm="2"-->
<!--              >  <v-autocomplete-->
<!--                  v-model="inst"-->
<!--                  :items="insts"-->
<!--                  chips-->
<!--                  small-chips-->
<!--                  clearable-->
<!--                  item-text="name"-->
<!--                  item-value="id"-->
<!--                  label="Институт:"-->
<!--                  color="#36a2eb"-->
<!--                  return-object-->
<!--              ></v-autocomplete>-->

<!--              </v-col>-->
<!--              <v-col-->
<!--                  class="d-flex"-->
<!--                  cols="12"-->
<!--                  sm="1"-->
<!--              ></v-col>-->
<!--              <v-col-->
<!--                  class="d-flex"-->
<!--                  cols="12"-->
<!--                  sm="2">-->
<!--                <v-autocomplete-->
<!--                    v-model="eduform"-->
<!--                    :items="groups"-->
<!--                    chips-->
<!--                    small-chips-->
<!--                    clearable-->
<!--                    item-text="name"-->
<!--                    item-value="id"-->
<!--                    label="Курс:"-->
<!--                    color="#36a2eb"-->
<!--                    return-object-->
<!--                ></v-autocomplete>-->

<!--              </v-col>-->
<!--              <v-col-->
<!--                  class="d-flex"-->
<!--                  cols="12"-->
<!--                  sm="3">-->


<!--              </v-col>-->
<!--              <v-col-->
<!--                  class="d-flex"-->
<!--                  cols="12"-->
<!--                  sm="3">-->


<!--              </v-col>-->



<!--            </v-row>-->
            <v-row>
                <v-col
                  class="d-flex"
                  cols="12"
                  sm="3">
                  <v-autocomplete
                      v-model="group"
                      :items="groups"
                      chips
                      small-chips
                      clearable
                      item-text="name"
                      item-value="id"
                      label="Группа:"
                      color="#36a2eb"
                      return-object
                  ></v-autocomplete>

              </v-col>
              <v-col
                  class="d-flex"
                  cols="12"
                  sm="3">
                <v-btn
                    color="#36a2eb"
                    dark
                    v-on:click="isHiddenGr = !isHiddenGr"
                >Расписание группы</v-btn>

              </v-col>
            </v-row>
            <v-row>
              <v-col
                  class="d-flex"
                  cols="12"
                  sm="3">

                <v-btn
                    color="#36a2eb"
                    dark
                    v-on:click="isHiddenListGr = !isHiddenListGr"
                >Список групп</v-btn>

              </v-col>

            </v-row>

            <v-container v-if="!isHiddenListGr">
              <v-card>
              <v-flex xs12>
                <v-card>
                  <v-card-text>
                    <v-layout row wrap>
                      <v-flex xs12 md12>
                        <p></p>
                        <v-card-actions class="justify-center">
                          <v-spacer></v-spacer>
                          <h1>Cписок групп</h1>
                          <v-spacer></v-spacer>
                          <h1> (Не)чётная неделя</h1>
                          <v-spacer></v-spacer>
                        </v-card-actions>
                        <p></p>

                      </v-flex>
                      <v-flex xs12 md6>

                      </v-flex>
                      <v-flex xs12 md6>

                      </v-flex>
                    </v-layout>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                  </v-card-actions>
                </v-card>
              </v-flex>
                <v-card-title>
                  <v-text-field
                      v-model="searchgr"
                      append-icon="mdi-magnify"
                      label="Поиск"
                      single-line
                      hide-details
                  ></v-text-field>
                </v-card-title>

              <v-data-table
                  fixed-header

                  :items-per-page="-1"
                  :headers="grlist"
                  :items="groups"
                  class="elevation-1"

                  item-key="name"
                  group-key="faculty"
                  group-by="faculty"
                  show-group-by
                  :search="searchgr"
                  :hide-default-footer="true"
                  :hide-actions="true"


              >

                <template v-slot:item.linkedgr="{ item }">

                  <v-icon
                      color="#36a2eb"
                      dark
                      @click="grSchedule(item)"

                  >info</v-icon>
<!--                  @click="grSchedule(item)"-->
<!--                  v-on:click="isHiddenGr = !isHiddenGr"-->

                </template>

              </v-data-table>
              </v-card>
            </v-container>
            <v-spacer><p></p></v-spacer>

          </v-container>
          <v-spacer></v-spacer>

          <v-container v-if="!isHiddenGr">
            <v-flex xs12>
              <v-card>
                <v-card-text>
                  <v-layout row wrap>
                    <v-flex xs12 md12>
                      <p></p>
                      <v-card-actions class="justify-center">
                        <v-spacer></v-spacer>
                        <h1>Группа: {{group.name}}</h1>
                        <v-spacer></v-spacer>
                        <h1> (Не)чётная неделя</h1>
                        <v-spacer></v-spacer>
                      </v-card-actions>
                      <p></p>

                    </v-flex>
                    <v-flex xs12 md6>

                    </v-flex>
                    <v-flex xs12 md6>

                    </v-flex>
                  </v-layout>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-flex>
            <v-data-table
                :headers="stdays"
                fixed-header
                :hide-default-footer="true"
                :hide-actions="true"
                :items="sttimes"
                class="elevation-1"

            >

            </v-data-table>
          </v-container>


        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel>
        <v-expansion-panel-header class="letter">РАСПИСАНИЕ ДЛЯ ПРЕПОДАВАТЕЛЕЙ</v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-container fluid >
            <v-row >
              <v-col
                  class="d-flex"
                  cols="12"
                  sm="2 "
              >
              <v-autocomplete
                  v-model="teacher"
                  :items="teachers"
                  label="ФИО преподавателя:"
                  color="#36a2eb"
                  chips
                  small-chips
                  clearable
                  item-text="teacher"
                  item-value="id"
                  return-object
              ></v-autocomplete>
              </v-col>
              <v-col>
                <v-btn
                    color="#36a2eb"
                    dark
                    v-on:click="visibleTch=!visibleTch"
                >Расписание преподавателя</v-btn>

              </v-col>
            </v-row>
            <v-row></v-row>
            <v-row >
               <v-col
                  class="d-flex"
                  cols="12"
                  sm="2">
                 <v-btn
                     color="#36a2eb"
                     dark
                     v-on:click="visibleListTch=!visibleListTch"
                 >Список преподавателей</v-btn>


              </v-col>
              <v-col
              class="d-flex"
              cols="12"
              sm="1">
              </v-col>
              <v-col>

              </v-col>
            </v-row>

          </v-container>
          <v-container v-if="visibleListTch">
            <v-card>
            <v-flex xs12>
              <v-card>
                <v-card-text>
                  <v-layout row wrap>
                    <v-flex xs12 md12>
                      <p></p>
                      <v-card-actions class="justify-center">
                        <v-spacer></v-spacer>
                        <h1>Список преподавателей</h1>
                        <v-spacer></v-spacer>
                        <h1> (Не)чётная неделя</h1>
                        <v-spacer></v-spacer>
                      </v-card-actions>
                      <p></p>

                    </v-flex>
                    <v-flex xs12 md6>

                    </v-flex>
                    <v-flex xs12 md6>

                    </v-flex>
                  </v-layout>
                </v-card-text>


                <v-card-actions>
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-flex>
              <v-card-title>
              <v-text-field
                  v-model="searchpr"
                  append-icon="mdi-magnify"
                  label="Поиск"
                  single-line
                  hide-details
              ></v-text-field>
              </v-card-title>

            <v-data-table
                :headers="prlist"
                fixed-header
                :hide-default-footer="true"
                :hide-actions="true"

                :items-per-page="-1"
                :items="teachers"
                class="elevation-1"
                :search="searchpr"



            >




              <template v-slot:item.linkedpr="{ item }">

                <v-icon
                    color="#36a2eb"
                    dark

                    @click="prSchedule(item)"
                >info</v-icon>
                <!--                  @click="grSchedule(item)"-->
                <!--                  v-on:click="isHiddenGr = !isHiddenGr"-->

              </template>
            </v-data-table>
            </v-card>
          </v-container>
          <v-container v-if="visibleTch">
            <v-flex xs12>
              <v-card>
                <v-card-text>
                  <v-layout row wrap>
                    <v-flex xs12 md12>
                      <p></p>
                      <v-card-actions class="justify-center">
                        <v-spacer></v-spacer>
                        <h1>Преподаватель: {{teacher.teacher}}</h1>
                        <v-spacer></v-spacer>
                        <h1> (Не)чётная неделя</h1>
                        <v-spacer></v-spacer>
                      </v-card-actions>
                      <p></p>

                    </v-flex>
                    <v-flex xs12 md6>

                    </v-flex>
                    <v-flex xs12 md6>

                    </v-flex>
                  </v-layout>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-flex>

            <v-data-table
                :headers="prdays"
                fixed-header
                :hide-default-footer="true"
                :hide-actions="true"
                :items="prtimes"
                class="elevation-1"

            >
            </v-data-table>
          </v-container>
        </v-expansion-panel-content>
      </v-expansion-panel>

    </v-expansion-panels>
  </div>
</template>
<script>

import Settings from "@/settings/settings_url";
import {HTTP} from '@/settings/axios';

export default {
  // name: "ScheduleAll",
  name: "ScheduleFor",
  data() {
    return {
      visibleListTch: false,
      visibleTch: false,

      isHiddenListGr:true,
      isHiddenGr:true,
      //panel: [0,1 ],
      panel: [ ],
      tabs: null,
      selected: '',
      eduform:[],

      group:[],

      groups:[],
      teacher: [],
      teachers:[],

      stdays: [
        {text: '', value: 'ltime', align: 'center', sortable: false, divider: true, class: "bolt"},

        {
          text: ['Понедельник: ', '26.07.21'], divider: true, sortable: false, align: 'center',
          // value: 'one',
          // sortable: false,
          // class: "bolt",
        },
        {text: ['Вторник: ', '27.07.21'], divider: true, sortable: false, align: 'center',
          // value: 'two',  sortable: false, class: "bolt",
        },
        {text: ['Среда: ', '28.07.21'],  divider: true, sortable: false, align: 'center',
          // value: 'three',   class: "bolt",
        },
        {
          text: ['Четверг: ', '29.07.21'], divider: true, sortable: false, align: 'center',
          // value: 'four',
          // sortable: false,
          // class: "bolt",

        },
        {
          text: ['Пятница: ', '30.07.21'],  divider: true, sortable: false, align: 'center',
          // value: 'five',
          // sortable: false,
          // class: "bolt",

        },
        {text: ['Суббота: ', '31.07.21'], divider: true, sortable: false, align: 'center',
          // value: 'six', sortable: false, class: "bolt",
        },

      ],
      sttimes: [
        {
          ltime:  '08:30 - 10:00',
          one: {lesson: 'Бизнес информатика', room: "504 E", teacher: "Доцент. Иванов И.И", current_count: 24, full_count: 45, is_teacher: true, is_lesson: true},
          two: {lesson: 'Юриспруденция',  room: "202", teacher: "Доцент. Иванов И.И", current_count: 42, full_count: 45, is_teacher: false, is_lesson: true},
          three: {lesson: 'Финансы и кредит',  room: "304 Д", teacher: "Доцент. Иванов И.И", current_count: 39, full_count: 45, is_teacher: true, is_lesson: true},
          four: {lesson: 'Торговое дело',  room: "425", teacher: "Доцент. Иванов И.И", current_count: 44, full_count: 45, is_teacher: true, is_lesson: true},
          five: {lesson: '',  room: "504 E", teacher: ""},
          six: {lesson: 'Торговое дело',  room: "425", teacher: "Доцент. Иванов И.И", current_count: 44, full_count: 45, is_teacher: true, is_lesson: false},
        },

        // {
        //   ltime:  '9.30 – 11.00',
        //   mo: {lesson: 'Бизнес информатика', room: "504 E", teacher: "Доцент. Иванов И.И", current_count: 24, full_count: 45, is_teacher: true, is_lesson: true},
        //   tue: {lesson: 'Юриспруденция',  room: "202", teacher: "Доцент. Иванов И.И", current_count: 42, full_count: 45, is_teacher: false, is_lesson: true},
        //   we: {lesson: 'Финансы и кредит',  room: "304 Д", teacher: "Доцент. Иванов И.И", current_count: 39, full_count: 45, is_teacher: true, is_lesson: true},
        //   th: {lesson: 'Торговое дело',  room: "425", teacher: "Доцент. Иванов И.И", current_count: 44, full_count: 45, is_teacher: true, is_lesson: true},
        //   fr: {lesson: '',  room: "504 E", teacher: ""},
        //   sa: {lesson: 'Торговое дело',  room: "425", teacher: "Доцент. Иванов И.И", current_count: 44, full_count: 45, is_teacher: true, is_lesson: false},
        // },
        {
          ltime:  '9.30 – 11.00',
          one: {lesson: 'Бизнес информатика', room: "504 E", teacher: "Доцент. Иванов И.И", current_count: 24, full_count: 45, is_teacher: true, is_lesson: true},
          two: {lesson: 'Юриспруденция',  room: "202", teacher: "Доцент. Иванов И.И", current_count: 42, full_count: 45, is_teacher: false, is_lesson: true},
          three: {lesson: 'Финансы и кредит',  room: "304 Д", teacher: "Доцент. Иванов И.И", current_count: 39, full_count: 45, is_teacher: true, is_lesson: true},
          four: {lesson: 'Торговое дело',  room: "425", teacher: "Доцент. Иванов И.И", current_count: 44, full_count: 45, is_teacher: true, is_lesson: true},
          five: {lesson: '',  room: "504 E", teacher: ""},
          six: {lesson: 'Торговое дело',  room: "425", teacher: "Доцент. Иванов И.И", current_count: 44, full_count: 45, is_teacher: true, is_lesson: false},
        },
        {
          ltime: '10:15 - 11.50',
          one: {lesson: 'Бизнес информатика', room: "504 E", teacher: "Доцент. Иванов И.И", current_count: 42, full_count: 45, is_teacher: true, is_lesson: true},
          two: {lesson: 'Юриспруденция',  room: "202", teacher: "Доцент. Иванов И.И", current_count: 42, full_count: 45, is_teacher: true, is_lesson: true},
          three: {lesson: 'Финансы и кредит',  room: "304 Д", teacher: "Доцент. Иванов И.И", current_count: 39, full_count: 45, is_teacher: true, is_lesson: false},
          four: {lesson: 'Торговое дело',  room: "425", teacher: "Доцент. Иванов И.И", current_count: 44, full_count: 45, is_teacher: true, is_lesson: true},
          five: {lesson: '',  room: "504 E", teacher: ""},
          six: {lesson: 'Торговое дело',  room: "425", teacher: "Доцент. Иванов И.И", current_count: 44, full_count: 45, is_teacher: true, is_lesson: false},
        },
        {
          ltime: '11.10 – 12.40',
          one: {lesson: 'Бизнес информатика', room: "504 E", teacher: "Доцент. Иванов И.И", current_count: 24, full_count: 45, is_teacher: true, is_lesson: true},
          two: {lesson: 'Юриспруденция',  room: "202", teacher: "Доцент. Иванов И.И", current_count: 42, full_count: 45, is_teacher: false, is_lesson: true},
          three: {lesson: 'Финансы и кредит',  room: "304 Д", teacher: "Доцент. Иванов И.И", current_count: 39, full_count: 45, is_teacher: true, is_lesson: true},
          four: {lesson: 'Торговое дело',  room: "425", teacher: "Доцент. Иванов И.И", current_count: 44, full_count: 45, is_teacher: true, is_lesson: true},
          five: {lesson: '',  room: "504 E", teacher: ""},
          six: {lesson: 'Торговое дело',  room: "425", teacher: "Доцент. Иванов И.И", current_count: 44, full_count: 45, is_teacher: true, is_lesson: false},
        },
        {
          ltime: '12.20 - 13.55',align: 'center',
          one: {lesson: 'Бизнес информатика', room: "504 E", teacher: "Доцент. Иванов И.И", current_count: 24, full_count: 45, is_teacher: true, is_lesson: true},
          two: {lesson: 'Юриспруденция',  room: "202", teacher: "Доцент. Иванов И.И", current_count: 42, full_count: 45, is_teacher: false, is_lesson: true},
          three: {lesson: 'Финансы и кредит',  room: "304 Д", teacher: "Доцент. Иванов И.И", current_count: 39, full_count: 45, is_teacher: true, is_lesson: true},
          four: {lesson: 'Торговое дело',  room: "425", teacher: "Доцент. Иванов И.И", current_count: 44, full_count: 45, is_teacher: true, is_lesson: true},
          five: {lesson: '',  room: "504 E", teacher: ""},
          six: {lesson: 'Торговое дело',  room: "425", teacher: "Доцент. Иванов И.И", current_count: 44, full_count: 45, is_teacher: true, is_lesson: false},
        },
        {
          ltime: '13.10 – 14.40',align: 'center',
          one: {lesson: 'Бизнес информатика', room: "504 E", teacher: "Доцент. Иванов И.И", current_count: 24, full_count: 45, is_teacher: true, is_lesson: true},
          two: {lesson: 'Юриспруденция',  room: "202", teacher: "Доцент. Иванов И.И", current_count: 42, full_count: 45, is_teacher: false, is_lesson: true},
          three: {lesson: 'Финансы и кредит',  room: "304 Д", teacher: "Доцент. Иванов И.И", current_count: 39, full_count: 45, is_teacher: true, is_lesson: true},
          four: {lesson: 'Торговое дело',  room: "425", teacher: "Доцент. Иванов И.И", current_count: 44, full_count: 45, is_teacher: true, is_lesson: true},
          five: {lesson: '',  room: "504 E", teacher: ""},
          six: {lesson: 'Торговое дело',  room: "425", teacher: "Доцент. Иванов И.И", current_count: 44, full_count: 45, is_teacher: true, is_lesson: false},
        },
        {
          ltime: '14.05 - 15.40',
          one: {lesson: 'Бизнес информатика', room: "504 E", teacher: "Доцент. Иванов И.И", current_count: 24, full_count: 45, is_teacher: true, is_lesson: true},
          two: {lesson: 'Юриспруденция',  room: "202", teacher: "Доцент. Иванов И.И", current_count: 42, full_count: 45, is_teacher: false, is_lesson: true},
          three: {lesson: 'Финансы и кредит',  room: "304 Д", teacher: "Доцент. Иванов И.И", current_count: 39, full_count: 45, is_teacher: true, is_lesson: true},
          four: {lesson: 'Торговое дело',  room: "425", teacher: "Доцент. Иванов И.И", current_count: 44, full_count: 45, is_teacher: true, is_lesson: true},
          five: {lesson: '',  room: "504 E", teacher: ""},
          six: {lesson: 'Торговое дело',  room: "425", teacher: "Доцент. Иванов И.И", current_count: 44, full_count: 45, is_teacher: true, is_lesson: false},
        },
        {
          ltime: '14.50 – 16.20',
          one: {lesson: 'Бизнес информатика', room: "504 E", teacher: "Доцент. Иванов И.И", current_count: 24, full_count: 45, is_teacher: true, is_lesson: true},
          two: {lesson: 'Юриспруденция',  room: "202", teacher: "Доцент. Иванов И.И", current_count: 42, full_count: 45, is_teacher: false, is_lesson: true},
          three: {lesson: 'Финансы и кредит',  room: "304 Д", teacher: "Доцент. Иванов И.И", current_count: 39, full_count: 45, is_teacher: true, is_lesson: true},
          four: {lesson: 'Торговое дело',  room: "425", teacher: "Доцент. Иванов И.И", current_count: 44, full_count: 45, is_teacher: true, is_lesson: true},
          five: {lesson: '',  room: "504 E", teacher: ""},
          six: {lesson: 'Торговое дело',  room: "425", teacher: "Доцент. Иванов И.И", current_count: 44, full_count: 45, is_teacher: true, is_lesson: false},
        },
        {
          ltime: '16.10  - 17.45',
          one: {lesson: 'Бизнес информатика', room: "504 E", teacher: "Доцент. Иванов И.И", current_count: 24, full_count: 45, is_teacher: true, is_lesson: true},
          two: {lesson: 'Юриспруденция',  room: "202", teacher: "Доцент. Иванов И.И", current_count: 42, full_count: 45, is_teacher: false, is_lesson: true},
          three: {lesson: 'Финансы и кредит',  room: "304 Д", teacher: "Доцент. Иванов И.И", current_count: 39, full_count: 45, is_teacher: true, is_lesson: true},
          four: {lesson: 'Торговое дело',  room: "425", teacher: "Доцент. Иванов И.И", current_count: 44, full_count: 45, is_teacher: true, is_lesson: true},
          five: {lesson: '',  room: "504 E", teacher: ""},
          six: {lesson: 'Торговое дело',  room: "425", teacher: "Доцент. Иванов И.И", current_count: 44, full_count: 45, is_teacher: true, is_lesson: false},
        },
        {
          ltime: '16.30 – 18.00',
          one: {lesson: 'Бизнес информатика', room: "504 E", teacher: "Доцент. Иванов И.И", current_count: 24, full_count: 45, is_teacher: true, is_lesson: true},
          two: {lesson: 'Юриспруденция',  room: "202", teacher: "Доцент. Иванов И.И", current_count: 42, full_count: 45, is_teacher: false, is_lesson: true},
          three: {lesson: 'Финансы и кредит',  room: "304 Д", teacher: "Доцент. Иванов И.И", current_count: 39, full_count: 45, is_teacher: true, is_lesson: true},
          four: {lesson: 'Торговое дело',  room: "425", teacher: "Доцент. Иванов И.И", current_count: 44, full_count: 45, is_teacher: true, is_lesson: true},
          five: {lesson: '',  room: "504 E", teacher: ""},
          six: {lesson: 'Торговое дело',  room: "425", teacher: "Доцент. Иванов И.И", current_count: 44, full_count: 45, is_teacher: true, is_lesson: false},
        },
        {
          ltime: '18.00  - 19.35',
          one: {lesson: 'Бизнес информатика', room: "504 E", teacher: "Доцент. Иванов И.И", current_count: 24, full_count: 45, is_teacher: true, is_lesson: true},
          two: {lesson: 'Юриспруденция',  room: "202", teacher: "Доцент. Иванов И.И", current_count: 42, full_count: 45, is_teacher: false, is_lesson: true},
          three: {lesson: 'Финансы и кредит',  room: "304 Д", teacher: "Доцент. Иванов И.И", current_count: 39, full_count: 45, is_teacher: true, is_lesson: true},
          four: {lesson: 'Торговое дело',  room: "425", teacher: "Доцент. Иванов И.И", current_count: 44, full_count: 45, is_teacher: true, is_lesson: true},
          five: {lesson: '',  room: "504 E", teacher: ""},
          six: {lesson: 'Торговое дело',  room: "425", teacher: "Доцент. Иванов И.И", current_count: 44, full_count: 45, is_teacher: true, is_lesson: false},
        },
        //    ////

      ],

      prdays: [
        {text: '', value: 'ltime', align: 'center', sortable: false, divider: true, class: "bolt"},

        {
          text: ['Понедельник: ', '26.07.21'], divider: true, sortable: false, align: 'center',
          // value: 'one',
          // sortable: false,
          // class: "bolt",
          // align: 'center'
        },
        {text: ['Вторник: ', '27.07.21'], divider: true, sortable: false, align: 'center',
          // value: 'two',  sortable: false, class: "bolt",
        },
        {text: ['Среда: ', '28.07.21'],  divider: true, sortable: false, align: 'center',
          // value: 'three',   class: "bolt",
        },
        {
          text: ['Четверг: ', '29.07.21'], divider: true, sortable: false, align: 'center',
          // value: 'four',
          // sortable: false,
          // class: "bolt",
        },
        {
          text: ['Пятница: ', '30.07.21'],  divider: true, sortable: false, align: 'center',
          // value: 'five',
          // sortable: false,
          // class: "bolt",
        },
        {text: ['Суббота: ', '31.07.21'], divider: true, sortable: false, align: 'center',
          // value: 'six', sortable: false, class: "bolt",
        },

      ],
      prtimes: [
        {
          ltime:  '08:30 - 10:00',
          one: {lesson: 'Бизнес информатика', room: "504 E", teacher: "Доцент. Иванов И.И", current_count: 24, full_count: 45, is_teacher: true, is_lesson: true},
          two: {lesson: 'Юриспруденция',  room: "202", teacher: "Доцент. Иванов И.И", current_count: 42, full_count: 45, is_teacher: false, is_lesson: true},
          three: {lesson: 'Финансы и кредит',  room: "304 Д", teacher: "Доцент. Иванов И.И", current_count: 39, full_count: 45, is_teacher: true, is_lesson: true},
          four: {lesson: 'Торговое дело',  room: "425", teacher: "Доцент. Иванов И.И", current_count: 44, full_count: 45, is_teacher: true, is_lesson: true},
          five: {lesson: '',  room: "504 E", teacher: ""},
          six: {lesson: 'Торговое дело',  room: "425", teacher: "Доцент. Иванов И.И", current_count: 44, full_count: 45, is_teacher: true, is_lesson: false},
        },

        // {
        //   ltime:  '9.30 – 11.00',
        //   mo: {lesson: 'Бизнес информатика', room: "504 E", teacher: "Доцент. Иванов И.И", current_count: 24, full_count: 45, is_teacher: true, is_lesson: true},
        //   tue: {lesson: 'Юриспруденция',  room: "202", teacher: "Доцент. Иванов И.И", current_count: 42, full_count: 45, is_teacher: false, is_lesson: true},
        //   we: {lesson: 'Финансы и кредит',  room: "304 Д", teacher: "Доцент. Иванов И.И", current_count: 39, full_count: 45, is_teacher: true, is_lesson: true},
        //   th: {lesson: 'Торговое дело',  room: "425", teacher: "Доцент. Иванов И.И", current_count: 44, full_count: 45, is_teacher: true, is_lesson: true},
        //   fr: {lesson: '',  room: "504 E", teacher: ""},
        //   sa: {lesson: 'Торговое дело',  room: "425", teacher: "Доцент. Иванов И.И", current_count: 44, full_count: 45, is_teacher: true, is_lesson: false},
        // },
        {
          ltime:  '9.30 – 11.00',
          one: {lesson: 'Бизнес информатика', room: "504 E", teacher: "Доцент. Иванов И.И", current_count: 24, full_count: 45, is_teacher: true, is_lesson: true},
          two: {lesson: 'Юриспруденция',  room: "202", teacher: "Доцент. Иванов И.И", current_count: 42, full_count: 45, is_teacher: false, is_lesson: true},
          three: {lesson: 'Финансы и кредит',  room: "304 Д", teacher: "Доцент. Иванов И.И", current_count: 39, full_count: 45, is_teacher: true, is_lesson: true},
          four: {lesson: 'Торговое дело',  room: "425", teacher: "Доцент. Иванов И.И", current_count: 44, full_count: 45, is_teacher: true, is_lesson: true},
          five: {lesson: '',  room: "504 E", teacher: ""},
          six: {lesson: 'Торговое дело',  room: "425", teacher: "Доцент. Иванов И.И", current_count: 44, full_count: 45, is_teacher: true, is_lesson: false},
        },
        {
          ltime: '10:15 - 11.50',
          one: {lesson: 'Бизнес информатика', room: "504 E", teacher: "Доцент. Иванов И.И", current_count: 42, full_count: 45, is_teacher: true, is_lesson: true},
          two: {lesson: 'Юриспруденция',  room: "202", teacher: "Доцент. Иванов И.И", current_count: 42, full_count: 45, is_teacher: true, is_lesson: true},
          three: {lesson: 'Финансы и кредит',  room: "304 Д", teacher: "Доцент. Иванов И.И", current_count: 39, full_count: 45, is_teacher: true, is_lesson: false},
          four: {lesson: 'Торговое дело',  room: "425", teacher: "Доцент. Иванов И.И", current_count: 44, full_count: 45, is_teacher: true, is_lesson: true},
          five: {lesson: '',  room: "504 E", teacher: ""},
          six: {lesson: 'Торговое дело',  room: "425", teacher: "Доцент. Иванов И.И", current_count: 44, full_count: 45, is_teacher: true, is_lesson: false},
        },
        {
          ltime: '11.10 – 12.40',
          one: {lesson: 'Бизнес информатика', room: "504 E", teacher: "Доцент. Иванов И.И", current_count: 24, full_count: 45, is_teacher: true, is_lesson: true},
          two: {lesson: 'Юриспруденция',  room: "202", teacher: "Доцент. Иванов И.И", current_count: 42, full_count: 45, is_teacher: false, is_lesson: true},
          three: {lesson: 'Финансы и кредит',  room: "304 Д", teacher: "Доцент. Иванов И.И", current_count: 39, full_count: 45, is_teacher: true, is_lesson: true},
          four: {lesson: 'Торговое дело',  room: "425", teacher: "Доцент. Иванов И.И", current_count: 44, full_count: 45, is_teacher: true, is_lesson: true},
          five: {lesson: '',  room: "504 E", teacher: ""},
          six: {lesson: 'Торговое дело',  room: "425", teacher: "Доцент. Иванов И.И", current_count: 44, full_count: 45, is_teacher: true, is_lesson: false},
        },
        {
          ltime: '12.20 - 13.55',align: 'center',
          one: {lesson: 'Бизнес информатика', room: "504 E", teacher: "Доцент. Иванов И.И", current_count: 24, full_count: 45, is_teacher: true, is_lesson: true},
          two: {lesson: 'Юриспруденция',  room: "202", teacher: "Доцент. Иванов И.И", current_count: 42, full_count: 45, is_teacher: false, is_lesson: true},
          three: {lesson: 'Финансы и кредит',  room: "304 Д", teacher: "Доцент. Иванов И.И", current_count: 39, full_count: 45, is_teacher: true, is_lesson: true},
          four: {lesson: 'Торговое дело',  room: "425", teacher: "Доцент. Иванов И.И", current_count: 44, full_count: 45, is_teacher: true, is_lesson: true},
          five: {lesson: '',  room: "504 E", teacher: ""},
          six: {lesson: 'Торговое дело',  room: "425", teacher: "Доцент. Иванов И.И", current_count: 44, full_count: 45, is_teacher: true, is_lesson: false},
        },
        {
          ltime: '13.10 – 14.40',align: 'center',
          one: {lesson: 'Бизнес информатика', room: "504 E", teacher: "Доцент. Иванов И.И", current_count: 24, full_count: 45, is_teacher: true, is_lesson: true},
          two: {lesson: 'Юриспруденция',  room: "202", teacher: "Доцент. Иванов И.И", current_count: 42, full_count: 45, is_teacher: false, is_lesson: true},
          three: {lesson: 'Финансы и кредит',  room: "304 Д", teacher: "Доцент. Иванов И.И", current_count: 39, full_count: 45, is_teacher: true, is_lesson: true},
          four: {lesson: 'Торговое дело',  room: "425", teacher: "Доцент. Иванов И.И", current_count: 44, full_count: 45, is_teacher: true, is_lesson: true},
          five: {lesson: '',  room: "504 E", teacher: ""},
          six: {lesson: 'Торговое дело',  room: "425", teacher: "Доцент. Иванов И.И", current_count: 44, full_count: 45, is_teacher: true, is_lesson: false},
        },
        {
          ltime: '14.05 - 15.40',
          one: {lesson: 'Бизнес информатика', room: "504 E", teacher: "Доцент. Иванов И.И", current_count: 24, full_count: 45, is_teacher: true, is_lesson: true},
          two: {lesson: 'Юриспруденция',  room: "202", teacher: "Доцент. Иванов И.И", current_count: 42, full_count: 45, is_teacher: false, is_lesson: true},
          three: {lesson: 'Финансы и кредит',  room: "304 Д", teacher: "Доцент. Иванов И.И", current_count: 39, full_count: 45, is_teacher: true, is_lesson: true},
          four: {lesson: 'Торговое дело',  room: "425", teacher: "Доцент. Иванов И.И", current_count: 44, full_count: 45, is_teacher: true, is_lesson: true},
          five: {lesson: '',  room: "504 E", teacher: ""},
          six: {lesson: 'Торговое дело',  room: "425", teacher: "Доцент. Иванов И.И", current_count: 44, full_count: 45, is_teacher: true, is_lesson: false},
        },
        {
          ltime: '14.50 – 16.20',
          one: {lesson: 'Бизнес информатика', room: "504 E", teacher: "Доцент. Иванов И.И", current_count: 24, full_count: 45, is_teacher: true, is_lesson: true},
          two: {lesson: 'Юриспруденция',  room: "202", teacher: "Доцент. Иванов И.И", current_count: 42, full_count: 45, is_teacher: false, is_lesson: true},
          three: {lesson: 'Финансы и кредит',  room: "304 Д", teacher: "Доцент. Иванов И.И", current_count: 39, full_count: 45, is_teacher: true, is_lesson: true},
          four: {lesson: 'Торговое дело',  room: "425", teacher: "Доцент. Иванов И.И", current_count: 44, full_count: 45, is_teacher: true, is_lesson: true},
          five: {lesson: '',  room: "504 E", teacher: ""},
          six: {lesson: 'Торговое дело',  room: "425", teacher: "Доцент. Иванов И.И", current_count: 44, full_count: 45, is_teacher: true, is_lesson: false},
        },
        {
          ltime: '16.10  - 17.45',
          one: {lesson: 'Бизнес информатика', room: "504 E", teacher: "Доцент. Иванов И.И", current_count: 24, full_count: 45, is_teacher: true, is_lesson: true},
          two: {lesson: 'Юриспруденция',  room: "202", teacher: "Доцент. Иванов И.И", current_count: 42, full_count: 45, is_teacher: false, is_lesson: true},
          three: {lesson: 'Финансы и кредит',  room: "304 Д", teacher: "Доцент. Иванов И.И", current_count: 39, full_count: 45, is_teacher: true, is_lesson: true},
          four: {lesson: 'Торговое дело',  room: "425", teacher: "Доцент. Иванов И.И", current_count: 44, full_count: 45, is_teacher: true, is_lesson: true},
          five: {lesson: '',  room: "504 E", teacher: ""},
          six: {lesson: 'Торговое дело',  room: "425", teacher: "Доцент. Иванов И.И", current_count: 44, full_count: 45, is_teacher: true, is_lesson: false},
        },
        {
          ltime: '16.30 – 18.00',
          one: {lesson: 'Бизнес информатика', room: "504 E", teacher: "Доцент. Иванов И.И", current_count: 24, full_count: 45, is_teacher: true, is_lesson: true},
          two: {lesson: 'Юриспруденция',  room: "202", teacher: "Доцент. Иванов И.И", current_count: 42, full_count: 45, is_teacher: false, is_lesson: true},
          three: {lesson: 'Финансы и кредит',  room: "304 Д", teacher: "Доцент. Иванов И.И", current_count: 39, full_count: 45, is_teacher: true, is_lesson: true},
          four: {lesson: 'Торговое дело',  room: "425", teacher: "Доцент. Иванов И.И", current_count: 44, full_count: 45, is_teacher: true, is_lesson: true},
          five: {lesson: '',  room: "504 E", teacher: ""},
          six: {lesson: 'Торговое дело',  room: "425", teacher: "Доцент. Иванов И.И", current_count: 44, full_count: 45, is_teacher: true, is_lesson: false},
        },
        {
          ltime: '18.00  - 19.35',
          one: {lesson: 'Бизнес информатика', room: "504 E", teacher: "Доцент. Иванов И.И", current_count: 24, full_count: 45, is_teacher: true, is_lesson: true},
          two: {lesson: 'Юриспруденция',  room: "202", teacher: "Доцент. Иванов И.И", current_count: 42, full_count: 45, is_teacher: false, is_lesson: true},
          three: {lesson: 'Финансы и кредит',  room: "304 Д", teacher: "Доцент. Иванов И.И", current_count: 39, full_count: 45, is_teacher: true, is_lesson: true},
          four: {lesson: 'Торговое дело',  room: "425", teacher: "Доцент. Иванов И.И", current_count: 44, full_count: 45, is_teacher: true, is_lesson: true},
          five: {lesson: '',  room: "504 E", teacher: ""},
          six: {lesson: 'Торговое дело',  room: "425", teacher: "Доцент. Иванов И.И", current_count: 44, full_count: 45, is_teacher: true, is_lesson: false},
        },
        //    ////

      ],

      grlist:[
        // {text: '', align: 'right', value: ' ', divider:false, sortable: false},
        {text: 'Институт', align: 'center', value: 'faculty', divider: true, sortable: true, },
        {text: 'Группы', align: 'center', value: 'name', divider: true, sortable: true, groupable: false, },
        {text: 'Курс', align: 'center', value: 'course', divider: true, sortable: true,groupable: false,},
        {text: 'Форма обучения', align: 'center', value: 'formOfTraining', divider: true, sortable: false, groupable: false,},
        {text: 'Расписание', align: 'center', value: 'linkedgr', divider: true, sortable: false, groupable: false,},
      ],
     // grnames:[],

      searchpr: '',
      searchgr: '',
      prlist:[
        {text: '', align: 'right', value: 'index', divider:false, sortable: false, },
        {text: 'Преподаватель', align: 'left', value: 'teacher', divider: true, sortable: true,},
        {text: 'Расписание', align: 'center', value: 'linkedpr', divider: true, sortable: false},
      ],
      //prnames:[],
    }
  },
  // watch: {
  //   group() {
  //     this.getGroup()
  //
  //   },
  // },
  methods:{
    grSchedule: async function (item) {
      // console.log('click on ' + item)
      this.isHiddenGr = false
      this.group = item

         },
    prSchedule: async function (item) {
      console.log(item)
      this.teacher = item
      this.visibleTch = true

  },

    getGroup: function () {
      HTTP.get(Settings.API.URL_DEFAULT + "/groups")
          .then((response) => {
            this.groups = response.data

           // this.grnames= response.data
            //this.eduforms = response.data

            console.log(this.groups)

          })
          .catch(() => {

          })
          .finally(() => {

          });
    },

    getTeachers: function () {
      HTTP.get(Settings.API.URL_DEFAULT + Settings.API.URL_TEACHERS)
          .then((response) => {
            this.teachers = response.data
            //this.prnames = response.data
            console.log(this.teachers)
          })
          .catch(() => {

          })
          .finally(() => {

          });
    },
  },
  created() {
    this.getGroup(),
    this.getTeachers()
  }
}
</script>

<style scoped>
.letter:hover {

  color: rgb(54, 162, 235); /* Цвет ссылки */
}
</style>