<template>
  <div>
    <v-app id="schedule">
      <v-container>
        <v-row class="mt-6 ml-2">
          <v-col class="mr-10">
            <v-autocomplete v-model="yearOfStud"
                            :items="yearOfStuds"
                            label="Год обучения"
                            dense
                            item-value="id"
                            item-text="name"
                            return-object
                            prepend-icon="event"
            >
            </v-autocomplete>
          </v-col>
          <v-col class="mr-4">
            <v-autocomplete v-model="halfYear"
                            :items="halfYears"
                            label="Полугодие"
                            dense
                            item-value="id"
                            item-text="name"
                            return-object
                            prepend-icon="event"
            >
            </v-autocomplete>
          </v-col>
        </v-row>
      </v-container>
      <v-container justify-center>
        <v-flex xs12>
          <template>
            <v-card
                class="mx-auto"
                tile
            >
              <v-list nav dense>
                <v-list-item-group color="primary">
                  <v-list-item
                      :to="'/journal-teacher'"
                  >
                    <v-icon>history f</v-icon>
                    <v-list-item-content>
                      <v-list-item-title v-text="'Моя посещаемость'" />
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item
                      :to="'/practice-sheets'"
                  >
                    <v-icon>school f</v-icon>
                    <v-list-item-content>
                      <v-list-item-title v-text="'Практики'" />
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item
                      :to="'/course-project-sheets'"
                  >
                    <v-icon>library_books f</v-icon>
                    <v-list-item-content>
                      <v-list-item-title v-text="'Курсовые работы'" />
                    </v-list-item-content>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
              <v-divider></v-divider>
              <v-list
                  nav
                  dense
              >
                <v-list-item-group
                    v-model="selectedItem"
                    color="primary"
                >
                  <v-list-item
                      v-for="(item, i) in items"
                      :key="i"
                      :to="item.link"
                  >
                    <v-list-item-icon>
                      <v-icon v-text="item.icon">groups</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                      <v-list-item-title v-text="item.name" ></v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </v-card>
          </template>
        </v-flex>
      </v-container>
    </v-app>

  </div>
</template>

<script>

import Settings from "@/settings/settings_url";
import {HTTP} from '@/settings/axios'

export default {
  name: "JournalList",
  data() {
    return {
      yearOfStud: {},
      yearOfStuds: [],
      halfYear: {},
      halfYears: [],

      title:"",
      selectedItem: 0,
      items: [],
    }
  },
  watch:{
    'yearOfStud.id': function (){
      if (this.yearOfStud.id != undefined && this.yearOfStud.id != 0 && this.halfYear.id != undefined && this.halfYear.id != 0) {
        this.getJournal()
      }
    },
    'halfYear.id': function (){
      if (this.yearOfStud.id != undefined && this.yearOfStud.id != 0 && this.halfYear.id != undefined && this.halfYear.id != 0) {
        this.getJournal()
      }
    },
  },
  methods: {

    getYear: async function () {
      HTTP.get(Settings.API.URL_DEFAULT + "/year-of-study")
          .then((response) => {
            this.yearOfStuds = response.data
          })
          .catch(() => {

          })
          .finally(() => {
            for (let i = 0; i < this.yearOfStuds.length; i++) {
              // if (this.yearOfStuds[i].id == 1){
              //   this.yearOfStuds.splice(i,1)
              //   continue
              // }
              if (this.yearOfStuds[i]?.current == 1) {
                this.yearOfStud = this.yearOfStuds[i]
              }
            }
          });
    },
    getHalfYear: async function () {
      HTTP.get(Settings.API.URL_DEFAULT + "/half-year")
          .then((response) => {
            this.halfYears = response.data
          })
          .catch(() => {

          })
          .finally(() => {
            for (let i = 0; i < this.halfYears.length; i++) {
              if (this.halfYears[i].current == 1) {
                this.halfYear = this.halfYears[i]
                break
              }
            }
          });
    },

    getJournal:  function () {

      HTTP.get(Settings.API.URL_JOURNAL_LIST(this.yearOfStud.id, this.halfYear.id))
          .then((response) => {
            this.items = response.data
            console.log(this.items)
          })
          .catch(() => {
            this.errored = true;
          })
          .finally(() => (this.loading = false));
    }
  },
  created() {
    this.getYear()
    this.getHalfYear()
    // this.getJournal()
  },
  components: {

  }
}
</script>

<style lang="sass">



</style>
