<template>
  <v-dialog v-model="dialog"
            max-width="600px">
  <v-card>
    <v-toolbar
        dark
        color="primary"
    >
      <v-btn
          icon
          dark
          @click="closeDialog()"
      >
        <v-icon>close</v-icon>
      </v-btn>
      <v-card-title>Создать новую компенсацию</v-card-title>
    </v-toolbar>
    <v-progress-linear
        color="primary"
        :indeterminate="isCreating"
        :active="isCreating"
    />
    <v-container>
      <v-card-text>

        <v-form
            ref="form"
            v-model="valid"
        >
          <v-text-field
              v-model="name"
              outlined
              dense
              :rules="nameRules"
              label="Название компенсации"
              required
          />
          <v-text-field
              type="number"
              v-model="pointsLimit"
              outlined
              dense
              :rules="pointsLimitRules"
              label="Максимальное количество баллов"
              required
          />
        </v-form>

      </v-card-text>
      <v-divider/>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
            text
            color="secondary"
            @click="closeDialog()"
        >
          Закрыть
        </v-btn>
        <v-btn
            text
            :disabled="!valid || isCreating"
            :loading="isCreating"
            color="primary"
            @click=createType()
        >
          Создать
        </v-btn>
      </v-card-actions>
    </v-container>
  </v-card>
  </v-dialog>
</template>

<script>
import {HTTP} from "@/settings/axios";
import Settings from "@/settings/settings_url";

export default {
  name: "CompensationCreateType",
  data() {
    return {
      dialog: false,
      isCreating: false,
      valid: false,
      maxNameLength: 255,
      name: null,
      nameRules: [
          v => !!v || 'Это поле обязательно',
          v => v.length < this.maxNameLength || 'Слишком длинное название. Введите меньше ' + this.maxNameLength + ' символов'
      ],
      pointsLimit: null,
      pointsLimitRules: [
          v => !!v || 'Это поле обязательно',
          v => v > 0 || 'Введите число выше 0',
      ],
    }
  },
  methods: {
    createType() {
      this.isCreating = true

      HTTP.post(Settings.API.URL_DEFAULT + "/compensation-type", {
        "name": this.name,
        "pointsLimit": this.pointsLimit,
        "creator": this.$store.getters.selectedUser
      })
          .then(() => {
            // this.balance = response.data
            this.$emit('typeCreated')
            this.$emit('success', 'Компенсация '
                + this.name + ' успешно создана')
          })
          .catch(() => {
            this.$emit('error', 'Ошибка создания компенсации')
          })
          .finally(() => {
            this.closeDialog()
            this.isCreating = false
          });
    },
    closeDialog: function() {
      this.$refs.form.reset()
      this.$refs.form.resetValidation()
      this.dialog = false
    },
  },
}
</script>

<style scoped>

</style>