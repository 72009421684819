var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-app',{attrs:{"id":"schedule"}},[_c('v-layout',{attrs:{"justify-center":""}},[_c('v-flex',{attrs:{"xs12":""}},[_c('v-card',[_c('v-card-text',[_c('v-layout',{attrs:{"row":"","wrap":""}},[_c('v-flex',{attrs:{"xs12":"","md12":""}},[_c('v-card-actions',{staticClass:"justify-center"},[_c('h1',[_vm._v("Отчет по группам за 14.10.2020 г.")])])],1),_c('v-flex',{attrs:{"xs12":"","md6":""}}),_c('v-flex',{attrs:{"xs12":"","md6":""}})],1)],1),_c('v-card-actions',[_c('v-spacer')],1)],1)],1)],1),_c('v-layout',{attrs:{"justify-center":""}},[_c('v-flex',{attrs:{"xs12":""}},[_c('v-data-table',{staticClass:"elevation-10",attrs:{"headers":_vm.headers,"items":_vm.desserts,"items-per-page":10,"hide-default-footer":false},scopedSlots:_vm._u([{key:"item.one",fn:function(ref){
var item = ref.item;
return [_c('schedule-item',{attrs:{"header":item.one.lesson,"content":item.one.teacher,"room":item.one.room,"current_count":item.one.current_count,"full_count":item.one.full_count,"is_teacher":item.one.is_teacher,"is_lesson":item.one.is_lesson}})]}},{key:"item.two",fn:function(ref){
var item = ref.item;
return [_c('schedule-item',{attrs:{"header":item.two.lesson,"content":item.two.teacher,"room":item.two.room,"current_count":item.two.current_count,"full_count":item.two.full_count,"is_teacher":item.two.is_teacher,"is_lesson":item.two.is_lesson}})]}},{key:"item.three",fn:function(ref){
var item = ref.item;
return [_c('schedule-item',{attrs:{"header":item.three.lesson,"content":item.three.teacher,"room":item.three.room,"current_count":item.three.current_count,"full_count":item.three.full_count,"is_teacher":item.three.is_teacher,"is_lesson":item.three.is_lesson}})]}},{key:"item.four",fn:function(ref){
var item = ref.item;
return [_c('schedule-item',{attrs:{"header":item.four.lesson,"content":item.four.teacher,"room":item.four.room,"current_count":item.four.current_count,"full_count":item.four.full_count,"is_teacher":item.four.is_teacher,"is_lesson":item.four.is_lesson}})]}},{key:"item.five",fn:function(ref){
var item = ref.item;
return [_c('schedule-item',{attrs:{"header":item.five.lesson,"content":item.five.teacher,"room":item.five.room,"current_count":item.five.current_count,"full_count":item.five.full_count,"is_teacher":item.five.is_teacher,"is_lesson":item.five.is_lesson}})]}},{key:"item.six",fn:function(ref){
var item = ref.item;
return [_c('schedule-item',{attrs:{"header":item.six.lesson,"content":item.six.teacher,"room":item.six.room,"current_count":item.six.current_count,"full_count":item.six.full_count,"is_teacher":item.six.is_teacher,"is_lesson":item.six.is_lesson}})]}}])})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }