<template>
  <div>
    <h3>Line Chart Example in Vue</h3>
    <line-chart
        :chart-data="chartData"
        :options="options"
    ></line-chart>
  </div>
</template>

<script>
import LineChart from '@/components/charts/LineChart'

export default {
  components: {
    LineChart
  },
  data () {
    return {

      chartData: {
        labels: ["Babol",	"Cabanatuan",	"Daegu",	"Jerusalem",	"Fairfield",	"New York",	"Gangtok", "Buenos Aires", "Hafar Al-Batin", "Idlib"],
        datasets: [
          {
            label: 'Line Chart',
            data: [600,	1150,	342,	6050,	2522,	3241,	1259,	157,	1545, 9841],
            fill: true,
            borderColor: '#2554FF',

            backgroundColor: '#25FFFF',
            borderWidth: 1
          }
        ]
      },
      options: {
        scales: {
          yAxes: [{
            ticks: {
              beginAtZero: true
            },
            gridLines: {
              display: true
            }
          }],
          xAxes: [ {
            gridLines: {
              display: true
            }
          }]
        },
        legend: {
          display: true
        },
        responsive: true,
        maintainAspectRatio: false
      }
    }
  },
}
</script>