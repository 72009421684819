<template>
  <div>

    <div>
      <v-btn
          color="primary"
          dark
          @click="newItem"
      >
        Добавить
      </v-btn>
    </div>

    <template>
      <v-row justify="center">
        <v-dialog
            v-model="dialog"
            persistent
            max-width="600px"
        >
          <v-card>
            <v-card-title>
              <span class="headline">{{ title_dialog }}</span>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col
                      cols="12"
                      sm="12"
                      md="12"
                  >
                    <v-autocomplete v-model="number"
                                    :items="numbers"
                                    label="Пара"
                                    dense
                    >
                    </v-autocomplete>

                    <v-dialog
                        ref="modalStartDate"
                        v-model="modalStartDate"
                        :return-value.sync="timeStart"
                        persistent
                        width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                            v-model="timeStart"
                            label="Picker in dialog"
                            prepend-icon="access_time"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                        ></v-text-field>
                      </template>
                      <v-time-picker
                          v-if="modalStartDate"
                          v-model="timeStart"
                          format="24hr"
                          full-width
                      >
                        <v-spacer></v-spacer>
                        <v-btn
                            text
                            color="primary"
                            @click="modalStartDate = false"
                        >
                          Cancel
                        </v-btn>
                        <v-btn
                            text
                            color="primary"
                            @click="$refs.modalStartDate.save(timeStart)"
                        >
                          OK
                        </v-btn>
                      </v-time-picker>
                    </v-dialog>

                    <v-dialog
                        ref="dialogEndDate"
                        v-model="modalEndDate"
                        :return-value.sync="timeEnd"
                        persistent
                        width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                            v-model="timeEnd"
                            label="Picker in dialog"
                            prepend-icon="access_time"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                        ></v-text-field>
                      </template>
                      <v-time-picker
                          v-if="modalEndDate"
                          v-model="timeEnd"
                          format="24hr"
                          full-width
                      >
                        <v-spacer></v-spacer>
                        <v-btn
                            text
                            color="primary"
                            @click="modalEndDate = false"
                        >
                          Cancel
                        </v-btn>
                        <v-btn
                            text
                            color="primary"
                            @click="$refs.dialogEndDate.save(timeEnd)"
                        >
                          OK
                        </v-btn>
                      </v-time-picker>
                    </v-dialog>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                  color="blue darken-1"
                  text
                  @click="dialog = false"
              >
                Close
              </v-btn>
              <v-btn
                  color="blue darken-1"
                  text
                  @click="save()"
              >
                Save
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
    </template>

    <v-data-table
        :headers="headers"
        :items="desserts"
        :items-per-page="15"
        :hide-default-footer="false"
        class="elevation-2"
        :single-expand="true"
    >
      <template v-slot:item.linked="{ item }">
        <v-btn @click="editItem(item)" icon>
          <v-icon>settings</v-icon>
        </v-btn>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import Settings from "@/settings/settings_url";
import {HTTP} from '@/settings/axios'

export default {
  name: "LessonNumbers",
  data() {
    return {
      headers: [
        {text: 'id', align: 'center', sortable: true, value: 'id', divider: true, class: "bolt"},
        {text: 'Наименование', align: 'center', value: 'name', divider: true, sortable: true, class: "bolt"},
        {text: 'Номер', align: 'center', value: 'number', divider: true, sortable: true, class: "bolt"},
        {text: 'Время начала', align: 'center', value: 'timeStart', divider: true, sortable: true, class: "bolt"},
        {text: 'Время конца', align: 'center', value: 'timeEnd', divider: true, sortable: true, class: "bolt"},
        {text: 'Редактировать', value: 'linked', divider: true, align: 'center'}
      ],
      desserts: [],

      modalStartDate: false,
      modalEndDate: false,

      dialog: false,
      dialogStartDate: false,
      dialogEndDate: false,
      title_dialog: "",

      id: null,
      number: null,
      numbers: [
        {"text": "1 п. 1-смена", "value": 1},
        {"text": "2 п. 1-смена", "value": 2},
        {"text": "3 п. 1-смена", "value": 3},
        {"text": "4 п. 1-смена", "value": 4},
        {"text": "1 п. 2-смена", "value": 5},
        {"text": "2 п. 2-смена", "value": 6},
        {"text": "3 п. 2-смена", "value": 7},
        {"text": "4 п. 2-смена", "value": 8},
      ],
      timeStart: null,
      timeEnd: null,


    };
  },
  methods: {
    clearItem: function () {
      this.id = null
      this.number = null
      this.timeStart = null
      this.timeEnd = null

    },
    editItem: function (item) {
      this.clearItem()

      this.id = item.id
      this.number = item.number
      this.timeStart = item.timeStart
      this.timeEnd = item.timeEnd

      this.dialog = true
      this.title_dialog = "Редактировать тип"
    },
    newItem: function () {
      this.clearItem()
      this.dialog = true
      this.title_dialog = "Добавить тип"
    },
    save: function () {
      this.dialog = false
      if (this.id == null) {
        this.create()
      } else {
        this.update()
      }
    },
    update: async function () {
      await HTTP.put(Settings.API.URL_DEFAULT + Settings.URL_LESSON_NUMBERS, {
        "id": this.id,
        "name": this.getName(),
        "number": this.number,
        "timeStart": this.timeStart,
        "timeEnd": this.timeEnd
      })
          .then(() => {
          })

      await this.getType()
    },
    getName: function () {
      let findName = this.number
      return this.numbers.find(function (item) {
        return item.value === findName
      }).text
    },
    create: async function () {
      await HTTP.post(Settings.API.URL_DEFAULT + Settings.URL_LESSON_NUMBERS, {
        "id": this.id,
        "name": this.getName(),
        "number": this.number,
        "timeStart": this.timeStart,
        "timeEnd": this.timeEnd
      })
          .then(() => {
          })

      await this.getType()
    },
    getType: async function () {
      await HTTP.get(Settings.API.URL_DEFAULT + Settings.URL_LESSON_NUMBERS)
          .then((response) => {
            this.desserts = response.data
          })
    }
  },
  created() {
    this.getType()
  }
}
</script>

<style scoped>

</style>
