<template>

  <v-layout row wrap>
    <v-flex xs12 sm4 md3 lg2 v-for="(item, i) in items_card" :key="i">
      <v-hover>
        <template v-slot="{ hover }">

<!--          class="my-1 mx-1"-->
          <div >
          <v-card
              :loading="loading"
              :elevation="hover ? 24 : 6"

              style="flex-basis:  200px"

              class="my-3 mx-3"



          >
            <template slot="progress">
              <v-progress-linear
                  color="deep-purple"
                  height="10"
                  indeterminate
              ></v-progress-linear>
            </template>


            <div>
              <v-flex style="height: 100px;weight:350px">
            <v-card-title>

                <div >
                  Аудитории :     <v-spacer></v-spacer><b><span v-for="i in item.audiences" :key="i">{{ i.name }} </span></b>
              </div>
              <v-spacer></v-spacer>

              <v-btn icon :to="getUrl(item)">
                <v-icon small>info</v-icon>
              </v-btn>


            </v-card-title>

            </v-flex>

            <v-divider class="mx-4"></v-divider>
<!--            <v-spacer></v-spacer>-->
            </div>
            <v-flex style="height: 300px; weight:350px">
            <v-card-text class="text--primary" style="font-size:15px">

              <p >   Группа : <b>{{ item.workload.group.name }}</b></p><p></p>


              <p>
                Дисциплина : <b>{{ item.workload.discipline.fullName }}</b>
              </p><p></p>

              <div>
            Преподаватель : <b>{{ item.workload.mainTeachers.fio}}  </b>
                <v-icon v-if="!is_teacher(item)" color="error">error</v-icon>
               <v-icon v-else color="green">lens</v-icon>

              </div><p></p>

              <p>
                Студенты : <b><v-chip :color=" getColor(item.studentPresentCount ,item.studentCount)">   {{ item.studentPresentCount }} / {{ item.studentCount }}</v-chip></b>
              </p><p></p>


            </v-card-text>


<!--            </v-flex>-->


<!--            <v-card-actions>-->
<!--              <v-btn-->
<!--                  color="lighten-2"-->
<!--                  text-->
<!--                  to="/groupcard"-->
<!--              >-->
<!--                Подробнее-->
<!--              </v-btn>-->
<!--            </v-card-actions>-->
            </v-flex>
          </v-card>

          </div>

        </template>
      </v-hover>

    </v-flex>

  </v-layout>


</template>

<script>
// @ is an alias to /src


// import Settings from "@/settings/settings_url";
// import {HTTP} from '@/settings/axios'

export default {
  name: 'Home',
  data: () => ({


    loading: false,
    selection: 1,
    items_card: []

  }),

  methods: {
    getColor (studentPresentCount,studentCount) {
      if (studentPresentCount < studentCount * 0.6 ) return "red";
      if (studentPresentCount >= studentCount * 0.6 && studentPresentCount < studentCount * 0.85 ) return "orange";
      if (studentPresentCount >=  studentCount * 0.85) return "green";
      //if (studentPresentCount == undefined) return 'white';
    },

    is_teacher(item) {
      return item.is_teacher;
    },

    reserve() {
      this.loading = true

      setTimeout(() => (this.loading = false), 2000)
    },
    // get_message_students(item) {
    //   let percent = item.current_count / item.full_count * 100;
    //   if (percent <= 70) return "error";
    //   if (percent <= 90) return "warning";
    //   return "";
    // },

    // getLessons: async function () {
    //   await HTTP.get(Settings.API.URL_LESSONS_BY_SATE_AND_TIME_AND_NUM_DAY())
    //       .then((response) => {
    //         this.items_card = response.data
    //
    //       })
    //       .catch(() => {
    //         this.errored = true;
    //       })
    //       .finally(() => (this.loading = false));
    // },
    getUrl: function (item){
      this.$store.commit('update_lesson_detail', item)
      return "/groupcard"
    }
  },
  created() {
    // this.getLessons()
  },
  components: {}
}
</script>
<style>

</style>
