<template>
  <div>
    <v-container>
      <v-row>
        <v-col class="mr-10">
          <v-autocomplete v-model="year_of_study"
                          :items="year_of_studs"
                          label="Год обучения"
                          prepend-icon="event"
                          dense
          >
          </v-autocomplete>
        </v-col>
        <v-col class="mr-10">
          <v-autocomplete v-model="half_year"
                          :items="half_years"
                          label="Полугодие"
                          prepend-icon="event"
                          dense
          >
          </v-autocomplete>
        </v-col>
        <v-btn
            icon
            @click="getStatistics"
        >
          <v-icon>sync</v-icon>
        </v-btn>
      </v-row>
      <v-text-field
          class="mb-4"
          v-model="search"
          prepend-icon="search"
          label="Поиск"
          single-line
          hide-details
      ></v-text-field>
      <v-data-table
          :headers="headers"
          :items="statistics"
          :items-per-page="20"
          :footer-props="{
             'items-per-page-options': itemsPerPages
          }"
          class="elevation-4"
          :loading="loading"
          :search="search"
          loading-text="Загрузка..."
          no-data-text="Нет информации"
      >

        <template v-slot:item.repeat="{ item }">
            <div>{{ item.repeat ? 'да' : 'нет' }}</div>
        </template>

        <template v-slot:item.link="{ item }">
          <a target="_blank" style="text-decoration: none"
             :href="'https://lms2.sseu.ru/course/view.php?id=' + item.controlPointCategories.lmsId">
            <v-icon>ios_share</v-icon>
          </a>
        </template>

      </v-data-table>
    </v-container>
  </div>
</template>

<script>
import {HTTP} from '@/settings/axios'
import Settings from "@/settings/settings_url";

export default {
  name: "StatisticCoursesLms",
  data() {
    return {
      half_year: 2,
      year_of_study: 2,
      year_of_studs: [
        {text: "2021-2022", value: 2},
        {text: "2022-2023", value: 3}
      ],
      half_years: [
        {text: "1 полугодие", value: 1},
        {text: "2 полугодие", value: 2}
      ],
      search: "",
      loading: false,
      statistics: [],
      itemsPerPages: [
        10,
        20,
        30,
        50,
        70,
        90,
        100,
        -1
      ],
      headers: [
        {
          text: 'Группа',
          align: 'center',
          sortable: true,
          value: 'controlPointCategories.group.name',
          divider: true,
          class: "bolt"
        },
        {
          text: 'Курс',
          align: 'center',
          sortable: true,
          value: 'controlPointCategories.group.course',
          divider: true,
          class: "bolt"
        },
        {
          text: 'Институт',
          align: 'center',
          sortable: true,
          value: 'controlPointCategories.group.faculty',
          divider: true,
          class: "bolt"
        },
        {text: 'Тип', align: 'center', sortable: true, value: 'typeEducation', divider: true, class: "bolt"},
        {
          text: 'Дисциплина',
          align: 'left',
          value: 'controlPointCategories.discipline.name',
          divider: true,
          sortable: true,
          class: "bolt"
        },
        {
          text: 'Продолжение',
          align: 'left',
          value: 'repeat',
          divider: true,
          sortable: true,
          class: "bolt"
        },
        {text: 'ТТК', value: 'ttk', divider: true, sortable: true, class: "bolt"},
        {text: 'TAA', value: 'taa', divider: true, sortable: true, class: "bolt"},
        {text: 'SUM', value: 'sum', divider: true, sortable: true, class: "bolt"},
        {text: 'link', value: 'link', divider: true, sortable: false, class: "bolt"},
      ],

    }
  },
  methods: {
    getStatistics() {
      this.loading = true
      this.statistics = []
      HTTP.get(Settings.API.URL_DEFAULT + "/control-points/statistic-courses-lms?year=" + this.year_of_study + "&halfYear=" + this.half_year)
          .then((response) => {
            this.statistics = response.data
            console.log(response)
          }).finally(() => {
        this.loading = false
      })
    },
  },
  created() {
    this.getStatistics()
  }
}
</script>

<style scoped>

</style>