var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-content',{attrs:{"fixed":""}},[_c('v-container',{staticClass:"lef",attrs:{"grid-list-xl":""}},[_c('v-layout',{attrs:{"row":"","wrap":""}},[_c('v-flex',{staticClass:"lg5-custom",attrs:{"xs6":"","sm4":"","md3":"","xl2":""}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{attrs:{"min-height":"100%","to":"/schedule-board/schedule-all"}},[_c('v-card-text',{attrs:{"align":"center"}},[_c('v-row',[_c('v-col')],1),_c('v-row',[_c('v-col',[_c('img',{attrs:{"src":require("@/assets/pictures/schedule.svg")}})])],1),_c('v-row',[_c('v-col',[_c('span',{staticClass:"ltr"},[_c('nobr',[_vm._v("Расписание ")])],1)])],1),_c('v-row',[_c('v-col')],1)],1),_c('v-fade-transition',[(hover)?_c('v-overlay',{attrs:{"absolute":"","color":"blue","opacity":"0.3"}}):_vm._e()],1)],1)]}}])})],1),_c('v-flex',{staticClass:"lg5-custom",attrs:{"xs6":"","sm4":"","md3":"","xl2":""}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{attrs:{"min-height":"100%","to":"/student-statistics"}},[_c('v-card-text',{attrs:{"align":"center"}},[_c('v-row',[_c('v-col')],1),_c('v-row',[_c('v-col',[_c('img',{attrs:{"src":require("@/assets/pictures/academ.svg")}})])],1),_c('v-row',[_c('v-col',[_c('span',{staticClass:"ltr"},[_vm._v("Успеваемость и посещаемость")])])],1),_c('v-row',[_c('v-col')],1)],1),_c('v-fade-transition',[(hover)?_c('v-overlay',{attrs:{"absolute":"","color":"blue","opacity":"0.3"}}):_vm._e()],1)],1)]}}])})],1),_c('v-flex',{staticClass:"lg5-custom",attrs:{"xs6":"","sm4":"","md3":"","xl2":""}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{attrs:{"min-height":"100%","to":"/certificate/certificate"}},[_c('v-card-text',{attrs:{"align":"center"}},[_c('v-row',[_c('v-col')],1),_c('v-row',[_c('v-col',[_c('img',{attrs:{"src":require("@/assets/pictures/usd.svg")}})])],1),_c('v-row',[_c('v-col',[_c('span',{staticClass:"ltr"},[_c('nobr',[_vm._v("Сервисы")]),_vm._v(" "),_c('nobr',[_vm._v("Единого")]),_vm._v(" "),_c('nobr',[_vm._v("студенческого")]),_vm._v(" "),_c('nobr',[_vm._v("департамента")])],1)])],1),_c('v-row',[_c('v-col')],1)],1),_c('v-fade-transition',[(hover)?_c('v-overlay',{attrs:{"absolute":"","color":"blue","opacity":"0.3"}}):_vm._e()],1)],1)]}}])})],1),_c('v-flex',{staticClass:"lg5-custom",attrs:{"xs6":"","sm4":"","md3":"","xl2":""}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('a',{attrs:{"href":"https://www.sseu.ru/vneuchebnaya-deyatelnost/vneuchebnaya-deyatelnost"}},[_c('v-card',{attrs:{"min-height":"100%"}},[_c('v-card-text',{attrs:{"align":"center"}},[_c('v-row',[_c('v-col')],1),_c('v-row',[_c('v-col',[_c('img',{attrs:{"src":require("@/assets/pictures/activities.svg")}})])],1),_c('v-row',[_c('v-col',[_c('span',{staticClass:"ltr"},[_c('nobr',[_vm._v("Внеучебная")]),_vm._v(" "),_c('nobr',[_vm._v("деятельность")])],1)])],1),_c('v-row',[_c('v-col')],1)],1),_c('v-fade-transition',[(hover)?_c('v-overlay',{attrs:{"absolute":"","color":"blue","opacity":"0.3"}}):_vm._e()],1)],1)],1)]}}])})],1),_c('v-flex',{staticClass:"lg5-custom",attrs:{"xs6":"","sm4":"","md3":"","xl2":""}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('a',{attrs:{"href":"https://lms2.sseu.ru/my/"}},[_c('v-card',{attrs:{"min-height":"100%"}},[_c('v-card-text',{attrs:{"align":"center"}},[_c('v-row',[_c('v-col')],1),_c('v-row',[_c('v-col',[_c('img',{attrs:{"src":require("@/assets/pictures/courses.svg")}})])],1),_c('v-row',[_c('v-col',[_c('span',{staticClass:"ltr"},[_c('nobr',[_vm._v("Текущие")]),_vm._v(" "),_c('nobr',[_vm._v("курсы")]),_vm._v(" и "),_c('nobr',[_vm._v("образовательная")]),_vm._v(" "),_c('nobr',[_vm._v("траектория")])],1)])],1),_c('v-row',[_c('v-col')],1)],1),_c('v-fade-transition',[(hover)?_c('v-overlay',{attrs:{"absolute":"","color":"blue","opacity":"0.3"}}):_vm._e()],1)],1)],1)]}}])})],1),_c('v-flex',{staticClass:"lg5-custom",attrs:{"xs6":"","sm4":"","md3":"","xl2":""}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('a',{attrs:{"href":"https://lms2.sseu.ru/blocks/sibportfolio/index.php"}},[_c('v-card',{attrs:{"min-height":"100%"}},[_c('v-card-text',{attrs:{"align":"center"}},[_c('v-row',[_c('v-col')],1),_c('v-row',[_c('v-col',[_c('img',{attrs:{"src":require("@/assets/pictures/porfolio.svg")}})])],1),_c('v-row',[_c('v-col',[_c('span',{staticClass:"ltr"},[_vm._v("Цифровое портфолио")])])],1),_c('v-row',[_c('v-col')],1)],1),_c('v-fade-transition',[(hover)?_c('v-overlay',{attrs:{"absolute":"","color":"blue","opacity":"0.3"}}):_vm._e()],1)],1)],1)]}}])})],1),_c('v-flex',{staticClass:"lg5-custom",attrs:{"xs6":"","sm4":"","md3":"","xl2":""}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('a',{attrs:{"href":"https://www.sseu.ru/fakultetyi/fakultet-vtorogo-vyisshego-i-dopolnitelnogo-obrazovaniya"}},[_c('v-card',{attrs:{"min-height":"100%"}},[_c('v-card-text',{attrs:{"align":"center"}},[_c('v-row',[_c('v-col')],1),_c('v-row',[_c('v-col',[_c('img',{attrs:{"src":require("@/assets/pictures/forum.svg")}})])],1),_c('v-row',[_c('v-col',[_c('span',{staticClass:"ltr"},[_c('nobr',[_vm._v("Дополнительное")]),_vm._v(" "),_c('nobr',[_vm._v("образование")])],1)])],1),_c('v-row',[_c('v-col')],1)],1),_c('v-fade-transition',[(hover)?_c('v-overlay',{attrs:{"absolute":"","color":"blue","opacity":"0.3"}}):_vm._e()],1)],1)],1)]}}])})],1),_c('v-flex',{staticClass:"lg5-custom",attrs:{"xs6":"","sm4":"","md3":"","xl2":""}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('a',{attrs:{"href":"http://lib1.sseu.ru/MegaPro/Web"}},[_c('v-card',{attrs:{"min-height":"100%"}},[_c('v-card-text',{attrs:{"align":"center"}},[_c('v-row',[_c('v-col')],1),_c('v-row',[_c('v-col',[_c('img',{attrs:{"src":require("@/assets/pictures/elibrary.svg")}})])],1),_c('v-row',[_c('v-col',[_c('span',{staticClass:"ltr"},[_c('nobr',[_vm._v("Электронная")]),_vm._v(" "),_c('nobr',[_vm._v("библиотека")])],1)])],1),_c('v-row',[_c('v-col')],1)],1),_c('v-fade-transition',[(hover)?_c('v-overlay',{attrs:{"absolute":"","color":"blue","opacity":"0.3"}}):_vm._e()],1)],1)],1)]}}])})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }