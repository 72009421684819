<!--names: ['ПОЭД31', 'СИНАД11', 'ПОНБгос22', 'ПОНБуг41', 'БПСЭ11', 'СИНД31', 'ПОНБгос42', 'ПОЭД22'],
    types: ['Месяц', 'Неделя', 'День', '4дня'],-->
<template>
  <v-layout justify-center>
    <v-flex xs12>
  <v-card>
    <v-card-text>
      <v-layout row wrap>
        <v-flex xs12 md12>
          <v-card-actions align="right" >
            <v-spacer><h2>Всего студентов в группе: {{allNumSt}} </h2></v-spacer>
          </v-card-actions>
          <v-card-actions class="justify-center">
            <h1>Отчет по группе {{grName}} за {{today}}</h1>
          </v-card-actions>
          <v-card-actions>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-flex>
      </v-layout>
    </v-card-text>
  </v-card>
  <v-data-table
      :headers="headers"
      :items="lessons"
      class="elevation-1"
      :hide-default-footer="true">
      <!--      fixed-header-->
      <!--      height="500"-->



    <template v-slot:item.visit="{ item }">
      <v-chip
          :color="getColor(item.visit)"
          dark
      >
        {{ item.visit }}
      </v-chip>
    </template>

    <template v-slot:item.prof="{ item }">
      <div v-for="i in item.prof" :key="i" >
        {{ i }}
      </div>
    </template>

  </v-data-table>
    </v-flex>
  </v-layout>
</template>




<script>
export default {
  data () {
    return {
      // week:'(не)чётная',
      grName:'БПСЭ11',
      today: '25-05-2021',
      allNumSt: 400,
      headers: [
        { text: 'Время', align: 'center',  value: 'time', divider: true,sortable: false  },
        { text: 'Дата', align: 'center',  value: 'dt', divider: true,sortable: false  },
        { text: 'Группа', value: 'group', divider: true,sortable: false  },
        { text: 'Дисциплина', value: 'disc', divider: true,sortable: false  },
        { text: 'Преподаватель', value: 'prof', divider: true,sortable: false },
        { text: 'Аудитория', value: 'room' , divider: true,sortable: false },
        { text: 'Посещаемость', value: 'visit', divider: true,sortable: false }

      ],
      lessons: [
        {
          time: '08:30 - 10:00',
          dt:'21.06.2021',
          visit: 4,
          group: 'БПСЭ11',
          disc: 'Финансы и кредит',
          room: 24,
          prof: [
              'Доцент. Иванов И.И',
            'Доцент. Петров И.И',
            ],


        },
        {
          time: '9.30 – 11.00',
          visit: undefined,
          disc: undefined,
          room: undefined,
          prof: [],


        },
        {
          time: '10:15 - 11.50',
          visit: 237,
          disc: 'Бизнес информатика',
          room: 37,
          prof: [
            'Доцент. Иванов И.И',
            'Доцент. Петров И.И',
          ],

        },
        {
          time: '11.10 – 12.40',
          visit: undefined,
          disc: undefined,
          room: undefined,
          prof: [],

        },
        {
          time: '12.20 - 13.55',
          visit: 262,
          disc: 'Юриспруденция',
          room: 23,
          prof: [
            'Доцент. Иванов И.И',
            'Доцент. Петров И.И',
          ],

        },
        {
          time: '13.10 – 14.40',
          visit: 452,
          disc: 'Торговое дело',
          room: 51,
          prof: [
            'Доцент. Иванов И.И',
            'Доцент. Петров И.И',
          ],

        },
        {
          time: '14.05 - 15.40',
          visit: 305,
          disc: 'Финансы и кредит',
          room: 67,
          prof: [
            'Доцент. Иванов И.И',
            'Доцент. Петров И.И',
          ],

        },
        {
          time: '14.50 – 16.20',
          visit: 518,
          disc: 'Бизнес информатика',
          room: 65,
          prof: [
            'Доцент. Иванов И.И',
            'Доцент. Петров И.И',
          ],

        },
        {
          time: '16.10  - 17.45',
          visit: 356,
          disc: 'Юриспруденция',
          room: 49,
          prof: [
            'Доцент. Иванов И.И',
            'Доцент. Петров И.И',
          ],

        },
        {
          time: '16.30 – 18.00',
          visit: 518,
          disc: 'Бизнес информатика',
          room: 65,
          prof: [
            'Доцент. Иванов И.И',
            'Доцент. Петров И.И',
          ],

        },
        {
          time: '18.00  - 19.35',
          visit: 375,
          disc: 'Финансы и кредит',
          room: 94,
          prof: [
            'Доцент. Иванов И.И',
            'Доцент. Петров И.И',
          ],

        },

      ],
    }
  },
  methods: {
    getColor (visit) {
      if (visit < this.allNumSt * 0.6 ) return "red";
      if (visit >= this.allNumSt * 0.6 && visit < this.allNumSt * 0.85 ) return "orange";
      if (visit >=  this.allNumSt * 0.85) return "green";
      if (visit == undefined) return 'white';
    },
  },
}
</script>

<style scoped>
</style>