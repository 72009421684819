<template>
  <div>
    <v-row>
      <v-col>
        <v-autocomplete
            v-model="group"
            :items="groups"
            outlined
            dense
            item-text="name"
            item-value="id"
            return-object
            label="Группа"
            prepend-icon="groups"
        />
      </v-col>
      <v-col>
        <v-autocomplete
            v-model="stud"
            :items="studs"
            outlined
            dense
            multiple
            label="Cтудент"
            item-text="student"
            item-value="id"
            return-object
            prepend-icon="person"
        />
      </v-col>
      <v-col>
        <v-btn color="primary" icon @click="all"><v-icon>done_all</v-icon></v-btn>
        <v-btn color="secondary" icon @click="clear"><v-icon>clear</v-icon></v-btn>
      </v-col>
    </v-row>
    <div class="qrcode-list">
      <div class="qrcode-list__item"  v-for="item in stud" :key="item.id">
        <div class="qrcode-list__item-content">
          <h2 style="margin: 10px auto 0 auto">{{item.student}}</h2>
          <div>{{qrcode}}</div>
          <!--      <v-img :src="url + '/qrcode/student/' + item.id"></v-img>-->
          <img :src="url + '/qrcode/student/' + item.id" alt="">
        </div>

      </div>
    </div>

  </div>
</template>

<script>
import Settings from "@/settings/settings_url";
import {HTTP} from '@/settings/axios'

export default {
  name: "SpoGroups",
  data(){
    return {
      loading: false,
      groups: [],
      studs: [],
      group: null,
      stud: [],
      qrcode:"",
      url: Settings.API.URL_DEFAULT,
    }
  },
  watch: {
    group: function () {
      this.getStudent()
    },
  },
  methods:{
    clear(){
      this.stud = []
    },
    all(){
      this.stud = [...this.studs]
    },
    getStudent: function () {
      if (this.group.id != undefined) {
        this.stud = []
        HTTP.get(Settings.API.URL_DEFAULT + "/groups/" + this.group.id + "/students")
            .then((response) => {
              // console.log(response.data)
              this.studs = response.data

            })
            .catch(() => {

            })
            .finally(() => {

            });
      }
    },
    async getGroups() {
      this.loading = true;

      await HTTP.get(Settings.API.URL_DEFAULT + "/groups/spo-all" )
          .then((response) => {
            this.groups = response.data
            //console.log(this.groups)
          })
          .catch(() => {
          })
          .finally(() => {
            // this.calcSum()
            this.loading = false
          });
    },
    getQrCode: function (id) {
        HTTP.get(Settings.API.URL_DEFAULT + "/qrcode/student/" + id )
            .then((response) => {
              // console.log(response.data)
              this.qrcode = response.data

            })
            .catch(() => {

            })
            .finally(() => {

            });

    },
  },
  mounted() {
    this.getGroups()
  }
}
</script>

<style scoped>
.qrcode-list{
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
}
.qrcode-list__item{
  border: #0f0f0f solid 1px;
  margin-bottom: 20px;
}
.qrcode-list__item-content{
  display: flex;
  flex-direction: column;
}
</style>