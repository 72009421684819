<template>
  <v-container>
    <v-row>
      <v-col cols="5">
        <v-autocomplete
            v-model="group"
            :items="groups"
            outlined
            dense
            item-text="name"
            item-value="id"
            return-object
            label="Группа"
            prepend-icon="groups"
        />
      </v-col>
      <v-col cols="5">
        <v-autocomplete
            v-model="stud"
            :items="studs"
            outlined
            dense
            multiple
            label="Cтудент"
            item-text="student"
            item-value="id"
            return-object
            prepend-icon="person"
        />
      </v-col>
      <v-col cols="2">
        <v-btn color="primary" icon @click="all"><v-icon>done_all</v-icon></v-btn>
        <v-btn color="secondary" icon @click="clear"><v-icon>clear</v-icon></v-btn>
        <v-btn color="primary" @click="printDocx()" :disabled="printLoading">
          <v-icon left>print</v-icon>
          Печать
        </v-btn>
      </v-col>
    </v-row>

    <v-container v-if="stud.length != 0">
    <div  class="doc__header">
      <div class="doc__title">
        Сводная статистика
      </div>
    </div>
    <div class="container-kt">
      <table>
        <tr>
          <th>№ п/п</th>
          <th>Наименование дисциплины</th>
          <th>Студентов прошло тестирование</th>
          <th>Средняя оценка по тестированию</th>
          <th>Студентов прошло промежуточную аттестацию</th>
          <th>Средняя оценка промежуточной аттестации</th>
          <th>Всего студентов</th>
        </tr>
        <tr style="text-align: center" v-for="(element, j) in disciplines" :key="element.id">
          <td>{{ j + 1 }}</td>
          <td>{{ element.name }}</td>
          <td>{{ element.gradedStudentCount}}</td>
          <td>{{ element.avgGrade }}</td>
          <td>{{ element.attestatedStudentCount }}</td>
          <td>{{ element.avgAttestationGrade }}</td>
          <td>{{ stud.length }}</td>
        </tr>
      </table>
    </div>
    </v-container>

    <div class="container-kt" v-for="student in stud" :key="student.id">
      <comprehensive-testing-student-card
          :student="student"
      ></comprehensive-testing-student-card>
    </div>
  </v-container>


</template>
<script>

import Settings from "@/settings/settings_url";
import {HTTP} from '@/settings/axios'
import ComprehensiveTestingStudentCard from '../../../components/report/ct/ComprehensiveTestingStudentCard'

export default {
  name: "ComprehensiveTesting",
  data() {
    return {
      loading: false,
      printLoading : true,

      disciplines: [],
      group: {},
      groups: [],

      stud: [],
      studs: [],
    }
  },
  watch: {
    group: function () {
      this.getStudent()
      this.$store.commit("comp_testing_disciplines", [])
    },
    stud: function () {
      this.disciplines = this.$store.getters.comp_testing_disciplines
      console.log(this.stud.length)
      if(this.stud.length != 0)
        this.printLoading = false
      else if(this.stud.length == 0)
        this.printLoading = true
    },
  },

  methods: {
    clear(){
      this.stud = []
    },
    all(){
      this.stud = [...this.studs]
    },
    getGroups: function () {
      HTTP.get(Settings.API.URL_DEFAULT + "/groups")
          .then((response) => {
            this.groups = response.data
          })
          .catch(() => {

          })
          .finally(() => {

          })

    },
    getStudent: function () {
      if (this.group.id != undefined) {
        this.stud = []
        HTTP.get(Settings.API.URL_DEFAULT + "/groups/" + this.group.id + "/students")
            .then((response) => {
              // console.log(response.data)
              this.studs = response.data

            })
            .catch(() => {

            })
            .finally(() => {

            });
      }
    },
    printDocx: async function () {
      this.printLoading = true
      await HTTP.post(
          Settings.API.URL_DEFAULT + '/students/assessment-protocol-as-word',
          this.stud
      ).then((response) => {
        if(response.data == "" == false)
          window.open(Settings.API.URL_DEFAULT + "/students/get-file?path=" + response.data, '_blank')
      }).catch(error => {
        console.log(error.response)
      }).finally(() => {
        this.printLoading = false
      });
    },
    // getDisciplineStatistics: function() {
    //   this.disciplines = this.$store.getters.comp_testing_disciplines
    // },

  },
  created() {
    this.getGroups()
  },
  components:{
    ComprehensiveTestingStudentCard,
  },
}
</script>

<style lang="scss" scoped>
.container-kt {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 0 0 50px 0;
}
table {
  border: 1px solid black;
  width: 40%;
  border-spacing: 0;
}
td {
  padding: 5px;
  border: 1px solid black;
}
th {
  padding: 5px; /* Поля вокруг текста */
  border: 1px solid black; /* Граница вокруг ячеек */
}
.doc__header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-weight: 900;
  font-size: 1.3em;
  .doc__title{
    display: flex;
    justify-content: center;
    margin: 0 0 20px 0;
  }
}
</style>
