import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Setting from "../views/Setting.vue";
import Schedule from "../views/Schedule.vue";
import Report from "@/views/Report";
import GroupCard from "@/views/GroupCard";
import AdminJson from "../views/AdminJson";
import ClassEdit from "../views/ClassEdit";
import ScheduleEdit from "../views/ScheduleEdit";
import AudiencesType from "../views/AudiencesType";
import ScheduleType from "../views/ScheduleType";
import Audiences from "../views/Audiences";
import Disciplines from "../views/Disciplines";
import LessonTypes from "../views/LessonTypes";
import LessonTypeDays from "../views/LessonTypeDays";
import LessonNumbers from "../views/LessonNumbers";
import Login from "../views/Login";
import Journal from "../views/journal";
import Gradebook from "../views/Gradebook";
import ScheduleList from "../views/ScheduleList";
import JournalList from "../views/JournalList";
import GradeBookList from "../views/GradeBookList";
import Exam from "../views/Exam";
import ExamList from "../views/ExamList";
import ControlPoints from "../views/ControlPoints";
import ControlPointsList from "../views/ControlPointsList";
import StudentStatistics from "../views/StudentStatistics";
import AdminControlPoints from "../views/AdminControlPoints";
import FollowedUsersSetting from "../views/FollowedUsersSetting";
import SchedulePrint from "../views/SchedulePrint";
import EditScheduleTable from "../views/EditScheduleTable";
import JournalAll from "../views/JournalAll";
import Exemption from "../views/Exemption";
import InfoStudVisit from "../views/InfoVisit";
import Calendar from "../views/Calendar";
import TimeTable from "../views/TimeTable";
import Statistics from "../views/Statistics";
import ProfessorSchedule from "../views/ProfessorSchedule";
import StatisticsReport from "../views/StatisticsReport";
import EditControlPoints from "../views/EditControlPoints";
import ProductionCalendarEdit from "../views/ProductionCalendarEdit";
import TestV from "../views/TestV";
import LessonsCalendar from "../views/LessonsCalendar";
import ControlAddedLessons from "../views/ControlAddedLessons";
import ScheduleMain from "../views/ScheduleMain";
import ScheduleMain2 from "../views/ScheduleMain2";
import SubGroupEdit from "../views/SubGroupEdit";
import StatisticsDetails from "../views/StatisticsDetails";
// import ScheduleAll from "../views/ScheduleAll";
import ScheduleFor from "../views/ScheduleFor";
import ScheduleAll from "../views/scheduleBoard/ScheduleAll";
import AdminCertificate from "../views/certificate/AdminCertificate";
import Certificate from "../views/certificate/Certificate";
import OrderCertificate from "../views/certificate/OrderCertificate";

import OperatorQueue from "../views/OperatorQueue";
import FAQ from "../views/FAQ";
import TakeQueue from "../views/TakeQueue";
import Tablo from "../components/Tablo";
import SupportPRS from "../views/SupportPRS";
import InviteCodeForGroup from "../views/InviteCodeForGroup";
import ControlPointListAdmin from "../views/ControlPointListAdmin";
import ControlPointAdmin from "../views/ControlPointAdmin";
import WorkLoadAdmin from "../views/WorkLoadAdmin";
import DisciplineAdmin from "../views/DisciplineAdmin";
import LessonTypeAdmin from "../views/LessonTypeAdmin";
import ReTakeList from "../views/ReTakeList";
import RetakeVed from "../views/RetakeVed";
import Compensation from "../views/Compensation";

import Account from "../views/Account";
import RecordBook from "../views/RecordBook";
import MsTeamsStudent from "../views/msTeams/MsTeamsStudent";
import StatisticCoursesLms from "../views/ControlPoint/StatisticCoursesLms";
import ControlAddedLessonsDiff from "../views/ControlAddedLessonsDiff";
import ComprehensiveTesting from "../views/report/ct/ComprehensiveTesting";
import StudentStatisticsTransfer from "../views/admin/StudentStatisticsTransfer";
import CurrentLessons from "@/views/statistics/CurrentLessons";
import SpoGroups from "../views/qrcode/SpoGroups";
import OrionAdmin from "@/views/admin/OrionAdmin";
import LearningActivityEdit from "../views/learningActivity/LearningActivityEdit";
import LearningActivityCard from "../views/learningActivity/LearningActivityCard";
import LearningActivityStat from "../views/learningActivity/LearningActivityStat";
import VisitLog from "@/views/report/VisitLog";
import TeacherVisits from "@/views/statistics/TeacherVisits";
import AdminTeacherVisits from "@/views/admin/AdminTeacherVisits";
import CompensationPointsAdmin from "@/views/compensation/CompensationPointsAdmin";
import TestingForm from "@/components/testingForm/TestingForm";
// import AdminTestingForm from "@/views/admin/AdminTestingForm";
import AdminTeacherSatisfaction from "@/views/admin/AdminTeacherSatisfaction";
import StudentTicketPage from "@/views/visitTicket/StudentTicketPage";
import AdminTicketPage from "@/views/visitTicket/AdminTicketPage";
import TeacherTicketPage from "@/views/visitTicket/TeacherTicketPage";
import SheetAdmin from "@/views/admin/gradeSheet/SheetAdmin";
import TeacherPracticeVeds from "@/views/journal/TeacherPracticeVeds";
import TeacherCourseProjects from "@/views/journal/TeacherCourseProjects";
import AdminGroupLeader from "@/views/admin/AdminGroupLeader";
import SendSchedule from "@/views/lessonStream/SendSchedule";


Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/schedule",
    name: "Schedule",
    component: Schedule,
  },
  {
    path: "/send-schedule",
    name: "SendSchedule",
    component: SendSchedule,
  },
  {
    path: "/schedule-list",
    name: "ScheduleList",
    component: ScheduleList,
  },
  {
    path: "/report",
    name: "Report",
    component: Report,
  },
  {
    path: "/setting",
    name: "Setting",
    component: Setting,
  },
  {
    path: "/about",
    name: "About",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue"),
  },
  {
    path: "/groupcard",
    name: "GroupCard",
    component: GroupCard,
  },
  {
    path: "/adminjson",
    name: "AdminJson",
    component: AdminJson,
  },
  {
    path: "/classedit",
    name: "ClassEdit",
    component: ClassEdit,
  },
  {
    path: "/scheduleedit",
    name: "ScheduleEdit",
    component: ScheduleEdit,
  },
  {
    path: "/audiencestype",
    name: "AudiencesType",
    component: AudiencesType,
  },
  {
    path: "/scheduletype",
    name: "ScheduleType",
    component: ScheduleType,
  },
  {
    path: "/audiences",
    name: "Audiences",
    component: Audiences,
  },
  {
    path: "/disciplines",
    name: "Disciplines",
    component: Disciplines,
  },
  {
    path: "/lesson-types",
    name: "LessonTypes",
    component: LessonTypes,
  },
  {
    path: "/lesson-type-days",
    name: "LessonTypeDays",
    component: LessonTypeDays,
  },
  {
    path: "/lesson-numbers",
    name: "LessonNumbers",
    component: LessonNumbers,
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
  },
  {
    path: "/journal",
    name: "Journal",
    component: Journal,
  },
  {
    path: "/Journal-all",
    name: "JournalAll",
    component: JournalAll,
  },
  {
    path: "/journal-list",
    name: "JournalList",
    component: JournalList,
  },
  {
    path: "/gradebook",
    name: "Gradebook",
    component: Gradebook,
  },
  {
    path: "/gradebook-list",
    name: "GradeBookList",
    component: GradeBookList,
  },
  {
    path: "/exam",
    name: "Exam",
    component: Exam,
  },
  {
    path: "/exam-list",
    name: "ExamList",
    component: ExamList,
  },
  {
    path: "/control-points",
    name: "ControlPoints",
    component: ControlPoints,
  },
  {
    path: "/control-points-list",
    name: "ControlPointsList",
    component: ControlPointsList,
  },
  {
    path: "/student-statistics",
    name: "StudentStatistics",
    component: StudentStatistics,
  },
  {
    path: "/admin-control-points",
    name: "AdminControlPoints",
    component: AdminControlPoints,
  },
  {
    path: "/followed-users-setting",
    name: "FollowedUsersSetting",
    component: FollowedUsersSetting,
  },
  {
    path: "/schedule-print",
    name: "SchedulePrint",
    component: SchedulePrint,
  },
  {
    path: "/edit-schedule-table",
    name: "EditScheduleTable",
    component: EditScheduleTable,
  },
  {
    path: "/exemption",
    name: "Exemption",
    component: Exemption,
  },
  {
    path: "/info-visit",
    name: "InfoStudVisit",
    component: InfoStudVisit,
  },
  {
    path: "/calendar",
    name: "Calendar",
    component: Calendar,
  },
  {
    path: "/timetable",
    name: "TimeTable",
    component: TimeTable,
  },
  {
    path: "/statistics",
    name: "Statistics",
    component: Statistics,
  },
  {
    path: "/prof-schedule",
    name: "ProfessorSchedule",
    component: ProfessorSchedule,
  },
  {
    path: "/statistics-report",
    name: "StatisticsReport",
    component: StatisticsReport,
  },
  {
    path: "/edit-control-points",
    name: "EditControlPoints",
    component: EditControlPoints,
  },
  {
    path: "/production-calendar-edit",
    name: "ProductionCalendarEdit",
    component: ProductionCalendarEdit,
  },
  {
    path: "/test-v",
    name: "TestV",
    component: TestV,
  },
  {
    path: "/lessons-calendar",
    name: "LessonsCalendar",
    component: LessonsCalendar,
  },
  {
    path: "/control-added-lessons",
    name: "ControlAddedLessons",
    component: ControlAddedLessons,
  },
  {
    path: "/control-added-lessons-diff",
    name: "ControlAddedLessonsDiff",
    component: ControlAddedLessonsDiff,
  },
  {
    path: "/schedule-main",
    name: "ScheduleMain",
    component: ScheduleMain,
  },
  {
    path: "/schedule-main2",
    name: "ScheduleMain2",
    component: ScheduleMain2,
  },
  {
    path: "/sub-group-edit",
    name: "SubGroupEdit",
    component: SubGroupEdit,
  },
  {
    path: "/statistics-details",
    name: "StatisticsDetails",
    component: StatisticsDetails,
  },
  // {
  //     path: '/schedule-all',
  //     name: 'ScheduleAll',
  //     component: ScheduleAll,
  // },
  {
    path: "/schedule-for",
    name: "ScheduleFor",
    component: ScheduleFor,
  },
  {
    path: "/schedule-board/schedule-all",
    name: "ScheduleAll",
    component: ScheduleAll,
  },
  {
    path: "/certificate/admin-certificate",
    name: "AdminCertificate",
    component: AdminCertificate,
  },
  {
    path: "/certificate/certificate",
    name: "Certificate",
    component: Certificate,
  },
  {
    path: "/certificate/order-certificate",
    name: "OrderCertificate",
    component: OrderCertificate,
  },
  {
    path: "/queue/operator-queue",
    name: "OperatorQueue",
    component: OperatorQueue,
  },

  {
    path: "/queue/take-queue",
    name: "TakeQueue",
    component: TakeQueue,
  },

  {
    path: "/faq",
    name: "FAQ",
    component: FAQ,
  },
  {
    path: "/queue/tablo",
    name: "Tablo",
    component: Tablo,
  },
  {
    path: "/support-prs",
    name: "SupportPRS",
    component: SupportPRS,
  },
  {
    path: "/invite-code-for-group",
    name: "InviteCodeForGroup",
    component: InviteCodeForGroup,
  },
  {
    path: "/control-point-list-admin",
    name: "ControlPointListAdmin",
    component: ControlPointListAdmin,
  },
  {
    path: "/control-point-admin",
    name: "ControlPointAdmin",
    component: ControlPointAdmin,
  },
  {
    path: "/workLoad-admin",
    name: "WorkLoadAdmin",
    component: WorkLoadAdmin,
  },
  {
    path: "/discipline-admin",
    name: "DisciplineAdmin",
    component: DisciplineAdmin,
  },
  {
    path: "/lesson-type-admin",
    name: "LessonTypeAdmin",
    component: LessonTypeAdmin,
  },
  {
    path: "/retake-list",
    name: "ReTakeList",
    component: ReTakeList,
  },
  {
    path: "/retake-ved",
    name: "RetakeVed",
    component: RetakeVed,
  },
  {
    path: "/compensation-admin",
    name: "Compensation",
    component: Compensation,
  },
  {
    path: "/recordbook",
    name: "RecordBook",
    component: RecordBook,
  },

  {
    path: "/ms-teams/student",
    name: "MsTeamsStudent",
    component: MsTeamsStudent,
  },
  {
    path: "/control-point/statistic-courses-lms",
    name: "StatisticCoursesLms",
    component: StatisticCoursesLms,
  },

  {
    path: "/account",
    name: "Account",
    component: Account,
  },
  {
    path: "/report/ct/comprehensive-testing",
    name: "ComprehensiveTesting",
    component: ComprehensiveTesting,
  },
  {
    path: "/admin/student-statistics-transfer",
    name: "StudentStatisticsTransfer",
    component: StudentStatisticsTransfer,
  },
  {
    path: "/statistics/current-lessons",
    name: "CurrentLessons",
    component: CurrentLessons,
  },
  {
    path: "/qrcode/spo-groups",
    name: "SpoGroups",
    component: SpoGroups,
  },
  {
    path: "/admin/user-linking",
    name: "OrionAdmin",
    component: OrionAdmin,
  },
  {
    path: "/learning-Activity/edit",
    name: "LearningActivityEdit",
    component: LearningActivityEdit,
  },
  {
    path: "/learning-Activity/stat",
    name: "LearningActivityStat",
    component: LearningActivityStat,
  },
  {
    path: "/learning-Activity/card",
    name: "LearningActivityCard",
    component: LearningActivityCard,
  },
  {
    path: "/visit-log",
    name: "VisitLog",
    component: VisitLog,
  },
  {
    path: "/journal-teacher",
    name: "TeacherVisits",
    component: TeacherVisits,
  },
  {
    path: "/admin-teacher-visits",
    name: "AdminTeacherVisits",
    component: AdminTeacherVisits,
  },
  {
    path: "/admin/compensation-points",
    name: "CompensationPointsAdmin",
    component: CompensationPointsAdmin,
  },
  {
    path: "/testing-form",
    name: "TestingForm",
    component: TestingForm,
  },
  {
    path: "/admin/teacher-satisfaction",
    name: "AdminTeacherSatisfaction",
    component: AdminTeacherSatisfaction,
  },
  {
    path: "/student/visit-ticket",
    name: "StudentTicketPage",
    component: StudentTicketPage,
  },
  {
    path: "/admin/visit-ticket",
    name: "AdminTicketPage",
    component: AdminTicketPage,
  },
  {
    path: "/teacher/visit-ticket",
    name: "TeacherTicketPage",
    component: TeacherTicketPage,
  },
  {
    path: "/admin/sheet",
    name: "SheetAdmin",
    component: SheetAdmin,
  },
  {
    path: "/practice-sheets",
    name: "TeacherPracticeVeds",
    component: TeacherPracticeVeds,
  },
  {
    path: "/course-project-sheets",
    name: "TeacherCourseProjects",
    component: TeacherCourseProjects,
  },
  {
    path: "/admin/group-leader",
    name: "AdminGroupLeader",
    component: AdminGroupLeader,
  },

  // {
  //     path: '/admin/testing-form',
  //     name: 'AdminTestingForm',
  //     component: AdminTestingForm
  // },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
