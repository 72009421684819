<template>


  <v-container fluid>

  <div style="text-align: center; margin-bottom: 10px; font-size: 200%;">
    <h1>Институт права. Расписание на 15.04.2021 года. </h1>
  </div>

    <v-layout align-start justify-start  row wrap >
      <v-flex class="schedule-rows"> <div class="divider-curse">1 Курс</div></v-flex>
      <v-flex   v-for="(item, i) in item_card" :key="i">
        <v-hover>
          <template v-slot="{ hover }">

            <div>
              <v-card
                  :loading="loading"
                  :elevation="hover ? 24 : 6"

                  class="my-3 mx-3 card-main-schedule"
              >
                <template slot="progress">
                  <v-progress-linear
                      color="deep-purple"
                      height="10"
                      indeterminate
                  ></v-progress-linear>
                </template>
                <div>
                  <!--   :height="heightTable()"-->
                  <v-data-table
                      :headers="scheduleGrid.headers"
                      :items="scheduleGrid.items"
                      :items-per-page="80"
                      :hide-default-footer="true"
                      :hide-default-header="true"
                      class="elevation-2"

                  >

                    <template v-slot:header="{ props }">
                      <thead class="v-data-table-header">
                      <tr>
                        <th class="text-center  th-date-main-schedule" v-for="head in props.headers" :key="head.value">
                          <h3>{{ head.text }}</h3>
                        </th>
                      </tr>
                      </thead>
                    </template>

                    <template v-slot:item="{ item  }">
                      <tr v-for="(subitem, iSub) in item.subs" :key="iSub">
<!--                        <td v-if="iSub === 0" :rowspan="item.subs.length" class="td-date-main-schedule">-->
<!--                          <div class="content-date-main-schedule">-->
<!--                            {{ item.date }}-->
<!--                          </div>-->
<!--                        </td>-->
                        <td class="td-sub-main-schedule time">
                          <div class="content-sub-main-schedule">
                            {{ subitem.time }}
                          </div>
                        </td>
                        <td class="td-sub-main-schedule pair">
                          <div class="content-sub-main-schedule discipline">
                            {{ subitem.discipline }}
                          </div>
                          <div class="content-sub-main-schedule type">
                            ({{ subitem.type }})
                          </div>
                          <div class="content-sub-main-schedule">
                            <span v-for="teacher in subitem.teachers" :key="teacher">{{teacher}}, </span>
                          </div>
                          <div class="content-sub-main-schedule">
                            Аудитория: <span v-for="audience in subitem.audiences" :key="audience">{{audience}}, </span>
                          </div>
                        </td>
                      </tr>
                    </template>

                    <!--      <template v-slot:item="{ item  }">-->

                    <!--        <tr>-->
                    <!--          <td-->
                    <!--              v-for="header in scheduleGrid.headers"-->
                    <!--              :key="header.text"-->
                    <!--          >-->
                    <!--            {{ item[header.value] }}-->
                    <!--          </td>-->

                    <!--        </tr>-->

                    <!--      </template>-->

                  </v-data-table>
                </div>


              </v-card>

            </div>

          </template>
        </v-hover>
      </v-flex>

      <v-flex class="schedule-rows"> <div class="divider-curse">2 Курс</div></v-flex>

      <v-flex v-for="(item, i) in item_card2" :key="i">
        <v-hover>
          <template v-slot="{ hover }">

            <div>
              <v-card
                  :loading="loading"
                  :elevation="hover ? 24 : 6"

                  class="my-3 mx-3 card-main-schedule"
              >
                <template slot="progress">
                  <v-progress-linear
                      color="deep-purple"
                      height="10"
                      indeterminate
                  ></v-progress-linear>
                </template>
                <div>
                  <!--   :height="heightTable()"-->
                  <v-data-table
                      :headers="scheduleGrid.headers"
                      :items="scheduleGrid.items"
                      :items-per-page="80"
                      :hide-default-footer="true"
                      :hide-default-header="true"
                      class="elevation-2"

                  >

                    <template v-slot:header="{ props }">
                      <thead class="v-data-table-header">
                      <tr>
                        <th class="text-center  th-date-main-schedule" v-for="head in props.headers" :key="head.value">
                          <h3>{{ head.text }}</h3>
                        </th>
                      </tr>
                      </thead>
                    </template>

                    <template v-slot:item="{ item  }">
                      <tr v-for="(subitem, iSub) in item.subs" :key="iSub">
                        <!--                        <td v-if="iSub === 0" :rowspan="item.subs.length" class="td-date-main-schedule">-->
                        <!--                          <div class="content-date-main-schedule">-->
                        <!--                            {{ item.date }}-->
                        <!--                          </div>-->
                        <!--                        </td>-->
                        <td class="td-sub-main-schedule time">
                          <div class="content-sub-main-schedule">
                            {{ subitem.time }}
                          </div>
                        </td>
                        <td class="td-sub-main-schedule pair">
                          <div class="content-sub-main-schedule discipline">
                            {{ subitem.discipline }}
                          </div>
                          <div class="content-sub-main-schedule type">
                            ({{ subitem.type }})
                          </div>
                          <div class="content-sub-main-schedule">
                            <span v-for="teacher in subitem.teachers" :key="teacher">{{teacher}}, </span>
                          </div>
                          <div class="content-sub-main-schedule">
                            Аудитория: <span v-for="audience in subitem.audiences" :key="audience">{{audience}}, </span>
                          </div>
                        </td>
                      </tr>
                    </template>

                    <!--      <template v-slot:item="{ item  }">-->

                    <!--        <tr>-->
                    <!--          <td-->
                    <!--              v-for="header in scheduleGrid.headers"-->
                    <!--              :key="header.text"-->
                    <!--          >-->
                    <!--            {{ item[header.value] }}-->
                    <!--          </td>-->

                    <!--        </tr>-->

                    <!--      </template>-->

                  </v-data-table>
                </div>


              </v-card>

            </div>

          </template>
        </v-hover>
      </v-flex>
      <v-flex class="schedule-rows"> <div class="divider-curse">3 Курс</div></v-flex>
      <v-flex v-for="(item, i) in item_card3" :key="i">
        <v-hover>
          <template v-slot="{ hover }">

            <div>
              <v-card
                  :loading="loading"
                  :elevation="hover ? 24 : 6"

                  class="my-3 mx-3 card-main-schedule"
              >
                <template slot="progress">
                  <v-progress-linear
                      color="deep-purple"
                      height="10"
                      indeterminate
                  ></v-progress-linear>
                </template>
                <div>
                  <!--   :height="heightTable()"-->
                  <v-data-table
                      :headers="scheduleGrid.headers"
                      :items="scheduleGrid.items"
                      :items-per-page="80"
                      :hide-default-footer="true"
                      :hide-default-header="true"
                      class="elevation-2"

                  >

                    <template v-slot:header="{ props }">
                      <thead class="v-data-table-header">
                      <tr>
                        <th class="text-center  th-date-main-schedule" v-for="head in props.headers" :key="head.value">
                          <h3>{{ head.text }}</h3>
                        </th>
                      </tr>
                      </thead>
                    </template>

                    <template v-slot:item="{ item  }">
                      <tr v-for="(subitem, iSub) in item.subs" :key="iSub">
                        <!--                        <td v-if="iSub === 0" :rowspan="item.subs.length" class="td-date-main-schedule">-->
                        <!--                          <div class="content-date-main-schedule">-->
                        <!--                            {{ item.date }}-->
                        <!--                          </div>-->
                        <!--                        </td>-->
                        <td class="td-sub-main-schedule time">
                          <div class="content-sub-main-schedule">
                            {{ subitem.time }}
                          </div>
                        </td>
                        <td class="td-sub-main-schedule pair">
                          <div class="content-sub-main-schedule discipline">
                            {{ subitem.discipline }}
                          </div>
                          <div class="content-sub-main-schedule type">
                            ({{ subitem.type }})
                          </div>
                          <div class="content-sub-main-schedule">
                            <span v-for="teacher in subitem.teachers" :key="teacher">{{teacher}}, </span>
                          </div>
                          <div class="content-sub-main-schedule">
                            Аудитория: <span v-for="audience in subitem.audiences" :key="audience">{{audience}}, </span>
                          </div>
                        </td>
                      </tr>
                    </template>

                    <!--      <template v-slot:item="{ item  }">-->

                    <!--        <tr>-->
                    <!--          <td-->
                    <!--              v-for="header in scheduleGrid.headers"-->
                    <!--              :key="header.text"-->
                    <!--          >-->
                    <!--            {{ item[header.value] }}-->
                    <!--          </td>-->

                    <!--        </tr>-->

                    <!--      </template>-->

                  </v-data-table>
                </div>


              </v-card>

            </div>

          </template>
        </v-hover>
      </v-flex>
      <v-flex class="schedule-rows"> <div class="divider-curse">4 Курс</div></v-flex>
      <v-flex v-for="(item, i) in item_card4" :key="i">
        <v-hover>
          <template v-slot="{ hover }">

            <div>
              <v-card
                  :loading="loading"
                  :elevation="hover ? 24 : 6"

                  class="my-3 mx-3 card-main-schedule"
              >
                <template slot="progress">
                  <v-progress-linear
                      color="deep-purple"
                      height="10"
                      indeterminate
                  ></v-progress-linear>
                </template>
                <div>
                  <!--   :height="heightTable()"-->
                  <v-data-table
                      :headers="scheduleGrid.headers"
                      :items="scheduleGrid.items"
                      :items-per-page="80"
                      :hide-default-footer="true"
                      :hide-default-header="true"
                      class="elevation-2"

                  >

                    <template v-slot:header="{ props }">
                      <thead class="v-data-table-header">
                      <tr>
                        <th class="text-center  th-date-main-schedule" v-for="head in props.headers" :key="head.value">
                          <h3>{{ head.text }}</h3>
                        </th>
                      </tr>
                      </thead>
                    </template>

                    <template v-slot:item="{ item  }">
                      <tr v-for="(subitem, iSub) in item.subs" :key="iSub">
                        <!--                        <td v-if="iSub === 0" :rowspan="item.subs.length" class="td-date-main-schedule">-->
                        <!--                          <div class="content-date-main-schedule">-->
                        <!--                            {{ item.date }}-->
                        <!--                          </div>-->
                        <!--                        </td>-->
                        <td class="td-sub-main-schedule time">
                          <div class="content-sub-main-schedule">
                            {{ subitem.time }}
                          </div>
                        </td>
                        <td class="td-sub-main-schedule pair">
                          <div class="content-sub-main-schedule discipline">
                            {{ subitem.discipline }}
                          </div>
                          <div class="content-sub-main-schedule type">
                            ({{ subitem.type }})
                          </div>
                          <div class="content-sub-main-schedule">
                            <span v-for="teacher in subitem.teachers" :key="teacher">{{teacher}}, </span>
                          </div>
                          <div class="content-sub-main-schedule">
                            Аудитория: <span v-for="audience in subitem.audiences" :key="audience">{{audience}}, </span>
                          </div>
                        </td>
                      </tr>
                    </template>

                    <!--      <template v-slot:item="{ item  }">-->

                    <!--        <tr>-->
                    <!--          <td-->
                    <!--              v-for="header in scheduleGrid.headers"-->
                    <!--              :key="header.text"-->
                    <!--          >-->
                    <!--            {{ item[header.value] }}-->
                    <!--          </td>-->

                    <!--        </tr>-->

                    <!--      </template>-->

                  </v-data-table>
                </div>


              </v-card>

            </div>

          </template>
        </v-hover>
      </v-flex>
      <v-flex class="schedule-rows"> <div class="divider-curse">5 Курс</div></v-flex>
      <v-flex v-for="(item, i) in item_card5" :key="i">
        <v-hover>
          <template v-slot="{ hover }">

            <div>
              <v-card
                  :loading="loading"
                  :elevation="hover ? 24 : 6"

                  class="my-3 mx-3 card-main-schedule"
              >
                <template slot="progress">
                  <v-progress-linear
                      color="deep-purple"
                      height="10"
                      indeterminate
                  ></v-progress-linear>
                </template>
                <div>
                  <!--   :height="heightTable()"-->
                  <v-data-table
                      :headers="scheduleGrid.headers"
                      :items="scheduleGrid.items"
                      :items-per-page="80"
                      :hide-default-footer="true"
                      :hide-default-header="true"
                      class="elevation-2"

                  >
                    <template v-slot:header="{ props }">
                      <thead class="v-data-table-header">
                      <tr>
                        <th class="text-center  th-date-main-schedule" v-for="head in props.headers" :key="head.value">
                          <h3>{{ head.text }}</h3>
                        </th>
                      </tr>
                      </thead>
                    </template>

                    <template v-slot:item="{ item  }">
                      <tr v-for="(subitem, iSub) in item.subs" :key="iSub">
                        <!--                        <td v-if="iSub === 0" :rowspan="item.subs.length" class="td-date-main-schedule">-->
                        <!--                          <div class="content-date-main-schedule">-->
                        <!--                            {{ item.date }}-->
                        <!--                          </div>-->
                        <!--                        </td>-->
                        <td class="td-sub-main-schedule time">
                          <div class="content-sub-main-schedule">
                            {{ subitem.time }}
                          </div>
                        </td>
                        <td class="td-sub-main-schedule pair">
                          <div class="content-sub-main-schedule discipline">
                            {{ subitem.discipline }}
                          </div>
                          <div class="content-sub-main-schedule type">
                            ({{ subitem.type }})
                          </div>
                          <div class="content-sub-main-schedule">
                            <span v-for="teacher in subitem.teachers" :key="teacher">{{teacher}}, </span>
                          </div>
                          <div class="content-sub-main-schedule">
                            Аудитория: <span v-for="audience in subitem.audiences" :key="audience">{{audience}}, </span>
                          </div>
                        </td>
                      </tr>
                    </template>

                    <!--      <template v-slot:item="{ item  }">-->

                    <!--        <tr>-->
                    <!--          <td-->
                    <!--              v-for="header in scheduleGrid.headers"-->
                    <!--              :key="header.text"-->
                    <!--          >-->
                    <!--            {{ item[header.value] }}-->
                    <!--          </td>-->

                    <!--        </tr>-->

                    <!--      </template>-->

                  </v-data-table>
                </div>


              </v-card>

            </div>

          </template>
        </v-hover>
      </v-flex>
    </v-layout>
  </v-container>



</template>

<script>
export default {
  name: "ScheduleMain",
  data() {
    return {
      scheduleGrid: {
        headers: [
          // {text: 'Дата', align: 'center', sortable: true, value: 'date', divider: true, class: "bolt"},
          {text: 'Время', align: 'center', value: 'subs', divider: true, sortable: true, class: "bolt"},
          {text: 'ПОНБуг11', value: 'subs', divider: true, align: 'center', class: "bolt"}
        ],
        items: [
          {
            date: '12',
            subs: [
              {
                time: '08:30',
                discipline: 'Административно-правовое регулирование миграционных процессов в зарубежных странах',
                type: 'Лекция',
                teachers: ['Петров П.П', 'Иванов И.И', 'Васильев В.В',],
                audiences: ['101E', '125Д', '425E'],
              },
              {
                time: '10:15',
                discipline: 'Административно-правовое регулирование миграционных процессов в зарубежных странах',
                type: 'Лекция',
                teachers: ['Петров П.П', 'Иванов И.И', 'Васильев В.В',],
                audiences: ['101E', '125Д', '425E'],
              },
              {
                time: '12:20',
                discipline: 'Административно-правовое регулирование миграционных процессов в зарубежных странах',
                type: 'Лекция',
                teachers: ['Петров П.П', 'Иванов И.И', 'Васильев В.В',],
                audiences: ['101E', '125Д', '425E'],
              },
              {
                time: '14:05',
                discipline: 'Административно-правовое регулирование миграционных процессов в зарубежных странах',
                type: 'Лекция',
                teachers: ['Петров П.П', 'Иванов И.И', 'Васильев В.В',],
                audiences: ['101E', '125Д', '425E'],
              }
            ]
          },
        ],
      },
      item_card: [
          1,2,3,4,5,6,7,8,9,10,12,13,
      ],
      item_card2: [
        1,2,3,4,5,6,7,8,9,10,12,13,14,15,16,17
      ],
      item_card3: [
        1,2,3,4,5,6,7,8,9,10,
      ],
      item_card4: [
       1,2,3,4,5,6,7,8,9,10,12,13,14,15,16,17,18,19,20
      ],
      item_card5: [
        1,2,3,4,5,6,7,8,9,10,12,13,14,15,16,17,18
      ],
    }
  },
  methods: {
    getRowspan(item) {
      return item.sub.length
    }
  },
}
</script>

<style scoped>
.td-date-main-schedule {
  padding: 0px !important;
  width: 40px !important;
}

.th-date-main-schedule {
  font-weight: bold !important;
  color: #0f0f0f !important;
}


.content-date-main-schedule {
  padding: 10px;
  font-weight: bold;
}

.td-sub-main-schedule {
  padding: 0px !important;

}

.td-sub-main-schedule.time {
  width: 40px !important;
  border: thin solid rgba(0,0,0,.12);
  font-weight: bold;
}


.content-sub-main-schedule {
  padding-left: 10px;
  margin: 0px;
}

.content-sub-main-schedule.discipline {
text-align: center;
}

.content-sub-main-schedule.type {
  text-align: center;
}

.card-main-schedule {
  width: 350px;
}
.divider-curse {
  color: white;
  text-align: center;
  background-color: #0f0f0f;
  /*text-align: center;*/
  font-weight: bold;
  /*transform: rotate(-90deg);*/
  /*transform-origin: bottom center;*/
  writing-mode: vertical-rl;
  height: 450px;
  margin-left: -13px;
  margin-right: -13px;
  margin-top: 55px;
}



.child-flex>*, .flex {
flex: 0 0 auto !important;
}

</style>

