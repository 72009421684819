<template>
  <v-container>
    <template v-if="subject.id != null">
      <div class="center-on-print">
        <Schedule
            :scheduleBoardFor="scheduleBoardFor"
            :subject="subject"
            :date="date"
            scheduleWeek="CURRENT"
            :user="user"
        />
      </div>
      <div class="mt-4 print-break-page center-on-print">
        <Schedule
            :scheduleBoardFor="scheduleBoardFor"
            :subject="subject"
            :date="date"
            scheduleWeek="NEXT"
            :user="user"
        />
      </div>
    </template>
  </v-container>
</template>

<script>
// import Settings from "@/settings/settings_url";
// import {HTTP} from '@/settings/axios'
import Schedule from '@/components/scheduleBoard/Schedule';

export default {
  name: "ScheduleTwoWeek",
  components: {
    Schedule,
  },
  props:{
    scheduleBoardFor: String,
    subject: Object,
    date: String,
    user: Object,
    audience: Number,
  },
  data() {
    return {

    }
  },
  methods:{
  },
  created() {
  }
}
</script>

<style>
@media print {
  .print-break-page {
    page-break-before: always !important;
  }
  body {
    overflow: visible;
  }
  .center-on-print {
    display: flex;
    justify-content: center;
  }
  /*div {*/
  /*  display: inline;*/
  /*}*/
}
</style>