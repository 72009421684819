<template>
  <div id="app">
    <h1></h1>
  </div>
</template>


<script>
import Settings from "@/settings/settings_url";
// import {HTTP} from '@/settings/axios'

export default {
  name: "app",
  data: () => ({
    //token: "",
    EditUserToken: {
      get() {
        return this.$store.getters.user_token;
      },
      set(value) {
        this.$store.commit('chekUserToken', value);
      }
    },
  }),
  methods: {
    setTokenUser(token) {
      if (token !== undefined && token !== null) {
        localStorage.setItem('AuthorizationToken', token)
      }
    },
    setTokenAxios() {

      let token = localStorage.getItem("AuthorizationToken");
      if (token !== undefined && token !== null) {
        let config = {
          headers: {
            'Authorization': token
          }
        }
        this.axios.get(Settings.API.URL_DEFAULT + "/auth/auth", config)
            .then(() => {
              document.location.href = '/'
            })
        //this.axios.defaults.headers.common['Authorization'] = token
        // HTTP.get(Settings.API.URL_DEFAULT + "/auth/auth")
        //     .then(()=> {
        //       document.location.href = '/schedule'
        //     })
      }
    },

  },

  // computed: {
  //    user_token: {
  //      get(){
  //        return this.$store.getters.user_token(this.$route.query.user);
  //      }
  //    }
  // },
  // async mounted() {
  //   this.$store.dispatch("infoToken")
  //
  // }

  created() {
    this.setTokenUser(this.$route.query.token);
    setTimeout(() => {
      this.setTokenAxios()
    },500)
    //this.EditUserToken = this.$route.query.token;
    //this.setTokenUser()
    //localStorage.setItem('someNumber', 2222)
    //this.getTokenUser()
  }
}
</script>
