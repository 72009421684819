<template>
  <v-dialog v-model="dialog"
            max-width="600px">
    <v-card>
      <v-toolbar
          dark
          color="primary"
      >
        <v-btn
            icon
            dark
            @click="closeDialog()"
        >
          <v-icon>close</v-icon>
        </v-btn>
        <v-card-title>Восстановить баллы с дисциплины</v-card-title>
      </v-toolbar>
      <v-progress-linear
          color="primary"
          :indeterminate="isRestoring"
          :active="isRestoring"
      />
      <v-container>
        <v-card-subtitle v-if="discipline !== null && maxPoints > 0">
          <v-alert type="info" color="primary">
            Можно восстановить баллов: {{ maxPoints }} шт.
          </v-alert>
        </v-card-subtitle>
        <v-card-text>
          <v-form
              ref="pointsForm"
              v-model="valid"
              >
            <v-autocomplete
                v-model="discipline"
                :items="disciplines"
                outlined
                dense
                item-text="name"
                item-value="id"
                return-object
                label="Дисциплина"
                required
            />
            <v-text-field
                v-show="discipline !== null"
                v-model="pointsToRestore"
                label="Введите количество баллов"
                :hint="'Максимум можно восстановить ' + maxPoints + ' баллов'"
                :rules="pointsToRestoreRules"
                :disabled="maxPoints !== null && maxPoints <= 0"
                persistent-hint
                type="number"
                required
            />
            <v-text-field
                v-show="discipline !== null"
                v-model="comment"
                label="Комментарий (необязательно)"
                :disabled="maxPoints !== null && maxPoints <= 0"
                persistent-hint
                required
            />
          </v-form>
        </v-card-text>
        <v-card-subtitle>
          <v-alert type="error" v-if="maxPoints !== null && maxPoints <= 0">
            По данной дисциплине не было распределено баллов!
          </v-alert>
          <v-alert color="info" type="warning" v-if="!!discipline && maxPoints !== null && maxPoints > 0">
            Внимание! Во время сессии убедитесь, что по предмету еще не было проведено экзаменов!
          </v-alert>
        </v-card-subtitle>
        <v-divider/>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              text
              color="secondary"
              @click="closeDialog()"
          >
            Закрыть
          </v-btn>
          <v-btn
              text
              :disabled="!valid || isRestoring"
              :loading="isRestoring"
              color="primary"
              @click=restorePoints()
          >
            Восстановить
          </v-btn>
        </v-card-actions>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import Settings from "@/settings/settings_url";
import {HTTP} from '@/settings/axios'

export default {
  name: "CompensationTransfer",
  data(){
    return {
      dialog: false,
      disciplines: [],
      discipline: null,
      pointsToRestore: null,
      maxPoints: null,
      pointsToRestoreRules: [
        v => !!v || 'Это поле обязательно',
        v => v > 0 || 'Введите больше 0 баллов',
        v => v <= this.maxPoints || 'Максимум можно восстановить ' + this.maxPoints + ' баллов'
      ],
      valid: false,
      isRestoring: false,
      comment: null,
    }
  },
  methods:{
    restorePoints: async function() {
      this.isRestoring = true

      HTTP.post(Settings.API.URL_DEFAULT + "/compensation-points-balance/restore-points", {
        "balance": this.balance,
        "discipline": this.discipline,
        "halfYearId": this.halfYearId,
        "yearOfStudyId": this.yearOfStudyId,
        "points": this.pointsToRestore,
        "user": this.$store.getters.selectedUser,
        "comment": this.comment
      })
          .then((response) => {
            this.balance = response.data
            this.$emit('updateBalance', this.balance)
            this.$emit('success', 'Баллы успешно восстановлены')
          })
          .catch(() => {
            this.$emit('error')
          })
          .finally(() => {
            this.closeDialog()
            this.isRestoring = false
          });
    },
    closeDialog: function() {
      this.$refs.pointsForm.reset()
      this.$refs.pointsForm.resetValidation()
      this.dialog = false
    },
    getDisciplines() {
      HTTP.get(Settings.API.URL_DEFAULT + "/disciplines/by-groupid?groupid="
          + this.balance.student.groups.id)
          .then((response) => {
            this.disciplines = response.data
          })
          .catch(() => {

          })
          .finally(() => {

          })
    },
    getDisciplineCompPoints() {
      HTTP.get(Settings.API.URL_DEFAULT
          + "/compensation-points/journal/get-spent-points?studentId="
          + this.balance.student.id
          + "&disciplineId=" + this.discipline.id
          + "&halfYearId=" + this.halfYearId
          + "&yearOfStudyId=" + this.yearOfStudyId)
          .then((response) => {
            this.maxPoints = response.data
            if(this.maxPoints === "" || this.maxPoints === null)
              this.maxPoints = 0
            else
              this.maxPoints *= -1
          })
          .catch(() => {

          })
          .finally(() => {

          })
    },
  },
  created() {
    // this.getDisciplines()
  },
  props: {
    student: {},
    balance: {},
    halfYearId: {
      type: Number,
      default: -1
    },
    yearOfStudyId: {
      type: Number,
      default: -1
    },
  },
  watch: {
    'balance' () {
      if(this.balance !== null)
        this.getDisciplines()
    },
    'discipline'() {
      this.getDisciplineCompPoints()
      this.pointsToRestore = null
    },
  }
}
</script>

<style scoped>

</style>