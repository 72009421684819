<template>
    <v-row justify="center">
      <v-expansion-panels popout>
        <v-expansion-panel
            v-for="item in groupCertificates"
            :key="item.id"
        >
          <v-expansion-panel-header>{{item.title}}</v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-divider></v-divider>
            <div class="ma-4">{{item.description}}</div>
            <v-divider></v-divider>
            <type-certificate-user-list
                class="mt-4"
                :groupCertificate="item"
                @requestTypeCertificate="requestTypeCertificate"
            >
            </type-certificate-user-list>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-row>
</template>

<script>
import TypeCertificateUserList from "./TypeCertificateUserList";

export default {
  name: "GroupCertificateList",
  components:{
    TypeCertificateUserList,
  },
  props: {
    groupCertificates: {
      type: Array,
      required: true,
    }
  },
  data() {
    return {

    }
  },
  methods:{
    requestTypeCertificate(item){
      this.$emit("requestTypeCertificate", item)
    },
  },
}
</script>

<style scoped>

</style>