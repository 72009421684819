import { render, staticRenderFns } from "./CompensationTypeEdit.vue?vue&type=template&id=3521b3cb&scoped=true&"
import script from "./CompensationTypeEdit.vue?vue&type=script&lang=js&"
export * from "./CompensationTypeEdit.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3521b3cb",
  null
  
)

export default component.exports