<template>
  <v-container v-show="isGroupLeader()">
    <v-card-title class="d-flex justify-center text-center"><h2>Служебные записки</h2></v-card-title>
    <v-container>
      <create-ticket-page ref="createPage" @ticketCreated="updateHistory"/>
    </v-container>

    <v-container/>
    <v-divider/>
    <ticket-history
        ref="history"
        history-for="STUDENT"
    />
  </v-container>
</template>

<script>
import TicketHistory from "@/components/visitTicket/TicketHistory";
import CreateTicketPage from "@/components/visitTicket/CreateTicketPage";

export default {
  name: "StudentTicketPage",
  components: {CreateTicketPage, TicketHistory},
  data() {
    return {

    }
  },

  methods: {
    updateHistory() {
      this.$refs.history.getTickets()
    },
    isGroupLeader() {
      return this.isRole("ROLE_GROUP_LEADER")
    },
    isTeacher() {
      return this.isRole("ROLE_TEACHER")
    },
    isAdmin() {
      return this.isRole("ROLE_ADMIN")
    },
    isRole(role) {
      if (this.$store.getters.selectedUser?.role != null && this.$store.getters.selectedUser?.role.length > 0){
        for (let i = 0; i < this.$store.getters.selectedUser?.role.length; i++){
          if (
              this.$store.getters.selectedUser?.role[i].name === role
          ){
            return true
          }
        }
      }else {
        return  false
      }
    },
  },

  watch: {
    '$store.getters.selectedStudent.groups'() {
      if(this.$store.getters.selectedStudent.groups) {
        this.$refs.createPage.selectedGroup = this.$store.getters.selectedStudent.groups.id
        this.$refs.history.groupId = this.$store.getters.selectedStudent.groups.id
      }
    },
  },

  mounted() {
    if(this.$store.getters.selectedStudent && this.$store.getters.selectedStudent.groups) {
      this.$refs.createPage.selectedGroup = this.$store.getters.selectedStudent.groups.id
      this.$refs.history.groupId = this.$store.getters.selectedStudent.groups.id
    }
  },

  props: {
    admin: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style scoped>

</style>