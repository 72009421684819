<template>
  <v-container>
    <h1 class="text-center">Пользователи LMS</h1>
    <v-row align="center" justify="center">
      <v-col cols="2">
        <v-text-field
            :disabled="disabled"
            v-model="usernameFilter"
            clearable
            label="Логин"
            @keydown.enter="getLmsUsers"
        />
      </v-col>
      <v-col cols="2">
        <v-text-field
            :disabled="disabled"
            v-model="familyNameFilter"
            clearable
            label="Фамилия"
            @keydown.enter="getLmsUsers"
        />
      </v-col>
      <v-col cols="2">
        <v-text-field
            :disabled="disabled"
            v-model="nameFilter"
            clearable
            label="Имя"
            @keydown.enter="getLmsUsers"
        />
      </v-col>
      <v-col cols=2>
        <v-text-field
            :disabled="disabled"
            v-model="patronymicFilter"
            clearable
            label="Отчество"
            @keydown.enter="getLmsUsers"
        />
      </v-col>
      <v-col cols="2">
        <v-text-field
            :disabled="disabled"
            v-model="emailFilter"
            clearable
            label="E-mail"
            @keydown.enter="getLmsUsers"
        />
      </v-col>
      <v-col cols="2" class="search">
        <v-btn color="primary" @click="getLmsUsers" :disabled="disabled">
          <v-icon>search</v-icon>
          Найти
        </v-btn>
      </v-col>
    </v-row>
    <v-data-table
        :loading="loading"
        v-show="!disabled"
        :items="studs"
        :headers="headers"
        loading-text="Загрузка пользователей.."
        class="elevation-2"
    >
      <template v-slot:item.actions="{ item }">
        <v-tooltip :disabled="item.id != userToLink.codeLms" bottom>
          <template v-slot:activator="{ on, attrs }">
            <div v-on="on">
              <v-btn
                  :disabled="item.id == userToLink.codeLms"
                  icon
                  color="success"
                  @click="openLinkDialog(item)"
                  v-bind="attrs"
                  v-on="on"
              >
                <v-icon>link</v-icon>
              </v-btn>
            </div>
          </template>
          <span>Уже привязан</span>
        </v-tooltip>
      </template>
      <template v-slot:item.fio="{ item }">
        {{ item.lastname }} {{ item.firstname }} {{ item.middlename }}
      </template>
      <template v-slot:no-data>
        <v-alert :value="true" color="error" icon="warning">
          {{ errorMessage }}
        </v-alert>
      </template>
    </v-data-table>
    <v-dialog v-model="linkDialog" max-width="800">
      <v-card>
        <v-toolbar
            dark
            color="primary"
        >
          <v-btn
              icon
              dark
              @click="closeLinkDialog()"
          >
            <v-icon>close</v-icon>
          </v-btn>
          <v-card-title>Вы уверены?</v-card-title>
        </v-toolbar>
        <v-card-title v-if="userToLink != null && selectedOrion != null">
          Привязать "<strong v-if="!useTeachers">{{ userToLink.student }}</strong>
          <strong v-if="useTeachers">{{userToLink.teacher}}</strong>"
          к пользователю LMS
          "<strong>{{ selectedOrion.lastname }} {{ selectedOrion.firstname }} {{ selectedOrion.middlename }}</strong>"?
        </v-card-title>
        <v-card-actions>
          <v-spacer/>
          <v-btn
              color="primary"
              @click="linkStudentToOrion"
              :disabled="loading"
          >
            Привязать
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import {HTTP} from "@/settings/axios";
import Settings from "@/settings/settings_url";

export default {
  name: "LmsUserList",

  data() {
    return {
      emailFilter: "",
      usernameFilter: "",
      loading: false,
      linkDialog: false,
      studs: [],
      familyNameFilter: "",
      nameFilter: "",
      patronymicFilter: "",
      headers: [
        {text: 'Логин', align: 'center', value: 'username', divider: true, class: "bolt"},
        {text: 'ФИО', align: 'center', value: 'fio', divider: true, class: "bolt"},
        {text: 'E-mail', align: 'center', value: 'email', divider: true, class: "bolt"},
        {text: 'Телефон', align: 'center', value: 'phone1', divider: true, class: "bolt"},
        {text: 'Действия', align: 'center', value: 'actions', divider: true, class: "bolt"},
      ],
      userToLink: null,
      selectedOrion: null,
      errorMessage: ""
    }
  },
  methods: {
    linkStudentToOrion() {
      this.loading = true
      this.userToLink.codeLms = this.selectedOrion.id
      let url = "/students"
      if(this.useTeachers)
        url = "/teachers"

      HTTP.post(Settings.API.URL_DEFAULT + url + "/update-lms-id", this.userToLink)
          .then((response) => {
            this.userToLink = response.data
            this.$emit("studentLinked")
          }).catch((err) => {
        console.log(err)
      }).finally(() => {
        this.loading = false
        this.closeLinkDialog()
      })
    },
    closeLinkDialog() {
      this.linkDialog = false
    },
    openLinkDialog(item) {
      this.selectedOrion = item
      this.linkDialog = true
    },
    resetFilters() {
      this.familyNameFilter = null
      this.nameFilter = null
      this.patronymicFilter = null
      this.emailFilter = null
      this.usernameFilter = null
    },
    getLmsUsers() {
      this.loading = true

      HTTP.get(Settings.API.URL_DEFAULT
          + "/user-lms/by-fio?firstname=" + this.nameFilter
          + "&lastname=" + this.familyNameFilter
          + "&middlename=" + this.patronymicFilter
          + "&username=" + this.usernameFilter
          + "&email=" + this.emailFilter)
          .then((response) => {
            console.log(response.data)
            this.studs = response.data
            if (this.studs.length == 0)
              this.errorMessage = "Пользователей не найдено. Попробуйте изменить фильтры"
          })
          .catch((err) => {
            this.studs = []
            this.errorMessage = err.response.data
          })
          .finally(() => {
            this.loading = false
          });
    },
  },
  watch: {
    'familyNameFilter'() {
      if (this.familyNameFilter === null)
        this.familyNameFilter = ""
    },
    'nameFilter'() {
      if (this.nameFilter === null)
        this.nameFilter = ""
    },
    'patronymicFilter'() {
      if (this.patronymicFilter === null)
        this.patronymicFilter = ""
    },
    'emailFilter'() {
      if (this.emailFilter === null)
        this.emailFilter = ""
    },
    'usernameFilter'() {
      if (this.usernameFilter === null)
        this.usernameFilter = ""
    },
  },
  props: {
    disabled: {
      default: false,
      type: Boolean,
    },
    useTeachers: {
      default: false,
      type: Boolean,
    },
  },
}
</script>

<style scoped>
.search {
  padding-bottom: 21px;
}
</style>