<template>
  <v-container>
    <v-card>
      <v-card-title>
        <div>Администрирование дополнительной нагрузки</div>
        <v-row>

          <v-spacer></v-spacer>
          <v-btn
              text
              to="/discipline-admin"
          >
            Добавить дисциплину
          </v-btn>
          <v-btn
              text
              to="/lesson-type-admin"
          >
            Добавить тип
          </v-btn>
        </v-row>
      </v-card-title>
    </v-card>

    <v-expansion-panels
    >
      <v-expansion-panel class="mt-8">
        <v-expansion-panel-header>ФИЛЬТР</v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-row>
            <v-col>
              <v-autocomplete
                  v-model="group"
                  :items="groups"
                  clearable
                  item-text="name"
                  item-value="id"
                  label="Группа"
                  color="#36a2eb"
                  return-object
                  prepend-icon="groups"
              ></v-autocomplete>
            </v-col>
            <v-col>
              <v-autocomplete v-model="yearOfStud"
                              :items="yearOfStuds"
                              label="Год обучения"
                              item-value="id"
                              item-text="name"
                              return-object
                              prepend-icon="event"
              >
              </v-autocomplete>
            </v-col>
            <v-col>
              <v-autocomplete v-model="halfYear"
                              :items="halfYears"
                              label="Полугодие"
                              item-value="id"
                              item-text="name"
                              return-object
                              prepend-icon="event"
              >
              </v-autocomplete>
            </v-col>

          </v-row>
          <v-row>
            <v-col class="text-right">
              <v-btn
                  color="primary"
                  @click="search()"
              >
                Поиск
              </v-btn>
            </v-col>
          </v-row>

        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <v-btn
        class="mt-4 mb-4"
        color="primary"
        @click="newWorkLoad()"
        :disabled="disabledBtn"
    >
      Добавить нагрузку
    </v-btn>
    <v-data-table
        :headers="headers"
        :items="workloads"
        class="elevation-1"
        :items-per-page="50"
        :hide-default-footer="true"
        :loading="loading"
    >

      <template v-slot:item.edit="{ item }">
        <v-btn icon
               @click="editWorkload(item)"
        >
          <v-icon>settings</v-icon>
        </v-btn>
      </template>

    </v-data-table>

    <template>
      <v-row justify="center">
        <v-dialog
            v-model="editDialog"
            persistent
            max-width="600px"
        >
          <v-card>
            <v-card-title>
              <span class="text-h5">Редактировать нагрузку</span>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col
                      cols="12"
                      sm="12"
                      md="12"
                  >
                    <v-autocomplete v-model="workloadSelect.workPlan.discipline"
                                    :items="disciplines"
                                    label="Дисциплина"
                                    clearable
                                    :disabled="workloadSelect.id != null"
                                    item-value="id"
                                    item-text="name"
                                    return-object
                    >
                    </v-autocomplete>
                  </v-col>

                </v-row>
                <v-row>
                  <v-col
                      cols="12"
                      sm="6"
                      md="6"
                  >
                    <v-autocomplete v-model="workloadSelect.workPlan.lessonTypes"
                                    :items="lessonTypes"
                                    label="Тип"
                                    clearable
                                    :disabled="workloadSelect.id != null"
                                    item-value="id"
                                    item-text="name"
                                    return-object
                    >
                    </v-autocomplete>
                  </v-col>
                  <v-col
                      cols="12"
                      sm="6"
                      md="6"
                  >
                    <v-text-field
                        label="Часов"
                        single-line
                        v-model="workloadSelect.workPlan.hours"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col
                      cols="12"
                      sm="12"
                      md="12"
                  >
                    <v-autocomplete v-model="workloadSelect.teacher"
                                    :items="teachers"
                                    label="Преподаватель"
                                    clearable
                                    :disabled="workloadSelect.id != null"
                                    item-value="id"
                                    item-text="teacher"
                                    return-object
                    >
                    </v-autocomplete>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                  color="blue darken-1"
                  text
                  @click="editDialog = !editDialog"
              >
                Close
              </v-btn>
              <v-btn
                  color="blue darken-1"
                  text
                  @click="save()"
              >
                Save
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
    </template>

  </v-container>
</template>

<script>
import Settings from "@/settings/settings_url";
import {HTTP} from '@/settings/axios';

export default {
  name: "WorkLoadAdmin",
  data() {
    return {
      group: {},
      groups: [],
      yearOfStud: {},
      yearOfStuds: [],
      halfYear: {},
      halfYears: [],
      editDialog: false,
      loading: false,

      headers: [
        {text: 'N', align: 'center', sortable: false, value: 'id',},
        {text: 'Группа', align: 'center', sortable: false, value: 'workPlan.group.name',},
        {text: 'Дисциплина', value: 'workPlan.discipline.name', sortable: true},
        {text: 'Часов', value: 'hours', sortable: true},
        {text: 'Тип', value: 'workPlan.lessonTypes.name', sortable: true},
        {text: 'Преподаватель', value: 'teacher.fio', sortable: true},
        {text: '', value: 'edit', sortable: false},
      ],

      workloads: [],
      workloadSelect: {
        workPlan: {
          discipline: {},
          lessonTypes: {}
        },
        teacher: {}
      },

      disciplines: [],
      lessonTypes: [],
      teachers: [],
    }
  },
  computed:{
    disabledBtn(){
      return this.group.id == undefined || this.group.id == null;
    }
  },
  methods: {
    save() {
      HTTP.post(Settings.API.URL_DEFAULT + "/workload/manually", this.workloadSelect)
          .then((response) => {
            console.log(response.data)
          })
          .catch(() => {

          })
          .finally(() => {
            this.editDialog = false
            this.searchManualWorkload()
          });
    },
    newWorkLoad(){
      this.getDisciplines()
      this.getLessonTypes()
      this.getTeachers()
      this.workloadSelect = {
        workPlan: {
          discipline: {
            name: ""
          },
          lessonTypes: {},
          group: this.group,
          yearOfStudy: this.yearOfStud,
          halfYear: this.halfYear,
        },
        teacher: {}
      }
      this.editDialog = true

    },
    editWorkload(item) {
      this.newWorkLoad()
      this.workloadSelect = {...item}
    },
    search() {
      this.searchManualWorkload()
    },
    searchManualWorkload() {
      this.loading = true
      HTTP.get(Settings.API.URL_DEFAULT + "/workload/manually?groupId=" + this.group.id + "&yearId=" + this.yearOfStud.id + "&halfYearId=" + this.halfYear.id)
          .then((response) => {
            this.workloads = response.data
            console.log(response.data)
          })
          .catch(() => {

          })
          .finally(() => {
            this.loading = false
          });
    },
    getGroup() {
      HTTP.get(Settings.API.URL_DEFAULT + "/groups")
          .then((response) => {
            this.groups = response.data
          })
          .catch(() => {

          })
          .finally(() => {

          });
    },
    getYear: async function () {
      HTTP.get(Settings.API.URL_DEFAULT + "/year-of-study")
          .then((response) => {
            this.yearOfStuds = response.data
          })
          .catch(() => {

          })
          .finally(() => {
            for (let i = 0; i < this.yearOfStuds.length; i++) {
              if (this.yearOfStuds[i].current == 1) {
                this.yearOfStud = this.yearOfStuds[i]
                break
              }
            }
          });
    },
    getHalfYear: async function () {
      HTTP.get(Settings.API.URL_DEFAULT + "/half-year")
          .then((response) => {
            this.halfYears = response.data
          })
          .catch(() => {

          })
          .finally(() => {
            for (let i = 0; i < this.halfYears.length; i++) {
              if (this.halfYears[i].current == 1) {
                this.halfYear = this.halfYears[i]
                break
              }
            }
          });
    },
    getDisciplines: async function () {
      HTTP.get(Settings.API.URL_DEFAULT + "/disciplines")
          .then((response) => {
            this.disciplines = response.data
            console.log(this.disciplines)
          })
          .catch(() => {

          })
          .finally(() => {

          });
    },
    getLessonTypes: async function () {
      HTTP.get(Settings.API.URL_DEFAULT + "/lesson-types/manually")
          .then((response) => {
            this.lessonTypes = response.data
            console.log(this.lessonTypes)
          })
          .catch(() => {

          })
          .finally(() => {

          });
    },
    getTeachers: async function () {
      HTTP.get(Settings.API.URL_DEFAULT + "/teachers")
          .then((response) => {
            this.teachers = response.data
            console.log(this.teachers)
          })
          .catch(() => {

          })
          .finally(() => {

          });
    },
  },
  created() {
    this.getGroup()
    this.getYear()
    this.getHalfYear()
  }
}
</script>

<style scoped>

</style>