<template>
  <v-dialog v-model="dialog"
            max-width="600px">
    <v-card>
      <v-toolbar
          dark
          color="primary"
      >
        <v-btn
            icon
            dark
            @click="closeDialog()"
        >
          <v-icon>close</v-icon>
        </v-btn>
        <v-card-title>Начислить компенсационные баллы</v-card-title>
      </v-toolbar>
      <v-progress-linear
          color="primary"
          :indeterminate="isAdding"
          :active="isAdding"
      />
      <v-container>
        <v-card-subtitle v-if="type !== null && maxPoints > 0">
          <v-alert type="info" color="primary">
            Можно начислить баллов: {{ maxPoints }} шт.
          </v-alert>
        </v-card-subtitle>
        <v-card-text>
          <v-form
              ref="pointsForm"
              v-model="valid"
          >
            <v-autocomplete
                v-model="type"
                :items="types"
                outlined
                dense
                item-text="name"
                item-value="id"
                return-object
                label="Тип компенсации"
                required
            />
            <v-text-field
                v-show="type !== null"
                v-model="pointsToAdd"
                :disabled="maxPoints !== null && maxPoints <= 0"
                label="Введите количество баллов"
                :hint="'Максимум можно начислить ' + maxPoints + ' баллов'"
                :rules="pointsToAddRules"
                persistent-hint
                type="number"
                required
            />
            <v-text-field
                v-show="type !== null"
                v-model="comment"
                label="Комментарий (необязательно)"
                :disabled="maxPoints !== null && maxPoints <= 0"
                persistent-hint
                required
            />
          </v-form>
        <v-alert type="warning" v-if="maxPoints !== null && maxPoints <= 0 && type !== null">
          Нельзя начислить баллы! Студент достиг лимита по данной компенсации ({{ type.pointsLimit }} баллов)!
        </v-alert>
        </v-card-text>
        <v-divider/>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              text
              color="secondary"
              @click="closeDialog()"
          >
            Закрыть
          </v-btn>
          <v-btn
              text
              :disabled="!valid || isAdding"
              :loading="isAdding"
              color="primary"
              @click=addPoints()
          >
            Добавить
          </v-btn>
        </v-card-actions>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import {HTTP} from "@/settings/axios";
import Settings from "@/settings/settings_url";

export default {
  name: "CompensationPointsAdd",
  data() {
    return {
      maxPoints: null,
      dialog: false,
      type: null,
      types: [],
      pointsToAdd: null,
      pointsAdded: null,
      pointsToAddRules: [
        v => !!v || 'Это поле обязательно',
        v => v > 0 || 'Введите больше 0 баллов',
        v => v <= this.maxPoints || 'Максимум можно начислить ' + this.maxPoints + ' баллов'
      ],
      valid: false,
      isAdding: false,
      comment: null,
    }
  },
  methods: {
    getTypes() {
      HTTP.get(Settings.API.URL_DEFAULT + "/compensation-type")
          .then((response) => {
            this.types = response.data
          })
          .catch(() => {

          })
          .finally(() => {

          })
    },
    getAddedPointsForType: async function () {
      await HTTP.get(Settings.API.URL_DEFAULT + "/compensation-points/journal"
          + "/get-added-points?studentId=" + this.student.id
          + "&compensationTypeId=" + this.type.id
          + "&halfYearId=" + this.halfYearId
          + "&yearOfStudyId=" + this.yearOfStudyId)
          .then((response) => {
            this.pointsAdded = response.data
          })
          .catch(() => {

          })
          .finally(() => {

          })
    },
    addPoints: async function () {
      this.isAdding = true
      await this.$refs.pointsForm.validate()
      if (!this.valid)
        return

      if(this.balance === null || this.balance === "") {
        this.balance = {
          student: {
            id: this.student.id,
          },
          halfYear: {
            id: this.halfYearId,
          },
          yearOfStudy: {
            id: this.yearOfStudyId,
          },
        }
      }

      HTTP.post(Settings.API.URL_DEFAULT + "/compensation-points-balance/add-points", {
        "balance": this.balance,
        "compensationType": this.type,
        "points": this.pointsToAdd,
        "user": this.$store.getters.selectedUser,
        "comment": this.comment,
      })
          .then((response) => {
            this.balance = response.data
            this.$emit('updateBalance', this.balance)
            this.$emit('success', 'Баллы успешно добавлены')
            // this.transferSuccessful = true
          })
          .catch(() => {
            this.$emit('error')
          })
          .finally(() => {
            this.closeDialog()
            this.isAdding = false
          });
    },
    closeDialog: function() {
      this.$refs.pointsForm.reset()
      this.$refs.pointsForm.resetValidation()
      this.dialog = false
    },
    calculateMaxPoints() {
      this.maxPoints = this.type.pointsLimit - this.pointsAdded
    }
  },
  watch: {
    'type': async function () {
      if (this.type === null)
        return

      await this.getAddedPointsForType()
      this.calculateMaxPoints()
    },
    'balance': async function () {
      if (this.type === null)
        return

      await this.getAddedPointsForType()
      this.calculateMaxPoints()
    }
  },
  props: {
    student: {},
    balance: {},
    halfYearId: {
      type: Number,
      default: -1
    },
    yearOfStudyId: {
      type: Number,
      default: -1
    },
  },
  created() {
    this.getTypes()
  }
}
</script>

<style scoped>

</style>