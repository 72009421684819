<template>
  <v-container justify-center>
    <div>
      <v-btn
          color="primary"
          dark
          @click="showDialogEditSchedule"
      >
        Добавить
      </v-btn>
      <v-row class="mt-6 ml-2">
        <v-col class="mr-10">
          <v-autocomplete v-model="yearOfStudTable"
                          :items="yearOfStudsTable"
                          label="Год обучения"
                          dense
                          item-value="id"
                          item-text="name"
                          return-object
                          prepend-icon="event"
          />
        </v-col>
        <v-col class="mr-4">
          <v-autocomplete v-model="halfYearTable"
                          :items="halfYearsTable"
                          label="Полугодие"
                          dense
                          item-value="id"
                          item-text="name"
                          return-object
                          prepend-icon="event"
          />
        </v-col>
      </v-row>
      <template>
        <v-row justify="center">
          <v-dialog
              v-model="newScheduleDialog"
              persistent
              max-width="600px"
          >
            <v-card>
              <v-card-title>
                <span class="text-h5">Профиль расписания</span>
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col
                        cols="12"
                        sm="6"
                        md="6"
                    >
                      <v-text-field
                          label="Название*"
                          required
                          :rules="rulesTextField"
                          v-model="newSchedule.name"
                      ></v-text-field>
                    </v-col>
                    <v-col
                        cols="12"
                        sm="6"
                        md="6"
                    >
                      <v-text-field
                          label="Описание"
                          hint="Краткое описание псевдоним"
                          v-model="newSchedule.description"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="4" >
                      <v-dialog
                          ref="dialog_start"
                          v-model="modal_date_start"
                          :return-value.sync="newSchedule.startDate"
                          persistent
                          width="290px"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                              v-model="newSchedule.startDate"
                              label="Начало обучения"
                              prepend-icon="mdi-calendar"
                              readonly
                              :rules="rulesTextField"
                              v-bind="attrs"
                              v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                            v-model="newSchedule.startDate"
                            scrollable
                        >
                          <v-spacer></v-spacer>
                          <v-btn
                              text
                              color="primary"
                              @click="modal_date_start = false"
                          >
                            Cancel
                          </v-btn>
                          <v-btn
                              text
                              color="primary"
                              @click="$refs.dialog_start.save(newSchedule.startDate)"
                          >
                            OK
                          </v-btn>
                        </v-date-picker>
                      </v-dialog>
                    </v-col>
                    <v-col cols="4" >
                      <v-dialog
                          ref="dialog_end"
                          v-model="modal_date_end"
                          :return-value.sync="newSchedule.endDate"
                          persistent
                          width="290px"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                              v-model="newSchedule.endDate"
                              label="Конец обучения"
                              prepend-icon="mdi-calendar"
                              readonly
                              v-bind="attrs"
                              v-on="on"
                              :rules="rulesTextField"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                            v-model="newSchedule.endDate"
                            scrollable
                        >
                          <v-spacer></v-spacer>
                          <v-btn
                              text
                              color="primary"
                              @click="modal_date_end = false"
                          >
                            Cancel
                          </v-btn>
                          <v-btn
                              text
                              color="primary"
                              @click="$refs.dialog_end.save(newSchedule.endDate)"
                          >
                            OK
                          </v-btn>
                        </v-date-picker>
                      </v-dialog>
                    </v-col>
                    <v-col cols="4"  >
                      <v-dialog
                          ref="dialog_end_session"
                          v-model="modal_date_end_session"
                          :return-value.sync="newSchedule.endDateSession"
                          persistent
                          width="290px"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                              v-model="newSchedule.endDateSession"
                              label="Конец сессии"
                              prepend-icon="mdi-calendar"
                              readonly
                              v-bind="attrs"
                              v-on="on"
                              :rules="rulesTextField"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                            v-model="newSchedule.endDateSession"
                            scrollable
                        >
                          <v-spacer></v-spacer>
                          <v-btn
                              text
                              color="primary"
                              @click="modal_date_end_session = false"
                          >
                            Cancel
                          </v-btn>
                          <v-btn
                              text
                              color="primary"
                              @click="$refs.dialog_end_session.save(newSchedule.endDateSession)"
                          >
                            OK
                          </v-btn>
                        </v-date-picker>
                      </v-dialog>
                    </v-col>
                  </v-row>
                  <v-row>

                  </v-row>
                  <v-row>

                    <v-col
                        cols="12"
                    >
                      <v-autocomplete v-model="newSchedule.groups"
                                      :items="groups"
                                      label="Группа"
                                      dense
                                      item-value="id"
                                      item-text="name"
                                      return-object
                                      :rules="rulesObjectField"
                      >
                      </v-autocomplete>
                    </v-col>
                    <v-col
                        cols="12"
                        sm="6"
                        md="6"
                    >
                      <v-autocomplete v-model="newSchedule.course"
                                      :items="courses"
                                      label="Курс"
                                      dense
                                      item-value="id"
                                      item-text="name"
                                      return-object
                                      :rules="rulesObjectField"
                      >
                      </v-autocomplete>
                    </v-col>
                    <v-col
                        cols="12"
                        sm="6"
                        md="6"
                    >
                      <v-autocomplete v-model="newSchedule.shift"
                                      :items="shifts"
                                      label="Смена"
                                      dense
                                      item-value="id"
                                      item-text="name"
                                      return-object
                                      :rules="rulesObjectField"
                      >
                      </v-autocomplete>
                    </v-col>

                    <v-col
                        cols="12"
                        sm="6"
                        md="6"
                    >
                      <v-autocomplete v-model="newSchedule.yearOfStudy"
                                      :items="yearOfStudys"
                                      label="Год"
                                      dense
                                      item-value="id"
                                      item-text="name"
                                      return-object
                                      :rules="rulesObjectField"
                      >
                      </v-autocomplete>
                    </v-col>
                    <v-col
                        cols="12"
                        sm="6"
                        md="6"
                    >
                      <v-autocomplete v-model="newSchedule.halfYear"
                                      :items="halfYears"
                                      label="Полугодие"
                                      dense
                                      item-value="id"
                                      item-text="name"
                                      return-object
                                      :rules="rulesObjectField"
                      >
                      </v-autocomplete>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col class="mr-10">
                      <v-checkbox
                          label="Активно"
                          required
                          v-model="active"
                      ></v-checkbox>
                    </v-col>
                  </v-row>
                </v-container>
                <!--              <small>*indicates required field</small>-->
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    color="blue darken-1"
                    text
                    @click="newScheduleDialog = false"
                >
                  Закрыть
                </v-btn>
                <v-btn
                    color="blue darken-1"
                    text
                    @click="saveSchedule"
                >
                  Сохранить
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-row>
        <v-flex xs12>
          <v-row class="ma-5">
            <v-text-field
                v-model="search"
                prepend-icon="search"
                label="Поиск"
                single-line
                hide-details
            ></v-text-field>
          </v-row>
          <div class="mt-5">
            <v-data-table
                :headers="headers"
                :items="schedules"
                :items-per-page="15"
                :hide-default-footer="false"
                class="elevation-2"
                :single-expand="true"
                :search="search"
                no-data-text="Нет информации"
                no-results-text="Информации не найдено"
            >
              <template v-slot:item.linked="{ item }">
                <v-btn @click="showDialogEditSchedule(item)" icon color="primary">
                  <v-icon>edit</v-icon>
                </v-btn>
              </template>



              <template v-slot:item.startDate="{ item }">
                {{ formatDate(item.startDate) }}
              </template>

              <template v-slot:item.endDate="{ item }">
                {{ formatDate(item.endDate) }}
              </template>

              <template v-slot:item.endDateSession="{ item }">
                {{ formatDate(item.endDateSession) }}
              </template>
            </v-data-table>
          </div>

        </v-flex>
      </template>
    </div>
  </v-container>
</template>

<script>
import Settings from "@/settings/settings_url";
import {HTTP} from '@/settings/axios'
import date_utils from "@/settings/date_utils";

export default {
  name: "ScheduleEdit",
  data() {
    return {

      yearOfStudTable: {},
      yearOfStudsTable: [],
      halfYearTable: {},
      halfYearsTable: [],

      loading: false,

      rulesTextField: [
        value => !!value || 'Required.',
      ],

      rulesObjectField: [
        value => value.id != null || 'Required.',
        value => value.id != undefined || 'Required.',
      ],

      modal_date_start: false,
      modal_date_end: false,
      modal_date_end_session: false,
      newScheduleDialog: false,

      // schedule_date_start: new Date().toISOString().substr(0, 10),
      // schedule_date_end: new Date().toISOString().substr(0, 10),

      groups: [],
      courses: [],
      shifts: [],
      yearOfStudys: [],
      halfYears: [],
      active: true,
      newSchedule: {},
      Schedule: {
        name: "",
        description: "",
        startDate: new Date().toISOString().substr(0, 10),
        endDate: new Date().toISOString().substr(0, 10),
        endDateSession: new Date().toISOString().substr(0, 10),
        groups: {},
        course: {},
        shift: {},
        yearOfStudy: {},
        halfYear: {},
        status: "ACTIVE"
      },
      headers: [
        {text: 'id', align: 'center', sortable: true, value: 'id', divider: true, class: "bolt"},
        {text: 'Группа', align: 'center', value: 'groups.name', divider: true, sortable: true, class: "bolt"},
        {text: 'Название', align: 'center', value: 'name', divider: true, sortable: true, class: "bolt"},
        {text: 'Описание', align: 'center', value: 'description', divider: true, sortable: true, class: "bolt"},
        {text: 'Активно', align: 'center', value: 'status', divider: true, sortable: true, class: "bolt"},
        {text: 'Начало обучения', align: 'center', value: 'startDate', divider: true, sortable: true, class: "bolt"},
        {text: 'Конец обучения', align: 'center', value: 'endDate', divider: true, sortable: true, class: "bolt"},
        {text: 'Конец сессии', align: 'center', value: 'endDateSession', divider: true, sortable: true, class: "bolt"},
        {text: 'Редактировать', value: 'linked', divider: true, align: 'center'}
      ],
      schedules: [],
      search: "",
    };
  },
  watch: {
    active() {
      this.newSchedule.status = this.active ? "ACTIVE" : "NOT_ACTIVE"
    },
    halfYearTable() {
      this.getSchedule()
    },
    yearOfStudTable() {
      this.getSchedule()
    },
  },
  methods: {
    formatDate(date) {
      return date_utils.formatDate(date)
    },
    showDialogEditSchedule(item = null) {
      this.newScheduleDialog = true
      if (item == null) {
        this.newSchedule = {...this.Schedule}
      } else {
        this.newSchedule = {...item}
      }

      this.active = this.newSchedule.status === "ACTIVE"
      this.getGroups()
      this.getCourses()
      this.getShift()
      this.getHalfYear()
      this.getYearOfStudys()
    },

    async saveSchedule() {
      console.log(this.newSchedule)
      await HTTP.post(Settings.API.URL_DEFAULT + Settings.URL_SCHEDULE, this.newSchedule)
          .then(() => {
            this.newScheduleDialog = false
          }).catch(error => {
            if (error.response.status === 409) {
              this.dialog_duplicate_schedule = true;
            }
          }).finally(() => {
            this.getSchedule()
          });
    },


    getGroups: async function () {
      await HTTP.get(Settings.API.URL_DEFAULT + Settings.URL_GROUPS_BRSO)
          .then((response) => {
            this.groups = response.data.map(function (item) {
              item.name = item.name + ", " + item.course + ", " + item.faculty + ", Направление:  " + item.direction + ", " + item.trainingPeriod
              return item
            })
          })
          .catch(() => {
            this.errored = true;
          })
          .finally(() => (this.loading = false));
    },

    getCourses: async function () {
      await HTTP.get(Settings.API.URL_DEFAULT + "/courses")
          .then((response) => {
            this.courses = response.data
          })
          .catch(() => {
            this.errored = true;
          })
          .finally(() => (this.loading = false));
    },

    getShift: async function () {
      await HTTP.get(Settings.API.URL_DEFAULT + "/shift")
          .then((response) => {
            this.shifts = response.data
          })
          .catch(() => {
            this.errored = true;
          })
          .finally(() => (this.loading = false));
    },

    getHalfYear: async function () {
      await HTTP.get(Settings.API.URL_DEFAULT + "/half-year")
          .then((response) => {
            this.halfYears = response.data
          })
          .catch(() => {
            this.errored = true;
          })
          .finally(() => (this.loading = false));
    },

    getYearOfStudys: async function () {
      await HTTP.get(Settings.API.URL_DEFAULT + "/year-of-study")
          .then((response) => {
            this.yearOfStudys = response.data
          })
          .catch(() => {
            this.errored = true;
          })
          .finally(() => (this.loading = false));
    },

    getYearTable: async function () {
      HTTP.get(Settings.API.URL_DEFAULT + "/year-of-study")
          .then((response) => {
            this.yearOfStudsTable = response.data
          })
          .catch(() => {

          })
          .finally(() => {
            for (let i = 0; i < this.yearOfStudsTable.length; i++) {
              if (this.yearOfStudsTable[i].current == 1) {
                this.yearOfStudTable = this.yearOfStudsTable[i]
                break
              }
            }
          });
    },
    getHalfYearTable: async function () {
      HTTP.get(Settings.API.URL_DEFAULT + "/half-year")
          .then((response) => {
            this.halfYearsTable = response.data
          })
          .catch(() => {

          })
          .finally(() => {
            for (let i = 0; i < this.halfYearsTable.length; i++) {
              if (this.halfYearsTable[i].current == 1) {
                this.halfYearTable = this.halfYearsTable[i]
                break
              }
            }
          });
    },

    getSchedule: async function () {
      console.log(this.halfYearTable)
      await HTTP.get(Settings.API.URL_DEFAULT + Settings.URL_SCHEDULE + "by-year?year=" + this.yearOfStudTable.id + "&halfYear=" + this.halfYearTable.id)
          .then((response) => {
            this.schedules = response.data
          })
          .catch(() => {
            this.errored = true;
          })
          .finally(() => (this.loading = false));
    },

  },
  async created() {
    await this.getHalfYearTable()
    await this.getYearTable()
  }
}
</script>

<style scoped>

</style>

