<template>
<div>
  <v-card-title>
    <v-text-field
        v-model="search"
        prepend-inner-icon="search"
        label="Поиск"
        single-line
        hide-details
    />
  </v-card-title>
  <v-data-table
      :headers="headers"
      :items="orderCertificates"
      :items-per-page="10"
      class="elevation-4"
      :search="search"
      no-data-text="Нет справок!"
      loading-text="Загрузка..."
  >

    <template v-slot:item.workStatus="{ item }">
      <div>
        {{ workStatus(item.workStatus) }}
      </div>
    </template>

    <template v-slot:item.certificateFile="{ item }">
      <div v-for="certificateFile in item.certificateFile" :key="certificateFile.id">
        <a target="_blank" :href="fileUrl(certificateFile)">{{ certificateFile.originName }}</a>
      </div>
    </template>

  </v-data-table>
</div>
</template>

<script>
import Settings from "@/settings/settings_url";

export default {
  name: "OrderCertificateStudentList",
  props:{
    orderCertificates: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      search: '',
      headers: [
        {text: 'N', align: 'center', sortable: false, value: 'id',},
        {text: 'Название', value: 'typeCertificate.title'},
        {text: 'Файлы', value: 'certificateFile'},
        {text: 'Ответственный', value: 'responsible.username'},
        {text: 'Статус', value: 'workStatus'},
      ],

    }
  },
  methods:{
    fileUrl(file){
      return Settings.API.URL_DEFAULT +  "/certificate-file/" + file.id
    },
    workStatus(status){
      if (status === "CREATION"){
        return "Создан"
      }
      if (status === "IN_WORK"){
        return "В работе"
      }
      if (status === "READY"){
        return "Готов"
      }
      if (status === "COMPLETED"){
        return "Выдан"
      }
      if (status === "CANCELED"){
        return "Отменен"
      }
    }
  }
}
</script>

<style scoped>

</style>
