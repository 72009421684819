<template>
  <div>

    <div>
      <v-btn
          color="primary"
          dark
          @click="newItem"
      >
        Добавить
      </v-btn>
    </div>

    <template>
      <v-row justify="center">
        <v-dialog
            v-model="dialog"
            persistent
            max-width="600px"
        >
          <v-card>
            <v-card-title>
              <span class="headline">{{ title_dialog }}</span>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col
                      cols="12"
                      sm="12"
                      md="12"
                  >
                    <v-autocomplete v-model="access_point_id"
                                    :items="access_point"
                                    label="Зона"
                                    dense
                    >
                    </v-autocomplete>
                    <v-autocomplete v-model="audiences_type_id"
                                    :items="audiences_type"
                                    label="Тип"
                                    dense
                    >
                    </v-autocomplete>


                    <v-text-field
                        label="Наименование"
                        required
                        v-model="name"
                    ></v-text-field>
                    <v-text-field
                        label="Вместимость"
                        required
                        v-model="capacity"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                  color="blue darken-1"
                  text
                  @click="dialog = false"
              >
                Close
              </v-btn>
              <v-btn
                  color="blue darken-1"
                  text
                  @click="saveAudience()"
              >
                Save
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
    </template>

    <v-data-table
        :headers="headers"
        :items="audiences"
        :items-per-page="15"
        :hide-default-footer="false"
        class="elevation-2"
        :single-expand="true"
    >
      <template v-slot:item.linked="{ item }">
        <v-btn @click="editItem(item)" icon>
          <v-icon>settings</v-icon>
        </v-btn>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import Settings from "@/settings/settings_url";
import {HTTP} from '@/settings/axios'

export default {
  name: "Audiences",
  data() {

    return {
      headers: [
        {text: 'id', align: 'center', sortable: true, value: 'id', divider: true, class: "bolt"},
        {text: 'Наименование', align: 'center', value: 'name', divider: true, sortable: true, class: "bolt"},
        {text: 'Вместимость', align: 'center', value: 'capacity', divider: true, sortable: true, class: "bolt"},
        {text: 'Тип расписания', align: 'center', value: 'typeName', divider: true, sortable: true, class: "bolt"},
        {text: 'Зона', align: 'center', value: 'accessPointName', divider: true, sortable: true, class: "bolt"},
        {text: 'Редактировать', value: 'linked', divider: true, align: 'center'}
      ],


      audiences_id: "",
      audiences: [],
      dialog: false,
      title_dialog: "",

      access_point_id: "",
      access_point: [],

      audiences_type_id: "",
      audiences_type: [],

      id: null,
      name: "",
      capacity: null

    };
  },
  methods: {
    clearVar: function () {
      this.access_point_id = ""
      this.audiences_type_id = ""
      this.id = null
      this.name = ""
      this.capacity = null
    },
    editItem: function (item) {
      this.clearVar()
      this.dialog = true

      this.title_dialog = "Редактировать тип"

      this.id = item.id
      this.name = item.name
      this.access_point_id = item.accessPoint
      this.audiences_type_id = item.type
      this.capacity = item.capacity
    },
    newItem: function () {
      this.clearVar()
      this.dialog = true
      this.title_dialog = "Создать новый тип"
    },
    saveAudience: function (){
      if (this.id == null){
        this.postAudience()
      }else {
        this.updateAudience()
      }

    },
    updateAudience: async function () {
      this.dialog = false
      await HTTP.put(Settings.API.URL_DEFAULT + Settings.URL_AUDIENCES, {
        "id": this.id,
        "name": this.name,
        "type": this.audiences_type_id,
        "capacity": this.capacity,
        "accessPoint": this.access_point_id,
        "typeName": "",
        "accessPointName": ""
      })
          .then(() => {
          })
          .catch(() => {
          })
          .finally(()=>{
            this.getAudience()
          })
    },
    postAudience: async function () {
      this.dialog = false
      await HTTP.post(Settings.API.URL_DEFAULT + Settings.URL_AUDIENCES, {
        "id": this.id,
        "name": this.name,
        "type": this.audiences_type_id,
        "capacity": this.capacity,
        "accessPoint": this.access_point_id,
        "typeName": "",
        "accessPointName": ""
      })
          .then(() => {
          })
          .catch(() => {

          })
      .finally(()=>{
        this.getAudience()
      })
    },
    getAccessPoint: async function () {
      await HTTP.get(Settings.API.URL_DEFAULT + Settings.URL_ACCESS_POINT_BRSO)
          .then((response) => {
            this.access_point = response.data.map(function (item) {
              return {
                text: item.name,
                value: item.id
              }
            })
          })
          .catch(() => {

          })
    },
    getAudienceType: async function () {
      await HTTP.get(Settings.API.URL_DEFAULT + Settings.URL_AUDIENCES_TYPE)
          .then((response) => {
            this.audiences_type = response.data.map(function (item) {
              return {
                text: item.name,
                value: item.id
              }
            })
          })
          .catch(() => {

          })
    },
    getAudience: async function () {
      await HTTP.get(Settings.API.URL_DEFAULT + Settings.URL_AUDIENCES)
          .then((response) => {
            this.audiences = response.data
          })
          .catch(() => {
          })
    }
  },
  created() {
    this.getAccessPoint()
    this.getAudienceType()
    this.getAudience()
  }
}
</script>

<style scoped>

</style>