<template>
  <v-card-text>
    <div class="text-center">
      <v-progress-circular
          :indeterminate="loading"
          :active="loading"
          color="primary"
          v-show="loading"
      />
    </div>

    <v-card>
      <v-alert type="error" v-if="!loading && (files === null || files.length === 0)">
        <span v-if="files === null">
          Не удалось загрузить файлы
        </span>
        <span v-if="files !== null && files.length === 0">
          У студента не было загружено файлов в точку контроля
        </span>
      </v-alert>
      <v-list v-if="!loading && files !== null && files.length > 0">

        <v-list-item v-for="file in files" :key="file.id">
          <v-list-item-content>
            <b>{{ file.filename }}</b>
          </v-list-item-content>
          <v-list-item-action>
            <v-row>
              <v-col>
                <v-tooltip bottom :color="file.fileUrl === visitItem.fileUrl ? 'info' : 'primary'">
                  <template v-slot:activator="{ on, attrs }">
                    <div v-on="on">
                      <v-btn :disabled="file.fileUrl === visitItem.fileUrl"
                             small
                             icon
                             color="primary"
                             @click="fileSelected(file)"
                             v-bind="attrs"
                      >
                        <v-icon>link</v-icon>
                      </v-btn>
                    </div>
                  </template>
                  <span v-if="file.fileUrl !== visitItem.fileUrl">Привязать файл</span>
                  <span v-if="file.fileUrl === visitItem.fileUrl">Этот файл уже привязан</span>
                </v-tooltip>
              </v-col>
              <v-col>
                <v-tooltip bottom color="primary">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn small icon color="primary" @click="openFile(file)" v-bind="attrs" v-on="on">
                      <v-icon>download</v-icon>
                    </v-btn>
                  </template>
                  Скачать файл
                </v-tooltip>
              </v-col>
            </v-row>
          </v-list-item-action>
        </v-list-item>
      </v-list>
    </v-card>
  </v-card-text>
</template>

<script>
import {HTTP} from "@/settings/axios";
import Settings from "@/settings/settings_url";

export default {
  name: "LmsFilesFromControlPoint",

  data() {
    return {
      loading: false,
      files: [],
    }
  },
  methods: {
    openFile(item) {
      window.open(item.fileUrl, '_blank').focus()
    },
    fileSelected(file) {
      this.$emit('file-selected', file)
    },
    getFiles() {
      console.log(this.selectedStudentId)
      console.log(this.assignId)

      this.loading = true

      HTTP.get(Settings.API.URL_DEFAULT + "/courses/lms/assigns/files?assignId=" + this.assignId + "&userId=" + this.selectedStudentId)
          .then((response) => {
            console.log(response.data)
            this.files = response.data
          }).finally(() => this.loading = false)
    },
  },
  created() {
    this.getFiles()
  },
  props: {
    selectedStudentId: String,
    assignId: Number,
    visitItem: null,
  }
}
</script>

<style scoped>

</style>