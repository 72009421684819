<template>
  <v-container>
    <div class="overlay-alert">
      <v-alert
          dismissible
          type="success"
          v-model="alertSuccess"
          transition="scale-transition">
        success!
      </v-alert>
      <v-alert
          dismissible
          type="error"
          v-model="alertError"
          transition="scale-transition">
        Error!
      </v-alert>
    </div>

    <v-row>
      <v-col
          cols="12"
          sm="4"
          md="2"
      >
        <v-autocomplete v-model="year"
                        :items="years"
                        label="год"
                        dense
        >
        </v-autocomplete>
      </v-col>
      <v-spacer></v-spacer>
      <v-col
          cols="12"
          sm="4"
          md="2"
      >
        <div><h2>{{ lessonCalendar.year }} Год</h2></div>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <h3>Январь</h3>
        <v-date-picker class="show-days-only"
                       v-model="datesJanuary"
                       multiple
                       no-title
                       readonly
                       :first-day-of-week="1"
                       :events="countEvents"
        ></v-date-picker>
      </v-col>
      <v-col>
        <h3>февраль</h3>
        <v-date-picker class="show-days-only"
                       v-model="datesFebruary"
                       multiple
                       no-title
                       readonly
                       :first-day-of-week="1"
                       :events="countEvents"
        ></v-date-picker>
      </v-col>
      <v-col>
        <h3>Март</h3>
        <v-date-picker class="show-days-only"
                       v-model="datesMarch"
                       multiple
                       no-title
                       readonly
                       :first-day-of-week="1"
                       :events="countEvents"
        ></v-date-picker>
      </v-col>
      <v-col>
        <h3>Апрель</h3>
        <v-date-picker class="show-days-only"
                       v-model="datesApril"
                       multiple
                       no-title
                       readonly
                       :first-day-of-week="1"
                       :events="countEvents"
        ></v-date-picker>
      </v-col>
    </v-row>

    <v-row>

      <v-col>
        <h3>Май</h3>
        <v-date-picker class="show-days-only"
                       v-model="datesMay"
                       multiple
                       no-title
                       readonly
                       :first-day-of-week="1"
                       :events="countEvents"
        ></v-date-picker>
      </v-col>
      <v-col>
        <h3>Июнь</h3>
        <v-date-picker class="show-days-only"
                       v-model="datesJune"
                       multiple
                       no-title
                       readonly
                       :first-day-of-week="1"
                       :events="countEvents"
        ></v-date-picker>
      </v-col>
      <v-col>
        <h3>Июль</h3>
        <v-date-picker class="show-days-only"
                       v-model="datesJuly"
                       multiple
                       no-title
                       readonly
                       :first-day-of-week="1"
                       :events="countEvents"
        ></v-date-picker>
      </v-col>
      <v-col>
        <h3>Август</h3>
        <v-date-picker class="show-days-only"
                       v-model="datesAugust"
                       multiple
                       no-title
                       readonly
                       :first-day-of-week="1"
                       :events="countEvents"
        ></v-date-picker>
      </v-col>
    </v-row>

    <v-row>

      <v-col>
        <h3>Сентябрь</h3>
        <v-date-picker class="show-days-only"
                       v-model="datesSeptember"
                       multiple
                       no-title
                       readonly
                       :first-day-of-week="1"
                       :events="countEvents"
        ></v-date-picker>
      </v-col>
      <v-col>
        <h3>Октябрь</h3>
        <v-date-picker class="show-days-only"
                       v-model="datesOctober"
                       multiple
                       no-title
                       readonly
                       :first-day-of-week="1"
                       :events="countEvents"
        ></v-date-picker>
      </v-col>
      <v-col>
        <h3>Ноябрь</h3>
        <v-date-picker class="show-days-only"
                       v-model="datesNovember"
                       multiple
                       no-title
                       readonly
                       :first-day-of-week="1"
                       :events="countEvents"
        ></v-date-picker>
      </v-col>
      <v-col>
        <h3>Декабрь</h3>
        <v-date-picker class="show-days-only"
                       v-model="datesDecember"
                       multiple
                       no-title
                       readonly
                       :first-day-of-week="1"
                       :events="countEvents"
        ></v-date-picker>
      </v-col>
    </v-row>

    <v-row>

    </v-row>

  </v-container>

</template>

<script>

import Settings from "@/settings/settings_url";
import {HTTP} from '@/settings/axios'

export default {
  name: "LessonsCalendar",

  data() {
    return {
      type: null,
      elapse: null,
      alertSuccess: false,
      alertError: false,
      years: [
        2020,
        2021,
        2022,
        2023,
        2024,
      ],
      year: 2021,
      workloadId: 0,
      lessonCalendar: {},
      datesJanuary: [],
      datesFebruary: [],
      datesMarch: [],
      datesApril: [],
      datesMay: [],
      datesJune: [],
      datesJuly: [],
      datesAugust: [],
      datesSeptember: [],
      datesOctober: [],
      datesNovember: [],
      datesDecember: [],
    }
  },
  watch: {
    year: function () {
      this.getLessonCalendar()
    }
  },
  methods: {
    getCountDate(month, date){
      let count = 0;
      month.forEach(function (item){
        if (item === date){
          count += 1
        }
      })
      return count
    },
    countEvents(date) {
      const [, month,] = date.split('-')
      let count = 0;
      switch (Number(month)) {
        case 1:
          count = this.getCountDate(this.datesJanuary, date)
          break
        case 2:
          count = this.getCountDate(this.datesFebruary, date)
          break
        case 3:
          count = this.getCountDate(this.datesMarch, date)
          break
        case 4:
          count = this.getCountDate(this.datesApril, date)
          break
        case 5:
          count = this.getCountDate(this.datesMay, date)
          break
        case 6:
          count = this.getCountDate(this.datesJune, date)
          break
        case 7:
          count = this.getCountDate(this.datesJuly, date)
          break
        case 8:
          count = this.getCountDate(this.datesAugust, date)
          break
        case 9:
          count = this.getCountDate(this.datesSeptember, date)
          break
        case 10:
          count = this.getCountDate(this.datesOctober, date)
          break
        case 11:
          count = this.getCountDate(this.datesNovember, date)
          break
        case 12:
          count = this.getCountDate(this.datesDecember, date)
          break
        default:
          return false;
      }
      if (count > 1){
        if (count == 2){
          return 'green'
        }
        if (count == 3){
          return 'blue'
        }
        if (count == 4){
          return 'yellow'
        }
        return 'red'
      }
      return false
    },
    showAlertSuccess() {
      this.alertSuccess = true
      setTimeout(this.hideAlertSuccess, 1500);
    },
    hideAlertSuccess() {
      this.alertSuccess = false
    },
    showAlertError() {
      this.alertError = true
      setTimeout(this.hideAlertError, 1500);
    },
    hideAlertError() {
      this.alertError = false
    },
    save() {
      HTTP.post(
          Settings.API.URL_DEFAULT + "/production-calendar/date-list", {
            "year": this.year,
            "date": this.datesJanuary.concat(
                this.datesJanuary,
                this.datesFebruary,
                this.datesMarch,
                this.datesApril,
                this.datesMay,
                this.datesJune,
                this.datesJuly,
                this.datesAugust,
                this.datesSeptember,
                this.datesOctober,
                this.datesNovember,
                this.datesDecember,
            )
          })
          .then(() => {
            this.getLessonCalendar()
            this.showAlertSuccess()
          })
          .catch(() => {
            this.showAlertError()
          })
          .finally(() => {
          });
    },
    setAllDay() {
      this.setJanuaryDys(this.lessonCalendar.january)
      this.setFebruaryDys(this.lessonCalendar.february)
      this.setMarchDys(this.lessonCalendar.march)
      this.setAprilDys(this.lessonCalendar.april)
      this.setMayDys(this.lessonCalendar.may)
      this.setJuneDys(this.lessonCalendar.june)
      this.setJulyDys(this.lessonCalendar.july)
      this.setAugustDys(this.lessonCalendar.august)
      this.setSeptemberDys(this.lessonCalendar.september)
      this.setOctober(this.lessonCalendar.october)
      this.setNovemberDys(this.lessonCalendar.november)
      this.setDecemberDys(this.lessonCalendar.december)

    },
    getLessonCalendar() {
      HTTP.get(
          Settings.API.URL_DEFAULT + "/lessons/lessons-calendar?workloadId=" + this.workloadId + "&year=" + this.year)
          .then((response) => {
            this.lessonCalendar = response.data
            this.setAllDay()
          })
          .catch(() => {
            this.errored = true;
          })
          .finally(() => {
          });

    },
    setJanuaryDys(item) {
      this.datesJanuary = []
      this.datesJanuary = item
    },
    setFebruaryDys(item) {
      this.datesFebruary = []
      this.datesFebruary = item
    },
    setMarchDys(item) {
      this.datesMarch = []
      this.datesMarch = item
    },
    setAprilDys(item) {
      this.datesApril = []
      this.datesApril = item
    },
    setMayDys(item) {
      this.datesMay = []
      this.datesMay = item
    },
    setJuneDys(item) {
      this.datesJune = []
      this.datesJune = item
    },
    setJulyDys(item) {
      this.datesJuly = []
      this.datesJuly = item
    },
    setAugustDys(item) {
      this.datesAugust = []
      this.datesAugust = item
    },
    setSeptemberDys(item) {
      this.datesSeptember = []
      this.datesSeptember = item
    },
    setOctober(item) {
      this.datesOctober = []
      this.datesOctober = item
    },
    setNovemberDys(item) {
      this.datesNovember = []
      this.datesNovember = item
    },
    setDecemberDys(item) {
      this.datesDecember = []
      this.datesDecember = item
    },
  },
  mounted() {
    this.year = this.$route.query.year
  },
  created() {
    this.workloadId = this.$route.query.plan
  },
  components: {}
}
</script>

<style lang="css">
.show-days-only .v-date-picker-header {
  display: none;
}

.overlay-alert {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 9999;
}


</style>