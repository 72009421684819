<template>
  <div>
    <v-app id="schedule">
      <v-container justify-center>
        <v-flex xs12>
          <v-row v-if="isAdmin()">
            <v-col
                cols="2"
                sm="5"
                md="5"
            >
              <v-autocomplete v-model="selectedGroup"
                              :items="groups"
                              label="Группа"
                              dense
                              prepend-icon="groups"
              />
            </v-col>

            <v-col
                cols="2"
                sm="5"
                md="5"
            >
              <v-autocomplete v-model="selectedDiscipline"
                              :items="disciplines"
                              label="Дисциплина"
                              dense
                              prepend-icon="school"
              />
            </v-col>
          </v-row>
          <v-tabs
              v-model="tab"
          >
            <v-tab>
              <v-icon left>
                mdi-account
              </v-icon>
              Статистика
            </v-tab>
            <!--            <v-tab>-->
            <!--              <v-icon left>-->
            <!--                mdi-lock-->
            <!--              </v-icon>-->
            <!--              Успеваемость-->
            <!--            </v-tab>-->
            <v-tab>
              <v-icon left>
                mdi-access-point
              </v-icon>
              Контрольные точки
            </v-tab>
<!--            <v-tab>-->
<!--              <v-icon left>-->
<!--                mdi-access-points-->
<!--              </v-icon>-->
<!--              Курсовой проект-->
<!--            </v-tab>-->
            <v-tab>
              <v-icon left>
                mdi-access-point
              </v-icon>
              Ведомость
            </v-tab>
          </v-tabs>
          <v-tabs-items
              v-model="tab"
              :touchless="true"
          >
            <v-tab-item

            >
<!--              <journal-->
<!--                  :groupId="selectedGroup"-->
<!--                  :disciplineId="selectedDiscipline"-->
<!--              ></journal>-->

              <v-container justify-center>
                <v-flex xs12>
                  <v-data-table
                      :headers="statistics.headers"
                      :items="statistics.items"
                      :items-per-page="40"
                      :hide-default-footer="true"
                      class="elevation-2"
                      :single-expand="true"
                      :loading="loadingSt"
                      loading-text="Загрузка..."
                      no-data-text="Нет информации"
                  >

                  </v-data-table>
                </v-flex>
              </v-container>
            </v-tab-item>
            <!--            <v-tab-item-->

            <!--            >-->
            <!--              <grade-book-->
            <!--                  :groupId="selectedGroup"-->
            <!--                  :disciplineId="selectedDiscipline"-->
            <!--              ></grade-book>-->
            <!--            </v-tab-item>-->
            <v-tab-item

            >
              <control-points
                  :groupId="selectedGroup"
                  :disciplineId="selectedDiscipline"
              ></control-points>
            </v-tab-item>
<!--            <v-tab-item-->

<!--            >-->
<!--              <course-project-->
<!--                  :groupId="selectedGroup"-->
<!--                  :disciplineId="selectedDiscipline"-->
<!--              ></course-project>-->
<!--            </v-tab-item>-->
            <v-tab-item

            >
              <exam
                  :groupId="selectedGroup"
                  :disciplineId="selectedDiscipline"
                  :year="1"
                  :halfYear="2"
              ></exam>
            </v-tab-item>
          </v-tabs-items>
        </v-flex>
      </v-container>
    </v-app>

  </div>
</template>

<script>
import Exam from "@/views/Exam";
// import Journal from "@/views/journal";
import ControlPoints from "@/views/ControlPointsRetake";
// import CourseProject from "@/views/CourseProject";
// import Gradebook from "@/views/Gradebook";
import Settings from "@/settings/settings_url";
import {HTTP} from '@/settings/axios'

export default {
  name: "RetakeVed",
  data() {
    return {
      tab: 1,
      groups: [],
      selectedGroup: 0,
      disciplines: [],
      selectedDiscipline: 0,
      statistics: [],
      loadingSt: false,
    }
  },
  watch: {
    tab: function () {
      if (this.tab == 0){
        this.getJournal()
      }
    },
    selectedGroup: function () {
      this.getDiscipline()
      this.getJournal()
    },
    selectedDiscipline: function (){
      this.getJournal()
    }
  },
  methods: {
    isAdmin(){
      for (let i = 0; i < this.$store.getters.user.role.length; i++ ){
        if (this.$store.getters.user.role[i].name === "ROLE_ADMIN") {
          return  true
        }
      }
    },
    getJournal:  function () {
      this.loadingSt = true
      HTTP.get(Settings.API.URL_DEFAULT + "/groups/"+ this.selectedGroup +"/retake-ved?disciplineId=" + this.selectedDiscipline + "&year=1&halfYear=2")
          .then((response) => {
            this.statistics = response.data
            console.log(this.statistics)
          })
          .catch(() => {
            this.errored = true;
          })
          .finally(() => (this.loadingSt = false));
    },
    getGroups: async function () {
      this.loading = true
      await HTTP.get(Settings.API.URL_GROUPS_BY_ROLE_USER_BRSO('2021-01-01', '2021-09-31'))
          .then((response) => {
            this.groups = response.data.map(function (item) {
              return {
                text: item.name,
                value: item.id
              }
            })
            //console.log(this.groups)
          })
          .catch(() => {
            this.errored = true;
          })
          .finally(() => {
            // this.calcSum()
            this.loading = false
          });
    },
    getDiscipline: async function () {
      this.loading = true
      await HTTP.get(Settings.API.URL_DISCIPLINES_BY_ROLE_USER_BRSO(this.selectedGroup, '2021-01-01', '2021-09-31'))
          .then((response) => {
            this.disciplines = response.data.map(function (item) {
              return {
                text: item.name,
                value: item.id
              }
            })
            //console.log(this.disciplines)
          })
          .catch(() => {
            this.errored = true;
          })
          .finally(() => {
            // this.calcSum()
            this.loading = false
          });
    },
  },
  created() {
    // this.selectedGroup = this.$route.query.group
    // this.selectedDiscipline = this.$route.query.discipline
    this.getGroups()
    this.tab = Number(this.$route.query.tab)
    if (
        this.$route.query.group != 0 && this.$route.query.discipline != 0
        && this.$route.query.group != undefined && this.$route.query.discipline != undefined
    ) {

      this.selectedGroup = Number(this.$route.query.group)
      this.selectedDiscipline = Number(this.$route.query.discipline)
      // console.log(this.selectedGroup)
      this.getJournal()
    }

  },
  components: {
    // 'journal': Journal,
    // 'grade-book': Gradebook,
    'control-points': ControlPoints,
    // 'course-project': CourseProject,
    'exam': Exam,
  }
}
</script>

<style lang="sass">


</style>





