<template>
  <v-container>
    <v-card>
      <v-card-title>
        <div>Дополнительные дисциплины</div>
      </v-card-title>
    </v-card>

    <v-btn
        class="mt-4 mb-4"
        color="primary"
        @click="newDiscipline()"
    >
      Добавить дисциплину
    </v-btn>
    <v-data-table
        :headers="headers"
        :items="disciplines"
        class="elevation-1"
        :items-per-page="50"
        :hide-default-footer="true">

      <template v-slot:item.edit="{ item }">
        <v-btn icon
               @click="editDiscipline(item)"
        >
          <v-icon>settings</v-icon>
        </v-btn>
      </template>

    </v-data-table>

    <template>
      <v-row justify="center">
        <v-dialog
            v-model="editDialog"
            persistent
            max-width="600px"
        >
          <v-card>
            <v-card-title>
              <span class="text-h5">Редактировать дисциплину</span>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col
                      cols="12"
                      sm="12"
                      md="12"
                  >
                    <v-text-field
                        label="Дисциплина"
                        single-line
                        v-model="disciplineSelect.name"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                  color="blue darken-1"
                  text
                  @click="editDialog = !editDialog"
              >
                Close
              </v-btn>
              <v-btn
                  color="blue darken-1"
                  text
                  @click="save()"
              >
                Save
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
    </template>

  </v-container>
</template>

<script>

import Settings from "@/settings/settings_url";
import {HTTP} from '@/settings/axios';

export default {
  name: "DisciplineAdmin",
  data(){
    return{
      headers: [
        {text: 'N', align: 'center', sortable: false, value: 'id',},
        {text: 'Дисциплина', value: 'name', sortable: true},
        {text: '', value: 'edit', sortable: false},
      ],
      disciplineSelect: {
        name:""
      },
      disciplines: [],
      editDialog: false,
    }
  },
  methods:{
    save(){
      HTTP.post(Settings.API.URL_DEFAULT + "/disciplines/manually", this.disciplineSelect)
          .then((response) => {
            console.log(response)
          })
          .catch(() => {

          })
          .finally(() => {
            this.getDisciplines()
            this.editDialog = false
          });
    },
    newDiscipline(){
      this.disciplineSelect = {
        name:""
      }
      this.editDialog = true
    },
    editDiscipline(item){
      this.newDiscipline()
      this.disciplineSelect = {...item}
    },
    getDisciplines: async function () {
      HTTP.get(Settings.API.URL_DEFAULT + "/disciplines/manually")
          .then((response) => {
            this.disciplines = response.data
            console.log(this.disciplines)
          })
          .catch(() => {

          })
          .finally(() => {

          });
    },
  },
  created() {
    this.getDisciplines()
  }
}
</script>

<style scoped>

</style>