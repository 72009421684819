<template>
  <v-container>
    <h1 class="text-center">Пропускные карты</h1>
    <v-row align="center" justify="center">
      <v-col cols="3">
        <v-text-field
            :disabled="disabled"
            v-model="familyNameFilter"
            clearable
            label="Фамилия"
            append-icon="search"
            @keydown.enter="getOrionUsers"
        />
      </v-col>
      <v-col cols="3">
        <v-text-field
            :disabled="disabled"
            v-model="nameFilter"
            clearable
            label="Имя"
            append-icon="search"
            @keydown.enter="getOrionUsers"
        />
      </v-col>
      <v-col cols=4>
        <v-text-field
            :disabled="disabled"
            v-model="patronymicFilter"
            clearable
            label="Отчество"
            append-icon="search"
            @keydown.enter="getOrionUsers"
        />
      </v-col>
      <v-col cols="2" class="search">
        <v-btn color="primary" @click="getOrionUsers" :disabled="disabled">
          <v-icon>search</v-icon>
          Найти
        </v-btn>
      </v-col>
    </v-row>
    <v-data-table
        :loading="loading"
        v-show="!disabled"
        :items="studs"
        :headers="headers"
        loading-text="Загрузка карт"
        class="elevation-2"
    >
      <template v-slot:item.actions="{ item }">
        <v-tooltip :disabled="item.id != userToLink.orionId" bottom>
          <template v-slot:activator="{ on, attrs }">
            <div v-on="on">
              <v-btn
                  :disabled="item.id == userToLink.orionId"
                  icon
                  color="success"
                  @click="openLinkDialog(item)"
                  v-bind="attrs"
                  v-on="on"
              >
                <v-icon>link</v-icon>
              </v-btn>
            </div>
          </template>
          <span>Уже привязан</span>
        </v-tooltip>
      </template>
      <template v-slot:item.photo="{ item }">
        <div class="d-flex justify-center">
        <v-img
            class="profile"
            color="grey"
            tile
            max-width="150px"
        >
          <v-img :src="'data:image/jpeg;base64,' + item.photo"/>
          <span v-if="item.photo == null">Нет фото</span>
        </v-img>
        </div>
      </template>
      <template v-slot:no-data>
        <v-alert :value="true" color="error" icon="warning">
          <v-row align="center">
            <v-col class="grow">
              {{ errorMessage }}
            </v-col>
          </v-row>
        </v-alert>
      </template>
    </v-data-table>
    <v-spacer/>
    <v-card>
      <v-row>
        <v-col/>
        <v-spacer/>
        <v-col>
          <v-btn color="primary" :disabled="disabled" @click="openCreateDialog()">
            <v-icon>add</v-icon>
            Создать аккаунт Orion
          </v-btn>
        </v-col>
      </v-row>
    </v-card>

    <v-card>
      <v-alert type="success" dismissible transition="scale-transition" v-model="successfullyAdded">
        Успешно создано! Не забудьте привязать аккаунт Orion к студенту!
      </v-alert>
    </v-card>

    <v-dialog v-model="linkDialog" max-width="800">
      <v-card>
        <v-toolbar
            dark
            color="primary"
        >
          <v-btn
              icon
              dark
              @click="closeLinkDialog()"
          >
            <v-icon>close</v-icon>
          </v-btn>
          <v-card-title>Вы уверены?</v-card-title>
        </v-toolbar>
        <v-card-title v-if="userToLink != null && selectedOrion != null">
          Привязать "<strong v-if="!useTeachers">{{ userToLink.student }}</strong>
          <strong v-if="useTeachers">{{userToLink.teacher}}</strong>"
          к карте пользователя
          "<strong>{{ selectedOrion.surname }} {{ selectedOrion.name }} {{ selectedOrion.patronymic }}</strong>"?
        </v-card-title>
        <v-card-actions>
          <v-spacer/>
          <v-btn
              color="primary"
              @click="linkStudentToOrion"
              :disabled="loading"
          >
            Привязать
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <create-orion @orionCreated="showOrionAddedAndGetOrion" ref="create"/>
  </v-container>
</template>

<script>
import {HTTP} from "@/settings/axios";
import Settings from "@/settings/settings_url";
import CreateOrion from "@/components/admin/OrionLinking/CreateOrion";

export default {
  name: "OrionCardsList",

  components: {
    CreateOrion,
  },

  data() {
    return {
      loading: false,
      linkDialog: false,
      studs: [],
      familyNameFilter: null,
      nameFilter: null,
      patronymicFilter: null,
      headers: [
        {text: 'Фамилия', align: 'center', sortable: true, value: 'surname', divider: true, class: "bolt"},
        {text: 'Имя', align: 'center', sortable: true, value: 'name', divider: true, class: "bolt"},
        {text: 'Отчество', align: 'center', sortable: true, value: 'patronymic', divider: true, class: "bolt"},
        {text: 'Фото', align: 'center', sortable: true, value: 'photo', divider: true, class: "bolt"},
        {text: 'Действия', align: 'center', sortable: true, value: 'actions', divider: true, class: "bolt"},
      ],
      userToLink: null,
      selectedOrion: null,
      errorMessage: "",
      successfullyAdded: false,
    }
  },
  methods: {
    openCreateDialog() {
      console.log(this.userToLink)
      this.$refs.create.cardStudent.name = this.userToLink.surname
      this.$refs.create.cardStudent.firstName = this.userToLink.name
      this.$refs.create.cardStudent.midName = this.userToLink.patronymic
      this.$refs.create.faculty = this.userToLink.groups.faculty
      this.$refs.create.cardStudent.direction = this.userToLink.groups.program
      this.$refs.create.cardStudent.oneCCode = this.userToLink.studentCode
      this.$refs.create.createOrionDialog = true
    },
    showOrionAddedAndGetOrion() {
      this.successfullyAdded = true
      this.getOrionUsers()
    },
    createOrion() {
      this.loading = true
    },
    linkStudentToOrion() {
      this.loading = true
      this.userToLink.orionId = this.selectedOrion.id
      let url = "/students"
      if(this.useTeachers)
        url = "/teachers"

      HTTP.post(Settings.API.URL_DEFAULT + url + "/update-orion-id", this.userToLink)
          .then((response) => {
            this.userToLink = response.data
            this.$emit("studentLinked")
          }).catch((err) => {
        console.log(err)
      }).finally(() => {
        this.loading = false
        this.closeLinkDialog()
      })
    },
    closeLinkDialog() {
      this.linkDialog = false
    },
    openLinkDialog(item) {
      this.selectedOrion = item
      this.linkDialog = true
    },
    resetFilters() {
      this.familyNameFilter = null
      this.nameFilter = null
      this.patronymicFilter = null
    },
    getOrionUsers() {
      this.loading = true

      HTTP.get(Settings.API.URL_DEFAULT
          + "/users-orion/by-fio?name=" + this.nameFilter
          + "&surname=" + this.familyNameFilter
          + "&patronymic=" + this.patronymicFilter)
          .then((response) => {
            console.log(response.data)
            this.studs = response.data
            if (this.studs.length == 0)
              this.errorMessage = "Карт не найдено. Попробуйте изменить фильтры"
          })
          .catch((err) => {
            this.studs = []
            this.errorMessage = err.response.data
          })
          .finally(() => {
            this.loading = false
          });
    },
  },
  watch: {
    'familyNameFilter'() {
      if (this.familyNameFilter === null)
        this.familyNameFilter = ""
    },
    'nameFilter'() {
      if (this.nameFilter === null)
        this.nameFilter = ""
    },
    'patronymicFilter'() {
      if (this.patronymicFilter === null)
        this.patronymicFilter = ""
    },
  },
  props: {
    disabled: {
      default: false,
      type: Boolean,
    },
    useTeachers: {
      default: false,
      type: Boolean,
    },
  },
}
</script>

<style scoped>
.search {
  padding-bottom: 21px;
}
</style>