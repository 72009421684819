var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[(_vm.items.length > 0)?_c('v-btn',{attrs:{"text":""},on:{"click":_vm.getPreviousFolders}},[_c('v-icon',[_vm._v("arrow_back")]),_vm._v(" Назад ")],1):_vm._e(),_c('v-breadcrumbs',{attrs:{"items":_vm.items,"divider":"/"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('v-breadcrumbs-item',[(item.name === undefined)?_c('span',[_vm._v(_vm._s(item.fullname.toUpperCase()))]):_vm._e(),(item.name !== undefined)?_c('span',[_vm._v(_vm._s(item.name.toUpperCase()))]):_vm._e()])]}}])}),_c('div',{staticClass:"text-center"},[_c('v-progress-circular',{directives:[{name:"show",rawName:"v-show",value:(_vm.loading),expression:"loading"}],attrs:{"indeterminate":_vm.loading,"active":_vm.loading,"color":"primary"}})],1),_c('v-card',[_c('v-list',[(_vm.folders && _vm.folders.length > 0)?_c('div',[_c('v-card-subtitle',[_c('strong',[_vm._v("Папки:")])])],1):_vm._e(),_vm._l((_vm.folders),function(folder){return _c('v-list-item',{key:folder.id,on:{"click":function($event){if($event.target !== $event.currentTarget){ return null; }return _vm.getNextFolders(folder)}}},[_c('v-icon',[_vm._v("folder")]),_vm._v(" "+_vm._s(folder.name)+" "),_c('v-spacer'),_c('v-tooltip',{attrs:{"bottom":"","color":"secondary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g({},on),[_c('v-btn',_vm._g(_vm._b({attrs:{"text":"","color":"secondary"},on:{"click":function($event){return _vm.goToUrl('https://lms2.sseu.ru/course/index.php?categoryid='+folder.id)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("language")])],1)],1)]}}],null,true)},[_c('span',[_vm._v("Перейти в ЭИОС в новой вкладке")])])],1)}),(_vm.sections && _vm.sections.length > 0)?_c('div',[_c('v-divider'),_c('v-card-subtitle',[_c('strong',[_vm._v("Разделы:")])])],1):_vm._e(),_vm._l((_vm.sections),function(section){return _c('v-list-item',{key:section.id,on:{"click":function($event){if($event.target !== $event.currentTarget){ return null; }return _vm.getAssignsAndQuizzesByModule(section, 'QUIZ')}}},[_c('v-icon',[_vm._v("school")]),_vm._v(" "+_vm._s(section.name)+" "),_c('v-spacer'),_c('v-tooltip',{attrs:{"bottom":"","color":"secondary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g({},on),[_c('v-btn',_vm._g(_vm._b({attrs:{"text":"","color":"secondary"},on:{"click":function($event){return _vm.goToUrl('https://lms2.sseu.ru/course/view.php?id=' + section.course + '&sectionid=' + section.id)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("language")])],1)],1)]}}],null,true)},[_c('span',[_vm._v("Перейти в ЭИОС в новой вкладке")])])],1)}),(_vm.disciplines && _vm.disciplines.length > 0)?_c('div',[_c('v-divider'),_c('v-card-subtitle',[_c('strong',[_vm._v("Дисциплины:")])])],1):_vm._e(),_vm._l((_vm.disciplines),function(discipline){return _c('v-list-item',{key:discipline.id,on:{"click":function($event){if($event.target !== $event.currentTarget){ return null; }return _vm.getAssignsAndQuizzes(discipline, true)}}},[_c('v-icon',[_vm._v("school")]),_vm._v(" "+_vm._s(discipline.fullname)+" "),_c('v-spacer'),_c('v-tooltip',{attrs:{"bottom":"","color":"secondary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g({},on),[_c('v-btn',_vm._g(_vm._b({attrs:{"text":"","color":"secondary"},on:{"click":function($event){return _vm.goToUrl('https://lms2.sseu.ru/course/view.php?id='+discipline.id)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("language")])],1)],1)]}}],null,true)},[_c('span',[_vm._v("Перейти в ЭИОС в новой вкладке")])])],1)}),(_vm.quizzes && _vm.quizzes.length > 0)?_c('div',[_c('v-divider'),_c('v-card-subtitle',[_c('strong',[_vm._v("ТТК:")])])],1):_vm._e(),_vm._l((_vm.quizzes),function(quiz){return _c('v-list-item',{key:quiz.id,class:_vm.selectedCP === quiz ? 'selected-row' : '',on:{"click":function($event){if($event.target !== $event.currentTarget){ return null; }return _vm.assignOrQuizSelected(quiz, 'QUIZ')}}},[_c('v-icon',{attrs:{"color":"green"}},[_vm._v("circle")]),_vm._v(" "+_vm._s(quiz.name)+" "),_c('v-spacer'),_c('v-tooltip',{attrs:{"bottom":"","color":"secondary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g({},on),[_c('v-btn',_vm._g(_vm._b({attrs:{"text":"","color":"secondary"},on:{"click":function($event){return _vm.goToUrl('https://lms2.sseu.ru/mod/quiz/view.php?id='+quiz.courseModuleId)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("language")])],1)],1)]}}],null,true)},[_c('span',[_vm._v("Перейти в ЭИОС в новой вкладке")])])],1)}),(_vm.assigns && _vm.assigns.length > 0)?_c('div',[_c('v-divider'),_c('v-card-subtitle',[_c('strong',[_vm._v("ТАА:")])])],1):_vm._e(),_vm._l((_vm.assigns),function(assign){return _c('v-list-item',{key:assign.id,class:_vm.selectedCP === assign ? 'selected-row' : '',on:{"click":function($event){if($event.target !== $event.currentTarget){ return null; }return _vm.assignOrQuizSelected(assign, 'ASSIGN')}}},[_c('v-icon',{attrs:{"color":"blue"}},[_vm._v("circle")]),_vm._v(" "+_vm._s(assign.name)+" "),_c('v-spacer'),_c('v-tooltip',{attrs:{"bottom":"","color":"secondary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g({},on),[_c('v-btn',_vm._g(_vm._b({attrs:{"text":"","color":"secondary"},on:{"click":function($event){return _vm.goToUrl('https://lms2.sseu.ru/mod/assign/view.php?id=' + assign.courseModuleId)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("language")])],1)],1)]}}],null,true)},[_c('span',[_vm._v("Перейти в ЭИОС в новой вкладке")])])],1)})],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }