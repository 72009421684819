<template>
  <div>
    <v-app id="schedule">
      <v-container justify-center>
        <v-flex xs12>
          <v-card
              :loading=loading
          >
            <v-card-title>Сформировать расписание</v-card-title>
            <v-card-text>
              <v-row>
                <v-col class="mr-10 ">
                  <v-autocomplete v-model="typesEducation"
                                  :items="typesEducations"
                                  label="Вид образования"
                                  dense
                  >
                  </v-autocomplete>
                </v-col>
                <v-col class="mr-10">
                  <v-autocomplete v-model="yearOfStud"
                                  :items="yearOfStuds"
                                  label="Год обучения"
                                  dense
                  >
                  </v-autocomplete>
                </v-col>
                <v-col class="mr-10">
                  <v-autocomplete v-model="halfYear"
                                  :items="halfYears"
                                  label="Полугодие"
                                  dense
                  >
                  </v-autocomplete>
                </v-col>

              </v-row>
              <v-row>
                <v-col class="mr-10">
                  <v-autocomplete v-model="course"
                                  :items="courses"
                                  label="Курс"
                                  dense
                  >
                  </v-autocomplete>
                </v-col>
                <v-col class="mr-10">
                  <v-autocomplete v-model="scheduleType"
                                  :items="scheduleTypes"
                                  label="Тип расписания"
                                  dense
                  >
                  </v-autocomplete>
                </v-col>

                <v-col class="mr-10">
                  <v-autocomplete v-model="shift"
                                  :items="shifts"
                                  label="Смена"
                                  dense
                  >
                  </v-autocomplete>
                </v-col>

                <v-col class="mr-10">
                  <v-autocomplete v-model="parity"
                                  :items="parityList"
                                  label="Неделя"
                                  dense
                  >
                  </v-autocomplete>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                  @click="downloadJournalGET"
                  color="primary"
              >Открыть
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-flex>
      </v-container>
    </v-app>
  </div>

</template>

<script>

import Settings from "@/settings/settings_url";
import {HTTP} from '@/settings/axios'

export default {
  name: "SchedulePrint",
  data() {
    return {
      loading: false,
      scheduleType: null,
      scheduleTypes: [],
      course: null,
      parityList:[
        {
          text: "Нечетная",
          value: 1
        },
        {
          text: "Четная",
          value: 2
        },
      ],
      parity: null,
      courses: [
        {
          text: "1 курс",
          value: 1
        },
        {
          text: "2 курс",
          value: 2
        },
        {
          text: "3 курс",
          value: 3
        },
        {
          text: "4 курс",
          value: 4
        },
        {
          text: "5 курс",
          value: 5
        },
        {
          text: "6 курс",
          value: 6
        },
      ],
      shift: null,
      shifts: [
        {
          text: "1 смена",
          value: 1
        },
        {
          text: "2 смена",
          value: 2
        },
        {
          text: "-------",
          value: 3
        },
      ],
      yearOfStud: null,
      yearOfStuds: [
        {
          text: "2020-2021",
          value: "2020-2021"
        },
      ],
      halfYear: null,
      halfYears: [
        {
          text: "1 полугодие",
          value: 1
        },
        {
          text: "2 полугодие",
          value: 2
        },
      ],
      typesEducation: null,
      typesEducations: [
        {
          text: "СПО",
          value: "SPO"
        },
        {
          text: "Бакалавриат",
          value: "BACCALAUREATE"
        },
        {
          text: "Магистратура",
          value: "MAGISTRACY"
        },
        {
          text: "Специалитет",
          value: "SPECIALTY"
        },
      ],
    }
  },
  watch: {},
  methods: {

    startLoading: function () {
      this.loading = true
    },
    stopLoading: function () {
      this.loading = false
    },

    getScheduleType: async function () {
      this.startLoading()
      await HTTP.get(Settings.API.URL_DEFAULT + Settings.URL_SCHEDULE_TYPE)
          .then((response) => {
            this.scheduleTypes = response.data.map(function (item) {
              return {
                text: item.name,
                value: item.id
              }
            })
          })
          .catch(() => {
            this.errored = true;
          })
          .finally(() => (this.stopLoading()));
    },

    fileDownload(response) {
      const url = window.URL.createObjectURL(new Blob([response.data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', 'schedule.pdf')
      document.body.appendChild(link)
      link.click()
    },


    // downloadJournalXMLHttp: function () {
    //   let username = 'hs';
    //   let password = 'hs';
    //   let credentials = btoa(username + ':' + password);
    //   let basicAuth = 'Basic ' + credentials;
    //   var xhr = new XMLHttpRequest();
    //   //xhr.open('get', 'http://10.10.11.201:5817/shabdev/hs/schedule', true);
    //   xhr.open('get', 'http://localhos:8081/api/v1/1c/shabdev/hs/schedule', true);
    //   xhr.setRequestHeader('Authorization', basicAuth);
    //   xhr.onload = this.onload
    //   xhr.onerror = this.onerror
    //   xhr.send();
    //
    // },

    downloadJournalGET: function () {
      let link = Settings.API.URL_DEFAULT + "/schedule/schedule-file?course="+
          this.course + "&halfYear=" + this.halfYear + "&parity=" +
          this.parity + "&scheduleType=" + this.scheduleType + "&shift=" +
          this.shift + "&yearOfStudy=" + this.yearOfStud + "&typesEducation=" + this.typesEducation;
      window.open(link);
    },
    // downloadJournal: function () {
    //   // let username = 'hs';
    //   // let password = 'hs';
    //   // let credentials = btoa(username + ':' + password);
    //   // let basicAuth = 'Basic ' + credentials;
    //   HTTP.get("http://localhost:8081/api/v1/schedule/schedule-file?course=2&halfYear=2&parity=2&shift=1&yearOfStudy=2020-2021"
    //   ).then((response) => {
    //     this.fileDownload(response)
    //   }).catch(error => {
    //   }).finally(() => {
    //     this.stopLoading()
    //   });
    // },
  },
  created() {
    this.getScheduleType()
  },
  components: {}
}
</script>

<style lang="css">
.td-border-right {

}


</style>
