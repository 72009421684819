<template>
  <div>
    <v-app id="schedule">
      <v-container justify-center>
        <v-flex xs12>
          <v-card>
            <v-card-text>
              <v-layout row wrap>
                <v-flex xs12 md12>
                  <v-card-actions class="justify-center">
                    <h1>Статистика - {{ statistics.title }}</h1>
                  </v-card-actions>

                </v-flex>
                <v-flex xs12 md6>

                </v-flex>
                <v-flex xs12 md6>

                </v-flex>
              </v-layout>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>

            </v-card-actions>
          </v-card>
        </v-flex>
      </v-container>
      <v-container>
        <v-row class="mt-6 ml-2">
          <v-col class="mr-10">
            <v-autocomplete v-model="student"
                            :items="students"
                            label="студент"
                            dense
                            :loading="stLoading"
                            item-value="id"
                            item-text="student"
                            return-object
            >
            </v-autocomplete>
          </v-col>
          <v-col class="mr-4">
            <v-autocomplete v-model="group"
                            :items="groups"
                            label="Группа"
                            dense
                            item-value="id"
                            item-text="name"
                            return-object
            >
            </v-autocomplete>
          </v-col>
        </v-row>
        <v-row class="mt-6 ml-2">
          <v-col class="mr-10">
            <v-autocomplete v-model="yearOfStudTable"
                            :items="yearOfStudsTable"
                            label="Год обучения"
                            dense
                            item-value="id"
                            item-text="name"
                            return-object
            >
            </v-autocomplete>
          </v-col>
          <v-col class="mr-4">
            <v-autocomplete v-model="halfYearTable"
                            :items="halfYearsTable"
                            label="Полугодие"
                            dense
                            item-value="id"
                            item-text="name"
                            return-object
            >
            </v-autocomplete>
          </v-col>
        </v-row>
      </v-container>
      <v-container justify-center>
        <div class="text-center">Текущая группа</div>
        <v-flex xs12>
          <v-data-table
              :headers="statistics.headersV"
              :items="statistics.itemsV"
              :items-per-page="40"
              :hide-default-footer="true"
              class="elevation-2"
              :single-expand="true"
              :loading="loading"
          >
                        <template v-slot:item.name="{ item }">
                          <span @click="openDialog(item.disciplineId, item.groupId)">
                            {{ item.name }}
                          </span>
                        </template>


            <!--            <template v-slot:item.controlPoint="{ item }">-->
            <!--              <v-btn text :to="'/journal-all?discipline=' + item.disciplineId + '&group=' + item.groupId  + '&tab=1' + '&year=' + yearOfStudTable.id + '&halfYear=' + halfYearTable.id" icon>-->
            <!--                {{ item.controlPoint }}-->
            <!--              </v-btn>-->
            <!--            </template>-->


          </v-data-table>


        </v-flex>
      </v-container>
      <v-container justify-center>
        <div class="text-center">Выбраная группа</div>
        <v-flex xs12>
          <v-data-table
              :headers="transferStatistics.headersV"
              :items="transferStatistics.itemsV"
              :items-per-page="40"
              :hide-default-footer="true"
              class="elevation-2"
              :single-expand="true"
              :loading="loadingTransferStat"
          >


          </v-data-table>


        </v-flex>


        <v-dialog
            v-model="dialog"
            persistent
            max-width="600px"
        >
          <v-card>
            <v-card-title>
              <span class="text-h5">Add</span>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                        v-model="evaluation"
                        label="Баллы"
                        required
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                  color="blue darken-1"
                  text
                  @click="dialog = false"
              >
                Close
              </v-btn>
              <v-btn
                  color="blue darken-1"
                  text
                  @click="dialog = false"
              >
                Save
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>


      </v-container>
    </v-app>
  </div>
</template>

<script>
import Settings from "@/settings/settings_url";
import {HTTP} from '@/settings/axios'


export default {
  components: {
    // BarChart,
    // PieChart,
    //BarChart
  },
  name: "StudentStatisticsTransfer",
  data() {
    return {
      dialog: false,
      yearOfStudTable: {},
      yearOfStudsTable: [],
      halfYearTable: {},
      halfYearsTable: [],
      statistics: {},
      transferStatistics: {},
      loading: false,
      stLoading: false,
      loadingTransferStat: false,
      group: {},
      groups: [],

      student: {},
      students: [],
      disciplineId:0,
      groupId:0,
      evaluation: 0,
    };
  },
  watch: {
    student: function () {
      this.loading = true
      if (this.yearOfStudTable.id != undefined && this.halfYearTable.id != undefined) {
        this.getStatistics()
      }
    },
    group: function () {
      if (this.student.id != undefined && this.yearOfStudTable.id != undefined && this.halfYearTable.id != undefined) {
        this.getTransferStatistics()
      }
    },
    'yearOfStudTable.id': function () {
      if (this.yearOfStudTable.id != undefined && this.halfYearTable.id != undefined) {
        this.getStatistics()
      }
      if (this.student.id != undefined && this.yearOfStudTable.id != undefined && this.halfYearTable.id != undefined) {
        this.getTransferStatistics()
      }
    },
    'halfYearTable.id': function () {
      if (this.yearOfStudTable.id != undefined && this.halfYearTable.id != undefined) {
        this.getStatistics()
      }
      if (this.student.id != undefined && this.yearOfStudTable.id != undefined && this.halfYearTable.id != undefined) {
        this.getTransferStatistics()
      }
    },
  },
  methods: {
    openDialog(dId, gId){
      this.disciplineId = dId;
      this.groupId = gId;
      this.dialog = true
    },
    add(){
      HTTP.post(Settings.API.URL_DEFAULT + "/assessment-compensation-transfer",{
        evaluation: this.evaluation,
        student: this.student.id,
        discipline: this.disciplineId,
        group: this.groupId,
        yearOfStudy: this.yearOfStudTable.id,
        halfYear: this.halfYearTable.id,
      })
          .then((response) => {
            this.groups = response.data
          })
          .catch(() => {
          })
          .finally(() => {

          });
    },
    getGroups: async function () {
      HTTP.get(Settings.API.URL_DEFAULT + "/schedule-board/groups")
          .then((response) => {
            this.groups = response.data
          })
          .catch(() => {
          })
          .finally(() => {

          });
    },
    getStudents: async function () {
      this.stLoading = true

      HTTP.get(Settings.API.URL_DEFAULT + "/schedule-board/students")
          .then((response) => {
            this.students = response.data
          })
          .catch(() => {
          })
          .finally(() => {
            this.stLoading = false
          });
    },
    getYearTable: async function () {
      HTTP.get(Settings.API.URL_DEFAULT + "/year-of-study")
          .then((response) => {
            this.yearOfStudsTable = response.data
          })
          .catch(() => {

          })
          .finally(() => {
            for (let i = 0; i < this.yearOfStudsTable.length; i++) {
              if (this.yearOfStudsTable[i].id == 1) {
                this.yearOfStudsTable.splice(i, 1)
                continue
              }
              if (this.yearOfStudsTable[i]?.current == 1) {
                this.yearOfStudTable = this.yearOfStudsTable[i]
              }
            }
          });
    },
    getHalfYearTable: async function () {
      HTTP.get(Settings.API.URL_DEFAULT + "/half-year")
          .then((response) => {
            this.halfYearsTable = response.data
          })
          .catch(() => {

          })
          .finally(() => {
            for (let i = 0; i < this.halfYearsTable.length; i++) {
              if (this.halfYearsTable[i].current == 1) {
                this.halfYearTable = this.halfYearsTable[i]
                break
              }
            }
          });
    },
    getStatistics: async function () {
      this.loading = true
      this.statistics = {}
      await HTTP.get(Settings.API.URL_STUDENTS_STATISTICS(this.student.id, this.yearOfStudTable.id, this.halfYearTable.id))
          .then((response) => {
            this.statistics = response.data
            console.log(this.statistics)
          }).finally(() => {
            this.loading = false
          })
    },
    getTransferStatistics: async function () {
      this.loadingTransferStat = true
      this.transferStatistics = {}
      await HTTP.get(Settings.API.URL_DEFAULT + "/students/statistics-by-group?studentId=" + this.student.id + "&groupId=" + this.group.id + "&yearId=" + this.yearOfStudTable.id + "&halfYearId=" + this.halfYearTable.id)
          .then((response) => {
            this.transferStatistics = response.data
          }).finally(() => {
            this.loadingTransferStat = false
          })
    },
  },

  async mounted() {
    await this.getStudents()
    await this.getGroups()
    await this.getHalfYearTable()
    await this.getYearTable()

    // this.getStatistics()
  },
  created() {
  }
}
</script>

<style scoped>

</style>
