<template>
  <v-container>
    <v-btn text v-if="items.length > 0" @click="getPreviousFolders">
      <v-icon>arrow_back</v-icon>
      Назад
    </v-btn>

    <v-breadcrumbs
        :items="items"
        divider="/"
    >
      <template v-slot:item="{ item }">
        <v-breadcrumbs-item>
          <span v-if="item.name === undefined">{{ item.fullname.toUpperCase() }}</span>
          <span v-if="item.name !== undefined">{{ item.name.toUpperCase() }}</span>

        </v-breadcrumbs-item>
      </template>
    </v-breadcrumbs>

    <div class="text-center">
      <v-progress-circular
          :indeterminate="loading"
          :active="loading"
          color="primary"
          v-show="loading"
      />
    </div>

    <v-card>
    <v-list>
      <div v-if="folders && folders.length > 0">
        <v-card-subtitle><strong>Папки:</strong></v-card-subtitle>
      </div>
      <v-list-item @click.self="getNextFolders(folder)" v-for="folder in folders" :key="folder.id">
          <v-icon>folder</v-icon>
          {{ folder.name }}
        <v-spacer/>
        <v-tooltip bottom color="secondary">
          <template v-slot:activator="{ on, attrs }">
            <div v-on="on">
              <v-btn v-bind="attrs"
                     v-on="on"
                     text
                     color="secondary"
                     @click="goToUrl('https://lms2.sseu.ru/course/index.php?categoryid='+folder.id)"
              >
                <v-icon>language</v-icon>
              </v-btn>
            </div>
          </template>
          <span>Перейти в ЭИОС в новой вкладке</span>
        </v-tooltip>
      </v-list-item>
      <div v-if="sections && sections.length > 0">
        <v-divider/>
        <v-card-subtitle><strong>Разделы:</strong></v-card-subtitle>
      </div>
      <v-list-item @click.self="getAssignsAndQuizzesByModule(section, 'QUIZ')" v-for="section in sections" :key="section.id">
        <v-icon>school</v-icon>
        {{ section.name }}
        <v-spacer/>
        <v-tooltip bottom color="secondary">
          <template v-slot:activator="{ on, attrs }">
            <div v-on="on">
              <v-btn v-bind="attrs"
                     v-on="on"
                     text
                     color="secondary"
                     @click="goToUrl('https://lms2.sseu.ru/course/view.php?id=' + section.course + '&sectionid=' + section.id)"
              >
                <v-icon>language</v-icon>
              </v-btn>
            </div>
          </template>
          <span>Перейти в ЭИОС в новой вкладке</span>
        </v-tooltip>
      </v-list-item>
      <div v-if="disciplines && disciplines.length > 0">
        <v-divider/>
        <v-card-subtitle><strong>Дисциплины:</strong></v-card-subtitle>
      </div>
      <v-list-item @click.self="getAssignsAndQuizzes(discipline, true)" v-for="discipline in disciplines" :key="discipline.id">
          <v-icon>school</v-icon>
          {{ discipline.fullname }}
        <v-spacer/>
        <v-tooltip bottom color="secondary">
          <template v-slot:activator="{ on, attrs }">
            <div v-on="on">
              <v-btn v-bind="attrs"
                     v-on="on"
                     text
                     color="secondary"
                     @click="goToUrl('https://lms2.sseu.ru/course/view.php?id='+discipline.id)"
              >
                <v-icon>language</v-icon>
              </v-btn>
            </div>
          </template>
          <span>Перейти в ЭИОС в новой вкладке</span>
        </v-tooltip>
      </v-list-item>
      <div v-if="quizzes && quizzes.length > 0">
        <v-divider/>
        <v-card-subtitle><strong>ТТК:</strong></v-card-subtitle>
      </div>
      <v-list-item @click.self="assignOrQuizSelected(quiz, 'QUIZ')" v-for="quiz in quizzes" :key="quiz.id" :class="selectedCP === quiz ? 'selected-row' : ''">
        <v-icon color="green">circle</v-icon>
        {{ quiz.name }}
        <v-spacer/>
        <v-tooltip bottom color="secondary">
          <template v-slot:activator="{ on, attrs }">
            <div v-on="on">
              <v-btn v-bind="attrs"
                     v-on="on"
                     text
                     color="secondary"
                     @click="goToUrl('https://lms2.sseu.ru/mod/quiz/view.php?id='+quiz.courseModuleId)"
              >
                <v-icon>language</v-icon>
              </v-btn>
            </div>
          </template>
          <span>Перейти в ЭИОС в новой вкладке</span>
        </v-tooltip>
      </v-list-item>
      <div v-if="assigns && assigns.length > 0">
        <v-divider/>
        <v-card-subtitle><strong>ТАА:</strong></v-card-subtitle>
      </div>
      <v-list-item @click.self="assignOrQuizSelected(assign, 'ASSIGN')" v-for="assign in assigns" :key="assign.id" :class="selectedCP === assign ? 'selected-row' : ''">
        <v-icon color="blue">circle</v-icon>
        {{ assign.name }}
        <v-spacer/>
        <v-tooltip bottom color="secondary">
          <template v-slot:activator="{ on, attrs }">
            <div v-on="on">
              <v-btn v-bind="attrs"
                     v-on="on"
                     text
                     color="secondary"
                     @click="goToUrl('https://lms2.sseu.ru/mod/assign/view.php?id=' + assign.courseModuleId)"
              >
                <v-icon>language</v-icon>
              </v-btn>
            </div>
          </template>
          <span>Перейти в ЭИОС в новой вкладке</span>
        </v-tooltip>
      </v-list-item>
    </v-list>
    </v-card>
  </v-container>
</template>

<script>
import {HTTP} from "@/settings/axios";
import Settings from "@/settings/settings_url";

export default {
  name: "LmsControlPoints",

  data() {
    return {
      folders: [],
      disciplines: [],
      loading: false,
      currentParent: null,
      previousParent: null,
      items: [],
      assigns: [],
      quizzes: [],
      files: [],
      sections: [],

      selectedCP: null,
    }
  },
  methods: {
    goToUrl(item) {
      window.open(item, '_blank').focus()
    },
    assignOrQuizSelected(cp, type) {
      cp.controlPointType = type;
      this.selectedCP = cp

      this.$emit('cp-selected', cp)

      this.getFilesIfAllowed(cp)
    },
    getFilesIfAllowed(cp) {
      if(!this.showFiles)
        return

      this.items.push(cp)
      console.log(cp.id)
      console.log(this.selectedStudentId)

      this.loading = true
      this.clearAllItems()

      HTTP.get(Settings.API.URL_DEFAULT + "/courses/lms/assigns/files?assignId=" + cp.id + "&userId=" + this.selectedStudentId)
          .then((response) => {
            console.log(response.data)
            this.files = response.data
          }).finally(() => this.loading = false)
    },
    getAssignsAndQuizzesByModule(section) {
      this.items.push(section)

      this.loading = true
      this.clearAllItems()

      HTTP.get(Settings.API.URL_DEFAULT + "/courses/lms/assigns/from-section?section=" + section.id)
          .then((response) => {
            this.assigns = response.data
          }).finally(() => {
        this.loading = false
      });

      // HTTP.get(Settings.API.URL_DEFAULT + "/courses/lms/quizzes/from-section?section=" + section.id)
      //     .then((response) => {
      //       this.quizzes = response.data
      //     }).finally(() => {
      //   this.loading = false
      // });
    },
    getAssignsAndQuizzes(discipline, shouldPush) {
      if(shouldPush)
        this.items.push(discipline)

      this.loading = true
      this.clearAllItems()

      HTTP.get(Settings.API.URL_DEFAULT + "/courses/lms/course-sections?course=" + discipline.id)
          .then((response) => {
            this.sections = response.data
          })

      HTTP.get(Settings.API.URL_DEFAULT + "/courses/lms/assigns?course=" + discipline.id)
          .then((response) => {
            this.assigns = response.data
          })

      HTTP.get(Settings.API.URL_DEFAULT + "/courses/lms/quizzes?course=" + discipline.id)
          .then((response) => {
            this.quizzes = response.data
          }).finally(() => {
        this.loading = false
      });
    },
    clearAllItems() {
      this.disciplines = []
      this.quizzes = []
      this.assigns = []
      this.folders = []
      this.sections = []
    },
    getInitialFolders() {
      this.loading = true
      this.clearAllItems()

      HTTP.get(Settings.API.URL_DEFAULT + "/courses/lms/folders/initial")
          .then((response) => {
            this.folders = response.data
          }).finally(() => {
            this.loading = false
      });
    },
    getPreviousFolders() {
      let item = this.items.pop()
      this.clearAllItems()

      console.log(item)

      if (item.parent === undefined && item.category === undefined) {
        item.id = item.course
        this.getAssignsAndQuizzes(item, false)
      } else if(item.parent === undefined) {
        this.getFolders(item.category)
        this.getCPs(item.category)
      } else if(item.parent == 0 || item.parent == 119) {
        this.getInitialFolders()
      } else {
        this.getFolders(item.parent)
        this.getCPs(item.parent)
      }
    },
    getNextFolders(folder) {
      this.items.push(folder)
      this.getFolders(folder.id)
      this.getCPs(folder.id)
    },
    getFolders(folderId) {
      this.loading = true

      HTTP.get(Settings.API.URL_DEFAULT + "/courses/lms/folders?parent=" + folderId)
          .then((response) => {
            this.folders = response.data
      }).finally(() => {
        this.loading = false
      });
    },
    getCPs(folderId) {
      this.loading = true

      HTTP.get(Settings.API.URL_DEFAULT + "/courses/lms?category=" + folderId)
          .then((response) => {
            this.disciplines = response.data
          }).finally(() => {
        this.loading = false
        // this.dialog = false
      });
    },
    getPracticeFolders() {
      this.loading = true

      HTTP.get(Settings.API.URL_DEFAULT + "/courses/lms/courses/000000system")
          .then((response) => {
            this.disciplines = response.data
          }).finally(() => {
        this.loading = false
      });
    }
  },
  created() {
    this.getInitialFolders()
  },
  props: {
    showPracticeCourses: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style scoped>
.selected-row {
  background-color: grey;
}
</style>