<template>
  <v-row justify="center">
    <v-dialog
        v-model="show"
        persistent
        max-width="600px"
    >
      <v-card>
        <v-card-title>
          <span class="headline">Удалить пару на {{lessons.date}}</span>
        </v-card-title>
        <v-card-text>
          <v-container>
          <div>{{lessons.groups.name}}</div>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-btn
              color="red darken-1"
              text
              @click="deleteList(lessons)"
          >
            Удалить
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
              color="blue darken-1"
              text
              @click="closeEditLessonDialog"
              :disabled="!closeActive"
          >
            Закрыть
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </v-row>
</template>

<script>
import Settings from "@/settings/settings_url";
import {HTTP} from '@/settings/axios'

// import TeacherLinkToAudience from '@/components/schedule/dialog/TeacherLinkToAudience';


export default {
  name: "LessonsEdit",
  components: {

  },
  props: {
    show: Boolean,
    title: String,
    lessons: Object,
    yearOfStud: Object,
    halfYear: Object,
    // lessonNumbers: Object,
    // lessonTypeDays: Object,
    // date: String,
    // schedule: Object,
    // scheduleType: Object,
  },
  data() {
    return {
      curriculums: [],
      selectCurriculums: [],
      replacementTeachers: [],
      audiences: [],
      retake: false,
      loadingCurriculum: false,
      closeActive: true,
      // dialogTeacherLink: false,
      // streamLessons: [],
      dialog_duplicate_teacher: false,
      dialog_duplicate_audience: false,
      duplicate_lesson: [],
      lessonsC: {
        audiences: [],
        date: this.lessons.date,
        id: null,
        isCheckDuplicateAudience: null,
        isCheckDuplicateTeacher: null,
        lessonNumbers: this.lessons.lessonNumbers,
        lessonTypeDays: this.lessons.lessonTypeDays,
        numberLessons: this.lessons.numberLessons,
        numberLessonsAdded: null,
        numberLessonsPlan: null,
        schedule: this.lessons.schedule,
        scheduleType: this.lessons.scheduleType,
        teacherLinkToAudience: null,
        workload: null,
        workloadStreamLessons: null,
      },
      originalLess: [],
    }
  },
  computed: {
    ignoreDouble: {
      get() {
        return this.$store.getters.ignoreDoubleTeacherAndAudienceForSchedule;
      },
      set(value) {
        this.$store.commit('updateIgnoreDoubleTeacherAndAudienceForSchedule', value)
      }
    },
  },
  watch: {
    retake(){
      this.selectCurriculums = []
      this.curriculums = []
      this.getCurriculum(this.lessons)
    },
    show() {
      if (this.show) {
        this.closeActive = true
        this.curriculums = []
        if (this.lessons.scheduledLessons.length == 0) {
          this.selectCurriculums = []
        }
        this.getCurriculum(this.lessons)
        this.getTeachers()
        // this.getStreamLessons()
        this.getAudiences()
        this.lessonsC.date = this.lessons.date
        this.lessonsC.lessonNumbers = this.lessons.lessonNumbers
        this.lessonsC.lessonTypeDays = this.lessons.lessonTypeDays
        this.lessonsC.numberLessons = this.lessons.numberLessons
        this.lessonsC.schedule = this.lessons.schedule
        this.lessonsC.scheduleType = this.lessons.scheduleType
        this.originalLess = [...this.lessons.scheduledLessons]
      }
    },
    'lessons.workload'() {
      this.streamLessons = []
      this.lessons.workloadStreamLessons = []
      // this.getStreamLessons()
    },
    selectCurriculums() {
      this.checkNewAndDelItems()
      this.disabledItemsWorkload()
    },
    curriculums() {
      let cur = []

      for (let i = 0; i < this.lessons.scheduledLessons.length; i++) {
        cur.push(this.lessons.scheduledLessons[i].workload)
      }

      if (cur.length > 0) {
        this.selectCurriculums = cur
      }
    },
  },
  methods: {
    disabledItemsWorkload() {
      if (this.lessons.scheduledLessons.length > 0) {
        for (let i = 0; i < this.curriculums.length; i++) {
          if (
              this.curriculums[i].hours == this.lessons.scheduledLessons[0].workload.hours &&
              // this.curriculums[i].teacher.id == this.lessons.scheduledLessons[0].workload.teacher.id &&
              this.curriculums[i].workPlan.id == this.lessons.scheduledLessons[0].workload.workPlan.id
              && this.lessons.scheduledLessons[0].workload.subGroup != null && this.curriculums[i].subGroup != null

          ) {
            this.curriculums[i].disabled = false
          } else {
            this.curriculums[i].disabled = true
          }

          if (this.curriculums[i].id == this.lessons.scheduledLessons[0].workload.id) {
            this.curriculums[i].disabled = false
          }

        }
      } else {
        for (let i = 0; i < this.curriculums.length; i++) {
          this.curriculums[i].disabled = false
        }
      }
    },
    async checkNewAndDelItems() {
      let newItem = []
      let delItem = []
      for (let i = 0; i < this.selectCurriculums.length; i++) {
        let f = true
        for (let k = 0; k < this.lessons.scheduledLessons.length; k++) {
          if (this.selectCurriculums[i].id === this.lessons.scheduledLessons[k].workload.id) {
            f = false
            break
          }
        }
        if (f) {
          newItem.push(this.selectCurriculums[i])
          let less = {...this.lessonsC}
          less.workload = {...this.selectCurriculums[i]}
          this.lessons.scheduledLessons.push({...less})
        }
      }

      for (let i = 0; i < this.lessons.scheduledLessons.length; i++) {
        let f = true
        for (let k = 0; k < this.selectCurriculums.length; k++) {
          if (this.lessons.scheduledLessons[i].workload.id === this.selectCurriculums[k].id) {
            f = false
            break
          }
        }
        if (f) {
          if (this.lessons.scheduledLessons[i].id != undefined && this.lessons.scheduledLessons[i].id != null) {
            if (confirm("Удалить запись?")) {
              this.deleteLesson(this.lessons.scheduledLessons[i])
                  .then(() => {
                    delItem.push(this.selectCurriculums[i])
                    this.lessons.scheduledLessons.splice(i, 1);
                  })
            }
          }else {
            delItem.push(this.selectCurriculums[i])
            this.lessons.scheduledLessons.splice(i, 1);
          }
        }
      }


    },
    closeEditLessonDialog() {
      this.lessons.scheduledLessons = this.originalLess
      this.$emit("closeEditLessonDialog")
    },
    deleteLesson: async function (less, date) {
      console.log(less)
      return new Promise((resolve, reject) => {
        if (less.id != null) {
          this.closeActive = false
          this.loading = true
          HTTP.delete(Settings.API.URL_DEFAULT + "/scheduled-lessons/" + less.id + "/by-date?date=" + date
          ).then(() => {
            //this.deleteLessonSuccess()
            resolve(true)
          }).catch(() => {
            reject(false)
          }).finally(() => (this.loading = false));
        } else {
          resolve(true)
        }
      })
    },
    deleteList(less) {
      console.log(less)
      if (confirm("Удалить все записи?")) {
        for (let i = 0; i < less.scheduledLessons.length; i++) {
          this.deleteLesson(less.scheduledLessons[i], less.date).then(() => {
            this.lessons.scheduledLessons = []
            this.deleteLessonSuccess()
          })
        }
      }
    },
    confirmSave(confirm) {
      this.saveLesson(confirm.isCheckAudience, confirm.isCheckTeacher)
    },
    duplicateDialogClose() {
      this.dialog_duplicate_teacher = false
    },
    getCurriculum: async function (item) {
      this.loadingCurriculum = true
      if (this.retake){
        await HTTP.get(Settings.API.URL_DEFAULT + Settings.API.URL_GROUPS_BRSO + item.groups.id + "/workload-retake")
            .then((response) => {
              this.curriculums = response.data
            })
            .catch(() => {
              this.errored = true;
            })
            .finally(() => (this.loadingCurriculum = false));
      }else {
        await HTTP.get(Settings.API.URL_GROUPS_BRSO_CURRICULUM_BY_GROUPID_HALFYEAR(item.groups.id, this.yearOfStud.id, this.halfYear.id))
            .then((response) => {
              this.curriculums = response.data
            })
            .catch(() => {
              this.errored = true;
            })
            .finally(() => (this.loadingCurriculum = false));
      }

    },
    getTeachers: async function () {
      await HTTP.get(Settings.API.URL_DEFAULT + Settings.API.URL_TEACHERS)
          .then((response) => {
            this.replacementTeachers = response.data
          })
          .catch(() => {
            this.errored = true;
          })
          .finally(() => {
          });
    },
    getAudiences: async function () {
      this.loading = true
      await HTTP.get(Settings.API.URL_DEFAULT + Settings.URL_AUDIENCES)
          .then((response) => {
            this.audiences = response.data
          })
          .finally(() => (this.loading = false));
    },
    // deleteTeacherLinkToAudience(item) {
    //   const index = this.lessons.teacherLinkToAudience.indexOf(item);
    //   if (index > -1) {
    //     this.lessons.teacherLinkToAudience.splice(index, 1);
    //     this.dialogTeacherLink = false
    //   }
    //
    // },
    // getStreamLessons: async function () {
    //   if (this.lessons.workload != null && this.lessons.workload.workPlan != null && this.lessons.workload.workPlan.discipline != null
    //       && this.lessons.workload.workPlan.lessonTypes != null && this.lessons.workload.workPlan.halfYear != null
    //       && this.lessons.workload.workPlan.yearOfStudy != null) {
    //
    //     await HTTP.get(Settings.API.URL_DEFAULT + "/workload/workload-for-stream?hours=" +
    //         this.lessons.workload.workPlan.hours + "&disciplineId=" + this.lessons.workload.workPlan.discipline.id +
    //         "&lessonTypesId=" + this.lessons.workload.workPlan.lessonTypes.id + "&halfYearId=" +
    //         this.lessons.workload.workPlan.halfYear.id + "&yearOfStudyId=" + this.lessons.workload.workPlan.yearOfStudy.id)
    //         .then((response) => {
    //
    //           let id = this.lessons.workload.id
    //           if (id != null && id != 0) {
    //             this.streamLessons = response.data.filter(function (item) {
    //               return item.id != id
    //             })
    //             this.lessons.workloadStreamLessons = []
    //           }
    //         })
    //         .catch(() => {
    //           this.errored = true;
    //         })
    //         .finally(() => {
    //         });
    //   }
    //
    // },


    readLessonNum: function (item) {
      if (item != undefined && item.lessonNumbers != null && item.lessonNumbers != undefined) {
        return item.lessonNumbers.timeStart // item.lessonNumbers.name + " " + item.lessonNumbers.timeStart
      }
      return ""
    },
    readLessonTypeDay: function (item) {
      let day = ""
      if (item.scheduleType != undefined && item.scheduleType != null) {
        if (item.scheduleType.type == "EVEN_ODD") {
          if (item != undefined && item.lessonTypeDays != null && item.lessonTypeDays != undefined) {
            if (item.lessonTypeDays.typeDay === 1) day = "(Нечет)"
            if (item.lessonTypeDays.typeDay === 1) day = "(Нечет)"
            if (item.lessonTypeDays.typeDay === 2) day = "(Чет)"
            day = item.lessonTypeDays.name + " " + day
          }
        } else {
          day = item.date
        }
      }
      return day
    },


    saveLessonSuccess(lesson) {
      this.$emit("saveLessonSuccess", lesson)
    },

    deleteLessonSuccess() {
      this.$emit("deleteLessonSuccess")
    },

    refreshLessons() {
      this.$emit("refreshLessons")
    },

    saveLesson: async function (isCheckAudience, isCheckTeacher) {
      if (this.ignoreDouble) {
        isCheckAudience = false
        isCheckTeacher = false
        this.ignoreDouble = false
      }
      await this.checkDuplication(isCheckAudience, isCheckTeacher)
    },
    checkDuplication: async function (isCheckAudience, isCheckTeacher) {
      this.duplicate_lesson = []
      for (let i = 0; i < this.lessons.scheduledLessons.length; i++) {
        this.lessons.scheduledLessons[i].isCheckDuplicateAudience = isCheckAudience
        this.lessons.scheduledLessons[i].isCheckDuplicateTeacher = isCheckTeacher
      }
      this.loading = true
      await HTTP.post(Settings.API.URL_DEFAULT + Settings.API.URL_SCHEDULED_LESSONS + "checkDuplication/", this.lessons
      ).then((response) => {
        //this.dialog_lesson = false
        this.dialog_duplicate_audience = false;
        this.dialog_duplicate_teacher = false;
        this.postLesson(response.data.id)
      }).catch(error => {
        this.ignoreDouble = false
        if (error.response != undefined) {
          this.duplicate_lesson = error.response.data
          // console.log(this.duplicate_lesson)
          if (error.response.status === 461) {
            this.dialog_duplicate_audience = true;
          }
          if (error.response.status === 463) {
            this.dialog_duplicate_teacher = true;
          }
          if (error.response.status === 466) {
            this.$emit("showAlertError", " no new lessons ")
            this.closeEditLessonDialog()
            this.refreshLessons()
            //this.responseDelete();
          }
        }else {
          this.$emit("showAlertError", " no new lessons ")
          this.refreshLessons()
          this.closeEditLessonDialog()
        }
      }).finally(() => {
        this.loading = false
        this.ignoreDouble = false
      });
    },
    postLesson: async function () {
      this.loading = true
      this.duplicate_lesson = []
      await HTTP.post(Settings.API.URL_DEFAULT + Settings.API.URL_SCHEDULED_LESSONS, this.lessons
      ).then((response) => {
        for (let i = 0; i < this.lessons.scheduledLessons.length; i++) {
          if (this.lessons.scheduledLessons[i].workloadStreamLessons != null && this.lessons.scheduledLessons[i].workloadStreamLessons.length > 0) {
            // this.getJournal(true)
            this.$emit('refreshLessons')
            break
          }
        }
        this.saveLessonSuccess(response.data)
      }).catch(error => {
        this.ignoreDouble = false
        if (error.response != undefined) {
          this.duplicate_lesson = error.response.data
          if (error.response.status === 461) {
            this.dialog_duplicate_audience = true;
          }
          if (error.response.status === 463) {
            this.dialog_duplicate_teacher = true;
          }
          if (error.response.status === 466) {
            this.$emit("showAlertError", " no new lessons ")
            this.refreshLessons()
            this.closeEditLessonDialog()
          }else {
            this.$emit("showAlertError", " no new lessons ")
            this.refreshLessons()
            this.closeEditLessonDialog()
          }
        }
      }).finally(() => {
        this.loading = false
        this.ignoreDouble = false
      });
    },

  },
  mounted() {

  },

}
</script>

<style scoped>

</style>

