<template>
  <v-row justify="center">
    <v-dialog
        v-model="show"
        fullscreen
        hide-overlay
        transition="dialog-bottom-transition"
    >
      <v-card>

        <v-toolbar
            dark
            color="primary"
        >
          <v-btn
              icon
              dark
              @click="close"
          >
            <v-icon>close</v-icon>
          </v-btn>
          <v-toolbar-title>Заказать - {{ certificate.title }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn
                dark
                text
                @click="order"
            >
              Заказать
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>


        <v-list  class="ma-6"
            three-line
            subheader
        >
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>{{certificate.groupCertificate.title}}</v-list-item-title>
              <v-list-item-subtitle>{{certificate.groupCertificate.description}}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>Справка - {{certificate.title}}</v-list-item-title>
              <v-list-item-subtitle>Описание - {{certificate.description}}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>Срок исполнения (Дней) - {{certificate.readinessInDays}}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <v-divider></v-divider>
        <div v-if="certificate.isFile">
          <v-list  class="ma-6"
                   three-line
                   subheader
          >

            <v-list-item >
              <v-list-item-content>
                <v-list-item-title >Загрузите файлы</v-list-item-title>
                <v-list-item-subtitle>{{ certificate.fileDescription }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
          <v-row class="ml-6 mr-8">
            <v-col>
              <v-file-input
                  chips
                  counter
                  multiple
                  show-size
                  truncate-length="15"
                  v-model="files"
              ></v-file-input>
            </v-col>
          </v-row>
        </div>


      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import Settings from "@/settings/settings_url";
import {HTTP} from '@/settings/axios'


export default {
  name: "CertificateOrderDialog",
  props:{
    show: {
      type: Boolean,
      required: true,
      default: false,
    },
    certificate:{
      type: Object,
      required: true
    },
  },
  data(){
    return{
      newOrder: {},
      files: []
    }
  },
  watch:{

  },
  methods:{
    close(){
      this.files = []
      this.$emit("close")
    },
    order(){
      // this.$emit("order", this.newOrder)
      if (this.files || !this.certificate.isFile) {
        let formData = new FormData();

        // files
        for (let file of this.files) {
          formData.append("files", file, file.name);
        }

        let fileUploads = ""
        if (this.files.length > 0){
          fileUploads = "/file_uploads"
        }else {
          fileUploads = "/new-order"
        }

        formData.append("id", this.certificate.id)

        // additional data
        formData.append("test", "foo bar");
        HTTP
            .post(Settings.API.URL_DEFAULT + "/order-certificate" + fileUploads, formData)
            .then(() => {
              this.files = []
              this.$emit("success")
            })
            .catch(error => {
              console.log({ error });
            });
      } else {
        console.log("there are no files.");
      }
    },
  },
}
</script>

<style scoped>

</style>