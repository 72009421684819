<template>
  <div>
    <div class="ma-2 " v-if="name !== null">
      <span>{{ name }}</span>
    </div>
    <div class="ma-2 " v-else>
      {{ headers() }}
    </div>

    <div class="ma-lg-2 " v-if="role === 'ROLE_TEACHER'">
      <v-list-item
          v-for="(lesson, i) in item"
          :key="i"
          link
          :to="getUrl(lesson)"
      >
        {{ lesson.workload.group.name }}
      </v-list-item>
    </div>

    <div class="ma-lg-2 " v-if="role === 'ROLE_STUDENT'">
      <v-list-item
          v-for="(lesson, i) in item"
          :key="i"
      >
        {{ getTeachers(lesson.workload.teachers) }}
      </v-list-item>
    </div>

    <div class="ma-lg-2 ">
      {{ getAudiences() }}
    </div>
  </div>
</template>

<script>
export default {
  name: "ScheduleItem",
  props: {
    role: String,
    item: Array,
    name: String
  },
  methods: {
    getTeachers: function (teachersList){
       let teachersString = ""

      teachersList.forEach(function (item){
        teachersString += item.fio + ", "
      });

        return teachersString
    },
    getAudiences: function () {
      if (this.item != null) {
        let audiences = "Аудитория - "
        this.item.forEach(function (item) {
          item.audiences.forEach(function (audience) {
            audiences += audience.name + ", "
          })
        })
        return audiences;
      }

    },
    headers: function () {
      if (this.item !== null && this.item instanceof Array && this.item.length > 0) {
        return this.item[0].workload.discipline.name +
            ' (' + this.item[0].workload.lessonTypes.name + ')'
      }
    },
    getUrl: function (lesson) {
      if (lesson == null) {
        return ""
      }
      return "/groupcard?lesson=" + lesson.id
    }
  }
}
</script>

<style scoped>

</style>
синад12