<template>
  <v-container>
    <v-container>
      <v-card-title class="justify-center">
        <h1>Практики в этом семестре</h1>
      </v-card-title>
    </v-container>

    <v-container>
      <v-alert type="info" v-if="!loading && (!sheets || sheets.length === 0)">
        <v-row class="text-center">
          <v-col class="grow">
            <strong>Практик нет!</strong>
          </v-col>
        </v-row>
      </v-alert>
    </v-container>

    <v-container>
      <v-card
          class="mx-auto"
          tile
      >
        <v-list
            nav
            dense
        >
          <v-list-item-group
              color="primary"
          >
            <v-list-item
                v-for="(item, i) in sheets"
                :key="i"
                :to="getSheetLink(item)"
            >
              <!--          {{ item }}-->
              <v-list-item-icon>
                <v-icon class="pr-6" v-text="item.icon">groups</v-icon>

                <v-tooltip bottom color="primary">
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                        color="primary"
                        v-if="item.userAdmin && item.userAdmin.id === $store.getters.selectedUser.id"
                        v-bind="attrs"
                        v-on="on"
                    >
                      admin_panel_settings
                    </v-icon>
                  </template>
                  <span>Вы можете редактировать преподавателей данной ведомости</span>
                </v-tooltip>
              </v-list-item-icon>

              <v-list-item-content :class="item.userAdmin && item.userAdmin.id === $store.getters.selectedUser.id ? 'ml-0' : 'ml-6'">
                <v-list-item-title v-text="sheetNameFormatted(item)" ></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-card>
    </v-container>
  </v-container>
</template>

<script>
import {HTTP} from "@/settings/axios";
import Settings from "@/settings/settings_url";

export default {
  name: "TeacherPracticeVeds",

  data() {
    return {
      sheets: [],
      loading: false,
    }
  },

  methods: {
    getSheets() {
      this.loading = true
      HTTP.get(Settings.API.URL_DEFAULT + "/practice-sheet/of-user/" + this.$store.getters.selectedUser.id + "/this-semester"
      )
          .then((response) => {
            this.sheets = response.data
          })
          .catch(() => {})
          .finally(() => {
            this.loading = false
          });
    },
    sheetNameFormatted(sheet) {
      return "Группа - " + sheet.group.name + ": Практика - " + sheet.discipline.name
    },
    getSheetLink(sheet) {
      return "journal-all?discipline=" + sheet.discipline.id + "&group=" + sheet.group.id + "&year=" + sheet.year.id + "&halfYear=" + sheet.halfYear.id + "&tab=3"
    },
  },

  watch: {
    '$store.getters.selectedUser'() {
      if(this.$store.getters.selectedUser.id)
        this.getSheets()
    }
  },

  created() {
    if(this.$store.getters.selectedUser.id)
      this.getSheets()
  }

}
</script>

<style scoped>

</style>