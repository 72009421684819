<template>
  <v-container>
    <v-data-table
        :headers="headers"
        :items="courseInfo"
        :items-per-page="15"
        class="elevation-1"
        loading-text="Загрузка..."
        no-data-text="Нет информации"
    >
      <template v-slot:item.edit="{ item }">
        <v-btn icon
               :to="getLink(item)"
               target="_blank"
               color="primary"
        >
          <v-icon>edit</v-icon>
        </v-btn>
      </template>

    </v-data-table>
  <v-row>


  </v-row>
  </v-container>
</template>

<script>

import Settings from "@/settings/settings_url";
import {HTTP} from '@/settings/axios'

export default {
  components: {},
  name: "ControlPointListAdmin",
  data() {
    return {
      headers: [
        // {text: 'N', align: 'center', sortable: false, value: 'id',},
        {text: 'Группа', value: 'groupName', divider: true, align: 'center'},
        {text: 'Количество дисциплин', value: 'disciplineCount', divider: true, align: 'center'},
        {text: 'Количество связаных курсов lms', value: 'courseCount', divider: true, align: 'center'},
        {text: 'Несвязанные курсы', value: 'dif', divider: true, align: 'center'},
        {text: 'Редактировать', value: 'edit', divider: true, align: 'center'},
      ],
      courseInfo: [],
    }
  },
  watch: {
  },
  methods: {
    getLink(item){
      return "/control-points-admin?course=" + item.groupId
    },
    getGroups: async function () {
      await HTTP.get(Settings.API.URL_DEFAULT + "/control-points-categories/info")
          .then((response) => {
            this.courseInfo = response.data
          })
          .catch(() => {
          })
    },
  },

  created() {
    this.getGroups()
  },

}
</script>

<style lang="sass">

</style>
