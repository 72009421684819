<template>
  <v-row justify="center">
    <v-dialog v-model="show" persistent max-width="600px">
      <v-card>
        <v-card-title>
          <span class="headline">Печать</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="6" sm="6" md="6">
                <template>
                  <v-dialog
                    ref="dialog_lesson_date"
                    v-model="modal_date_start"
                    :return-value.sync="dateStart"
                    persistent
                    width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="dateStart"
                        label="От"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker v-model="dateStart" scrollable>
                      <v-spacer></v-spacer>
                      <v-btn
                        text
                        color="primary"
                        @click="modal_date_start = false"
                      >
                        Cancel
                      </v-btn>
                      <v-btn
                        text
                        color="primary"
                        @click="$refs.dialog_lesson_date.save(dateStart)"
                      >
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-dialog>
                </template>
              </v-col>
              <v-col cols="6" sm="6" md="6">
                <template>
                  <v-dialog
                    ref="dialog_lesson_date_end"
                    v-model="modal_date_end"
                    :return-value.sync="dateEnd"
                    persistent
                    width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="dateEnd"
                        label="До"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker v-model="dateEnd" scrollable>
                      <v-spacer></v-spacer>
                      <v-btn
                        text
                        color="primary"
                        @click="modal_date_end = false"
                      >
                        Cancel
                      </v-btn>
                      <v-btn
                        text
                        color="primary"
                        @click="$refs.dialog_lesson_date_end.save(dateEnd)"
                      >
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-dialog>
                </template>
              </v-col>
            </v-row>
            <v-radio-group v-model="fileType">
              <v-radio key="pdf" label="pdf" value="pdf"></v-radio>
              <v-radio key="xlsx" label="xlsx" value="xlsx"></v-radio>
            </v-radio-group>
            <div style="display: flex;">
              <div>
                <v-checkbox v-model="filterMain" label="Основное"></v-checkbox>
                <v-checkbox v-model="filterCns" label="Консультации"></v-checkbox>
              </div>
              <div class="ml-4">
                <v-checkbox v-model="filterExam" label="Экзамены"></v-checkbox>
                <v-checkbox v-model="filterZ" label="Зачеты"></v-checkbox>
              </div>
              <div class="ml-4">
                <v-checkbox v-model="filterC" label="Курсовые"></v-checkbox>
                <v-checkbox v-model="filterP" label="Практики"></v-checkbox>
              </div>
              <div class="ml-4">
                <v-checkbox v-model="filterG" label="Госы"></v-checkbox>
                <v-checkbox v-model="filterVKR" label="ВКР"></v-checkbox>
              </div>
            </div>
            <div v-if="loading">
              <v-progress-linear
                indeterminate
                rounded
                height="6"
              ></v-progress-linear>
            </div>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="close()"
            :disabled="loading"
          >
            Close
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            @click="print()"
            :disabled="loading"
          >
            Ok
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import Settings from "@/settings/settings_url";
import { HTTP } from "@/settings/axios";

export default {
  name: "SchedulePrint",
  components: {},
  props: {
    show: Boolean,
    headers: Array,
    scheduleType: String,
    year: Object,
    halfYear: Object,
    course: Number,
    parity: Number,
  },
  data() {
    return {
      dateStart: "",
      dateEnd: "",
      modal_date_start: false,
      modal_date_end: false,
      fileType: "pdf",
      loading: false,
      filterMain: true,
      filterExam: false,
      filterZ: false,
      filterC: false,
      filterP: false,
      filterG: false,
      filterVKR: false,
      filterCns: false,

    }
  },

  computed: {
    groups() {
      let res = [];
      for (let i = 0; i < this.headers.length; i++) {
        if (this.headers[i].schedule != null) {
          res.push(this.headers[i].schedule.groups);
        }
      }
      // return this.headers.map(function (item) {
      //   if (item.schedule != null){
      //     return item.schedule.groups
      //   }
      // })
      return res;
    },
  },
  watch: {},
  methods: {
    close() {
      this.$emit("closePrintDialog");
    },
    downloadFile(response, filename) {
      // It is necessary to create a new blob object with mime-type explicitly set
      // otherwise only Chrome works like it should
      var newBlob = new Blob([response.body], { type: "application/pdf" });

      // IE doesn't allow using a blob object directly as link href
      // instead it is necessary to use msSaveOrOpenBlob
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(newBlob);
        return;
      }

      // For other browsers:
      // Create a link pointing to the ObjectURL containing the blob.
      const data = window.URL.createObjectURL(newBlob);
      var link = document.createElement("a");
      link.href = data;
      link.download = filename;
      link.click();
      setTimeout(function () {
        // For Firefox it is necessary to delay revoking the ObjectURL
        window.URL.revokeObjectURL(data);
      }, 100);
    },
    downLoadFile2(response) {
      var fileURL = window.URL.createObjectURL(new Blob([response.data]));
      var fileLink = document.createElement("a");
      fileLink.href = fileURL;
      fileLink.setAttribute("download", "file.pdf");
      document.body.appendChild(fileLink);
      fileLink.click();
    },
    print: async function () {
      this.loading = true;
      // console.log( this.groups)
      await HTTP.post(
        Settings.API.URL_DEFAULT +
          "/schedule-print?scheduleType=" +
          this.scheduleType +
          "&dateStart=" +
          this.dateStart +
          "&dateEnd=" +
          this.dateEnd +
          "&year=" +
          this.year.id +
          "&halfYear=" +
          this.halfYear.id +
          "&filetype=" +
          this.fileType +
          "&course=" +
          this.course +
          "&parity=" +
          this.parity +
          "&filterMain=" +
          this.filterMain +
          "&filterExam=" +
          this.filterExam +
          "&filterZ=" +
          this.filterZ +
          "&filterC=" +
          this.filterC +
          "&filterP=" +
          this.filterP +
          "&filterG=" +
          this.filterG +
          "&filterVKR=" +
          this.filterVKR +
          "&filterCns=" +
          this.filterCns,
        this.groups
      )      
      .then((response) => {
        // this.downloadFile(response, 'Test')
        // console.log(response)
        // this.downLoadFile2(response)
        window.open(Settings.API.URL_DEFAULT + "/schedule-print/get-file?path=" + response.data, '_blank')
      }).catch(() => {

      }).finally(() => {
        this.loading = false
      });
    },
  },
  mounted() {},
};
</script>

<style scoped>
</style>

