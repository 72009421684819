<template>
  <v-dialog v-model="dialog"
            max-width="600px">
    <v-card>
      <v-toolbar
          dark
          color="primary"
      >
        <v-btn
            icon
            dark
            @click="closeDialog()"
        >
          <v-icon>close</v-icon>
        </v-btn>
        <v-card-title>Распределить компенсационные баллы</v-card-title>
      </v-toolbar>
      <v-progress-linear
          color="primary"
          :indeterminate="isTransferring"
          :active="isTransferring"
      />
      <v-container>
        <v-card-subtitle>
          <v-alert type="info" color="primary">
            Для распределения доступно баллов: {{ balance.points }} шт.
          </v-alert>
        </v-card-subtitle>
        <v-card-text>
          <v-form
              ref="pointsForm"
              v-model="valid"
              >
            <v-autocomplete
                v-model="discipline"
                :items="disciplines"
                dense
                item-text="name"
                item-value="id"
                return-object
                label="Дисциплина"
                required
            />
            <v-text-field
                v-show="discipline !== null"
                v-model="pointsToTransfer"
                label="Введите количество баллов"
                :hint="balance.points < (3 - spentPointsOnDiscipline) ? 'Максимум можно распределить ' + balance.points + ' баллов' : 'Максимум можно распределить ' + (3 - spentPointsOnDiscipline) + ' баллов'"
                :rules="pointsToTransferRules"
                persistent-hint
                type="number"
                required
            />
            <v-text-field
                v-show="discipline !== null && !isTransferredByStudent"
                v-model="comment"
                label="Комментарий (необязательно)"
                persistent-hint
                required
            />
          </v-form>
        </v-card-text>
        <v-card-subtitle>
          <v-alert outlined color="error" type="warning" v-if="isTransferredByStudent && !!discipline">
            - Будьте внимательны! Восстановить распределенные баллы может только тех.поддержка!<br/>
            - Баллы сгорят в следующем семестре!
          </v-alert>
        </v-card-subtitle>
        <v-divider/>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-alert></v-alert>
          <v-btn
              text
              color="secondary"
              @click="closeDialog()"
          >
            Закрыть
          </v-btn>
          <v-btn
              text
              :disabled="!valid || isTransferring"
              :loading="isTransferring"
              color="primary"
              @click=transferPoints()
          >
            Распределить
          </v-btn>
        </v-card-actions>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import Settings from "@/settings/settings_url";
import {HTTP} from '@/settings/axios'

export default {
  name: "CompensationTransfer",
  data() {
    return {
      dialog: false,
      disciplines: [],
      discipline: null,
      pointsToTransfer: null,
      pointsToTransferRules: [
        v => !!v || 'Это поле обязательно',
        v => v > 0 || 'Введите больше 0 баллов, но меньше ' + this.balance.points,
        v => (+this.spentPointsOnDiscipline + +v) <= 3 || "На эту дисциплину можно распределить не больше " + this.allowedToSpendPoints() + " баллов",
        v => v <= this.balance.points || 'Максимум можно распределить ' + this.allowedToSpendPoints() + ' баллов',
      ],
      valid: false,
      isTransferring: false,
      comment: null,
      spentPointsOnDiscipline: null,
    }
  },
  methods:{
    transferPoints: async function() {
      this.isTransferring = true

      HTTP.post(Settings.API.URL_DEFAULT + "/compensation-points-balance/transfer-points", {
        "balance": this.balance,
        "discipline": this.discipline,
        "halfYearId": this.halfYearId,
        "yearOfStudyId": this.yearOfStudyId,
        "points": this.pointsToTransfer,
        "user": this.$store.getters.selectedUser,
        "comment": this.comment,
      })
          .then((response) => {
            this.balance = response.data
            this.$emit('updateBalance', this.balance)
            this.$emit('success', 'Баллы успешно распределены')
          })
          .catch(() => {
            this.$emit('error', 'Ошибка распределения')
          })
          .finally(() => {
            this.closeDialog()
            this.isTransferring = false
          });
    },
    closeDialog: function() {
      this.$refs.pointsForm.reset()
      this.$refs.pointsForm.resetValidation()
      this.dialog = false
    },
    getDisciplines() {
      HTTP.get(Settings.API.URL_DEFAULT + "/disciplines/by-groupid?groupid="
          + this.balance.student.groups.id)
          .then((response) => {
            this.disciplines = response.data
          })
          .catch(() => {

          })
          .finally(() => {

          })
    },
    getMaxPointsToTransfer() {
      this.loading = true
      HTTP.get(Settings.API.URL_DEFAULT + "/compensation-points/journal/get-spent-points-on-discipline" +
          "?studentId=" + this.balance.student.id +
          "&disciplineId=" + this.discipline.id
      )
          .then((response) => {
            this.spentPointsOnDiscipline = response.data
          })
          .catch(() => {

          })
          .finally(() => {
            this.loading = false
          })
    },
    allowedToSpendPoints() {
      if(this.balance.points < 3 - this.spentPointsOnDiscipline)
        return "this.balance.points"
      else
        return 3 - this.spentPointsOnDiscipline
    }
  },
  created() {
    this.getDisciplines()
  },
  props: {
    student: {},
    balance: {},
    halfYearId: {
      type: Number,
      default: -1
    },
    yearOfStudyId: {
      type: Number,
      default: -1
    },
    isTransferredByStudent: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    'balance'() {
      this.getDisciplines()
    },
    'discipline'() {
      this.getMaxPointsToTransfer()
    }
  }
}
</script>

<style scoped>

</style>