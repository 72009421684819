<template>
  <div>
    <v-container justify-center>
      <v-row>
        <v-col
            cols="6"
            sm="6"
            md="6"
        >
          <v-autocomplete v-model="faculty"
                          :items="facultyList"
                          label="Институт"
                          dense
          >
          </v-autocomplete>
        </v-col>
        <v-col
            cols="3"
            sm="3"
            md="3"
        >
          <v-autocomplete v-model="year"
                          :items="years"
                          label="Год"
                          dense
                          prepend-icon="event"
          >
          </v-autocomplete>
        </v-col>
        <v-col
            cols="3"
            sm="3"
            md="3"
        >
          <v-autocomplete v-model="halfYear"
                          :items="halfYears"
                          label="Полугодие"
                          dense
                          prepend-icon="event"
          >
          </v-autocomplete>
        </v-col>
      </v-row>
      <v-row>
        <v-btn
            class="mt-4"
            color="primary"
            dark
            @click="search"
            :loading="loading"
        >
          Поиск
        </v-btn>
        <v-checkbox v-if="!!items.length" class="ml-10"
            v-model="ex"
            label="менее 100"
        ></v-checkbox>
      </v-row>

      <div class="mt-10">
        <v-data-table
            :headers="headers"
            :items="items"
            :items-per-page="80"
            class="elevation-2"
            fixed-header
            :loading="loading"
        >
        </v-data-table>
      </div>

    </v-container>
  </div>
</template>

<script>
import Settings from "@/settings/settings_url";
import {HTTP} from '@/settings/axios'

export default {
  name: "LearningActivityStat",
  components: {},
  data() {
    return {
      headers: [
        {text: 'Группа', align: 'center', sortable: true, value: 'groupName', divider: true, class: "bolt"},
        {text: 'Дисциплина', align: 'center', value: 'disciplineName', divider: true, sortable: true, class: "bolt"},
        {text: 'Преподаватель', align: 'center', value: 'teacherName', divider: true, sortable: true, class: "bolt"},
        {text: 'Посещение', align: 'center', value: 'visitPoints', divider: true, sortable: true, class: "bolt"},
        {text: 'уч. Активность', align: 'center', value: 'learningActivityPoints', divider: true, sortable: true, class: "bolt"},
        {text: 'ТАА', align: 'center', value: 'taaPoints', divider: true, sortable: true, class: "bolt"},
        {text: 'ТТК', align: 'center', value: 'ttkPoints', divider: true, sortable: true, class: "bolt"},
        {text: 'экзамен/зачет', align: 'center', value: 'exam', divider: true, sortable: true, class: "bolt"},
        {text: 'итого', align: 'center', value: 'sum', divider: true, sortable: true, class: "bolt"},
      ],
      items: [],
      loading: false,
      years: [
        {
          text: "2022-2023",
          value: 3
        },
      ],
      halfYears:[
        {
          text: "1 полугодие",
          value: 1
        },
        {
          text: "2 полугодие",
          value: 2
        },
      ],
      facultyList: [],
      year: 3,
      halfYear: 1,
      faculty: null,
      ex: false,
    }
  },
  watch: {
    ex(){
      if (this.ex){
        this.items = [...this.itemsB.filter(i => i.sum < 100)]
      }else {
        this.items = this.itemsB
      }
    },
  },
  methods: {
    getFaculties: function() {
      HTTP.get(Settings.API.URL_DEFAULT + "/groups/get-faculties")
          .then((response) => {
            this.facultyList = response.data
            this.facultyList.unshift("")
            // console.log(this.facultyList)
          })
          .catch(() => {

          })
          .finally(() => {

          })
    },
    search(){
      if (!this.loading){
        this.loading = true
        HTTP.get(Settings.API.URL_DEFAULT + "/learning-activity/stat?faculty="
            + this.faculty + "&year=" + this.year + "&halfYear=" + this.halfYear )
            .then((response) => {
              this.items = response.data
              this.itemsB = response.data
            })
            .catch(() => {

            })
            .finally(() => {
              this.loading = false
            })
      }

    },
  },
  created() {
    this.getFaculties()
  },
}
</script>

<style scoped>

</style>