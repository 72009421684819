<template>
  <div>
    <v-row>
      <v-col></v-col>
    </v-row>
    <v-row>
      <v-col
          cols="3"
          sm="3"
          md="3"
      >
      </v-col>
      <v-col
          cols="4"
          sm="4"
          md="4"
      >
        <v-autocomplete
            v-model="year"
            :items="years"
            outlined
            dense
            chips
            small-chips
            item-text="name"
            item-value="id"
            return-object
            label="Год"
            prepend-icon="event"
        ></v-autocomplete>
        <v-autocomplete
            v-model="halfYear"
            :items="halfYears"
            outlined
            dense
            chips
            small-chips
            item-text="name"
            item-value="id"
            return-object
            label="Полугодие"
            prepend-icon="event"
        ></v-autocomplete>
        <v-autocomplete
            v-model="group"
            :items="groups"
            outlined
            dense
            chips
            small-chips
            item-text="name"
            item-value="id"
            return-object
            label="Группа"
            prepend-icon="groups"
        ></v-autocomplete>
        <v-autocomplete
            v-model="stud"
            :items="studs"
            outlined
            dense
            chips
            small-chips
            label="Cтудент"
            multiple
            item-text="student"
            item-value="id"
            return-object
            prepend-icon="group"

        ></v-autocomplete>
        <v-autocomplete
            v-model="type"
            :items="types"
            outlined
            dense
            chips
            small-chips
            item-text="name"
            item-value="id"
            label="На основании"
            prepend-icon="description"
        ></v-autocomplete>
        <v-textarea
            v-model="comm"
            outlined
            name="input-7-4"
            label="Комментарий"
            prepend-icon="comment"
        ></v-textarea>
        <!--        Дата -->

        <v-col
        ></v-col>
        <v-row>
          <v-col
              cols="12"
              sm="6"
              md="4"
          ></v-col>
          <v-btn
              color="primary"
              dark
              :loading="loading"
              @click="newItem"
          >
            Добавить 10 баллов компенсации
          </v-btn>
        </v-row>
      </v-col>
    </v-row>

    <!--    Таблица комментариев-->
    <v-row>
      <v-col></v-col>
    </v-row>
    <v-row>
      <v-col></v-col>
    </v-row>
    <v-row>
      <v-col md="2"></v-col>
      <v-col>
        <h3 align="left">«Информация о проставленных баллах </h3>
      </v-col>
    </v-row>
    <v-row>
      <v-col></v-col>
    </v-row>
    <v-row>
      <v-col md="1">
      </v-col>
      <v-col>
        <v-data-table
            :headers="headers"
            :items="itemsComment"
            :items-per-page="5"
            class="elevation-1"
            no-data-text="Нет информации"
            loading-text="Загрузка..."
        >

          <!--            <template v-slot:item.dateStart="{ item }">-->
          <!--              <div class="hdsgf">-->
          <!--                {{item.dateStart}}-->
          <!--              </div>-->
          <!--            </template>-->

          <!--      <template v-slot:item.user="{ item }">-->
          <!--        <v-btn>{{item.user.username}}</v-btn>-->

          <!--      </template>-->


        </v-data-table>
      </v-col>
      <v-col md="2">
      </v-col>

    </v-row>
  </div>


</template>
<script>

import Settings from "@/settings/settings_url";
import {HTTP} from '@/settings/axios'

export default {
  name: "Compensation",
  data() {
    return {
      loading: false,
      headers: [
        {
          text: 'Дата ',
          align: 'center',
          sortable: false,
          value: 'date',
          divider: true,
          // class: "bolt"
        },
        {
          text: 'Группа',
          align: 'center',
          sortable: false,
          value: 'students.groups.name',
          divider: true,
          // class: "bolt"
        },
        {
          text: 'Студент',
          align: 'center',
          sortable: false,
          value: 'students.student',
          divider: true,
          // class: "bolt"
        },
        {
          text: 'Обновил',
          align: 'center',
          sortable: false,
          value: 'user.username',
          divider: true,
          // class: "bolt"
        },
        {
          text: 'Комментарии',
          align: 'center',
          sortable: false,
          value: 'comment',
          divider: true,
          // class: "bolt"
        },
      ],
      itemsComment: [],

      values: "",
      year: {
        id: 2,
        name: "2021-2022"
      },
      years: [],

      type: 1,
      types: [
        {
          id: 1,
          name: "Сертификат о вакцинации"
        },
        {
          id: 2,
          name: "Медотвод"
        },
        {
          id: 3,
          name: "Сертификат переболевшего"
        },
        {
          id: 4,
          name: "пцр тест"
        },
      ],
      comm: "",

      halfYear: {
        id: 1,
        name: "1 полугодие"
      },
      halfYears: [],

      group: {},
      groups: [],
      divider: true,

      date1: new Date().toISOString().substr(0, 10),
      date2: new Date().toISOString().substr(0, 10),
      menu1: false,
      menu2: false,

      // datePass: new Date().toISOString().substr(0, 10),

      stud: [],
      studs: [],
    }
  },
  watch: {
    year: function () {
      // console.log(this.year)
      this.getGroups()
    },
    halfYear: function () {
      // console.log(this.halfYear)
      this.getGroups()
    },
    group: function () {
      this.getStudent()
    },
    stud: function () {
      this.getComment()
    },
  },
  methods: {
    // minCalendarDate(){
    //
    // },

    newItem: function () {
      this.updateCompens()
    },
    test: function () {
      console.log(this.year)
    },
    getYear: function () {
      HTTP.get(Settings.API.URL_DEFAULT + "/year-of-study")
          .then((response) => {
            this.years = response.data.filter(function (item) {
              return item.current === 1
            })
          })
          .catch(() => {

          })
          .finally(() => {

          });
    },

    getHalfYear: function () {
      HTTP.get(Settings.API.URL_DEFAULT + "/half-year")
          .then((response) => {
            this.halfYears = response.data
          })
          .catch(() => {

          })
          .finally(() => {

          });
    },
    getComment: function () {
      HTTP.post(Settings.API.URL_DEFAULT + "/journal-compensation-points/for-students", {
        yearOfStudy: this.year,
        halfYear: this.halfYear,
        students: this.stud,
        groups: this.group
      })
          .then((response) => {
            this.itemsComment = response.data
            console.log(response.data)
          })
          .catch(() => {

          })
          .finally(() => {

          });
    },
    clearForms: function () {
      this.stud = []
    },
    updateCompens: function () {
      this.loading = true
      HTTP.post(Settings.API.URL_DEFAULT + "/compensation-point/update-point-for-users", {
        yearOfStudy: this.year,
        halfYear: this.halfYear,
        students: this.stud,
        type: this.type,
        comm: this.comm,
        groups: this.group
      })
          .then((response) => {
            console.log(response.data)
            if (response.data.status == "ok") {
              this.getComment()
              alert(response.data.status)
              this.clearForms()
            }
          })
          .catch(() => {

          })
          .finally(() => {
            this.loading = false

          });
    },


    getGroups: function () {
      if (this.year != undefined && this.year != null
          && this.halfYear != undefined && this.halfYear != null) {
        HTTP.get(Settings.API.URL_DEFAULT + "/groups/by-year-and-half-year?idHalfYear=" + this.halfYear.id + "&idYear=" + this.year.id)
            .then((response) => {
              this.groups = response.data
            })
            .catch(() => {

            })
            .finally(() => {

            });
      }
    },
    getStudent: function () {
      if (this.group.id != undefined) {
        HTTP.get(Settings.API.URL_DEFAULT + "/groups/" + this.group.id + "/students")
            .then((response) => {
              console.log(response.data)
              this.studs = response.data

            })
            .catch(() => {

            })
            .finally(() => {

            });
      }
    },
    // getDiscipline(){
    //   HTTP.get(Settings.API.URL_DEFAULT + "/groups/" + this.group.id + "/disciplines/by-groupid")
    //       .then((response) => {
    //         this.group = response.data
    //       })
    //       .catch(() => {
    //
    //       })
    //       .finally(() => {
    //
    //       });
    // },

  },
  created() {
    this.getHalfYear()
    this.getYear()
    this.getGroups()
  },
  components: {}
}
</script>

<style lang="css">
</style>
