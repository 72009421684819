export default {
    state: {
        selectScheduleTableItemId: String,
        selectScheduleTableItem: Object,
        copyScheduleTableItem: Object,
        ignoreDoubleTeacherAndAudienceForSchedule: false,
    },
    mutations: {
        updateSelectScheduleTableItemId(state, itemId) {
            state.selectScheduleTableItemId = itemId;
        },
        updateSelectScheduleTableItem(state, item) {
            state.selectScheduleTableItem = item;
        },
        updateCopyScheduleTableItem(state, item) {
            state.copyScheduleTableItem = item;
        },
        updateIgnoreDoubleTeacherAndAudienceForSchedule(state, item) {
            state.ignoreDoubleTeacherAndAudienceForSchedule = item;
        }
    },
    actions: {},
    getters: {
        selectScheduleTableItemId(state) {
            return state.selectScheduleTableItemId;
        },
        selectScheduleTableItem(state) {
            return state.selectScheduleTableItem;
        },
        copyScheduleTableItem(state) {
            return state.copyScheduleTableItem;
        },
        ignoreDoubleTeacherAndAudienceForSchedule(state) {
            return state.ignoreDoubleTeacherAndAudienceForSchedule;
        },
    }
}