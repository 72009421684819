<template>
  <v-container class="text-center">
    <h1>Преподаватель СГЭУ глазами студента - Отчёт</h1>
    <v-container>
      <v-tabs v-model="tab">
          <v-tab>
            <v-icon left>group</v-icon>
            По преподавателю
          </v-tab>
          <v-tab>
            <v-icon left>groups</v-icon>
            По группе
          </v-tab>
      </v-tabs>
      <v-divider/>
      <v-tabs-items v-model="tab">
        <v-tab-item>
          <admin-teacher-satisfaction-by-teacher/>
        </v-tab-item>
        <v-tab-item>
          <admin-teacher-satisfaction-by-group/>
        </v-tab-item>
      </v-tabs-items>
    </v-container>
  </v-container>
</template>

<script>
import AdminTeacherSatisfactionByGroup from "@/components/admin/TeacherSatisfaction/AdminTeacherSatisfactionByGroup";
import AdminTeacherSatisfactionByTeacher
  from "@/components/admin/TeacherSatisfaction/AdminTeacherSatisfactionByTeacher";
export default {
  name: "AdminTeacherSatisfaction",
  components: {
    AdminTeacherSatisfactionByTeacher,
    AdminTeacherSatisfactionByGroup
  },
  data() {
    return {
      tab: 0,
    }
  },
  methods: {

  },
  created() {

  }
}
</script>

<style scoped>

</style>