<template>
  <v-layout align-center justify-center>
    <v-flex xs12 md10>
      <v-card>
        <v-card-text>
          <v-layout row wrap>
            <v-flex xs12 md6>
              <v-card-title>
<!--                {{ lesson.groupName }}-->
              </v-card-title>
              <template>
                <!--                :sort-by="['is_present', ]"-->
                <v-data-table
                    :headers="headers"
                    :items="students"
                    v-model="selected"
                    :single-select="singleSelect"
                    show-select
                    :sort-desc="[false, ]"
                    class="elevation-20"
                    :hide-default-footer="true"
                    :items-per-page="2000"

                >
                  <template v-slot:top>
                    <div class="text-right mr-5">
                      <v-btn icon right @click="followedUsers">
                        <v-icon>done</v-icon>
                      </v-btn>
                    </div>

                  </template>
                  <template v-slot:item.is_present="{ item }">
                    {{ item.time }}
                  </template>
                </v-data-table>
              </template>
            </v-flex>
          </v-layout>
        </v-card-text>

      </v-card>
    </v-flex>
  </v-layout>
</template>

<script>
import Settings from "@/settings/settings_url";
import {HTTP} from '@/settings/axios'

export default {
  name: "FollowedUsersSetting",
  data() {
    return {
      headers: [
        {text: 'Студент', align: 'start', sortable: false, value: 'student', class: "",},
        {text: 'Время', value: 'time', sortable: false,},
      ],
      selected: [],
      singleSelect: false,
      students: [],
      lesson: {},
    }
  },
  methods: {
    getStudents: async function () {
      let id = this.$route.query.lesson;
      await HTTP.get(Settings.API.URL_STUDENTS_BY_LESSON_ID(id))
          .then((response) => {
            //let lesson = this.lesson
            this.students = response.data
            this.selected = this.students.map(function (item) {
              if (item.followed === 1) {
                return item
              }
            })
          })
          .catch(() => {
            this.errored = true;
          })
          .finally(() => (this.loading = false));
    },
    postLessons: function () {
      return this.selected
    },
    followedUsers: function () {
      let id = this.$route.query.lesson;
      HTTP.post(Settings.API.URL_DEFAULT + Settings.URL_LESSONS + id + "/followed-students", this.postLessons())
          .then(() => {
            this.getStudents()
          })
    },
  },
  created() {
    this.getStudents()
  },
  computed: {
    // count_missing_students1() {
    //
    // },
  },
}
</script>

<style lang="css">
tr.red > td {
  background-color: #B71C1C;
}

tr.green > td {
  background-color: #1B5E20;
}
</style>
