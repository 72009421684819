
export default {
    actions: {},
    state: {
        token: null
    },
    mutations: {
        chekUserToken(state, token){
            state.token = token;

        }
    },
    getters: {
        user_token(state){
            return state.token
        }
    }
}