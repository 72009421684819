<template>
  <v-container>
    <v-card class="">
      <v-card-text class="align-center text-center">
        <v-container fluid>
          <v-form v-model="valid" ref="ticketForm">
          <v-autocomplete
              v-if="admin"
              v-model="selectedGroup"
              :items="groups"
              :rules="notEmptyRules"
              clearable
              item-text="name"
              item-value="id"
              :loading="groupsLoading"
              outlined
              dense
              prepend-icon="group"
              label="Группа"
              no-data-text="Нет групп!"
          />
          <v-autocomplete
              v-model="selectedDiscipline"
              :items="disciplines"
              :rules="notEmptyRules"
              clearable
              item-text="name"
              item-value="id"
              return-object
              :loading="disciplinesLoading"
              outlined
              dense
              prepend-icon="school"
              label="Дисциплина"
              no-data-text="Нет дисциплин!"
          />
          <v-autocomplete
              v-model="selectedStudents"
              :items="students"
              :rules="notEmptyListRules"
              clearable
              item-text="student"
              item-value="id"
              :loading="studentsLoading"
              outlined
              dense
              prepend-icon="groups"
              label="Студенты"
              no-data-text="Нет студентов!"
              multiple
              placeholder="Можно выбрать несколько студентов"
              hint="Можно выбрать несколько студентов"
              persistent-hint
          />
          <v-autocomplete
              v-model="selectedLesson"
              :items="lessons"
              :rules="admin ? notEmptyListRules : notEmptyRules"
              clearable
              :item-text="lessonNameFormatted"
              item-value="id"
              :loading="lessonsLoading"
              outlined
              dense
              prepend-icon="schedule"
              label="Пара"
              no-data-text="Нет пар за неделю!"
              :disabled="!selectedDiscipline"
              :hint="studentVisitsHint()"
              persistent-hint
              :multiple="admin ? true : false"
          />
          <v-autocomplete
              v-model="selectedTeacher"
              :items="teachers"
              :rules="admin ? null : notEmptyRules"
              clearable
              item-text="teacher"
              item-value="id"
              :loading="teachersLoading"
              outlined
              dense
              prepend-icon="person"
              label="Преподаватель"
              no-data-text="Нет преподавателей!"
              :disabled="!selectedDiscipline"
              :hint="teacherHint()"
              persistent-hint
          />
          <v-btn @click="createVisitTicket()" color="primary" :disabled="!valid || isCreatingVisitTicket" :loading="isCreatingVisitTicket">
            <v-icon left>add</v-icon>
            Отправить служебную записку преподавателю
          </v-btn>
            <v-btn @click="createAndAcceptTicket()" class="ma-2" v-if="admin" color="success" :disabled="!valid || isCreatingVisitTicket" :loading="isCreatingVisitTicket">
              <v-icon left>add</v-icon>
              Создать и принять служебку
            </v-btn>
        </v-form>
        </v-container>
      </v-card-text>
    </v-card>

    <v-container>
      <v-alert
          v-model="alert"
          dismissible
          :type="alertType"
          transition="fade-transition"
      >
        {{ alertText }}
      </v-alert>
    </v-container>
  </v-container>
</template>

<script>
import {HTTP} from "@/settings/axios";
import Settings from "@/settings/settings_url";
import date_utils from "@/settings/date_utils";

export default {
  name: "CreateTicketPage",

  data() {
    return {
      selectedGroup: null,
      groups: [],
      groupsLoading: false,

      selectedDiscipline: null,
      disciplines: [],
      disciplinesLoading: false,

      selectedStudents: [],
      students: [],
      studentsLoading: false,

      selectedLesson: null,
      lessons: [],
      lessonsLoading: false,

      selectedTeacher: null,
      teachers: [],
      teachersLoading: false,

      valid: false,
      isCreatingVisitTicket: false,

      notEmptyRules: [
        value => !!value || 'Это поле обязательное',
      ],
      notEmptyListRules: [
        value => !!value || 'Это поле обязательное',
        value => value.length > 0 || 'Это поле обязательное',
      ],

      alert: false,
      alertType: null,
      alertText: null,
    }
  },

  methods: {
    createAndAcceptTicket() {
      if(!this.valid)
        return

      this.isCreatingVisitTicket = true


      HTTP.post(Settings.API.URL_DEFAULT + "/student-visit-ticket/admin/create-and-accept", {
        lessonIds: this.selectedLesson,
        studentIds: this.selectedStudents,
        groupId: this.selectedGroup,
        teacherId: this.selectedTeacher
      })
          .then((response) => {
            console.log(response.data)
            this.alert = true
            this.alertType = "success"
            this.alertText = "Успешно создано и принято"
            this.$emit("ticketCreated")
          })
          .catch(() => {
            this.alert = true
            this.alertType = "error"
            this.alertText = "При создании или проведении заявки произошла ошибка"
          })
          .finally(() => (this.isCreatingVisitTicket = false));
    },
    createVisitTicket() {
      // this.$refs.ticketForm.validate()

      if(!this.valid)
        return

      this.isCreatingVisitTicket = true

      let url
      let obj

      if(this.admin) {
        url = "/student-visit-ticket/admin/new"
        obj = {
          lessonIds: this.selectedLesson,
          studentIds: this.selectedStudents,
          groupId: this.selectedGroup,
          teacherId: this.selectedTeacher
        }
      } else {
        url = "/student-visit-ticket/new"
        obj = {
          lessonId: this.selectedLesson,
          studentIds: this.selectedStudents,
          groupId: this.selectedGroup,
          teacherId: this.selectedTeacher
        }
      }


      HTTP.post(Settings.API.URL_DEFAULT + url, obj)
          .then((response) => {
            console.log(response.data)
            this.alert = true
            this.alertType = "success"
            this.alertText = "Успешно создано"
            this.$emit("ticketCreated")
          })
          .catch(() => {
            this.alert = true
            this.alertType = "error"
            this.alertText = "При создании заявки произошла ошибка"
          })
          .finally(() => (this.isCreatingVisitTicket = false));
    },
    getGroups() {
      this.groupsLoading = true

      HTTP.get(Settings.API.URL_DEFAULT + "/groups")
          .then((response) => {
            this.groups = response.data
          })
          .catch(() => {

          })
          .finally(() => {
            this.groupsLoading = false
          })
    },
    getDisciplines() {
      this.disciplinesLoading = true

      HTTP.get(Settings.API.URL_DEFAULT + "/disciplines/by-groupid?groupid="
          + this.selectedGroup)
          .then((response) => {
            this.disciplines = response.data
          })
          .catch(() => {

          })
          .finally(() => {
            this.disciplinesLoading = false
          })
    },
    getStudents() {
      this.studentsLoading = true

      HTTP.get(Settings.API.URL_DEFAULT + "/groups/" + this.selectedGroup + "/students")
          .then((response) => {
            this.students = response.data
          })
          .catch(() => {
            this.errored = true;
          })
          .finally(() => (this.studentsLoading = false));
    },
    getLessons() {
      this.lessonsLoading = true

      let url = "/last-week"

      if(this.admin)
        url = "/this-semester"

      HTTP.get(Settings.API.URL_DEFAULT + "/lessons/by-discipline-and-group" + url + "?groupId=" + this.selectedGroup
          + "&disciplineId=" + this.selectedDiscipline.id)
          .then((response) => {
            this.lessons = response.data
          })
          .catch(() => {
            this.errored = true;
          })
          .finally(() => (this.lessonsLoading = false));
    },
    getTeachers() {
      this.teachersLoading = true

      HTTP.get(Settings.API.URL_DEFAULT + "/teachers/by-group-and-discipline/this-semester?groupId=" + this.selectedGroup
          + "&disciplineId=" + this.selectedDiscipline.id)
          .then((response) => {
            this.teachers = response.data
          })
          .catch(() => {
            this.errored = true;
          })
          .finally(() => (this.teachersLoading = false));
    },
    teacherHint() {
      if(!this.selectedDiscipline)
        return 'Выберите дисциплину'
      else
        return ''
    },
    studentVisitsHint() {
      if(!this.selectedDiscipline)
        return 'Выберите дисциплину'
      else if(this.admin)
        return 'Можно выбрать несколько пар'
      else
        return ''
    },
    lessonNameFormatted(lesson) {
      return this.formatDate(lesson.date) + " (" + lesson.lessonTypeDto.name + ")"
    },
    formatDate(date) {
      return date_utils.formatDate(date)
    },
    isAdmin() {
      return this.isRole("ROLE_ADMIN")
    },
    isRole(role) {
      if (this.$store.getters.selectedUser?.role != null && this.$store.getters.selectedUser?.role.length > 0){
        for (let i = 0; i < this.$store.getters.selectedUser?.role.length; i++){
          if (
              this.$store.getters.selectedUser?.role[i].name === role
          ){
            return true
          }
        }
      }else {
        return  false
      }
    },
  },

  watch: {
    'selectedGroup'() {
      if(this.selectedGroup) {
        this.selectedDiscipline = null
        this.selectedStudents = []
        this.getDisciplines()
        this.getStudents()
        // this.$refs.ticketForm.resetValidation()
      }
    },
    'selectedDiscipline'() {
      if(this.selectedDiscipline) {
        this.selectedTeacher = null
        this.selectedLesson = null
        this.getTeachers()
        this.getLessons()
        // this.$refs.ticketForm.resetValidation()
      }
    },
  },

  created() {
    if(this.admin)
      this.getGroups()
    if(this.selectedGroup) {
      this.selectedDiscipline = null
      this.selectedStudents = []
      this.getDisciplines()
      this.getStudents()
      // this.$refs.ticketForm.resetValidation()
    }
  },

  props: {
    admin: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style scoped>

</style>