<template>
  <v-container>
    <journal-teacher-visits ref="journal"/>
  </v-container>
</template>

<script>
import JournalTeacherVisits from "@/components/statistics/JournalTeacherVisits";

export default {
  name: "TeacherVisits",
  components: {
    JournalTeacherVisits
  },
  methods: {
    getCurrentTeacher() {
      this.$refs.journal.filterTeacher = this.$store.getters.selectedUser.teachers
    }
  },
  mounted() {
    this.getCurrentTeacher()
  },
  watch: {
    '$store.getters.selectedUser'() {
      this.getCurrentTeacher()
    }
  },
}
</script>

<style scoped>

</style>