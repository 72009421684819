<template>
  <div>
    <v-container justify-center>
      <div class="text-center d-print-none">
        <h2>Расписание занятий</h2>
      </div>
      <v-tabs
          v-model="tab" class="d-print-none"
      >

        <v-tab>
          <v-icon left>
            groups
          </v-icon>
          группы
        </v-tab>
        <v-tab>
          <v-icon left>
            group
          </v-icon>
          преподавателя
        </v-tab>
        <v-tab>
          <v-icon left>
            school
          </v-icon>
          Дисциплина
        </v-tab>
        <v-tab v-if="getUserName() != null">
          <v-icon left>
            person
          </v-icon>
          мое расписание
        </v-tab>
        <v-tab v-if="isUmu() || isSdk() || isAdmin()">
          <v-icon left>
            meeting_room
          </v-icon>
          аудитории
        </v-tab>
        <v-tab v-if="isAdmin()">
          <v-icon left>
            group
          </v-icon>
          студента
        </v-tab>


        <v-row class="d-print-none">
          <v-col class="d-flex align-center justify-end">
            <v-btn onclick="window.print()" color="primary">
              <v-icon left>print</v-icon>
              Печать
            </v-btn>
          </v-col>
        </v-row>
      </v-tabs>
      <v-tabs-items
          v-model="tab"
          :touchless="true"
      >
        <v-tab-item>
          <v-container>
            <div class="ma-4 d-print-none">
              <v-row>
                <v-col >
                  <v-autocomplete v-model="group"
                                  :items="groups"
                                  label="Группа"
                                  dense
                                  item-value="id"
                                  item-text="name"
                                  return-object
                                  prepend-icon="groups"
                                  :loading="groupsLoading"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-menu
                      ref="dialog_date"
                      v-model="modal_date1"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                          :value="computedDateFormatted"
                          label="Дата"
                          prepend-icon="event"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                      />
                    </template>
                    <v-date-picker
                        v-model="date"
                        scrollable
                        show-adjacent-months
                        locale="ru-RU"
                        :first-day-of-week="1"
                        @input="saveDate(date)"
                    >
                    </v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>
            </div>
            <ScheduleTwoWeek
                scheduleBoardFor="GROUP"
                :subject="group"
                :date="dateSearch"
                :user="user"
            >
            </ScheduleTwoWeek>
          </v-container>
        </v-tab-item>

        <v-tab-item>
          <v-container>
            <div class="ma-4 d-print-none">
              <v-row>
                <v-col>
                  <v-autocomplete v-model="teacher"
                                  :items="teachers"
                                  label="Преподаватель"
                                  dense
                                  item-value="id"
                                  item-text="teacher"
                                  return-object
                                  prepend-icon="group"
                                  :loading="teachersLoading"
                  >
                  </v-autocomplete>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-menu
                      ref="dialog_date"
                      v-model="modal_date2"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                          :value="computedDateFormatted"
                          label="Дата"
                          prepend-icon="event"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                      />
                    </template>
                    <v-date-picker
                        v-model="date"
                        scrollable
                        show-adjacent-months
                        locale="ru-RU"
                        :first-day-of-week="1"
                        @input="saveDate(date)"
                    >
                    </v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>
            </div>
            <ScheduleTwoWeek
                scheduleBoardFor="TEACHER"
                :subject="teacher"
                :date="dateSearch"
                :user="user"
            >
            </ScheduleTwoWeek>
          </v-container>
        </v-tab-item>

        <v-tab-item>
          <v-container>
            <div class="ma-4 d-print-none">
              <v-row>
                <v-col>
                  <v-autocomplete v-model="discipline"
                                  :items="disciplines"
                                  label="Дисциплина"
                                  dense
                                  item-value="id"
                                  item-text="name"
                                  return-object
                                  prepend-icon="school"
                                  :loading="disciplineLoading"

                  >
                  </v-autocomplete>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-menu
                      ref="dialog_date"
                      v-model="modal_date6"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                          :value="computedDateFormatted"
                          label="Дата"
                          prepend-icon="event"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                      />
                    </template>
                    <v-date-picker
                        v-model="date"
                        scrollable
                        show-adjacent-months
                        locale="ru-RU"
                        :first-day-of-week="1"
                        @input="saveDate(date)"
                    >
                    </v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>
            </div>
            <ScheduleTwoWeek
                scheduleBoardFor="DISCIPLINE"
                :subject="discipline"
                :date="dateSearch"
                :user="user"
            >
            </ScheduleTwoWeek>
          </v-container>
        </v-tab-item>

        <v-tab-item v-if="getUserName() != null">
          <v-container>
            <div class="ma-4">
              <v-alert
                  v-model="errored"
                  type="warning"
                  prominent
              >
                {{ errorMessage }}
              </v-alert>
              <v-row>
                <v-col>
                  <h2 class="d-print-none">{{ getUserName() }}</h2>
<!--                  <h2 class="d-none justify-center text-center d-print-block">{{ getUserName() }}</h2>-->
                </v-col>
              </v-row>
              <v-row class="d-print-none">
                <v-col class="d-flex justify-end">
                  <v-btn @click="goToTelegramBot()" color="primary"><v-icon left>smartphone</v-icon>Расписание в Telegram</v-btn>
                </v-col>
              </v-row>

            <v-row class="d-print-none">
              <v-col>
                <v-menu
                    ref="dialog_date"
                    v-model="modal_date4"
                    :close-on-content-click="true"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                        :value="computedDateFormatted"
                        label="Дата"
                        prepend-icon="event"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                    />
                  </template>
                  <v-date-picker
                      v-model="date"
                      scrollable
                      show-adjacent-months
                      locale="ru-RU"
                      :first-day-of-week="1"
                      @input="saveDate(date)"
                  >
                  </v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
            </div>
            <ScheduleTwoWeek
                :scheduleBoardFor="getScheduleBoardFor"
                :subject="getCurrentSubject"
                :date="dateSearch"
                :user="user"
            >
            </ScheduleTwoWeek>
          </v-container>
        </v-tab-item>

        <v-tab-item v-if="isUmu() || isSdk() || isAdmin()">
          <v-container>
            <div class="ma-4 d-print-none">
              <v-row>
                <v-col>
                  <v-autocomplete v-model="audience"
                                  :items="audiences"
                                  label="Аудитория"
                                  dense
                                  item-value="id"
                                  item-text="name"
                                  prepend-icon="meeting_room"
                                  return-object
                                  :loading="audiencesLoading"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-menu
                      ref="dialog_date"
                      v-model="modal_date3"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                          :value="computedDateFormatted"
                          label="Дата"
                          prepend-icon="event"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                      />
                    </template>
                    <v-date-picker
                        v-model="date"
                        scrollable
                        show-adjacent-months
                        locale="ru-RU"
                        :first-day-of-week="1"
                        @input="saveDate(date)"
                    >
                    </v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>
            </div>
            <ScheduleTwoWeek
                scheduleBoardFor="AUDIENCE"
                :subject="audience"
                :date="dateSearch"
                :user="user"
            >
            </ScheduleTwoWeek>
          </v-container>
        </v-tab-item>

        <v-tab-item v-if="isAdmin()">
          <v-container class="ma-4 d-print-none">
            <div>
              <v-row>
                <v-col>
                  <v-autocomplete v-model="student"
                                  :items="students"
                                  label="Студент"
                                  dense
                                  item-value="id"
                                  item-text="student"
                                  return-object
                                  prepend-icon="person"
                                  no-data-text="Студентов нет!"
                                  :loading="studentsLoading"
                  >
                  </v-autocomplete>
                </v-col>
              </v-row>
              <v-row v-if="isAdmin()">
                <v-col >
                  <v-menu
                      ref="dialog_date"
                      v-model="modal_date5"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                          :value="computedDateFormatted"
                          label="Дата"
                          prepend-icon="event"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                      />
                    </template>
                    <v-date-picker
                        v-model="date"
                        scrollable
                        show-adjacent-months
                        locale="ru-RU"
                        :first-day-of-week="1"
                        @input="saveDate(date)"
                    >
                    </v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>
            </div>
            <ScheduleTwoWeek
                scheduleBoardFor="STUDENT"
                :subject="student"
                :date="dateSearch"
                :user="user"
            >
            </ScheduleTwoWeek>
          </v-container>
        </v-tab-item>



      </v-tabs-items>
    </v-container>

  </div>
</template>

<script>
import Settings from "@/settings/settings_url";
import {HTTP} from '@/settings/axios'
import ScheduleTwoWeek from '@/components/scheduleBoard/ScheduleTwoWeek';
import date_utils from "@/settings/date_utils";

export default {
  name: "ScheduleAll",
  components: {
    ScheduleTwoWeek,
  },
  data() {
    return {
      modal_date1: false,
      modal_date2: false,
      modal_date3: false,
      modal_date4: false,
      modal_date5: false,
      modal_date6: false,
      date: new Date().toISOString().split('T')[0],
      dateSearch: new Date().toISOString().split('T')[0],
      tab: 0,
      teacher: {},
      teachers:[],
      group: {},
      groups: [],
      student: {},
      user: {},
      students: [],
      audience: null,
      audiences: [],
      discipline: null,
      disciplines: [],

      errorMessage: null,
      errored: false,

      studentsLoading: false,
      groupsLoading: false,
      teachersLoading: false,
      audiencesLoading: false,
      disciplineLoading: false,

      printing: false,
    }
  },
  computed:{
    computedDateFormatted () {
      return date_utils.formatFullDate(this.date)
    },
    startTab(){
      if (this.getScheduleBoardFor != null){
        return 3
      }

      return 0
    },
    getScheduleBoardFor(){
      if (this.user != null && this.user.students != null && this.user.students.length > 0){
        return "STUDENT"
      }
      if (this.user.teachers != null){
        return "TEACHER"
      }
      return null
    },
    getCurrentSubject(){
      if (this.getScheduleBoardFor === "STUDENT"){
        return this.$store.getters.selectedStudent
      }
      if (this.getScheduleBoardFor === "TEACHER"){
        return this.user.teachers
      }
      return null
    },
  },
  watch:{

  },
  methods: {
    goToTelegramBot() {
      let url = ""

      if(this.isTeacher())
        url += "teacher_" + this.$store.getters.selectedUser.teachers.id
      else if(this.isStudent())
        url += "student_" + this.$store.getters.selectedStudent.groups.id

      window.open("https://t.me/SseuScheduleBot?start=" + url);
    },
    saveDate(date){
      this.$refs.dialog_date.save(date)
      this.dateSearch = date
    },
    getUserName(){
      if (this.getScheduleBoardFor === "STUDENT"){
        if (this.$store.getters.selectedStudent.orionId === null || this.$store.getters.selectedStudent.orionId === ''){
          this.errored = true
          this.errorMessage = " Ошибка привязки кампусной карты! Обратитесь по почте brso@sseu.ru"
        }
        return this.$store.getters.selectedStudent.student + ". Группа -  " + this.$store.getters.selectedStudent.groups.name
      }
      if (this.getScheduleBoardFor === "TEACHER"){
        if (this.$store.getters.selectedUser.teachers.orionId == null
            || this.$store.getters.selectedUser.teachers.orionId == ''){
          this.errored = true
          this.errorMessage = " Ошибка привязки кампусной карты! Обратитесь по почте brso@sseu.ru"
        }
        return this.user.teachers.teacher
      }
      return null
    },
    getGroups: async function () {
      this.groupsLoading = true
      HTTP.get(Settings.API.URL_DEFAULT_SCHEDULE_BOARD + "/schedule-board/groups")
          .then((response) => {
            this.groups = response.data
          })
          .catch(() => {
          })
          .finally(() => {
            this.groupsLoading = false
          });
    },
    getTeachers: async function () {
      this.teachersLoading = true
      HTTP.get(Settings.API.URL_DEFAULT_SCHEDULE_BOARD + "/schedule-board/teachers")
          .then((response) => {
            this.teachers = response.data
          })
          .catch(() => {
          })
          .finally(() => {
            this.teachersLoading = false
          });
    },
    getStudents: async function () {
      this.studentsLoading = true
      HTTP.get(Settings.API.URL_DEFAULT_SCHEDULE_BOARD + "/schedule-board/students")
          .then((response) => {
            this.students = response.data
          })
          .catch(() => {
          })
          .finally(() => {
            this.studentsLoading = false
          });
    },
    getAudiences: async function () {
      this.audiencesLoading = true
        HTTP.get(Settings.API.URL_DEFAULT_SCHEDULE_BOARD + "/audiences")
            .then((response) => {
              this.audiences = response.data
            })
            .catch(() => {
            })
            .finally(() => {
              this.audiencesLoading = false
            });
    },
    getActiveDisciplines() {
      this.disciplineLoading = true
      HTTP.get(Settings.API.URL_DEFAULT_SCHEDULE_BOARD + "/disciplines/active")
          .then((response) => {
            this.disciplines = response.data
            console.log(this.disciplines)
          }).finally(() => {
        this.disciplineLoading = false
      });
    },
    getCurrentUser: async function () {
      HTTP.get(Settings.API.URL_DEFAULT_SCHEDULE_BOARD + "/users/current-user")
          .then((response) => {
            this.user = response.data
            // console.log(this.user)
          })
          .catch(() => {
          })
          .finally(() => {
            this.tab = this.startTab
          });
    },
    isUmu() {
      return this.isRole("ROLE_UMU")
    },
    isSdk() {
      return this.isRole("ROLE_SDK")
    },
    isAdmin(){
      return this.isRole("ROLE_ADMIN")
    },
    isStudent() {
      return this.isRole("ROLE_STUDENT")
    },
    isTeacher() {
      return this.isRole("ROLE_TEACHER")
    },
    isRole(role) {
      if (this.user?.role != null && this.user?.role.length > 0){
        for (let i = 0; i < this.user?.role.length; i++){
          if (
              this.user?.role[i].name === role
          ){
            return true
          }
        }
      }else {
        return  false
      }
    },
    // isSdkSpo(){
    //   console.log(this.user?.role)
    //   if (this.user?.role != null && this.user?.role.length > 0){
    //     for (let i = 0; i < this.user?.role.length; i++){
    //       console.log(this.user?.role[i].name)
    //       if (
    //           this.user?.role[i].name === "ROLE_SDK_SPO"
    //       ){
    //         return true
    //       }
    //     }
    //   }else {
    //     return  false
    //   }
    // },
    showDate(){
      if (this.user?.role != null && this.user?.role.length > 0){
        for (let i = 0; i < this.user?.role.length; i++){
          if (
              this.user?.role[i].name === "ROLE_SDK" ||
              this.user?.role[i].name === "ROLE_ADMIN" ||
              this.user?.role[i].name === "ROLE_TEACHER" ||
              this.user?.role[i].name === "ROLE_UMU"
          ){
            return true
          }
        }
      }else {
        return  false
      }
    },
    showSchedule(){
      if (this.user?.role != null && this.user?.role.length > 0){
        for (let i = 0; i < this.user?.role.length; i++){
          if (
              this.user?.role[i].name === "ROLE_ADMIN" ||
              this.user?.role[i].name === "ROLE_UMU"
          ){
            return true
          }
        }
      }else {
        return  false
      }
    },
  },
  created() {
    this.getGroups()
    this.getTeachers()
    this.getStudents()
    this.getAudiences()
    this.getActiveDisciplines()
    this.getCurrentUser()
    console.log( localStorage.getItem('AuthorizationToken'))
    console.log( "/login?token=" + localStorage.getItem('AuthorizationToken'))
  }
}
</script>

<style scoped>
@media print {
  div {
    float: none !important;
    position: static !important;
    display: inline;
    box-sizing: content-box !important;
  }
}
</style>
