<template>
  <v-container>
    <v-card-title class="justify-center">
      <h1>История посещений преподавателей</h1>
    </v-card-title>
    <journal-teacher-visits ref="journal"/>
  </v-container>
</template>

<script>
import JournalTeacherVisits from "@/components/statistics/JournalTeacherVisits";

export default {
  name: "TeacherVisits",
  components: {
    JournalTeacherVisits
  },
}
</script>

<style scoped>

</style>