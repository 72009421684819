<template>
  <v-container>
    <h2 v-if="showHeader">
      <span v-if="!question.isRequired">{{ question.position }}) {{ question.text }}</span>
      <v-tooltip bottom v-if="question.isRequired" color="error">
        <template v-slot:activator="{ on, attrs }">
          <span v-bind="attrs" v-on="on">{{ question.position }}) {{ question.text }}
            <span class="red--text" >
              *
            </span>
          </span>
        </template>
        <span>Этот вопрос обязательный!</span>
      </v-tooltip>
    </h2>
    <v-container v-if="question.type === 'ONE_ANSWER'">
        <v-row>
          <v-col>
            <v-radio-group v-model="answerList[0]"
                           :rules="oneAnswerRules"
            >
              <v-radio v-for="answer in question.answerList"
                       :key="answer.id"
                       :label="answer.text"
                       :value="answer"
                       :hide-details="answer.position !== question.answerList.length"
                       class="mb-4"
              />
            </v-radio-group>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-btn
                color="primary"
                @click='answerList = []'
                v-show="!question.isRequired && answerList !== null && answerList.length > 0"
            >
              Сбросить
            </v-btn>
          </v-col>
        </v-row>
      </v-container>

      <v-container v-if="question.type === 'MULTIPLE_ANSWERS'">
        <v-row>
          <v-col>
            <v-checkbox v-for="answer in question.answerList"
                        :key="answer.position"
                        v-model="answerList"
                        :label="answer.text"
                        :value="answer"
                        :rules="multipleAnswersRules"
                        :hide-details="answer.position !== question.answerList.length"
            />
          </v-col>
        </v-row>

      </v-container>

      <v-container v-if="question.type === 'OPEN_FORM'">
        <v-row>
          <v-col>
            <v-textarea
                counter="255"
                :rules="openAnswerRules"
                outlined
                auto-grow
                name="input-7-4"
                label="Ваш ответ"
                :value="studentAnswer.openAnswer"
                v-model="studentAnswer.openAnswer"
                clearable
            />
          </v-col>
        </v-row>
      </v-container>

      <v-container v-if="question.type === 'OPEN_FORM_CAN_BE_DISABLED'">
        <v-row>
          <v-col>
            <v-checkbox v-for="answer in question.answerList"
                        :key="answer.id"
                        v-model="answerList"
                        :label="answer.text"
                        :value="answer"
                        hide-details
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-textarea
                ref="openAnswerCanBeDisabled"
                :disabled="answerList.length > 0"
                counter="255"
                outlined
                auto-grow
                name="input-7-4"
                label="Ваш ответ"
                v-model="studentAnswer.openAnswer"
                :rules="openAnswerCanBeDisabledRules"
                :hint="answerList.length > 0
                  && studentAnswer.openAnswer !== null
                  && studentAnswer.openAnswer !== '' ? 'Ответ не будет учтен' : null"
                persistent-hint
                clearable
            />
          </v-col>
        </v-row>
      </v-container>
  </v-container>
</template>

<script>
export default {
  name: "TestingFormQuestion",

  data() {
    return {
      savingAnswer: false,

      answerList: [],
      studentAnswer: {
        pickedAnswerList: [],
        openAnswer: null,
        student: null,
        question: null,
      },
    }
  },

  methods: {
    resetAnswers() {
      this.createStudentAnswer()
      this.studentAnswer.openAnswer = null
      this.studentAnswer.pickedAnswerList = []
      this.answerList = []
      this.$emit("reset-validation")
    },
    createStudentAnswer() {
      this.studentAnswer.student = this.$store.getters.selectedStudent
      this.studentAnswer.question = this.question;
    },
    getStudentAnswers() {
      let studentAnswerList = []
      if(this.question.type === 'OPEN_FORM_CAN_BE_DISABLED') {
        let studAnswer = {
          openAnswer: this.studentAnswer.openAnswer,
          student: this.studentAnswer.student,
          question: this.studentAnswer.question,
          pickedAnswer: null
        }
        studentAnswerList.push(studAnswer)
      } else {
        this.answerList.forEach(answer => {
          let studAnswer = {
            openAnswer: null,
            student: this.studentAnswer.student,
            question: this.studentAnswer.question,
            pickedAnswer: answer
          }
          studentAnswerList.push(studAnswer)
        })
      }

      return studentAnswerList
    },
  },
  computed: {
    multipleAnswersRules() {
      if(this.question.isRequired) {
        return [
          this.answerList !== null || 'Выберите хотя бы один пункт',
          this.answerList.length > 0 || 'Выберите хотя бы один пункт',
        ]
      }
      return []
    },
    oneAnswerRules() {
      if(this.question.isRequired) {
        return [
          this.answerList !== null || 'Выберите хотя бы один пункт',
          this.answerList.length > 0 || 'Выберите хотя бы один пункт',
        ]
      }
      return []
    },
    openAnswerRules() {
      if(this.question.isRequired)
        return [
          v => !!v && v !== '' || 'Заполните это поле',
          v => v === null || v.length <= 255 || 'Ответ - не более 255 слов',
        ]
      return [
        v => v === null || v.length <= 255 || 'Ответ - не более 255 слов',
      ]
    },
    openAnswerCanBeDisabledRules() {
      if(this.question.isRequired)
        return [
          v => this.answerList.length > 0 || !!v || 'Заполните это поле или поставьте галочку',
          v => v === null || v.length <= 255 || 'Ответ - не более 255 слов',
        ]
      return [
        v => v === null || v.length <= 255 || 'Ответ - не более 255 слов',
      ]
    },
  },
  watch: {
    'answerList'() {
      if(this.question.isRequired
          && this.question.type === 'OPEN_FORM_CAN_BE_DISABLED'
          && this.answerList.length > 0) {
        this.$emit('validate')
      }
    },
  },

  props: {
    question: {
      type: Object,
      default: null,
    },
    showHeader: {
      type: Boolean,
      default: true
    }
  },
  created() {
    this.createStudentAnswer()
  },
}
</script>

<style scoped>

</style>