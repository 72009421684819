<template>
  <div>

  <div class="mb-3">
    <h3 v-if=" lessons.workload.teacher"> {{ lessons.workload.teacher.fio }} </h3>
    <h3 v-else style="color: red"> не закреплен </h3>
  </div>
    <v-autocomplete v-model="lessons.workload.replacementTeachers"
                    :items="replacementTeachers"
                    label="Замена"
                    dense
                    chips
                    deletable-chips
                    item-value="id"
                    item-text="teacher"
                    return-object
                    multiple
    >
    </v-autocomplete>


    <v-autocomplete v-model="lessons.audiences"
                    :items="audiences"
                    label="Аудитория"
                    :clearable="true"
                    dense
                    chips
                    deletable-chips
                    item-value="id"
                    item-text="itemName"
                    return-object
                    multiple
    >
    </v-autocomplete>
<!--    <v-row>-->
      <v-autocomplete v-model="lessons.workloadStreamLessons"
                      :items="streamLessons"
                      label="Поток"
                      dense
                      chips
                      deletable-chips
                      item-value="id"
                      item-text="workPlan.group.name"
                      return-object
                      multiple
      >
      </v-autocomplete>
<!--    </v-row>-->
  </div>
</template>

<script>
import Settings from "@/settings/settings_url";
import {HTTP} from '@/settings/axios'

export default {
  name: "Lessons",
  props: {
    title: String,
    lessons: Object,
    audiences: Array,
    replacementTeachers: Array
  },
  data(){
    return{
      streamLessons: [],
    }
  },
  methods: {
    getStreamLessons: async function () {
      if (this.lessons.workload != null && this.lessons.workload.workPlan != null && this.lessons.workload.workPlan.discipline != null
          && this.lessons.workload.workPlan.lessonTypes != null && this.lessons.workload.workPlan.halfYear != null
          && this.lessons.workload.workPlan.yearOfStudy != null) {
        let isSubGroup = this.lessons.workload.subGroup != undefined && this.lessons.workload.subGroup != null && this.lessons.workload.subGroup.id != undefined && this.lessons.workload.subGroup.id != null
        await HTTP.get(Settings.API.URL_DEFAULT + "/workload/workload-for-stream?hours=" +
            this.lessons.workload.workPlan.hours + "&disciplineId=" + this.lessons.workload.workPlan.discipline.id +
            "&lessonTypesId=" + this.lessons.workload.workPlan.lessonTypes.id + "&halfYearId=" +
            this.lessons.workload.workPlan.halfYear.id + "&yearOfStudyId=" + this.lessons.workload.workPlan.yearOfStudy.id
            + "&teacherId=" + this.lessons.workload.teacher.id + "&isSubGroup=" + isSubGroup)
            .then((response) => {
              // console.log(response.data)
              let id = this.lessons.workload.id
              if (id != null && id != 0) {
                this.streamLessons = response.data.filter(function (item) {
                  return item.id != id
                })
                this.lessons.workloadStreamLessons = []
                // console.log(this.streamLessons)
              }
            })
            .catch(() => {
              this.errored = true;
            })
            .finally(() => {
            });
      }

    },
  },
  created() {
    this.getStreamLessons()
  }
}
</script>

<style scoped>

</style>