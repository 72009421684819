<template>
  <div class="statistics">
    <v-btn
        v-bind:color="filtered ? 'primary' : 'white'"
        fab
        right
        fixed
        absolute
        @click="dialog = !dialog">
      <v-icon>settings</v-icon>
    </v-btn>
    <LessonStatisticsCard v-for="(item) in currLessons" :key="item.id" :lesson="item"/>
    <v-progress-circular
        :indeterminate="loading"
        :active="loading"
        color="primary"
        v-show="loading"
    />
    <div v-if="currLessons.length == 0 && !loading">
      <v-alert :type="alertType" >
        <h3>{{ emptyMsg }}</h3>
      </v-alert>
    </div>
    <v-dialog
        v-model="dialog"
        max-width="500px">
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Фильтр занятий
        </v-card-title>
        <v-card-text>
          <v-select :items="facultyList" v-model="faculty" label="Выберите институт"></v-select>
          <v-select :items="floorList" v-model="floor" label="Выберите номер этажа"></v-select>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn text color="primary" @click="resetLessonsFilter()">Сбросить</v-btn>
          <v-btn text color="primary" @click="filterLessons()">Применить</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {HTTP} from "@/settings/axios";
import Settings from "@/settings/settings_url";
import LessonStatisticsCard from "@/components/statistics/LessonStatisticsCard";

export default {
  name: "CurrentLessons",
  data() {
    return {
      currLessons: [],
      lessonStartTimes: [ "08:30", "10:15", "12:20", "14:05", "16:10", "18:00", "19:45"],
      lessonEndTimes: [ "10:05", "11:50", "13:55", "15:40", "17:45", "19:35", "21:20"],
      dialog: false,
      emptyMsg: "Занятий нет",
      faculty: "",
      floor: "",
      facultyList: [],
      floorList: ["", "1", "2", "3", "4", "5"],
      filtered: false,
      loading: false,
      alertType: "info",
    }
  },

  components: {
    LessonStatisticsCard
  },

  methods: {
    getCurrentTime: function() {
      let currentDate = new Date();
      this.$store.commit("currentTime", currentDate.toLocaleTimeString())
    },
    getCurrentLessonTime: function() {
      let currentTime = this.$store.getters.currentTime
      for(let i = 0; i < this.lessonStartTimes.length; i++) {
        if(currentTime.toString() <= this.lessonEndTimes[i] && currentTime.toString() >= this.lessonStartTimes[i]) {
          this.$store.commit("currentLessonTime", this.lessonStartTimes[i] + " - " + this.lessonEndTimes[i])
          // console.log(this.currentLessonTime)
          break;
        } else
          this.$store.commit("currentLessonTime", "")
      }
    },
    resetLessonsFilter: function() {
      this.dialog = false
      this.floor = ""
      this.faculty = ""
      this.getCurrentLessons()
      this.filtered = false
    },
    filterLessons: function() {
      this.dialog = false
      this.getCurrentLessons()

      if(this.faculty == '' && this.floor == '') {
        this.filtered = false
      } else {
        this.filtered = true
      }
    },
    getCurrentLessons: function() {
      this.loading = true
      HTTP.get(Settings.API.URL_DEFAULT + "/lessons/get-current?faculty=" + this.faculty + "&floor=" + this.floor
      + "&updateVisits=true")
          .then((response) => {
            this.currLessons = response.data
            if(this.currLessons.length < 1) {
              this.alertType = "info"
              this.emptyMsg = "Занятий нет"
            }
            // console.log(this.currLessons)
          })
          .catch(() => {
            this.alertType = "error"
            this.emptyMsg = "Произошла ошибка при получении занятий"
          })
          .finally(() => {
            this.loading = false
          })
    },
    getFaculties: function() {
      HTTP.get(Settings.API.URL_DEFAULT + "/groups/get-faculties")
          .then((response) => {
            this.facultyList = response.data
            this.facultyList.unshift("")
            // console.log(this.facultyList)
          })
          .catch(() => {

          })
          .finally(() => {

          })
    }
  },
  created() {
    this.getFaculties()
    this.getCurrentLessons()
    this.getCurrentTime()
    this.getCurrentLessonTime()
    setInterval(this.getCurrentLessons, 60000)
    setInterval(this.getCurrentLessonTime, 1000)
    setInterval(this.getCurrentTime, 1000)
  },
}
</script>

<style scoped>
.statistics {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}
</style>