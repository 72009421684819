<template>
  <div>
    <v-container>
      <v-card >
        <v-card-title class="justify-center"><span  class = 'ltrcd' >Техническая поддержка БРСО СГЭУ</span></v-card-title>
      </v-card>
      <v-card >
        <v-spacer><p></p></v-spacer>
        <v-card-title class="justify-center">
<!--           <v-row><v-col></v-col></v-row>-->
            <v-row> <v-col>
<!--              <span  class = 'ltrcd' align="right">Студент</span>-->
            </v-col>
            </v-row>
        </v-card-title>
       <v-spacer><p></p></v-spacer>
       <v-spacer><p></p></v-spacer>
       <v-spacer><p></p></v-spacer>
       <v-spacer><p></p></v-spacer>

        <v-card-text>
          <v-row>
            <v-col md="3">
            </v-col>
            <v-col md="3">
              <diV class = 'text-right'>
                <span class = 'ttl'>Студент:</span>
              </diV>
            </v-col>
            <v-col md="3">
              <v-autocomplete
                  v-model="inst"
                  :items="insts"
                  outlined

                  dense
                  chips
                  small-chips
                  item-text="name"
                  item-value="id"
                  return-object
                  label="ФИО"
              ></v-autocomplete>
            </v-col>
            <v-col md="3"></v-col>
          </v-row>
          <v-row><v-col></v-col></v-row>
          <v-row>
            <v-col md="1">
              <diV class = 'text-right'>
                 <span class = 'ltrcd'>Код LMS:</span>
              </diV>
            </v-col>
            <v-col md="2">
              <v-text-field
                  value="00000000"
                  outlined
              ></v-text-field>
            </v-col>
            <v-col >
              <diV class = 'text-right'>
              <span class = 'ltrcd'>Orion ID:</span>
              </diV>
            </v-col>
            <v-col md="2">
              <v-text-field
                  value="00000000"
                  outlined
              ></v-text-field>
            </v-col>

            <v-col  >
              <diV class = 'text-right'>
                <span class = 'ltrcd'>ID_number:</span>
              </diV>
            </v-col>
            <v-col md="2">
              <v-text-field
                  value="00000000"
                  outlined
              ></v-text-field>
            </v-col>
            <v-col md="1"></v-col>
            <v-col md="1">
              <v-btn
                  color="blue"
                  elevation="7"
                  fab
                  outlined
                 small
                  rounded
                  block
                  title="Обновить данные у студента"
              >
                <i class="material-icons">autorenew</i>
              </v-btn>
            </v-col>
            <v-col></v-col>
          </v-row>
          <v-row>
            <v-col md="1">
              <diV class = 'text-right'>
                <span class = 'ltrcd'>Группа:</span>
              </diV>
            </v-col>
            <v-col md="2">
              <v-autocomplete
                  v-model="inst"
                  :items="insts"
                  outlined
                  dense
                  chips
                  small-chips
                  item-text="name"
                  item-value="id"
                  return-object
                  label="Группа"
              ></v-autocomplete>
            </v-col>
            <v-col md="1" >
              <diV class = 'text-right'>
                <span class = 'ltrcd'>Код группы:</span>
              </diV>
            </v-col>
            <v-col md="2">
              <v-text-field
                  value="00000000"
                  outlined
                  disabled
              ></v-text-field>
            </v-col>

            <v-col md="1" >
              <diV class = 'text-right'>
                <span class = 'ltrcd'>Код группы У студента:</span>
              </diV>
            </v-col>
            <v-col md="2">
              <v-text-field
                  value="00000000"
                  outlined
              ></v-text-field>
            </v-col>
            <v-col md="1"></v-col>
            <v-col md="1">
              <v-btn
                  color="blue"
                  elevation="7"
                  fab
                  outlined
                  small
                  rounded
                  block
                  title="Обновить группу студента"
              >
                <i class="material-icons">autorenew</i>
              </v-btn>
            </v-col>
            <v-col></v-col>
          </v-row>
          <v-spacer><p></p></v-spacer>
          <v-simple-table >
            <template v-slot:default>
              <thead>
              <tr>
                <th></th>
                <th class="text-left">
                  <h2>
                    ФИО студента
                  </h2>
                </th>
                <th class="text-left">
                  <h2>
                    Фото
                  </h2>
                </th>
                <th class="text-left">
                  <h2>
                    Группа
                  </h2>
                </th>
                <th class="text-left">
                  <h2>
                    Код LMS
                  </h2>
                </th>
                <th class="text-left">
                <h2>
                 Orion_ID
                </h2>
              </th>
                <th class="text-left">
                  <h2>
                    ID_number
                  </h2>
                </th>
                <th class="text-left">
                  <h2>
                    Email доступа к LMS
                  </h2>
                </th>
              </tr>
              </thead>
              <tbody>
              <tr
                  v-for="item in studs"
                  :key="item.name"
                  class="text-left"
              >
                <td></td>
                <td><span class ="ltr">{{ item.stud }}</span></td>
                <td>
                  <template>
                    <v-container
                        fluid

                    >
                      <v-row justify="left">
                        <v-menu
                            bottom

                            rounded
                            offset-y
                        >
                          <template v-slot:activator="{ on }">
                            <v-btn
                                icon
                                x-large
                                v-on="on"
                            >
                              <v-avatar
                                  color="primary"
                                 size="40"
                              >
                                <span class="white--text text-h5">{{item.sphoto}}</span>
                              </v-avatar>
                            </v-btn>
                          </template>
                          <v-card>
                            <v-list-item-content class="justify-center">
                              <div class="mx-auto text-center">
                                <v-avatar
                                    color="primary"
                                >
                                  <span class="white--text text-h5">{{ item.sphoto }}</span>
                                </v-avatar>
                                <v-spacer><p></p></v-spacer>
                                <h3> {{ item.stud }}</h3>

                                <v-divider class="my-3"></v-divider>
                                <v-img
                                    lazy-src="https://picsum.photos/id/11/10/6"
                                    max-height="264"
                                    max-width="250"
                                    src="https://picsum.photos/id/11/500/300"
                                ></v-img>
                                <v-divider class="my-3"></v-divider>

                              </div>
                            </v-list-item-content>
                          </v-card>
                        </v-menu>
                      </v-row>
                    </v-container>
                  </template>

                </td>
                <td><span class ="ltr">{{ item.grp }}</span></td>
                <td><span class ="ltr">{{ item.cdlms }}</span></td>
                <td><span class ="ltr">{{ item.cdorion }}</span></td>
                <td><span class ="ltr">{{ item.idnum }}</span></td>
                <td><span class ="ltr">{{ item.mail }}</span></td>

              </tr>
              </tbody>
            </template>
          </v-simple-table>



        </v-card-text>




      </v-card>
      <v-divider>

      </v-divider>
    </v-container>
    <v-container>

      <v-card>
        <v-spacer><p></p></v-spacer>
        <v-card-title class="justify-center">
          <!--           <v-row><v-col></v-col></v-row>-->
          <v-row> <v-col>
            <!--              <span  class = 'ltrcd' align="right">Студент</span>-->
          </v-col>
          </v-row>
        </v-card-title>
        <v-spacer><p></p></v-spacer>
        <v-spacer><p></p></v-spacer>
        <v-spacer><p></p></v-spacer>
        <v-spacer><p></p></v-spacer>
        <v-card-text>
          <v-row>
            <v-col md="3">
            </v-col>
            <v-col md="3">
              <diV class = 'text-right'>
                <span class = 'ttl'>Преподаватель:</span>
              </diV>
            </v-col>
            <v-col md="3">
              <v-autocomplete
                  v-model="inst"
                  :items="insts"
                  outlined

                  dense
                  chips
                  small-chips
                  item-text="name"
                  item-value="id"
                  return-object
                  label="ФИО"
              ></v-autocomplete>
            </v-col>
            <v-col md="3"></v-col>
          </v-row>
          <v-row><v-col></v-col></v-row>
          <v-row>
            <v-col md="1">
              <diV class = 'text-right'>
                <span class = 'ltrcd'>Код LMS:</span>
              </diV>
            </v-col>
            <v-col md="2">
              <v-text-field
                  value="00000000"
                  outlined
              ></v-text-field>
            </v-col>
            <v-col >
              <diV class = 'text-right'>
                <span class = 'ltrcd'>Orion ID:</span>
              </diV>
            </v-col>
            <v-col md="2">
              <v-text-field
                  value="00000000"
                  outlined
              ></v-text-field>
            </v-col>

            <v-col  >
              <diV class = 'text-right'>
                <span class = 'ltrcd'>ID_number:</span>
              </diV>
            </v-col>
            <v-col md="2">
              <v-text-field
                  value="00000000"
                  outlined
              ></v-text-field>
            </v-col>
            <v-col md="1"></v-col>
            <v-col md="1">
              <v-btn
                  color="blue"
                  elevation="7"
                  fab
                  outlined
                  small
                  rounded
                  block
                  title="Обновить данные у преподавателя"
              >
                <i class="material-icons">autorenew</i>
              </v-btn>
            </v-col>
            <v-col></v-col>
          </v-row>

          <v-spacer><p></p></v-spacer>
          <v-simple-table >
            <template v-slot:default>
              <thead>
              <tr>
                <th></th>
                <th class="text-left">
                  <h2>
                    ФИО преподавателя
                  </h2>
                </th>
                <th class="text-left">
                  <h2>
                    Фото
                  </h2>
                </th>
                <th class="text-left">
                  <h2>
                    Код LMS
                  </h2>
                </th>
                <th class="text-left">
                  <h2>
                    Orion_ID
                  </h2>
                </th>
                <th class="text-left">
                  <h2>
                    ID_number
                  </h2>
                </th>
                <th class="text-left">
                  <h2>
                    Email доступа к LMS
                  </h2>
                </th>
              </tr>
              </thead>
              <tbody>
              <tr
                  v-for="item in tchrs"
                  :key="item.name"
                  class="text-left"
              >
                <td></td>
                <td><span class ="ltr">{{ item.tchr }}</span></td>
                <td>
                  <template>
                    <v-container
                        fluid

                    >
                      <v-row justify="left">
                        <v-menu
                            bottom

                            rounded
                            offset-y
                        >
                          <template v-slot:activator="{ on }">
                            <v-btn
                                icon
                                x-large
                                v-on="on"
                            >
                              <v-avatar
                                  color="primary"
                                  size="40"
                              >
                                <span class="white--text text-h5">{{item.tphoto}}</span>
                              </v-avatar>
                            </v-btn>
                          </template>
                          <v-card>
                            <v-list-item-content class="justify-center">
                              <div class="mx-auto text-center">
                                <v-avatar
                                    color="primary"
                                >
                                  <span class="white--text text-h5">{{ item.tphoto }}</span>
                                </v-avatar>
                                <v-spacer><p></p></v-spacer>
                                <h3> {{ item.tchr }}</h3>

                                <v-divider class="my-3"></v-divider>
                                <v-img
                                    lazy-src="https://picsum.photos/id/11/10/6"
                                    max-height="264"
                                    max-width="250"
                                    src="https://picsum.photos/id/1/200/300"
                                ></v-img>
                                <v-divider class="my-3"></v-divider>

                              </div>
                            </v-list-item-content>
                          </v-card>
                        </v-menu>
                      </v-row>
                    </v-container>
                  </template>
                </td>

                <td><span class ="ltr">{{ item.cdlms }}</span></td>
                <td><span class ="ltr">{{ item.cdorion }}</span></td>
                <td><span class ="ltr">{{ item.idnum }}</span></td>
                <td><span class ="ltr">{{ item.mail }}</span></td>

              </tr>
              </tbody>
            </template>
          </v-simple-table>



        </v-card-text>
      </v-card>
    </v-container>
  </div>
</template>

<script>
export default {
name: "SupportPRS",
  data: () => ({
    studs: [
      {
        stud: 'Иванов И.И.',
        sphoto: 'ИИ',
        grp: 'Группа1',
        cdlms: '00000111',
        cdorion:'12345',
        idnum:'000001_000000_000000',
        mail:'mail@mail.com',
      },
      {
        stud: 'Иванов И.И.',
        sphoto: 'ИИ',
        grp: 'Группа1',
        cdlms: '00000111',
        cdorion:'12345',
        idnum:'000001_000000_000000',
        mail:'mail@mail.com',
      },
      {
        stud: 'Иванов И.И.',
        sphoto: 'ИИ',
        grp: 'Группа1',
        cdlms: '00000111',
        cdorion:'12345',
        idnum:'000001_000000_000000',
        mail:'mail@mail.com',
      },
      {
        stud: 'Иванов И.И.',
        sphoto: 'ИИ',
        grp: 'Группа1',
        cdlms: '00000111',
        cdorion:'12345',
        idnum:'000001_000000_000000',
        mail:'mail@mail.com',
      },





    ],

    tchrs: [
      {
        tchr: 'Петров П.П.',
        tphoto: 'ПП',
        cdlms: '00000111',
        cdorion:'12345',
        idnum:'000001_000000_000000',
        mail:'mail@mail.com',
      },





    ],
  })
}
</script>

<style scoped>
.ttl{

  font-size: 250%;
}
.ltrcd{


  font-size: 150%;
}

</style>