<template>
  <v-container v-if="isAdmin()">
    <h1>Старосты групп</h1>

    <v-row>
      <v-col>
        <v-autocomplete
            label="Группа"
            prepend-icon="groups"
            item-text="name"
            item-value="id"
            v-model="selectedGroup"
            :items="groups"
            :loading="groupsLoading"
        />
      </v-col>
      {{ selectedGroup }}
      <v-col>
        <v-autocomplete
            label="Студент"
            prepend-icon="person"
            item-text="student"
            item-value="id"
            return-object
            v-model="selectedStudent"
            :items="students"
            :loading="studentLoading"
        />
      </v-col>
    </v-row>
    <v-btn color="primary" @click="setGroupLeader" :loading="loading">
      <v-icon left>done</v-icon>
      Поставить
    </v-btn>
  </v-container>
</template>

<script>
import {HTTP} from "@/settings/axios";
import Settings from "@/settings/settings_url";

export default {
  name: "AdminGroupLeader",

  data() {
    return {
      groupsLoading: false,
      groups: [],
      selectedGroup: null,

      studentLoading: false,
      students: [],
      selectedStudent: null,

      loading: false,
    }
  },

  methods: {
    setGroupLeader() {
      this.loading = true
      HTTP.post(Settings.API.URL_DEFAULT + "/students/set-as-group-leader", this.selectedStudent)
          .then((response) => {
            this.selectedStudent = response.data
          }).catch((err) => {
        console.log(err)
      }).finally(() => {
        this.loading = false
      })
    },
    getGroups: async function () {
      this.groupsLoading = true
      HTTP.get(Settings.API.URL_DEFAULT + "/schedule-board/groups")
          .then((response) => {
            this.groups = response.data
          })
          .catch(() => {
          })
          .finally(() => {
            this.groupsLoading = false
          });
    },
    getStudents() {
      this.studentLoading = true
      HTTP.get(Settings.API.URL_DEFAULT + "/groups/" + this.selectedGroup + "/students")
          .then((response) => {
            this.students = response.data
          })
          .catch(() => {
          })
          .finally(() => {
            this.studentLoading = false
          });
    },
    isAdmin(){
      return this.isRole("ROLE_ADMIN")
    },
    isRole(role) {
      if (this.$store.getters.selectedUser?.role != null && this.$store.getters.selectedUser?.role.length > 0){
        for (let i = 0; i < this.$store.getters.selectedUser?.role.length; i++){
          if (
              this.$store.getters.selectedUser?.role[i].name === role
          ){
            return true
          }
        }
      }else {
        return  false
      }
    },
  },

  watch: {
    'selectedGroup'() {
      if(this.selectedGroup)
        this.getStudents()
    }
  },

  created() {
    this.getGroups()
  }

}
</script>

<style scoped>

</style>