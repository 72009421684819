<template>
  <v-container>
    <v-row>
      <v-col class="mb-5">
        <v-btn
            @click="newItem"
            color="primary"
        >
          <v-icon>add</v-icon>
          Создать
        </v-btn>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-dialog
          v-model="dialog"
          fullscreen
          hide-overlay
          transition="dialog-bottom-transition"
      >

        <v-card>
          <v-toolbar
              dark
              color="primary"
          >
            <v-btn
                icon
                dark
                @click="dialog = false"
            >
              <v-icon>close</v-icon>
            </v-btn>
            <v-toolbar-title>Редактирование подгруппы</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items>
              <v-btn
                  dark
                  text
                  @click="save"
              >
                Сохранить
              </v-btn>
            </v-toolbar-items>
          </v-toolbar>
          <sub-group-edit-form
              ref="subGroupForm"
              @saveSuccess="saveSuccess"
              :subGroup="subGroup"
              :edit="edit"
          ></sub-group-edit-form>
        </v-card>
      </v-dialog>
    </v-row>

    <v-container>
      <v-row>
        <v-col class="mr-10">
          <v-autocomplete v-model="yearOfStud"
                          :items="yearOfStuds"
                          label="Год обучения"
                          dense
                          item-value="id"
                          item-text="name"
                          return-object
          >
          </v-autocomplete>
        </v-col>
        <v-col class="mr-10">
          <v-autocomplete v-model="halfYear"
                          :items="halfYears"
                          label="Полугодие"
                          dense
                          item-value="id"
                          item-text="name"
                          return-object
          >
          </v-autocomplete>
        </v-col>
      </v-row>
    <v-row class="mr-10">
      <v-col>
        <v-text-field
            v-model="search"
            prepend-icon="search"
            label="Поиск по названию"
            clearable
            @click:clear="resetSearch"
            @keydown.enter="getSubGroup"
            @focusout="getSubGroup"
        />
        <!--        <v-btn @click="getSubGroup">Найти</v-btn>-->
      </v-col>
      <v-col>
      <v-autocomplete
          v-model="filterGroup"
          :items="groups"
          chips
          small-chips
          clearable
          item-text="name"
          item-value="id"
          label="Группа"
          color="#36a2eb"
          prepend-icon="groups"
      />
      </v-col>
      <v-col>
      <v-autocomplete
          v-model="filterDiscipline"
          :items="disciplines"
          chips
          small-chips
          clearable
          item-text="name"
          item-value="id"
          label="Дисциплины"
          color="#36a2eb"
          prepend-icon="school"
      />
      </v-col>
      <v-col>
      <v-autocomplete
          v-model="filterTeacher"
          :items="teachers"
          chips
          small-chips
          clearable
          item-text="teacher"
          item-value="id"
          label="Преподаватели"
          color="#36a2eb"
          prepend-icon="group"
      ></v-autocomplete>
      </v-col>
    </v-row>
    </v-container>


    <template>
      <v-card>
        <v-card-title>

        </v-card-title>
      <v-data-table
          :loading="loading"
          :headers="headers"
          :items="subGroups"
          :pagination.sync="pagination"
          :items-per-page="pageSize"
          class="elevation-1"
          hide-default-footer
          no-data-text="Нет подгрупп"
          loading-text="Загрузка..."
      >
        <template v-slot:item.groups="{ item }">
          <div v-for="group in item.groups" :key="group.id">
            {{ group.name }}
          </div>
        </template>

        <template v-slot:item.linked="{ item }">
          <v-btn @click="editItem(item)" icon>
            <v-icon>settings</v-icon>
          </v-btn>
        </template>

      </v-data-table>
        <!-- Пагинация -->
        <v-card-text>
          <v-row>
            <v-col
                cols="1"
                sm="1"
                md="1">
            </v-col>
            <v-col
                cols="2"
                sm="2"
                md="2">
              <v-select
                  v-model="pageSize"
                  :items="pageSizes"
                  label="Количество строк "
                  color="#36a2eb"
              >
              </v-select>
            </v-col>
            <v-col>
              <v-pagination
                  class="my-4"
                  v-model="pagination.page"
                  :length="pages"
                  total-visible="9"
                  circle
                  color="#36a2eb"
              >

              </v-pagination>
            </v-col>
          </v-row>
        </v-card-text>
        <!-- Пагинация-->
      </v-card>

    </template>

  </v-container>
</template>

<script>
import Settings from "@/settings/settings_url";
import {HTTP} from '@/settings/axios'
import SubGroupEditForm from '@/components/SubGroupEditForm'

export default {
  name: "SubGroupEdit",
  components:{
    SubGroupEditForm,
  },
  data(){
    return {
      search: '',
      dialog: false,
      edit: false,
      loading: false,

      yearOfStud: {},
      yearOfStuds: [],
      halfYears: [],
      halfYear: {},

      page: 1,
      pageSize: 10,
      totalItems: 0,
      pageSizes: [5, 10, 15, 20, 30, 40, 50, 60, 70, 80, 90, 100],
      pagination: {
        page: 1,
      },

      pages: 1,
      subGroups: [],

      headers: [
        {text: 'ID', align: 'center', sortable: true, value: 'id', divider: true, class: "bolt"},
        {text: 'Название', align: 'center', sortable: true, value: 'name', divider: true, class: "bolt"},
        {text: 'Группа', align: 'center', sortable: true, value: 'workload.workPlan.group.name', divider: true, class: "bolt"},
        {text: 'Дисциплина', align: 'center', sortable: true, value: 'workload.workPlan.discipline.name', divider: true, class: "bolt"},
        {text: 'Тип занятия', align: 'center', sortable: true, value: 'workload.workPlan.lessonTypes.name', divider: true, class: "bolt"},
        {text: 'Преподаватель', align: 'center', sortable: true, value: 'workload.teacher.fio', divider: true, class: "bolt"},
        {text: 'Редактировать', value: 'linked', divider: true, align: 'center'}

      ],

      subGroup: {},
      group: {},
      newSubGroup: {
        name: "",
        status: "ACTIVE",
        typesEducation: "",
        students: [],
        workload: {},
      },

      groups: [],
      disciplines: [],
      teachers: [],

      filterGroup: 0,
      filterDiscipline: 0,
      filterTeacher: 0,
    }
  },
  computed:{

  },
  watch:{
    halfYear(){
      this.getSubGroup()
    },
    yearOfStud(){
      this.getSubGroup()
    },
    'pagination.page'() {
      this.getSubGroup()
    },
    pageSize() {
      this.getSubGroup()
    },
    group() {
      if(this.group == null)
        this.group = 0
      console.log(this.group)
      this.getSubGroup()
    },
    filterGroup() {
      if(this.filterGroup == null)
        this.filterGroup = 0
      console.log(this.filterGroup)
      this.pagination.page = this.page
      this.getSubGroup()
    },
    filterDiscipline() {
      if(this.filterDiscipline == null)
        this.filterDiscipline = 0
      console.log(this.filterDiscipline)
      this.pagination.page = this.page
      this.getSubGroup()
    },
    filterTeacher() {
      if(this.filterTeacher == null)
        this.filterTeacher = 0
      console.log(this.filterTeacher)
      this.pagination.page = this.page
      this.getSubGroup()
    }
  },
  methods:{
    getYear: async function () {
      HTTP.get(Settings.API.URL_DEFAULT + "/year-of-study")
          .then((response) => {
            this.yearOfStuds = response.data
          })
          .catch(() => {

          })
          .finally(() => {
            for (let i = 0; i < this.yearOfStuds.length; i++) {
              if (this.yearOfStuds[i].current == 1) {
                this.yearOfStud = this.yearOfStuds[i]
                break
              }
            }
          });
    },
    getHalfYear: async function () {
      HTTP.get(Settings.API.URL_DEFAULT + "/half-year")
          .then((response) => {
            this.halfYears = response.data
          })
          .catch(() => {

          })
          .finally(() => {
            for (let i = 0; i < this.halfYears.length; i++) {
              if (this.halfYears[i].current == 1) {
                this.halfYear = this.halfYears[i]
                break
              }
            }
          });
    },
    newItem(){
      this.dialog = true
      this.subGroup = {...this.newSubGroup}
      this.edit = false
    },
    editItem(item){
      console.log(item)
      this.subGroup = null
      this.subGroup = {...item}
      this.dialog = true
      this.edit = true
    },
    save(){
      this.$refs.subGroupForm.saveSubGroup()
    },
    saveSuccess(){
      this.dialog = false
      this.getSubGroup()
    },
    getSubGroup: async function () {
      if(this.search == null) {
        this.search = ""
      }

      this.loading = true
      await HTTP.get(Settings.API.URL_DEFAULT + "/sub-groups/getPage?page=" + this.pagination.page + "&size=" + this.pageSize
      + "&groupId=" + this.filterGroup + "&disciplineId=" + this.filterDiscipline + "&teacherId=" + this.filterTeacher
      + "&keyword=" + this.search + "&year=" + this.yearOfStud.id + "&halfYear=" + this.halfYear.id)
          .then((response) => {
            this.subGroups = response.data.content
            this.totalItems = response.data.totalElements
            this.pages = response.data.totalPages
            if(this.pagination.page > this.pages)
              this.pagination.page = 1
            console.log(this.pages)
            console.log(this.subGroups)
            console.log(this.totalItems)
          })
          .catch(() => {
            this.errored = true;
          })
          .finally(() => (this.loading = false));
    },
    getAllDisciplines() {
      HTTP.get(Settings.API.URL_DEFAULT + "/disciplines")
          .then((response) => {
            this.disciplines = response.data
            console.log(this.disciplines)
          });
    },
    getAllGroups() {
      HTTP.get(Settings.API.URL_DEFAULT + "/groups")
          .then((response) => {
            this.groups = response.data
            console.log(this.groups)
          });
    },
    getAllTeachers() {
      HTTP.get(Settings.API.URL_DEFAULT + "/teachers")
          .then((response) => {
            this.teachers = response.data
            console.log(this.teachers)
          });
    },
    resetSearch() {
      this.search = ''
      this.getSubGroup()
    }
  },
  created() {
    this.getSubGroup()
    this.getAllDisciplines()
    this.getAllGroups()
    this.getAllTeachers()
    this.getYear()
    this.getHalfYear()
  }
}
</script>

<style scoped>

</style>