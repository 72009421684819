<template>
  <div id="example-1">
    <section v-if="errored">
      <p>Сервер временно не работает.</p>
    </section>
    <section v-else>
      <div v-if="loading">Информация загружается ....</div>
      <!--      <select v-model="selected">-->
      <!--        <option v-for='item in info' :value='item.groupCode' v-bind:key='item.groupCode'>-->
      <!--          {{item.faculty}}, {{item.course}}, {{item.directionCode}}, {{item.formOfTraining}}, {{item.trainingPeriod}}: Направление: {{item.direction}}, Группа: {{ item.group }}, Кол-во ст. : {{item.numberOfStudents}}-->
      <!--        </option>-->
      <!--      </select>-->
      <v-autocomplete v-model="selectedGroup" @change="onChangeSelectedTask"
                      :items="groups"
                      label="Группы"
                      dense
      >
      </v-autocomplete>
      <br>
      Выбранная группа: {{ selectedGroup }}
    </section>
    <v-card-text>
      <v-layout row wrap>
        <v-flex xs12 md13>
          <v-card-actions class="justify-center">
            <h1>Сводная таблица</h1>
          </v-card-actions>

        </v-flex>
        <v-flex xs12 md7>

        </v-flex>
        <v-flex xs12 md7>

        </v-flex>
      </v-layout>
    </v-card-text>
    <v-layout justify-center>
      <v-flex xs12>
        <v-data-table
            :headers="disciplineHeaders"
            :items="disciplineItems"
            :items-per-page="15"
            :hide-default-footer="false"
            class="elevation-2"
            :single-expand="true"
            item-key="code"
        >
          <template v-slot:item.linked="{ item }">
            <v-btn @click="editControlPoints(item)" icon>
              <v-icon>settings</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-flex>
    </v-layout>


    <template>
      <v-row justify="center">
        <v-dialog
            v-model="dialogEditControlPoints"
            fullscreen
            hide-overlay
            transition="dialog-bottom-transition"
        >

          <v-card>
            <v-toolbar
                dark
                color="primary"
            >
              <v-btn
                  icon
                  dark
                  @click="dialogEditControlPoints = false"
              >
                <v-icon>close</v-icon>
              </v-btn>
              <v-toolbar-title>Список контрольных точек</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-toolbar-items>
                <v-btn
                    dark
                    text
                    @click="dialogEditControlPoints = false"
                >
                  Save
                </v-btn>
              </v-toolbar-items>
            </v-toolbar>


            <v-divider></v-divider>
<!--            <v-subheader>{{ selectDisciplineItems.name }}</v-subheader>-->

            <v-btn v-if="controlPointItems.length < 5"
                color="primary"
                dark
                class="ma-2"
                @click="dialogNewControlPoints = true"
            >
              Добавить
            </v-btn>


            <template>
              <v-row justify="center">
                <v-dialog
                    v-model="dialogNewControlPoints"
                    persistent
                    max-width="600px"
                >
                  <v-card>
                    <v-card-title>
<!--                      <span class="headline">{{ title_dialog }}</span>-->
                    </v-card-title>
                    <v-card-text>
                      <v-container>
                        <v-row>
                          <v-col
                              cols="12"
                              sm="12"
                              md="12"
                          >

                            <template>
                              <div>
                                <v-text-field
                                    v-model="newControlPoints.num"
                                    label="Номер"
                                    :rules="rules"
                                    hide-details="auto"
                                ></v-text-field>
                              </div>
                              <div>
                                <v-text-field
                                    v-model="newControlPoints.name"
                                    label="Нозвание"
                                    :rules="rules"
                                    hide-details="auto"
                                ></v-text-field>
                              </div>
                              <div>
                                <v-text-field
                                    v-model="newControlPoints.numberOfPoints"
                                    label="Кол Баллов"
                                    :rules="rules"
                                    hide-details="auto"
                                ></v-text-field>
                              </div>
                            </template>


                          </v-col>
                        </v-row>
                      </v-container>
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn
                          color="blue darken-1"
                          text
                          @click="dialogNewControlPoints = false"
                      >
                        Close
                      </v-btn>
                      <v-btn
                          color="blue darken-1"
                          text
                          @click="saveControlPoints"
                      >
                        Save
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-row>
            </template>

            <v-layout justify-center>
              <v-flex xs12>
                <v-data-table
                    :headers="controlPointHeaders"
                    :items="controlPointItems"
                    :items-per-page="15"
                    :hide-default-footer="false"
                    class="elevation-2"
                    :single-expand="true"
                    item-key="code"
                >
                  <template v-slot:item.linked="{ item }">
                    <v-btn @click="editControlPoints(item)" icon>
                      <v-icon>settings</v-icon>
                    </v-btn>
                  </template>
                </v-data-table>
              </v-flex>
            </v-layout>


          </v-card>
        </v-dialog>
      </v-row>
    </template>


  </div>


</template>

<script>
import Settings from '../settings/settings_url'
import {HTTP} from '@/settings/axios'

export default {
  name: "AdminControlPoints",
  data() {
    return {
      disciplineHeaders: [
        {text: 'Код', align: 'center', sortable: false, value: 'code', divider: true, class: "bolt"},
        {text: 'Дисциплина', align: 'center', value: 'name', divider: true, sortable: false, class: "bolt"},
        {text: 'setting', value: 'linked', divider: true, sortable: false, class: "bolt"},
      ],
      controlPointHeaders: [
        {text: 'id', align: 'center', sortable: false, value: 'id', divider: true, class: "bolt"},
        {text: 'Название', align: 'center', value: 'name', divider: true, sortable: false, class: "bolt"},
        {text: 'Нномер', value: 'num', divider: true, sortable: false, class: "bolt"},
        {text: 'Количество баллов', value: 'numberOfPoints', divider: true, sortable: false, class: "bolt"},
        {text: 'Контрольная дата', value: 'dateControl', divider: true, sortable: false, class: "bolt"},
        {text: 'setting', value: 'linked', divider: true, sortable: false, class: "bolt"},
      ],
      rules: [
        value => !!value || 'Required.',
      ],
      controlPointItems: [],
      dialogEditControlPoints: false,
      disciplineItems: [],
      selectDisciplineItems: null,
      groups: [],
      dialogNewControlPoints: false,
      selectedGroup: null,
      loading: true,
      errored: false,
      newControlPoints: {
        id: null,
        num: null,
        name: null,
        dateControl: null,
        groupCode: null,
        disciplinesCode: null,
        numberOfPoints: null,
      }

    };
  },

  created() {
    this.getGroupsOneC()
  },
  mounted() {
  },
  methods: {
    getGroupsOneC: function () {
      HTTP.get(Settings.API.URL_DEFAULT + '/groups-one-c/')
          .then((response) => {
            this.groups = response.data.map(function (item) {
              return {
                text: item.group + ", " + item.course + ", " + item.faculty + ", Направление:  " + item.direction + ", Число студентов: " + item.numberOfStudents + ", " + item.formOfTraining + ", " + item.directionCode + ", " + item.trainingPeriod,
                value: item.groupCode
              }
            })

          })
          .catch(() => {
            this.errored = true;
          })
          .finally(() => (this.loading = false));
    },
    onChangeSelectedTask: function () {
      HTTP.get(Settings.API.URL_DEFAULT + '/groups-one-c/' + this.selectedGroup + '/disciplines')
          .then((response) => {
            this.disciplineItems = response.data
          })
    },
    getControlPointsByGroupCode: function (groupCode, disciplineCode) {
      HTTP.get(Settings.API.URL_CONTROL_PINTS_BY_GROUP_CODE_AND_DISCIPLINE_CODE(groupCode, disciplineCode))
          .then((response) => {
            this.controlPointItems = response.data
          })
    },
    editControlPoints: function (item) {
      let code = item.code;
      if (code === undefined){
        code = item.disciplinesCode
        this.dialogNewControlPoints = true
      }
      this.selectDisciplineItems = item
      this.getControlPointsByGroupCode(this.selectedGroup, code)
      this.dialogEditControlPoints = true

    },
    saveControlPoints: function (){
      this.newControlPoints.groupCode = this.selectedGroup
      this.newControlPoints.disciplinesCode = this.selectDisciplineItems.code
      HTTP.post(Settings.API.URL_DEFAULT + Settings.API.URL_CONTROL_POINTS, this.newControlPoints)
          .then(() => {
            this.getControlPointsByGroupCode(this.selectedGroup, this.selectDisciplineItems.code)
            this.dialogNewControlPoints = false
          })

    },
    // onChangeInfoStud: async function ({item}){
    //   this.onChangeInfoStud1(item)
    // },
    // onChangeConnDel: async function (itembrso, item, grId, join = true){
    //   if(item.linked){
    //     this.ConnectDel(itembrso, item)
    //   //}else if((!join || !item.linked) && (!join || !itembrso.linked)){
    //   }else if(!item.linked){
    //     this.ConnectUs(itembrso, item, grId, join)
    //   }
    // }
    // ,
    // ConnectUs: async function (itembrso, item, grId, join = true){
    //   let idSt = null;
    //   if (join) idSt = itembrso.id;
    //   await HTTP.post(Settings.API.URL_DEFAULT + '/students', {
    //     'grade_book_student_one_c': item.gradebook,
    //     'id_student_orion': idSt,
    //     'group_code_one_c': grId,
    //     'join': join
    //   })
    //       .then((response) => {
    //        this.info = response.data;
    //        this.onChangeInfoStud1(item)
    //         this.onChangeSelectedTask()
    //      })
    // },
    // ConnectDel: async function (itembrso, item){
    //   await HTTP.delete(Settings.API.URL_DEFAULT + '/students/' + item.studentIdLink + '?studentcode=' + item.studentCode + '&orionid=' + itembrso.id)
    //       .then((response) => {
    //         this.info = response.data;
    //         this.onChangeInfoStud1(item)
    //         this.onChangeSelectedTask()
    //       })
    // },
    //     getColor(item, itembrso){
    //
    //         if(itembrso.studentCode != item.studentCodeLink && item.linked == true){
    //           return "red"
    //         }
    //         else if(item.linked == true){
    //           return "green"
    //         }
    //         else{
    //           return ""
    //         }
    //
    //     },
    // onChangeInfoStud1: async function (item){
    //   this.stinfo = []
    //
    //   await HTTP.get(Settings.API.URL_DEFAULT + '/users-orion?name='+item.name+'&patronymic='+item.patronymic+'&surname='+item.surname)
    //       .then((response) => {
    //         this.info = response.data;
    //         this.stinfo = this.info
    //       })
    // }
  }
}
</script>

