<template>

  <v-layout align-center justify-center>
    <v-flex xs10>
      <v-card>
        <v-card-text>
          <v-layout row wrap>
            <v-flex xs12 md6>
              <span>Расписание</span>
              <v-list>
                <v-list-item
                    v-for="(item, i) in nav_items_schedule"
                    :key="i"
                    link
                    :to="item.url"
                >

                  <v-list-item-icon>
                    <v-icon>{{ item.icon }}</v-icon>
                  </v-list-item-icon>

                  <v-list-item-content>
                    <v-list-item-title>{{ item.text }}</v-list-item-title>
                  </v-list-item-content>

                </v-list-item>
              </v-list>
            </v-flex>
            <v-flex xs12 md6>
              <span>Аудитории</span>
              <v-list>
                <v-list-item
                    v-for="(item, i) in nav_items_audience"
                    :key="i"
                    link
                    :to="item.url"
                >

                  <v-list-item-icon>
                    <v-icon>{{ item.icon }}</v-icon>
                  </v-list-item-icon>

                  <v-list-item-content>
                    <v-list-item-title>{{ item.text }}</v-list-item-title>
                  </v-list-item-content>

                </v-list-item>
              </v-list>
            </v-flex>
            <v-flex xs12 md6>
              <span>Пары</span>
              <v-list>
                <v-list-item
                    v-for="(item, i) in nav_items_lesson"
                    :key="i"
                    link
                    :to="item.url"
                >

                  <v-list-item-icon>
                    <v-icon>{{ item.icon }}</v-icon>
                  </v-list-item-icon>

                  <v-list-item-content>
                    <v-list-item-title>{{ item.text }}</v-list-item-title>
                  </v-list-item-content>

                </v-list-item>
              </v-list>
            </v-flex>
            <v-flex xs12 md6>
              <span>Темная тема</span>
              <v-switch v-model="settings_color_themes" primary label="Dark"></v-switch>
            </v-flex>

            <v-flex xs12 md6>
              <span>Меню</span>
              <v-radio-group v-model="settings_primary_drawer_type" column>
                <v-radio
                    v-for="(drawer, i) in drawers"
                    :key="i"
                    :label="drawer.title"
                    :value="drawer.val.toLowerCase()"
                    primary
                ></v-radio>
              </v-radio-group>
              <v-switch v-model="settings_primary_drawer_clipped" label="Меню под хедером" primary></v-switch>
              <v-switch v-model="settings_primary_drawer_floating" label="Скрыть граници меню" primary></v-switch>
              <v-switch v-model="settings_primary_drawer_mini" label="Меню только иконки" primary></v-switch>
            </v-flex>
            <v-flex xs12 md6>
              <span>Футер</span>
              <v-switch v-model="settings_footer_inset" label="Футер адаптируется по ширину контента" primary></v-switch>
            </v-flex>
          </v-layout>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn>Cancel</v-btn>
          <v-btn color="primary">Submit</v-btn>
        </v-card-actions>
      </v-card>
    </v-flex>
  </v-layout>

</template>

<script>

export default {
  name: "Setting",
  data: () => ({
    nav_items_audience:[
      {icon: "settings", text: "Аудитории", url: "/audiences"},
      {icon: "settings", text: "Типы аудиторий", url: "/audiencestype"},
    ],
    nav_items_lesson: [
      {icon: "settings", text: "Тип пары", url: "/lesson-types"},
      {icon: "settings", text: "Номер пары", url: "/lesson-numbers"},
      {icon: "settings", text: "Дни", url: "/lesson-type-days"},
    ],

    nav_items_schedule: [
      {icon: "settings", text: "Редактирование расписания", url: "/scheduleedit"},
      {icon: "report", text: "Группы и студенты", url: "/adminjson"},
      {icon: "settings", text: "Дисциплины", url: "/disciplines"},
      {icon: "settings", text: "Типы расписания", url: "/scheduletype"},





    ],
    drawers: [
      {val: 'Default (no property)', title: 'Скрывать кнопкой меню'},
      {val: 'Permanent', title: 'Видно всегда'},
      {val: 'Temporary', title: 'Скрывать при действии'},
    ],
  }),
  computed: {
    settings_color_themes: {
      get() {
        return this.$store.getters.settings_color_themes;
      },
      set(value) {
        this.$store.commit('update_settings_color_themes', value)
        this.$vuetify.theme.dark = this.$store.getters.settings_color_themes;
      }
    },
    settings_primary_drawer_model: {
      get() {
        return this.$store.getters.settings_primary_drawer_model;
      },
      set(value) {
        this.$store.commit('update_settings_primary_drawer_model', value)
      }
    },
    settings_primary_drawer_type: {
      get() {
        return this.$store.getters.settings_primary_drawer_type;
      },
      set(value) {
        this.$store.commit('update_settings_primary_drawer_type', value)
      }
    },
    settings_primary_drawer_clipped: {
      get() {
        return this.$store.getters.settings_primary_drawer_clipped;
      },
      set(value) {
        this.$store.commit('update_settings_primary_drawer_clipped', value)
      }
    },
    settings_primary_drawer_floating: {
      get() {
        return this.$store.getters.settings_primary_drawer_floating;
      },
      set(value) {
        this.$store.commit('update_settings_primary_drawer_floating', value)
      }
    },
    settings_primary_drawer_mini: {
      get() {
        return this.$store.getters.settings_primary_drawer_mini;
      },
      set(value) {
        this.$store.commit('update_settings_primary_drawer_mini', value)
      }
    },
    settings_footer_inset: {
      get() {
        return this.$store.getters.settings_footer_inset;
      },
      set(value) {
        this.$store.commit('update_settings_footer_inset', value)
      }
    }
  },
  created() {
    this.$vuetify.theme.dark = this.$store.getters.settings_color_themes;
  }

}
</script>

<style scoped>

</style>
