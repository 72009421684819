<template>
  <div>
    <v-container justify-center>
      <div class="text-center">
        <h2>Администрирование групп и типов справок</h2>
      </div>
      <v-tabs
          v-model="tab"
      >
        <v-tab>
          <v-icon left>
            mdi-account
          </v-icon>
          Группы справок
        </v-tab>
        <v-tab>
          <v-icon left>
            mdi-lock
          </v-icon>
          Типы справок
        </v-tab>
      </v-tabs>
      <v-tabs-items
          v-model="tab"
          :touchless="true"
      >
        <v-tab-item>
          <v-container>
            <v-row>
              <v-col class="text-right">
                <v-btn
                    @click="addGroupCertificate"
                    color="primary"
                >
                  <v-icon>add</v-icon>
                  Добавить
                </v-btn>
              </v-col>
            </v-row>

            <v-row class="mb-4 mt-4">
              <v-col>
                <group-certificate-list
                    :groupCertificates="groupCertificates"
                    @editGroupCertificate="editGroupCertificate"
                >
                </group-certificate-list>
              </v-col>

            </v-row>

            <group-certificate-card
                :show="showGroupEditDialog"
                :groupCertificate="newGroupCertificate"
                @close="closeGroupCertificateDialog"
                @addGroupCertificateSuccess="addGroupCertificateSuccess"
            >

            </group-certificate-card>
          </v-container>
        </v-tab-item>

        <v-tab-item>
          <v-container>
            <v-row>
              <v-col class="text-right">
                <v-btn
                    @click="addTypeCertificate"
                    color="primary"
                >
                  <v-icon>add</v-icon>
                  Добавить
                </v-btn>
              </v-col>
            </v-row>
            <v-row class="mb-4 mt-4">
              <v-col>
                <type-certificate-list
                    :typeCertificates="typeCertificates"
                    @editTypeCertificate="editTypeCertificate"
                ></type-certificate-list>
              </v-col>
            </v-row>

            <type-certificate-card
                :show="showTypeEditDialog"
                :typeCertificate="newTypeCertificate"
                :groupCertificates="groupCertificates"
                @close="closeTypeCertificateDialog"
                @addTypeCertificateSuccess="addTypeCertificateSuccess"
            >

            </type-certificate-card>

          </v-container>
        </v-tab-item>

      </v-tabs-items>
    </v-container>
  </div>
</template>

<script>
import Settings from "@/settings/settings_url";
import {HTTP} from '@/settings/axios'

import GroupCertificateList from "@/components/certificate/GroupCertificateList";
import GroupCertificateCard from "@/components/certificate/GroupCertificateCard";
import TypeCertificateList from "@/components/certificate/TypeCertificateList";
import TypeCertificateCard from "@/components/certificate/TypeCertificateCard";

export default {
  name: "AdminCertificate",
  components: {
    GroupCertificateList,
    GroupCertificateCard,
    TypeCertificateList,
    TypeCertificateCard
  },
  data() {
    return {
      tab: 0,
      groupCertificates: [],

      typeCertificates: [],

      showGroupEditDialog: false,
      newGroupCertificate: {},

      showTypeEditDialog: false,
      newTypeCertificate: {},
    }
  },
  methods: {
    closeGroupCertificateDialog() {
      this.showGroupEditDialog = false
      this.getGroupCertificate()
    },
    closeTypeCertificateDialog() {
      this.showTypeEditDialog = false
      this.getTypeCertificate()
    },
    addGroupCertificateSuccess() {
      this.showGroupEditDialog = false
      this.getGroupCertificate()
    },
    addTypeCertificateSuccess() {
      this.showTypeEditDialog = false
      this.getTypeCertificate()
    },
    editGroupCertificate(item) {
      this.showGroupEditDialog = true
      this.newGroupCertificate = {...item}
    },
    editTypeCertificate(item) {
      this.showTypeEditDialog = true
      this.newTypeCertificate = {...item}
    },
    addGroupCertificate() {
      this.showGroupEditDialog = true
      this.newGroupCertificate = {
        id: null,
        title: "",
        description: "",
        status: "ACTIVE"
      }
    },
    addTypeCertificate() {
      this.showTypeEditDialog = true
      this.newTypeCertificate = {
        id: null,
        title: "",
        description: "",
        readinessInDays: 0,
        isFile: false,
        fileDescription: "",
        groupCertificate: null,
        status: "ACTIVE"
      }
    },
    getGroupCertificate: async function () {
      HTTP.get(Settings.API.URL_DEFAULT + "/group-certificate")
          .then((response) => {
            this.groupCertificates = response.data
          })
          .catch(() => {
          })
          .finally(() => {

          });
    },
    getTypeCertificate: async function () {
      HTTP.get(Settings.API.URL_DEFAULT + "/type-certificate")
          .then((response) => {
            this.typeCertificates = response.data
          })
          .catch(() => {
          })
          .finally(() => {

          });
    },
  },
  created() {
    this.getGroupCertificate()
    this.getTypeCertificate()
  }
}
</script>

<style scoped>

</style>