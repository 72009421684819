<template>
  <!--  style="background-color: #ff5252"-->
  <div v-if="is_lesson">
    <div class="ma-2 ">
      <span>{{ header }}</span>
    </div>
<!--    style="text-align:  center;"-->
    <div class="ma-lg-2 ">
      Аудитория - {{ room }}
    </div>

    <div class="ma-lg-2 ">
      {{ content }}
      <v-icon v-if="!is_teacher" color="error">error</v-icon>
    </div>
    <div class="ma-lg-2 ">
      Студенты - {{ current_count }} / {{ full_count }}
      <v-icon v-if="get_message_students() != ''" :color="get_message_students()">{{ get_message_students() }}</v-icon>
    </div>
    <div class="ma-lg-2 ">
      <!--      <v-icon color="warning">warning</v-icon>Студенты - 28 / 34-->
    </div>
<!--    <v-divider class="mx-4" ></v-divider>-->
  </div>
</template>

<script>
export default {
  name: "ReportItem",
  props: {
    header: String,
    content: String,
    room: String,
    current_count: Number,
    full_count: Number,
    is_teacher: Boolean,
    is_lesson: Boolean,
    footer: String,
    color: String
  },
  methods: {
    get_message_students() {
      let percent = this.current_count / this.full_count * 100;
      if (percent <= 70) return "error";
      if (percent <= 90) return "warning";
      return "";
    }
  }
}
</script>

<style scoped>

</style>