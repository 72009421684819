<template>
  <div>

    <div v-if="loading" class="progress-barr">
      <div class="progress">
        <v-progress-circular
            :size="100"
            :width="10"
            color="blue"
            indeterminate
        ></v-progress-circular>
      </div>
    </div>
    <v-app id="schedule">
      <v-container justify-center>
        <v-flex xs12>
          <v-card>
            <v-card-text>
              <v-layout row wrap>
                <v-flex xs12 md12>
                  <v-card-actions class="justify-center">
                    <h1>Журнал успеваемости - {{ visits.title }}</h1>
                  </v-card-actions>

                </v-flex>
                <v-flex xs12 md6>

                </v-flex>
                <v-flex xs12 md6>

                </v-flex>
              </v-layout>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>

            </v-card-actions>
          </v-card>
        </v-flex>
      </v-container>
      <v-container justify-center>
        <v-flex xs12>
          <v-data-table
              :headers="visits.headers"
              :items="visits.students"
              :items-per-page="80"
              :hide-default-footer="true"
              :hide-default-header="true"
              class="elevation-2"
          >

<!--            <template v-slot:top>-->
<!--              <v-row>-->
<!--                <v-col>-->
<!--                  <div class="text-left ">-->
<!--                    <v-switch-->
<!--                        v-model="all"-->
<!--                        label="Все"-->
<!--                        class="pa-3"-->
<!--                    ></v-switch>-->
<!--                  </div>-->
<!--                </v-col>-->
<!--              </v-row>-->
<!--            </template>-->

            <template v-slot:header="{ props }">
              <thead class="v-data-table-header">
              <tr>
                <th class="text-start v-data-table__divider" v-for="head in props.headers" :key="head.value">
                  {{ head.text }}
<!--                  <v-btn-->
<!--                      v-if="head.lessonId != null"-->
<!--                      icon-->
<!--                      @click="showDialogUploadFile(head.date, head.lessonId)"-->
<!--                  >-->
<!--                    <v-icon small>file_upload</v-icon>-->
<!--                  </v-btn>-->
                </th>
              </tr>
              </thead>
            </template>

            <template v-slot:item="item">
              <tr>
                <td class="td-border-right v-data-table__divider" v-for="header in visits.headers" :key="header.value"
                    @click="showDialog(item.item[header.value])">
                  {{ content(item.item[header.value]) }}
                </td>
              </tr>
            </template>

            <!--            <template v-slot:item="item">-->
            <!--              <tr>-->
            <!--                <td class="td-border-right v-data-table__divider" v-for="header in headers"-->
            <!--                    :key="item.item[header.value].id">-->
            <!--                  {{ item.item[header.value].text }}-->
            <!--                </td>-->
            <!--              </tr>-->
            <!--            </template>-->
          </v-data-table>
        </v-flex>
      </v-container>

      <template>
        <v-row justify="center">
          <v-dialog
              v-model="dialog"
              width="600px"
              persistent
          >
            <v-card>
              <v-card-title>
                <span class="headline">Редактировать успеваемость</span>
              </v-card-title>

                            <v-card-text>
                              <p>Студент - <b> {{ visitItem.students.student }} </b> </p>
                              <p>Дисциплина - {{ visitItem.workload.workPlan.discipline.name }} </p>
                              <p>Тип - {{ visitItem.workload.workPlan.lessonTypes.name }} </p>
                              <p>Дата - {{ visitItem.date }} </p>
                              <p>Статус - <b> {{ isVisitDialog(visitItem) }} </b> </p>
                              <p>Оценка:</p>
                              <v-card-text>
                                <v-text-field
                                    v-model="visitItem.evaluation"
                                    hint="Введите заработанные баллы"
                                    :rules="rules"
                                    :label="'Максимальный балл -' + maxEvaluation"
                                    :readonly="!isEditItem(visitItem)"
                                ></v-text-field>
                              </v-card-text>
<!--                              <p>Оценка - {{ visitItem.evaluation != null ? visitItem.evaluation.toFixed(1): "" }} </p>-->
                            </v-card-text>

              <v-card-actions>
                <v-btn
                    color="green darken-1"
                    text
                    @click="commentDialog = !commentDialog"
                >
                  история
                </v-btn>

                <v-spacer></v-spacer>
                <v-btn
                    color="green darken-1"
                    text
                    @click="dialog = !dialog"
                >
                  cancel
                </v-btn>
                <v-btn v-if="isEditItem(visitItem)"
                    color="red darken-1"
                    text
                    @click="updateEvaluationGradeBook"
                >
                  Ок
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-row>
      </template>

      <template>
        <v-row justify="center">
          <v-dialog
              v-model="commentDialog"
              width="600px"
              persistent
          >
            <v-card>
              <v-card-title>
                <span class="headline">История</span>
              </v-card-title>

              <v-card-text>
                <ul>
                  <li v-for="item in visitItem.journalGradeComments" :key="item.id">
                    {{ getDatetime(item.date)   }} - {{ getValComment(item.preVal, item.newVal) }} : {{ item.user.username }} :
                    {{ getTextComment(item) }}
                  </li>
                </ul>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    color="green darken-1"
                    text
                    @click="commentDialog = !commentDialog"
                >
                  OK
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-row>
      </template>

<!--      <template>-->
<!--        <v-row justify="center">-->
<!--          <v-dialog-->
<!--              v-model="dialogUploadFile"-->
<!--              width="600px"-->
<!--              persistent-->
<!--          >-->
<!--            <v-card>-->
<!--              <v-card-title>-->
<!--                <span class="headline">Загрузить файл</span>-->
<!--              </v-card-title>-->

<!--              <v-card-text>-->
<!--                <template>-->
<!--                  <v-file-input-->
<!--                      v-model="files"-->
<!--                      placeholder="Upload your documents"-->
<!--                      label="File input"-->
<!--                      multiple-->
<!--                      prepend-icon="mdi-paperclip"-->
<!--                  >-->
<!--                    <template v-slot:selection="{ text }">-->
<!--                      <v-chip-->
<!--                          small-->
<!--                          label-->
<!--                          color="primary"-->
<!--                      >-->
<!--                        {{ text }}-->
<!--                      </v-chip>-->
<!--                    </template>-->
<!--                  </v-file-input>-->
<!--                  &lt;!&ndash;                                <v-btn&ndash;&gt;-->
<!--                  &lt;!&ndash;                                    color="primary"&ndash;&gt;-->
<!--                  &lt;!&ndash;                                    dark&ndash;&gt;-->
<!--                  &lt;!&ndash;                                    text&ndash;&gt;-->
<!--                  &lt;!&ndash;                                    @click="uploadFile"&ndash;&gt;-->
<!--                  &lt;!&ndash;                                >&ndash;&gt;-->
<!--                  &lt;!&ndash;                                  Загрузить&ndash;&gt;-->
<!--                  &lt;!&ndash;                                </v-btn>&ndash;&gt;-->
<!--                </template>-->
<!--              </v-card-text>-->

<!--              <v-card-actions>-->
<!--                <v-spacer></v-spacer>-->
<!--                <v-btn-->
<!--                    color="green darken-1"-->
<!--                    text-->
<!--                    @click="dialogUploadFile = !dialogUploadFile"-->
<!--                >-->
<!--                  cancel-->
<!--                </v-btn>-->
<!--                <v-btn-->
<!--                    color="green darken-1"-->
<!--                    text-->
<!--                    @click="uploadFile"-->
<!--                >-->
<!--                  ОК-->
<!--                </v-btn>-->
<!--              </v-card-actions>-->
<!--            </v-card>-->
<!--          </v-dialog>-->
<!--        </v-row>-->
<!--      </template>-->

    </v-app>

  </div>
</template>

<script>

import Settings from "@/settings/settings_url";
import {HTTP} from '@/settings/axios'

export default {
  name: "Gradebook",
  props:{
    groupId: Number,
    disciplineId: Number
  },
  data() {
    return {
      //editItem: {},
      loading: false,
      all: true,
      files: [],
      commentDialog:  false,
      visits:{

      },
      //title: "",
      //headers: [],
      //students: [],
      //discipline: "",
      //group: "",
      dialog: false,
      // dialogUploadFile: false,
      maxEvaluation: 0,
      rules: [v => v <= this.maxEvaluation || 'Max ' + this.maxEvaluation + ' Баллов'],
      visitItem: {
        date: "",
        evaluation: 0,
        visit: 0,
        students:{
          student: ""
        },
        workload:{
          workPlan: {
            discipline:{
              name: ""
            },
            lessonTypes:{
              name: ""
            }
          }
        }
      },
      // dateUploadedFile: "",
      // uploadLessonId: 0,
    }
  },
  watch: {
    all: function () {
      this.getJournal()
    },
    groupId: function (){
      this.updateTable()

    },
    disciplineId: function (){
      this.updateTable()
    },
  },
  methods: {
    getDatetime: function (date){
      let dateF = new Date(Date.parse(date))
      return dateF.getDate() + "." + dateF.getMonth() + "." + dateF.getFullYear() +
          "г. " + dateF.getHours() + ":" + dateF.getMinutes() + "м "
    },
    getValComment: function (preVal,  newVal){
      let pval = preVal.toFixed(2)
      let nVal = newVal.toFixed(2)
      return "Изменено с " +  pval + " -> " + nVal
    },
    getTextComment: function (item){
      if (item.comment != ""){
        return "Комментарий - " + item.comment
      }
    },
    updateTable: function () {
      if (this.groupId != undefined && this.groupId != 0
          && this.disciplineId != undefined && this.disciplineId != 0){
        this.getJournal()
      }
    },
    responseVisitUpdate: function (stud){
      for (let i = 0; i < this.visits.students.length; i++ ){
        for (const [key, value] of Object.entries(this.visits.students[i])) {
          if (value instanceof Object){
            if (value.id == stud.id){
              console.log(this.visits.students[i])
              this.visits.students[i][key] = stud
              break
            }
          }
        }
      }
      this.calcSum()
    },
    calcSum: function () {
      // eslint-disable-next-line no-unused-vars
      let sum = 0
      const copy = []
      //const getPoints = this.getPoints
      for (let i = 0; i < this.visits.students.length; i++ ){
        // eslint-disable-next-line no-unused-vars
        for (const [key, value] of Object.entries(this.visits.students[i])) {
          if (value instanceof Object){
            sum += value.evaluation != null? value.evaluation: 0
          }
        }
        this.visits.students[i].sum = sum.toFixed(1) + "Б"
        copy.push(this.visits.students[i])
        sum = 0
      }

      this.visits.students = copy
      console.log(this.visits.students)
    },
    isVisitDialog:  function (item) {
      if (item instanceof Object) {
        if (item.visit === 1){
          return "Присутствовал(a)"
        }
        if (item.visit === 2){
          return "Отсутствовал(a) по уважительным причинам"
        }
        return "Отсутствовал(a)"
      }
      return item
    },
    content: function (item) {
      if (item instanceof Object) {
        return item.evaluation != null ? item.evaluation: ""
      }
      return item
    },
    // showDialogUploadFile: function (dateUploadedFile, uploadLessonId) {
    //   this.dialogUploadFile = true
    //   this.dateUploadedFile = dateUploadedFile
    //   this.uploadLessonId = uploadLessonId
    // },
    // uploadFile: function () {
    //   let formData = new FormData();
    //   formData.append('file', this.files[0]);
    //   formData.append('date', this.dateUploadedFile);
    //   formData.append('discipline', this.discipline);
    //   formData.append('group', this.group);
    //   formData.append('lessonId', this.uploadLessonId);
    //
    //   HTTP.post(Settings.API.URL_DEFAULT + Settings.API.URL_REGISTRATION_OF_STUDENT_VISIT + "upload-file", formData, {headers: {'Content-Type': 'multipart/form-data'}})
    //       .then(() => {
    //         this.dialogUploadFile = false
    //         this.getJournal()
    //       }).catch(() => {
    //   });
    // },
    isEditItem: function (item){
      if (item.id !== 0 && item.id !== undefined  && item.id !== null &&
          (this.visits.role === "ROLE_TEACHER" || this.visits.role === "ROLE_ADMIN")
      ) {
        if  (this.visits.role === "ROLE_ADMIN"){
          return true
        }

        if (this.visits.editGrade){
          let d1 = new Date(item.date);
          let d2 = new Date();
          if (this.dateDiffInDays(d1, d2) <= this.visits.editDayGrade){  //todo получать с сервака
            return true;
          }
        }

      }
      return false
    },
    showDialog: function (item) {
          this.getMaxEvaluation(item)
          this.dialog = true
          this.visitItem = item
    },
    getMaxEvaluation: function (item){
      HTTP.post(Settings.API.URL_REGISTRATION_OF_STUDENT_VISIT_MAX_EVALUATION(), item
      ).then((response) => {
        console.log(response.data)
        this.maxEvaluation = response.data.evaluation.toFixed(4);
        //this.responseVisitUpdate(response.data)
        //this.evaluationItem.evaluation = response.data.evaluation
      }).catch(() => {
        this.errored = true;
        this.getJournal()
      }).finally(() => {
      });
    },
    dateDiffInDays: function(d1, d2) {
      var t2 = d2.getTime();
      var t1 = d1.getTime();

      return parseInt((t2-t1)/(24*3600*1000));
    },
    // setVisit: function (data) {
    //   if (data.visit === 1) {
    //     this.visitItem.text = "";
    //   } else {
    //     this.visitItem.text = "Н";
    //   }
    // },
    // updateVisit: function () {
    //   HTTP.put(Settings.API.URL_REGISTRATION_OF_STUDENT_VISIT_UPDATE_VISITS(this.visitItem.id)).then((response) => {
    //     console.log(response.data)
    //     //this.setVisit(response.data)
    //     this.responseVisitUpdate(response.data)
    //    //this.visitItem =
    //   }).catch(() => {
    //     this.errored = true;
    //     this.getJournal()
    //   }).finally(() => {
    //     this.dialog = false
    //   });
    // },
    updateEvaluationGradeBook: function () {
      if (this.maxEvaluation >= this.visitItem.evaluation) {
        this.loading = true
        HTTP.put(Settings.API.URL_REGISTRATION_OF_STUDENT_VISIT_UPDATE_EVALUATION(this.visitItem.id), {
          evaluation: this.visitItem.evaluation
        }).then((response) => {
          this.responseVisitUpdate(response.data)
          //this.evaluationItem.evaluation = response.data.evaluation
        }).catch(() => {
          this.errored = true;
          this.getJournal()
        }).finally(() => {
          this.loading = false
          this.dialog = false
        });
      }
    },
    // getPoints: async function (item) {
    //   //this.loading = true
    //   let pointVisit = 0;
    //   await HTTP.get(
    //       Settings.API.URL_REGISTRATION_OF_STUDENT_POINTS_FOR_VISITS(item.students.id, item.workload.group.id, item.workload.discipline.id))
    //       .then((response) => {
    //         //console.log(pointVisit)
    //         pointVisit = response.data.points
    //         // console.log(pointVisit)
    //         // console.log(response.data)
    //       })
    //       .catch(() => {
    //         this.errored = true;
    //       })
    //       .finally(() => {
    //         //this.loading = false
    //       });
    //   return pointVisit
    // },
    getJournal: async function () {

      let groupId = this.groupId
      let disciplineId = this.disciplineId

      if (groupId == 0 || disciplineId == 0){
        groupId = this.$route.query.group
        disciplineId = this.$route.query.discipline
      }

      if (groupId == 0 || disciplineId == 0 || groupId == undefined || disciplineId == undefined ){
        return
      }
      this.loading = true
      await HTTP.get(Settings.API.URL_REGISTRATION_OF_STUDENT_VISIT_GRADE_BOOK(groupId, disciplineId, '2021-01-01', '2021-09-31', this.all ? 0 : 1))
          .then((response) => {
            this.visits = response.data
            // this.title = response.data.title
            // this.headers = response.data.headers
            // this.students = response.data.students
            // this.role = response.data.role
            console.log(this.visits)
          })
          .catch(() => {
            this.errored = true;
          })
          .finally(() => {
            this.calcSum()
            this.loading = false
          });
    },

  },

  created() {
    this.getJournal()
  },
  components: {}
}
</script>

<style lang="sass">


</style>
