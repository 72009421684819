import { render, staticRenderFns } from "./OrderCertificateStudentList.vue?vue&type=template&id=d2f845d8&scoped=true&"
import script from "./OrderCertificateStudentList.vue?vue&type=script&lang=js&"
export * from "./OrderCertificateStudentList.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d2f845d8",
  null
  
)

export default component.exports