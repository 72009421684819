<template>
  <v-dialog v-model="dialog" max-width="600px">
    <v-card>
      <v-toolbar
          dark
          color="primary"
      >
        <v-btn
            icon
            dark
            @click="closeDialog()"
        >
          <v-icon>close</v-icon>
        </v-btn>
        <v-card-title>Вы уверены?</v-card-title>
      </v-toolbar>
      <v-progress-linear
          color="primary"
          :indeterminate="isBeingDeleted"
          :active="isBeingDeleted"
      />
      <v-container>
        <v-card-text v-if="compensationType != null">
          Компенсация <b>"{{ compensationType.name }}"</b> будет <span style="color: red">удалена</span>!
        </v-card-text>
        <v-divider/>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-alert></v-alert>
          <v-btn
              text
              color="secondary"
              @click="closeDialog()"
          >
            Закрыть
          </v-btn>
          <v-btn
              text
              :disabled="isBeingDeleted"
              :loading="isBeingDeleted"
              color="primary"
              @click=deleteCompensationType()
          >
            Удалить
          </v-btn>
        </v-card-actions>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import {HTTP} from "@/settings/axios";
import Settings from "@/settings/settings_url";

export default {
  name: "CompensationTypeDelete",

  data() {
    return {
      isBeingDeleted: false,
      dialog: false,
    }
  },
  methods: {
    deleteCompensationType() {
      this.isBeingDeleted = true
      HTTP.post(Settings.API.URL_DEFAULT + "/compensation-type/delete", this.compensationType)
          .then(() => {
            this.$emit('deletedType')
            this.$emit('success', 'Компенсация успешно удалена')
          })
          .catch(() => {
            this.$emit('error', 'Ошибка удаления')
          })
          .finally(() => {
            this.isBeingDeleted = false
            this.closeDialog()
          });
    },
    closeDialog() {
      this.dialog = false
    },
  },
  props: {
    compensationType: {},
  },
}
</script>

<style scoped>

</style>