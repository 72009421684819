<template>
  <div>
    <v-app id="schedule" v-if="hideStatistics">
      <v-container justify-center>
        <v-flex xs12>
          <v-card>
            <v-card-text>
              <v-layout row wrap>
                <v-flex xs12 md12>
                  <v-card-actions class="justify-center">
                    <h1>Статистика недоступна</h1>
                  </v-card-actions>

                </v-flex>
                <v-flex xs12 md12>

                </v-flex>
                <v-flex xs12 md6>

                </v-flex>
              </v-layout>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>

            </v-card-actions>

          </v-card>
        </v-flex>
        <v-flex/>
        <v-alert type="error" v-if="notPassedTeacherSatisfaction">
          <v-row align="center">
            <v-col class="grow">
              Чтобы открыть статистику, пройдите анкетирование «Преподаватель СГЭУ глазами студента»
            </v-col>
            <v-col class="shrink">
              <v-btn text :to="teacherSatisfactionLink">
                <!--                <v-icon>arrow_back</v-icon>-->
                Пройти анкетирование
              </v-btn>
            </v-col>
          </v-row>
        </v-alert>

        <v-alert type="error" v-if="notPassedCompTesting">
          Чтобы открыть статистику, необходимо пройти комплексное тестирование по ссылкам ниже
          <v-list-item v-for="test in compTests" :key="test.id"
                       @click.middle="goToUrl(test.quizUrl)"
                       @click.left="goToUrlNewTab(test.quizUrl)"
          >
            <v-list-item-icon><v-icon>link</v-icon></v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>
                Нажмите, чтобы перейти к "{{ test.name }}"
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-alert>

      </v-container>
    </v-app>
    <v-app id="schedule" v-if="!hideStatistics">
      <v-container justify-center>
        <v-flex xs12>
          <v-card>
            <v-card-text>
              <v-layout row wrap>
                <v-flex xs12 md12>
                  <v-card-actions class="justify-center">
                    <h1>Статистика - {{ statistics.title }}</h1>
                  </v-card-actions>

                </v-flex>
                <v-flex xs12 md6>

                </v-flex>
                <v-flex xs12 md6>

                </v-flex>
              </v-layout>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>

            </v-card-actions>
          </v-card>
        </v-flex>
      </v-container>
      <v-container>
        <v-flex xs12>
          <v-alert
              v-model="alert"
              dismissible
              :type="alertType"
              transition="fade-transition"
          >
            {{ alertText }}
          </v-alert>
        </v-flex>
      </v-container>
      <v-container>
        <v-row class="mt-6 ml-2">
          <v-col class="mr-10">
            <v-autocomplete v-model="yearOfStudTable"
                            :items="yearOfStudsTable"
                            label="Год обучения"
                            dense
                            item-value="id"
                            item-text="name"
                            return-object
                            prepend-icon="event"
            >
            </v-autocomplete>
          </v-col>
          <v-col class="mr-4">
            <v-autocomplete v-model="halfYearTable"
                            :items="halfYearsTable"
                            label="Полугодие"
                            dense
                            item-value="id"
                            item-text="name"
                            return-object
                            prepend-icon="event"
            >
            </v-autocomplete>
          </v-col>
        </v-row>
      </v-container>
      <v-container justify-center>
        <v-flex xs12>
          <v-alert outlined
                   prominent
                   type="info"
                   color="primary"
                   v-if="pointsBalance != null && pointsBalance.points > 0
                   && currentHalfYear == this.halfYearTable && currentYear == this.yearOfStudTable"
          >
            <v-row align="center">
              <v-col class="grow">
                У вас есть нераспределенные компенсационные баллы ({{ pointsBalance.points }} шт.)!
              </v-col>
              <v-col class="shrink">
                <v-btn
                    color="primary"
                    outlined
                    @click="openTransferPointsPopup()"
                >
                  Распределить
                </v-btn>
              </v-col>
            </v-row>
          </v-alert>
        </v-flex>
      </v-container>
      <v-container justify-center>
        <v-flex xs12>

          <v-data-table
              :headers="statistics.headersV"
              :items="statistics.itemsV"
              :items-per-page="40"
              :hide-default-footer="true"
              class="elevation-2"
              :single-expand="true"
              :loading="loading"
              no-data-text="Нет информации"
              loading-text="Загрузка..."
          >
            <template v-slot:item.name="{ item }">
              <div class="table-item-name">
                <div>
                  {{ item.name }}
                </div>
                <div>
                  <v-btn :to="'/learning-Activity/card?discipline=' + item.disciplineId + '&group=' + item.groupId + '&year=' + yearOfStudTable.id + '&halfYear=' + halfYearTable.id" icon color="primary">
                    <v-icon>subject</v-icon>
                  </v-btn>
                </div>
              </div>
            </template>
            <template v-slot:item.visitPoint="{ item }">
              <v-btn color="primary" large block text :to="'/journal-all?discipline=' + item.disciplineId + '&group=' + item.groupId + '&tab=0' + '&year=' + yearOfStudTable.id + '&halfYear=' + halfYearTable.id">
                {{ item.visitPoint }}
              </v-btn>
            </template>

            <template v-slot:item.visitStats="{ item }">
              <v-btn color="primary" large block text :to="'/journal-all?discipline=' + item.disciplineId + '&group=' + item.groupId + '&tab=0' + '&year=' + yearOfStudTable.id + '&halfYear=' + halfYearTable.id">
                {{ item.visitStats }}
              </v-btn>
            </template>

            <!--            <template v-slot:item.grade="{ item }">-->
            <!--              <v-btn text :to="'/journal-all?discipline=' + item.disciplineId + '&group=' + item.groupId  + '&tab=1'" icon>-->
            <!--                {{ item.grade }}-->
            <!--              </v-btn>-->
            <!--            </template>-->

            <template v-slot:item.controlPoint="{ item }">
              <v-btn color="primary" block text :to="'/journal-all?discipline=' + item.disciplineId + '&group=' + item.groupId  + '&tab=1' + '&year=' + yearOfStudTable.id + '&halfYear=' + halfYearTable.id">
                {{ item.controlPoint }}
              </v-btn>
            </template>

            <!--            <template v-slot:item.ved="{ item }">-->
            <!--              <v-btn text :to="'/journal-all?discipline=' + item.disciplineId + '&group=' + item.groupId + '&tab=3'" icon>-->
            <!--                {{ item.ved }}-->
            <!--              </v-btn>-->
            <!--            </template>-->

          </v-data-table>
        </v-flex>
      </v-container>

<!--      <v-container justify-center v-if="statisticsCourseProject.items.length > 0">-->
<!--        <v-flex xs12>-->
<!--          <div class="mb-4">-->
<!--            <h2>Курсовые проекты</h2>-->
<!--          </div>-->
<!--          <v-data-table-->
<!--              :headers="statisticsCourseProject.headersCourseProject"-->
<!--              :items="statisticsCourseProject.items"-->
<!--              :items-per-page="40"-->
<!--              :hide-default-footer="true"-->
<!--              class="elevation-2"-->
<!--              :single-expand="true"-->
<!--              :loading="loadingCourseProject"-->
<!--          >-->
<!--          </v-data-table>-->
<!--        </v-flex>-->
<!--      </v-container>-->
      <v-container justify-center v-if="statisticsCourseProject.length > 0">
        <v-flex xs12>
          <v-container/>
          <div justify-center>
            <v-flex xs12>
              <v-data-table
                  :headers="headersCourseProject"
                  :items="statisticsCourseProject"
                  :items-per-page="40"
                  :hide-default-footer="true"
                  class="elevation-2"
                  :loading="loadingCourseProject"
              >
                <template v-slot:top>
                  <v-card-text>
                    <v-layout row wrap>
                      <v-flex xs12 md12>
                        <v-card-actions class="justify-center">
                          <h1>Курсовые работы</h1>
                        </v-card-actions>

                      </v-flex>
                      <v-flex xs12 md6>

                      </v-flex>
                      <v-flex xs12 md6>

                      </v-flex>
                    </v-layout>
                  </v-card-text>
                </template>
                <template v-slot:item.visit="{ item }">
                  <v-icon color="green" v-if="item.visit">check_circle</v-icon>
                  <v-icon color="red" v-if="!item.visit">cancel</v-icon>
                </template>
              </v-data-table>
            </v-flex>
          </div>
        </v-flex>
      </v-container>

      <v-container justify-center v-if="statisticsPractice.length > 0">
        <v-flex xs12>
          <v-container/>
          <div justify-center>
            <v-flex xs12>
              <v-data-table
                  :headers="headersPractice"
                  :items="statisticsPractice"
                  :items-per-page="40"
                  :hide-default-footer="true"
                  class="elevation-2"
                  :single-expand="true"
                  :loading="loadingPractice"
              >
                <template v-slot:top>
                  <v-card-text>
                    <v-layout row wrap>
                      <v-flex xs12 md12>
                        <v-card-actions class="justify-center">
                          <h1>Практики</h1>
                        </v-card-actions>

                      </v-flex>
                      <v-flex xs12 md6>

                      </v-flex>
                      <v-flex xs12 md6>

                      </v-flex>
                    </v-layout>
                  </v-card-text>
                </template>
                <template v-slot:item.visit="{ item }">
                  <v-icon color="green" v-if="item.visit">check_circle</v-icon>
                  <v-icon color="red" v-if="!item.visit">cancel</v-icon>
                </template>
              </v-data-table>
            </v-flex>
          </div>
        </v-flex>
      </v-container>

    </v-app>
    <compensation-transfer ref="pointsTransfer" :balance="pointsBalance"
                           :year-of-study-id="yearOfStudTable.id"
                           :half-year-id="halfYearTable.id"
                           :student="$store.getters.selectedStudent"
                           :is-transferred-by-student="true"
                           @updateBalance="updateBalance"
                           @error="showErrorAlert"
                           @success="showSuccessAlert"
                           v-if="pointsBalance != null"
    />

  </div>
</template>

<script>
import Settings from "@/settings/settings_url";
import {HTTP} from '@/settings/axios'
import CompensationTransfer from "@/components/compensation/points/CompensationPointsTransfer";

export default {
  components: {
    CompensationTransfer,
  },
  name: "StudentStatistics",
  data() {
    return {
      currentHalfYear: 1,
      currentYear: 2,
      yearOfStudTable: {},
      yearOfStudsTable: [],
      halfYearTable: {},
      halfYearsTable: [],

      statistics: {},

      statisticsCourseProject: [],
      headersCourseProject: [
        {text: "Практика", align: 'center', sortable: false, value: 'discipline.name', divider: true, class: "bolt"},
        {text: "Формальный балл", align: 'center', sortable: false, value: 'formalEvaluation', divider: true, class: "bolt"},
        {text: "Содержательный балл", align: 'center', sortable: false, value: 'contentEvaluation', divider: true, class: "bolt"},
        {text: "Презентационный балл (защита)", align: 'center', sortable: false, value: 'presentationEvaluation', divider: true, class: "bolt"},
        {text: "Итого", align: 'center', sortable: false, value: 'totalEvaluation', divider: true, class: "bolt"},
        {text: "Явка", align: 'center', sortable: false, value: 'visit', divider: true, class: "bolt"},
      ],

      statisticsPractice: [],
      headersPractice: [
        {text: "Практика", align: 'center', sortable: false, value: 'discipline.name', divider: true, class: "bolt"},
        {text: "Формальный балл", align: 'center', sortable: false, value: 'formalEvaluation', divider: true, class: "bolt"},
        {text: "Содержательный балл", align: 'center', sortable: false, value: 'contentEvaluation', divider: true, class: "bolt"},
        {text: "Презентационный балл (защита)", align: 'center', sortable: false, value: 'presentationEvaluation', divider: true, class: "bolt"},
        {text: "Итого", align: 'center', sortable: false, value: 'totalEvaluation', divider: true, class: "bolt"},
        {text: "Явка", align: 'center', sortable: false, value: 'visit', divider: true, class: "bolt"},
      ],

      desserts: [ ],
      loading: false,
      loadingCourseProject: false,
      loadingPractice: false,

      alert: false,
      alertText: '',
      alertType: 'success',
      pointsBalance: null,

      hideStatistics: false,
      notPassedTeacherSatisfaction: false,
      notPassedCompTesting: false,
      teacherSatisfactionLink: null,
      compTests: [],
    };
  },
  watch: {
    '$store.getters.selectedStudent': async function (){
      this.loading = true
      await this.checkForNotPassedTeacherSatisfactionForms()
      await this.checkForNotPassedCompTesting()
      // await this.checkForTests()
      if(this.yearOfStudTable.id == undefined || this.halfYearTable.id == undefined) { // todo remove - test
        await this.getHalfYearTable() // todo remove - test
        await this.getYearTable() // todo remove - test
      } // todo remove - test
      if (this.yearOfStudTable.id != undefined && this.halfYearTable.id != undefined && !this.hideStatistics){
        await this.getStatistics()
        this.getPointsBalance()
      }
      this.getStatisticsPractice()
      this.getStatisticsCourseProject()
    },
    'yearOfStudTable.id': function (){
      this.loading = true
      if (this.yearOfStudTable.id != undefined && this.halfYearTable.id != undefined && !this.hideStatistics){
        this.getStatistics()
        this.getStatisticsPractice()
        this.getStatisticsCourseProject()
      }
    },
    'halfYearTable.id': function (){
      this.loading = true
      if (this.yearOfStudTable.id != undefined && this.halfYearTable.id != undefined && !this.hideStatistics){
        this.getStatistics()
        this.getStatisticsPractice()
        this.getStatisticsCourseProject()
      }
    },
    'currentHalfYear': function() {
      if (this.currentHalfYear.id != undefined && this.currentYear.id != undefined && !this.hideStatistics){
        this.getStatistics()
        this.getStatisticsPractice()
        this.getStatisticsCourseProject()
        this.getPointsBalance()
      }
    },
    'currentYear': function() {
      if (this.currentHalfYear.id != undefined && this.currentYear.id != undefined && !this.hideStatistics){
        this.getStatistics()
        this.getStatisticsPractice()
        this.getStatisticsCourseProject()
        this.getPointsBalance()
      }
    },
    'alert'() {
      if(this.alert)
        setTimeout(() => {this.alert = false}, 5000)
    },
  },
  methods: {
    getYearTable: async function () {
      HTTP.get(Settings.API.URL_DEFAULT + "/year-of-study")
          .then((response) => {
            this.yearOfStudsTable = response.data
          })
          .catch(() => {

          })
          .finally(() => {
            for (let i = 0; i < this.yearOfStudsTable.length; i++) {
              if (this.yearOfStudsTable[i].id == 1 ){
                this.yearOfStudsTable.splice(i,1)
                // continue // ломает иногда установку нынешнего года
              }
              if (this.yearOfStudsTable[i]?.current == 1) {
                this.yearOfStudTable = this.yearOfStudsTable[i]
                this.currentYear = this.yearOfStudsTable[i]
              }
            }
          });
    },
    getHalfYearTable: async function () {
      HTTP.get(Settings.API.URL_DEFAULT + "/half-year")
          .then((response) => {
            this.halfYearsTable = response.data
          })
          .catch(() => {

          })
          .finally(() => {
            for (let i = 0; i < this.halfYearsTable.length; i++) {
              if (this.halfYearsTable[i].current == 1) {
                this.halfYearTable = this.halfYearsTable[i]
                this.currentHalfYear = this.halfYearsTable[i]
                break
              }
            }
          });
    },
    getStatistics: async function () {
      this.loading = true
      await HTTP.get(Settings.API.URL_STUDENTS_STATISTICS(this.$store.getters.selectedStudent?.id, this.yearOfStudTable.id, this.halfYearTable.id))
          .then((response) => {
            this.statistics = response.data
            // console.log(this.statistics)
          }).finally(() => {
            this.loading = false
            // this.getStatisticsCourseProject()
          })
    },
    getStatisticsCourseProject: function () {
      this.loadingCourseProject = true
      HTTP.get(Settings.API.URL_DEFAULT + "/course-project-sheet/evaluation/student-statistics" +
          "?studentId=" + this.$store.getters.selectedStudent?.id +
          "&yearId=" + this.yearOfStudTable.id  +
          "&halfYearId=" + this.halfYearTable.id)
          .then((response) => {
            this.statisticsCourseProject = response.data
          }).finally(() => {
        this.loadingCourseProject = false
        // this.getStatisticsPractice()
      })
    },
    getStatisticsPractice: function () {
      this.loadingPractice = true
      HTTP.get(Settings.API.URL_DEFAULT + "/practice-sheet/evaluation/student-statistics" +
          "?studentId=" + this.$store.getters.selectedStudent?.id +
          "&yearId=" + this.yearOfStudTable.id  +
          "&halfYearId=" + this.halfYearTable.id)
          .then((response) => {
            this.statisticsPractice = response.data
          }).finally(() => this.loadingPractice = false)
    },
    getPointsBalance: function () {
      HTTP.get(Settings.API.URL_DEFAULT + "/compensation-points-balance/get-balance?studentId=" + this.$store.getters.selectedStudent.id
          + "&halfYearId=" + this.halfYearTable.id
          + "&yearOfStudyId=" + this.yearOfStudTable.id)
          .then((response) => {
            this.pointsBalance = response.data
            if(response.data === '')
              this.pointsBalance = null
          })
          .catch(() => {

          })
          .finally(() => {

          })
    },
    updateBalance(newBalance) {
      this.pointsBalance = newBalance
      this.getStatistics()
    },
    showSuccessAlert(alertText) {
      this.alert = true
      this.alertText = alertText
      this.alertType = 'success'
    },
    showErrorAlert(alertText) {
      this.alert = true
      this.alertText = alertText
      this.alertType = "error"
    },
    openTransferPointsPopup(){
      this.$refs.pointsTransfer.dialog = true
    },
    getTeacherSatisfactionLink() {
      HTTP.get(Settings.API.URL_DEFAULT + "/testing-form/teacher-satisfaction/get-id-for-group"
          + "?groupId=" + this.$store.getters.selectedStudent.groups.id)
          .then((response) => {
            this.teacherSatisfactionLink = "/testing-form?id=" + response.data + "&from=/student-statistics"
          })
    },
    async checkForNotPassedTeacherSatisfactionForms() {
      this.loading = true

      await HTTP.get(Settings.API.URL_DEFAULT + "/testing-form/teacher-satisfaction/have-pending-forms"
          + "?studentId=" + this.$store.getters.selectedStudent.id)
          .then((response) => {
            if(!this.hideStatistics)
              this.hideStatistics = response.data

            this.notPassedTeacherSatisfaction = response.data
            if(response.data === true)
              this.getTeacherSatisfactionLink()
          })
          .catch(() => {

          })
          .finally(() => {
            this.loading = false
          })
    },
    async checkForNotPassedCompTesting() {
      this.loading = true

      await HTTP.get(Settings.API.URL_DEFAULT + "/comprehensive-test/not-passed/this-semester"
          + "?studentId=" + this.$store.getters.selectedStudent.id)
          .then((response) => {
            if(response.data && response.data.length > 0) {
              if(!this.hideStatistics)
                this.hideStatistics = true
              this.notPassedCompTesting = true
              this.compTests = response.data
            }
          })
          .catch(() => {

          })
          .finally(() => {
            this.loading = false
          })
    },
    checkForTests: async function() {
      await HTTP.get(Settings.API.URL_DEFAULT + "/students/check-for-new-passed-tests?studentId=" + this.$store.getters.selectedStudent?.id)
    },
    goToUrl(item) {
      window.open(item)
      window.focus()
    },
    goToUrlNewTab(item) {
      window.open(item, '_blank').focus()
    },
  },

  async mounted() {
    if(!this.$store.getters.selectedStudent.id)
      return

    await this.checkForNotPassedTeacherSatisfactionForms()
    await this.checkForNotPassedCompTesting()

    await this.getHalfYearTable()
    await this.getYearTable()

    this.getPointsBalance()
    // this.getStatistics()
    this.getStatisticsCourseProject()
    this.getStatisticsPractice()
  },
  created() {
    // this.getStatistics()
    // this.getStatisticsCourseProject()
  }
}
</script>

<style scoped>
.table-item-name{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
</style>
