<template>
  <div>
    <div v-if="scheduleGrid.week == 'EVEN'" class="text-center d-print-none">
      <h2>Четная неделя</h2>
    </div>
    <div v-if="scheduleGrid.week == 'ODD'" class="text-center d-print-none">
      <h2>Нечетная неделя</h2>
    </div>
    <v-container class="text-center d-none d-print-block">
      <h2 v-if="scheduleGrid.week == 'ODD'">Нечетная неделя</h2>
      <h2 v-if="scheduleGrid.week == 'EVEN'">Четная неделя</h2>
    </v-container>
    <v-data-table
        mobile-breakpoint="0"
        fixed-header
        :items-per-page="-1"
        :headers="scheduleGrid.headers"
        :items="scheduleGrid.body"
        class="elevation-4 d-print-table"
        item-key="name"
        :hide-default-footer="true"
        :hide-default-header="true"
        :loading="loading"
        no-data-text="Пар нет!"
        loading-text="Загрузка..."
    >
      <template v-slot:header="{ props }">
        <template>
          <thead class="v-data-table-header">
          <tr>
            <th class="text-start v-data-table__divider" v-for="head in props.headers" :key="head.text" >
              {{ head.text }}
            </th>
          </tr>
          </thead>
        </template>
      </template>

      <template v-slot:item="item">
        <schedule-item
            :item="item"
            :headers="scheduleGrid.headers"
        ></schedule-item>
      </template>

    </v-data-table>
  </div>
</template>

<script>
import Settings from "@/settings/settings_url";
import {HTTP} from '@/settings/axios'
import ScheduleItem from '@/components/scheduleBoard/ScheduleItem';

export default {
  name: "Schedule",
  components: {
    ScheduleItem,
  },
  props:{
    scheduleBoardFor: String,
    subject: Object,
    scheduleWeek: String,
    date: String,
    user: Object,
  },
  data() {
    return {
      loading:false,
       scheduleGrid:{},
    }
  },
  watch:{
    subject(){
      this.getScheduleCurrentWeek()
    },
    date(){
      this.getScheduleCurrentWeek()
    },
    audience() {
      this.getScheduleCurrentWeek()
    },
  },
  methods:{
    getScheduleCurrentWeek: async function () {
      let link = ""
      let linkController = "/schedule-board"
      // console.log(this.user)
      if (this.user.id != undefined && this.user.id != null){
        linkController = "/schedule-board-user"
      }
      if (this.scheduleBoardFor === "AUDIENCE" && this.subject != null ) {
        link = Settings.API.URL_DEFAULT_SCHEDULE_BOARD + linkController + "/by-audience?audienceId=" + this.subject.id + "&scheduleWeek=" + this.scheduleWeek + "&date=" + this.date
      } else if (this.scheduleBoardFor === "GROUP" && this.subject != null ){
        link = Settings.API.URL_DEFAULT_SCHEDULE_BOARD + linkController + "/by-group?groupId=" + this.subject.id + "&scheduleWeek=" + this.scheduleWeek + "&date=" + this.date
      } else if (this.scheduleBoardFor === "TEACHER" && this.subject != null){
        link = Settings.API.URL_DEFAULT_SCHEDULE_BOARD + linkController + "/by-teacher?teacherId=" + this.subject.id + "&scheduleWeek=" + this.scheduleWeek + "&date=" + this.date
      } else if (this.scheduleBoardFor === "STUDENT" && this.subject != null){
        link = Settings.API.URL_DEFAULT_SCHEDULE_BOARD + linkController +  "/by-student?studentId=" + this.subject.id + "&scheduleWeek=" + this.scheduleWeek + "&date=" + this.date
      }else if (this.scheduleBoardFor === "DISCIPLINE" && this.subject != null){
        link = Settings.API.URL_DEFAULT_SCHEDULE_BOARD + linkController +  "/by-discipline?disciplineId=" + this.subject.id + "&scheduleWeek=" + this.scheduleWeek + "&date=" + this.date
      }
      if ( link != ""){
        this.loading = true
        HTTP.get(link)
            .then((response) => {
              this.scheduleGrid = response.data
              console.log(this.scheduleGrid)
            })
            .catch(() => {
            })
            .finally(() => {
              this.loading = false
            });
      }

    },
  },
  created() {
    if (this.subject != null &&  this.subject.id != null){
      this.getScheduleCurrentWeek()
    }
  }
}
</script>

<style scoped>

</style>