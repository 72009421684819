<template>
  <v-container>
    <v-expansion-panels multiple>
<!--    <v-flex >-->
      <v-expansion-panel v-for="report in reportRaw" :key="report.type">
        <v-expansion-panel-header>
          <v-row>
            <v-col>
              <h3>{{ translateTestingFormType(report.type) }}</h3>
            </v-col>
            <v-col class="text-right">
              <b>Итоговая оценка: {{ getTotalPoints(report.questionToPointsList) }}</b>
            </v-col>
          </v-row>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
      <v-flex xs12 >
        <v-divider/>
        <div v-show="report === null">
          <h3>Выберите преподавателя</h3>
        </div>
        <v-card-title class="d-flex justify-center text-center">
          <h2>{{ translateTestingFormType(report.type) }}</h2>
        </v-card-title>
      </v-flex>
      <v-simple-table class="elevation-3" v-show="overlay === false" v-if="report && report.questionToPointsList.length > 0">
        <template v-slot:default>
          <thead>
          <tr>
            <th class="text-center">
              Вопрос
            </th>
            <th class="text-left">
              Средняя оценка
            </th>
          </tr>
          </thead>
          <tbody>

          <tr class="text-left" v-for="item in report.questionToPointsList" :key="item.question.id">
            <td>{{ item.question.position }}) {{ item.question.text }}</td>
            <td ref="point">{{ item.points.toFixed(2) }}</td>
          </tr>
          <tr class="text-left orange">
            <td class="text-right"><b>Итоговая оценка</b></td>
            <td><b>{{ getTotalPoints(report.questionToPointsList) }}</b></td>
          </tr>
          </tbody>
        </template>
      </v-simple-table>
              <v-container
                  class="d-flex justify-center"
                  v-if="report !== null && report.problemList !== null && report.questionToPointsList.length > 0"
              >
                <v-card class="problemCard">
                  <v-card-title class="text-center">
                    Проблемы с преподавателем
                  </v-card-title>
                  <v-card-text v-if="report.problemList.length === 0">
                    Проблем нет!
                  </v-card-text>
                  <div v-if="report.problemList.length > 0">
                        <v-card v-for="item in report.problemList" :key="item">
                          <v-card-text>
                            <p><b>{{ item }}</b></p>
                            <v-divider/>
                          </v-card-text>
                        </v-card>
                  </div>
                </v-card>
              </v-container>
      <div class="d-flex justify-center" v-if="report && report.questionToPointsList.length === 0">
        <v-alert type="error">
          Нет информации!
        </v-alert>
      </div>
        </v-expansion-panel-content>
      </v-expansion-panel>
<!--    </v-flex>-->
    </v-expansion-panels>
    <v-simple-table v-if="reportRaw.length > 1">
      <tr class="text-right orange">
        <td></td>
        <td><b>Средняя итоговая оценка {{ getTotalTotalPoints().toFixed(2) }}</b></td>
      </tr>
    </v-simple-table>
  </v-container>
</template>

<script>
export default {
  name: "TeacherSatisfactionReport",

  data() {
    return {
      overlay: false,
      totalPoints: 0.0,

      reportForAll: false,
    }
  },

  methods: {
    translateTestingFormType(item) {
      if(item === 'TEACHER_SATISFACTION_SPO')
        return "Статистика по СПО"
      else if(item === 'TEACHER_SATISFACTION_BACCALAUREATE')
        return "Статистика по бакалавриату"
      else if(item === 'TEACHER_SATISFACTION_MAGISTRACY')
        return "Статистика по магистратуре"
      else
        return "Неизвестная статистика"
    },
    getTotalPoints(report) {
      let total = 0.0
      report.forEach(item => {
        total += +item.points.toFixed(2)
      })

      return total.toFixed(2)
    },
    getTotalTotalPoints() {
      let total = 0.0;
      let amountOfItems = 0;
      this.reportRaw.forEach(report => {
        let itemTotalPoints = this.getTotalPoints(report.questionToPointsList)
        if(itemTotalPoints && itemTotalPoints > 0.0) {
          total += +itemTotalPoints
          amountOfItems = +amountOfItems + 1
        }
      })

      return +(+total/+amountOfItems).toFixed(2)
    },
  },

  props: {
    reportRaw: Array
  }
}
</script>

<style scoped>
.problemCard{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  line-break: normal;
}
</style>