<template>
  <div>
      <v-data-table
          :headers="headers"
          :items="typeCertificates"
          :items-per-page="10"
          class="elevation-4"
      >
        <template v-slot:item.edit="{ item }">
          <v-btn icon
            @click="editTypeCertificate(item)"
          >
            <v-icon>settings</v-icon>
          </v-btn>
        </template>

      </v-data-table>
  </div>
</template>

<script>
export default {
  name: "TypeCertificateList",
  props: {
    typeCertificates: {
      type: Array,
      required: true,
    }
  },
  data() {
    return {
      headers: [
        {text: 'N', align: 'center', sortable: false, value: 'id',},
        {text: 'Название', value: 'title'},
        {text: 'Описание', value: 'description'},
        {text: 'Срок исполнения', value: 'readinessInDays'},
        {text: 'Файл', value: 'isFile'},
        {text: 'Описание к файлам', value: 'fileDescription'},
        {text: 'Группа', value: 'groupCertificate.title'},
        {text: 'Редактировать', value: 'edit'},
      ],

    }
  },
  methods:{
    editTypeCertificate(item){
      this.$emit("editTypeCertificate", item)
    },
  },
}
</script>

<style scoped>

</style>