import thisSetting from "@/settings/settings_url";
export default {

    ///api/v1/swagger-ui/ test

    API: {
        // URL_DEFAULT: 'http://localhost:8092/api/v1',
        // URL_DEFAULT_SCHEDULE_BOARD: 'http://localhost:8092/api/v1',
        // URL_DEFAULT_V: 'http://localhost:8092/api/v1',
        // URL_DEFAULT_SV: 'http://localhost:8092/api/v1',
        // URL_DEFAULT_V: 'http://localhost:8081/api/v1/v',
        // URL_DEFAULT_SV: 'http://lms3.sseu.ru/api/v1/sv',
        // URL_DEFAULT: 'http://10.10.80.94:8088/api/v1',
        // URL_DEFAULT: 'http://10.10.80.94:8099/api/v1',
        // URL_DEFAULT:'http://10.10.30.82:8080/api/v1',
        // URL_DEFAULT: 'http://10.10.80.94:8099/api/v1',
        // URL_DEFAULT: 'https://lms3.sseu.ru/api/v1',
        URL_DEFAULT: '/api/v1',
        URL_DEFAULT_SCHEDULE_BOARD: '/api/v1',
        URL_DEFAULT_V: '/api/v1/v',
        URL_DEFAULT_SV: '/api/v1/sv',

        // URL_DEFAULT_V: 'http://10.10.80.97:8300/api/v1',
        // URL_DEFAULT_SV: 'http://10.10.80.97:8400/api/v1',
        URL_DISCIPLINES: "/disciplines/",
        URL_DISCIPLINES_PLAN: "/workload/",
        URL_DISCIPLINES_BY_ID: (disciplineId) => thisSetting.API.URL_DEFAULT + thisSetting.API.URL_DISCIPLINES + disciplineId,
        URL_SCHEDULE: "/schedule/",
        URL_SCHEDULED_LESSONS: "/scheduled-lessons/",
        URL_EMPLOYEE_ONE_C: "/employees-one-c/",
        URL_TEACHERS: "/teachers/",
        URL_GROUPS_BRSO: "/groups/",
        URL_LESSONS: "/lessons/",

        URL_GROUPS_BRSO_CURRICULUM_BY_GROUPID_HALFYEAR: (groupId, year, halfYear) =>
            thisSetting.API.URL_DEFAULT + thisSetting.API.URL_GROUPS_BRSO + groupId + "/workload?year=" + year + "&halfyear=" + halfYear,

        URL_GROUPS_BY_ROLE_USER_BRSO: (yearId, halfYearId,) =>
            thisSetting.API.URL_DEFAULT + thisSetting.API.URL_GROUPS_BRSO + "by-role-user" + "?yearId=" + yearId + "&halfYearId=" + halfYearId,

        URL_DISCIPLINES_BY_ROLE_USER_BRSO: (groupId, yearId, halfYearId,) =>
            thisSetting.API.URL_DEFAULT + thisSetting.API.URL_DISCIPLINES
            + "by-role-user" + "?yearId=" + yearId + "&halfYearId=" + halfYearId + "&groupid=" + groupId,

        URL_CONTROL_POINTS: "/control-points/",
        URL_ASSESSMENT_CONTROL_POINTS: "/assessment-control-points/",
        URL_USERS: "/users/",
        URL_LESSONS_BY_ID: (id) =>
            thisSetting.API.URL_DEFAULT +
            thisSetting.API.URL_LESSONS + id,
        URL_ASSESSMENT_CONTROL_POINTS_BY_ID: (id) =>
            thisSetting.API.URL_DEFAULT +
            thisSetting.API.URL_ASSESSMENT_CONTROL_POINTS + id,
        URL_USER_ROLE: () => thisSetting.API.URL_DEFAULT + thisSetting.API.URL_USERS + "role",
        URL_SCHEDULE_LIST: () => thisSetting.API.URL_DEFAULT + thisSetting.API.URL_EMPLOYEE_ONE_C + "schedule-list",
        URL_CONTROL_POINTS_LIST: () => thisSetting.API.URL_DEFAULT + thisSetting.API.URL_EMPLOYEE_ONE_C + "control-points-list",
        URL_JOURNAL_LIST: (year, halfYear) => thisSetting.API.URL_DEFAULT + thisSetting.API.URL_EMPLOYEE_ONE_C + "journal-list?yearId=" + year + "&halfYearId=" + halfYear,
        URL_EXM_LIST: () => thisSetting.API.URL_DEFAULT + thisSetting.API.URL_EMPLOYEE_ONE_C + "exam-list",
        URL_GRADE_BOOK_LIST: () => thisSetting.API.URL_DEFAULT + thisSetting.API.URL_EMPLOYEE_ONE_C + "gradebook-list",
        URL_GROUP_BRSO_BY_ID: (groupId) => thisSetting.API.URL_DEFAULT + thisSetting.API.URL_GROUPS_BRSO + groupId,
        URL_GROUP_BRSO_BY_SCHEDULE_ID: (groupId) => thisSetting.API.URL_DEFAULT + thisSetting.API.URL_SCHEDULE + groupId + "/groups",
        URL_CONTROL_PINTS_BY_GROUP_CODE_AND_DISCIPLINE_CODE: (groupCode, disciplineCode) => thisSetting.API.URL_DEFAULT + thisSetting.API.URL_GROUPS_BRSO + groupCode + "/control-points?disciplinecode=" + disciplineCode,
        URL_GROUPS_ONE_C: "/groups-one-c/",
        URL_STUDENTS_BRSO: "/students/",
        URL_STUDENTS_STATISTICS: (id, yearId, halfYear) => thisSetting.API.URL_DEFAULT_SV + thisSetting.API.URL_STUDENTS_BRSO + "statistics?studentId=" + id + "&yearId=" + yearId + "&halfYearId=" + halfYear,
        URL_REGISTRATION_OF_STUDENT_VISIT: "/registration-of-student/",

        URL_REGISTRATION_OF_STUDENT_POINTS_FOR_VISITS: (student, group, discipline, yearId, halfYearId) =>
            thisSetting.API.URL_DEFAULT +
            thisSetting.API.URL_REGISTRATION_OF_STUDENT_VISIT +
            "points-for-visits?group=" +
            group + "&discipline=" + discipline + "&student=" + student + "&yearId=" + yearId + "&halfYearId=" + halfYearId,

        URL_REGISTRATION_OF_STUDENT_VISIT_JOURNAL: (group, discipline, yearId, halfYearId, followed) =>
            thisSetting.API.URL_DEFAULT +
            thisSetting.API.URL_REGISTRATION_OF_STUDENT_VISIT +
            "journal?group=" +
            group + "&discipline=" + discipline + "&yearId=" + yearId + "&halfYearId=" + halfYearId + "&followed=" + followed,

        // URL_REGISTRATION_OF_STUDENT_VISIT_GRADE_BOOK :  (scheduleid, disciplineid, datestart, dateend) =>
        //     thisSetting.API.URL_DEFAULT +
        //     thisSetting.API.URL_REGISTRATION_OF_STUDENT_VISIT +
        //     "grade-book?scheduleid=" +
        //     scheduleid + "&disciplineid=" + disciplineid + "&datestart=" + datestart + "&dateend=" + dateend,


        URL_REGISTRATION_OF_STUDENT_VISIT_GRADE_BOOK: (groupid, disciplineid, datestart, dateend, followed) =>
            thisSetting.API.URL_DEFAULT +
            thisSetting.API.URL_REGISTRATION_OF_STUDENT_VISIT +
            "grade-book?groupid=" +
            groupid + "&disciplineid=" + disciplineid + "&datestart=" + datestart + "&dateend=" + dateend + "&followed=" + followed,


        URL_CONTROL_POINTS_JOURNAL: (groupid, disciplineid, yearId, halfYearId, followed) =>
            thisSetting.API.URL_DEFAULT +
            thisSetting.API.URL_CONTROL_POINTS +
            "by-group-and-discipline?groupid=" +
            groupid + "&disciplineid=" + disciplineid + "&yearId=" + yearId + "&halfYearId=" + halfYearId + "&followed=" + followed,


        URL_REGISTRATION_OF_STUDENT_VISIT_EXAM_BOOK: (groupid, disciplineid, yearId, halfYearId) =>
            thisSetting.API.URL_DEFAULT +
            thisSetting.API.URL_REGISTRATION_OF_STUDENT_VISIT +
            "exam-book?groupid=" +
            groupid + "&disciplineid=" + disciplineid + "&yearId=" + yearId + "&halfYearId=" + halfYearId,
        URL_REGISTRATION_OF_STUDENT_VISIT_UPDATE_EVALUATION_EXAM: (id) =>
            thisSetting.API.URL_DEFAULT_V +
            thisSetting.API.URL_REGISTRATION_OF_STUDENT_VISIT + id + "/points-exam",


        URL_REGISTRATION_OF_STUDENT_VISIT_UPDATE_EVALUATION: (id) =>
            thisSetting.API.URL_DEFAULT +
            thisSetting.API.URL_REGISTRATION_OF_STUDENT_VISIT + id + "/points",

        URL_REGISTRATION_OF_STUDENT_VISIT_MAX_EVALUATION: () =>
            thisSetting.API.URL_DEFAULT +
            thisSetting.API.URL_REGISTRATION_OF_STUDENT_VISIT + "max-evaluation",

        URL_REGISTRATION_OF_STUDENT_VISIT_UPDATE_VISITS: (id) =>
            thisSetting.API.URL_DEFAULT +
            thisSetting.API.URL_REGISTRATION_OF_STUDENT_VISIT + id + "/invert",


        URL_SCHEDULE_TABLE: () => thisSetting.API.URL_DEFAULT + thisSetting.API.URL_SCHEDULE + "schedule-table/",
        URL_SCHEDULE_TABLE_BY_DATE: () => thisSetting.API.URL_DEFAULT + thisSetting.API.URL_SCHEDULE + "schedule-table-by-date/",
        URL_CURRICULUM_ONE_C: (groupId, half_year) => thisSetting.API.URL_GROUPS_ONE_C + groupId + "/curriculum?halfyear=" + half_year,
        URL_PRESENCE_MARK: () => thisSetting.API.URL_DEFAULT + "/presence-mark/",
        URL_STUDENTS_BY_LESSON_ID: (id) => thisSetting.API.URL_DEFAULT + thisSetting.API.URL_LESSONS + id + thisSetting.API.URL_STUDENTS_BRSO,
        URL_STUDENTS_VISITS_BY_SCHEDULED_LESSON_ID: (id) => thisSetting.API.URL_DEFAULT + thisSetting.API.URL_SCHEDULED_LESSONS + id + "/student-visit-by-current-date",
        URL_STUDENTS_BY_GROUP_ID: (id) => thisSetting.API.URL_DEFAULT + thisSetting.API.URL_GROUPS_BRSO + id + thisSetting.API.URL_STUDENTS_BRSO,
        URL_LESSONS_BY_SATE_AND_TIME_AND_NUM_DAY: () => thisSetting.API.URL_DEFAULT + thisSetting.API.URL_LESSONS + "details-by-date-and-time-and-num-day/",
        URL_LESSONS_BY_SCHEDULE_ID: (id) => thisSetting.API.URL_DEFAULT + thisSetting.API.URL_SCHEDULE + id + thisSetting.API.URL_LESSONS,
    },
    URL_DEFAULT: 'http://127.0.0.1:8081/',
    URL_SCHEDULE_TYPE: "/schedule-types/",
    URL_GROUPS_BRSO: "/groups/",
    URL_SCHEDULE: "/schedule/",
    URL_AUDIENCES: "/audiences/",
    URL_AUDIENCES_TYPE: "/audience-types/",
    URL_ACCESS_POINT_ORION: "/access-point-orion/",
    URL_ACCESS_POINT_BRSO: "/access-point/",
    URL_LESSON_TYPES: "/lesson-types/",
    URL_LESSON_TYPE_DAYS: "/lesson-type-days/",
    URL_LESSON_NUMBERS: "/lesson-numbers/",
    URL_LESSONS: "/scheduled-lessons/"
}


//
// <script type="text/javascript" src="//vk.com/js/api/openapi.js?150"></script>
//
// <script type="text/javascript">
//     VK.init({apiId: 6071663, onlyWidgets: true});
// </script>
// <script src="https://webasr.yandex.net/jsapi/v1/webspeechkit.js" type="text/javascript"></script>
// <script src="https://webasr.yandex.net/jsapi/v1/webspeechkit-settings.js" type="text/javascript"></script>
// <script>
//     window.onload = function () {
//     var tts = new ya.speechkit.Tts({apikey: '5ae77165-b991-453b-8545-ecd633bc2cb2',emotion: 'good',speed: 1.0,speaker: 'ermil'});
// }
// </script>
