<template>
  <v-row justify="center">
    <v-dialog
        v-model="show"
        persistent
        max-width="600px"
    >
      <v-card>
        <v-card-title>
          <span class="headline"></span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col
                  cols="12"
                  sm="12"
                  md="12"
              >
                <v-text-field
                    label="Название"
                    :rules="rules"
                    hide-details="auto"
                    v-model="groupCertificate.title"
                ></v-text-field>

              </v-col>
              <v-col
                  cols="12"
                  sm="12"
                  md="12"
              >
                <v-textarea
                    v-model="groupCertificate.description"
                >
                  <template v-slot:label>
                    <div>
                      Описание
                    </div>
                  </template>
                </v-textarea>

              </v-col>
            </v-row>

          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-btn
              color="red darken-1"
              text
          >
            Удалить
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
              color="blue darken-1"
              text
              @click="close"
          >
            Закрыть
          </v-btn>
          <v-btn
              color="blue darken-1"
              text
              @click="saveGroupCertificate"
          >
            Сохранить
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import Settings from "@/settings/settings_url";
import {HTTP} from '@/settings/axios'

export default {
  name: "GroupCertificateCard",
  props:{
    show: {
      type: Boolean,
      required: true,
      default: false,
    },
    groupCertificate: {
      type: Object,
      required: true
    },
  },
  data(){
    return{
      rules: [
        value => !!value || 'Required.',
        value => (value && value.length >= 3) || 'Min 3 characters',
      ],
    }
  },
  methods:{
    close(){
      this.$emit("close")
    },
    addGroupCertificateSuccess(){
      this.$emit("addGroupCertificateSuccess")
    },
    saveGroupCertificate: async function () {
      HTTP.post(Settings.API.URL_DEFAULT + "/group-certificate", this.groupCertificate)
          .then(() => {
            this.addGroupCertificateSuccess()
          })
          .catch(() => {
          })
          .finally(() => {

          });
    },
  },
}
</script>

<style scoped>

</style>