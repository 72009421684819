<template>
  <v-row justify="center">
    <v-dialog
        v-model="show"
        persistent
        max-width="600px"
    >
      <v-card>
        <v-card-title>
          <span class="headline"></span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col
                  cols="12"
                  sm="12"
                  md="12"
              >
                <v-select v-model="typeCertificate.typesEducation"
                          :items="eduList"
                          label="Тип образования"
                          prepend-icon="school"
                          clearable
                          dense
                >
                </v-select>
              </v-col>
              <v-col
                  cols="12"
                  sm="12"
                  md="12"
              >
                <v-autocomplete v-model="typeCertificate.groupCertificate"
                                :items="groupCertificates"
                                label="Группа"
                                dense
                                item-value="id"
                                item-text="title"
                                return-object

                >
                </v-autocomplete>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                  cols="12"
                  sm="12"
                  md="12"
              >
                <v-text-field
                    label="Название"
                    :rules="rules"
                    hide-details="auto"
                    v-model="typeCertificate.title"
                ></v-text-field>

              </v-col>
              <v-col
                  cols="12"
                  sm="12"
                  md="12"
              >
                <v-textarea
                    v-model="typeCertificate.description"
                >
                  <template v-slot:label>
                    <div>
                      Описание
                    </div>
                  </template>
                </v-textarea>

              </v-col>

              <v-col
                  cols="12"
                  sm="12"
                  md="12"
              >
                <v-text-field
                    label="Срок исполнения в днях"
                    hide-details="auto"
                    v-model="typeCertificate.readinessInDays"
                ></v-text-field>

              </v-col>
              <v-col
                  cols="12"
                  sm="12"
                  md="12"
              >
                <v-checkbox
                    v-model="typeCertificate.isFile"
                    label="файлы"
                ></v-checkbox>

              </v-col>
            </v-row>

            <v-col v-if="typeCertificate.isFile"
                   cols="12"
                   sm="12"
                   md="12"
            >
              <v-textarea
                  v-model="typeCertificate.fileDescription"
              >
                <template v-slot:label>
                  <div>
                    Описание к файлам
                  </div>
                </template>
              </v-textarea>

            </v-col>

          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-btn
              color="red darken-1"
              text
          >Удалить
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
              color="blue darken-1"
              text
              @click="close"
          >Закрыть
          </v-btn>
          <v-btn
              color="blue darken-1"
              text
              @click="saveTypeCertificate"
          >Сохранить
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import Settings from "@/settings/settings_url";
import {HTTP} from '@/settings/axios'

export default {
  name: "TypeCertificateCard",
  props: {
    show: {
      type: Boolean,
      required: true,
      default: false,
    },
    typeCertificate: {
      type: Object,
      required: true
    },
    groupCertificates:{
      type: Array,
      required: true
    },
  },
  data() {
    return {
      rules: [
        value => !!value || 'Required.',
        value => (value && value.length >= 3) || 'Min 3 characters',
      ],
      edu: "BACCALAUREATE",
      eduList: [
        {text: "Бакалавриат/Специалитет", value: "BACCALAUREATE"},
        {text: "Аспирантура", value: "ASPIRANT"},
        {text: "СПО", value: "SPO"},
      ],
    }
  },
  methods: {
    close() {
      this.$emit("close")
    },
    addTypeCertificateSuccess() {
      this.$emit("addTypeCertificateSuccess")
    },
    saveTypeCertificate: async function () {
      HTTP.post(Settings.API.URL_DEFAULT + "/type-certificate", this.typeCertificate)
          .then(() => {
            this.addTypeCertificateSuccess()
          })
          .catch(() => {
          })
          .finally(() => {

          });
    },
  },
}
</script>

<style scoped>

</style>