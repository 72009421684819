<template>
  <div>

    <div v-if="loading" class="progress-barr">
      <div class="progress">
        <v-progress-circular
            :size="100"
            :width="10"
            color="blue"
            indeterminate
        ></v-progress-circular>
      </div>
    </div>
    <v-app id="schedule">
      <v-container justify-center>
        <v-flex xs12>
          <v-card>
            <v-card-text>
              <v-layout row wrap>
                <v-flex xs12 md12>
                  <v-card-actions class="justify-center">
                    <h1>Научная деятельность</h1>
                  </v-card-actions>

                </v-flex>
                <v-flex xs12 md6>

                </v-flex>
                <v-flex xs12 md6>

                </v-flex>
              </v-layout>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>

            </v-card-actions>
          </v-card>
        </v-flex>
      </v-container>
      <v-container justify-center>
        <v-flex xs12>
          <v-data-table
              :headers="headers"
              :items="assessmentScientificActivities"
              :items-per-page="80"
              :hide-default-footer="true"
              class="elevation-2"
              fixed-header
              :height="assessmentScientificActivities.length > 1 ? '85vh' : ''"
          >



            <template v-slot:item.evaluation="item"

            >
              <div
                  @click="showDialog(item.item)"
              >
                {{ item.item.evaluation }}
              </div>
            </template>
          </v-data-table>
        </v-flex>
      </v-container>

      <template>
        <v-row justify="center">
          <v-dialog
              v-model="dialog"
              width="600px"
              persistent
          >
            <v-card>
              <v-card-title>
                <span class="headline">Редактировать</span>
              </v-card-title>

              <v-card-text>
                <v-card-text>
                  <v-text-field
                      v-model="assessmentScientificActivitySelect.evaluation"
                      hint="Введите заработанные баллы"
                      :rules="rules"
                      :label="'Максимальный балл -' + maxEvaluation"
                  ></v-text-field>
                </v-card-text>
                <!--                              <p>Оценка - {{ visitItem.evaluation != null ? visitItem.evaluation.toFixed(1): "" }} </p>-->
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    color="green darken-1"
                    text
                    @click="updateTable()"
                >
                  cancel
                </v-btn>
                <v-btn
                    color="red darken-1"
                    text
                    @click="updateEvaluation"
                >
                  Ок
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-row>
      </template>

    </v-app>

  </div>
</template>

<script>

import Settings from "@/settings/settings_url";
import {HTTP} from '@/settings/axios'

export default {
  name: "ScientificActivity",
  props: {
    groupId: Number,
    disciplineId: Number,
    yearId: Number,
    halfYearId: Number
  },
  data() {
    return {
      assessmentScientificActivitySelect: {
        evaluation: 0,
        controlPoints: {
          scientificActivityId: Number,
          student: {
          },
        }
      },
      loading: false,
      headers: [
        {text: 'Студент', align: 'left', sortable: true, value: 'student.student', divider: true, class: "bolt"},
        {text: 'Балл', align: 'center', value: 'evaluation', divider: true, sortable: true, class: "bolt"},
      ],
      assessmentScientificActivities:[],
      scientificActivity: {
        name:"",
        discipline:{
          name:""
        },
        group:{
          name:""
        },
      },
      dialog: false,
      dialogNewControlPoints: false,
      maxEvaluation: 10,
      rules: [v => v <= this.maxEvaluation || 'Max ' + this.maxEvaluation + ' Баллов'],
      controlPointsLms:[],
    }
  },
  watch: {
    // all: function () {
    //   this.getJournal()
    // },
    yearId(){
      this.updateTable()
    },
    halfYearId(){
      this.updateTable()
    },
    groupId: function () {
      this.updateTable()

    },
    disciplineId: function () {
      this.updateTable()
    },
  },
  methods: {

    updateTable: function () {
      this.dialog = false
      if (this.groupId != undefined && this.groupId != 0 && this.yearId != undefined && this.yearId != 0
          && this.halfYearId != undefined && this.halfYearId != 0
          && this.disciplineId != undefined && this.disciplineId != 0) {
        this.getScientificActivity()
      }
    },
    getScientificActivity: async function () {
      let groupId = this.groupId
      let disciplineId = this.disciplineId

      if (groupId == 0 || disciplineId == 0) {
        groupId = this.$route.query.group
        disciplineId = this.$route.query.discipline
      }

      if (groupId == 0 || disciplineId == 0 || groupId == undefined || disciplineId == undefined) {
        return
      }
      this.loading = true
      this.assessmentScientificActivities = []
      await HTTP.get(Settings.API.URL_DEFAULT + "/scientific-activity/by-group-and-discipline-current-year?groupId=" + groupId + "&disciplineId=" + disciplineId + "&yearId=" + this.yearId + "&halfYearId=" + this.halfYearId)
          .then((response) => {
            this.scientificActivity = response.data
            this.getAssessmentScientificActivity()
          })
          .catch(() => {
            this.errored = true;
          })
          .finally(() => {
            this.loading = false
          });
    },

    getAssessmentScientificActivity: async function () {
      this.loading = true
      this.assessmentScientificActivities = []

      await HTTP.get(Settings.API.URL_DEFAULT + "/scientific-activity/" + this.scientificActivity.id + "/assessment-for-group")
          .then((response) => {
            this.assessmentScientificActivities = response.data
            console.log(this.assessmentScientificActivities)
          })
          .catch(() => {
            this.errored = true;
          })
          .finally(() => {
            this.loading = false
          });
    },

    // content: function (item) {
    //   if (item instanceof Object) {
    //     return item.evaluation != null && item.evaluation != 0 ? item.evaluation : ""
    //   }
    //   return item
    // },

    showDialog: function (item) {
      console.log(item)
      if (item.id !== 0 && item.id !== undefined && item.id !== null && (this.scientificActivity.role === "ROLE_TEACHER" || this.scientificActivity.role === "ROLE_ADMIN")) {
        // this.getMaxEvaluation(item)
        this.dialog = true
        this.assessmentScientificActivitySelect = item

      }
    },
    // getMaxEvaluation: function (item) {
    //   this.maxEvaluation = item.controlPoints.numberOfPoints
    // },


    updateEvaluation: function () {
      this.loading = true
      if (this.assessmentScientificActivitySelect.evaluation > this.maxEvaluation){
        return
      }
      HTTP.post(Settings.API.URL_DEFAULT + "/assessment-scientific-activity", this.assessmentScientificActivitySelect)
          .then(() => {
            // console.log(response.data)
            this.updateTable()
            //this.evaluationItem.evaluation = response.data.evaluation
          }).catch(() => {
        this.errored = true;
        //this.getJournal()
      }).finally(() => {
        this.loading = false
        this.dialog = false
      });
    }

  },

  created() {
    this.getScientificActivity()
  },
  components: {}
}
</script>

<style lang="sass">


</style>
