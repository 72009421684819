<template>
  <v-card
      class="statsCard"
      elevation="2"
      outlined
  >
    <div>
      <v-card-title>
<!--        <strong>ID: {{lesson.id}}</strong> <br/>-->
        <strong>Группа {{ lesson.group }} <br/>
          <span v-if="lesson.audienceName !== null && lesson.audienceName.length > 1">Аудитории</span>
          <span v-if="lesson.audienceName !== null && lesson.audienceName.length == 1">Аудитория</span>
          <span v-if="lesson.audienceName === null || lesson.audienceName.length < 1">Аудитория не указана</span>
          <span v-for="(item) in lesson.audienceName" :key="item">
            {{ item }}
          </span>
        </strong>
      </v-card-title>
      <v-card-subtitle>
        Дисциплина "{{ lesson.discipline }}"
      </v-card-subtitle>
    </div>
    <div><v-card-text>
      <v-simple-table>
        <template v-slot:default>
          <tbody>
            <tr>
              <td>
                Студентов
              </td>
              <td>
                <v-chip :color="getPresentStudentsColor(lesson.studentsVisited, lesson.studentsExpected)" white label>
                  {{ lesson.studentsVisited }} / {{ lesson.studentsExpected }}
                </v-chip>
              </td>
            </tr>
            <tr v-for="(fooKey, barValue) in lesson.replacementTeachersAndVisits" :key="barValue">
              <td>
                Преподаватель
              </td>
              <td>
                <v-chip :color="getPresentTeacherColor(fooKey)" white label>{{ barValue }}</v-chip>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card-text>
    </div>
  </v-card>
</template>

<script>
export default {
  name: "LessonStatistics",
  data() {
    return {
      universityBuilding: "",
    }
  },
  props: {
    lesson: Object,
  },
  methods: {
    getPresentStudentsColor(presentStudents, students) {
      if (presentStudents < students * 0.6) return "red";
      if (presentStudents >= students * 0.6 && presentStudents < students * 0.85) return "orange";
      if (presentStudents >= students * 0.85) return "green";
      if (presentStudents == undefined) return 'white';
    },
    getPresentTeacherColor(teacherPresent) {
      if(teacherPresent)
        return "green"
      return "red"
    },
  },
  created() {
  },
}
</script>

<style scoped>
.statsCard{
  width: 400px;
  margin-bottom: 20px;
  margin-left: 10px;
  margin-right: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  line-break: normal;
}
tr:hover {
  background-color: transparent !important;
}

</style>