<template>
  <div>
    <v-text-field
        label="Название аудитории"
        v-model="nameclass"
        :rules="rules"
        hide-details="auto"
    ></v-text-field>
    <v-select
        v-model="select"
        :items="items"
        item-text="name"
        item-value="id"
        label="Выбрать"

    >
      <template v-slot:item="{ item, attrs, on }">
        <v-list-item
            v-bind="attrs"
            v-on="on"
        >
          <v-list-item-title
              :id="attrs['aria-labelledby']"
              v-text="item"
          ></v-list-item-title>
        </v-list-item>
      </template>
    </v-select>

    <v-text-field
        label="Вместимость"

        hide-details="auto"
    ></v-text-field><br>
    <v-btn
        color="red lighten-2"
        dark
        v-on:click="AddClassE(nameclass, select.id)"
    >
      Добавить
    </v-btn>
  </div>
</template>

<script>

import Settings from "@/settings/settings_url";
import {HTTP} from '@/settings/axios'

export default {
  data() {
    return {
      select: {name: 'Лекционная', id: '1'},
      items: [
        {name: 'Лекционная', id: '1'},
        {name: 'Поточная', id: '2'},
      ],
      nameclass: [],
      info: null,
      attrs: null,
      on: null,
      rules: [
        value => !!value || '*Обязательное поле',
        value => (value && value.length >= 3) || 'Минимальное количество символов - 3',
      ],
    };
  },
      methods: {
        AddClassE: async function (name, type) {
          await HTTP.post(Settings.API.URL_DEFAULT + '/audiences',{
            'name': name,
            'type': type,
          })
        .then((response) => {
            this.info = response.data;
          })
        }
      }
    }
</script>

