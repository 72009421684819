<template>
  <v-row justify="center">
    <v-dialog
        v-model="show"
        persistent
        max-width="600px"
    >
      <v-card>
        <v-card-title>
          <span class="headline">{{ title }}</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col
                  cols="12"
                  sm="12"
                  md="12"
              >
                <v-data-table
                    :headers="headers"
                    :items="lessons"
                    :items-per-page="15"
                    class="elevation-2"
                >
                </v-data-table>
                <div>
                  <v-checkbox
                      v-model="ignoreDouble"
                      :label="textIgnore"
                  ></v-checkbox>
                </div>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="blue darken-1"
              text
              @click="close"
          >
            Close
          </v-btn>
          <v-btn
              color="blue darken-1"
              text
              @click="save"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  name: "DuplicateLessonProperties",
  props:{
    title: String, // Преподаватель в это время уже добавлен в расписание
    show: Boolean,
    lessons: Array,
    textIgnore: String,
    isCheckAudience: Boolean,
    isCheckTeacher: Boolean,
  },
  computed:{
    ignoreDouble: {
      get() {
        return this.$store.getters.ignoreDoubleTeacherAndAudienceForSchedule;
      },
      set(value) {
        this.$store.commit('updateIgnoreDoubleTeacherAndAudienceForSchedule', value)
      }
    },
  },
  data(){
    return{
      headers:[
        {text: 'Группа', align: 'center', sortable: false, value: 'workload.workPlan.group.name', divider: true, class: "bolt"},
        {text: 'Дата', align: 'center', value: 'date', divider: true, sortable: false, class: "bolt"},
      ],
    }
  },
  methods:{
    close(){
      this.$emit("dialogClose")
    },
    save(){
      this.$emit("save", {
        isCheckAudience: this.isCheckAudience,
        isCheckTeacher: this.isCheckTeacher,
      })
    }
  },
  created() {
    // console.log(this.lessons)
  }
}
</script>

<style scoped>

</style>