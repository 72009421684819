<template>
  <v-container>
    <div class="overlay-alert">
      <v-alert
          dismissible
          type="success"
          v-model="alertSuccess"
          transition="scale-transition">
        success! - {{ textSuccessAlert }}
      </v-alert>
      <v-alert
          dismissible
          type="error"
          v-model="alertError"
          transition="scale-transition">
        Error! - {{ textErrorAlert }}
      </v-alert>
    </div>
    <v-data-table
        :headers="headers"
        :items="controlPointCategories"
        :items-per-page="15"
        class="elevation-1"
    >
      <template v-slot:item.lmsId="{ item }">
        <v-autocomplete v-model="item.lmsId"
                        :items="courses"
                        label=""
                        dense
                        hide-details
                        hide-selected
                        flat
                        solo
                        hide-no-data
                        item-value="id"
                        item-text="fullname"
                        clearable
                        @change="changeCategories(item)"
        >
        </v-autocomplete>
      </template>

      <template v-slot:item.save="{ item }">
        <v-btn icon
               @click="saveControlPointCategories(item)"
        >
          <v-icon color="rgb(1, 81, 136)">save</v-icon>
        </v-btn>
      </template>
    </v-data-table>
  <v-row>


  </v-row>
  </v-container>
</template>

<script>

import Settings from "@/settings/settings_url";
import {HTTP} from '@/settings/axios'

export default {
  components: {},
  name: "ControlPointAdmin",
  data() {
    return {
      alertSuccess: false,
      alertError: false,
      textSuccessAlert: "",
      textErrorAlert: "",
      groupId: 0,
      headers: [
        // {text: 'N', align: 'center', sortable: false, value: 'id',},
        {text: 'Дисциплина БРСО', value: 'discipline.name'},
        {text: 'Курс LMS', value: 'lmsId'},
        {text: 'Сохранить', value: 'save'},
      ],
      controlPointCategories: [],
      courses:[],
    }
  },
  watch: {
  },

  methods: {
    // changeCategories(item){
    //   item.edit = 1
    // },
    // isDisable(item){
    //     return item.edit === undefined || item.edit === null || !(item?.edit === 1);
    // },
    async saveControlPointCategories(item){
        // console.log(item)
      await HTTP.post(Settings.API.URL_DEFAULT + "/control-points-categories", item)
          .then((response) => {
            item.lmsId = response.data.lmsId
            this.showAlertSuccess("");
            // item.edit = null
            // this.$refs.btn.disabled = true
            // console.log(this.$refs.btn)
          })
          .catch(() => { this.showAlertError("")
          }).finally(() => {
            // this.$refs.btn.disabled = true
            // item.edit = null
          })
    },
    getControlPointCategories: async function () {
      await HTTP.get(Settings.API.URL_DEFAULT + "/groups/" + this.groupId + "/control-points-categories")
          .then((response) => {
            this.controlPointCategories = response.data
          })
          .catch(() => {
          }).finally(() => this.getCourses())
    },
    getCourses: async function () {
      await HTTP.get(Settings.API.URL_DEFAULT + "/groups/" + this.groupId + "/course-lms")
          .then((response) => {
            this.courses = response.data
            console.log(this.courses)
          })
          .catch(() => {
          })
    },
    showAlertSuccess(message) {
      this.alertSuccess = true
      this.textSuccessAlert = message
      setTimeout(this.hideAlertSuccess, 2000);
    },
    hideAlertSuccess() {
      this.alertSuccess = false
    },
    showAlertError(message) {
      this.alertError = true
      this.textErrorAlert = message
      setTimeout(this.hideAlertError, 2000);
    },
    hideAlertError() {
      this.alertError = false
    },
  },

  created() {
    this.groupId = this.$route.query.course
  },
  mounted() {
    this.getControlPointCategories()
  }

}
</script>

<style lang="sass">

</style>
