<template>
  <v-container v-show="isAdmin()">
    <v-card-title class="d-flex justify-center text-center"><h2>Администрирование служебных записок</h2></v-card-title>
    <v-container>
      <create-ticket-page ref="createPage" @ticketCreated="updateHistory" admin/>
    </v-container>

    <v-container/>
    <v-divider/>
    <ticket-history
        ref="history"
        history-for="ADMIN"
    />
  </v-container>
</template>

<script>
import CreateTicketPage from "@/components/visitTicket/CreateTicketPage";
import TicketHistory from "@/components/visitTicket/TicketHistory";

export default {
  name: "AdminTicketPage",

  components: {TicketHistory, CreateTicketPage},

  data() {
    return {
    }
  },

  methods: {
    updateHistory() {
      this.$refs.history.getTickets()
    },
    isAdmin() {
      return this.isRole("ROLE_ADMIN")
    },
    isRole(role) {
      if (this.$store.getters.selectedUser?.role != null && this.$store.getters.selectedUser?.role.length > 0){
        for (let i = 0; i < this.$store.getters.selectedUser?.role.length; i++){
          if (
              this.$store.getters.selectedUser?.role[i].name === role
          ){
            return true
          }
        }
      }else {
        return  false
      }
    },
  },
}
</script>

<style scoped>

</style>