<template>
<div>
  <v-app id="profsdl">
    <v-layout justify-center>
      <v-flex xs12>
        <v-card>
          <v-card-text>
            <v-layout row wrap>
              <v-flex xs12 md12>
                <v-card-actions>
                  <v-spacer></v-spacer>
                </v-card-actions>
                <v-card-actions class="justify-center">
                  <h1>Преподаватель : {{profName}}</h1>
                </v-card-actions>
                <v-card-actions>
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-flex>
            </v-layout>
          </v-card-text>
        </v-card>
    <v-data-table
            :headers="days"
            fixed-header
            height="900"
            :hide-default-footer="false"
            :items="times"
            :items-per-page="10"

            class="elevation-20"
        >

      <template v-slot:item.one="{ item }">
        <schedule-item
            :header="item.one.lesson"
            :content="item.one.teacher"
            :room="item.one.room"
            :current_count="item.one.current_count"
            :full_count="item.one.full_count"
            :is_teacher="item.one.is_teacher"
            :is_lesson="item.one.is_lesson"
        ></schedule-item>
      </template>
        </v-data-table>
      </v-flex>
    </v-layout>
  </v-app>
</div>
</template>
<script>


// import ProfsdlItem from "@/components/ProfsdlItem ";
import ReportItem from "@/components/ReportItem";



export default {

name: "ProfessorSchedule",
  data() {
    return {
      profName:'Иванов Иван Иванович',
      days: [
        {text: '(Не)чётная неделя',value: 'ltime',  align: 'center', sortable: false,  divider: true, class: "bolt"},
        {text: ['Понедельник: ', '24.05.21'], value: 'one', divider: true, sortable: false, class: "bolt",  align: 'center'},
        {text: ['weekday: ', 'day'], value: 'two', divider: true, sortable: false, class: "bolt",  align: 'center'},
        {text: ['Среда: ', '26.05.21'], value: 'three', divider: true, sortable: false, class: "bolt",  align: 'center'},
        {text: ['Четверг: ', '27.05.21' ], value: 'four', divider: true, sortable: false, class: "bolt",  align: 'center'},
        {text: ['Пятница: ', '28.05.21'], value: 'five', divider: true, sortable: false, class: "bolt",  align: 'center'},
        {text: ['Суббота: ', '29.05.21'], value: 'six', divider: true, sortable: false, class: "bolt",  align: 'center'},

      ],
      times: [
        {
          ltime:  '08:30 - 10:00',
          // one: {lesson: 'Бизнес информатика', room: "504 E", teacher: "Доцент. Иванов И.И", current_count: 24, full_count: 45, is_teacher: true, is_lesson: true},
          // two: {lesson: 'Юриспруденция',  room: "202", teacher: "Доцент. Иванов И.И", current_count: 42, full_count: 45, is_teacher: false, is_lesson: true},
          // three: {lesson: 'Финансы и кредит',  room: "304 Д", teacher: "Доцент. Иванов И.И", current_count: 39, full_count: 45, is_teacher: true, is_lesson: true},
          // four: {lesson: 'Торговое дело',  room: "425", teacher: "Доцент. Иванов И.И", current_count: 44, full_count: 45, is_teacher: true, is_lesson: true},
          // five: {lesson: '',  room: "504 E", teacher: ""},
          // six: {lesson: 'Торговое дело',  room: "425", teacher: "Доцент. Иванов И.И", current_count: 44, full_count: 45, is_teacher: true, is_lesson: false},
        },

        //
        // {
        //   ltime:  '9.30 – 11.00',
        //   mo: {lesson: 'Бизнес информатика', room: "504 E", teacher: "Доцент. Иванов И.И", current_count: 24, full_count: 45, is_teacher: true, is_lesson: true},
        //   tue: {lesson: 'Юриспруденция',  room: "202", teacher: "Доцент. Иванов И.И", current_count: 42, full_count: 45, is_teacher: false, is_lesson: true},
        //   we: {lesson: 'Финансы и кредит',  room: "304 Д", teacher: "Доцент. Иванов И.И", current_count: 39, full_count: 45, is_teacher: true, is_lesson: true},
        //   th: {lesson: 'Торговое дело',  room: "425", teacher: "Доцент. Иванов И.И", current_count: 44, full_count: 45, is_teacher: true, is_lesson: true},
        //   fr: {lesson: '',  room: "504 E", teacher: ""},
        //   sa: {lesson: 'Торговое дело',  room: "425", teacher: "Доцент. Иванов И.И", current_count: 44, full_count: 45, is_teacher: true, is_lesson: false},
        // },
        // {
        //   ltime: '10:15 - 11.50',
        //   mo: {lesson: 'Бизнес информатика', room: "504 E", teacher: "Доцент. Иванов И.И", current_count: 24, full_count: 45, is_teacher: true, is_lesson: true},
        //   tue: {lesson: 'Юриспруденция',  room: "202", teacher: "Доцент. Иванов И.И", current_count: 42, full_count: 45, is_teacher: false, is_lesson: true},
        //   we: {lesson: 'Финансы и кредит',  room: "304 Д", teacher: "Доцент. Иванов И.И", current_count: 39, full_count: 45, is_teacher: true, is_lesson: true},
        //   th: {lesson: 'Торговое дело',  room: "425", teacher: "Доцент. Иванов И.И", current_count: 44, full_count: 45, is_teacher: true, is_lesson: true},
        //   fr: {lesson: '',  room: "504 E", teacher: ""},
        //   sa: {lesson: 'Торговое дело',  room: "425", teacher: "Доцент. Иванов И.И", current_count: 44, full_count: 45, is_teacher: true, is_lesson: false},
        // },
        // {
        //   ltime: '11.10 – 12.40',
        //   mo: {lesson: 'Бизнес информатика', room: "504 E", teacher: "Доцент. Иванов И.И", current_count: 24, full_count: 45, is_teacher: true, is_lesson: true},
        //   tue: {lesson: 'Юриспруденция',  room: "202", teacher: "Доцент. Иванов И.И", current_count: 42, full_count: 45, is_teacher: false, is_lesson: true},
        //   we: {lesson: 'Финансы и кредит',  room: "304 Д", teacher: "Доцент. Иванов И.И", current_count: 39, full_count: 45, is_teacher: true, is_lesson: true},
        //   th: {lesson: 'Торговое дело',  room: "425", teacher: "Доцент. Иванов И.И", current_count: 44, full_count: 45, is_teacher: true, is_lesson: true},
        //   fr: {lesson: '',  room: "504 E", teacher: ""},
        //   sa: {lesson: 'Торговое дело',  room: "425", teacher: "Доцент. Иванов И.И", current_count: 44, full_count: 45, is_teacher: true, is_lesson: false},
        // },
        // {
        //   ltime: '12.20 - 13.55',align: 'center',
        //   mo: {lesson: 'Бизнес информатика', room: "504 E", teacher: "Доцент. Иванов И.И", current_count: 24, full_count: 45, is_teacher: true, is_lesson: true},
        //   tue: {lesson: 'Юриспруденция',  room: "202", teacher: "Доцент. Иванов И.И", current_count: 42, full_count: 45, is_teacher: false, is_lesson: true},
        //   we: {lesson: 'Финансы и кредит',  room: "304 Д", teacher: "Доцент. Иванов И.И", current_count: 39, full_count: 45, is_teacher: true, is_lesson: true},
        //   th: {lesson: 'Торговое дело',  room: "425", teacher: "Доцент. Иванов И.И", current_count: 44, full_count: 45, is_teacher: true, is_lesson: true},
        //   fr: {lesson: '',  room: "504 E", teacher: ""},
        //   sa: {lesson: 'Торговое дело',  room: "425", teacher: "Доцент. Иванов И.И", current_count: 44, full_count: 45, is_teacher: true, is_lesson: false},
        // },
        // {
        //   ltime: '13.10 – 14.40',align: 'center',
        //   mo: {lesson: 'Бизнес информатика', room: "504 E", teacher: "Доцент. Иванов И.И", current_count: 24, full_count: 45, is_teacher: true, is_lesson: true},
        //   tue: {lesson: 'Юриспруденция',  room: "202", teacher: "Доцент. Иванов И.И", current_count: 42, full_count: 45, is_teacher: false, is_lesson: true},
        //   we: {lesson: 'Финансы и кредит',  room: "304 Д", teacher: "Доцент. Иванов И.И", current_count: 39, full_count: 45, is_teacher: true, is_lesson: true},
        //   th: {lesson: 'Торговое дело',  room: "425", teacher: "Доцент. Иванов И.И", current_count: 44, full_count: 45, is_teacher: true, is_lesson: true},
        //   fr: {lesson: '',  room: "504 E", teacher: ""},
        //   sa: {lesson: 'Торговое дело',  room: "425", teacher: "Доцент. Иванов И.И", current_count: 44, full_count: 45, is_teacher: true, is_lesson: false},
        // },
        // {
        //   ltime: '14.05 - 15.40',
        //   mo: {lesson: 'Бизнес информатика', room: "504 E", teacher: "Доцент. Иванов И.И", current_count: 24, full_count: 45, is_teacher: true, is_lesson: true},
        //   tue: {lesson: 'Юриспруденция',  room: "202", teacher: "Доцент. Иванов И.И", current_count: 42, full_count: 45, is_teacher: false, is_lesson: true},
        //   we: {lesson: 'Финансы и кредит',  room: "304 Д", teacher: "Доцент. Иванов И.И", current_count: 39, full_count: 45, is_teacher: true, is_lesson: true},
        //   th: {lesson: 'Торговое дело',  room: "425", teacher: "Доцент. Иванов И.И", current_count: 44, full_count: 45, is_teacher: true, is_lesson: true},
        //   fr: {lesson: '',  room: "504 E", teacher: ""},
        //   sa: {lesson: 'Торговое дело',  room: "425", teacher: "Доцент. Иванов И.И", current_count: 44, full_count: 45, is_teacher: true, is_lesson: false},
        // },
        // {
        //   ltime: '14.50 – 16.20',
        //   mo: {lesson: 'Бизнес информатика', room: "504 E", teacher: "Доцент. Иванов И.И", current_count: 24, full_count: 45, is_teacher: true, is_lesson: true},
        //   tue: {lesson: 'Юриспруденция',  room: "202", teacher: "Доцент. Иванов И.И", current_count: 42, full_count: 45, is_teacher: false, is_lesson: true},
        //   we: {lesson: 'Финансы и кредит',  room: "304 Д", teacher: "Доцент. Иванов И.И", current_count: 39, full_count: 45, is_teacher: true, is_lesson: true},
        //   th: {lesson: 'Торговое дело',  room: "425", teacher: "Доцент. Иванов И.И", current_count: 44, full_count: 45, is_teacher: true, is_lesson: true},
        //   fr: {lesson: '',  room: "504 E", teacher: ""},
        //   sa: {lesson: 'Торговое дело',  room: "425", teacher: "Доцент. Иванов И.И", current_count: 44, full_count: 45, is_teacher: true, is_lesson: false},
        // },
        // {
        //   ltime: '16.10  - 17.45',
        //   mo: {lesson: 'Бизнес информатика', room: "504 E", teacher: "Доцент. Иванов И.И", current_count: 24, full_count: 45, is_teacher: true, is_lesson: true},
        //   tue: {lesson: 'Юриспруденция',  room: "202", teacher: "Доцент. Иванов И.И", current_count: 42, full_count: 45, is_teacher: false, is_lesson: true},
        //   we: {lesson: 'Финансы и кредит',  room: "304 Д", teacher: "Доцент. Иванов И.И", current_count: 39, full_count: 45, is_teacher: true, is_lesson: true},
        //   th: {lesson: 'Торговое дело',  room: "425", teacher: "Доцент. Иванов И.И", current_count: 44, full_count: 45, is_teacher: true, is_lesson: true},
        //   fr: {lesson: '',  room: "504 E", teacher: ""},
        //   sa: {lesson: 'Торговое дело',  room: "425", teacher: "Доцент. Иванов И.И", current_count: 44, full_count: 45, is_teacher: true, is_lesson: false},
        // },
        // {
        //   ltime: '16.30 – 18.00',
        //   mo: {lesson: 'Бизнес информатика', room: "504 E", teacher: "Доцент. Иванов И.И", current_count: 24, full_count: 45, is_teacher: true, is_lesson: true},
        //   tue: {lesson: 'Юриспруденция',  room: "202", teacher: "Доцент. Иванов И.И", current_count: 42, full_count: 45, is_teacher: false, is_lesson: true},
        //   we: {lesson: 'Финансы и кредит',  room: "304 Д", teacher: "Доцент. Иванов И.И", current_count: 39, full_count: 45, is_teacher: true, is_lesson: true},
        //   th: {lesson: 'Торговое дело',  room: "425", teacher: "Доцент. Иванов И.И", current_count: 44, full_count: 45, is_teacher: true, is_lesson: true},
        //   fr: {lesson: '',  room: "504 E", teacher: ""},
        //   sa: {lesson: 'Торговое дело',  room: "425", teacher: "Доцент. Иванов И.И", current_count: 44, full_count: 45, is_teacher: true, is_lesson: false},
        // },
        // {
        //   ltime: '18.00  - 19.35',
        //   mo: {lesson: 'Бизнес информатика', room: "504 E", teacher: "Доцент. Иванов И.И", current_count: 24, full_count: 45, is_teacher: true, is_lesson: true},
        //   tue: {lesson: 'Юриспруденция',  room: "202", teacher: "Доцент. Иванов И.И", current_count: 42, full_count: 45, is_teacher: false, is_lesson: true},
        //   we: {lesson: 'Финансы и кредит',  room: "304 Д", teacher: "Доцент. Иванов И.И", current_count: 39, full_count: 45, is_teacher: true, is_lesson: true},
        //   th: {lesson: 'Торговое дело',  room: "425", teacher: "Доцент. Иванов И.И", current_count: 44, full_count: 45, is_teacher: true, is_lesson: true},
        //   fr: {lesson: '',  room: "504 E", teacher: ""},
        //   sa: {lesson: 'Торговое дело',  room: "425", teacher: "Доцент. Иванов И.И", current_count: 44, full_count: 45, is_teacher: true, is_lesson: false},
        // },
      //    ////

      ],
    }
  },
  methods:{
    // getWeekDay: function (date) {
    //   let days = ['Воскресенье', 'Понедельник', 'Вторник', 'Среда', 'Четверг', 'Пятница', 'Суббота'];
    //   return days[date.getDay()];
    //   let date = new Date(2021, 4, 24);
    //   // let weekday;
    //   // weekday = getWeekDay(date) ; // Понедельник
    //   // alert(weekday);
    // }

  },
  components: {
    'schedule-item': ReportItem,
  }
  // components: {
  //   'profsdl-item': ProfsdlItem,
  // }
}
</script>

<style scoped>

</style>