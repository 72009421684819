<template>
  <div>
    <v-container justify-center>
      <v-btn
          :loading="loading"
          @click="updateEvalList"
      >
        Обновить все баллы.
      </v-btn>
      <v-btn class="ma-4"
             :loading="loading"
             @click="updateStudentList"
      >
        Обновить список студентов.
      </v-btn>
      <v-data-table
          :headers="headers"
          :items="gradeSheets"
          :items-per-page="80"
          :hide-default-footer="true"
          class="elevation-2"
          :loading="loading"
      >
        <template v-slot:item.visitPoints="{ item }">
          <v-btn text @click="openDialog(item)">
            {{ item.visitPoints }}
          </v-btn>
        </template>

        <template v-slot:item.learningActivityPoints="{ item }">
          <v-btn text @click="openDialog(item)">
            {{ item.learningActivityPoints }}
          </v-btn>
        </template>

        <template v-slot:item.controlPoints="{ item }">
          <v-btn text @click="openDialog(item)">
            {{ item.controlPoints }}
          </v-btn>
        </template>

        <template v-slot:item.sciencePoints="{ item }">
          <v-btn text @click="openDialog(item)">
            {{ item.sciencePoints }}
          </v-btn>
        </template>

        <template v-slot:item.compensationPoints="{ item }">
          <v-btn text @click="openDialog(item)">
            {{ item.compensationPoints }}
          </v-btn>
        </template>

        <template v-slot:item.evaluation="{ item }">
          <v-btn text @click="openDialog(item)">
            {{ item.evaluation }}
          </v-btn>
        </template>

        <template v-slot:item.summary="{ item }">
          <v-btn text @click="openDialog(item)">
            {{ item.summary }}
          </v-btn>
        </template>

        <template v-slot:item.grade="{ item }">
          <v-btn text class="text-lowercase" @click="openDialog(item)">
            {{ getGrade(item) }}
          </v-btn>
        </template>

      </v-data-table>
    </v-container>

    <template>
      <v-row justify="center">
        <v-dialog
            v-model="dialog"
            width="600px"
            persistent
        >
          <v-card>
            <v-card-title>
              <span class="headline">Редактировать успеваемость</span>
            </v-card-title>

            <v-card-text>
              <p>Студент - <b> {{ gradeSheetsItem.student.student }} </b></p>
              <v-switch
                  v-model="gradeSheetsItem.visit"
                  :label="`${gradeSheetsItem.visit? 'Присутствовал': 'Не явился'}`"
              ></v-switch>
              <p>Оценка:</p>
              <v-card-text>
                <v-text-field
                    v-model="gradeSheetsItem.evaluation"
                    hint="Введите заработанные баллы"
                    :rules="rules"
                    :label="'Максимальный балл -' + maxEvaluation"
                ></v-text-field>
                <h2 style="margin-bottom: 5px">Статистика</h2>
                <p>Посещение - <b> {{ gradeSheetsItem.visitPoints }} </b></p>
                <p>Учебная активность - <b> {{ gradeSheetsItem.learningActivityPoints }} </b></p>
                <p>Контр. точки - <b> {{ gradeSheetsItem.controlPoints }} </b></p>
                <p>Научная деятельность - <b> {{ gradeSheetsItem.sciencePoints }} </b></p>
                <p>Компенсация - <b> {{ gradeSheetsItem.compensationPoints }} </b></p>
                <p>Итого - <b> {{ gradeSheetsItem.summary }} </b></p>
                <h3>Оценка - <b> {{ getGrade(gradeSheetsItem) }} </b></h3>
              </v-card-text>
              <!--                              <p>Оценка - {{ visitItem.evaluation != null ? visitItem.evaluation.toFixed(1): "" }} </p>-->
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                  color="green darken-1"
                  text
                  @click="closeDialog"
              >
                cancel
              </v-btn>
              <v-btn v-if="gradeSheetsItem.evaluation <= maxEvaluation"
                     color="red darken-1"
                     text
                     @click="updateEvaluation"
              >
                Ок
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
    </template>

  </div>
</template>

<script>
import Settings from "@/settings/settings_url";
import {HTTP} from '@/settings/axios';

export default {
  name: "GradeSheet",
  props: {
    gradeSheetId: Number,
  },

  data() {
    return {
      dialog: false,
      rules: [v => v <= this.maxEvaluation || 'Max ' + this.maxEvaluation + ' Баллов'],
      maxEvaluation: 20,
      loading: false,
      headers: [
        {text: 'студент', align: 'left', sortable: false, value: 'student.student', divider: true, class: "bolt"},
        {text: 'посещение', align: 'center', value: 'visitPoints', divider: true, sortable: false, class: "bolt"},
        {
          text: 'учебная активность',
          align: 'center',
          value: 'learningActivityPoints',
          divider: true,
          sortable: false,
          class: "bolt"
        },
        {
          text: 'контр. точки',
          align: 'center',
          value: 'controlPoints',
          divider: true,
          sortable: false,
          class: "bolt"
        },
        {
          text: 'научная деятельность',
          align: 'center',
          value: 'sciencePoints',
          divider: true,
          sortable: false,
          class: "bolt"
        },
        {
          text: 'компенсация',
          align: 'center',
          value: 'compensationPoints',
          divider: true,
          sortable: false,
          class: "bolt"
        },
        {text: 'ведомость', align: 'center', value: 'evaluation', divider: true, sortable: false, class: "bolt"},
        {text: 'итого', align: 'center', value: 'summary', divider: true, sortable: false, class: "bolt"},
        {text: 'Оценка', align: 'center', value: 'grade', divider: true, sortable: false, class: "bolt"},
      ],
      gradeSheets: [],

      gradeSheetsItem: {
        id: null,
        evaluation: 0,
        visitPoints: 0,
        learningActivityPoints: 0,
        controlPoints: 0,
        sciencePoints: 0,
        compensationPoints: 0,
        summary: 0,
        grade: 0,
        gradeString: "",
        visit: false,
        isExam: false,
        student: {
          student: ""
        }
      },
    }
  },
  watch: {
    gradeSheetId: function () {
      this.gradeSheets = [];
      this.getJournal();
    }
  },
  methods: {
    async updateEvalList() {
      this.loading = true;
      await HTTP.put(Settings.API.URL_DEFAULT + "/grade-sheet/" + this.gradeSheetId + "/update-eval")
          .then((response) => {
            this.gradeSheets = response.data
            // console.log(this.gradeSheetsItem)
            this.loading = false;
            // this.getJournal();
          })
          .catch(() => {
            this.errored = true;
            this.loading = false;
          })
          .finally(() => {
            this.loading = false
          });
    },
    async updateStudentList() {
      this.loading = true;
      await HTTP.put(Settings.API.URL_DEFAULT + "/grade-sheet/" + this.gradeSheetId + "/update-student-list")
          .then((response) => {
            this.gradeSheets = response.data
            // console.log(this.gradeSheetsItem)
            this.loading = false;
            // this.getJournal();
          })
          .catch(() => {
            this.errored = true;
            this.loading = false;
          })
          .finally(() => {
            this.loading = false
          });
    },
    getGrade(item) {
      if (item.isExam) {
        return item.grade;
      }
      return item.gradeString;
    },
    openDialog(item) {
      this.gradeSheetsItem = item
      this.dialog = true;
    },
    closeDialog() {
      this.dialog = false;
    },
    updateEvaluation() {
      this.updateEvalAndVisit(this.gradeSheetsItem.id, this.gradeSheetsItem.evaluation, this.gradeSheetsItem.visit)
      this.dialog = false;
    },
    async updateEvalAndVisit(id, evaluation, visit) {
      this.loading = true;
      await HTTP.put(Settings.API.URL_DEFAULT + "/evaluation-grade-sheet/" + id + "/eval?eval=" + evaluation + "&visit=" + visit)
          .then((response) => {
            this.gradeSheetsItem = response.data
            console.log(this.gradeSheetsItem)
            this.loading = false;
            this.getJournal();
          })
          .catch(() => {
            this.errored = true;
            this.loading = false;
          })
          .finally(() => {
            this.loading = false
          });
    },

    async getJournal() {
      this.loading = true;
      await HTTP.get(Settings.API.URL_DEFAULT + "/grade-sheet/" + this.gradeSheetId + "/eval")
          .then((response) => {
            this.gradeSheets = response.data
            console.log(this.gradeSheets)
            this.loading = false;
          })
          .catch(() => {
            this.errored = true;
            this.loading = false;
          })
          .finally(() => {
            // this.calcSum()
            this.loading = false
            // console.log(this.loading)
          });
    }
  },
  mounted() {

  }
}
</script>

<style scoped>

</style>