<template>
  <div>

    <div v-if="loading" class="progress-barr">
      <div class="progress">
        <v-progress-circular
            :size="100"
            :width="10"
            color="blue"
            indeterminate
        ></v-progress-circular>
      </div>
    </div>
    <v-app id="schedule">
      <v-container justify-center>
        <v-flex xs12>
          <v-card>
            <v-card-text>
              <v-layout row wrap>
                <v-flex xs12 md12>
                  <v-card-actions class="justify-center">
                    <h1>Курсовой проект - {{ visits.title }}</h1>
                  </v-card-actions>

                </v-flex>
                <v-flex xs12 md6>

                </v-flex>
                <v-flex xs12 md6>

                </v-flex>
              </v-layout>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>

            </v-card-actions>
          </v-card>
        </v-flex>
      </v-container>
      <v-container justify-center>
        <v-flex xs12>
          <v-data-table
              :headers="headers"
              :items="visits.students"
              :items-per-page="80"
              :hide-default-footer="true"
              :hide-default-header="true"
              class="elevation-2"
          >

            <template v-slot:header="{ props }">
              <thead class="v-data-table-header">
              <tr>
                <th class="text-start v-data-table__divider" v-for="head in props.headers" :key="head.value">
                  {{ head.text }}
                </th>
              </tr>
              </thead>
            </template>

            <template v-slot:item.formal="item">
              <div
                  @click="showDialog(item.item.formal)"
              >
                {{ item.item.formal.formalEvaluation }}
              </div>
            </template>
            <template v-slot:item.content="item">
              <div
                  @click="showDialog(item.item.content)"
              >
                {{ item.item.content.contentEvaluation }}
              </div>
            </template>

            <template v-slot:item.present="item">
              <div
                  @click="showDialog(item.item.present)"
              >
                {{ item.item.present.presentationEvaluation }}
              </div>
            </template>
            <template v-slot:item.eval="item">
              <div
                  @click="showDialog(item.item.eval)"
              >
                {{ content(item.item.eval) }}
              </div>
            </template>

<!--            <template v-slot:item="item">-->
<!--              <tr>-->
<!--                <td class="td-border-right v-data-table__divider" v-for="header in visits.headers" :key="header.value"-->
<!--                    @click="showDialog(item.item[header.value])">-->
<!--                  {{ content(item.item[header.value]) }}-->
<!--                </td>-->
<!--              </tr>-->
<!--            </template>-->
          </v-data-table>
        </v-flex>
      </v-container>

      <template>
        <v-row justify="center">
          <v-dialog
              v-model="dialog"
              width="600px"
              persistent
          >
            <v-card>
              <v-card-title>
                <span class="headline">Редактировать успеваемость</span>
              </v-card-title>

              <v-card-text>
                <p>Студент - <b> {{ visitItem.students.student }} </b> </p>
                <p>Дисциплина - {{ visitItem.workload.workPlan.discipline.name }} </p>
                <p>Тип - {{ visitItem.workload.workPlan.lessonTypes.name }} </p>
                <p>Дата - {{ visitItem.date }} </p>
<!--                <p>Статус - <b> {{ isVisitDialog(visitItem) + "(а)"}} </b> </p>-->
                <v-switch
                    v-model="visitItem.visit"
                    :label="`${visitItem.visit? 'Присутствовал': 'Не явился'}`"
                ></v-switch>
                <p>Формальный:</p>
                <v-card-text>
                  <v-text-field
                      v-model="visitItem.formalEvaluation"
                      hint="Введите заработанные баллы"
                      :rules="rulesF"
                      :label="'Максимальный балл -' + 30"
                  ></v-text-field>
                </v-card-text>
                <p>Содержательный:</p>
                <v-card-text>
                  <v-text-field
                      v-model="visitItem.contentEvaluation"
                      hint="Введите заработанные баллы"
                      :rules="rulesC"
                      :label="'Максимальный балл -' + 50"
                  ></v-text-field>
                </v-card-text>
                <p>Защита:</p>
                <v-card-text>
                  <v-text-field
                      v-model="visitItem.presentationEvaluation"
                      hint="Введите заработанные баллы"
                      :rules="rulesP"
                      :label="'Максимальный балл -' + 20"
                  ></v-text-field>
                </v-card-text>
                <!--                              <p>Оценка - {{ visitItem.evaluation != null ? visitItem.evaluation.toFixed(1): "" }} </p>-->
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    color="green darken-1"
                    text
                    @click="cancel()"
                >
                  cancel
                </v-btn>
                <v-btn
                    color="red darken-1"
                    text
                    @click="updateEvaluationCourseProject"
                >
                  Ок
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-row>
      </template>
    </v-app>
  </div>
</template>

<script>

import Settings from "@/settings/settings_url";
import {HTTP} from '@/settings/axios'

export default {
  name: "CourseProjectOld",
  props:{
    groupId: Number,
    disciplineId: Number,
    yearId: Number,
    halfYearId: Number
  },
  data() {
    return {
      headers:[
        {text: 'Студент', align: 'left', sortable: false, value: 'name', divider: true, class: "bolt"},
        {text: 'Формальный', align: 'center', value: 'formal', divider: true, sortable: false, class: "bolt"},
        {text: 'Содержательный', align: 'center', value: 'content', divider: true, sortable: false, class: "bolt"},
        {text: 'Презентационный (защита)', align: 'center', value: 'present', divider: true, sortable: false, class: "bolt"},
        {text: 'Итого', align: 'center', value: 'eval', divider: true, sortable: false, class: "bolt"},
      ],
      loading: false,
      all: true,
      files: [],
      visits:{

      },
      dialog: false,
      maxEvaluation: 0,
      rules: [v => v <= this.maxEvaluation || 'Max ' + this.maxEvaluation + ' Баллов'],
      rulesF: [v => v <= 30 || 'Max ' + 30 + ' Баллов'],
      rulesC: [v => v <= 50 || 'Max ' + 50 + ' Баллов'],
      rulesP: [v => v <= 20 || 'Max ' + 20 + ' Баллов'],
      visitItem: {
        date: "",
        evaluation: 0,
        visit: 0,
        students:{
          student: ""
        },
        workload:{
          workPlan: {
            discipline:{
              name: ""
            },
            lessonTypes:{
              name: ""
            }
          }
        },
      },
    }
  },
  watch: {
    all: function () {
      this.getJournal()
    },
    yearId(){
      this.updateTable()
    },
    halfYearId(){
      this.updateTable()
    },
    groupId: function (){
      this.updateTable()

    },
    disciplineId: function (){
      this.updateTable()
    },
  },
  methods: {
    cancel(){
      this.dialog = false
      this.getJournal()
    },
    updateTable: function () {
      if (this.groupId != undefined && this.groupId != 0 && this.yearId != undefined && this.yearId != 0
          && this.halfYearId != undefined && this.halfYearId != 0
          && this.disciplineId != undefined && this.disciplineId != 0){
        this.getJournal()
      }
    },
    responseVisitUpdate: function (stud){
      for (let i = 0; i < this.visits.students.length; i++ ){
        for (const [key, value] of Object.entries(this.visits.students[i])) {
          if (value instanceof Object){
            if (value.id == stud.id){
              console.log(this.visits.students[i])
              this.visits.students[i][key] = stud
              break
            }
          }
        }
      }
      this.calcSum()
    },
    calcSum: function () {
      // eslint-disable-next-line no-unused-vars
      let sum = 0
      const copy = []
      //const getPoints = this.getPoints
      for (let i = 0; i < this.visits.students.length; i++ ){
        // eslint-disable-next-line no-unused-vars
        for (const [key, value] of Object.entries(this.visits.students[i])) {
          if (value instanceof Object){
            sum += value.evaluation != null? value.evaluation: 0
            console.log(value.evaluation)

          }
        }
        this.visits.students[i].sum =  sum > this.maxEvaluation? this.maxEvaluation.toFixed(1) + "Б": sum.toFixed(1) + "Б"
        copy.push(this.visits.students[i])
        sum = 0
      }

      this.visits.students = copy
      console.log(this.visits.students)
    },
    isVisitDialog:  function (item) {
      if (item instanceof Object) {
        return item.visit === 1 ? "Присутствовал" : "Не явился"
      }
      return item
    },
    content: function (item) {
      if (item instanceof Object) {
        let sy = ""
        if (item.visit == 0){
          sy = " Не явился"
        }
        return item.evaluation != null && item.evaluation != 0? item.evaluation + sy: "" + sy
      }
      return item
    },
    showDialog: function (item) {
      if (item.id !== 0 && item.id !== undefined  && item.id !== null && (this.visits.role === "ROLE_TEACHER" || this.visits.role === "ROLE_ADMIN")) {
        // let d1 = new Date(item.date);
        // let d2 = new Date();
        // if (this.dateDiffInDays(d1, d2) < 120){  //todo получать с сервака
          this.getMaxEvaluation(item)
          this.dialog = true
          this.visitItem = item
        // }
      }
    },
    getMaxEvaluation: function (item){
      console.log(item)
      this.maxEvaluation = 100
    },
    dateDiffInDays: function(d1, d2) {
      var t2 = d2.getTime();
      var t1 = d1.getTime();

      return parseInt((t2-t1)/(24*3600*1000));
    },
    updateEvaluationCourseProject: function () {
      this.loading = true
      this.visitItem.visit = this.visitItem.visit? 1: 0
      HTTP.put(Settings.API.URL_DEFAULT + "/registration-of-student/course-project",
          this.visitItem
      ).then(() => {
        //this.responseVisitUpdate(response.data)
        //this.evaluationItem.evaluation = response.data.evaluation
        this.getJournal()
      }).catch(() => {
        this.errored = true;
        this.getJournal()
      }).finally(() => {
        this.loading = false
        this.dialog = false
      });
      // }
    },

    getJournal: async function () {

      let groupId = this.groupId
      let disciplineId = this.disciplineId

      if (groupId == 0 || disciplineId == 0){
        groupId = this.$route.query.group
        disciplineId = this.$route.query.discipline
      }

      if (groupId == 0 || disciplineId == 0 || groupId == undefined || disciplineId == undefined ){
        return
      }
      this.loading = true
      this.visits = []

      if(this.yearId < 3) {
        await HTTP.get(Settings.API.URL_DEFAULT + "/registration-of-student/course-project-book?groupid=" + groupId + "&disciplineid=" + disciplineId + "&yearId=" + this.yearId + "&halfYearId=" + this.halfYearId)
            .then((response) => {
              this.visits = response.data
              console.log(this.visits)
            })
            .catch(() => {
              this.errored = true;
            })
            .finally(() => {
              this.loading = false
            });
      } else {
        this.loading = false
      }
    },

  },

  created() {
    this.getJournal()
  },
  components: {}
}
</script>

<style lang="sass">


</style>
