export default {
    actions: {},
    state: {
        currentTime: "",
        currentLessonTime: ""
    },
    mutations: {
        currentTime(state, currentTime){
            state.currentTime = currentTime;
        },
        currentLessonTime(state, currentLessonTime) {
            state.currentLessonTime = currentLessonTime;
        }
    },
    getters: {
        currentTime(state){
            return state.currentTime
        },
        currentLessonTime(state){
            return state.currentLessonTime
        }
    }
}