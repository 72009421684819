<template>
  <v-container>
    <v-alert
        v-model="alert"
        dismissible
        :type="alertType"
        transition="fade-transition"
    >
      {{ alertText }}
    </v-alert>
    <v-container>
    <v-row>
      <v-col cols="9"/>
      <v-col cols="3">
        <v-btn
            color="primary"
            @click="openCreateTypeDialog()"
        >
          Создать новую компенсацию
        </v-btn>
      </v-col>
    </v-row>
    </v-container>
    <v-card>
      <h2 class="text-center">Виды компенсаций</h2>
    <v-data-table
        :loading="loading"
        :headers="headers"
        :items="types"
        class="elevation-2"
        no-data-text="Нет видов"
        loading-text="Загрузка..."
        :footer-props="{
              itemsPerPageAllText: 'Все',
              itemsPerPageText: 'Видов на страницу',
              }"
    >
      <template v-slot:item.edit="{ item }">
        <v-btn icon color="primary" @click="openEditDialog(item)">
          <v-icon>edit</v-icon>
        </v-btn>
        <v-btn icon color="error" @click="openDeleteDialog(item)">
          <v-icon>delete</v-icon>
        </v-btn>
      </template>

    </v-data-table>
    </v-card>
    <compensation-create-type ref="createType"
                              @error="showErrorAlert"
                              @success="showSuccessAlert"
                              @typeCreated="getTypesAndUpdateTypesInCompensationPoints"
    />
    <compensation-type-delete ref="deleteType"
                              @error="showErrorAlert"
                              @success="showSuccessAlert"
                              @deletedType="getTypesAndUpdateTypesInCompensationPoints"
    />
    <compensation-type-edit ref="editType"
                            @error="showErrorAlert"
                            @success="showSuccessAlert"
                            @editedType="getTypesAndUpdateTypesInCompensationPoints"
    />
  </v-container>
</template>

<script>
import {HTTP} from "@/settings/axios";
import Settings from "@/settings/settings_url";
import CompensationCreateType from "@/components/compensation/types/CompensationTypeCreate";
import CompensationTypeDelete from "@/components/compensation/types/CompensationTypeDelete";
import CompensationTypeEdit from "@/components/compensation/types/CompensationTypeEdit";

export default {
  name: "CompensationTypesAdmin",
  components: {
    CompensationTypeEdit,
    CompensationCreateType,
    CompensationTypeDelete,
  },

  data() {
    return {
      loading: false,
      types: [],
      headers: [
        {text: 'Название', align: 'center', sortable: true, value: 'name', divider: true, class: "bolt"},
        {text: 'Максимальное количество баллов', align: 'center', sortable: true, value: 'pointsLimit', divider: true, class: "bolt"},
        {text: 'Создан', align: 'center', sortable: true, value: 'creator.username', divider: true, class: "bolt"},
        {text: 'Редактировать', align: 'center', sortable: true, value: 'edit', divider: true, class: "bolt"},
      ],
      alertText: null,
      alert: false,
      alertType: null
    }
  },
  methods: {
    openDeleteDialog(type) {
      this.$refs.deleteType.compensationType = type
      this.$refs.deleteType.dialog = true
    },
     openEditDialog(type) {
      this.$refs.editType.compensationType = type
      this.$refs.editType.dialog = true
    },
    openCreateTypeDialog() {
      this.$refs.createType.dialog = true
    },
    showSuccessAlert(alertText) {
      this.alert = true
      this.alertText = alertText
      this.alertType = 'success'
    },
    showErrorAlert(alertText) {
      this.alert = true
      this.alertText = alertText
      this.alertType = "error"
    },
    getTypes() {
      this.loading = true
      HTTP.get(Settings.API.URL_DEFAULT + "/compensation-type")
          .then((response) => {
            this.types = response.data
          })
          .catch(() => {

          })
          .finally(() => {
            this.loading = false
          })
    },
    getTypesAndUpdateTypesInCompensationPoints() {
      this.getTypes()
      this.$emit('typesUpdated')
    },
  },
  watch: {
    'alert'() {
      if(this.alert)
        setTimeout(() => {this.alert = false}, 5000)
    },
  },
  created() {
    this.getTypes()
  },
}
</script>

<style scoped>

</style>