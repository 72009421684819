<template>
  <v-container>
    <h1 style="text-align: center">Администрирование компенсационных баллов</h1>

    <v-tabs v-model="tab">
      <v-tab><v-icon left>account_balance_wallet</v-icon>Баллы</v-tab>
      <v-tab><v-icon left>list</v-icon>Виды компенсаций</v-tab>
    </v-tabs>
    <v-tabs-items
        v-model="tab"
    >
      <v-tab-item>
        <compensation-points ref="points"/>
      </v-tab-item>
      <v-tab-item>
        <compensation-types-admin ref="types"
            @typesUpdated="$refs.points.updateTypes()"
        />
      </v-tab-item>
    </v-tabs-items>

  </v-container>
</template>

<script>
import CompensationPoints from "@/components/compensation/CompensationPoints";
import CompensationTypesAdmin from "@/components/compensation/CompensationTypes";

export default {
  name: "CompensationPointsAdmin",
  components: {
    CompensationPoints,
    CompensationTypesAdmin,
  },

  data() {
    return {
      tab: 0,
    }
  },
}
</script>

<style scoped>

</style>