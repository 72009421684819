<template>
  <v-container v-if="isAdmin()">
    <v-card-title class="justify-center">
      <h2>Администрирование ведомостей</h2>
    </v-card-title>
    <v-tabs v-model="tab">
      <v-tab>
        Практика
      </v-tab>
      <v-tab>
        Курсовые работы
      </v-tab>
    </v-tabs>
    <v-tabs-items v-model="tab">
      <v-tab-item>
        <practice-ved-admin/>
      </v-tab-item>
      <v-tab-item>
        <course-project-admin/>
      </v-tab-item>
    </v-tabs-items>
  </v-container>
</template>

<script>
import PracticeVedAdmin from "@/views/admin/gradeSheet/PracticeVedAdmin";
import CourseProjectAdmin from "@/views/admin/gradeSheet/CourseProjectAdmin";
export default {
  name: "SheetAdmin",
  components: {CourseProjectAdmin, PracticeVedAdmin},

  data() {
    return {
      tab: 0,
    }
  },

  methods: {
    isAdmin(){
      return this.isRole("ROLE_ADMIN")
    },
    isRole(role) {
      if (this.$store.getters.selectedUser?.role != null && this.$store.getters.selectedUser?.role.length > 0){
        for (let i = 0; i < this.$store.getters.selectedUser?.role.length; i++){
          if (
              this.$store.getters.selectedUser?.role[i].name === role
          ){
            return true
          }
        }
      }else {
        return  false
      }
    },
  },
}
</script>

<style scoped>

</style>