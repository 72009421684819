<template>
  <div>
    <v-app>
      <v-container justify-center>
        <v-flex xs12>

          <v-card
          >
            <v-card-text>

              <v-layout row wrap>
                <v-flex xs12 md12>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                  </v-card-actions>
                  <v-card-actions class="justify-center">
                    <h1 v-if="!aspirant">ЭЛЕКТРОННАЯ ЗАЧЁТНАЯ КНИЖКА</h1>
                    <h1 v-else>УЧЕБНАЯ КАРТОЧКА</h1>
                  </v-card-actions>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                  </v-card-actions>
                </v-flex>
              </v-layout>
            </v-card-text>
          </v-card>
        </v-flex>
      </v-container>

      <v-container justify-center>
        <v-flex xs12>
          <v-progress-linear v-if="loading"
                             indeterminate
                             color="cyan"
          ></v-progress-linear>
          <v-card>
            <!--              Общая инфо о студенте-->
            <v-card-text v-if="!!gradeBook.state.gradeBook">

              <!--              <v-card-->
              <!--                  class="mx-auto"-->
              <!--                  max-width="434"-->
              <!--                  tile-->
              <!--              >-->
              <!--                <v-img-->
              <!--                    height="100%"-->
              <!--                    src="https://cdn.vuetifyjs.com/images/cards/server-room.jpg"-->
              <!--                >-->
              <v-row>
                <v-col></v-col>
              </v-row>
              <v-row>
                <v-col md="2">
                </v-col>
                <v-col md="1" align="left">
                  <v-row>
                    <v-col></v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-img
                          class="profile"
                          color="grey"
                          tile

                      >
                        <v-img :src="'data:image/jpeg;base64,' + getPhoto()"></v-img>
                      </v-img>
                    </v-col>
                  </v-row>

                </v-col>
                <v-col md="1">
                </v-col>
                <v-col md="8">
                  <v-row>
                    <v-col md="3">
                      <b>
                        <p v-if="!aspirant" class="font-weight-medium"><span class='ttl'> <strong>
                          № Зачётной книжки
                        </strong></span></p>
                        <p v-else class="font-weight-medium"><span class='ttl'> <strong>
                          № Личной учетной карточки
                        </strong></span></p>
                      </b>
                    </v-col>
                    <v-col>
                      <b>
                        <p class="font-weight-medium">
                          <span class='ttl'>
                            {{ gradeBook.state.gradeBook }}
                          </span></p>
                      </b>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col md="3">
                      <b>
                        <p class="font-weight-medium"><span class='ttl'> <strong>
                          Фамилия</strong></span></p>
                      </b>
                    </v-col>
                    <v-col align="left">
                      <b>
                        <p class="font-weight-medium"><span class='ttl'>
                          {{ gradeBook.state.surname }}</span></p>
                      </b>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col md="3">
                      <b>
                        <p class="font-weight-medium"><span class='ttl'> <strong>
                          Имя</strong></span></p>
                      </b>
                    </v-col>
                    <v-col>
                      <b>
                        <p class="font-weight-medium">
                          <span class='ttl'>

                            {{ gradeBook.state.name }}</span></p>
                      </b>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col md="3">
                      <b>
                        <p class="font-weight-medium"><span class='ttl'> <strong>
                          Отчество
                        </strong></span></p>
                      </b>
                    </v-col>
                    <v-col>
                      <b>
                        <p class="font-weight-medium"><span class='ttl'>
                          {{ gradeBook.state.patronymic }}
                      </span>
                        </p>
                      </b>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <!--                  <v-row><v-col></v-col></v-row>-->
                  <v-row>
                    <v-col md="2" align="left">

                    </v-col>
                    <v-col md="4" align="left">
                      <p class="font-weight-medium">
                  <span class='ttl'> <strong>
                  Код направления
                  </strong></span>
                      </p>
                    </v-col>
                    <v-col md="5" align="left">
                      <p class="font-weight-medium">
                    <span class='ttl'>
                  {{ gradeBook.state.directionCode }}
                  </span>
                      </p>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col md="2" align="left">

                    </v-col>
                    <v-col md="4" align="left">
                      <p class="font-weight-medium">
                      <span class='ttl'> <strong>
                      Направление подготовки / специальность
                      </strong></span></p>
                    </v-col>
                    <v-col md="5" align="left">
                      <p class="font-weight-medium">
                      <span class='ttl'>
                      {{ gradeBook.state.direction }}
                      </span></p>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col md="2" align="left">

                    </v-col>
                    <v-col v-if="!aspirant" md="4" align="left">
                      <p class="font-weight-medium">
                      <span class='ttl'> <strong>
                      Образовательная программа
                      </strong></span></p>
                    </v-col>
                    <v-col md="5" align="left">
                      <p class="font-weight-medium">
                      <span class='ttl'>
                      {{ gradeBook.state.program }}
                      </span>
                      </p>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col md="2" align="left">

                    </v-col>
                    <v-col md="4" align="left">
                      <p class="font-weight-medium">
                      <span class='ttl'><strong>
                        Шифр академической группы</strong>
                      </span></p>
                    </v-col>
                    <v-col md="5" align="left">
                      <p class="font-weight-medium">
                      <span class='ttl'>
                      {{ gradeBook.state.groupName }}
                      </span></p>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col md="2" align="left">

                    </v-col>
                    <v-col md="4" align="left">
                      <p class="font-weight-medium">
                      <span class='ttl'><strong>
                        Структурное подразделение
                      </strong></span></p>
                    </v-col>
                    <v-col md="5" align="left">
                      <p class="font-weight-medium">
                      <span class='ttl'>
                      {{ gradeBook.state.institute }}
                      </span></p>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col md="2" align="left">

                    </v-col>
                    <v-col v-if="false" md="4" align="left">
                      <p class="font-weight-medium">
                      <span class='ttl'><strong>
                      Приказ о зачислении №
                      </strong>
                      </span></p>
                    </v-col>
                    <v-col md="5" align="left"><span class='ttl'>
                      {{ gradeBook.state.orderOfAdmission }}
                    </span>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col md="2" align="left">

                    </v-col>
                    <v-col md="4" align="left">
                      <p class="font-weight-medium">
                      <span class='ttl'><strong>
                      Дата приказа о зачислении
                      </strong></span></p>
                    </v-col>
                    <v-col md="5" align="left">
                      <p class="font-weight-medium">
                      <span class='ttl'>
                      {{ gradeBook.state.dateOrderOfAdmission }}
                      </span></p>
                    </v-col>
                  </v-row>

                </v-col>
              </v-row>


              <!--                </v-img>-->
              <!--              </v-card>-->

            </v-card-text>

            <!--            Приказы по студенческому составу-->
            <v-card-text v-if="gradeBook.studentOrders != undefined && gradeBook.studentOrders.length > 0">
              <v-row>
                <v-col md="2"></v-col>
                <v-col md="8">
                  <p>

                    <v-btn

                        text
                        icon
                        color="grey"
                        @click="hidden1 = !hidden1"
                    >
                      <v-icon v-show="!hidden1">add</v-icon>
                      <v-icon v-show="hidden1">close</v-icon>

                    </v-btn>

                    <!--                    <v-btn outline fab small color="blue-grey lighten-4" @click="hidden = !hidden">-->
                    <!--                      <v-icon color="grey darken-4">mdi-google-plus</v-icon>-->
                    <!--                    </v-btn>-->


                    <span class='biglt'> <strong>   Приказы по студенческому составу</strong></span></p>
                </v-col>
                <v-col md="2"></v-col>
              </v-row>

              <v-row>
                <v-col md="2"></v-col>
                <v-col md="7">
                  <div v-show="hidden1">
                    <table border="1" width="100%">

                      <tr>
                        <th>№ Приказа</th>
                        <th>Дата приказа</th>
                        <th>Краткое содержание</th>

                      </tr>
                      <tr v-for="(item, index) in gradeBook.studentOrders"
                          :key="index">
                        <td>{{ item.orderNumber }}</td>
                        <td>{{ item.orderDate.substring(8, 10) }}.{{
                            item.orderDate.substring(5, 7)
                          }}.{{ item.orderDate.substring(0, 4) }}
                        </td>
                        <!--                      <td>01.09.2020</td>-->
                        <td>{{ item.orderType }}</td>
                      </tr>

                    </table>
                  </div>
                </v-col>
                <v-col md="3"></v-col>
              </v-row>


            </v-card-text>
            <!--            Результаты промежуточной аттестации-->
            <v-card-text v-if="semestr.length > 0">
              <v-row>
                <v-col></v-col>
              </v-row>
              <v-row>
                <v-col md="2"></v-col>
                <v-col md="8" align='center'>
                  <p><span class='biglt'> <strong>РЕЗУЛЬТАТЫ ПРОМЕЖУТОЧНОЙ АТТЕСТАЦИИ</strong></span></p>
                </v-col>
                <v-col md="2"></v-col>
              </v-row>
              <div class="mt-10" v-for="(item, index) in semestr"
                   :key="index">
                <v-row>
                  <v-col md="2"></v-col>
                  <v-col md="8" align='left'>
                    <p><span class="mglt">{{ item.name }}</span></p>
                  </v-col>
                  <v-col md="2"></v-col>
                </v-row>

                <v-row>
                  <v-col md="2"></v-col>
                  <v-col md="7">
                    <div>
                      <table border="1" width="100%">
                        <tr>
                          <!--                      <th >111</th>-->
                          <th>Дисциплина</th>
                          <th>Оценка</th>


                        </tr>
                        <tr v-for="(ds, indexDs) in item.resultsInterimAssessment"
                            :key="indexDs">
                          <!--                      <td>999</td> class="lfttxt"-->
                          <td>
                            <v-expansion-panels>
                              <v-expansion-panel>
                                <v-expansion-panel-header>
                                  <div align="left">


                                    <v-row>
                                      <v-col></v-col>
                                    </v-row>
                                    <v-row>
                                      <v-col md="1" align="left"></v-col>
                                      <!--                          <v-col  md ="1" align="left">-->
                                      <!--                            <v-btn-->

                                      <!--                                text-->
                                      <!--                                icon-->
                                      <!--                                color="grey"-->
                                      <!--                                @click="hidden2 = !hidden2"-->
                                      <!--                            >-->
                                      <!--                              <v-icon v-show="!hidden2">add</v-icon>-->
                                      <!--                              <v-icon v-show="hidden2" >close</v-icon>-->

                                      <!--                            </v-btn>-->

                                      <!--                          </v-col>-->

                                      <v-col align="left"><strong>{{ ds.discipline }}</strong></v-col>
                                    </v-row>
                                  </div>
                                </v-expansion-panel-header>
                                <v-expansion-panel-content>
                                  <div v-show="hidden2">
                                    <v-row>
                                      <v-col md="2" align="left"></v-col>
                                      <V-col md="4" align="left"><strong>З.Е.</strong></V-col>
                                      <v-col align="left">{{ ds.ze }}</v-col>
                                    </v-row>
                                    <v-row>
                                      <v-col md="2" align="left"></v-col>
                                      <V-col md="4" align="left"><strong>Часов</strong></V-col>
                                      <v-col align="left">{{ ds.hours }}</v-col>
                                    </v-row>
                                    <v-row>
                                      <v-col md="2" align="left"></v-col>
                                      <V-col md="4" align="left"><strong>Вид аттестации</strong></V-col>
                                      <v-col align="left">{{ ds.type }}</v-col>
                                    </v-row>
                                    <v-row>
                                      <v-col md="2" align="left"></v-col>
                                      <V-col md="4" align="left"><strong>Дата сдачи</strong></V-col>
                                      <v-col align="left">{{ ds.date.substring(0, 10) }}</v-col>
                                    </v-row>
                                    <v-row>
                                      <v-col md="2" align="left"></v-col>
                                      <V-col md="4" align="left"><strong>Преподаватель(и)</strong></V-col>
                                      <v-col align="left">
                                        <div v-for="teacher in ds.teachers" :key="teacher.fio">
                                          {{ teacher.fio }}
                                        </div>
                                      </v-col>
                                    </v-row>
                                    <v-row>
                                      <v-col></v-col>
                                    </v-row>
                                  </div>
                                </v-expansion-panel-content>
                              </v-expansion-panel>
                            </v-expansion-panels>
                          </td>
                          <td><strong>{{ ds.evaluation }}</strong></td>

                        </tr>

                      </table>
                    </div>
                  </v-col>
                  <v-col md="3"></v-col>
                </v-row>
              </div>


            </v-card-text>

            <!--            Курсовые-->
            <v-card-text v-if="gradeBook.courseProject != undefined && gradeBook.courseProject.length > 0">
              <v-row>
                <v-col></v-col>
              </v-row>
              <v-row>
                <v-col md="2"></v-col>
                <v-col md="8" align='center'>
                  <p><span class='biglt'> <strong>КУРСОВЫЕ РАБОТЫ</strong></span></p>
                </v-col>
                <v-col md="2"></v-col>
              </v-row>
              <div>

                <v-row>
                  <v-col md="2"></v-col>
                  <v-col md="8" align='left'>
                    <p><span class="mglt"></span></p>
                  </v-col>
                  <v-col md="2"></v-col>
                </v-row>

                <v-row>
                  <v-col md="2"></v-col>
                  <v-col md="7">
                    <div>
                      <table border="1" width="100%">
                        <tr>
                          <!--                      <th >111</th>-->
                          <th>Дисциплина</th>
                          <th>Оценка</th>


                        </tr>
                        <tr v-for="(ds, indexDs) in gradeBook.courseProject"
                            :key="indexDs">
                          <!--                      <td>999</td> class="lfttxt"-->
                          <td>
                            <v-expansion-panels>
                              <v-expansion-panel>
                                <v-expansion-panel-header>
                                  <div align="left">


                                    <v-row>
                                      <v-col></v-col>
                                    </v-row>
                                    <v-row>
                                      <v-col md="1" align="left"></v-col>
                                      <!--                          <v-col  md ="1" align="left">-->
                                      <!--                            <v-btn-->

                                      <!--                                text-->
                                      <!--                                icon-->
                                      <!--                                color="grey"-->
                                      <!--                                @click="hidden2 = !hidden2"-->
                                      <!--                            >-->
                                      <!--                              <v-icon v-show="!hidden2">add</v-icon>-->
                                      <!--                              <v-icon v-show="hidden2" >close</v-icon>-->

                                      <!--                            </v-btn>-->

                                      <!--                          </v-col>-->

                                      <v-col align="left"><strong>{{ ds.discipline }}</strong></v-col>
                                    </v-row>
                                  </div>
                                </v-expansion-panel-header>
                                <v-expansion-panel-content>
                                  <div v-show="hidden2">
                                    <v-row>
                                      <v-col md="2" align="left"></v-col>
                                      <V-col md="4" align="left"><strong>З.Е.</strong></V-col>
                                      <v-col align="left">{{ ds.ze }}</v-col>
                                    </v-row>
                                    <v-row>
                                      <v-col md="2" align="left"></v-col>
                                      <V-col md="4" align="left"><strong>Часов</strong></V-col>
                                      <v-col align="left">{{ ds.hours }}</v-col>
                                    </v-row>
                                    <v-row>
                                      <v-col md="2" align="left"></v-col>
                                      <V-col md="4" align="left"><strong>Вид аттестации</strong></V-col>
                                      <v-col align="left">{{ ds.type }}</v-col>
                                    </v-row>
                                    <v-row>
                                      <v-col md="2" align="left"></v-col>
                                      <V-col md="4" align="left"><strong>Дата сдачи</strong></V-col>
                                      <v-col align="left">{{ ds.date.substring(0, 10) }}</v-col>
                                    </v-row>
                                    <v-row>
                                      <v-col md="2" align="left"></v-col>
                                      <V-col md="4" align="left"><strong>Преподаватель(и)</strong></V-col>
                                      <v-col align="left">
                                        <div v-for="teacher in ds.teachers" :key="teacher.fio">
                                          {{ teacher.fio }}
                                        </div>
                                      </v-col>
                                    </v-row>
                                    <v-row>
                                      <v-col md="2" align="left"></v-col>
                                      <V-col md="4" align="left"><strong>Семестр</strong></V-col>
                                      <v-col align="left">{{ ds.semester }}</v-col>
                                    </v-row>
                                    <v-row>
                                      <v-col></v-col>
                                    </v-row>
                                  </div>
                                </v-expansion-panel-content>
                              </v-expansion-panel>
                            </v-expansion-panels>
                          </td>
                          <td><strong>{{ ds.evaluation }}</strong></td>

                        </tr>

                      </table>
                    </div>
                  </v-col>
                  <v-col md="3"></v-col>
                </v-row>
              </div>


            </v-card-text>

            <!--            Практики-->
            <v-card-text v-if="gradeBook.practice != undefined && gradeBook.practice.length > 0">
              <v-row>
                <v-col></v-col>
              </v-row>
              <v-row>
                <v-col md="2"></v-col>
                <v-col md="8" align='center'>
                  <p><span class='biglt'> <strong>ПРАКТИКИ</strong></span></p>
                </v-col>
                <v-col md="2"></v-col>
              </v-row>
              <div>

                <v-row>
                  <v-col md="2"></v-col>
                  <v-col md="8" align='left'>
                    <p><span class="mglt"></span></p>
                  </v-col>
                  <v-col md="2"></v-col>
                </v-row>

                <v-row>
                  <v-col md="2"></v-col>
                  <v-col md="7">
                    <div>
                      <table border="1" width="100%">
                        <tr>
                          <!--                      <th >111</th>-->
                          <th>Название</th>
                          <th>Оценка</th>


                        </tr>
                        <tr v-for="(ds, indexDs) in gradeBook.practice"
                            :key="indexDs">
                          <!--                      <td>999</td> class="lfttxt"-->
                          <td>
                            <v-expansion-panels>
                              <v-expansion-panel>
                                <v-expansion-panel-header>
                                  <div align="left">


                                    <v-row>
                                      <v-col></v-col>
                                    </v-row>
                                    <v-row>
                                      <v-col md="1" align="left"></v-col>
                                      <!--                          <v-col  md ="1" align="left">-->
                                      <!--                            <v-btn-->

                                      <!--                                text-->
                                      <!--                                icon-->
                                      <!--                                color="grey"-->
                                      <!--                                @click="hidden2 = !hidden2"-->
                                      <!--                            >-->
                                      <!--                              <v-icon v-show="!hidden2">add</v-icon>-->
                                      <!--                              <v-icon v-show="hidden2" >close</v-icon>-->

                                      <!--                            </v-btn>-->

                                      <!--                          </v-col>-->

                                      <v-col align="left"><strong>{{ ds.discipline }}</strong></v-col>
                                    </v-row>
                                  </div>
                                </v-expansion-panel-header>
                                <v-expansion-panel-content>
                                  <div v-show="hidden2">
                                    <v-row>
                                      <v-col md="2" align="left"></v-col>
                                      <V-col md="4" align="left"><strong>Курс</strong></V-col>
                                      <v-col align="left">{{ ds.course }}</v-col>
                                    </v-row>
                                    <v-row>
                                      <v-col md="2" align="left"></v-col>
                                      <V-col md="4" align="left"><strong>Семестр</strong></V-col>
                                      <v-col align="left">{{ ds.semester }}</v-col>
                                    </v-row>
                                    <v-row>
                                      <v-col></v-col>
                                    </v-row>
                                  </div>
                                </v-expansion-panel-content>
                              </v-expansion-panel>
                            </v-expansion-panels>
                          </td>
                          <td><strong>{{ ds.evaluation }}</strong></td>

                        </tr>

                      </table>
                    </div>
                  </v-col>
                  <v-col md="3"></v-col>
                </v-row>
              </div>


            </v-card-text>

            <v-card-text>
              <v-spacer></v-spacer>
              <v-spacer></v-spacer>
            </v-card-text>
          </v-card>
        </v-flex>
      </v-container>
    </v-app>

  </div>
</template>


<script>
import Settings from "@/settings/settings_url";
import {HTTP} from '@/settings/axios'

export default {
  name: "RecordBook",
  data: () => ({
    sticky: false,
    hidden1: false,
    hidden2: true,
    loading: false,
    gradeBook: {
      state: {
       gradeBook: "",
        photo: "",
      },
      semestr: []
    },
    semestr: [],
    aspirant: false,
  }),
  watch: {
    '$store.getters.selectedStudent': function () {
      console.log(this.$store.getters.selectedStudent)
      this.getGradeBook()
      if (this.$store.getters.selectedStudent != null) {
        if (this.$store.getters.selectedStudent.groups.typesEducation === 'ASPIRANT') {
          this.aspirant = true
        }
      }
    },
  },
  methods: {
    getPhoto() {
      return this.gradeBook?.state?.photo
    },
    getSemestr() {
      this.semestr = this.gradeBook.semestr.sort(this.compareSemestr);
    },
    compareSemestr(a, b) {
      if (a.num < b.num) {
        return -1;
      }
      if (a.num > b.num) {
        return 1;
      }
      return 0;
    },
    getGradeBook() {
      this.loading = true
      this.gradeBook = {
        state: {
          gradeBook: "",
          photo: "",
        },
        semestr: []
      }
      if (!!this.$store.getters.selectedStudent && !!this.$store.getters.selectedStudent.gradeBook){
        HTTP.get(Settings.API.URL_DEFAULT + "/e-garde-book/" + this.$store.getters.selectedStudent.gradeBook)
            .then((response) => {

              if (!!response.data.state && !!response.data.state.gradeBook){
                this.gradeBook = response.data

              }
              console.log(response.data)
              this.getSemestr()
            })
            .catch(() => {

            })
            .finally(() => {
              // this.calcSum()

              this.loading = false
            });
      }

    }
  },
  mounted() {
    this.getGradeBook()


    if (this.$store.getters.selectedStudent != null) {
      if (this.$store.getters.selectedStudent.groups.typesEducation === 'ASPIRANT') {
        this.aspirant = true
      }
    }
  }
}
</script>

<style scoped>
.ttl {

  font-size: 140%;
}

.biglt {

  font-size: 200%;
}

.mglt {

  font-size: 200%;
}

.lfttxt {
  text-align: left;
}

table {
  border-collapse: collapse;
  font-size: 140%;
}

td, tr {
  height: 50px;
  width: 50px;
  text-align: center;
}
</style>
