<template>
  <v-layout align-center justify-center>
    <v-flex xs12 md10>
      <v-card>
        <v-card-text>
          <v-layout row wrap>
            <v-flex xs12 md6>
              <v-card-title>Журнал посещения
                {{ new Date().getDate() }}-{{
                  ("0" + (new Date().getMonth() + 1)).slice(-2)
                }}-{{ new Date().getFullYear() }} г.
                {{ lesson.workload.workPlan.discipline.name }}
              </v-card-title>
              <template>
                <!--                :sort-by="['is_present', ]"-->
                <v-data-table
                    :headers="headers"
                    :items="students"

                    :sort-desc="[false, ]"
                    class="elevation-20"
                    @click:row="handleClick"
                    :hide-default-footer="true"
                    :items-per-page="2000"
                    :item-class="row_classes"

                >
                  <!--                  <template v-slot:top>-->
                  <!--                    <v-row>-->
                  <!--                      <v-col>-->
                  <!--                        <div class="text-left ">-->
                  <!--                          <v-switch-->
                  <!--                              v-model="all"-->
                  <!--                              label="Все"-->
                  <!--                              class="pa-3"-->
                  <!--                          ></v-switch>-->
                  <!--                        </div>-->
                  <!--                      </v-col>-->
                  <!--                      <v-col>-->
                  <!--                        <div class="text-right mr-5">-->
                  <!--                          <v-btn icon right :to="'/followed-users-setting?lesson=' + lesson.id">-->
                  <!--                            <v-icon>settings</v-icon>-->
                  <!--                          </v-btn>-->
                  <!--                        </div>-->
                  <!--                      </v-col>-->
                  <!--                    </v-row>-->
                  <!--                  </template>-->

                  <template v-slot:item.student="{ item }">
                    {{ item.students.student }}
                  </template>
                </v-data-table>
              </template>
            </v-flex>
            <v-flex xs12 md6>
              <v-card-title>
                Аудитория - {{ getAudienceName() }}
              </v-card-title>
              <v-card-text>
                Группа - {{ lesson.workload.workPlan.group.name }}
              </v-card-text>
              <v-card-text>
                Преподаватель - {{ getTeacherName() }}
              </v-card-text>
              <v-card-text>
                Итого студентов - {{ students.length }}
              </v-card-text>
              <v-card-text>
                Присутствует студентов - {{ count_missing_students() }}
              </v-card-text>
              <v-card-actions>
                <v-btn
                    :to="'/journal-all?discipline=' + lesson.workload.workPlan.discipline.id + '&group=' + lesson.workload.workPlan.group.id + '&year=' + lesson.workload.workPlan.yearOfStudy.id + '&halfYear=' + lesson.workload.workPlan.halfYear.id + '&tab=0'">
                  журнал
                </v-btn>
              </v-card-actions>
              <v-card-actions>
<!--                <v-btn-->
<!--                    :to="'/journal-all?discipline=' + lesson.workload.workPlan.discipline.id + '&group=' + lesson.workload.workPlan.group.id + '&tab=1'">-->
<!--                  журнал успеваемости-->
<!--                </v-btn>-->
              </v-card-actions>
            </v-flex>
            <v-flex xs12 md6>

            </v-flex>
          </v-layout>
        </v-card-text>

      </v-card>
    </v-flex>
  </v-layout>
</template>

<script>
import Settings from "@/settings/settings_url";
import {HTTP} from '@/settings/axios'

export default {
  name: "GroupCard",
  data() {
    return {
      headers: [
        {text: 'Студент', align: 'start', sortable: false, value: 'student', class: "",},
        {text: 'Время', value: 'time', sortable: false,},
      ],
      all: true,
      students: [],
      lesson: {
        date: "",
        evaluation: 0,
        visit: 0,
        students: {
          student: ""
        },
        workload: {
          workPlan: {
            discipline: {
              name: ""
            },
            lessonTypes: {
              name: ""
            },
            group: {
              name: ""
            },
            replacementTeachers:[]
          },
          teachers: [],
        },
        audiences: [],
      },
      // group: {},
      // discipline: {},
    }
  },
  watch: {
    all: function () {
      if (this.all) {
        this.getStudents(this.lesson.id)
      } else {
        this.getFollowed()
      }
    }
  },
  methods: {
    getTeacherName: function () {
      let name = ""
      if (this.lesson.id != null){

        name = this.lesson?.workload?.teacher?.fio != undefined ? this.lesson?.workload?.teacher?.fio: ""

      this.lesson.workload.replacementTeachers.forEach(function (item) {
        name += item.fio + ", "
      })
      }
      return name
    },
    getAudienceName: function () {
      let name = ""
      this.lesson.audiences.forEach(function (item) {
        name += item.name + ", "
      })
      return name
    },
    handleClick(item) {
      if (item.id !== null) {
        this.postPresenceMark(item)
      }
    },
    row_classes(item) {
      if (item.id === null) return "";
      if (item.visit == 1) return "green accent-1";
      // if (item.present === 0 && item.is_present) return "lime darken-4";
      // if (item.is_present) return "green accent-1";
      // if (item.present === 1) return "lime darken-4";
      return "red accent-1"
    },
    count_missing_students() {
      let count = 0;

      this.students.forEach(
          function (element) {
            if (element.visit == 1 /* || element.present*/) count++;
          }
      );
      return count
    },
    postPresenceMark: async function (item) {

      // let data = {
      //   id: item.presenceMarkId,
      //   present: item.present === 0 || item.present === null ? 1 : 0,
      //   audiences: this.lesson.audiencesId,
      //   students: item.id,
      //   lessons: this.lesson.id,
      //   visitId: item.visitId
      // }
      // await HTTP.post(Settings.API.URL_PRESENCE_MARK(), item)
      //     .then((response) => {
      //       item = response.data
      //       this.getStudents(this.lesson.id)
      //     })
      //     .catch(() => {
      //       this.errored = true;
      //     })
      //     .finally(() => (this.loading = false));

      await HTTP.put(Settings.API.URL_REGISTRATION_OF_STUDENT_VISIT_UPDATE_VISITS(item.id)).then((response) => {
        console.log(response.data)
        // item = response.data
        item.visit =  response.data?.visit
        //this.setVisit(response.data)
        //this.responseVisitUpdate(response.data)
        //this.visitItem =
      }).catch(() => {
        this.errored = true;
      }).finally(() => {
        this.loading = false
        this.dialog = false
      });

    },
    getFollowed: function () {
      let newSt = []
      if (!this.all) {
        for (let i = 0; i < this.students.length; i++) {
          if (this.students[i].followed == 1) {
            newSt.push(this.students[i])
          }
        }
        if (newSt !== undefined) {
          this.students = newSt
        }

      }
    },
    getStudents: async function (id) {
      await HTTP.get(Settings.API.URL_STUDENTS_VISITS_BY_SCHEDULED_LESSON_ID(id))
          .then((response) => {
            //let lesson = this.lesson
            this.students = response.data
            //     .map(function (item) {
            //   if (/*((item.location === lesson.audiencesId) && lesson.audiencesId != null)
            //       && item.visitId !== null*/ item.visit === 1) item.is_present = true
            //   else item.is_present = false
            //   return item
            // })
            console.log(this.students)
          })
          .catch(() => {
            this.errored = true;
          })
          .finally(() => (this.loading = false));
    },
    getLesson: function () {
      this.getLessonServer(this.$route.query.lesson);
      //return this.$store.getters.lesson_detail;
    },
    // getDiscipline: function () {
    //   HTTP.get(Settings.API.URL_DISCIPLINES_BY_ID(this.lesson.disciplineId))
    //       .then((response) => {
    //         this.discipline = response.data
    //         console.log(this.lesson)
    //         console.log(response.data)
    //       })
    //       .catch(() => {
    //         this.errored = true;
    //       })
    //       .finally(() => (this.loading = false));
    // },
    // getGroup: function () {
    //   HTTP.get(Settings.API.URL_GROUP_BRSO_BY_ID(this.lesson.groupId))
    //       .then((response) => {
    //         this.group = response.data
    //       })
    //       .catch(() => {
    //         this.errored = true;
    //       })
    //       .finally(() => (this.loading = false));
    // },
    getLessonServer: async function (lesson) {
      await HTTP.get(Settings.API.URL_DEFAULT + "/lessons/" + lesson + "/scheduled-lessons")
          .then((response) => {
            this.lesson = response.data
            console.log(response.data)
            this.getStudents(this.lesson.id)
            // this.getGroup()
            // this.getDiscipline()
          })
          .catch(() => {
            this.errored = true;
          })
          .finally(() => (this.loading = false))
    },
  },
  created() {
    this.getLesson()
  },
  computed: {
    // count_missing_students1() {
    //
    // },
  },
}
</script>

<style lang="css">
tr.red > td {
  background-color: #B71C1C;
}

tr.green > td {
  background-color: #1B5E20;
}
</style>
