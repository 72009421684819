<template>
  <v-dialog
      v-model="dialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
  >
    <!--                     <template v-slot:activator="{ on, attrs }">-->
    <!--                       <v-btn-->
    <!--                           color="primary"-->
    <!--                           dark-->
    <!--                           v-bind="attrs"-->
    <!--                           v-on="on"-->
    <!--                       >-->
    <!--                         Подробнее-->
    <!--                       </v-btn>-->
    <!--                     </template>-->
    <v-toolbar
        dark
        color="#36a2eb"
    >

      <v-spacer></v-spacer>
      <v-toolbar-title>Cтатистика за {{ dialogItem.date }}</v-toolbar-title>

      <v-spacer></v-spacer>
      <v-btn
          icon
          dark
          @click="close()"
      >
        <v-icon>close</v-icon>
      </v-btn>
      <!--          <v-toolbar-items>-->
      <!--            <v-btn-->
      <!--                dark-->
      <!--                text-->
      <!--                @click="dialog = false"-->
      <!--            >-->
      <!--              Сохранить-->
      <!--            </v-btn>-->
      <!--          </v-toolbar-items>{{dialogItem.disciplines.fullName}} {{dialogItem.audiences}}-->
    </v-toolbar>

    <v-card
        class="mx-auto"
        max-width="344"
        outlined
        elevation="22"
    >
       <v-list-item
          three-line
          subheader
      >

        <v-list-item-content>

          <v-container>
            <v-card>
              <v-row><v-col></v-col></v-row>

           <v-row><v-col>
            <div class="d-flex justify-content-center">
              <p></p>
              <v-list-item-title align="center">Группа: <b>{{ dialogItem.groupName }}</b></v-list-item-title>
              <p></p>
            </div>
          </v-col></v-row>

          <v-row><v-col>
                <div class="d-flex justify-content-center">
                  <v-list-item-title align="center">Дисциплина: <b> {{ getDisciplineName() }}</b></v-list-item-title>
                  <p></p>
                </div>
          </v-col></v-row>

          <v-row><v-col>
                <div class="d-flex justify-content-center">
                  <v-list-item-title align="center">Посещаемость:
                    <b>{{ ((dialogItem.presentStudents / dialogItem.students) * 100).toFixed(3) }} % </b></v-list-item-title>
                  <p></p>
                </div>
          </v-col></v-row>

          <v-row><v-col>
                <div class="d-flex justify-content-center">
                  <v-list-item-title align="center">Всего студентов: <b>{{ dialogItem.presentStudents }} из {{ dialogItem.students }}</b></v-list-item-title>
                  <p></p>
                </div>
          </v-col></v-row>

           <v-row><v-col>
                <div class="d-flex justify-content-center">
                  <v-list-item-title align="center">Аудитории: <b v-for="(item, i) in dialogItem.audiences" :key="item.id"> {{ item.name }}<i
                      v-if="dialogItem.audiences.length > 1 && i < dialogItem.audiences.length - 1">, </i> </b></v-list-item-title>
                  <p></p>
                </div>
           </v-col></v-row>
              <v-row><v-col></v-col></v-row>
           </v-card>
          </v-container>

        </v-list-item-content>
      </v-list-item>
<!--      <v-divider></v-divider>-->
      <v-list
          three-line
          subheader
      >
        <v-list-item>
          <v-list-item-content>
            <v-container>
            <v-data-table
                :headers="headers_tchr"
                :items="dialogItem.teachers"
                class="elevation-1"
                :items-per-page="50"
                :hide-default-footer="true">

            </v-data-table>
            </v-container>
          </v-list-item-content>
        </v-list-item>
      </v-list>
<!--      <v-divider></v-divider>-->
      <v-list-item>
        <v-list-item-content>
          <v-container>
          <v-data-table
              :headers="headers_stud"
              :items="info_stud"
              class="elevation-1"
              :items-per-page="500"
              :hide-default-footer="true">
            <template #item.index="{ item  } ">
              {{ info_stud.indexOf(item)+ 1 +")"}}
            </template>


          </v-data-table>
            <v-row><v-col><p></p></v-col></v-row>
          </v-container>
        </v-list-item-content>
      </v-list-item>
    </v-card>
  </v-dialog>
</template>

<script>
import Settings from "@/settings/settings_url";
import {HTTP} from '@/settings/axios';
export default {
  name: "DetailStatistics",
  props:{
    dialog: Boolean,
    dialogItem: Object,
  },
  data() {
    return {

      notifications: false,
      sound: true,
      widgets: false,
      headers_tchr: [


        {text: 'Преподаватель', align: 'center', value: 'teacher', divider: true, sortable: false},
        {text: 'Присутствие', align: 'center', value: 'visit', divider: true, sortable: false},
        {text: 'Аудитория', align: 'center', value: 'presentAudience.name', divider: true, sortable: false},


      ],
      info_tchrs: [],

      headers_stud: [
        {text: '', align: 'right', value: 'index', divider:false, sortable: false},
        {text: 'Студент', align: 'left', value: 'student', divider: true, sortable: false},
        {text: 'Присутствие', align: 'center', value: 'visit', divider: true, sortable: false},
        {text: 'Аудитория', align: 'center', value: 'presentAudience.name', divider: true, sortable: false},


      ],
      info_stud: [  ],
      // dialogItem: {
      //   disciplines: {
      //     name: ''
      //   },
      // }
    }

  },
  watch:{
    async dialogItem() {
      if (this.dialog){
        await this.getStudentSt()
        await this.getTeacherSt()
        await this.getVisitStudentSt()
      }

    },
  },
  methods: {
    close(){
      this.$emit('close')
    },
    getDisciplineName(){
      return  this.dialogItem.disciplines != null && this.dialogItem.disciplines != undefined ? this.dialogItem.disciplines.name: ""
    },
    // detailItem: async function (item) {
    //   this.dialog = true
    //   console.log(item)
    //   this.dialogItem = {...item}
    //   await this.getStudentSt()
    //   await this.getTeacherSt()
    //   await this.getVisitStudentSt()
    //
    // },
    getStudentSt: async function () {
      await  HTTP
          .get(Settings.API.URL_DEFAULT + "/lessons/"+ this.dialogItem.lessonId +"/students" )

          .then((response) => {
            this.info_stud = response.data

          })
       },
    getVisitStudentSt: async function () {
      await  HTTP
          .get(Settings.API.URL_DEFAULT + "/lessons/"+ this.dialogItem.lessonId +"/student-visit" )
          .then((response) => {
            // console.log( "sssssssss")
            console.log( response.data)
            this.info_stud = this.info_stud.map(function (item){
              item.visit = response.data.find(function (it){
                return it.students.id == item.id
              }).visit?"✔":"Н";

              item.presentAudience = response.data.find(function (it){
                return it.students.id == item.id
              }).presentAudience;
              return item
            });

          })
    },


    //dialogItem.
    // "+ this.dialogItem.lessonId +"
    getTeacherSt: function () {

      HTTP.get(Settings.API.URL_DEFAULT + "/lessons/"+ this.dialogItem.lessonId +"/teacher-visit")
          .then((response) => {
            this.info_tchrs = response.data
            this.dialogItem.teachers= this.dialogItem.teachers.map(function (item){
              item.visit = response.data.find(function (it){
                return it.teacher.id == item.id
              }).visit?"✔":"Н";

              item.presentAudience = response.data.find(function (it){
                return it.teacher.id == item.id
              }).presentAudience;
              return item
            });

          }).catch(() => {

      }).finally(() => {

      });
    },
  }

  }
</script>

<style scoped>

</style>