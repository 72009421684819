<template>
  <div id="example-1">
    <section v-if="errored">
      <p>Сервер временно не работает.</p>
    </section>
    <section v-else>
      <div v-if="loading">Информация загружается ....</div>
<!--      <select v-model="selected">-->
<!--        <option v-for='item in info' :value='item.groupCode' v-bind:key='item.groupCode'>-->
<!--          {{item.faculty}}, {{item.course}}, {{item.directionCode}}, {{item.formOfTraining}}, {{item.trainingPeriod}}: Направление: {{item.direction}}, Группа: {{ item.group }}, Кол-во ст. : {{item.numberOfStudents}}-->
<!--        </option>-->
<!--      </select>-->
      <v-autocomplete v-model="selected" @change="onChangeSelectedTask"
          :items="items"
          label="Группы"
          dense
      >
      </v-autocomplete>
      <br>
      Выбранная группа: {{ selected}}
    </section>
    <v-card-text>
      <v-layout row wrap>
        <v-flex xs12 md13>
          <v-card-actions class="justify-center">
            <h1>Сводная таблица</h1>
          </v-card-actions>

        </v-flex>
        <v-flex xs12 md7>

        </v-flex>
        <v-flex xs12 md7>

        </v-flex>
      </v-layout>
    </v-card-text>
    <v-layout justify-center>
      <v-flex xs12>
        <v-data-table

            :headers="headers"
            :items="desserts"
            :items-per-page="15"
            :hide-default-footer="false"
            class="elevation-2"
            :single-expand="true"
            @item-expanded="onChangeInfoStud"
            item-key="gradebook"
            show-expand
        >
          <template v-slot:item.linked="{ item }" icon>
            <div v-if="item.linked == false">
              <v-icon>unpublished</v-icon>
            </div>
            <div v-else icon>
              <v-icon>verified</v-icon>
            </div>
          </template>
        <template v-slot:expanded-item="{ headers, item }" @change="onChangeInfoStud(item)">
          <td :colspan="headers.length" class="ma-0 pa-0">
            <template v-if="stinfo.length > 0">
              <v-list>
                <template v-for="(itembrso, i) in stinfo">
                  <v-divider
                      v-if="!itembrso"
                      :key="`divider-${i}`"
                  ></v-divider>

                  <v-list-item
                      v-else
                      :key="`item-${i}`"
                      :value="itembrso"
                      active-class="deep-purple--text text--accent-4"
                  >
                    <template>
                      <v-list-item-action>
                        <v-btn :color="getColor(itembrso, item)" v-on:click="onChangeConnDel(itembrso, item, selected)" icon >
                          <v-icon >check_circle</v-icon>
                        </v-btn>
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title v-text="itembrso.surname + ' ' + itembrso.name + ' ' + itembrso.patronymic"></v-list-item-title>

                      </v-list-item-content>
                      <template>
                        <div class="text-center">
                          <v-dialog
                              v-model="dialog"
                              width="500"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                  color="red lighten-2"
                                  dark
                                  v-bind="attrs"
                                  v-on="on"

                              >
                                Информация о пользователе
                              </v-btn>
                            </template>

                            <v-card>
                              <v-card-title class="headline grey lighten-2">
                                Информация о пользователе
                              </v-card-title>

                              <v-card-text>
                                <br>
                                <h1>БРСО</h1>
                                <p>ФИО: {{itembrso.surname + ' ' + itembrso.name + ' ' + itembrso.patronymic}}</p>
                                ID OREON: {{itembrso.id}}<br>
                                Дата рождения : {{itembrso.birthDate}}
                              </v-card-text>
                              <v-divider></v-divider>
                              <v-card-text>
                                <br>
                                <h1>1C</h1>
                                <p>ФИО: {{item.student}}</p>
                                <p>
                                  Номер зачётки: {{item.gradebook}}<br>
                                  Код студента: {{item.studentCode}}<br>
                                  Код зачётки: {{item.gradebookCode}}<br>
                                </p>
                              </v-card-text>
                              <v-divider></v-divider>
                              <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn
                                    color="primary"
                                    text
                                    @click="dialog = false"
                                >
                                  Закрыть
                                </v-btn>
                              </v-card-actions>
                            </v-card>
                          </v-dialog>
                        </div>
                      </template>
                    </template>
                  </v-list-item>
                </template>
              </v-list>
            </template>
            <div v-else class="ml-10">
              Пользователь не найден !!!
              <v-btn
                  color="primary"
                  text
                  @click="onChangeConnDel(null, item, selected, false)"
              >
                Добавить без привязке к Орион
              </v-btn>
            </div>
          </td>
        </template>
        </v-data-table>
      </v-flex>
    </v-layout>
  </div>

</template>

<script>
import Settings from '../settings/settings_url'
import {HTTP} from '@/settings/axios'

export default {
  data() {
    return {
      headers: [
        {text: 'Студент', align: 'student', sortable: false, value: 'student', divider: true, class: "bolt"},
        {text: 'Состояние БРСО', align: 'center', value: 'linked', divider: true, sortable: false, class: "bolt"},
        {text: 'Номер зачётной книжки', value: 'gradebook', divider: true, sortable: false, class: "bolt"},
        {text: 'Код зачётной книжки', value: 'gradebookCode', divider: true, sortable: false, class: "bolt"},
        {text: 'Код студента', value: 'studentCode', divider: true, sortable: false, class: "bolt"},
        {text: 'Фамилия', value: 'surname', divider: true, sortable: false, class: "bolt"},
        {text: 'Имя', value: 'name', divider: true, sortable: false, class: "bolt"},
        {text: 'Отчество', value: 'patronymic', divider: true, sortable: false, class: "bolt"},
      ],
      dialog: false,
      desserts: [],
      stinfo: [],
      items: [],
      selected: null,
      info: null,
      loading: true,
      errored: false
    };
  },
  async created() {
    await HTTP.get(Settings.API.URL_DEFAULT + '/groups-one-c/')
        .then((response) => {
          this.info = response.data;
          this.items = this.info.map(function (item){
            return {
              text: item.group + ", " + item.course + ", " + item.faculty + ", Направление:  " + item.direction + ", Число студентов: " + item.numberOfStudents + ", " + item.formOfTraining + ", " + item.directionCode + ", " + item.trainingPeriod,
              value:item.groupCode
            }
          })
        })
        .catch(() => {
          this.errored = true;
        })
        .finally(() => (this.loading = false));
  },

  mounted() {
  },
  methods: {
    onChangeSelectedTask : async function () {
      await HTTP.get(Settings.API.URL_DEFAULT + '/groups-one-c/' + this.selected + '/students')
          .then((response) => {
            this.info = response.data;
            this.desserts = this.info
          })
    },
    onChangeInfoStud: async function ({item}){
      this.onChangeInfoStud1(item)
    },
    onChangeConnDel: async function (itembrso, item, grId, join = true){
      if(item.linked){
        this.ConnectDel(itembrso, item)
      //}else if((!join || !item.linked) && (!join || !itembrso.linked)){
      }else if(!item.linked){
        this.ConnectUs(itembrso, item, grId, join)
      }
    }
    ,
    ConnectUs: async function (itembrso, item, grId, join = true){
      let idSt = null;
      if (join) idSt = itembrso.id;
      await HTTP.post(Settings.API.URL_DEFAULT + '/students', {
        'grade_book_student_one_c': item.gradebook,
        'id_student_orion': idSt,
        'group_code_one_c': grId,
        'join': join
      })
          .then((response) => {
           this.info = response.data;
           this.onChangeInfoStud1(item)
            this.onChangeSelectedTask()
         })
    },
    ConnectDel: async function (itembrso, item){
      await HTTP.delete(Settings.API.URL_DEFAULT + '/students/' + item.studentIdLink + '?studentcode=' + item.studentCode + '&orionid=' + itembrso.id)
          .then((response) => {
            this.info = response.data;
            this.onChangeInfoStud1(item)
            this.onChangeSelectedTask()
          })
    },
        getColor(item, itembrso){

            if(itembrso.studentCode != item.studentCodeLink && item.linked == true){
              return "red"
            }
            else if(item.linked == true){
              return "green"
            }
            else{
              return ""
            }

        },
    onChangeInfoStud1: async function (item){
      this.stinfo = []

      await HTTP.get(Settings.API.URL_DEFAULT + '/users-orion?name='+item.name+'&patronymic='+item.patronymic+'&surname='+item.surname)
          .then((response) => {
            this.info = response.data;
            this.stinfo = this.info
          })
    }
  },
}
</script>

