<template>
  <v-dialog v-model="dialog" width="600px">
    <v-card>
      <v-toolbar
          dark
          color="primary"
      >
        <v-card-title>Создать ведомость</v-card-title>
        <v-spacer/>
        <v-btn
            icon
            dark
            @click="dialog = false"
        >
          <v-icon>close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text>
        <v-form
            ref="form"
            v-model="valid"
        >
          <v-text-field v-model="group" prepend-icon="groups" disabled label="Группа"/>
          <v-text-field v-model="discipline" prepend-icon="school" disabled label="Дисциплина"/>
          <v-autocomplete v-model="selectedTeachers"
                          :items="teachers"
                          label="Преподаватель(-и)"
                          item-value="id"
                          item-text="teacher"
                          multiple
                          :loading="teachersLoading"
                          prepend-icon="group"
                          autofocus
                          :rules="notNullRules"
                          hint="Можно выбрать несколько"
                          persistent-hint
          />
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-spacer/>
        <v-btn
            color="primary"
            :loading="loading"
            :disabled="loading || !selectedTeachers || (selectedTeachers && selectedTeachers.length === 0)"
            @click="createSheet"
        >
          Создать
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {HTTP} from "@/settings/axios";
import Settings from "@/settings/settings_url";

export default {
  name: "CreateSheetDialog",

  props: {
    groupId: Number,
    disciplineId: Number,
    yearId: Number,
    halfYearId: Number,
    sheetType: String,
  },

  data() {
    return {
      valid: false,
      dialog: false,
      loading: false,
      teachersLoading: false,
      teachers: [],
      selectedTeachers: [],
      group: null,
      discipline: null,
      notNullRules: [
        v => !!v || 'Укажите хотя бы одного преподавателя',
          v => v.length > 0 || 'Укажите хотя бы одного преподавателя '
      ],
    }
  },

  methods: {
    createSheet() {
      this.loading = true

      let url = ""

      if(this.sheetType === "PRACTICE")
        url += "/practice-sheet"
      else if(this.sheetType === "COURSE_PROJECT")
        url += "/course-project-sheet"
      else
        return

      url += "/create-main-sheet"
          + "?teacherId=" + this.selectedTeachers
          + "&disciplineId=" + this.disciplineId
          + "&groupId=" + this.groupId
          + "&halfYearId=" + this.halfYearId
          + "&yearId=" + this.yearId

      HTTP.get(Settings.API.URL_DEFAULT + url
      )
          .then((response) => {
            this.$emit("createdSheet", response.data)
            this.dialog = false
            this.selectedTeachers = []
          })
          .catch(() => {})
          .finally(() => {
            this.loading = false
          });
    },
    getTeachers() {
      this.teachersLoading = true
      HTTP.get(Settings.API.URL_DEFAULT + "/schedule-board/teachers")
          .then((response) => {
            this.teachers = response.data
          })
          .catch(() => {
          })
          .finally(() => {
            this.teachersLoading = false
          });
    },
    getDiscipline() {
      HTTP.get(Settings.API.URL_DEFAULT + "/disciplines/" + this.disciplineId)
          .then((response) => {
            this.discipline = response.data.name
          })
    },
    getGroup() {
      HTTP.get(Settings.API.URL_DEFAULT + "/groups/" + this.groupId)
          .then((response) => {
            this.group = response.data.name
          })
    },
  },
  watch: {
    'dialog'() {
      this.getDiscipline()
      this.getGroup()
      this.getTeachers()
    }
  },
  mounted() {
    this.getDiscipline()
    this.getGroup()
    this.getTeachers()
  },
}
</script>

<style scoped>

</style>