<template>
  <div>
    <!--    <v-btn icon v-if="lessonsError.length">-->
    <!--      <v-badge :content="lessonsError.length" color="error">-->
    <!--        <v-icon>model_training</v-icon>-->
    <!--      </v-badge>-->
    <!--    </v-btn>-->

    <v-menu v-if="lessonsError.length"
            transition="slide-y-transition"
            bottom
            :close-on-content-click="false"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
            icon
            dark
            v-bind="attrs"
            v-on="on"
        >
          <v-badge :content="lessonsError.length" color="error">
            <v-icon>model_training</v-icon>
          </v-badge>
        </v-btn>
      </template>
      <v-list>
        <v-list-item
            v-for="item in lessonsError" :key="item.course"
        >
          <v-list-item-title>
            {{ translateTypesEducation(item.typeEducation) }} {{ item.faculty }} {{ item.course }}
            {{ item.year }} {{ item.halfYear }}
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>

    <v-menu v-if="lessonsSend.length"
            transition="slide-y-transition"
            bottom
            :close-on-content-click="false"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
            icon
            dark
            v-bind="attrs"
            v-on="on"
        >
          <v-badge :content="lessonsSend.length">
            <v-icon>more_time</v-icon>
          </v-badge>
        </v-btn>
      </template>
      <v-list>
        <v-list-item
            v-for="item in lessonsSend" :key="item.course"
        >
          <v-list-item-title>
            {{ translateTypesEducation(item.typeEducation) }} {{ item.faculty }} {{ item.group }}
            {{ item.course }} {{ item.year }} {{ item.halfYear }}
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>

    <v-menu v-if="lessonsNew.length"
            transition="slide-y-transition"
            bottom
            :close-on-content-click="false"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
            icon
            dark
            v-bind="attrs"
            v-on="on"
        >
          <v-badge :content="lessonsNew.length" color="success">
            <v-icon>cached</v-icon>
          </v-badge>
        </v-btn>
      </template>
      <v-list>
        <v-virtual-scroll
            :bench="benched"
            :items="lessonsNew"
            height="300"
            width="900"
            item-height="64"
        >
          <template v-slot:default="{ item }">
            <v-list-item :key="item">
              <!--              <v-list-item-action>-->
              <!--                <v-btn-->
              <!--                    fab-->
              <!--                    small-->
              <!--                    depressed-->
              <!--                    color="primary"-->
              <!--                >-->
              <!--                  {{ item }}-->
              <!--                </v-btn>-->
              <!--              </v-list-item-action>-->

              <v-list-item-action>
                <v-btn icon
                       @click="sendByGroup(item.groupId, item.yearId, item.halfYearId)"
                       color="primary"
                       :loading="loading"
                       :disabled="loading"
                >
                  <v-icon>send</v-icon>
                </v-btn>
              </v-list-item-action>

              <v-list-item-content>
                <v-list-item-title>
                  {{ translateTypesEducation(item.typeEducation) }} {{ item.faculty }} {{ item.group }}
                  {{ item.course }} {{ item.year }} {{ item.halfYear }} ({{ item.lessonCount }})
                </v-list-item-title>
              </v-list-item-content>


            </v-list-item>

            <v-divider></v-divider>
          </template>
        </v-virtual-scroll>

        <!--        <v-list-item-->
        <!--            v-for="item in lessonsNew" :key="item.course"-->
        <!--        >-->
        <!--          <v-list-item-title>-->
        <!--            {{translateTypesEducation(item.typeEducation)}} {{item.faculty}}  {{item.group}}-->
        <!--            {{item.course}} {{item.year}} {{item.halfYear}} ({{item.lessonCount}})-->
        <!--          </v-list-item-title>-->
        <!--        </v-list-item>-->
      </v-list>
    </v-menu>


  </div>
</template>

<script>
import Settings from "@/settings/settings_url";
import {HTTP} from '@/settings/axios'

export default {
  name: "LessInfo",
  data: () => ({
    lessonsNew: [],
    lessonsSend: [],
    lessonsError: [],
    benched: 0,
    loading: false,

  }),
  methods: {
    async sendByGroup(id, year, halfYear) {
      this.loading = true;
      console.log(id);
      await HTTP.get(Settings.API.URL_DEFAULT + "/schedule-exchangeService/" + id +
          "?yearId=" + year + "&halfYearId=" + halfYear)
          .then((response) => {
            this.groups = response.data;
            console.log(this.groups);
            // this.lessonsNew = []
            // this.lessonsSend = []
            // this.lessonsError = []
            this.getLessonsNew()
            this.getLessonsSend()
            this.getLessonsError()
          })
          .catch(() => {
            this.errored = true;
          })
          .finally(() => {
            this.loading = false;
          });
    },
    translateTypesEducation(item) {
      if (item === 'MAGISTRACY')
        return "Магистратура"
      else if (item === 'BACCALAUREATE')
        return "Бакалавриат"
      else if (item === 'SPO')
        return "СПО"
      else if (item === "ASPIRANT")
        return "Аспирантура"
      else if (item === null)
        return "Не указано"
      else
        return "Неизвестно"
    },
    getLessonsNew: async function () {
      return new Promise((resolve, reject) => {
        HTTP.get(Settings.API.URL_DEFAULT + "/scheduled-lessons/lesson-new-info?status=NEW")
            .then((response) => {
              this.lessonsNew = response.data
              // console.log(this.user)
              console.log(this.lessonsNew)
              resolve(response.data)
            })
            .catch((err) => {
              reject(err)
            })
      });
    },
    getLessonsSend: async function () {
      return new Promise((resolve, reject) => {
        HTTP.get(Settings.API.URL_DEFAULT + "/scheduled-lessons/lesson-new-info?status=SEND")
            .then((response) => {
              this.lessonsSend = response.data
              // console.log(this.user)
              resolve(response.data)
            })
            .catch((err) => {
              reject(err)
            })
      });
    },
    getLessonsError: async function () {
      return new Promise((resolve, reject) => {
        HTTP.get(Settings.API.URL_DEFAULT + "/scheduled-lessons/lesson-new-info?status=ERROR")
            .then((response) => {
              this.lessonsError = response.data
              // console.log(this.user)
              resolve(response.data)
            })
            .catch((err) => {
              reject(err)
            })
      });
    },
  },
  async mounted() {
    setInterval(this.getLessonsNew, 5000)
    setInterval(this.getLessonsSend, 5000)
    setInterval(this.getLessonsError, 5000)
    // await this.getLessonsNew();
    // await this.getLessonsSend();
    // await this.getLessonsError();

    console.log(this.lessonsSend)
    console.log(this.lessonsError)
  }
}
</script>

<style scoped>

</style>