
export default {
    state: {
        lesson_detail: null,
    },
    mutations: {
        update_lesson_detail(state, lesson){
            state.lesson_detail = lesson;
        }
    },
    actions: {

    },
    getters:{
        lesson_detail(state){
            return state.lesson_detail;
        }
    }
}
