<template>
  <div>
    <v-app id="schedule">
      <v-container justify-center>
        <v-flex xs12>
          <template>
            <v-card
                class="mx-auto"
                tile
            >
              <v-divider></v-divider>
              <v-list
                  nav
                  dense
              >
                <v-list-item-group
                    v-model="selectedItem"
                    color="primary"
                >
                  <v-list-item
                      v-for="(item, i) in items"
                      :key="i"
                      :to="item.link"
                  >
                    <v-list-item-icon>
                      <v-icon v-text="item.icon"></v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                      <v-list-item-title v-text="item.name" ></v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </v-card>
          </template>
        </v-flex>
      </v-container>
    </v-app>

  </div>
</template>

<script>

import Settings from "@/settings/settings_url";
import {HTTP} from '@/settings/axios'

export default {
  name: "GradeBookList",
  data() {
    return {
      title:"",
      selectedItem: 0,
      items: [],
    }
  },
  methods: {
    getJournal:  function () {

      HTTP.get(Settings.API.URL_GRADE_BOOK_LIST())
          .then((response) => {
            this.items = response.data
          })
          .catch(() => {
            this.errored = true;
          })
          .finally(() => (this.loading = false));
    }
  },
  created() {
    this.getJournal()
  },
  components: {

  }
}
</script>

<style lang="sass">



</style>
