<template>
  <v-container>
    <v-expansion-panels
        v-model="panel"
        multiple
    >
      <v-expansion-panel>
        <v-expansion-panel-header><span class="ltr" align="center">Регистрация в очередь</span>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-card elevation="0">
            <v-spacer></v-spacer>
            <v-card-text>
              <v-row>
                <v-col></v-col>
              </v-row>
              <v-row class="d-flex flex-wrap">


                <v-col md="1">
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="3"  >
                  <v-text-field
                      v-model="clientName"
                      value=""
                      label="ФИО"
                      outlined
                      rounded
                      :readonly="isStudent"
                  ></v-text-field>
                </v-col>
                <v-col md="1">
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="3">
                  <v-autocomplete
                      v-model="queueGroup"
                      :items="queueGroups"
                      outlined
                      rounded
                      dense
                      chips
                      small-chips
                      item-text="name"
                      item-value="id"
                      return-object
                      label="Направление"
                  ></v-autocomplete>
                </v-col>
                <v-col md="1"></v-col>
                <v-col cols="12" xs="12" sm="12" md="2">
                  <v-btn
                      block
                      color="primary"
                      elevation="7"
                      fab
                      outlined
                      rounded
                      :disabled="disabledNewClient"
                      :loading="loadedNewClient"
                      @click="postQueueClient"
                  >Записаться
                  </v-btn>
                </v-col>
                <v-col md="1"></v-col>

              </v-row>
            </v-card-text>
          </v-card>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel>
        <v-expansion-panel-header></v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-card elevation="0">
<!--            <span class="ltr"><p align="center">Вы  в очереди в окно № </p></span>-->
            <span class="ltr" v-if="queueTicket.id != undefined && queueTicket.id != null"><p align="center">
              Ваш номер № {{ queueTicket.num + queueTicket.queueGroups.letter }}<span v-if="showWindow">,  Окно {{ getWindow(queueTicket) }}</span>
            </p></span>
            <p></p>
            <p align="center">
              <v-col md="2">
                <v-btn
                    block
                    color="red"
                    elevation="7"
                    fab
                    outlined
                    rounded
                    :disabled="disabledDeletedTicket"
                    @click="deleteTicket"
                >Отменить
                </v-btn>
              </v-col>
            </p>
            <v-col md="1"></v-col>
          </v-card>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <v-spacer></v-spacer>
    <v-card fliud>
      <tablo></tablo>
    </v-card>
  </v-container>


</template>


<script>
import Tablo from "../components/Tablo";
import {HTTP} from '@/settings/axios'
import Settings from "@/settings/settings_url";

export default {
  name: "TakeQueue",
  components: {Tablo},
  data() {
    return {

      headers: [
        {
          text: 'Номер',
          align: 'center',
          sortable: false,
          color: 'green',
          value: 'number',
        },
        {
          text: 'Окно', align: 'center',
          sortable: false, value: 'window'
        },

      ],
      ques: [
        {
          number: '4А',
          window: 'Окно 5',
        },
        {
          number: '2D',
          window: 'Окно 5',
        }, {
          number: '1C',
          window: 'Окно 5',
        },
        {
          number: '3Б',
          window: 'Окно 5',
        },

      ],

      panel: [0,],

      clientName: "",
      user: null,
      queueGroup: {},
      queueGroups: [],
      isStudent: false,
      disabledNewClient: true,
      loadedNewClient: true,
      queueTicket: {
        id: null,
        status: null
      },
      disabledDeletedTicket: true,
      showWindow: false,
    }
  },
  watch: {
    'queueTicket.id': function (val) {
      // console.log(val)
      if (val != null){
        this.disabledDeletedTicket = false
        this.disabledNewClient = true
        this.panel = [1,]
      }else {
        this.panel = [0,]
        this.disabledDeletedTicket = true
        this.disabledNewClient = false
        this.loadedNewClient = false
      }

    },
    'queueTicket.status': function (val) {
      // console.log(val)
      console.log(val)
      if (val === "ACTIVE"){
        this.disabledDeletedTicket = false
        this.showWindow = false
      }

      if (val === "BUSY"){
        this.disabledDeletedTicket = true
        this.showWindow = true
      }
    }
  },
  methods: {
    getWindow(ticket){
      let queue;
      for (let i = 0; i < ticket?.queue.length; i++){
        console.log(ticket)
        if (ticket?.queue[i].status == 'ACTIVE'){
          queue = ticket?.queue[i];
        }
      }
      console.log(queue)
      return queue?.queueManager?.queueWindow?.name
    },
    getQueueGroups() {
      HTTP.get(Settings.API.URL_DEFAULT + "/virtual-queue/queue-groups")
          .then((response) => {
            this.queueGroups = response.data
            // console.log(response)
          }).catch(() => {
      })
    },
    getCurrentUserInfo() {
      if (this.$store.getters.selectedStudent?.id != undefined ||
          this.$store.getters.selectedStudent?.id != null ||
          this.$store.getters.selectedStudent?.groups?.queueGroups != undefined ||
          this.$store.getters.selectedStudent?.groups?.queueGroups != null
      ) {
        this.clientName = this.$store.getters.selectedStudent.student
        // this.queueGroup = this.$store.getters.selectedStudent.groups.queueGroups
        this.user = this.$store.getters.selectedUser
        this.isStudent = true
      }
      this.disabledNewClient = false
      this.loadedNewClient = false
      this.qetQueueTicket()
    },
    postQueueClient() {
      HTTP.post(Settings.API.URL_DEFAULT + "/virtual-queue/queue-clients/new-client", {
        name: this.clientName,
        user: this.user,
        queueGroups: this.queueGroup
      })
          .then((response) => {
            this.queueTicket = response.data
            this.setQueueTicket(response.data)
            // console.log(response)
          }).catch(() => {
      })
    },
    setQueueTicket(ticket) {
      if (ticket?.id !== undefined && ticket?.id !== null) {
        localStorage.setItem('queueTicket', ticket?.id)
        this.disabledNewClient = true
      }
    },

    qetQueueTicket(){
      if(localStorage.getItem('queueTicket') != undefined){
        HTTP.get(Settings.API.URL_DEFAULT + "/virtual-queue/queue-ticket/" + localStorage.getItem('queueTicket'))
            .then((response) => {
              this.queueTicket = response.data
              console.log(response)
            }).catch(() => {
        })
      }
    },
    deleteTicket(){
      if(this.queueTicket.id != undefined && this.queueTicket.id != null){
        HTTP.delete(Settings.API.URL_DEFAULT + "/virtual-queue/queue-ticket/" + this.queueTicket.id)
            .then(() => {
              localStorage.removeItem('queueTicket')
              this.queueTicket.id = null
              // console.log(response)
            }).catch(() => {
        })
      }
    }
  },
  created() {
    this.getQueueGroups();
    setTimeout(this.getCurrentUserInfo, 2000);
    setInterval(this.getCurrentUserInfo, 5000);
  }
}
</script>

<style scoped>
.letter {
  font-size: 300%;
  color: rgb(1, 81, 136); /* Цвет ссылки */
}

.ltr {
  color: cornflowerblue;
  font-size: 355%;
}
</style>