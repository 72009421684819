<template>
  <v-card elevation="0">


    <v-container>
      <v-expansion-panels
          v-model="panel"
          multiple
      >
        <v-expansion-panel>
          <v-expansion-panel-header><h2><span class="letter">ВЫБЕРИТЕ НАПРАВЛЕНИЕ</span></h2></v-expansion-panel-header>
          <v-expansion-panel-content>

            <v-card elevation="0">
              <v-card-title>
                <v-table>
                  <v-row >
                    <v-col cols="16" xs="16" sm="16"  md="4" align="right"><span class="letter">Номер окна:</span> </v-col>
                    <v-col  cols="16" xs="16" sm="16"  md="2">
                      <v-autocomplete
                          v-model="queueWindow"
                          :items="queueWindows"
                          dense

                          item-text="name"
                          item-value="id"
                          return-object
                          ></v-autocomplete>
                    </v-col>
                    <v-col  cols="16" xs="16" sm="16" md="1"></v-col>
                    <v-col ccols="16" xs="16" sm="16" md="3" align="right"><v-btn
                        block
                        color="primary"
                        elevation="7"
                        fab
                        small
                        outlined
                        rounded
                        :disabled="disabledNewClient"
                        :loading="loadedNewClient"
                        @click="postQueueClient"
                    >Выбрать
                    </v-btn></v-col>
                    <v-col ></v-col>
                  </v-row>

                </v-table>

              </v-card-title>
              <v-simple-table>
                <template v-slot:default>
                  <thead>
                  <tr>
                    <th></th>
                    <th class="text-left">
                      <h2>
                        Направление
                      </h2>
                    </th>
                    <th class="text-left">
                      <h2>
                        Всего в очереди
                      </h2>
                    </th>
                    <th class="text-left">
                      <h2>
                        Ответственный
                      </h2>
                    </th>

                    <th class="text-center">
                      <h2>
                        Взять в работу
                      </h2>
                    </th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr
                      v-for="item in queueGroupsR"
                      :key="item.id"
                      class="text-left"
                  >
                    <td></td>
                    <td><span class="ltr">{{ item.name }}</span></td>
                    <td><span class="ltr">{{ item.queueTicket.length }}</span></td>
                    <td>
                      <div v-for="oper in item.queueManagers" :key="oper.id">
                        <span class="ltr">{{ oper.user.username }}</span>
                      </div>
                    </td>

                    <td align="center">
                      <v-btn v-if="queueManger.id != undefined && queueManger.id != null && !item.isWork"
                             icon
                             @click="takeToWork(item.id)"
                      >
                        <v-icon onclick="" color="#015188">work</v-icon>
                      </v-btn>
                      <v-btn v-if="queueManger.id != undefined && queueManger.id != null && item.isWork"
                             icon
                             @click="removeFromWork(item.id)"
                      >
                        <v-icon onclick="" color="#015188">close</v-icon>
                      </v-btn>
                    </td>
                  </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-card>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel>
          <v-expansion-panel-header><h2><span class="letter">ЗАЯВКА В ОЧЕРЕДИ</span></h2></v-expansion-panel-header>
          <v-expansion-panel-content>

            <v-container v-if="queue.id != undefined && queue.id != null">
              <v-card elevation="0">
                <v-row>
                  <v-col></v-col>
                </v-row>

                <v-row>

                  <v-col>
                    <div class="d-flex justify-content-center">
                      <p></p>
                      <v-list-item-title align="center"><span
                          class="ltr"> <b>Номер заявки: {{
                          queue.queueTicket.num
                        }}{{ queue.queueTicket.queueGroups.letter }}</b></span>
                      </v-list-item-title>
                      <p></p>
                    </div>
                  </v-col>


                </v-row>

                <v-row>
                  <v-col>
                    <div class="d-flex justify-content-center">
                      <v-list-item-title align="center"><span
                          class="ltr">ФИО: <b>{{ queue.queueTicket.queueClient.name }}</b> </span></v-list-item-title>
                      <p></p>
                    </div>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col>
                    <div class="d-flex justify-content-center">
                      <v-list-item-title align="center"><span class="ltr">Направление:
                    <b>{{ queue.queueTicket.queueGroups.name }}</b> </span></v-list-item-title>
                      <p></p>
                    </div>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col>
                    <div class="d-flex justify-content-center">
                      <v-list-item-title align="center"><span class="ltr">Группа: <b></b></span></v-list-item-title>
                      <p></p>
                    </div>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col>
                    <div class="d-flex justify-content-center">
                      <v-list-item-title align="CENTER"><span class="ltr">Готовые справки: <b></b></span>
                      </v-list-item-title>
                      <p></p>
                    </div>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col></v-col>
                </v-row>
                <v-row>
                  <v-col></v-col>
                  <v-col>
                    <v-btn
                        block
                        color="red"
                        elevation="7"
                        fab
                        outlined
                        rounded
                        @click="denialQueue()"
                    >Отказать
                    </v-btn>

                  </v-col>
                  <v-col></v-col>
                  <v-col>
                    <v-btn
                        block
                        color="primary"
                        elevation="7"
                        fab
                        outlined
                        rounded
                        @click="returnQueue()"
                    >Вернуть
                    </v-btn>
                  </v-col>
                  <v-col></v-col>
                  <v-col>
                    <v-btn
                        block
                        color="green"
                        elevation="7"
                        fab
                        outlined
                        rounded
                        @click="done()"
                    >Завершить
                    </v-btn>
                  </v-col>
                  <v-col></v-col>
                </v-row>

                <v-row>
                  <v-col></v-col>
                </v-row>
              </v-card>
            </v-container>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
      <v-spacer></v-spacer>


    </v-container>
    <v-speed-dial v-model="fab" bottom right absolute>
      <template #activator>
        <v-btn fab v-model="fab" :color="getColor()">
          <v-icon v-if="fab" color="white">close</v-icon>
          <v-icon v-else color="white">add</v-icon>
        </v-btn>

        <!-- from current official vuetify documentation -->
      </template>

      <v-btn fab small color="red" @click="isStop = true">
        <v-icon color="white">stop</v-icon>
      </v-btn>
      <v-btn fab small color="green" @click="isReady = true">
        <v-icon color="white">play_arrow</v-icon>
      </v-btn>

    </v-speed-dial>

    <v-dialog v-model="isReady" max-width="250">
      <v-card>
        <v-card-text>Начать обработку новых заявок очереди?</v-card-text>
        <v-card-actions>
          <v-btn
              @click="play" block>Начать
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="isStop" max-width="250">
      <v-card>
        <v-card-text>Завершить работу?</v-card-text>
        <v-card-actions>
          <v-btn @click="stop" block>Завершить</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>


  </v-card>
</template>
//OperatorQueue
<script>
import {HTTP} from '@/settings/axios'
import Settings from "@/settings/settings_url";

export default {
  name: 'OperatorQueue',
  data: () => ({
    panel: [0, 1],
    queueWindow: {},
    queueWindows: [1,3,4,5,6],

    fab: false,
    isReady: false,
    isStop: false,

    queueGroups: [],
    queueGroupsR: [],
    queueManger: {
      id: null
    },
    queue: {
      queueTicket: {
        num: "",
        queueClient: {
          name: ""
        },
        queueGroups: {
          name: "",
          letter: ""
        }
      }
    },
  }),
  watch: {
    'queueManger.id': function (val) {
      this.workC(val)
    },
  },
  methods: {
    getQueue() {
      if (this.queueManger.id != null && (this.queue.id == undefined || this.queue.id == null)) {
        HTTP.get(Settings.API.URL_DEFAULT + "/virtual-queue/queue-manger/" + this.queueManger.id + "/queue")
            .then((response) => {
              if (response.data.id != undefined && response.data.id != null) {
                console.log(response)
                this.queue = response.data
                this.panel = [1, 1]
              }
            }).catch(() => {
        })
      }

    },
    getColor() {
      if (this.queueManger.status == 'ACTIVE' || this.queueManger.status == "BUSY") {
        return 'primary'
      }
      return "red"
    },
    workC(val) {
      if (val != null && this.queueGroups.length > 0) {
        for (let i = 0; i < this.queueGroups.length; i++) {
          for (let k = 0; k < this.queueGroups[i].queueManagers.length; k++) {
            if (this.queueGroups[i].queueManagers[k].id == this.queueManger.id) {
              this.queueGroups[i].isWork = true
            } else {
              this.queueGroups[i].isWork = false
            }
          }
        }
      }
      this.queueGroupsR = this.queueGroups
    },

    play() {
      HTTP.get(Settings.API.URL_DEFAULT + "/virtual-queue/queue-manger/" + this.queueManger.id + "/start-work")
          .then((response) => {
            this.queueManger = response.data
          }).catch(() => {
      })
      this.isReady = false
    },
    stop() {
      HTTP.get(Settings.API.URL_DEFAULT + "/virtual-queue/queue-manger/" + this.queueManger.id + "/stop-work")
          .then((response) => {
            this.queueManger = response.data
            this.queue = {
              queueTicket: {
                num: "",
                queueClient: {
                  name: ""
                },
                queueGroups: {
                  name: "",
                  letter: ""
                }
              }
            }
          }).catch(() => {
      })
      this.isStop = false
    },
    takeToWork(groupId) {
      HTTP.get(Settings.API.URL_DEFAULT + "/virtual-queue/queue-groups/" + groupId + "/take-to-work")
          .then((response) => {
            console.log(response)
            this.getQueueGroups()
          }).catch(() => {
      })
    }
    ,
    removeFromWork(groupId) {
      HTTP.get(Settings.API.URL_DEFAULT + "/virtual-queue/queue-groups/" + groupId + "/remove-from-work")
          .then((response) => {
            console.log(response)
            this.getQueueGroups()
          }).catch(() => {
      })
    }
    ,
    updateWorkSpace() {
      this.getQueueGroups()
      if (this.queueManger?.id == undefined || this.queueManger?.id == null) {
        this.initManger()
      }
    }
    ,
    getQueueGroups() {
      HTTP.get(Settings.API.URL_DEFAULT + "/virtual-queue/queue-groups/detail")
          .then((response) => {
            this.queueGroups = response.data
            console.log(response)
          }).catch(() => {
      }).finally(() => {
        this.workC(this.queueManger.id)
      })
    }
    ,
    initManger() {
      HTTP.get(Settings.API.URL_DEFAULT + "/virtual-queue/queue-manger/init")
          .then((response) => {
            this.queueManger = response.data
            console.log(response)
          }).catch(() => {
      })
    }
    ,
    done() {
      if (this.queue.id != undefined && this.queue.id != null) {
        HTTP.get(Settings.API.URL_DEFAULT + "/virtual-queue/queue/" + this.queue.id + "/done")
            .then(() => {
              this.queue = {
                queueTicket: {
                  num: "",
                  queueClient: {
                    name: ""
                  },
                  queueGroups: {
                    name: "",
                    letter: ""
                  }
                }
              }
            }).catch(() => {
        })
      }
    }
    ,
    returnQueue() {
      if (this.queue.id != undefined && this.queue.id != null) {
        HTTP.get(Settings.API.URL_DEFAULT + "/virtual-queue/queue/" + this.queue.id + "/return")
            .then(() => {
              this.queue = {
                queueTicket: {
                  num: "",
                  queueClient: {
                    name: ""
                  },
                  queueGroups: {
                    name: "",
                    letter: ""
                  }
                }
              }
            }).catch(() => {
        })
      }
    }
    ,
    denialQueue() {
      if (this.queue.id != undefined && this.queue.id != null) {
        HTTP.get(Settings.API.URL_DEFAULT + "/virtual-queue/queue/" + this.queue.id + "/denial")
            .then(() => {
              this.queue = {
                queueTicket: {
                  num: "",
                  queueClient: {
                    name: ""
                  },
                  queueGroups: {
                    name: "",
                    letter: ""
                  }
                }
              }
            }).catch(() => {
        })
      }
    }
    ,
  },
  mounted() {
    setInterval(this.getQueue, 5000);
    setInterval(this.updateWorkSpace, 5000);
  },
  created() {
    this.updateWorkSpace()
  },

}
</script>

<style scoped>
.letter {

  color: rgb(1, 81, 136); /* Цвет ссылки */
}

.ltr {
  font-size: 135%
}


</style>