import { render, staticRenderFns } from "./CompensationPointsTransfer.vue?vue&type=template&id=327fcb4c&scoped=true&"
import script from "./CompensationPointsTransfer.vue?vue&type=script&lang=js&"
export * from "./CompensationPointsTransfer.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "327fcb4c",
  null
  
)

export default component.exports