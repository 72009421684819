<template>
  <v-dialog v-model="dialog"
            max-width="600px">
    <v-card>
      <v-toolbar
          dark
          color="primary"
      >
        <v-btn
            icon
            dark
            @click="closeDialog()"
        >
          <v-icon>close</v-icon>
        </v-btn>
        <v-card-title>Редактировать компенсацию</v-card-title>
      </v-toolbar>
      <v-progress-linear
          color="primary"
          :indeterminate="isBeingEdited"
          :active="isBeingEdited"
      />
      <v-container>
        <v-card-text>

          <v-form
              ref="form"
              v-model="valid"
          >
            <v-text-field
                v-model="name"
                outlined
                dense
                :rules="nameRules"
                label="Название компенсации"
                required
            />
            <v-text-field
                type="number"
                v-model="pointsLimit"
                outlined
                dense
                :rules="pointsLimitRules"
                label="Максимальное количество баллов"
                required
            />
          </v-form>

        </v-card-text>
        <v-divider/>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              text
              color="secondary"
              @click="closeDialog()"
          >
            Закрыть
          </v-btn>
          <v-btn
              text
              :disabled="!valid || isBeingEdited"
              :loading="isBeingEdited"
              color="primary"
              @click=editType()
          >
            Сохранить
          </v-btn>
        </v-card-actions>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import {HTTP} from "@/settings/axios";
import Settings from "@/settings/settings_url";

export default {
  name: "CompensationTypeEdit",

  data() {
    return {
      isBeingEdited: false,
      dialog: false,
      valid: false,
      maxNameLength: 255,
      name: null,
      nameRules: [
        v => !!v || 'Это поле обязательно',
        v => v.length < this.maxNameLength || 'Слишком длинное название. Введите меньше ' + this.maxNameLength + ' символов'
      ],
      pointsLimit: null,
      pointsLimitRules: [
        v => !!v || 'Это поле обязательно',
        v => v > 0 || 'Введите число выше 0',
      ],
    }
  },
  methods: {
    editType() {
      this.isBeingEdited = true

      this.compensationType.name = this.name
      this.compensationType.pointsLimit = this.pointsLimit

      HTTP.post(Settings.API.URL_DEFAULT + "/compensation-type/edit", this.compensationType)
          .then((response) => {
            this.compensationType = response.data
            this.$emit('editedType')
            this.$emit('success', 'Компенсация успешно изменена')
          })
          .catch(() => {
            this.$emit('error', 'Ошибка изменения')
          })
          .finally(() => {
            this.closeDialog()
            this.isBeingEdited = false
          });
    },
    closeDialog() {
      this.dialog = false
    },
  },
  props: {
    compensationType: {},
  },
  watch: {
    'compensationType'() {
      this.name = this.compensationType.name
      this.pointsLimit = this.compensationType.pointsLimit
    },
  },
}
</script>

<style scoped>

</style>