<template>
  <v-dialog v-model="createOrionDialog" max-width="800">
    <v-card>
      <v-toolbar
          dark
          color="primary"
      >
        <v-btn
            icon
            dark
            @click="createOrionDialog = false"
        >
          <v-icon>close</v-icon>
        </v-btn>
        <v-card-title>Создать Orion</v-card-title>
      </v-toolbar>
      <v-card-text v-if="cardStudent != null">
        <v-text-field v-model="cardStudent.name" label="Фамилия"/>
        <v-text-field v-model="cardStudent.firstName" label="Имя"/>
        <v-text-field v-model="cardStudent.midName" label="Отчество"/>
        <v-text-field v-model="cardStudent.oneCCode" label="1C ID"/>
        <v-autocomplete v-model="faculty" :items="faculties" label="Факультет"/>
        <v-autocomplete v-model="cardStudent.direction" :items="specialties" label="Специальность"/>
      </v-card-text>

      <v-card-actions>
        <v-spacer/>
        <v-btn
            color="primary"
            :disabled="loading"
            @click="createOrion"
        >
          Создать
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {HTTP} from "@/settings/axios";
import Settings from "@/settings/settings_url";

export default {
  name: "CreateOrion",

  data() {
    return {
      loading: false,
      createOrionDialog: false,
      cardStudent: {},
      faculties: [],
      specialties: [],
      faculty: null,
    }
  },
  methods: {
    getFaculties() {
      HTTP.get(Settings.API.URL_DEFAULT
          + "/groups/get-faculties")
          .then((response) => {
            this.faculties = response.data
          })
          .catch(() => {

          })
          .finally(() => {
            this.loading = false
          });
    },
    getSpecialties() {
      this.loading = true
      let url = ""
      if(this.faculty != null) {
        url = "?faculty=" + this.faculty
      }

      HTTP.get(Settings.API.URL_DEFAULT + "/groups/get-specialties" + url)
          .then((response) => {
            console.log(response.data)
            this.specialties = response.data
          })
          .finally(() => {
            this.loading = false
          })
    },
    closeDialog() {
      this.createOrionDialog = false
    },
    createOrion() {
      this.loading = true
      this.cardStudent.faculty = this.faculty

      HTTP.post(Settings.API.URL_DEFAULT + "/users-orion", this.cardStudent)
          .then(() => {
            this.$emit("orionCreated")
          }).catch((err) => {
        console.log(err)
      }).finally(() => {
        this.loading = false
        this.closeDialog()
      })
    },
  },
  created() {
    this.getFaculties()
    this.getSpecialties()
  },
  watch: {
    'faculty'() {
      this.getSpecialties()
    },
  },
}
</script>

<style scoped>

</style>