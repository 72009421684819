var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('div',{staticStyle:{"text-align":"center","margin-bottom":"10px","font-size":"200%"}},[_c('h1',[_vm._v("Институт права. Расписание на 15.04.2021 года. ")])]),_c('v-layout',{attrs:{"align-start":"","justify-start":"","row":"","wrap":""}},[_c('v-flex',{staticClass:"schedule-rows"},[_c('div',{staticClass:"divider-curse"},[_vm._v("1 Курс")])]),_vm._l((_vm.item_card),function(item,i){return _c('v-flex',{key:i},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('div',[_c('v-card',{staticClass:"my-3 mx-3 card-main-schedule",attrs:{"loading":_vm.loading,"elevation":hover ? 24 : 6}},[_c('template',{slot:"progress"},[_c('v-progress-linear',{attrs:{"color":"deep-purple","height":"10","indeterminate":""}})],1),_c('div',[_c('v-data-table',{staticClass:"elevation-2",attrs:{"headers":_vm.scheduleGrid.headers,"items":_vm.scheduleGrid.items,"items-per-page":80,"hide-default-footer":true,"hide-default-header":true},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var props = ref.props;
return [_c('thead',{staticClass:"v-data-table-header"},[_c('tr',_vm._l((props.headers),function(head){return _c('th',{key:head.value,staticClass:"text-center  th-date-main-schedule"},[_c('h3',[_vm._v(_vm._s(head.text))])])}),0)])]}},{key:"item",fn:function(ref){
var item = ref.item;
return _vm._l((item.subs),function(subitem,iSub){return _c('tr',{key:iSub},[_c('td',{staticClass:"td-sub-main-schedule time"},[_c('div',{staticClass:"content-sub-main-schedule"},[_vm._v(" "+_vm._s(subitem.time)+" ")])]),_c('td',{staticClass:"td-sub-main-schedule pair"},[_c('div',{staticClass:"content-sub-main-schedule discipline"},[_vm._v(" "+_vm._s(subitem.discipline)+" ")]),_c('div',{staticClass:"content-sub-main-schedule type"},[_vm._v(" ("+_vm._s(subitem.type)+") ")]),_c('div',{staticClass:"content-sub-main-schedule"},_vm._l((subitem.teachers),function(teacher){return _c('span',{key:teacher},[_vm._v(_vm._s(teacher)+", ")])}),0),_c('div',{staticClass:"content-sub-main-schedule"},[_vm._v(" Аудитория: "),_vm._l((subitem.audiences),function(audience){return _c('span',{key:audience},[_vm._v(_vm._s(audience)+", ")])})],2)])])})}}],null,true)})],1)],2)],1)]}}],null,true)})],1)}),_c('v-flex',{staticClass:"schedule-rows"},[_c('div',{staticClass:"divider-curse"},[_vm._v("2 Курс")])]),_vm._l((_vm.item_card2),function(item,i){return _c('v-flex',{key:i},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('div',[_c('v-card',{staticClass:"my-3 mx-3 card-main-schedule",attrs:{"loading":_vm.loading,"elevation":hover ? 24 : 6}},[_c('template',{slot:"progress"},[_c('v-progress-linear',{attrs:{"color":"deep-purple","height":"10","indeterminate":""}})],1),_c('div',[_c('v-data-table',{staticClass:"elevation-2",attrs:{"headers":_vm.scheduleGrid.headers,"items":_vm.scheduleGrid.items,"items-per-page":80,"hide-default-footer":true,"hide-default-header":true},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var props = ref.props;
return [_c('thead',{staticClass:"v-data-table-header"},[_c('tr',_vm._l((props.headers),function(head){return _c('th',{key:head.value,staticClass:"text-center  th-date-main-schedule"},[_c('h3',[_vm._v(_vm._s(head.text))])])}),0)])]}},{key:"item",fn:function(ref){
var item = ref.item;
return _vm._l((item.subs),function(subitem,iSub){return _c('tr',{key:iSub},[_c('td',{staticClass:"td-sub-main-schedule time"},[_c('div',{staticClass:"content-sub-main-schedule"},[_vm._v(" "+_vm._s(subitem.time)+" ")])]),_c('td',{staticClass:"td-sub-main-schedule pair"},[_c('div',{staticClass:"content-sub-main-schedule discipline"},[_vm._v(" "+_vm._s(subitem.discipline)+" ")]),_c('div',{staticClass:"content-sub-main-schedule type"},[_vm._v(" ("+_vm._s(subitem.type)+") ")]),_c('div',{staticClass:"content-sub-main-schedule"},_vm._l((subitem.teachers),function(teacher){return _c('span',{key:teacher},[_vm._v(_vm._s(teacher)+", ")])}),0),_c('div',{staticClass:"content-sub-main-schedule"},[_vm._v(" Аудитория: "),_vm._l((subitem.audiences),function(audience){return _c('span',{key:audience},[_vm._v(_vm._s(audience)+", ")])})],2)])])})}}],null,true)})],1)],2)],1)]}}],null,true)})],1)}),_c('v-flex',{staticClass:"schedule-rows"},[_c('div',{staticClass:"divider-curse"},[_vm._v("3 Курс")])]),_vm._l((_vm.item_card3),function(item,i){return _c('v-flex',{key:i},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('div',[_c('v-card',{staticClass:"my-3 mx-3 card-main-schedule",attrs:{"loading":_vm.loading,"elevation":hover ? 24 : 6}},[_c('template',{slot:"progress"},[_c('v-progress-linear',{attrs:{"color":"deep-purple","height":"10","indeterminate":""}})],1),_c('div',[_c('v-data-table',{staticClass:"elevation-2",attrs:{"headers":_vm.scheduleGrid.headers,"items":_vm.scheduleGrid.items,"items-per-page":80,"hide-default-footer":true,"hide-default-header":true},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var props = ref.props;
return [_c('thead',{staticClass:"v-data-table-header"},[_c('tr',_vm._l((props.headers),function(head){return _c('th',{key:head.value,staticClass:"text-center  th-date-main-schedule"},[_c('h3',[_vm._v(_vm._s(head.text))])])}),0)])]}},{key:"item",fn:function(ref){
var item = ref.item;
return _vm._l((item.subs),function(subitem,iSub){return _c('tr',{key:iSub},[_c('td',{staticClass:"td-sub-main-schedule time"},[_c('div',{staticClass:"content-sub-main-schedule"},[_vm._v(" "+_vm._s(subitem.time)+" ")])]),_c('td',{staticClass:"td-sub-main-schedule pair"},[_c('div',{staticClass:"content-sub-main-schedule discipline"},[_vm._v(" "+_vm._s(subitem.discipline)+" ")]),_c('div',{staticClass:"content-sub-main-schedule type"},[_vm._v(" ("+_vm._s(subitem.type)+") ")]),_c('div',{staticClass:"content-sub-main-schedule"},_vm._l((subitem.teachers),function(teacher){return _c('span',{key:teacher},[_vm._v(_vm._s(teacher)+", ")])}),0),_c('div',{staticClass:"content-sub-main-schedule"},[_vm._v(" Аудитория: "),_vm._l((subitem.audiences),function(audience){return _c('span',{key:audience},[_vm._v(_vm._s(audience)+", ")])})],2)])])})}}],null,true)})],1)],2)],1)]}}],null,true)})],1)}),_c('v-flex',{staticClass:"schedule-rows"},[_c('div',{staticClass:"divider-curse"},[_vm._v("4 Курс")])]),_vm._l((_vm.item_card4),function(item,i){return _c('v-flex',{key:i},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('div',[_c('v-card',{staticClass:"my-3 mx-3 card-main-schedule",attrs:{"loading":_vm.loading,"elevation":hover ? 24 : 6}},[_c('template',{slot:"progress"},[_c('v-progress-linear',{attrs:{"color":"deep-purple","height":"10","indeterminate":""}})],1),_c('div',[_c('v-data-table',{staticClass:"elevation-2",attrs:{"headers":_vm.scheduleGrid.headers,"items":_vm.scheduleGrid.items,"items-per-page":80,"hide-default-footer":true,"hide-default-header":true},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var props = ref.props;
return [_c('thead',{staticClass:"v-data-table-header"},[_c('tr',_vm._l((props.headers),function(head){return _c('th',{key:head.value,staticClass:"text-center  th-date-main-schedule"},[_c('h3',[_vm._v(_vm._s(head.text))])])}),0)])]}},{key:"item",fn:function(ref){
var item = ref.item;
return _vm._l((item.subs),function(subitem,iSub){return _c('tr',{key:iSub},[_c('td',{staticClass:"td-sub-main-schedule time"},[_c('div',{staticClass:"content-sub-main-schedule"},[_vm._v(" "+_vm._s(subitem.time)+" ")])]),_c('td',{staticClass:"td-sub-main-schedule pair"},[_c('div',{staticClass:"content-sub-main-schedule discipline"},[_vm._v(" "+_vm._s(subitem.discipline)+" ")]),_c('div',{staticClass:"content-sub-main-schedule type"},[_vm._v(" ("+_vm._s(subitem.type)+") ")]),_c('div',{staticClass:"content-sub-main-schedule"},_vm._l((subitem.teachers),function(teacher){return _c('span',{key:teacher},[_vm._v(_vm._s(teacher)+", ")])}),0),_c('div',{staticClass:"content-sub-main-schedule"},[_vm._v(" Аудитория: "),_vm._l((subitem.audiences),function(audience){return _c('span',{key:audience},[_vm._v(_vm._s(audience)+", ")])})],2)])])})}}],null,true)})],1)],2)],1)]}}],null,true)})],1)}),_c('v-flex',{staticClass:"schedule-rows"},[_c('div',{staticClass:"divider-curse"},[_vm._v("5 Курс")])]),_vm._l((_vm.item_card5),function(item,i){return _c('v-flex',{key:i},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('div',[_c('v-card',{staticClass:"my-3 mx-3 card-main-schedule",attrs:{"loading":_vm.loading,"elevation":hover ? 24 : 6}},[_c('template',{slot:"progress"},[_c('v-progress-linear',{attrs:{"color":"deep-purple","height":"10","indeterminate":""}})],1),_c('div',[_c('v-data-table',{staticClass:"elevation-2",attrs:{"headers":_vm.scheduleGrid.headers,"items":_vm.scheduleGrid.items,"items-per-page":80,"hide-default-footer":true,"hide-default-header":true},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var props = ref.props;
return [_c('thead',{staticClass:"v-data-table-header"},[_c('tr',_vm._l((props.headers),function(head){return _c('th',{key:head.value,staticClass:"text-center  th-date-main-schedule"},[_c('h3',[_vm._v(_vm._s(head.text))])])}),0)])]}},{key:"item",fn:function(ref){
var item = ref.item;
return _vm._l((item.subs),function(subitem,iSub){return _c('tr',{key:iSub},[_c('td',{staticClass:"td-sub-main-schedule time"},[_c('div',{staticClass:"content-sub-main-schedule"},[_vm._v(" "+_vm._s(subitem.time)+" ")])]),_c('td',{staticClass:"td-sub-main-schedule pair"},[_c('div',{staticClass:"content-sub-main-schedule discipline"},[_vm._v(" "+_vm._s(subitem.discipline)+" ")]),_c('div',{staticClass:"content-sub-main-schedule type"},[_vm._v(" ("+_vm._s(subitem.type)+") ")]),_c('div',{staticClass:"content-sub-main-schedule"},_vm._l((subitem.teachers),function(teacher){return _c('span',{key:teacher},[_vm._v(_vm._s(teacher)+", ")])}),0),_c('div',{staticClass:"content-sub-main-schedule"},[_vm._v(" Аудитория: "),_vm._l((subitem.audiences),function(audience){return _c('span',{key:audience},[_vm._v(_vm._s(audience)+", ")])})],2)])])})}}],null,true)})],1)],2)],1)]}}],null,true)})],1)})],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }