<template>
  <div>

    <div>
      <v-btn
          color="primary"
          dark
          @click="newItem"
      >
        Добавить
      </v-btn>
    </div>

    <template>
      <v-row justify="center">
        <v-dialog
            v-model="dialog"
            persistent
            max-width="600px"
        >
          <v-card>
            <v-card-title>
              <span class="headline">{{ title_dialog }}</span>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col
                      cols="12"
                      sm="12"
                      md="12"
                  >
                    <v-text-field
                        label="Название типа"
                        required
                        v-model="type"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                  color="blue darken-1"
                  text
                  @click="dialog = false"
              >
                Close
              </v-btn>
              <v-btn
                  color="blue darken-1"
                  text
                  @click="saveType()"
              >
                Save
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
    </template>

    <v-data-table
        :headers="headers"
        :items="desserts"
        :items-per-page="15"
        :hide-default-footer="false"
        class="elevation-2"
        :single-expand="true"
    >
      <template v-slot:item.linked="{ item }">
        <v-btn @click="editItem(item)" icon>
          <v-icon>settings</v-icon>
        </v-btn>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import Settings from "@/settings/settings_url";
import {HTTP} from '@/settings/axios'

export default {
  name: "AudiencesType",
  data() {
    return {
      headers: [
        {text: 'id', align: 'center', sortable: true, value: 'id', divider: true, class: "bolt"},
        {text: 'Наименование', align: 'center', value: 'name', divider: true, sortable: true, class: "bolt"},
        {text: 'Редактировать', value: 'linked', divider: true, align: 'center'}
      ],
      desserts: [],
      selected_audience: null,
      audiences: [],
      dialog: false,
      title_dialog: "",
      type: "",
      type_id: null,
      is_new: false
    };
  },
  methods: {
    editItem: function (item) {
      this.is_new = false
      this.clearItem()
      this.dialog = true
      this.title_dialog = "Редактировать тип"
      this.type = item.name
      this.type_id = item.id
    },
    newItem: function () {
      this.is_new = true
      this.clearItem()
      this.dialog = true
      this.title_dialog = "Создать новый тип"
    },
    saveType: function (){
      this.dialog = false
      if (this.is_new){
        this.createType()
      }else {
        this.updateType()
      }
    },
    updateType: async function () {
      await HTTP.put(Settings.API.URL_DEFAULT + '/audience-types/', {
        "id": this.type_id,
        "name": this.type
      })
          .then(() => {
          })

      await this.getAudiencesType()
    },
    createType: async function () {
      await HTTP.post(Settings.API.URL_DEFAULT + '/audience-types/', {
        "id": this.type_id,
        "name": this.type
      })
          .then(() => {
          })

      await this.getAudiencesType()
    },
    clearItem: function () {
      this.type = ""
      this.type_id = null
    },
    getAudiencesType: async function () {
      await HTTP.get(Settings.API.URL_DEFAULT + '/audience-types/')
          .then((response) => {
            this.desserts = response.data
          })
    }
  },
  created() {
    this.getAudiencesType()
  }
}
</script>

<style scoped>

</style>