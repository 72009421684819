<template>
  <v-container>
    <v-row>
      <v-col>
        <v-text-field
            label="Имя подгруппы"
            required
            v-model="subGroup.name"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-autocomplete v-model="group"
                        :items="groups"
                        :disabled="edit"
                        label="Группа"
                        dense
                        item-value="id"
                        item-text="name"
                        return-object

        >
        </v-autocomplete>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="mr-10">
        <v-autocomplete v-model="yearOfStudy"
                        :items="yearOfStudys"
                        :disabled="edit"
                        label="Год обучения"
                        dense
                        item-value="id"
                        item-text="name"
                        return-object
        >
        </v-autocomplete>
      </v-col>
      <v-col class="mr-10">
        <v-autocomplete v-model="halfYear"
                        :items="halfYears"
                        :disabled="edit"
                        label="Полугодие"
                        dense
                        item-value="id"
                        item-text="name"
                        return-object
        >
        </v-autocomplete>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-autocomplete v-model="subGroup.workload"
                        :items="workloads"
                        :disabled="edit"
                        label="Нагрузка"
                        dense
                        item-value="id"
                        item-text="itemName"
                        return-object
        >
        </v-autocomplete>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <div>Все студенты</div>
        <v-responsive
            class="overflow-y-auto"
            max-height="400"
        >
          <v-list
              subheader
              two-line
              flat
          >

            <!--            <v-list-item-group-->
            <!--                v-model="indexSelectedStudents"-->
            <!--                :value="selectStudents2"-->
            <!--                multiple-->
            <!--            >-->
            <v-list-item
                v-for="student in studentsDiff"
                :key="student.id"
                @click="addStudents(student)"
            >
              <!--                <template v-slot:default="{ active, }">-->
              <!--                  <v-list-item-action>-->
              <!--                    <v-checkbox-->
              <!--                        :input-value="active"-->
              <!--                        color="primary"-->
              <!--                    ></v-checkbox>-->
              <!--                  </v-list-item-action>-->

              <v-list-item-content>
                <v-list-item-title v-text="student.student"></v-list-item-title>
                <v-list-item-subtitle v-text="student.groups.name"></v-list-item-subtitle>
              </v-list-item-content>
              <!--                </template>-->
            </v-list-item>

            <!--            </v-list-item-group>-->
          </v-list>
        </v-responsive>
      </v-col>
      <v-col>
        <div>Выбранные студенты</div>
        <v-responsive
            class="overflow-y-auto"
            max-height="400"
        >
          <v-list
              subheader
              two-line
              flat
          >

            <!--            <v-list-item-group-->
            <!--                v-model="indexSelectedStudents"-->
            <!--                multiple-->
            <!--            >-->
            <v-list-item
                v-for="student in subGroup.students"
                :key="student.id"
                @click="rmStudents(student)"
            >
              <!--                <template v-slot:default="{ active, }">-->
              <!--                  <v-list-item-action>-->
              <!--                    <v-checkbox-->
              <!--                        :input-value="active"-->
              <!--                        color="primary"-->
              <!--                    ></v-checkbox>-->
              <!--                  </v-list-item-action>-->

              <v-list-item-content>
                <v-list-item-title v-text="student.student"></v-list-item-title>
                <v-list-item-subtitle v-text="student.groups.name"></v-list-item-subtitle>
              </v-list-item-content>
              <!--                </template>-->
            </v-list-item>

            <!--            </v-list-item-group>-->
          </v-list>
        </v-responsive>
      </v-col>

    </v-row>
    <v-row>
      <!--      <v-btn-->
      <!--          color="primary"-->
      <!--          dark-->
      <!--          class="ma-2"-->
      <!--          @click="saveSubGroup"-->
      <!--      >-->
      <!--        Сохранить-->
      <!--      </v-btn>-->
    </v-row>
  </v-container>
</template>

<script>
import Settings from "@/settings/settings_url";
import {HTTP} from '@/settings/axios'

export default {
  name: "SubGroupEditForm",
  props: {
    subGroup: Object,
    edit: Boolean,
  },
  data() {
    return {

      yearOfStudys: [],
      yearOfStudy: {},
      halfYears: [],
      halfYear: {},

      workloads: [],
      // selectedWorkload: {},
      // workload: {},
      groups: [],
      group: {},
      // selectGroups: [],

      students: [],
      // selectStudents:[],

      // teachers: [],

      // newSubGroup: {
      //   name: "",
      //   status: "ACTIVE",
      //   typesEducation: "",
      //   schedules: [],
      //   groups: [],
      //   students: [],
      //   controlPoints: [],
      //   workload: {},
      //   workloadUniqueInfo: {
      //     teachers: []
      //   },
      // },
    }
  },
  computed: {
    // eslint-disable-next-line vue/return-in-computed-property
    studentsDiff() {
      const selectStudent = this.subGroup.students
      // console.log(selectStudent)
      return this.students.filter(function (item) {
        for (let i = 0; i < selectStudent.length; i++) {
          if (selectStudent[i].gradeBook === item.gradeBook) {
            return false;
          }
        }
        return true
      })
    }
  },
  watch: {
    // 'subGroup.students'(){
    //   const selectStudent = this.subGroup.students
    //   this.students =  this.students.filter(function (item) {
    //     return !selectStudent.includes(item)
    //   })
    // },
    // edit(){
    //   const selectStudent = this.subGroup.students
    //   this.students =  this.students.filter(function (item) {
    //     return !selectStudent.includes(item)
    //   })
    // },
    halfYear() {
      // this.students = []
      this.workloads = []
      this.getWorkloads()
    },
    yearOfStudy() {
      // console.log(this.subGroup)
      // this.students = []
      this.workloads = []
      this.getWorkloads()
    },
    // 'subGroup.workloadUniqueInfo'() {
    //     this.subGroup.group = []
    //     this.students = []
    //     if (this.subGroup.workloadUniqueInfo.hours != undefined) {
    //       this.getGroup()
    //     }
    // },
    subGroup(){
      // console.log("this.create")
      if (!this.edit) {
        this.subGroup.students = []
      }
      this.group = this.subGroup?.workload?.workPlan?.group
      this.students = []
      this.workloads = []
      // this.subGroup.workloadUniqueInfo.teachers = []
      // this.getTeachers()
      // if (this.subGroup.group.length > 0) {
      // this.getStudents()
      // }
      this.getWorkloads()
    },
    'subGroup.id'(){
      // console.log(this.create)
      if (!this.edit) {
        this.subGroup.students = []
      }
      this.students = []
      this.workloads = []
      // this.subGroup.workloadUniqueInfo.teachers = []
      // this.getTeachers()
      // if (this.subGroup.group.length > 0) {
      // this.getStudents()
      // }
      this.getWorkloads()
    },
    // 'subGroup.workload'() {
    //   this.getStudentsNotInSubGroup()
    // },
    group() {
      // console.log(this.create)
      if (!this.edit) {
        this.subGroup.students = []
      }
      this.students = []
      this.workloads = []
      // this.subGroup.workloadUniqueInfo.teachers = []
      // this.getTeachers()
      // if (this.subGroup.group.length > 0) {
      // this.getStudents()
      // }
      this.getWorkloads()
    },
  },
  methods: {
    addStudents(item) {
      this.subGroup.students.push(item)
    },
    rmStudents(item) {
      const i = this.subGroup.students.indexOf(item)
      this.subGroup.students.splice(i, 1)
    },
    changeGroup(active) {
      return active
    },
    getHalfYear: async function () {
      await HTTP.get(Settings.API.URL_DEFAULT + "/half-year")
          .then((response) => {
            this.halfYears = response.data
          })
          .catch(() => {
            this.errored = true;
          })
          .finally(() => (this.loading = false));
    },
    getYearOfStudys: async function () {
      await HTTP.get(Settings.API.URL_DEFAULT + "/year-of-study")
          .then((response) => {
            this.yearOfStudys = response.data
          })
          .catch(() => {
            this.errored = true;
          })
          .finally(() => (this.loading = false));
    },
    // getUniqueInfoMainDp: async function () {
    //   await HTTP.get(Settings.API.URL_DEFAULT + "/workload/unique-info-main-dp?yearId=" + this.yearOfStudy.id + "&halfYearId=" + this.halfYear.id)
    //       .then((response) => {
    //         this.workloads = response.data
    //       })
    //       .catch(() => {
    //         this.errored = true;
    //       })
    //       .finally(() => (this.loading = false));
    // },
    getGroup: async function () {
      await HTTP.get(Settings.API.URL_DEFAULT + "/groups")
          .then((response) => {
            this.groups = response.data
          })
          .catch(() => {
            this.errored = true;
          })
          .finally(() => (this.loading = false));
    },
    // getStudents: async function () {
    //   const data = this.subGroup.group.map(function (item) {
    //     return item.id
    //   })
    //   await HTTP.post(Settings.API.URL_DEFAULT + "/students/by-group-in", data)
    //       .then((response) => {
    //         this.students = response.data
    //       })
    //       .catch(() => {
    //         this.errored = true;
    //       })
    //       .finally(() => (this.loading = false));
    // },
    // getStudents: async function () {

    //   await HTTP.get(Settings.API.URL_DEFAULT + "/groups/" + this.group.id + "/students")
    //       .then((response) => {
    //         this.students = response.data
    //       })
    //       .catch(() => {
    //         this.errored = true;
    //       })
    //       .finally(() => (this.loading = false));
    // },
    saveSubGroup: async function () {
      this.subGroup.typesEducation = this.group.typesEducation
      // console.log(this.subGroup)
      await HTTP.post(Settings.API.URL_DEFAULT + "/sub-groups/detail", this.subGroup)
          .then((response) => {
            // console.log(response.data)
            this.$emit('saveSuccess', response.data)
            //this.subGroup = response.data
          })
          .catch(() => {
            this.errored = true;
          })
          .finally(() => (this.loading = false));
    },
    getWorkloads: async function () {
      this.loading = true
      if (this.group.id != undefined && this.group.id != null &&
          this.yearOfStudy.id != undefined && this.yearOfStudy.id != null &&
          this.halfYear.id != undefined && this.halfYear.id != null) {
        await HTTP.get(Settings.API.URL_GROUPS_BRSO_CURRICULUM_BY_GROUPID_HALFYEAR(this.group.id, this.yearOfStudy.id, this.halfYear.id))
            .then((response) => {
              this.workloads = response.data
              // console.log(this.workloads)
            })
            .catch(() => {
              this.errored = true;
            })
            .finally(() => (this.loading = false));
      }

    },
    // getStudentsNotInSubGroup: async function () {
    //   this.loading = true
    //   console.log(Settings.API.URL_DEFAULT + "/students/by-not-in-sub-group?groupId=" + this.group.id +
    //       "&workloadId=" + this.subGroup.workload.id)
    //   await HTTP.get(Settings.API.URL_DEFAULT + "/students/by-not-in-sub-group?groupId=" + this.group.id +
    //   "&workloadId=" + this.subGroup.workload.id)
    //       .then((response) => {
    //         this.students = response.data
    //         console.log(this.students.length)
    //         console.log(response.data)
    //       })
    //       .catch(() => {
    //         this.errored = true;
    //       })
    //       .finally(() => (this.loading = false));
    // }
    // getTeachers: async function () {
    //   await HTTP.get(Settings.API.URL_DEFAULT + Settings.API.URL_TEACHERS)
    //       .then((response) => {
    //         this.teachers = response.data
    //       })
    //       .catch(() => {
    //         this.errored = true;
    //       })
    //       .finally(() => {
    //       });
    // },
  },
  async created() {
    await this.getHalfYear()
    await this.getYearOfStudys()
    await this.getGroup()
  },
  mounted() {
    // console.log(this.subGroup )
    if (this.edit) {
      this.group = this.subGroup.workload.workPlan.group
      this.yearOfStudy = this.subGroup.workload.workPlan.yearOfStudy
      this.halfYear = this.subGroup.workload.workPlan.halfYear
      // this.getWorkloads()
      // this.yearOfStudy = this.subGroup.workload.workPlan.halfYear
    }
  }
}
</script>

<style scoped>

</style>