<template>
  <v-container>
    <v-container>
    <v-row>
      <v-col/>
      <v-col class="text-center">
        <h3>«Добавление баллов студента за пропуски занятий по уважительной причине» </h3>
      </v-col>
      <v-col/>
    </v-row>
    </v-container>
    <v-row><v-col></v-col></v-row>
      <v-row>
        <v-col/>
        <v-col
          cols="4"
          sm="4"
          md="4"
      >
        <v-autocomplete
            v-model="year"
            :items="years"
            outlined
            dense
            item-text="name"
            item-value="id"
            return-object
            label="Год"
            prepend-icon="event"
        />
        <v-autocomplete
            v-model="halfYear"
            :items="halfYears"
            outlined
            dense
            item-text="name"
            item-value="id"
            return-object
            label="Полугодие"
            prepend-icon="event"
        />
        <v-autocomplete
            v-model="group"
            :items="groups"
            outlined
            dense
            item-text="name"
            item-value="id"
            return-object
            label="Группа"
            prepend-icon="groups"
        />
        <v-autocomplete
            v-model="stud"
            :items="studs"
            outlined
            dense
            label="Cтудент"
            item-text="student"
            item-value="id"
            return-object
            prepend-icon="person"
        />
<!--        <v-autocomplete-->
<!--            v-model="discipline"-->
<!--            :items="disciplines"-->
<!--            outlined-->
<!--            dense-->
<!--            multiple-->
<!--            label="Дисциплина"-->
<!--            item-text="name"-->
<!--            item-value="id"-->
<!--            return-object-->
<!--            prepend-icon="school"-->
<!--        />-->
        <!--        Дата -->
        <template>
          <v-row>

            <v-col
                cols="12"
                sm="6"
                md="5"
            >
              <v-menu
                  ref="menu1"
                  v-model="menu1"
                  :close-on-content-click="false"
                  :return-value.sync="date1"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
              >

                <template v-slot:activator="{ on, attrs }">
<!--                  <v-label>От:</v-label>-->
                  <v-text-field
                      :value="computedDate1Formatted"
                      label="Дата первого пропуска"
                      prepend-icon="event"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                    v-model="date1"
                    no-title
                    scrollable
                    show-adjacent-months
                    locale="ru-RU"
                    :first-day-of-week="1"
                    :max="getCurrentDate()"
                >
                  <v-spacer></v-spacer>
                  <v-btn
                      text
                      color="primary"
                      @click="menu1 = false"
                  >
                    Отмена
                  </v-btn>
                  <v-btn
                      text
                      color="primary"
                      @click="$refs.menu1.save(date1)"
                  >
                    Выбрать
                  </v-btn>
                </v-date-picker>
              </v-menu>
            </v-col>
            <v-spacer></v-spacer>
            <v-col
                cols="12"
                sm="6"
                md="5"
            >
              <v-menu
                  ref="menu2"
                  v-model="menu2"
                  :close-on-content-click="false"
                  :return-value.sync="date2"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                      :value="computedDate2Formatted"
                      label="Дата последнего пропуска"
                      prepend-icon="event"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                    v-model="date2"
                    no-title
                    scrollable
                    show-adjacent-months
                    locale="ru-RU"
                    :first-day-of-week="1"
                    :max="getCurrentDate()"
                >
                  <v-spacer></v-spacer>
                  <v-btn
                      text
                      color="primary"
                      @click="menu2 = false"
                  >
                    Отмена
                  </v-btn>
                  <v-btn
                      text
                      color="primary"
                      @click="$refs.menu2.save(date2)"
                  >
                    Выбрать
                  </v-btn>
                </v-date-picker>
              </v-menu>
            </v-col>
            <v-spacer></v-spacer>
          </v-row>
        </template>
<!--        Дата-->
<!--        <template>-->
<!--          <div class="block">-->
<!--            <span class="demonstration">Период пропущенных занятий</span>-->
<!--            <el-date-picker-->
<!--                v-model="datePass"-->
<!--                type="daterange"-->
<!--                range-separator="To"-->
<!--                start-placeholder="dateStart"-->
<!--                end-placeholder="dateAnd">-->
<!--            </el-date-picker>-->
<!--          </div>-->
<!--        </template>-->
<!--        Дата-->

        <v-col
        ></v-col>
        <v-row>
        <v-col
            cols="12"
            sm="6"
            md="4"
        ></v-col>
        <v-btn
            color="primary"
            @click="newItem"
            :disabled="updatingCompens || stud === null"
        >
          <v-icon left>add</v-icon>
          Добавить баллы
        </v-btn>
        </v-row>
          <v-row>
            <v-col class="text-center">
              <v-progress-circular v-show="updatingCompens" indeterminate color="primary"/>
            </v-col>
          </v-row>
        <v-row>
          <v-col>
            <v-alert :type="alertType" v-show="alert" dismissible transition="fade-transition">
              {{ alertText }}
            </v-alert>
          </v-col>
        </v-row>
      </v-col>
        <v-col/>
      </v-row>
    <v-divider/>
<!--    Таблица комментариев-->
    <v-container>
    <v-row>
      <v-col/>
      <v-col class="text-center">
        <h3>«Информация о проставленных баллах за пропуски занятий по уважительной причине» </h3>
      </v-col>
      <v-col/>
    </v-row>
    <v-row><v-col></v-col></v-row>
    <v-row>
      <v-col>
    <v-data-table
        :headers="headers"
        :items="itemsComment"
        :items-per-page="5"
        class="elevation-1"
        loading-text="Загрузка..."
        no-data-text="Нет информации"
    >
      <template v-slot:item.date="{ item }">
        {{ formatDateTime(item.date) }}
      </template>

      <template v-slot:item.dateStart="{ item }">
        {{ formatFullDate(item.dateStart) }}
      </template>

      <template v-slot:item.dateEnd="{ item }">
        {{ formatFullDate(item.dateEnd) }}
      </template>

      <template v-slot:item.cancel="{ item }">
        <v-btn block color="primary" v-if="item.comment === 'update'" :disabled="updatingCompens" @click="cancelCompensation(item)">
          <v-icon left>cancel</v-icon>
          Отменить
        </v-btn>
      </template>

<!--            <template v-slot:item.dateStart="{ item }">-->
<!--              <div class="hdsgf">-->
<!--                {{item.dateStart}}-->
<!--              </div>-->
<!--            </template>-->

<!--      <template v-slot:item.user="{ item }">-->
<!--        <v-btn>{{item.user.username}}</v-btn>-->

<!--      </template>-->


    </v-data-table>
    </v-col>
    </v-row>
    </v-container>
  </v-container>


</template>
<script>

import Settings from "@/settings/settings_url";
import {HTTP} from '@/settings/axios'
import date_utils from "@/settings/date_utils";

export default {
  name: "Exemption",
  data() {
    return {
      headers: [
        {
          text: 'Дата',
          align: 'center',
          sortable: false,
          value: 'date',
          divider: true,
          // class: "bolt"
        },
        {
          text: 'Дата первого пропуска',
          align: 'center',
          sortable: false,
          value: 'dateStart',
          divider: true,
          // class: "bolt"
        },
        {
          text: 'Дата последнего пропуска',
          align: 'center',
          sortable: false,
          value: 'dateEnd',
          divider: true,
          // class: "bolt"
        },
        {
          text: 'Группа',
          align: 'center',
          sortable: false,
          value: 'students.groups.name',
          divider: true,
          // class: "bolt"
        },
        {
          text: 'Студент',
          align: 'center',
          sortable: false,
          value: 'students.student',
          divider: true,
          // class: "bolt"
        },

        {
          text: 'Обновил',
          align: 'center',
          sortable: false,
          value: 'user.username',
          divider: true,
          // class: "bolt"
        },
        {
          text: 'Комментарии',
          align: 'center',
          sortable: false,
          value: 'comment',
          divider: true,
          // class: "bolt"
        },
        {
          text: 'Действия',
          align: 'center',
          sortable: false,
          value: 'cancel',
          divider: true,
          // class: "bolt"
        },
      ],
      itemsComment: [],

      values: "",
      year: undefined,
      years: [],

      halfYear: undefined,
      halfYears: [],

      group: {},
      groups:  [],
      divider: true,

      date1: new Date().toISOString().substr(0, 10),
      date2: new Date().toISOString().substr(0, 10),
      menu1: false,
      menu2: false,

      // datePass: new Date().toISOString().substr(0, 10),

      stud: null,
      studs: [],

      discipline: null,
      disciplines: [],

      alert: false,
      alertType: "success",
      alertText: null,
      updatingCompens: false,
    }
  },
  watch: {
    year: function (){
      // console.log(this.year)
      this.getGroups()
    },
    halfYear: function (){
      // console.log(this.halfYear)
      this.getGroups()
    },
    group:function (){
      this.getStudent()
    },
    stud:function (){
      this.getComment()
    },
    discipline: function() {
      this.getComment()
    },
  },
  computed: {
    computedDate1Formatted () {
      return date_utils.formatFullDate(this.date1)
    },
    computedDate2Formatted () {
      return date_utils.formatFullDate(this.date2)
    },
  },
  methods: {
    getCurrentDate() {
      return date_utils.formatDateUnix(new Date());
    },
    showAlertSuccess(message) {
      this.alert = true
      this.alertText = message
      setTimeout(this.hideAlertSuccess, 5000);
    },
    hideAlertSuccess() {
      this.alert = false
    },
    formatDateTime(date) {
      return date_utils.formatDateTime(date)
    },
    formatFullDate(date) {
      return date_utils.formatFullDate(date)
    },
    // minCalendarDate(){
    //
    // },

    newItem: function (){
        this.updateCompens()
    },
    test: function (){
      console.log(this.year)
    },
    getYear: function (){
      HTTP.get(Settings.API.URL_DEFAULT + "/year-of-study")
          .then((response) => {
            this.years = response.data.filter(function (item) {
              return item.current === 1 || item.id === 2
            })
          })
          .catch(() => {

          })
          .finally(() => {
            for (let i = 0; i < this.years.length; i++) {
              if (this.years[i]?.current == 1) {
                this.year = this.years[i]
                break
              }
            }
          });
    },

    getHalfYear: function (){
      HTTP.get(Settings.API.URL_DEFAULT + "/half-year")
          .then((response) => {
            this.halfYears = response.data
          })
          .catch(() => {

          })
          .finally(() => {
            for (let i = 0; i < this.halfYears.length; i++) {
              if (this.halfYears[i].current == 1) {
                this.halfYear = this.halfYears[i]
                break
              }
            }
          });
    },
    getComment: function (){
      HTTP.get(Settings.API.URL_DEFAULT + "/students/" + this.stud.id + "/journal-points-compensation")
          .then((response) => {
            this.itemsComment = response.data
            console.log(response.data)
          })
          .catch(() => {

          })
          .finally(() => {

          });
    },
    clearForms: function (){
      // this.stud = null
    },
    updateCompensationFromItem (item) {
      this.stud = item.students.id
      this.date1 = item.dateStart
      this.date2 = item.dateEnd
      this.updateCompens()
    },
    updateCompens: function (){
      this.updatingCompens = true
        HTTP.post(Settings.API.URL_DEFAULT + "/points-compensation", {
          dateAnd: this.date2,
          dateStart: this.date1,
          studentId: this.stud.id
          // disciplineId: this.discipline.id
        })
            .then((response) => {
              console.log(response.data)
              if (response.data.status == "ok"){
                this.getComment()
                this.alert = true
                this.showAlertSuccess("Успешно добавлено")
                this.clearForms()
              }
            })
            .catch(() => {

            })
            .finally(() => {
              this.updatingCompens = false
            });
    },
    cancelCompensation(item) {
      this.updatingCompens = true
      HTTP.post(Settings.API.URL_DEFAULT + "/points-compensation/cancel", {
        dateAnd: item.dateEnd,
        dateStart: item.dateStart,
        studentId: item.students.id,
        disciplineId: item.disciplineId
      })
          .then((response) => {
            console.log(response.data)
            if (response.data.status == "ok"){
              this.getComment()
              this.alert = true
              this.alertType = "success"
              this.showAlertSuccess("Успешно отменено")
              this.clearForms()
            }
          })
          .catch(() => {

          })
          .finally(() => {
            this.updatingCompens = false
          });
    },
    getGroups: function (){
      if (this.year != undefined &&  this.year != null
      && this.halfYear != undefined &&  this.halfYear != null){
        HTTP.get(Settings.API.URL_DEFAULT + "/groups/by-year-and-half-year?idHalfYear=" + this.halfYear.id + "&idYear=" + this.year.id)
            .then((response) => {
              this.groups = response.data
            })
            .catch(() => {

            })
            .finally(() => {

            });
      }
    },
    getStudent: function (){
      if (this.group.id != undefined){
        HTTP.get(Settings.API.URL_DEFAULT + "/groups/" + this.group.id + "/students")
            .then((response) => {
              console.log(response.data)
              this.studs = response.data

            })
            .catch(() => {

            })
            .finally(() => {

            });
      }
    },
    // getDiscipline(){
    //   HTTP.get(Settings.API.URL_DEFAULT + "/groups/" + this.group.id + "/disciplines/by-groupid")
    //       .then((response) => {
    //         this.group = response.data
    //       })
    //       .catch(() => {
    //
    //       })
    //       .finally(() => {
    //
    //       });
    // },

  },
  created() {
    this.getHalfYear()
    this.getYear()
  },
  components: {}
}
</script>

<style  lang="css">
</style>
