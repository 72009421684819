var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',[_c('v-col',{staticClass:"mr-10"},[_c('v-autocomplete',{attrs:{"items":_vm.yearOfStuds,"label":"Год обучения","dense":"","item-value":"id","item-text":"name","return-object":""},model:{value:(_vm.yearOfStud),callback:function ($$v) {_vm.yearOfStud=$$v},expression:"yearOfStud"}})],1),_c('v-col',{staticClass:"mr-10"},[_c('v-autocomplete',{attrs:{"items":_vm.halfYears,"label":"Полугодие","dense":"","item-value":"id","item-text":"name","return-object":""},model:{value:(_vm.halfYear),callback:function ($$v) {_vm.halfYear=$$v},expression:"halfYear"}})],1),_c('v-col',{staticClass:"mr-10"},[_c('v-autocomplete',{attrs:{"items":_vm.groups,"label":"Группа","dense":"","item-value":"id","item-text":"name","return-object":""},model:{value:(_vm.group),callback:function ($$v) {_vm.group=$$v},expression:"group"}})],1)],1),_c('v-data-table',{staticClass:"elevation-2",attrs:{"headers":_vm.headers,"items":_vm.items,"items-per-page":15,"hide-default-footer":false,"search":_vm.search,"single-expand":true,"loading":_vm.loading},scopedSlots:_vm._u([{key:"item.invitationCode",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"target":"_blank","text":""},on:{"click":function($event){return _vm.editInviteCode(item)}}},[_vm._v(" "+_vm._s(item.invitationCode)+" ")])]}},{key:"item.numberLessonsPlan",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.numberLessonsPlan * 2)+" ")]}},{key:"item.numberLessonsAdded",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.numberLessonsAdded * 2)+" ")]}},{key:"item.numberLessons",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.numberLessons * 2)+" ")]}},{key:"item.linked",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"to":_vm.getLinkCalendar(item),"target":"_blank","icon":""}},[_c('v-icon',[_vm._v("date_range")])],1)]}}])}),[_c('v-row',{attrs:{"justify":"center"}},[_c('v-dialog',{attrs:{"persistent":"","max-width":"600px"},model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v("Код приглашения")])]),_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[_c('div',[_c('v-text-field',{attrs:{"label":"Код","hide-details":"auto"},model:{value:(_vm.editItem.invitationCode),callback:function ($$v) {_vm.$set(_vm.editItem, "invitationCode", $$v)},expression:"editItem.invitationCode"}})],1)])],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":function($event){_vm.show = false}}},[_vm._v(" Close ")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.saveWorkload}},[_vm._v(" Save ")])],1)],1)],1)],1)]],2)}
var staticRenderFns = []

export { render, staticRenderFns }