<template>
  <div>
    <v-expansion-panels
        multiple
    >
      <v-expansion-panel>
        <v-expansion-panel-header>Параметры сетки расписания</v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-layout justify-center>
            <v-flex xs12>
              <v-card>

                <v-card-text>
                  <v-row>
                    <v-col class="mr-10 ">
                      <v-autocomplete v-model="typesEducation"
                                      :items="typesEducations"
                                      label="Вид образования"
                                      dense
                      >
                      </v-autocomplete>
                    </v-col>
                    <v-col class="mr-10">
                      <v-autocomplete v-model="yearOfStud"
                                      :items="yearOfStuds"
                                      label="Год обучения"
                                      dense
                                      item-value="id"
                                      item-text="name"
                                      return-object
                      >
                      </v-autocomplete>
                    </v-col>
                    <v-col class="mr-10">
                      <v-autocomplete v-model="halfYear"
                                      :items="halfYears"
                                      label="Полугодие"
                                      dense
                                      item-value="id"
                                      item-text="name"
                                      return-object
                      >
                      </v-autocomplete>
                    </v-col>
                    <v-col class="mr-10">
                      <v-autocomplete v-model="course"
                                      :items="courses"
                                      label="Курс"
                                      dense
                      >
                      </v-autocomplete>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col class="mr-10">
                      <v-autocomplete v-model="faculty"
                                      :items="faculties"
                                      label="faculty"
                                      dense
                                      multiple
                      >
                      </v-autocomplete>
                    </v-col>
                    <v-col class="mr-10">
                      <v-autocomplete v-model="direction"
                                      :items="directions"
                                      label="direction"
                                      dense
                      >
                      </v-autocomplete>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col class="mr-10 col-2">
                      <v-autocomplete v-model="formOfTraining"
                                      :items="formOfTrainings"
                                      label="Тип"
                                      multiple
                                      dense
                      >
                      </v-autocomplete>
                    </v-col>
                  </v-row>

                </v-card-text>
                <v-card-actions>
                  <v-btn
                      @click="sendALl"
                      :disabled="sendAllBtn"
                      color="primary"
                  >Отправить все
                  </v-btn>
                  <v-spacer></v-spacer>
                  <v-btn
                      @click="getGroups"
                      color="primary"
                  >Поиск
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-flex>
          </v-layout>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <v-card>
      <v-card-title>
<!--        <v-row>-->
<!--          <v-col>-->
<!--            <v-autocomplete v-model="yearOfStudy"-->
<!--                            :items="yearOfStudys"-->
<!--                            label="Год"-->
<!--                            dense-->
<!--                            item-value="id"-->
<!--                            item-text="name"-->
<!--                            return-object-->
<!--            >-->
<!--            </v-autocomplete>-->
<!--          </v-col>-->
<!--          <v-col>-->
<!--            <v-autocomplete v-model="halfYear"-->
<!--                            :items="halfYears"-->
<!--                            label="Полугодие"-->
<!--                            dense-->
<!--                            item-value="id"-->
<!--                            item-text="name"-->
<!--                            return-object-->
<!--            >-->
<!--            </v-autocomplete>-->
<!--          </v-col>-->
<!--        </v-row>-->
        <v-spacer></v-spacer>
        <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
        ></v-text-field>
      </v-card-title>
      <v-data-table
          :loading="loading"
          :headers="headers"
          :items="groups"
          :search="search"
      >
        <template v-slot:item.send="{ item }">
          <v-btn icon
                 @click="sendByGroup(item.groupId)"
                 color="primary"
          >
            <v-icon>send</v-icon>
          </v-btn>
        </template>

      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import Settings from "@/settings/settings_url";
import {HTTP} from "@/settings/axios";

export default {
  name: "SendSchedule",
  data() {
    return {
      sendAllBtn: true,
      loading: false,
      search: "",
      headers: [
        {
          text: "Группа",
          align: "start",
          sortable: false,
          value: "groupName",
        },
        {text: "Новые", value: "newCount"},
        {text: "Отправлено", value: "sendCount"},
        {text: "обновлено всего", value: "updateCount"},
        {text: "Ошибка обновления", value: "errorCount"},
        {text: "", value: "send"},
      ],
      yearOfStud: {},
      yearOfStuds: [],
      halfYears: [],
      halfYear: {},
      groups: [],
      typesEducation: "BACCALAUREATE",
      typesEducations: [
        {
          text: "СПО",
          value: "SPO"
        },
        {
          text: "Бакалавриат-Специалитет",
          value: "BACCALAUREATE"
        },
        {
          text: "Магистратура",
          value: "MAGISTRACY"
        },
        {
          text: "Аспирантура",
          value: "ASPIRANT"
        },
      ],
      course: 1,
      courses: [
        {
          text: "1 курс",
          value: 1
        },
        {
          text: "2 курс",
          value: 2
        },
        {
          text: "3 курс",
          value: 3
        },
        {
          text: "4 курс",
          value: 4
        },
        {
          text: "5 курс",
          value: 5
        },
        {
          text: "6 курс",
          value: 6
        },
      ],
      // scheduleType: {},
      // scheduleTypes: [],
      // parity: 1,
      // parityList: [
      //   {
      //     text: "Нечетная",
      //     value: 1
      //   },
      //   {
      //     text: "Четная",
      //     value: 2
      //   },
      // ],
      faculty: [],
      faculties: [],
      direction: "",
      directions: [
        "",
      ],
      formOfTraining: [],
      formOfTrainings:[],
    };
  },
  watch: {
    formOfTraining(){
      this.sendAllBtn = true
    },
    direction(){
      this.sendAllBtn = true
    },
    faculty(){
      this.sendAllBtn = true
    },
    yearOfStud() {
      this.years = this.yearOfStud.name.split("-")
      this.getDirection()
      this.getFaculty()
      this.sendAllBtn = true
    },
    halfYear(){
      this.getDirection()
      this.getFaculty()
      this.sendAllBtn = true
    },
    course(){
      this.getDirection()
      this.getFaculty()
      this.sendAllBtn = true
    },
    typesEducation(){
      this.getDirection()
      this.getFaculty()
      this.sendAllBtn = true

    }
  },
  methods: {
    getYear: async function () {
      HTTP.get(Settings.API.URL_DEFAULT + "/year-of-study")
          .then((response) => {
            this.yearOfStuds = response.data
          })
          .catch(() => {

          })
          .finally(() => {
            for (let i = 0; i < this.yearOfStuds.length; i++) {
              if (this.yearOfStuds[i].current == 1) {
                this.yearOfStud = this.yearOfStuds[i]
                break
              }
            }
          });
    },
    getHalfYear: async function () {
      HTTP.get(Settings.API.URL_DEFAULT + "/half-year")
          .then((response) => {
            this.halfYears = response.data
          })
          .catch(() => {

          })
          .finally(() => {
            for (let i = 0; i < this.halfYears.length; i++) {
              if (this.halfYears[i].current == 1) {
                this.halfYear = this.halfYears[i]
                break
              }
            }
          });
    },
    async getGroups() {
      this.loading = true;
      this.groups = [];
      const data = {
        faculty: this.faculty,
        type: this.formOfTraining
      }
      console.log("this.groups");
      await HTTP.post(Settings.API.URL_DEFAULT + "/schedule-exchangeService/?year=" + this.yearOfStud.id + "&halfYear=" + this.halfYear.id + "&typesEducation=" + this.typesEducation +
          "&course=" + this.course + "&direction=" + this.direction, data)
          .then((response) => {
            this.groups = response.data;
            console.log(this.groups);
            this.sendAllBtn = false
          })
          .catch(() => {
            this.errored = true;
          })
          .finally(() => {
            this.loading = false;
          });
    },
    async sendALl() {
      // this.loading = true;
      let ids = this.groups.reduce((acc, val) => {
        return val ? [...acc, val.groupId] : acc;
      }, []);
      await HTTP.post(Settings.API.URL_DEFAULT + "/schedule-exchangeService/group-list"  +
          "?yearId=" + this.yearOfStud.id + "&halfYearId=" + this.halfYear.id, ids)
          .then(() => {
            this.groups = [];
            this.getGroups()
          })
          .catch(() => {
            this.errored = true;
          })
          .finally(() => {
            this.loading = false;
          });
    },
    async sendByGroup(id) {
      this.loading = true;
      this.groups = [];
      console.log(id);
      await HTTP.get(Settings.API.URL_DEFAULT + "/schedule-exchangeService/" + id +
          "?yearId=" + this.yearOfStud.id + "&halfYearId=" + this.halfYear.id)
          .then((response) => {
            this.groups = response.data;
            console.log(this.groups);
            this.getGroups()
          })
          .catch(() => {
            this.errored = true;
          })
          .finally(() => {
            this.loading = false;
          });
    },
    getFormOfTraining: async function () {
      await HTTP.get(Settings.API.URL_DEFAULT + Settings.URL_SCHEDULE + "form-of-training")
          .then((response) => {
            this.formOfTrainings = response.data // ["",].concat(response.data)
            this.formOfTraining = [...response.data] // ["",].concat(response.data)
          })
          .catch(() => {
          })
    },
    getFaculty: async function () {
      await HTTP.get(Settings.API.URL_DEFAULT + Settings.URL_SCHEDULE + "faculty?course=" +
          this.course + "&halfYear=" + this.halfYear.id + "&yearOfStudy=" +
          this.yearOfStud.name + "&typesEducation=" + this.typesEducation)
          .then((response) => {
            this.faculties = response.data  //["",].concat(response.data)
          })
          .catch(() => {
          })
    },
    getDirection: async function () {
      await HTTP.get(Settings.API.URL_DEFAULT + Settings.URL_SCHEDULE + "direction?course=" +
          this.course + "&halfYear=" + this.halfYear.id + "&yearOfStudy=" +
          this.yearOfStud.name + "&typesEducation=" + this.typesEducation)
          .then((response) => {
            this.directions = ["",].concat(response.data)
          })
          .catch(() => {
          })
    },
  },
  mounted() {
    // this.getGroups();
    this.getYear()
    this.getHalfYear()
    // this.getScheduleType()
    this.getDirection()
    this.getFaculty()
    this.getFormOfTraining()
  },
};
</script>

<style scoped></style>
