<template>
  <v-container>
    <v-row>
      <v-col/>
      <v-col>
        <v-autocomplete
            label="Форма обучения"
            prepend-icon="school"
            item-text="ru"
            return-object
            v-model="typeOfEducation"
            :items="typesOfEducation"
            multiple
            chips
            deletable-chips
        />
      </v-col>
      <v-col>
        <v-autocomplete
            label="Преподаватель"
            prepend-icon="group"
            v-model="selectedTeacher"
            :items="teachers"
            :loading="teachersLoading"
            item-text="teacher"
            item-value="id"
            clearable
        />
      </v-col>
      <v-col/>
    </v-row>
    <v-row>
      <v-col/>
      <v-col>
        <v-btn @click="getTeacherSatisfactionReport" color="primary">
          Получить отчёт
        </v-btn>
      </v-col>
      <v-col>
        <v-btn color="#1F6B41" class="white--text" :disabled="printing" :loading="printing" @click="getExcelForAllTeachers()">
          <v-icon left>print</v-icon>
          Скачать Excel отчет по всем преподавателям
        </v-btn>
<!--        <v-menu :offset-y="true"-->
<!--                :close-on-content-click="false">-->
<!--          <template v-slot:activator="{ on, attrs }">-->
<!--            <v-btn-->
<!--                color="#1F6B41"-->
<!--                dark-->
<!--                v-bind="attrs"-->
<!--                v-on="on"-->
<!--            >-->
<!--              <v-icon left>more_horiz</v-icon>-->
<!--              Excel-->
<!--            </v-btn>-->
<!--          </template>-->
<!--          <v-list>-->
<!--            <v-list-item>-->
<!--              <v-btn color="primary" :loading="printing" :disabled="printing || !selectedTeacher" @click="getExcelForSelectedTeacher()">-->
<!--                <v-icon left>print</v-icon>-->
<!--                Excel отчёт по выбранному преподавателю-->
<!--              </v-btn>-->
<!--            </v-list-item>-->
<!--            <v-list-item>-->
<!--              <v-btn color="primary" :disabled="printing" :loading="printing" @click="getExcelForAllTeachers()">-->
<!--                <v-icon left>print</v-icon>-->
<!--                Excel отчет по всем преподавателям-->
<!--              </v-btn>-->
<!--            </v-list-item>-->
<!--          </v-list>-->
<!--        </v-menu>-->
      </v-col>
    </v-row>
    <teacher-satisfaction-report ref="teacherReport" :report-raw="reports"/>
    <v-overlay v-show="overlay">
      <v-progress-circular
          indeterminate
          size="64"
      ></v-progress-circular>
    </v-overlay>
  </v-container>
</template>

<script>
import {HTTP} from "@/settings/axios";
import Settings from "@/settings/settings_url";
import TeacherSatisfactionReport from "@/components/admin/TeacherSatisfaction/TeacherSatisfactionReport";

export default {
  name: "AdminTeacherSatisfactionByTeacher",
  components: {TeacherSatisfactionReport},

  data() {
    return {
      typeOfEducation: [
        {ru: "Бакалавриат", en: "TEACHER_SATISFACTION_BACCALAUREATE"},
        {ru: "Магистратура", en: "TEACHER_SATISFACTION_MAGISTRACY"},
        {ru: "СПО", en: "TEACHER_SATISFACTION_SPO"},
      ],
      typesOfEducation: [
        {ru: "Бакалавриат", en: "TEACHER_SATISFACTION_BACCALAUREATE"},
        {ru: "Магистратура", en: "TEACHER_SATISFACTION_MAGISTRACY"},
        {ru: "СПО", en: "TEACHER_SATISFACTION_SPO"},
      ],

      selectedTeacher: null,
      teachers: [],
      teachersLoading: false,

      reportRaw: null,
      reports: [],
      overlay: false,

      printing: false,
    }
  },
  watch: {
  },
  methods: {
    async getTeacherSatisfactionReport() {
      this.overlay = true
      this.$refs.teacherReport.totalPoints = 0.0

      let url = ""

      if (this.typeOfEducation !== null && this.typeOfEducation.length > 0 && this.selectedTeacher === null) {
        this.$refs.teacherReport.reportForAll = true
        // url += "/all?types=" + this.typeOfEducation[0].en
        url += "?types=" + this.typeOfEducation[0].en
        for (let i = 1; i < this.typeOfEducation.length; i++)
          url += "&types=" + this.typeOfEducation[i].en
      }

      if (this.selectedTeacher !== null && (this.typeOfEducation === null || this.typeOfEducation.length === 0)) {
        this.$refs.teacherReport.reportForAll = false
        url += "?teacherId=" + this.selectedTeacher
      }

      if (this.selectedTeacher !== null && this.typeOfEducation !== null && this.typeOfEducation.length > 0) {
        this.$refs.teacherReport.reportForAll = false
        url += "?teacherId=" + this.selectedTeacher
        this.typeOfEducation.forEach(function (item) {
          url += "&types=" + item.en
        })
      }

      // if(url === "") {
      //   this.$refs.teacherReport.reportForAll = true
      //   url = "/all"
      // }

      await HTTP.get(Settings.API.URL_DEFAULT + "/testing-form/teacher-satisfaction/report" + url)
          .then((response) => {
            this.reports = response.data

          })
          .catch(() => {
          })
          .finally(() => {
            this.overlay = false
          });
    },
    getTeachers() {
      this.teachersLoading = true

      HTTP.get(Settings.API.URL_DEFAULT + "/testing-form/teacher-satisfaction/teachers-answered/this-semester")
          .then((response) => {
            this.teachers = response.data
          })
          .catch(() => {
          })
          .finally(() => {
            this.teachersLoading = false
          });
    },
    getExcelForAllTeachers() {
      this.printing = true

      HTTP.get(Settings.API.URL_DEFAULT + "/testing-form/teacher-satisfaction/excel-report/full")
          .then((response) => {
            window.open(Settings.API.URL_DEFAULT + "/testing-form/teacher-satisfaction/get-file?path=" + response.data, '_blank');
            // window.open(Settings.API.URL_DEFAULT + Settings.API.URL_REGISTRATION_OF_STUDENT_VISIT + "get-file?path=" + response.data, '_blank')
          })
          .catch(() => {
          })
          .finally(() => {
            this.printing = false
          });
    },
    getExcelForSelectedTeacher() {
      this.printing = true

      HTTP.get(Settings.API.URL_DEFAULT + "/testing-form/teacher-satisfaction/excel-report?teacherId=" + this.selectedTeacher)
          .then((response) => {
            console.log(response.data)
          })
          .catch(() => {
          })
          .finally(() => {
            this.printing = false
          });
    },
  },
  created() {
    this.getTeachers()
  }
}
</script>

<style scoped>

</style>