<template>
  <div>
    <v-app id="schedule">
      <v-layout justify-center>
        <v-flex xs12>
          <v-card>
            <v-card-text>
              <v-layout row wrap>
                <v-flex xs12 md12>
                  <v-card-actions class="justify-center">
                    <h1>Отчет по группам за 14.10.2020 г.</h1>
                  </v-card-actions>

                </v-flex>
                <v-flex xs12 md6>

                </v-flex>
                <v-flex xs12 md6>

                </v-flex>
              </v-layout>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>

            </v-card-actions>
          </v-card>
        </v-flex>
      </v-layout>
      <v-layout justify-center>
        <v-flex xs12>
          <v-data-table
              :headers="headers"
              :items="desserts"
              :items-per-page="10"
              :hide-default-footer="false"
              class="elevation-10"

          >

            <template v-slot:item.one="{ item }">
              <schedule-item
                  :header="item.one.lesson"
                  :content="item.one.teacher"
                  :room="item.one.room"
                  :current_count="item.one.current_count"
                  :full_count="item.one.full_count"
                  :is_teacher="item.one.is_teacher"
                  :is_lesson="item.one.is_lesson"
              ></schedule-item>
            </template>

            <template v-slot:item.two="{ item }">
              <schedule-item
                  :header="item.two.lesson"
                  :content="item.two.teacher"
                  :room="item.two.room"
                  :current_count="item.two.current_count"
                  :full_count="item.two.full_count"
                  :is_teacher="item.two.is_teacher"
                  :is_lesson="item.two.is_lesson"
              ></schedule-item>
            </template>
            <template v-slot:item.three="{ item }">
              <schedule-item
                  :header="item.three.lesson"
                  :content="item.three.teacher"
                  :room="item.three.room"
                  :current_count="item.three.current_count"
                  :full_count="item.three.full_count"
                  :is_teacher="item.three.is_teacher"
                  :is_lesson="item.three.is_lesson"
              ></schedule-item>
            </template>

            <template v-slot:item.four="{ item }">
              <schedule-item
                  :header="item.four.lesson"
                  :content="item.four.teacher"
                  :room="item.four.room"
                  :current_count="item.four.current_count"
                  :full_count="item.four.full_count"
                  :is_teacher="item.four.is_teacher"
                  :is_lesson="item.four.is_lesson"
              ></schedule-item>
            </template>

            <template v-slot:item.five="{ item }">
              <schedule-item
                  :header="item.five.lesson"
                  :content="item.five.teacher"
                  :room="item.five.room"
                  :current_count="item.five.current_count"
                  :full_count="item.five.full_count"
                  :is_teacher="item.five.is_teacher"
                  :is_lesson="item.five.is_lesson"
              ></schedule-item>
            </template>

            <template v-slot:item.six="{ item }">
              <schedule-item
                  :header="item.six.lesson"
                  :content="item.six.teacher"
                  :room="item.six.room"
                  :current_count="item.six.current_count"
                  :full_count="item.six.full_count"
                  :is_teacher="item.six.is_teacher"
                  :is_lesson="item.six.is_lesson"
              ></schedule-item>
            </template>
          </v-data-table>
        </v-flex>
      </v-layout>
    </v-app>

  </div>
</template>

<script>
import ReportItem from "@/components/ReportItem";

export default {
name: "Report",
  data() {
    return {
      headers: [
        {text: 'Пары / Время', align: 'start', sortable: false, value: 'name', divider: true, class: "bolt"},
        {text: '1-я пара 08:30 - 10:00', value: 'one', divider: true, sortable: false, class: "bolt"},
        {text: '2-я пара 10:15 - 11:45', value: 'two', divider: true, sortable: false, class: "bolt"},
        {text: '3-я пара 12:10 - 13:40', value: 'three', divider: true, sortable: false, class: "bolt"},
        {text: '4-я пара 13:55 - 15:25', value: 'four', divider: true, sortable: false, class: "bolt"},
        {text: '5-я пара 15:35 - 17:05', value: 'five', divider: true, sortable: false, class: "bolt"},
        {text: '6-я пара   17:15 - 18:45', value: 'six', divider: true, sortable: false, class: "bolt"},
      ],
      desserts: [
        {
          name: 'Группа - 1',
          one: {lesson: 'Бизнес информатика', room: "504 E", teacher: "Доцент. Иванов И.И", current_count: 24, full_count: 45, is_teacher: true, is_lesson: true},
          two: {lesson: 'Юриспруденция',  room: "202", teacher: "Доцент. Иванов И.И", current_count: 42, full_count: 45, is_teacher: false, is_lesson: true},
          three: {lesson: 'Финансы и кредит',  room: "304 Д", teacher: "Доцент. Иванов И.И", current_count: 39, full_count: 45, is_teacher: true, is_lesson: true},
          four: {lesson: 'Торговое дело',  room: "425", teacher: "Доцент. Иванов И.И", current_count: 44, full_count: 45, is_teacher: true, is_lesson: true},
          five: {lesson: '',  room: "504 E", teacher: ""},
          six: {lesson: 'Торговое дело',  room: "425", teacher: "Доцент. Иванов И.И", current_count: 44, full_count: 45, is_teacher: true, is_lesson: false},
        },
        {
          name: 'Группа - 2',
          one: {lesson: 'Бизнес информатика', room: "504 E", teacher: "Доцент. Иванов И.И", current_count: 42, full_count: 45, is_teacher: true, is_lesson: true},
          two: {lesson: 'Юриспруденция',  room: "202", teacher: "Доцент. Иванов И.И", current_count: 42, full_count: 45, is_teacher: true, is_lesson: true},
          three: {lesson: 'Финансы и кредит',  room: "304 Д", teacher: "Доцент. Иванов И.И", current_count: 39, full_count: 45, is_teacher: true, is_lesson: false},
          four: {lesson: 'Торговое дело',  room: "425", teacher: "Доцент. Иванов И.И", current_count: 44, full_count: 45, is_teacher: true, is_lesson: true},
          five: {lesson: '',  room: "504 E", teacher: ""},
          six: {lesson: 'Торговое дело',  room: "425", teacher: "Доцент. Иванов И.И", current_count: 44, full_count: 45, is_teacher: true, is_lesson: false},
        },
        {
          name: 'Группа - 3',
          one: {lesson: 'Бизнес информатика', room: "504 E", teacher: "Доцент. Иванов И.И", current_count: 38, full_count: 45, is_teacher: true, is_lesson: true},
          two: {lesson: 'Юриспруденция',  room: "202", teacher: "Доцент. Иванов И.И", current_count: 42, full_count: 45, is_teacher: true, is_lesson: true},
          three: {lesson: 'Финансы и кредит',  room: "304 Д", teacher: "Доцент. Иванов И.И", current_count: 39, full_count: 45, is_teacher: true, is_lesson: true},
          four: {lesson: 'Торговое дело',  room: "425", teacher: "Доцент. Иванов И.И", current_count: 44, full_count: 45, is_teacher: true, is_lesson: true},
          five: {lesson: '',  room: "504 E", teacher: ""},
          six: {lesson: 'Торговое дело',  room: "425", teacher: "Доцент. Иванов И.И", current_count: 44, full_count: 45, is_teacher: true, is_lesson: false},
        },
        {
          name: 'Группа - 4',
          one: {lesson: 'Бизнес информатика', room: "504 E", teacher: "Доцент. Иванов И.И", current_count: 24, full_count: 45, is_teacher: true, is_lesson: true},
          two: {lesson: 'Юриспруденция',  room: "202", teacher: "Доцент. Иванов И.И", current_count: 42, full_count: 45, is_teacher: false, is_lesson: true},
          three: {lesson: 'Финансы и кредит',  room: "304 Д", teacher: "Доцент. Иванов И.И", current_count: 39, full_count: 45, is_teacher: true, is_lesson: true},
          four: {lesson: 'Торговое дело',  room: "425", teacher: "Доцент. Иванов И.И", current_count: 44, full_count: 45, is_teacher: true, is_lesson: true},
          five: {lesson: '',  room: "504 E", teacher: ""},
          six: {lesson: 'Торговое дело',  room: "425", teacher: "Доцент. Иванов И.И", current_count: 44, full_count: 45, is_teacher: true, is_lesson: false},
        },
        {
          name: 'Группа - 5',
          one: {lesson: 'Бизнес информатика', room: "504 E", teacher: "Доцент. Иванов И.И", current_count: 24, full_count: 45, is_teacher: true, is_lesson: true},
          two: {lesson: 'Юриспруденция',  room: "202", teacher: "Доцент. Иванов И.И", current_count: 42, full_count: 45, is_teacher: false, is_lesson: true},
          three: {lesson: 'Финансы и кредит',  room: "304 Д", teacher: "Доцент. Иванов И.И", current_count: 39, full_count: 45, is_teacher: true, is_lesson: true},
          four: {lesson: 'Торговое дело',  room: "425", teacher: "Доцент. Иванов И.И", current_count: 44, full_count: 45, is_teacher: true, is_lesson: true},
          five: {lesson: '',  room: "504 E", teacher: ""},
          six: {lesson: 'Торговое дело',  room: "425", teacher: "Доцент. Иванов И.И", current_count: 44, full_count: 45, is_teacher: true, is_lesson: false},
        },
        {
          name: 'Группа - 6',
          one: "",
          two: {lesson: 'Юриспруденция',  room: "202", teacher: "Доцент. Иванов И.И", current_count: 42, full_count: 45, is_teacher: true, is_lesson: true},
          three: "",
          four: "",
          five: "",
          six: "",
        },
        {
          name: 'Группа - 7',
          one: {lesson: 'Бизнес информатика', room: "504 E", teacher: "Доцент. Иванов И.И", current_count: 45, full_count: 45, is_teacher: true, is_lesson: true},
          two: {lesson: 'Юриспруденция',  room: "202", teacher: "Доцент. Иванов И.И", current_count: 42, full_count: 45, is_teacher: true, is_lesson: true},
          three: {lesson: 'Финансы и кредит',  room: "304 Д", teacher: "Доцент. Иванов И.И", current_count: 39, full_count: 45, is_teacher: true, is_lesson: false},
          four: {lesson: 'Торговое дело',  room: "425", teacher: "Доцент. Иванов И.И", current_count: 44, full_count: 45, is_teacher: true, is_lesson: false},
          five: {lesson: '',  room: "504 E", teacher: ""},
          six: {lesson: 'Торговое дело',  room: "425", teacher: "Доцент. Иванов И.И", current_count: 44, full_count: 45, is_teacher: true, is_lesson: false},
        },
        {
          name: 'Группа - 8',
          one: {lesson: 'Бизнес информатика', room: "504 E", teacher: "Доцент. Иванов И.И", current_count: 37, full_count: 45, is_teacher: true, is_lesson: true},
          two: {lesson: 'Юриспруденция',  room: "202", teacher: "Доцент. Иванов И.И", current_count: 42, full_count: 45, is_teacher: true, is_lesson: true},
          three: {lesson: 'Финансы и кредит',  room: "304 Д", teacher: "Доцент. Иванов И.И", current_count: 39, full_count: 45, is_teacher: true, is_lesson: false},
          four: {lesson: 'Торговое дело',  room: "425", teacher: "Доцент. Иванов И.И", current_count: 44, full_count: 45, is_teacher: false, is_lesson: true},
          five: {lesson: '',  room: "504 E", teacher: ""},
          six: {lesson: 'Торговое дело',  room: "425", teacher: "Доцент. Иванов И.И", current_count: 44, full_count: 45, is_teacher: true, is_lesson: false},
        },
        {
          name: 'Группа - 9',
          one: {lesson: 'Бизнес информатика', room: "504 E", teacher: "Доцент. Иванов И.И", current_count: 44, full_count: 45, is_teacher: true, is_lesson: false},
          two: {lesson: 'Юриспруденция',  room: "202", teacher: "Доцент. Иванов И.И", current_count: 44, full_count: 45, is_teacher: true, is_lesson: true},
          three: {lesson: 'Финансы и кредит',  room: "304 Д", teacher: "Доцент. Иванов И.И", current_count: 44, full_count: 45, is_teacher: true, is_lesson: true},
          four: {lesson: 'Торговое дело',  room: "425", teacher: "Доцент. Иванов И.И", current_count: 44, full_count: 45, is_teacher: true, is_lesson: true},
          five: {lesson: '',  room: "504 E", teacher: ""},
          six: {lesson: 'Торговое дело',  room: "425", teacher: "Доцент. Иванов И.И", current_count: 44, full_count: 45, is_teacher: true, is_lesson: false},
        },
        {
          name: 'Группа - 10',
          one: {lesson: 'Бизнес информатика', room: "504 E", teacher: "Доцент. Иванов И.И", current_count: 44, full_count: 45, is_teacher: true, is_lesson: true},
          two: {lesson: 'Юриспруденция',  room: "202", teacher: "Доцент. Иванов И.И", current_count: 44, full_count: 45, is_teacher: true, is_lesson: true},
          three: {lesson: 'Финансы и кредит',  room: "304 Д", teacher: "Доцент. Иванов И.И", current_count: 44, full_count: 45, is_teacher: true, is_lesson: true},
          four: {lesson: 'Торговое дело',  room: "425", teacher: "Доцент. Иванов И.И", current_count: 44, full_count: 45, is_teacher: true, is_lesson: true},
          five: {lesson: '',  room: "504 E", teacher: ""},
          six: {lesson: 'Торговое дело',  room: "425", teacher: "Доцент. Иванов И.И", current_count: 44, full_count: 45, is_teacher: true, is_lesson: false},
        },
        {
          name: 'Группа - 11',
          one: {lesson: 'Бизнес информатика', room: "504 E", teacher: "Доцент. Иванов И.И", current_count: 45, full_count: 45, is_teacher: true, is_lesson: true},
          two: {lesson: 'Юриспруденция',  room: "202", teacher: "Доцент. Иванов И.И", current_count: 41, full_count: 45, is_teacher: true, is_lesson: true},
          three: {lesson: 'Финансы и кредит',  room: "304 Д", teacher: "Доцент. Иванов И.И", current_count: 45, full_count: 45, is_teacher: true, is_lesson: true},
          four: {lesson: 'Торговое дело',  room: "425", teacher: "Доцент. Иванов И.И", current_count: 45, full_count: 45, is_teacher: true, is_lesson: true},
          five: {lesson: '',  room: "504 E", teacher: ""},
          six: {lesson: 'Торговое дело',  room: "425", teacher: "Доцент. Иванов И.И", current_count: 44, full_count: 45, is_teacher: true, is_lesson: false},
        },
        {
          name: 'Группа - 12',
          one: {lesson: 'Бизнес информатика', room: "504 E", teacher: "Доцент. Иванов И.И", current_count: 45, full_count: 45, is_teacher: true, is_lesson: true},
          two: {lesson: 'Юриспруденция',  room: "202", teacher: "Доцент. Иванов И.И", current_count: 45, full_count: 45, is_teacher: true, is_lesson: true},
          three: {lesson: 'Финансы и кредит',  room: "304 Д", teacher: "Доцент. Иванов И.И", current_count: 35, full_count: 45, is_teacher: true, is_lesson: true},
          four: {lesson: 'Торговое дело',  room: "425", teacher: "Доцент. Иванов И.И", current_count: 35, full_count: 45, is_teacher: true, is_lesson: true},
          five: {lesson: '',  room: "504 E", teacher: ""},
          six: {lesson: 'Торговое дело',  room: "425", teacher: "Доцент. Иванов И.И", current_count: 44, full_count: 45, is_teacher: true, is_lesson: false},
        },
        {
          name: 'Группа - 13',
          one: {lesson: 'Бизнес информатика', room: "504 E", teacher: "Доцент. Иванов И.И", current_count: 45, full_count: 45, is_teacher: true, is_lesson: true},
          two: {lesson: 'Юриспруденция',  room: "202", teacher: "Доцент. Иванов И.И", current_count: 45, full_count: 45, is_teacher: true, is_lesson: true},
          three: {lesson: 'Финансы и кредит',  room: "304 Д", teacher: "Доцент. Иванов И.И", current_count: 45, full_count: 45, is_teacher: true, is_lesson: true},
          four: {lesson: 'Торговое дело',  room: "425", teacher: "Доцент. Иванов И.И", current_count: 45, full_count: 45, is_teacher: true, is_lesson: true},
          five: {lesson: '',  room: "504 E", teacher: ""},
          six: {lesson: 'Торговое дело',  room: "425", teacher: "Доцент. Иванов И.И", current_count: 44, full_count: 45, is_teacher: true, is_lesson: false},
        },
        {
          name: 'Группа - 14',
          one: {lesson: 'Бизнес информатика', room: "504 E", teacher: "Доцент. Иванов И.И", current_count: 45, full_count: 45, is_teacher: true, is_lesson: true},
          two: {lesson: 'Юриспруденция',  room: "202", teacher: "Доцент. Иванов И.И", current_count: 45, full_count: 45, is_teacher: true, is_lesson: true},
          three: {lesson: 'Финансы и кредит',  room: "304 Д", teacher: "Доцент. Иванов И.И", current_count: 45, full_count: 45, is_teacher: true, is_lesson: true},
          four: {lesson: 'Торговое дело',  room: "425", teacher: "Доцент. Иванов И.И", current_count: 45, full_count: 45, is_teacher: true, is_lesson: true},
          five: {lesson: '',  room: "504 E", teacher: ""},
          six: {lesson: 'Торговое дело',  room: "425", teacher: "Доцент. Иванов И.И", current_count: 44, full_count: 45, is_teacher: true, is_lesson: false},
        },
        {
          name: 'Группа - 15',
          one: {lesson: 'Бизнес информатика', room: "504 E", teacher: "Доцент. Иванов И.И", current_count: 45, full_count: 45, is_teacher: true, is_lesson: true},
          two: {lesson: 'Юриспруденция',  room: "202", teacher: "Доцент. Иванов И.И", current_count: 45, full_count: 45, is_teacher: true, is_lesson: true},
          three: {lesson: 'Финансы и кредит',  room: "304 Д", teacher: "Доцент. Иванов И.И", current_count: 45, full_count: 45, is_teacher: false, is_lesson: true},
          four: {lesson: 'Торговое дело',  room: "425", teacher: "Доцент. Иванов И.И", current_count: 45, full_count: 45, is_teacher: true, is_lesson: true},
          five: {lesson: '',  room: "504 E", teacher: ""},
          six: {lesson: 'Торговое дело',  room: "425", teacher: "Доцент. Иванов И.И", current_count: 44, full_count: 45, is_teacher: true, is_lesson: false},
        },
        {
          name: 'Группа - 16',
          one: {lesson: 'Бизнес информатика', room: "504 E", teacher: "Доцент. Иванов И.И", current_count: 45, full_count: 45, is_teacher: true, is_lesson: true},
          two: {lesson: 'Юриспруденция',  room: "202", teacher: "Доцент. Иванов И.И", current_count: 45, full_count: 45, is_teacher: true, is_lesson: true},
          three: {lesson: 'Финансы и кредит',  room: "304 Д", teacher: "Доцент. Иванов И.И", current_count: 40, full_count: 45, is_teacher: true, is_lesson: true},
          four: {lesson: 'Торговое дело',  room: "425", teacher: "Доцент. Иванов И.И", current_count: 45, full_count: 45, is_teacher: true, is_lesson: true},
          five: {lesson: '',  room: "504 E", teacher: ""},
          six: {lesson: 'Торговое дело',  room: "425", teacher: "Доцент. Иванов И.И", current_count: 44, full_count: 45, is_teacher: true, is_lesson: false},
        },
        {
          name: 'Группа - 17',
          one: {lesson: 'Бизнес информатика', room: "504 E", teacher: "Доцент. Иванов И.И", current_count: 45, full_count: 45, is_teacher: true, is_lesson: true},
          two: {lesson: 'Юриспруденция',  room: "202", teacher: "Доцент. Иванов И.И", current_count: 45, full_count: 45, is_teacher: true, is_lesson: true},
          three: {lesson: 'Финансы и кредит',  room: "304 Д", teacher: "Доцент. Иванов И.И", current_count: 45, full_count: 45, is_teacher: true, is_lesson: true},
          four: {lesson: 'Торговое дело',  room: "425", teacher: "Доцент. Иванов И.И", current_count: 32, full_count: 45, is_teacher: true, is_lesson: true},
          five: {lesson: '',  room: "504 E", teacher: ""},
          six: {lesson: 'Торговое дело',  room: "425", teacher: "Доцент. Иванов И.И", current_count: 44, full_count: 45, is_teacher: true, is_lesson: false},
        },
        {
          name: 'Группа - 18',
          one: {lesson: 'Бизнес информатика', room: "504 E", teacher: "Доцент. Иванов И.И", current_count: 45, full_count: 45, is_teacher: true, is_lesson: true},
          two: {lesson: 'Юриспруденция',  room: "202", teacher: "Доцент. Иванов И.И", current_count: 42, full_count: 45, is_teacher: true, is_lesson: true},
          three: {lesson: 'Финансы и кредит',  room: "304 Д", teacher: "Доцент. Иванов И.И", current_count: 45, full_count: 45, is_teacher: true, is_lesson: true},
          four: {lesson: 'Торговое дело',  room: "425", teacher: "Доцент. Иванов И.И", current_count: 45, full_count: 45, is_teacher: true, is_lesson: true},
          five: {lesson: '',  room: "504 E", teacher: ""},
          six: {lesson: 'Торговое дело',  room: "425", teacher: "Доцент. Иванов И.И", current_count: 44, full_count: 45, is_teacher: true, is_lesson: false},
        },
        {
          name: 'Группа - 19',
          one: {lesson: 'Бизнес информатика', room: "504 E", teacher: "Доцент. Иванов И.И", current_count: 45, full_count: 45, is_teacher: true, is_lesson: true},
          two: {lesson: 'Юриспруденция',  room: "202", teacher: "Доцент. Иванов И.И", current_count: 45, full_count: 45, is_teacher: true, is_lesson: true},
          three: {lesson: 'Финансы и кредит',  room: "304 Д", teacher: "Доцент. Иванов И.И", current_count: 45, full_count: 45, is_teacher: true, is_lesson: true},
          four: {lesson: 'Торговое дело',  room: "425", teacher: "Доцент. Иванов И.И", current_count: 45, full_count: 45, is_teacher: true, is_lesson: true},
          five: {lesson: '',  room: "504 E", teacher: ""},
          six: {lesson: 'Торговое дело',  room: "425", teacher: "Доцент. Иванов И.И", current_count: 44, full_count: 45, is_teacher: true, is_lesson: false},
        },
        {
          name: 'Группа - 20',
          one: {lesson: 'Бизнес информатика', room: "504 E", teacher: "Доцент. Иванов И.И", current_count: 24, full_count: 45, is_teacher: true, is_lesson: true},
          two: {lesson: 'Юриспруденция',  room: "202", teacher: "Доцент. Иванов И.И", current_count: 42, full_count: 45, is_teacher: false, is_lesson: true},
          three: {lesson: 'Финансы и кредит',  room: "304 Д", teacher: "Доцент. Иванов И.И", current_count: 39, full_count: 45, is_teacher: true, is_lesson: true},
          four: {lesson: 'Торговое дело',  room: "425", teacher: "Доцент. Иванов И.И", current_count: 44, full_count: 45, is_teacher: true, is_lesson: true},
          five: {lesson: '',  room: "504 E", teacher: ""},
          six: {lesson: 'Торговое дело',  room: "425", teacher: "Доцент. Иванов И.И", current_count: 44, full_count: 45, is_teacher: true, is_lesson: false},
        },
      ],
    }
  },
  components: {
    'schedule-item': ReportItem,
  }
}
</script>

<style scoped>

</style>
