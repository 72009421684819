<template>
  <v-dialog v-model="dialog" width="600px">
    <v-card>
      <v-toolbar
          dark
          color="primary"
      >
        <v-card-title>Привязка администратора к ведомости</v-card-title>
        <v-spacer/>
        <v-btn
            icon
            dark
            @click="dialog = false"
        >
          <v-icon>close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-container>
        <v-alert :type="alertType"
                 v-model="alert"
                 dismissible
                 transition="fade-transition"
        >
          {{ alertText }}
        </v-alert>
      </v-container>
      <v-card-text>
        <v-form
            ref="form"
            v-model="valid"
        >
          <v-autocomplete v-model="selectedTeacher"
                          :items="teachers"
                          label="Преподаватель"
                          item-value="id"
                          item-text="teacher"
                          :loading="teachersLoading"
                          prepend-icon="group"
                          autofocus
                          :rules="notNullRules"
          />
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-spacer/>
        <v-btn
            color="primary"
            :loading="loading"
            :disabled="loading || !selectedTeacher"
            @click="linkTeachers"
        >
          Привязать
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {HTTP} from "@/settings/axios";
import Settings from "@/settings/settings_url";

export default {
  name: "LinkAdminToSheet",

  props: {
    sheetType: String,
  },

  data() {
    return {
      dialog: false,
      loading: false,
      selectedTeacher: null,
      teachers: [],
      valid: false,
      teachersLoading: false,
      notNullRules: [
        v => !!v || 'Укажите преподавателя',
      ],
      sheet: null,

      alert: false,
      alertType: null,
      alertText: null,
    }
  },

  methods: {
    linkTeachers() {
      this.loading = true

      let url = ""

      if(this.sheetType === "PRACTICE")
        url += "/practice-sheet/"
      else if(this.sheetType === "COURSE_PROJECT")
        url += "/course-project-sheet/"

      url += this.sheet.id + "/update-admin?teacherId=" + this.selectedTeacher

      console.log(url)

      HTTP.get(Settings.API.URL_DEFAULT + url)
          .then(() => {
            this.$emit("updatedSheet")
            this.dialog = false
          })
          .catch(() => {
            this.alert = true
            this.alertType = "error"
            this.alertText = "При привязке преподавателей произошла ошибка"
          })
          .finally(() => {
            this.loading = false
          });
    },
    getCurrentlyLinkedTeachers() {
      this.teachersLoading = true

      let url = ""

      if(this.sheetType === "PRACTICE")
        url += "/practice-sheet/"
      else if(this.sheetType === "COURSE_PROJECT")
        url += "/course-project-sheet/"

      url += this.sheet.id + "/admin"

      HTTP.get(Settings.API.URL_DEFAULT + url)
          .then((response) => {
            this.selectedTeacher = response.data
          })
          .catch(() => {
          })
          .finally(() => {
            this.teachersLoading = false
          });
    },
    async getTeachers() {
      this.teachersLoading = true
      await HTTP.get(Settings.API.URL_DEFAULT + "/schedule-board/teachers")
          .then((response) => {
            this.teachers = response.data
          })
          .catch(() => {
          })
          .finally(() => {
            this.teachersLoading = false
          });
    },
  },

  watch: {
    async 'dialog'() {
      if(this.dialog) {
        await this.getTeachers()
        this.getCurrentlyLinkedTeachers()
      }
    }
  }
}
</script>

<style scoped>

</style>