<template>
  <div>
    <v-card-title class="d-flex justify-center text-center"><h2>История</h2></v-card-title>
    <v-row v-if="historyFor === 'ADMIN'">
      <v-col>
        <v-autocomplete
            v-model="selectedYear"
            :items="years"
            item-text="name"
            item-value="id"
            return-object
            :loading="yearLoading"
            outlined
            dense
            prepend-icon="event"
            label="Год"
            no-data-text="Нет информации!"
        />
      </v-col>
      <v-col>
        <v-autocomplete
            v-model="selectedHalfYear"
            :items="halfYears"
            item-text="name"
            item-value="id"
            return-object
            :loading="halfYearLoading"
            outlined
            dense
            prepend-icon="event"
            label="Полугодие"
            no-data-text="Нет информации!"
        />
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-autocomplete
            v-model="selectedStatus"
            :items="statuses"
            item-text="ru"
            item-value="en"
            outlined
            dense
            prepend-icon="filter"
            label="Статус заявки"
        >
          <template #selection="{ item }">
            <v-chip small :color="item.color">{{ item.ru }}</v-chip>
          </template>
        </v-autocomplete>
      </v-col>
    </v-row>

    <v-row class="d-flex justify-center text-center">
      <v-col>
        <v-data-table
            class="elevation-1"
            no-data-text="История пуста!"
            :loading="ticketsLoading"
            :headers="ticketsHeaders"
            :items="tickets"
            :search="selectedStatus"
        >
          <template v-slot:item.created="{ item }">
            {{ formatDateTime(item.created) }}
          </template>

          <template v-slot:item.updated="{ item }">
            {{ formatDateTime(item.updated) }}
          </template>

          <template v-slot:item.createdBy="{ item }">
            {{ item.createdBy.username }}
          </template>

          <template v-slot:item.ticketStatus="{ item }">
            <v-chip :color="getTicketColor(item.ticketStatus)">{{ getTicketStatus(item.ticketStatus) }}</v-chip>
          </template>

          <template v-slot:item.lesson="{ item }">
            <div v-if="item && item.dateList && item.dateList.length === 1">
              <span v-for="date in item.dateList" :key="date">
                {{ formatDate(date) }}
              </span>
            </div>
            <div v-if="item && item.dateList && item.dateList.length > 1">
              <v-expansion-panels flat class="justify-center" hover>
                <v-expansion-panel>
                  <v-expansion-panel-header class="justify-center font-normal" disable-icon-rotate expand-icon="more_horiz">
                    {{ formatDate(item.dateList[0]) }} и ещё {{ item.dateList.length - 1 }}
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <ul>
                      <li v-for="date in item.dateList" :key="date">
                        {{ formatDate(date) }}
                      </li>
                    </ul>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </div>
              {{ item.discipline.name }}<br/>
              ({{ item.lessonType.name }})
          </template>

          <template v-slot:item.teacher="{ item }">
            <span v-if="item.teacher">{{ item.teacher.teacher }}</span>
            <span v-if="!item.teacher">Не указан</span>
          </template>



          <template v-slot:item.studentVisits="{ item }">
            <div v-if="item && item.studentList && item.studentList.length === 1">
              <span v-for="student in item.studentList" :key="student.id">
                {{ student.student }}
                <br/>
              </span>
            </div>
            <div v-if="item && item.studentList && item.studentList.length > 1">
              <v-expansion-panels flat class="justify-center" hover>
                <v-expansion-panel>
                  <v-expansion-panel-header class="justify-center font-normal" disable-icon-rotate expand-icon="more_horiz">
                    {{ item.studentList[0].student }} и ещё {{ item.studentList.length - 1 }}
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <ul>
                      <li v-for="student in item.studentList" :key="student.id">
                        {{ student.student }}
                      </li>
                    </ul>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </div>
          </template>

          <template v-slot:item.actions="{ item }">
            <v-row>
              <v-col v-if="(isTeacher() || isAdmin()) && item.ticketStatus === 'PENDING'">
                <v-tooltip bottom color="green">
                  <template v-slot:activator="{ on, attrs }">
                    <div v-on="on">
                      <v-btn
                          v-if="(isTeacher() && item.ticketStatus === 'PENDING') && !isAdmin()"
                          :disabled="changingStatus"
                          :loading="changingStatus"
                          color="green"
                          @click="showDialog(item, 0)"
                          v-bind="attrs"
                          v-on="on"
                      >
                        <v-icon>done</v-icon>
                      </v-btn>
                      <v-btn
                          v-if="isAdmin()"
                          :disabled="changingStatus"
                          :loading="changingStatus"
                          color="green"
                          @click="acceptTicket(item)"
                          v-bind="attrs"
                          v-on="on"
                      >
                        <v-icon>done</v-icon>
                      </v-btn>
                    </div>
                  </template>
                  <span>Подписать</span>
                </v-tooltip>
              </v-col>
              <v-col v-if="(isTeacher() || isAdmin()) && item.ticketStatus === 'PENDING'">
                <v-tooltip bottom color="red">
                  <template v-slot:activator="{ on, attrs }">
                    <div v-on="on">
                      <v-btn
                          v-if="(isTeacher() || isAdmin()) && item.ticketStatus === 'PENDING'"
                          :disabled="changingStatus"
                          :loading="changingStatus"
                          color="red"
                          @click="showDialog(item, 1)"
                          v-bind="attrs"
                          v-on="on"
                      >
                        <v-icon>close</v-icon>
                      </v-btn>
                    </div>
                  </template>
                  <span>Отказать</span>
                </v-tooltip>
              </v-col>
              <v-col v-if="(isGroupLeader() && item.ticketStatus === 'PENDING') || isAdmin()">
                <v-tooltip bottom color="secondary">
                  <template v-slot:activator="{ on, attrs }">
                    <div v-on="on">
                      <v-btn
                          v-if="(isGroupLeader() && item.ticketStatus === 'PENDING') && !isAdmin()"
                          :disabled="changingStatus"
                          :loading="changingStatus"
                          color="secondary"
                          @click="showDialog(item, 2)"
                          v-bind="attrs"
                          v-on="on"
                      >
                        <v-icon>delete</v-icon>
                      </v-btn>
                      <v-btn
                          v-if="isAdmin() && item.ticketStatus !== 'DELETED'"
                          :disabled="changingStatus"
                          :loading="changingStatus"
                          color="secondary"
                          @click="deleteTicket(item)"
                          v-bind="attrs"
                          v-on="on"
                      >
                        <v-icon>delete</v-icon>
                      </v-btn>
                    </div>
                  </template>
                  <span>Удалить</span>
                </v-tooltip>
              </v-col>
            </v-row>
          </template>


        </v-data-table>
      </v-col>
    </v-row>
    <v-container>
      <v-alert
          v-model="alert"
          dismissible
          :type="alertType"
          transition="fade-transition"
      >
        {{ alertText }}
      </v-alert>
    </v-container>

    <template v-if="isTeacher() || isAdmin()">
      <v-row justify="center">
        <v-dialog
            v-model="acceptDialog"
            width="600px"
        >
          <v-card v-if="acceptDialog !== false">
            <v-toolbar
                dark
                color="primary"
            >
              <v-card-title>Подписать служебную записку?</v-card-title>
              <v-spacer/>
              <v-btn
                  icon
                  dark
                  @click="acceptDialog = false"
              >
                <v-icon>close</v-icon>
              </v-btn>
            </v-toolbar>

            <v-card-text>
              <v-card-text>
                Вы уверены?
              </v-card-text>

            <v-card-actions>
              <v-divider/>
              <v-btn
                  color="secondary"
                  text
                  @click="acceptDialog = false"
              >
                <v-icon left>close</v-icon>
                Отмена
              </v-btn>
              <v-btn text color="primary" @click="acceptTicket(selectedTicket)">
                <v-icon left>done</v-icon>
                Подписать
              </v-btn>
            </v-card-actions>
            </v-card-text>
          </v-card>
        </v-dialog>
      </v-row>
    </template>

    <template v-if="isTeacher() || isAdmin()">
      <v-row justify="center">
        <v-dialog
            v-model="declineDialog"
            width="600px"
        >
          <v-card v-if="declineDialog !== false">
            <v-toolbar
                dark
                color="primary"
            >
              <v-card-title>Не подписывать служебную записку?</v-card-title>
              <v-spacer/>
              <v-btn
                  icon
                  dark
                  @click="declineDialog = false"
              >
                <v-icon>close</v-icon>
              </v-btn>
            </v-toolbar>

            <v-card-text>
              <v-card-text>
                Вы уверены?
              </v-card-text>

              <v-card-actions>
                <v-divider/>
                <v-btn
                    color="secondary"
                    text
                    @click="declineDialog = false"
                >
                  <v-icon left>close</v-icon>
                  Отмена
                </v-btn>
                <v-btn text color="primary" @click="declineTicket(selectedTicket)">
                  <v-icon left>close</v-icon>
                  Отказать
                </v-btn>
              </v-card-actions>
            </v-card-text>
          </v-card>
        </v-dialog>
      </v-row>
    </template>

    <template v-if="isGroupLeader() || isAdmin()">
      <v-row justify="center">
        <v-dialog
            v-model="deleteDialog"
            width="600px"
            persistent
        >
          <v-card v-if="deleteDialog !== false">
            <v-toolbar
                dark
                color="primary"
            >
              <v-card-title>Удалить служебную записку?</v-card-title>
              <v-spacer/>
              <v-btn
                  icon
                  dark
                  @click="deleteDialog = false"
              >
                <v-icon>close</v-icon>
              </v-btn>
            </v-toolbar>

            <v-card-text>
              <v-card-text>
                Вы уверены?
              </v-card-text>

              <v-card-actions>
                <v-divider/>
                <v-btn
                    color="secondary"
                    text
                    @click="deleteDialog = false"
                >
                  <v-icon left>close</v-icon>
                  Отмена
                </v-btn>
                <v-btn text color="primary" @click="deleteTicket(selectedTicket)">
                  <v-icon left>delete</v-icon>
                  Удалить
                </v-btn>
              </v-card-actions>
            </v-card-text>
          </v-card>
        </v-dialog>
      </v-row>
    </template>
  </div>
</template>

<script>
import date_utils from "@/settings/date_utils";
import {HTTP} from "@/settings/axios";
import Settings from "@/settings/settings_url";

export default {
  name: "TicketHistory",

  data() {
    return {
      ticketsHeaders: [
        {text: 'Создано', align: 'center', value: 'created', filterable: false, divider: true, sortable: true, class: "bolt"},
        {text: 'Обновлено', align: 'center', value: 'updated', filterable: false, divider: true, sortable: true, class: "bolt"},
        {text: 'Статус заявки', align: 'center', value: 'ticketStatus', filterable: true, divider: true, sortable: true, class: "bolt"},
        {text: 'Преподаватель', align: 'center', value: 'teacher', filterable: false, divider: true, sortable: true, class: "bolt"},
        {text: 'Группа', align: 'center', value: 'group.name', filterable: false, divider: true, sortable: true, class: "bolt"},
        {text: 'Пара', align: 'center', value: 'lesson', filterable: false, divider: true, sortable: true, class: "bolt"},
        {text: 'Студенты', align: 'center', value: 'studentVisits', filterable: false, divider: true, sortable: true, class: "bolt"},
        {text: 'Создал', align: 'center', value: 'createdBy', filterable: false, divider: true, sortable: true, class: "bolt"},
        {text: 'Действия', align: 'center', value: 'actions', filterable: false, divider: true, sortable: true, class: "bolt", width: "300px"},
      ],
      tickets: [],
      ticketsLoading: false,
      changingStatus: false,

      alert: false,
      alertType: null,
      alertText: null,

      groupId: null,
      teacherId: null,

      selectedYear: null,
      years: [],
      yearLoading: false,

      selectedHalfYear: null,
      halfYears: [],
      halfYearLoading: false,

      acceptDialog: false,
      declineDialog: false,
      deleteDialog: false,
      selectedTicket: null,

      selectedStatus: {"en": "", "ru": "Все", color: "white"},
      statuses: [
        {"en": "", "ru": "Все", color: "white"},
        {"en": "PENDING", "ru": "На рассмотрении", color: "orange"},
        {"en": "ACCEPTED", "ru": "Подписаны", color: "green"},
        {"en": "DECLINED", "ru": "Отказаны", color: "red"},
      ],

      studentList: [],
      dateList: [],
    }
  },

  methods: {
    showDialog(item, i) {
      if(i === 0)
        this.acceptDialog = true
      else if(i === 1)
        this.declineDialog = true
      else if(i === 2)
        this.deleteDialog = true

      this.selectedTicket = item
    },
    getYear: async function () {
      await HTTP.get(Settings.API.URL_DEFAULT + "/year-of-study")
          .then((response) => {
            this.years = response.data
          })
          .catch(() => {
            this.alert = true
            this.alertType = "error"
            this.alertText = "Произошла ошибка при получении годов обучения"
          })
          .finally(() => {
            for (let i = 0; i < this.years.length; i++) {
              if (this.years[i]?.current == 1) {
                this.selectedYear = this.years[i]
                break
              }
            }
          });
    },
    getHalfYear: async function () {
      await HTTP.get(Settings.API.URL_DEFAULT + "/half-year")
          .then((response) => {
            this.halfYears = response.data
          })
          .catch(() => {
            this.alert = true
            this.alertType = "error"
            this.alertText = "Произошла ошибка при получении полугодий"
          })
          .finally(() => {
            for (let i = 0; i < this.halfYears.length; i++) {
              if (this.halfYears[i].current == 1) {
                this.selectedHalfYear = this.halfYears[i]
                break
              }
            }
          });
    },
    acceptTicket(ticket) {
      this.changingStatus = true

      ticket.ticketStatus = 'ACCEPTED'

      HTTP.post(Settings.API.URL_DEFAULT + "/student-visit-ticket/change-status", ticket)
          .then((response) => {
            ticket = response.data
            this.alert = true
            this.alertType = "success"
            this.alertText = "Заявка успешно принята"
            this.getTickets()
          })
          .catch(() => {
            this.alert = true
            this.alertType = "error"
            this.alertText = "При удалении заявки произошла ошибка"
          })
          .finally(() => {
            this.changingStatus = false
            this.acceptDialog = false
          });
    },
    declineTicket(ticket) {
      this.changingStatus = true

      ticket.ticketStatus = 'DECLINED'

      HTTP.post(Settings.API.URL_DEFAULT + "/student-visit-ticket/change-status", ticket)
          .then((response) => {
            ticket = response.data
            this.alert = true
            this.alertType = "success"
            this.alertText = "Заявке успешно отказано"
            this.getTickets()
          })
          .catch(() => {
            this.alert = true
            this.alertType = "error"
            this.alertText = "При удалении заявки произошла ошибка"
          })
          .finally(() => {
            this.changingStatus = false
            this.declineDialog = false
          });
    },
    deleteTicket(ticket) {
      this.changingStatus = true

      ticket.ticketStatus = 'DELETED'

      HTTP.post(Settings.API.URL_DEFAULT + "/student-visit-ticket/change-status", ticket)
          .then((response) => {
            ticket = response.data
            this.alert = true
            this.alertType = "success"
            this.alertText = "Заявка успешно удалена"
            this.getTickets()
          })
          .catch(() => {
            this.alert = true
            this.alertType = "error"
            this.alertText = "При удалении заявки произошла ошибка"
          })
          .finally(() => {
            this.changingStatus = false
            this.deleteDialog = false
          });
    },
    getTickets() {
      this.ticketsLoading = true

      let url = ""

      if(this.historyFor === 'STUDENT') {
        url = "/student-visit-ticket/history/this-semester?groupId="
            + this.groupId
      } else if(this.historyFor === 'TEACHER') {
        url = "/student-visit-ticket/history/this-semester?teacherId="
            + this.teacherId
      } else if(this.historyFor === 'ADMIN') {
        url = "/student-visit-ticket/history?halfYearId=" + this.selectedHalfYear.id + "&yearId=" + this.selectedYear.id
      } else
        return

      HTTP.get(Settings.API.URL_DEFAULT + url)
          .then((response) => {
            this.tickets = response.data
          })
          .catch(() => {

          })
          .finally(() => {
            this.ticketsLoading = false
          })
    },
    getTicketColor(status) {
      if(status === 'PENDING')
        return "orange"
      else if(status === 'ACCEPTED')
        return "green"
      else if(status === 'DECLINED')
        return "red"
      else if(status === 'DELETED')
        return "gray"

      return "white"
    },
    getTicketStatus(status) {
      if(status === 'PENDING')
        return "На рассмотрении"
      else if(status === 'ACCEPTED')
        return "Подписано"
      else if(status === 'DECLINED')
        return "Отказано"
      else if(status === 'DELETED')
        return "Удалено"

      return "Неизвестно"
    },
    formatDate(date) {
      return date_utils.formatDateShort(date)
    },
    formatDateTime(date) {
      return date_utils.formatDateTimeShort(date)
    },
    isGroupLeader() {
      return this.isRole("ROLE_GROUP_LEADER")
    },
    isTeacher() {
      return this.isRole("ROLE_TEACHER")
    },
    isAdmin() {
      return this.isRole("ROLE_ADMIN")
    },
    isRole(role) {
      if (this.$store.getters.selectedUser?.role != null && this.$store.getters.selectedUser?.role.length > 0){
        for (let i = 0; i < this.$store.getters.selectedUser?.role.length; i++){
          if (
              this.$store.getters.selectedUser?.role[i].name === role
          ){
            return true
          }
        }
      }else {
        return  false
      }
    },
  },
  watch: {
    'groupId'() {
      if((this.historyFor === 'STUDENT' && this.groupId)
          || (this.historyFor === 'TEACHER' && this.teacherId))
        this.getTickets()
    },
    'teacherId'() {
      if((this.historyFor === 'STUDENT' && this.groupId)
          || (this.historyFor === 'TEACHER' && this.teacherId))
        this.getTickets()
    },
    'selectedHalfYear'() {
      if(this.selectedHalfYear && this.selectedYear && this.historyFor === 'ADMIN')
        this.getTickets()
    },
    'selectedYear'() {
      if(this.selectedHalfYear && this.selectedYear && this.historyFor === 'ADMIN')
        this.getTickets()
    },
  },

  created() {
    if(this.historyFor === 'ADMIN') {
      this.getHalfYear()
      this.getYear()
      this.statuses.push(
          {"en": "DELETED", "ru": "Удалены", color: "gray"}
      )
      this.selectedStatus.push(
          {"en": "DELETED", "ru": "Удалены", color: "gray"}
      )
    }

    if((this.historyFor === 'STUDENT' && this.groupId)
        || (this.historyFor === 'TEACHER' && this.teacherId)
        || (this.halfYearId && this.yearId && this.historyFor === 'ADMIN')
    ) {
      this.getTickets()
    }
  },
  props:{
    historyFor: {
      type: String,
      default: null,
    },
  },
}
</script>

<style scoped>
.font-normal {
  font-size: .875rem !important;
}
.mouse-hover-finger {
  cursor: pointer !important;
  margin: 15px 0;
}
</style>