
<template>
<v-content>
<!--  <v-row><v-col> </v-col></v-row>-->
<!--  <v-row><v-col md="2"></v-col>-->
<!--    <v-col>-->

      <h2 class ="mb-5" align="center"  >{{header.toLocaleUpperCase()}} {{curusers.username.toLocaleUpperCase()}}</h2>

<!--  </v-col>-->
<!--  </v-row>-->
<!--  <v-row><v-col> </v-col></v-row>-->
<!--  <v-row>-->
<!--    <v-col ></v-col>-->
<!--    <v-col >-->
  <v-data-table
      :headers="headers"
      :items="items"
      :items-per-page="15"
      class="elevation-1"
  >
          <template v-slot:item.date="{ item }">
            {{getDate(item) }}

          </template>
  </v-data-table>
<!--    </v-col>-->
<!--    <v-col md="3"></v-col>-->
<!--    </v-row>-->
</v-content>
</template>

<script>
import Settings from "@/settings/settings_url";
import {HTTP} from '@/settings/axios'
import date_utils from "@/settings/date_utils";

export default {
//name: "InfoStudVisit"
  data () {
    return {
      header:"История посещений. Пользователь:",
      headers: [
        {
            text: ' ',
          align: 'left',
          sortable: false,
          value: ' '
  },

        {
          text: 'Зона посещения',
          align: 'left',
          sortable: false,
          value: 'description'
        },

        {
          text: 'Дата и время ',
          align: 'center',
          sortable: false,
          value: 'date'
        },

      ],

      items: [ ],
      curusers: [],

          }
        },

  methods: {
    getDate: function (item){
      return date_utils.formatDateTime(item.date)
      // const d = new Date(Date.parse(item.date));
      // const dt = ("0" + d.getDate()).slice(-2) + "-" + ("0"+(d.getMonth()+1)).slice(-2) + "-" +
      //     d.getFullYear() + " " + ("0" + d.getHours()).slice(-2) + ":" + ("0" + d.getMinutes()).slice(-2);
      // return dt;
    },

      updateHistV: function (){
      HTTP.get(Settings.API.URL_DEFAULT + "/event-orion/by-current-date-for-current-user" )
          .then((response) => {
            console.log(response.data)
            this.items = response.data
              })
          .catch(() => {

          })
          .finally(() => {

          });
    },
    getCurUser: function () {
      HTTP.get(Settings.API.URL_DEFAULT + "/users/current-user" )
          .then((response) => {
            console.log(response.data)
            this.curusers = response.data
          })
          .catch(() => {

          })
          .finally(() => {

          });

    }

    //Group
    // getGroups: function (){
    //
    //     HTTP.get(Settings.API.URL_DEFAULT + "/groups/by-year-and-half-year?idHalfYear=" + this.halfYear.id + "&idYear=" + this.year.id)
    //         .then((response) => {
    //           this.groups = response.data
    //         })
    //         .catch(() => {
    //
    //         })
    //         .finally(() => {
    //         });
    // },
    //Student
    // getStudent: function (){
    //
    //     HTTP.get(Settings.API.URL_DEFAULT + "/groups/" + this.group.id + "/students")
    //         .then((response) => {
    //           console.log(response.data)
    //           this.studs = response.data
    //
    //         })
    //         .catch(() => {
    //
    //         })
    //         .finally(() => {
    //
    //         });
    //
    // },
  },
  created() {
    this.updateHistV()
    this.getCurUser()
  },

}
</script>

<style scoped>

</style>