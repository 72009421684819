<template>
  <div>

    <div v-if="false" class="progress-barr">
      <div class="progress">
        <v-progress-circular
            :size="100"
            :width="10"
            color="blue"
            indeterminate
        ></v-progress-circular>
      </div>
    </div>
    <v-app id="schedule">
      <v-container justify-center>
        <v-flex xs12>
          <v-card>
            <v-card-text>
              <v-layout row wrap>
                <v-flex xs12 md12>
                  <v-card-actions class="justify-center">
                    <h1 v-if="year < 3">Ведомость - {{ visits.title }}</h1>
                    <h1 v-else>Ведомость - {{ examSheet.group.name }} {{ examSheet.discipline.name }}</h1>
                  </v-card-actions>

                </v-flex>
                <v-flex xs12 md6>

                </v-flex>
                <v-flex xs12 md6>

                </v-flex>
              </v-layout>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>

            </v-card-actions>
          </v-card>
        </v-flex>
      </v-container>
      <div v-if="year < 3" class="mb-15">
        <v-container justify-center>
          <v-flex xs12>
            <v-data-table
                :headers="headers"
                :items="visits.examJournalVedItem"
                :items-per-page="80"
                :hide-default-footer="true"
                class="elevation-2 "
            >

              <!--            <template v-slot:top>-->
              <!--              <v-row>-->
              <!--                <v-col>-->
              <!--                  <div class="text-left ">-->
              <!--                    <v-switch-->
              <!--                        v-model="all"-->
              <!--                        label="Все"-->
              <!--                        class="pa-3"-->
              <!--                    ></v-switch>-->
              <!--                  </div>-->
              <!--                </v-col>-->
              <!--              </v-row>-->
              <!--            </template>-->

              <!--            <template v-slot:header="{ props }">-->
              <!--              <thead class="v-data-table-header">-->
              <!--              <tr>-->
              <!--                <th class="text-start v-data-table__divider" v-for="head in props.headers" :key="head.value">-->
              <!--                  <div >-->
              <!--                    {{ head.text }}-->
              <!--                  </div>-->

              <!--                  <v-btn-->
              <!--                      v-if="head.lessonId != null"-->
              <!--                      icon-->
              <!--                      @click="showDialogUploadFile(head.date, head.lessonId)"-->
              <!--                  >-->
              <!--                    <v-icon small>file_upload</v-icon>-->
              <!--                  </v-btn>-->
              <!--                </th>-->
              <!--              </tr>-->
              <!--              </thead>-->
              <!--            </template>-->

              <template v-slot:item="item">
                <tr>
                  <td class="td-border-right v-data-table__divider" v-for="header in headers" :key="header.value"
                      @click="showDialog(item.item, header.value)">
                    <div v-if="header.value == 'ved'">
                      {{ content(item.item, header.value) }}
                    </div>
                    <div v-else>
                      {{ item.item[header.value] }}
                    </div>
                  </td>
                </tr>
              </template>
              <!--            <template v-slot:item="item">-->
              <!--              <tr>-->
              <!--                <td class="td-border-right v-data-table__divider" v-for="header in headers"-->
              <!--                    :key="item.item[header.value].id">-->
              <!--                  {{ item.item[header.value].text }}-->
              <!--                </td>-->
              <!--              </tr>-->
              <!--            </template>-->
            </v-data-table>
          </v-flex>
        </v-container>
      </div>
      <div v-else class="mb-15">
        <GradeSheet v-if="$store.getters.isAdmin || $store.getters.isTeacher"
            :gradeSheetId="examSheet.id"
        />
      </div>
      <template>
        <v-row justify="center">
          <v-dialog
              v-model="dialog"
              width="600px"
              persistent
          >
            <v-card>
              <v-card-title>
                <span class="headline">Редактировать успеваемость</span>
              </v-card-title>

              <v-card-text>
                <p>Студент - <b> {{ visitItem.name }} </b></p>
                <!--                <p>Дисциплина - {{ visitItem.workload.workPlan.discipline.name }} </p>-->
                <!--                <p>Тип - {{ visitItem.workload.workPlan.lessonTypes.name }} </p>-->
                <!--                <p>Дата - {{ visitItem.date }} </p>-->
                <!--                <p>Статус - <b> {{ isVisitDialog(visitItem) + "(а)"}} </b> </p>-->
                <v-switch
                    v-model="visitItem.visit"
                    :label="`${visitItem.visit? 'Присутствовал': 'Не явился'}`"
                ></v-switch>
                <p>Оценка:</p>
                <v-card-text>
                  <v-text-field
                      v-model="visitItem.ved"
                      hint="Введите заработанные баллы"
                      :rules="rules"
                      :label="'Максимальный балл -' + maxEvaluation"
                  ></v-text-field>
                  <h2 style="margin-bottom: 5px">Статистика</h2>
                  <p>Посещение - <b> {{ visitItem.visitPoint }} </b></p>
                  <p>Контр. точки - <b> {{ visitItem.controlPoint }} </b></p>
                  <p>Научная деятельность - <b> {{ visitItem.scientificActivityPoint }} </b></p>
                  <p>Компенсация - <b> {{ visitItem.compensationPoint }} </b></p>
                  <p>Итого - <b> {{ visitItem.sum }} </b></p>
                  <h3>Оценка - <b> {{ visitItem.grade }} </b></h3>
                </v-card-text>
                <!--                              <p>Оценка - {{ visitItem.evaluation != null ? visitItem.evaluation.toFixed(1): "" }} </p>-->
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    color="green darken-1"
                    text
                    @click="cancel"
                >
                  cancel
                </v-btn>
                <v-btn
                    color="red darken-1"
                    text
                    @click="updateEvaluationGradeBook"
                >
                  Ок
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-row>
      </template>


      <!--      <template>-->
      <!--        <v-row justify="center">-->
      <!--          <v-dialog-->
      <!--              v-model="dialogUploadFile"-->
      <!--              width="600px"-->
      <!--              persistent-->
      <!--          >-->
      <!--            <v-card>-->
      <!--              <v-card-title>-->
      <!--                <span class="headline">Загрузить файл</span>-->
      <!--              </v-card-title>-->

      <!--              <v-card-text>-->
      <!--                <template>-->
      <!--                  <v-file-input-->
      <!--                      v-model="files"-->
      <!--                      placeholder="Upload your documents"-->
      <!--                      label="File input"-->
      <!--                      multiple-->
      <!--                      prepend-icon="mdi-paperclip"-->
      <!--                  >-->
      <!--                    <template v-slot:selection="{ text }">-->
      <!--                      <v-chip-->
      <!--                          small-->
      <!--                          label-->
      <!--                          color="primary"-->
      <!--                      >-->
      <!--                        {{ text }}-->
      <!--                      </v-chip>-->
      <!--                    </template>-->
      <!--                  </v-file-input>-->
      <!--                  &lt;!&ndash;                                <v-btn&ndash;&gt;-->
      <!--                  &lt;!&ndash;                                    color="primary"&ndash;&gt;-->
      <!--                  &lt;!&ndash;                                    dark&ndash;&gt;-->
      <!--                  &lt;!&ndash;                                    text&ndash;&gt;-->
      <!--                  &lt;!&ndash;                                    @click="uploadFile"&ndash;&gt;-->
      <!--                  &lt;!&ndash;                                >&ndash;&gt;-->
      <!--                  &lt;!&ndash;                                  Загрузить&ndash;&gt;-->
      <!--                  &lt;!&ndash;                                </v-btn>&ndash;&gt;-->
      <!--                </template>-->
      <!--              </v-card-text>-->

      <!--              <v-card-actions>-->
      <!--                <v-spacer></v-spacer>-->
      <!--                <v-btn-->
      <!--                    color="green darken-1"-->
      <!--                    text-->
      <!--                    @click="dialogUploadFile = !dialogUploadFile"-->
      <!--                >-->
      <!--                  cancel-->
      <!--                </v-btn>-->
      <!--                <v-btn-->
      <!--                    color="green darken-1"-->
      <!--                    text-->
      <!--                    @click="uploadFile"-->
      <!--                >-->
      <!--                  ОК-->
      <!--                </v-btn>-->
      <!--              </v-card-actions>-->
      <!--            </v-card>-->
      <!--          </v-dialog>-->
      <!--        </v-row>-->
      <!--      </template>-->

    </v-app>

  </div>
</template>

<script>

import Settings from "@/settings/settings_url";
import {HTTP} from '@/settings/axios'
import GradeSheet from "@/components/gradeSheet/GradeSheet";

export default {
  name: "Gradebook",
  props: {
    groupId: Number,
    disciplineId: Number,
    year: Number,
    halfYear: Number
  },
  components: {GradeSheet},
  data() {
    return {
      headers: [
        {text: 'Студент', align: 'left', sortable: false, value: 'name', divider: true, class: "bolt"},
        // {text: 'Посещение', align: 'center', value: 'visitPoint', divider: true, sortable: false},
        // {text: 'Контр. точки', align: 'center', value: 'controlPoint', divider: true, sortable: false},
        // {
        //   text: 'Научная деятельность',
        //   align: 'center',
        //   value: 'scientificActivityPoint',
        //   divider: true,
        //   sortable: false
        // },
        // {
        //   text: 'Компенсация',
        //   align: 'center',
        //   value: 'compensationPoint',
        //   divider: true,
        //   sortable: false
        // },
        {text: 'Ведомость', align: 'center', value: 'ved', divider: true, sortable: false, class: "bolt"},
        // {text: 'Итого', align: 'center', value: 'sum', divider: true, sortable: false},
        // {text: 'Оценка', align: 'center', value: 'grade', divider: true, sortable: false, class: "bolt"},
      ],
      loading: false,
      all: true,
      files: [],
      visits: {},
      dialog: false,
      maxEvaluation: 0,
      rules: [v => v <= this.maxEvaluation || 'Max ' + this.maxEvaluation + ' Баллов'],
      visitItem: {
        // date: "",
        ved: 0,
        visit: 0,
        name: "",
        // workload: {
        //   workPlan: {
        //     discipline: {
        //       name: ""
        //     },
        //     lessonTypes: {
        //       name: ""
        //     }
        //   }
        // },
      },


      examSheet: {
        id:null,
        group:{
          name: "",
        },
        discipline: {
          name: "",
        }
      },
    }
  },
  watch: {
    all: function () {
      this.getJournal()
    },
    year(){
      this.updateTable()
    },
    halfYear(){
      this.updateTable()
    },
    groupId: function () {
      this.updateTable()

    },
    disciplineId: function () {
      this.updateTable()
    },
  },
  methods: {
    cancel() {
      this.dialog = false
      // this.getJournal()
    },
    updateTable: function () {

      if (this.groupId != undefined && this.groupId != 0 && this.year != undefined && this.year != 0
          && this.halfYear != undefined && this.halfYear != 0
          && this.disciplineId != undefined && this.disciplineId != 0) {

        // if (this.visits.role === 'ROLE_TEACHER' || this.visits.role === 'ROLE_ADMIN') {
        if (this.$store.getters.isAdmin || this.$store.getters.isTeacher) {
          this.getJournal()
        }
      }
    },
    responseVisitUpdate: function (stud) {
      console.log(stud)
      for (let i = 0; i < this.visits.examJournalVedItem.length; i++) {
        if (this.visits.examJournalVedItem[i].id == stud.id) {
          // console.log(this.visits.students[i])
          this.visits.examJournalVedItem[i].ved = stud.ved
          this.visits.examJournalVedItem[i].visit = stud.visit
          this.visits.examJournalVedItem[i].grade = stud.grade
          this.visits.examJournalVedItem[i].sum = stud.sum
          break
        }
      }
      this.calcSum()
    },
    calcSum: function () {


      for (let i = 0; i < this.visits.examJournalVedItem.length; i++) {

        if (this.visits.examJournalVedItem[i].visit == 1) {
          this.visits.examJournalVedItem[i].sum = (this.visits.examJournalVedItem[i].compensationPoint +
              this.visits.examJournalVedItem[i].controlPoint +
              this.visits.examJournalVedItem[i].scientificActivityPoint +
              this.visits.examJournalVedItem[i].ved +
              this.visits.examJournalVedItem[i].visitPoint).toFixed(2);
        } else {
          this.visits.examJournalVedItem[i].sum = 0
        }

      }

    },

    content: function (item, head) {
      if (head == 'ved') {
        if (item['visit'] == 1) {
          return item[head] != null ? item[head] : ""
        } else {
          return item[head] != null ? item[head] /*+ " Не явился"*/ : ""
        }
      }
      return item[head]
    },
    showDialog: function (item, head) {
      if (head == 'ved' && item.id !== 0 && item.id !== undefined && item.id !== null && (this.visits.role === "ROLE_TEACHER" || this.visits.role === "ROLE_ADMIN")) {
        console.log(item)
        this.getMaxEvaluation(item.ved)
        this.dialog = true
        this.visitItem = {...item}
        this.getJournalItem()
      }
    },
    getMaxEvaluation: function (item) {
      console.log(item)
      this.maxEvaluation = 20
    },

    updateEvaluationGradeBook: function () {
      this.loading = true
      if (this.visitItem.ved > this.maxEvaluation) {
        this.loading = false
        return
      }
      //if (this.evaluationItem.maxEvaluation >= this.evaluationItem.text) {
      HTTP.put(Settings.API.URL_REGISTRATION_OF_STUDENT_VISIT_UPDATE_EVALUATION_EXAM(this.visitItem.id), {
        evaluation: this.visitItem.ved,
        visit: this.visitItem.visit ? 1 : 0
      }).then((response) => {
        this.responseVisitUpdate(response.data)
        //this.evaluationItem.evaluation = response.data.evaluation
      }).catch(() => {
        this.errored = true;
        // this.getJournal()
      }).finally(() => {
        this.loading = false
        this.dialog = false
      });
      // }
    },

    getJournal: function() {

      let groupId = this.groupId
      let disciplineId = this.disciplineId

      if (groupId == 0 || disciplineId == 0) {
        groupId = this.$route.query.group
        disciplineId = this.$route.query.discipline
      }

      if (groupId == 0 || disciplineId == 0 || groupId == undefined || disciplineId == undefined) {
        console.log("ret")
        return
      }

      if (this.year < 3){
        this.getJournalOld(groupId, disciplineId)
      }else {
        this.getExamSheet(groupId, disciplineId);
      }
    },
    getExamSheet: async function(groupId, disciplineId) {
      this.loading = true
      this.examSheet = {
        id:null,
        group:{
          name: "",
        },
        discipline: {
          name: "",
        }
      }
      await HTTP.get(Settings.API.URL_DEFAULT + "/grade-sheet/main?groupId=" + groupId + "&disciplineId=" + disciplineId + "&year=" + this.year + "&halfYear=" + this.halfYear )
          .then((response) => {
            this.examSheet = response.data
            console.log(this.examSheet)
            this.loading = false;
          })
          .catch(() => {
            this.errored = true;
            this.loading = false;
          })
          .finally(() => {
            // this.calcSum()
            this.loading = false
            console.log(this.loading)
          });
    },
    getJournalOld: async function (groupId, disciplineId) {
      // console.log("sdsdsdd")
      this.loading = true
      await HTTP.get(Settings.API.URL_DEFAULT_V + "/registration-of-student/exam-book-new?groupid=" + groupId + "&disciplineid=" + disciplineId + "&yearId=" + this.year + "&halfYearId=" + this.halfYear )
          .then((response) => {
            this.visits = response.data
            // this.title = response.data.title
            // this.headers = response.data.headers
            // this.students = response.data.students
            // this.role = response.data.role
            console.log(this.visits)
          })
          .catch(() => {
            this.errored = true;
          })
          .finally(() => {
            // this.calcSum()
            this.loading = false
          });
    },
    getJournalItem: async function () {

      let groupId = this.groupId
      let disciplineId = this.disciplineId

      if (groupId == 0 || disciplineId == 0) {
        groupId = this.$route.query.group
        disciplineId = this.$route.query.discipline
      }

      if (groupId == 0 || disciplineId == 0 || groupId == undefined || disciplineId == undefined) {
        return
      }
      this.loading = true
      await HTTP.get(Settings.API.URL_DEFAULT_V + "/registration-of-student/exam-book-for-student?studentVisitId=" + this.visitItem.id+ "&yearId=" + this.year + "&halfYearId=" + this.halfYear )
          .then((response) => {
            this.visitItem = response.data
            // this.title = response.data.title
            // this.headers = response.data.headers
            // this.students = response.data.students
            // this.role = response.data.role
            console.log(this.visitItem)
          })
          .catch(() => {
            this.errored = true;
          })
          .finally(() => {
            // this.calcSum()
            this.loading = false
          });
    },

  },

  mounted() {
    this.getJournal()
  },
}
</script>

<style lang="sass">


</style>
