<template>
  <v-app id="sandbox">
    <v-navigation-drawer
        v-model="settings_primary_drawer_model"
        :permanent="settings_primary_drawer_type === 'permanent'"
        :temporary="settings_primary_drawer_type === 'temporary'"
        :clipped="settings_primary_drawer_clipped"
        :floating="settings_primary_drawer_floating"
        :mini-variant="settings_primary_drawer_mini"
        overflow
        app
        class="d-print-none"
    >
      <!--      // <img src="./assets/pictures/logosseu.svg" />-->
      <v-list dark color="#015188" class="navigation-drawer-top-list">
        <v-list-item class="navigation-drawer-top-item">
          <v-list-item-icon>
            <v-icon> account_balance</v-icon>

          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title class="title">
              СГЭУ
            </v-list-item-title>
            <!--            <v-list-item-subtitle>-->
            <!--         account_balance      Самара-->
            <!--            </v-list-item-subtitle>-->
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <v-divider></v-divider>
      <v-list>
        <v-list-item
            href="https://lms3.sseu.ru/schedule-board/schedule-all"
            target="_blank"
        >

          <v-list-item-icon>
            <v-icon>schedule</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Расписание</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <v-list>
        <v-list-item
            v-for="(item, i) in nav_items"
            :key="i"
            link
            :to="item.url"
        >

          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ item.text }}</v-list-item-title>
          </v-list-item-content>

        </v-list-item>
        <v-list-item
            link
            @click="exit"
        >

          <v-list-item-icon>
            <v-icon>exit_to_app</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>Выход</v-list-item-title>
          </v-list-item-content>

        </v-list-item>
      </v-list>

      <v-spacer></v-spacer>
      <v-divider></v-divider>
      <v-list>
        <v-list-item
            @click="settings_primary_drawer_mini = !settings_primary_drawer_mini"
        >

          <v-list-item-icon>
            <v-icon v-if="settings_primary_drawer_mini">navigate_next</v-icon>
            <v-icon v-else>navigate_before</v-icon>
          </v-list-item-icon>

        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar :clipped-left="settings_primary_drawer_clipped" app dark color="#015188" class="d-print-none">
      <v-app-bar-nav-icon
          v-if="settings_primary_drawer_type !== 'permanent'"
          @click.stop="settings_primary_drawer_model = !settings_primary_drawer_model"
      ></v-app-bar-nav-icon>
      <v-avatar class="ms-16">
        <img src="./assets/pictures/logosseu.svg"/>
      </v-avatar>
      <v-toolbar-title class="ms-4" to="/account"><a class="clr" href="/account"> #СГЭУЦИФРОВОЙ</a></v-toolbar-title>
      <v-spacer/>
      <div class="time" v-if="getCurrentRoute == 'CurrentLessons'">
        <v-toolbar-title class="timeTitle1">
          Время: {{ this.$store.getters.currentTime }}
        </v-toolbar-title>
        <v-toolbar-title class="timeTitle2">
          Пара: {{ this.$store.getters.currentLessonTime }}
        </v-toolbar-title>
      </div>
      <v-spacer/>
      <div v-if="isStudents()">
        <v-menu
            bottom
            offset-y
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
                v-bind="attrs"
                v-on="on"
                icon
            >
              <v-icon>people</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item
                v-for="(item, i) in this.$store.getters.students"
                :key="i"
                @click="selectStudent(item)"
            >
              <v-list-item-title>{{ item.groups.name }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>

      <!--      <v-btn icon @click="settings_color_themes = !settings_color_themes">-->
      <!--        <v-icon>people</v-icon>-->
      <!--      </v-btn>-->
      <LessInfo></LessInfo>
      <v-btn icon @click="settings_color_themes = !settings_color_themes">
        <v-icon>wb_incandescent</v-icon>
      </v-btn>
    </v-app-bar>


    <v-main>
      <v-container fluid>
        <router-view/>
      </v-container>
    </v-main>

    <v-footer :inset="settings_footer_inset" app dark color="#015188" class="d-print-none">
      <v-row class="d-flex flex-wrap">
        <!--        <v-col align="center">-->
        <!--          <v-row><v-col></v-col></v-row><v-row><v-col>1</v-col></v-row>-->
        <!--        </v-col>-->
        <!--        <v-col align="center" cols="12" xs="12" sm="12" md="4"> <v-row><v-col></v-col></v-row><v-row><v-col>2</v-col></v-row></v-col>-->
        <v-col align="center">
          <!--          <v-row cols="12" xs="12" sm="12" md="4"><v-col></v-col></v-row>-->
          <!--          <v-row cols="12" xs="12" sm="12" md="4"><v-col>3</v-col></v-row>-->
          <v-row>
            <v-col><span class="px-3">&copy; Самарский государственный экономический университет 2020 - {{
                new Date().getFullYear()
              }}</span></v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-footer>
  </v-app>
</template>

<script>
import Settings from "@/settings/settings_url";
import {HTTP} from '@/settings/axios'
import LessInfo from './components/lessInfo/LessInfo.vue'


export default {
  components: {
    LessInfo
  },
  data: () => ({
    student: false,
    admin: false,
    teacher: false,
    umu: false,
    guest: false,
    sdk: false,
    sdkSpo: false,
    allStatistic: false,
    ct: false,
    aspirant: false,
    groupLeader: false,
    items: [
      // {
      //   icon: "home",
      //   text: "Главная",
      //   url: "/",
      //   student: false,
      //   admin: true,
      //   teacher: false,
      //   umu: true,
      //   sdk: false,
      //   sdkSpo: false,
      //   allStatistic: false,
      //   ct: false,
      //   aspirant: false,
      // },
      {
        icon: "schedule",
        text: "Расписание предварительное.",
        url: "/schedule-board/schedule-all",
        student: true,
        admin: true,
        teacher: true,
        umu: true,
        guest: true,
        sdk: false,
        sdkSpo: false,
        allStatistic: false,
        ct: false,
        aspirant: true,
      },
      // {icon: "schedule", text: "Расписание студент", url: "/schedulest"},
      // {
      //   icon: "trending_up",
      //   text: "Статистика",
      //   url: "/statistics-report",
      //   student: false,
      //   admin: true,
      //   teacher: false,
      //   umu: false,
      //   sdkSpo: false,
      //   allStatistic: true,
      //   ct: false,
      //   sdk: false,
      //   aspirant: false,
      // },
      // {
      //   icon: "trending_up",
      //   text: "Карты успеваемости",
      //   url: "/learning-Activity/stat",
      //   student: false,
      //   admin: true,
      //   teacher: false,
      //   umu: false,
      //   sdkSpo: false,
      //   allStatistic: true,
      //   ct: false,
      //   sdk: false,
      //   aspirant: false,
      // },
      // {
      //   icon: "trending_up",
      //   text: "Стат по КТ",
      //   url: "/control-point/statistic-courses-lms",
      //   student: false,
      //   admin: true,
      //   teacher: false,
      //   umu: false,
      //   sdkSpo: false,
      //   allStatistic: true,
      //   ct: false,
      //   sdk: false,
      //   aspirant: false,
      // },

      // {
      //   icon: "trending_up",
      //   text: "Статистика",
      //   url: "/student-statistics",
      //   student: true,
      //   admin: false,
      //   teacher: false,
      //   umu: false,
      //   sdkSpo: false,
      //   allStatistic: false,
      //   ct: false,
      //   sdk: false,
      //   aspirant: false,
      // },
      // {
      //   icon: "alarm",
      //   text: "Журнал",
      //   url: "/journal-all",
      //   student: false,
      //   admin: true,
      //   teacher: false,
      //   umu: false,
      //   sdkSpo: true,
      //   allStatistic: false,
      //   ct: false,
      //   sdk: false,
      //   aspirant: false,
      // },

      // {
      //   icon: "alarm",
      //   text: "Журналы",
      //   url: "/journal-list",
      //   student: false,
      //   admin: false,
      //   teacher: true,
      //   umu: false,
      //   sdkSpo: false,
      //   allStatistic: false,
      //   ct: false,
      //   sdk: false,
      //   aspirant: false,
      // },
      // {
      //   icon: "trending_up",
      //   text: "Компл. тестирование",
      //   url: "/report/ct/comprehensive-testing",
      //   student: false,
      //   admin: false,
      //   teacher: false,
      //   umu: false,
      //   sdkSpo: false,
      //   allStatistic: false,
      //   ct: true,
      //   sdk: true,
      //   aspirant: false,
      //
      // },
      // {
      //   icon: "alarm",
      //   text: "Пересдачи",
      //   url: "/retake-list",
      //   student: false,
      //   admin: false,
      //   teacher: true,
      //   umu: false,
      //   sdkSpo: false,
      //   sdk: false
      // },
      // {
      //   icon: "alarm",
      //   text: "Пересдачи adm",
      //   url: "/retake-ved",
      //   student: false,
      //   admin: true,
      //   teacher: false,
      //   umu: false,
      //   sdkSpo: false,
      //   allStatistic: false,
      //   ct: false,
      //   sdk: false,
      //   aspirant: false,
      // },
      // {
      //   icon: "assessment",
      //   text: "Журнал усп...",
      //   url: "/gradebook-list",
      //   student: false,
      //   admin: false,
      //   teacher: true,
      //   umu: false,
      //   sdk: false
      // },
      // {
      //   icon: "assessment",
      //   text: "Контр. точки..",
      //   url: "/control-points-list",
      //   student: false,
      //   admin: false,
      //   teacher: true,
      //   umu: false,
      //   sdk: false
      // },
      // {
      //   icon: "assignment",
      //   text: "Экзамен, зачет",
      //   url: "/exam-list",
      //   student: false,
      //   admin: false,
      //   teacher: true,
      //   umu: false,
      //   sdkSpo: false,
      //   sdk: false
      // },
      // {
      //   icon: "assignment",
      //   text: "Печать",
      //   url: "/schedule-print",
      //   student: false,
      //   admin: true,
      //   teacher: false,
      //   umu: true
      // },
      // {icon: "report", text: "Контрольные точки", url: "/control-points-list"},
      // {icon: "report", text: "Отчет", url: "/report"},
      {
        icon: "dashboard_customize",
        text: "Расписание сетка",
        url: "/edit-schedule-table",
        student: false,
        admin: true,
        teacher: false,
        umu: true,
        sdkSpo: false,
        allStatistic: false,
        ct: false,
        sdk: false,
        aspirant: false,
      },
      {
        icon: "toc",
        text: "Расписание список",
        url: "/scheduleedit",
        student: false,
        admin: true,
        teacher: false,
        umu: true,
        sdkSpo: false,
        allStatistic: false,
        ct: false,
        sdk: false,
        aspirant: false,
      },
      // {
      //   icon: "settings",
      //   text: "Баллы освобождения",
      //   url: "/exemption",
      //   student: false,
      //   admin: true,
      //   teacher: false,
      //   umu: false,
      //   sdkSpo: false,
      //   allStatistic: false,
      //   ct: false,
      //   sdk: true,
      //   aspirant: false,
      // },
      // {
      //   icon: "history",
      //   text: "Мои посещения",
      //   url: "/journal-teacher",
      //   student: false,
      //   admin: false,
      //   teacher: true,
      //   umu: false,
      //   sdkSpo: false,
      //   allStatistic: false,
      //   ct: false,
      //   sdk: false,
      //   aspirant: false,
      // },
      // {
      //   icon: "history",
      //   text: "История посещений",
      //   url: "/info-visit",
      //   student: true,
      //   admin: false,
      //   teacher: false,
      //   umu: false,
      //   sdkSpo: false,
      //   allStatistic: false,
      //   ct: false,
      //   sdk: false,
      //   aspirant: false,
      // },
      // {
      //   icon: "settings",
      //   text: "Ред. К. точки..",
      //   url: "/edit-control-points",
      //   student: false,
      //   admin: true,
      //   teacher: true,
      //   umu: false,
      //   sdk: false
      // },
      // {
      //   icon: "settings",
      //   text: "Контр. точки..",
      //   url: "/admin-control-points",
      //   student: false,
      //   admin: true,
      //   teacher: false,
      //   umu: false
      // },
      {
        icon: "grading",
        text: "Контроль расписание",
        url: "/control-added-lessons",
        student: false,
        admin: true,
        teacher: false,
        umu: true,
        sdkSpo: false,
        allStatistic: false,
        ct: false,
        sdk: false,
        aspirant: false,
      },
      {
        icon: "schedule_send",
        text: "Выгрузить расписание",
        url: "/send-schedule",
        student: false,
        admin: true,
        teacher: false,
        umu: true,
        sdkSpo: false,
        allStatistic: false,
        ct: false,
        sdk: false,
        aspirant: false,
      },
      {
        icon: "event",
        text: "Календарь произв..",
        url: "/production-calendar-edit",
        student: false,
        admin: true,
        teacher: false,
        umu: false,
        sdk: false,
        sdkSpo: false,
        allStatistic: false,
        ct: false,
        aspirant: false,
      },
      // {
      //   icon: "info",
      //   text: "Общая статистика",
      //   url: "/statistics-report",
      //   student: false,
      //   admin: true,
      //   teacher: false,
      //   umu: true,
      //   sdk: false
      // },
      // {
      //   icon: "content_paste",
      //   text: "Справки",
      //   url: "/certificate/certificate",
      //   student: true,
      //   admin: true,
      //   teacher: false,
      //   umu: false,
      //   sdkSpo: false,
      //   allStatistic: false,
      //   ct: false,
      //   sdk: false,
      //   aspirant: true,
      // },
      // {
      //   icon: "info",
      //   text: "Справки",
      //   url: "/certificate/certificate",
      //   student: false,
      //   admin: true,
      //   teacher: false,
      //   umu: false,
      //   sdk: true
      // },
      // {
      //   icon: "info",
      //   text: "Заявки справок",
      //   url: "/certificate/order-certificate",
      //   student: false,
      //   admin: true,
      //   teacher: false,
      //   umu: false,
      //   sdkSpo: false,
      //   allStatistic: false,
      //   ct: false,
      //   sdk: true,
      //   aspirant: false,
      // },
      {
        icon: "groups",
        text: "Подгруппы",
        url: "/sub-group-edit",
        student: false,
        admin: true,
        teacher: false,
        umu: true,
        sdkSpo: true,
        allStatistic: false,
        ct: false,
        sdk: true,
        aspirant: false,
      },
      // {
      //   icon: "settings",
      //   text: "Ред.. справок",
      //   url: "/certificate/admin-certificate",
      //   student: false,
      //   admin: true,
      //   teacher: false,
      //   umu: false,
      //   sdkSpo: false,
      //   allStatistic: false,
      //   ct: false,
      //   sdk: true,
      //   aspirant: false,
      // },
      // {
      //   icon: "settings",
      //   text: "Коды команд",
      //   url: "/invite-code-for-group",
      //   student: false,
      //   admin: true,
      //   teacher: false,
      //   umu: true,
      //   sdk: false
      // },
      // {
      //   icon: "settings",
      //   text: "Контр.точ. админ",
      //   url: "/control-point-list-admin",
      //   student: false,
      //   admin: true,
      //   teacher: false,
      //   umu: false,
      //   sdkSpo: false,
      //   allStatistic: false,
      //   ct: false,
      //   sdk: false,
      //   aspirant: false,
      // },
      {
        icon: "settings",
        text: "Добавить нагрузку",
        url: "/workLoad-admin",
        student: false,
        admin: true,
        teacher: false,
        umu: true,
        sdkSpo: false,
        allStatistic: false,
        ct: false,
        sdk: false,
        aspirant: false,
      },
      // {
      //   icon: "settings",
      //   text: "Компенсация covid",
      //   url: "/compensation-admin",
      //   student: false,
      //   admin: true,
      //   teacher: false,
      //   umu: false,
      //   sdkSpo: false,
      //   allStatistic: false,
      //   ct: false,
      //   sdk: false,
      //   aspirant: false,
      // },

      // {
      //   icon: "sticky_note_2",
      //   text: "Зачетная книжка",
      //   url: "/recordbook",
      //   student: true,
      //   admin: false,
      //   teacher: false,
      //   umu: false,
      //   sdkSpo: false,
      //   allStatistic: false,
      //   sdk: false,
      //   ct: false,
      //   aspirant: false,
      // },
      // {
      //   icon: "sticky_note_2",
      //   text: "Учебная карточка",
      //   url: "/recordbook",
      //   student: false,
      //   admin: false,
      //   teacher: false,
      //   umu: false,
      //   sdkSpo: false,
      //   allStatistic: false,
      //   sdk: false,
      //   ct: false,
      //   aspirant: true,
      // },
      // {
      //   icon: "groups",
      //   text: "MsTeams",
      //   url: "/ms-teams/student",
      //   student: true,
      //   admin: false,
      //   teacher: false,
      //   umu: false,
      //   sdkSpo: false,
      //   allStatistic: false,
      //   ct: false,
      //   sdk: false,
      //   aspirant: true,
      // },
      // {
      //   icon: "settings",
      //   text: "Очередь",
      //   url: "/queue/operator-queue",
      //   student: false,
      //   admin: false,
      //   teacher: false,
      //   umu: false,
      //   sdkSpo: false,
      //   allStatistic: false,
      //   ct: false,
      //   sdk: true,
      //   aspirant: false,
      // },
      // {
      //   icon: "desktop_windows",
      //   text: "Онлайн монитор",
      //   url: "/statistics/current-lessons",
      //   student: false,
      //   admin: true,
      //   teacher: false,
      //   umu: true,
      //   sdkSpo: true,
      //   allStatistic: true,
      //   ct: false,
      //   sdk: true,
      //   aspirant: false,
      // },
      // {
      //   icon: "qr_code_scanner",
      //   text: "QrCode СПО",
      //   url: "/qrcode/spo-groups",
      //   student: false,
      //   admin: true,
      //   teacher: false,
      //   umu: false,
      //   sdkSpo: true,
      //   allStatistic: false,
      //   ct: false,
      //   sdk: false,
      //   aspirant: false,
      // },
      // {
      //   icon: "account_balance_wallet",
      //   text: "Компенсац. баллы",
      //   url: "/admin/compensation-points",
      //   student: false,
      //   admin: true,
      //   teacher: false,
      //   umu: false,
      //   sdkSpo: false,
      //   allStatistic: false,
      //   ct: false,
      //   sdk: false,
      //   aspirant: false,
      // },
      // {
      //   icon: "badge",
      //   text: "Привязка пользователей",
      //   url: "/admin/user-linking",
      //   student: false,
      //   admin: true,
      //   teacher: false,
      //   umu: false,
      //   sdkSpo: false,
      //   allStatistic: false,
      //   ct: false,
      //   sdk: false,
      //   aspirant: false,
      // },
      // {
      //   icon: "history",
      //   text: "История посещений",
      //   url: "/visit-log",
      //   student: false,
      //   admin: true,
      //   teacher: false,
      //   umu: false,
      //   sdkSpo: false,
      //   allStatistic: false,
      //   ct: false,
      //   sdk: false,
      //   aspirant: false,
      // },
      // {
      //   icon: "settings",
      //   text: "Препод.посещения",
      //   url: "/admin-teacher-visits",
      //   student: false,
      //   admin: true,
      //   teacher: false,
      //   umu: true,
      //   sdkSpo: true,
      //   allStatistic: true,
      //   ct: false,
      //   sdk: true,
      //   aspirant: false,
      // },
      // {
      //   icon: "quiz",
      //   text: "Анкеты",
      //   url: "/admin/testing-form",
      //   student: false,
      //   admin: true,
      //   teacher: false,
      //   umu: false,
      //   sdkSpo: false,
      //   allStatistic: false,
      //   ct: false,
      //   sdk: false
      // },
      // {
      //   icon: "format_list_numbered",
      //   text: "Оценка преподавателей",
      //   url: "/admin/teacher-satisfaction",
      //   student: false,
      //   admin: true,
      //   teacher: false,
      //   umu: false,
      //   sdkSpo: false,
      //   allStatistic: true,
      //   ct: false,
      //   sdk: false,
      //   aspirant: false,
      // },
      // {
      //   icon: "article",
      //   text: "Служебные записки",
      //   url: "/student/visit-ticket",
      //   student: false,
      //   admin: false,
      //   teacher: false,
      //   umu: false,
      //   sdkSpo: false,
      //   allStatistic: false,
      //   ct: false,
      //   sdk: false,
      //   aspirant: false,
      //   groupLeader: true,
      // },
      // {
      //   icon: "article",
      //   text: "Адм. служебные записки",
      //   url: "/admin/visit-ticket",
      //   student: false,
      //   admin: true,
      //   teacher: false,
      //   umu: false,
      //   sdkSpo: false,
      //   allStatistic: false,
      //   ct: false,
      //   sdk: false,
      //   aspirant: false,
      //   groupLeader: false,
      // },
      // {
      //   icon: "article",
      //   text: "Служебные записки",
      //   url: "/teacher/visit-ticket",
      //   student: false,
      //   admin: false,
      //   teacher: true,
      //   umu: false,
      //   sdkSpo: false,
      //   allStatistic: false,
      //   ct: false,
      //   sdk: false,
      //   aspirant: false,
      //   groupLeader: false,
      // },
      // {
      //   icon: "school",
      //   text: "Админ. ведомости",
      //   url: "/admin/sheet",
      //   student: false,
      //   admin: true,
      //   teacher: false,
      //   umu: false,
      //   sdkSpo: false,
      //   allStatistic: false,
      //   ct: false,
      //   sdk: false,
      //   aspirant: false,
      // },
      // {
      //   icon: "school",
      //   text: "Практики",
      //   url: "/practice-sheets",
      //   student: false,
      //   admin: false,
      //   teacher: true,
      //   umu: false,
      //   sdkSpo: false,
      //   allStatistic: false,
      //   ct: false,
      //   sdk: false,
      //   aspirant: false,
      // },
      // {
      //   icon: "library_books",
      //   text: "Курсовые работы",
      //   url: "/course-project-sheets",
      //   student: false,
      //   admin: false,
      //   teacher: true,
      //   umu: false,
      //   sdkSpo: false,
      //   allStatistic: false,
      //   ct: false,
      //   sdk: false,
      //   aspirant: false,
      // },


      //
      // {
      //   icon: "settings",
      //   text: "TakeQueue",
      //   url: "/take-queue",
      //   student: false,
      //   admin: true,
      //   teacher: false,
      //   umu: false,
      //   sdk: false
      // },
      //
      // {
      //   icon: "settings",
      //   text: "SupportPRS",
      //   url: "/support-prs",
      //   student: false,
      //   admin: true,
      //   teacher: false,
      //   umu: false,
      //   sdk: false
      // },
      //
      // {
      //   icon: "settings",
      //   text: "Tablo",
      //   url: "/tablo",
      //   student: false,
      //   admin: true,
      //   teacher: false,
      //   umu: false,
      //   sdk: false
      // },

      // {
      //   icon: "settings",
      //   text: "Контр. точки..",
      //   url: "/admin-control-points",
      //   student: false,
      //   admin: true,
      //   teacher: false,
      //   umu: true
      // },
      // {icon: "settings", text: "Настройки", url: "/setting", student: false, admin: true, teacher: false, umu: false},
      // {icon: "contact_support", text: "О программе", url: "/about"},
      // {icon: "report", text: "Отчёт json", url: "/adminjson"},
      // {icon: "settings", text: "Привязка классов", url: "/classEdit"},

    ],
    nav_items: [],
  }),
  watch: {
    items() {
      this.nav_items = []
      this.getItemMenu()
    }
  },
  computed: {
    settings_color_themes: {
      get() {
        return this.$store.getters.settings_color_themes;
      },
      set(value) {
        this.$store.commit('update_settings_color_themes', value)
        this.$vuetify.theme.dark = this.$store.getters.settings_color_themes;
      }
    },
    settings_primary_drawer_model: {
      get() {
        return this.$store.getters.settings_primary_drawer_model;
      },
      set(value) {
        this.$store.commit('update_settings_primary_drawer_model', value)
      }
    },
    settings_primary_drawer_type: {
      get() {
        return this.$store.getters.settings_primary_drawer_type;
      },
      set(value) {
        this.$store.commit('update_settings_primary_drawer_type', value)
      }
    },
    settings_primary_drawer_clipped: {
      get() {
        return this.$store.getters.settings_primary_drawer_clipped;
      },
      set(value) {
        this.$store.commit('update_settings_primary_drawer_clipped', value)
      }
    },
    settings_primary_drawer_floating: {
      get() {
        return this.$store.getters.settings_primary_drawer_floating;
      },
      set(value) {
        this.$store.commit('update_settings_primary_drawer_floating', value)
      }
    },
    settings_primary_drawer_mini: {
      get() {
        return this.$store.getters.settings_primary_drawer_mini;
      },
      set(value) {
        this.$store.commit('update_settings_primary_drawer_mini', value)
      }
    },
    settings_footer_inset: {
      get() {
        return this.$store.getters.settings_footer_inset;
      },
      set(value) {
        this.$store.commit('update_settings_footer_inset', value)
      }
    },
    getCurrentRoute() {
      return this.$route.name;
    }
  },
  methods: {
    isStudents() {

      return this.$store.getters.students != null && this.$store.getters.students != undefined && this.$store.getters.students.length > 1
    },
    selectStudent(student) {
      this.$store.commit('selectedStudent', student)
    },
    getItemMenu: function () {
      let itemMenuForUser = this.nav_items
      let student = this.student
      let admin = this.admin
      let teacher = this.teacher
      let umu = this.umu
      let guest = this.guest
      let sdk = this.sdk
      let sdkSpo = this.sdkSpo
      let allStatistic = this.allStatistic
      let ct = this.ct
      let groupLeader = this.groupLeader
      let aspirant = this.aspirant

      // if(console.log(this.$store.getters.students))

      this.items.map(function (item) {
        if (item.admin && admin || item.student && student || item.teacher && teacher ||
            item.umu && umu || item.guest && guest || item.sdk && sdk || item.sdkSpo && sdkSpo || item.allStatistic && allStatistic
            || item.ct && ct || item.groupLeader && groupLeader || item.aspirant && aspirant
        ) {
          itemMenuForUser.push(item)
        }
      })
    },
    getUsers() {

      HTTP.get(Settings.API.URL_DEFAULT + Settings.API.URL_USERS + "current-user")
          .then((response) => {
            this.$store.commit('user', response.data)
            this.$store.commit('selectedUser', response.data)
            this.$store.commit('students', response.data.students)
            if (response.data.students) {
              this.$store.commit('selectedStudent', response.data.students[0])
            }
            // if (response.data.students != null && response.data.students.length > 0){
            //   if (response.data.students[0].groups.typesEducation == "ASPIRANT"){
            //     this.aspirant = true;
            //   }
            // }
            console.log(response)
          }).then().finally(() => {
        this.getStudentsByCode()
        // this.isRecordBook(this.$store.getters.selectedStudent)
        // this.isRecordBook()
      })
    },
    // isRecordBook() {
    //   this.items.push({
    //     icon: "settings",
    //     text: "Зачетная книжка",
    //     url: "/recordbook",
    //     student: true,
    //     admin: false,
    //     teacher: false,
    //     umu: false,
    //     sdkSpo: false,
    //     allStatistic: false,
    //     sdk: false,
    //     ct: false,
    //   })
    // },
    getStudentsByCode() {
      if (this.$store.getters.user.students?.length > 0) {
        // console.log(this.$store.getters.user.students[0])
        HTTP.get(Settings.API.URL_DEFAULT + "/students/students-by-code?code=" + this.$store.getters.user.students[0].studentCode)
            .then((response) => {
              this.$store.commit('students', response.data)
            }).then()
      }

    },
    getCurrentUser: async function () {
      return new Promise((resolve, reject) => {
        HTTP.get(Settings.API.URL_DEFAULT + "/users/current-user")
            .then((response) => {
              this.user = response.data
              // console.log(this.user)
              resolve(response.data)
            })
            .catch((err) => {
              reject(err)
            })

      });

    },
    getUserRole: async function () {
      const user = await this.getCurrentUser();
      let student = false
      let admin = false
      let teacher = false
      let umu = false
      let guest = false
      let sdk = false
      let sdkSpo = false
      let allStatistic = false
      let ct = false
      let aspirant = false
      let groupLeader = false

      if (user.students && user.students.length === 1) {
        if (user.students[0].groups.typesEducation === 'ASPIRANT') {
          aspirant = true
        }
      }

      HTTP.get(Settings.API.URL_USER_ROLE())
          .then((response) => {
            response.data.map(function (item) {
              if (item.status === "ACTIVE" && item.name === "ROLE_STUDENT" && !aspirant) {
                student = true
              } else if (item.status === "ACTIVE" && item.name === "ROLE_TEACHER") {
                teacher = true
              } else if (item.status === "ACTIVE" && item.name === "ROLE_ADMIN") {
                admin = true
              } else if (item.status === "ACTIVE" && item.name === "ROLE_UMU") {
                umu = true
              } else if (item.status === "ACTIVE" && item.name === "ROLE_SDK") {
                sdk = true
              } else if (item.status === "ACTIVE" && item.name === "ROLE_SDK_SPO") {
                sdkSpo = true
              } else if (item.status === "ACTIVE" && item.name === "ROLE_ALL_STATISTIC") {
                allStatistic = true
              } else if (item.status === "ACTIVE" && item.name === "ROLE_COMPREHENSIVE_TESTING") {
                ct = true
              } else if (item.status === "ACTIVE" && item.name === "ROLE_GROUP_LEADER") {
                groupLeader = true
              } else {
                guest = true
              }


            })

            this.teacher = teacher
            this.admin = admin
            this.student = student
            this.umu = umu
            this.guest = guest
            this.sdk = sdk
            this.sdkSpo = sdkSpo
            this.allStatistic = allStatistic
            this.ct = ct
            this.aspirant = aspirant
            this.groupLeader = groupLeader
          }).catch(() => {
        this.guest = true
      }).finally(() => (this.getItemMenu()));
    },
    exit: function () {
      localStorage.setItem('AuthorizationToken', "")
      document.location.href = '/'
    },
  },
  created() {
    // console.log(this.getCurrentRoute)
    this.getUserRole()
    this.getUsers()
    if (this.$store.getters.settings_color_themes == null) {
      this.$store.commit('update_settings_color_themes', false)
      this.$vuetify.theme.dark = this.$store.getters.settings_color_themes;
    } else {
      this.$vuetify.theme.dark = this.$store.getters.settings_color_themes;
    }


    // setTimeout(console.log(this.$store.getters.selectedStudent), 5000);


  }

}
</script>


<style scoped>
.time {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  flex-direction: row;
  width: 70%;
}

.timeTitle1 {
  display: flex;
  flex-basis: 30%;
  justify-content: right;
}

.timeTitle2 {
  display: flex;
  flex-basis: 51%;
  justify-content: left;
}

.navigation-drawer-top-list {
  padding: 0;
  /*color: #015188;*/
}

.navigation-drawer-top-item {
  height: 64px !important;
  background: rgba(255, 255, 255, 0.08);
}

.blue {
  background: steelblue;
}

.clr {
  text-decoration: none;
  color: white !important;

}

a {
  text-decoration: none; /* Отменяем подчеркивание у ссылки */

}


</style>
