<template>
  <div>
    <div v-if="loading" class="progress-barr">
      <div class="progress" >
        <v-progress-circular
            :size="100"
            :width="10"
            color="blue"
            indeterminate
        ></v-progress-circular>
      </div>
    </div>
    <v-app id="schedule">
      <v-container justify-center>
        <v-flex xs12>
          <v-card>
            <v-card-text>
              <v-layout row wrap>
                <v-flex xs12 md12>
                  <v-card-actions class="justify-center">
                    <h1>Расписание - {{ title }}</h1>
                  </v-card-actions>
                </v-flex>
                <v-flex xs12 md6>
                </v-flex>
                <v-flex xs12 md6>
                </v-flex>
              </v-layout>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-flex>
      </v-container>

      <v-container justify-center>
        <v-flex xs12>
      <template>
        <v-card>
          <v-tabs
              right
          >
            <v-tab>чет нечет</v-tab>
            <v-tab>по датам</v-tab>


            <v-tab-item>
                  <v-flex xs12>
                    <v-data-table
                        :headers="headers"
                        :items="scheduleDays"
                        :items-per-page="20"
                        :hide-default-footer="true"
                        class="elevation-2"
                    >

                      <template v-slot:item="item">
                        <tr>
                          <td class="td-border-right v-data-table__divider" v-for="header in headers" :key="header.text" >
                            <schedule-item
                                :role = "role"
                                :item = "getItem(item.item, header.value)"
                                :name= "getName(item.item, header.value)"
                            >
                            </schedule-item>
                          </td>
                        </tr>
                      </template>
                    </v-data-table>
                  </v-flex>
            </v-tab-item>

            <v-tab-item>
              <v-flex xs12>
                <v-data-table
                    :headers="headersByDate"
                    :items="scheduleDaysByDate"
                    :items-per-page="20"
                    :hide-default-footer="true"
                    class="elevation-2"
                >
                  <template v-slot:item="item">
                    <tr>
                      <td class="td-border-right v-data-table__divider" v-for="header in headers" :key="header.text" >
                        <schedule-item
                            :role = "roleByDate"
                            :item = "getItem(item.item, header.value)"
                            :name= "getName(item.item, header.value)"
                        >
                        </schedule-item>
                      </td>
                    </tr>
                  </template>
                </v-data-table>
              </v-flex>
            </v-tab-item>


          </v-tabs>
        </v-card>
      </template>
        </v-flex>
      </v-container>


    </v-app>

  </div>
</template>

<script>
import ScheduleItem from '../components/ScheduleItem'
import Settings from "@/settings/settings_url";
import {HTTP} from '@/settings/axios'

export default {
  name: "Schedule",
  data() {
    return {
      title: "",
      loading: true,
      loadingCh: false,
      loadingDate: false,
      role: "",
      headers: [],
      scheduleDays: [],
      roleByDate: "",
      headersByDate: [],
      scheduleDaysByDate: [],
    }
  },
  watch:{
    loadingCh: function (val){
        this.loading = val || this.loadingDate
    },
    loadingDate: function (val){
      this.loading = val || this.loadingCh
    },
  },
  methods: {
    getItem: function (item, val){
      return val !== 'name'? item[val]: null
    },
    getName: function (item, val){
      return val === 'name'? item[val]: null
    },
    getScheduleTable: async function () {
      this.loadingCh = true
      await HTTP.get(Settings.API.URL_SCHEDULE_TABLE())
          .then((response) => {
            this.role = response.data.role
            this.title = response.data.title
            this.headers = response.data.headers
            this.scheduleDays = response.data.scheduleDays
          })
          .catch(() => {
            this.errored = true;
          })
          .finally(() => (this.loadingCh = false));
    },
    getScheduleTableByDate: async function () {
      this.loadingDate = true
      await HTTP.get(Settings.API.URL_SCHEDULE_TABLE_BY_DATE())
          .then((response) => {
            this.roleByDate = response.data.role
            this.title = response.data.title
            this.headersByDate = response.data.headers
            this.scheduleDaysByDate = response.data.scheduleDays
          })
          .catch(() => {
            this.errored = true;
          })
          .finally(() => (this.loadingDate = false));
    },
    getHeaderLesson: function (item) {
      let res
      if (item.lesson) {
        res = item.lesson + " (" + item.type + ")"
      }
      return res
    }
  },
  created() {
    this.getScheduleTable()
    this.getScheduleTableByDate()
    console.log( localStorage.getItem('AuthorizationToken'))
    console.log( "/login?token=" + localStorage.getItem('AuthorizationToken'))
  },
  components: {
    'schedule-item': ScheduleItem,
  }
}
</script>

<style lang="sass">


</style>
