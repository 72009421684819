<template>
  <v-container>
    <v-card-title class="text-center justify-center">
      <h2>
        Посещаемость преподавателя
        <span v-if="filterTeacher != null">{{ filterTeacher.teacher }}<br/></span>
        <span v-if="filterGroup != null"> у группы {{ filterGroup.name }}<br/></span>
        <span v-if="filterDiscipline != null"> на дисциплину {{ filterDiscipline.name }}</span>
      </h2><br/>
      <v-container>
      <v-alert type="error" v-if="filterTeacher != null && filterTeacher.orionId === null">
        Ошибка привязки кампусной карты, посещения могут отображаться неправильно. Пожалуйста, обратитесь в поддержку: brso@sseu.ru
      </v-alert>
      </v-container>
    </v-card-title>
    <v-row>
        <v-col v-if="isAdmin()">
          <v-autocomplete v-model="filterTeacher"
                          :items="teachers"
                          label="Преподаватель"
                          dense
                          item-value="id"
                          item-text="teacher"
                          return-object
                          prepend-icon="person"
                          :loading="teachersLoading"
                          clearable
          />
        </v-col>
        <v-col>
          <v-autocomplete v-model="filterGroup"
                          :items="groups"
                          label="Группа"
                          dense
                          item-value="id"
                          item-text="name"
                          return-object
                          prepend-icon="groups"
                          :loading="groupsLoading"
                          clearable
          />
        </v-col>
        <v-col>
          <v-autocomplete v-model="filterDiscipline"
                          :items="disciplines"
                          label="Дисциплина"
                          dense
                          item-value="id"
                          item-text="name"
                          return-object
                          prepend-icon="school"
                          :loading="disciplinesLoading"
                          :disabled="filterGroup === null"
                          hint="Выберите группу, чтобы выбрать дисциплину"
                          persistent-hint
                          clearable
          />
        </v-col>
        <v-col>
          <v-menu
              ref="dialog_date"
              v-model="modal_date"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                  :value="computedDateFormatted"
                  label="Дата"
                  prepend-icon="event"
                  readonly
                  v-bind="attrs"
                  v-on="on"
              />
            </template>
            <v-date-picker
                v-model="date"
                no-title
                scrollable
                show-adjacent-months
                locale="ru-RU"
                :first-day-of-week="1"
                :range="allowDateRange"
                :selected-items-text="date.length + ' выбрано'"
            >
                <v-checkbox
                    label="Несколько дат"
                    v-model="allowDateRange"
                />
                <v-spacer/>
                <v-btn icon color="secondary" @click="modal_date = false">
                  <v-icon>close</v-icon>
                </v-btn>
                <v-btn icon color="primary" @click="saveDate(date)">
                  <v-icon>done</v-icon>
                </v-btn>
            </v-date-picker>
          </v-menu>
        </v-col>
    </v-row>

    <v-data-table
        :headers="headers"
        :items="lessons"
        :item-class="backgroundColor"
        :items-per-page="10"
        class="elevation-1"
        :loading="loading"
        loading-text="Загрузка..."
        :footer-props="{
              itemsPerPageAllText: 'Все',
              itemsPerPageText: 'Количество строк',
        }"
    >
      <template v-slot:no-data>
        <v-alert type="info" v-if="!loading">
          Нет пар!
        </v-alert>
      </template>
      <template v-slot:item.date="{ item }">
        {{ formatDate(item.date) }}, {{ item.lessonNumber.timeStart }}
      </template>

      <template v-slot:item.presentAudience.name="{ item }">
        <v-container v-if="item.visit === null">
          <span v-for="audience in item.audiences" v-bind:key="audience.id">{{ audience.name }}; </span>
          <span v-if="item.audiences.length == 0">Нет аудитории</span>
        </v-container>
        <span v-if="item.visit && !item.visit.presentAudience">Нет аудитории</span>
        <span v-if="item.visit && item.visit.presentAudience">{{ item.visit.presentAudience.name }}</span>
      </template>

      <template v-slot:item.teacherVisits.visit="{ item }">
        <v-icon color="error" v-if="item.visit === null || item.visit.visit === 0">cancel</v-icon>
        <v-icon color="success" v-if="item.visit !== null && item.visit.visit === 1">check_circle</v-icon><br/>
        <div v-if="item.visit != null">
          Последнее изменение: {{item.visit.journalVisitComments[0].user.username }}<br/>
          Комментарий: {{item.visit.journalVisitComments[0].comment }}<br/>
          Дата: {{ formatDate(item.visit.journalVisitComments[0].date) }}
        </div>
      </template>

      <template v-slot:item.teacherPresent="{ item }">
        <span v-if="item.teacherPresent !== null">{{ item.teacherPresent.teacher }}</span>
        <v-container v-if="item.teacherPresent === null" >
          <span v-for="teacher in item.teachers" v-bind:key="teacher.id">
            {{ teacher.teacher }}<span v-if="item.teachers.length > 1">;</span>
          </span>
        </v-container>
      </template>

      <template v-slot:item.orion="{ item }">
        <v-icon color="error" v-if="item.dateOfEvent === null">cancel</v-icon>
        <div v-if="item.dateOfEvent !== null">
          <v-icon color="success" >check_circle</v-icon><br/>
          Дата посещения: {{ formatDateTime(item.dateOfEvent) }}
        </div>
      </template>

      <template v-slot:item.fullHistory="{ item }">
        <v-btn v-if="item.visit != null" icon @click="showHistory(item.visit)"><v-icon color="primary">visibility</v-icon></v-btn>
        <history
            ref="historyVisit"
            :show-teachers="true"
        />
      </template>

      <template v-slot:item.edit="{ item }">
        <v-btn
            icon color="primary"
            v-if="item.visit == null"
            @click="createVisit(item)"
            :disabled="creatingVisit" :loading="creatingVisit">
          <v-icon>add</v-icon>
        </v-btn>
        <v-btn icon color="primary" v-if="item.visit != null" @click="openEditDialog(item)"><v-icon>edit</v-icon></v-btn>
      </template>
    </v-data-table>

<!--    Обновить посещение-->
    <v-dialog width="500px" v-model="editDialog">
      <v-card>
      <v-toolbar
          dark
          color="primary"
      >
        <v-card-title>Изменить посещение</v-card-title>
        <v-spacer/>
        <v-btn
            icon
            dark
            @click="editDialog = false"
        >
          <v-icon>close</v-icon>
        </v-btn>
      </v-toolbar>

        <v-card-text v-if="editableVisit != null">
          <v-form v-model="valid">
            <v-text-field
                label="Комментарий"
                required
                v-model="comment"
                hint="*Это поле обязательно"
                persistent-hint
                :rules="notNullRules"
            />
          </v-form>
        </v-card-text>

        <v-divider/>
        <v-card-actions>
          <v-spacer/>
          <v-btn color="secondary" text @click="editDialog = false">Отмена</v-btn>
          <v-btn color="error" text @click="updateVisit('0')" :disabled="!valid || editableVisit.visit.visit === 0">Отсутствует</v-btn>
          <v-btn color="success" text @click="updateVisit('1')" :disabled="!valid || editableVisit.visit.visit === 1">Присутствует</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import Settings from "@/settings/settings_url";
import {HTTP} from '@/settings/axios'
import date_utils from "@/settings/date_utils";
import History from "@/components/journal/VisitHistory";

export default {
  name: "JournalTeacherVisits",
  components: {History},
  data() {
    return {
      editDialog: false,
      date: new Date().toISOString().split('T')[0],
      modal_date: false,
      dateSearch: new Date().toISOString().split('T')[0],
      visits: [],
      headers: [
        {text: 'Дата', sortable: false, divider: true, value: 'date', align: 'center'},
        {text: 'Дисциплина', sortable: false, divider: true, value: 'discipline.name', align: 'center'},
        {text: 'Аудитория', sortable: false, divider: true, value: 'presentAudience.name', align: 'center'},
        {text: 'Группа', sortable: false, divider: true, value: 'group.name', align: 'center'},
        {text: 'Преподаватель(-и)', sortable: false, divider: true, value: 'teacherPresent', align: 'center'},
        {text: 'Присутствие в БРСО', sortable: false, divider: true, value: 'teacherVisits.visit', align: 'center'},
        {text: 'Фиксация в Orion', sortable: false, divider: true, value: 'orion', align: 'center'},
        {text: 'История', align: 'center', value: 'fullHistory', divider: true, sortable: true, class: "bolt"},
      ],
      editHeader: {text: 'Редактировать', align: 'center', value: 'edit', divider: true, sortable: true, class: "bolt"},
      loading: false,
      lessonsLoading: false,
      lessons: [],
      editableVisit: null,
      creatingVisit: false,
      comment: "",
      allowDateRange: false,
      notNullRules: [
          v => !!v || '*Это поле обязательно',
          v => v.length <= 255 || 'Комментарий не может быть длиннее 255 символов',
      ],
      valid: false,

      filterTeacher: null,
      teachers: [],
      teachersLoading: false,
      filterGroup: null,
      groups: [],
      groupsLoading: false,
      filterDiscipline: null,
      disciplines: [],
      disciplinesLoading: false,

      page: 1,
      pageSize: 10,
      totalItems: 0,
      pageSizes: [5, 10, 15, 20, 100],
      pagination: {
        page: 1,
      },
      pages: 1
    }
  },
  mounted() {
    if(this.isAdmin())
      this.headers.push(this.editHeader);
  },
  computed: {
    computedDateFormatted () {
      return this.formatFullDate(this.date)
    },
  },
  methods: {
    isAnyFilterApplied() {
      if(this.filterGroup !== null || this.filterTeacher !== null || this.filterDiscipline !== null)
        return true
      return false
    },
    createVisit(item) {
      this.creatingVisit = true
      HTTP.get(Settings.API.URL_DEFAULT + "/registration-of-teacher/create-empty-visit?lessonId=" + item.id
          + "&teacherId=" + this.filterTeacher.id)
          .then((response) => {
            item.visit = response.data

            // console.log(this.visits)
          })
          .catch(() => {
          })
          .finally(() => {
            this.creatingVisit = false
          });
      // create-empty-visit
    },
    openEditDialog(item) {
      this.editableVisit = item
      this.editDialog = true
    },
    backgroundColor: function (item) {
      if((item.dateOfEvent === null && item.visit !== null && item.visit.visit === 1) || (item.dateOfEvent !== null && (item.visit === null || item.visit.visit === 0)))
        return 'pink lighten-5'
      return ''
      // return item.wasFoundInOrion ? 'style-1' : 'style-2'
    },
    showHistory(item){
      this.$refs.historyVisit.commentDialog = true;
      this.$refs.historyVisit.visitItem = item
      // console.log(item)
      // this.$refs.historyVisit.getComments(item);
    },
    formatFullDate(date) {
      return date_utils.formatFullDate(date)
    },
    formatDateTime(date) {
      return date_utils.formatDateTime(date)
    },
    formatDate(date) {
      return date_utils.formatDate(date)
    },
    getVisitsByDate() {
      let urlParams = ''
      if(this.filterTeacher != null)
        urlParams += "&teacherId=" + this.filterTeacher.id
      if(this.filterGroup != null)
        urlParams += "&groupId=" + this.filterGroup.id
      if(this.filterDiscipline != null)
        urlParams += "&disciplineId=" + this.filterDiscipline.id

      if(this.dateSearch instanceof Array && this.dateSearch.length > 0)
        this.getVisitsBetweenDate(urlParams)
      else
        this.getVisitsBySingleDate(urlParams)
    },
    getVisitsBySingleDate(urlParams) {
      this.loading = true

      HTTP.get(Settings.API.URL_DEFAULT + "/registration-of-teacher/history"
          + "?date=" + this.dateSearch
          + urlParams)
          .then((response) => {
            this.lessons = response.data

            // console.log(this.visits)
          })
          .catch(() => {
          })
          .finally(() => {
            this.loading = false
          });
    },
    getVisitsBetweenDate(urlParams) {
      this.loading = true
      HTTP.get(Settings.API.URL_DEFAULT + "/registration-of-teacher/history"
          + "?dateStart=" + this.dateSearch[0] + "&dateEnd=" + this.dateSearch[1]
          + urlParams)
          .then((response) => {
            this.lessons = response.data
          })
          .catch(() => {
          })
          .finally(() => {
            this.loading = false
          });
    },
    updateVisit: function (visit) {
      this.loading = true
      HTTP.put(Settings.API.URL_DEFAULT + "/registration-of-teacher/" + this.editableVisit.visit.id + "/update-visit", {
        id: this.editableVisit.id,
        visit: visit,
        comment: this.comment
      })
          .then((response) => {
        this.editableVisit.visit = response.data
      }).catch(() => {
      }).finally(() => {
        this.loading = false
        this.editDialog = false
      });
    },
    saveDate(date){
      this.$refs.dialog_date.save(date)
      this.dateSearch = date
    },
    isAdmin(){
      if (this.$store.getters.selectedUser?.role != null && this.$store.getters.selectedUser?.role.length > 0){
        for (let i = 0; i < this.$store.getters.selectedUser?.role.length; i++){
          if (
              this.$store.getters.selectedUser?.role[i].name === "ROLE_ADMIN"
              || this.$store.getters.selectedUser?.role[i].name === "ROLE_UMU"
              || this.$store.getters.selectedUser?.role[i].name === "ROLE_SDK"
              || this.$store.getters.selectedUser?.role[i].name === "ROLE_SDK_SPO"
              || this.$store.getters.selectedUser?.role[i].name === "ROLE_ALL_STATISTIC"
          ){
            return true
          }
        }
      }else {
        return  false
      }
    },
    getDisciplines() {
      this.disciplinesLoading = true
      HTTP.get(Settings.API.URL_DEFAULT + "/disciplines/by-groupid?groupid=" + this.filterGroup.id)
          .then((response) => {
            this.disciplines = response.data

            // console.log(this.visits)
          })
          .catch(() => {
          })
          .finally(() => {
            this.disciplinesLoading = false
          });
    },
    getTeachers() {
      this.teachersLoading = true
      HTTP.get(Settings.API.URL_DEFAULT + "/teachers/no-workers")
          .then((response) => {
            this.teachers = response.data

            // console.log(this.visits)
          })
          .catch(() => {
          })
          .finally(() => {
            this.teachersLoading = false
          });
    },
    getGroups() {
      this.groupsLoading = true
      HTTP.get(Settings.API.URL_DEFAULT + "/groups")
          .then((response) => {
            this.groups = response.data

            // console.log(this.visits)
          })
          .catch(() => {
          })
          .finally(() => {
            this.groupsLoading = false
          });
    }
  },
  watch: {
    'editDialog'() {
      this.comment = null
    },
    'pagination.page'() {
      this.getVisitsByDate()
    },
    pageSize() {
      this.getVisitsByDate()
    },
    allowDateRange() {
      if(this.allowDateRange === false && this.date instanceof Array) {
        this.date = this.date[0]
        this.saveDate(this.date)
        this.getVisitsByDate()
      }
    },
    dateSearch() {
      if(this.isAnyFilterApplied())
        this.getVisitsByDate()
      else
        this.lessons = []
    },
    'filterTeacher'() {
      if(this.isAnyFilterApplied())
        this.getVisitsByDate()
      else
        this.lessons = []
    },
    'filterDiscipline'() {
      if(this.isAnyFilterApplied())
        this.getVisitsByDate()
      else
        this.lessons = [];
    },
    '$store.getters.selectedUser'() {
      if(this.isAdmin() && !this.headers.includes(this.editHeader))
        this.headers.push(this.editHeader);
    },
    'filterGroup'() {
      if(this.filterGroup != null)
        this.getDisciplines()
      if(this.filterGroup == null)
        this.filterDiscipline = null

      if(this.isAnyFilterApplied())
        this.getVisitsByDate()
      else
        this.lessons = [];
    }
  },
  created() {
    if(this.filterTeacher != null)
      this.getVisitsByDate()
    this.getTeachers()
    this.getGroups()
  },
}
</script>

<style scoped>
.style-1 {
  background: rgba(218, 166, 166, 0.68) !important;
}
</style>