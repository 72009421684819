<template>
  <v-container>
    <v-card>
      <v-card-title class="justify-center">
        <h3>Фильтры</h3>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col>
            <v-autocomplete v-model="selectedYear"
                            :items="years"
                            item-text="name"
                            return-object
                            label="Год"
                            dense
                            prepend-icon="event"
            />
          </v-col>
          <v-col>
            <v-autocomplete v-model="selectedHalfYear"
                            :items="halfYears"
                            item-text="name"
                            return-object
                            label="Полугодие"
                            dense
                            prepend-icon="event"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-autocomplete
                label="Институт"
                prepend-icon="apartment"
                dense
                no-data-text="Нет институтов!"
                v-model="selectedFaculty"
                :items="faculties"
                :loading="facultyLoading"
            />
          </v-col>
          <v-col>
            <v-autocomplete
                label="Направление"
                prepend-icon="school"
                dense
                no-data-text="Нет направлений!"
                v-model="selectedDirection"
                :items="directions"
                :loading="directionsLoading"
            />
          </v-col>
          <v-col>
            <v-autocomplete
                label="Форма обучения"
                prepend-icon="school"
                dense
                no-data-text="Нет форм!"
                item-text="ru"
                item-value="en"
                v-model="formOfTraining"
                :items="formsOfTraining"
                clearable
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-autocomplete
                label="Тип ведомости"
                prepend-icon="book"
                item-text="ru"
                item-value="en"
                dense
                v-model="selectedType"
                :items="types"
            />
          </v-col>
          <!--      <v-col>-->
          <!--        <v-autocomplete-->
          <!--            label="Статус ведомости"-->
          <!--            prepend-icon="book"-->
          <!--            item-text="ru"-->
          <!--            item-value="en"-->
          <!--            dense-->
          <!--            v-model="selectedStatus"-->
          <!--            :items="statuses"-->
          <!--        />-->
          <!--      </v-col>-->
        </v-row>
        <v-row>
          <v-col/>
          <v-col class="d-flex justify-center">
            <v-btn @click="getSheets" color="primary" :disabled="loading" :loading="loading">
              Получить ведомости
            </v-btn>
          </v-col>
          <v-col/>
        </v-row>
      </v-card-text>
    </v-card>
    <v-container>
      <v-alert :type="alertType"
               v-model="alert"
               dismissible
               transition="fade-transition"
      >
        {{ alertText }}
      </v-alert>
    </v-container>

    <v-data-table
        :headers="headers"
        :items="sheets"
        :items-per-page="-1"
        class="elevation-2"
        no-data-text="Ведомости отсутствуют"
        :loading="loading"
        show-expand
        show-select
        hide-default-footer
        single-expand
        v-model="selectedSheets"
        :search="selectedGroup"
        checkbox-color="primary"
    >
      <template v-slot:top>
        <v-card-title class="justify-center">
          <h3>Ведомости</h3>
        </v-card-title>

        <v-container>
          <v-row>
            <v-col cols="7"/>
            <v-col cols="3">
              <v-text-field
                  label="Поиск по названию группы"
                  append-outer-icon="search"
                  dense
                  v-model="selectedGroup"
                  :disabled="!sheets || sheets.length === 0"
              />
            </v-col>
            <v-col cols="2">
              <v-menu :offset-y="true"
                      :close-on-content-click="false">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                      color="primary"
                      v-bind="attrs"
                      v-on="on"
                      text
                  >
                    <v-icon left>more_horiz</v-icon>
                    Действия
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item>
                    <v-btn text :disabled="updating || !selectedSheets || selectedSheets.length === 0" color="primary" @click="updateStudentList(selectedSheets)" :loading="updating">
                      <v-icon left>refresh</v-icon>
                      Обновить список студентов выделенных ведомостей
                    </v-btn>
                  </v-list-item>
                  <v-list-item>
                    <v-btn text :disabled="zipping || !selectedSheets || selectedSheets.length === 0" :loading="zipping" color="primary" @click="getZipArchive(selectedSheets)" download>
                      <v-icon left>archive</v-icon>
                      Скачать архив выгрузок выделенных ведомостей
                    </v-btn>
                  </v-list-item>
                  <v-list-item>
                    <v-btn text :disabled="downloading || !selectedSheets || selectedSheets.length === 0" color="primary" @click="downloadFilesToServer(selectedSheets)" :loading="downloading">
                      <v-icon left>download</v-icon>
                      Выгрузить файлы выделенных ведомостей на сервер
                    </v-btn>
                  </v-list-item>
                  <v-list-item>
                    <v-btn text :disabled="zipping || !sheets || sheets.length === 0" :loading="zipping" color="primary" @click="getZipArchive(sheets)" download>
                      <v-icon left>archive</v-icon>
                      Скачать архив всех выгрузок
                    </v-btn>
                  </v-list-item>
                  <v-list-item>
                    <v-btn text :disabled="downloading || !sheets || sheets.length === 0" color="primary" @click="downloadFilesToServer(sheets)" :loading="downloading">
                      <v-icon left>download</v-icon>
                      Выгрузить все файлы на сервер
                    </v-btn>
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-col>
          </v-row>
        </v-container>
      </template>

      <template v-slot:footer fixed bottom>
        <v-card-actions absolute>
          <v-divider/>
          <v-menu :offset-y="true"
                  :close-on-content-click="false">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                  color="primary"
                  v-bind="attrs"
                  v-on="on"
                  text
              >
                <v-icon left>more_horiz</v-icon>
                Действия
              </v-btn>
            </template>
            <v-list>
              <v-list-item>
                <v-btn text :disabled="updating || !selectedSheets || selectedSheets.length === 0" color="primary" @click="updateStudentList(selectedSheets)" :loading="updating">
                  <v-icon left>refresh</v-icon>
                  Обновить список студентов выделенных ведомостей
                </v-btn>
              </v-list-item>
                            <v-list-item>
                              <v-btn text :disabled="zipping || !selectedSheets || selectedSheets.length === 0" :loading="zipping" color="primary" @click="getZipArchive(selectedSheets)" download>
                                <v-icon left>archive</v-icon>
                                Скачать архив выгрузок выделенных ведомостей
                              </v-btn>
                            </v-list-item>
              <v-list-item>
                <v-btn text :disabled="downloading || !selectedSheets || selectedSheets.length === 0" color="primary" @click="downloadFilesToServer(selectedSheets)" :loading="downloading">
                  <v-icon left>download</v-icon>
                  Выгрузить файлы выделенных ведомостей на сервер
                </v-btn>
              </v-list-item>
              <v-list-item>
                <v-btn text :disabled="zipping || !sheets || sheets.length === 0" :loading="zipping" color="primary" @click="getZipArchive(sheets)" download>
                  <v-icon left>archive</v-icon>
                  Скачать архив всех выгрузок
                </v-btn>
              </v-list-item>
              <v-list-item>
                <v-btn text :disabled="downloading || !sheets || sheets.length === 0" color="primary" @click="downloadFilesToServer(sheets)" :loading="downloading">
                  <v-icon left>download</v-icon>
                  Выгрузить все файлы на сервер
                </v-btn>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-card-actions>
      </template>

      <template v-slot:item.group.formOfTraining="item">
        {{ item.item.group.formOfTraining }} форма обучения
      </template>

      <template v-slot:item.gradeSheetType="item">
        {{ translateGradeSheetType(item.item.gradeSheetType) }}
      </template>

      <template v-slot:item.group.typesEducation="item">
        <span v-if="item.item && item.item.group">{{ translateTypesEducation(item.item.group.typesEducation) }}</span>
      </template>

      <template v-slot:item.assignId="item">
        <v-icon color="green" v-if="item.item && item.item.assignId">check_circle</v-icon>
        <v-icon color="red" v-if="item.item && !item.item.assignId">cancel</v-icon>
      </template>

      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length">
          <v-container>
            <course-project
                :group-id="item.group.id"
                :half-year-id="item.halfYear.id"
                :year-id="item.year.id"
                :discipline-id="item.discipline.id"
            />
          </v-container>
        </td>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import {HTTP} from "@/settings/axios";
import Settings from "@/settings/settings_url";
import CourseProject from "@/views/CourseProject";

export default {
  name: "CourseProjectAdmin",
  components: {CourseProject},
  data() {
    return {
      selectedFaculty: null,
      faculties: [],
      facultyLoading: false,

      formOfTraining: null,
      formsOfTraining: [
        "Очная", "Очно-заочная", "Заочная"
      ],

      selectedGroup: null,
      groups: [],
      groupLoading: false,

      selectedYear: null,
      years: [],

      selectedHalfYear: null,
      halfYears: [],

      selectedDirection: null,
      directions: [],
      directionsLoading: false,

      selectedType: null,
      types: [
        {ru: "Основная", en: "MAIN"},
        {ru: "Пересдача", en: "RETAKE"},
        {ru: "Пересдача (комиссия)", en: "COMMISSION_RETAKE"},
        {ru: "Индивидуальная пересдача", en: "INDIVIDUAL_RETAKE"},
      ],

      selectedStatus: null,
      statuses: [
        {ru: "Активная", en: "ACTIVE"},
        {ru: "Закрытая", en: "CLOSED"},
        {ru: "Неактивная", en: "NOT_ACTIVE"},
      ],

      loading: false,
      downloading: false,
      updating: false,
      zipping: false,

      sheets: [],
      selectedSheets: [],
      headers: [
        {text: "Тип ведомости", align: 'center', sortable: false, value: 'gradeSheetType', divider: true, class: "bolt", filterable: false,},
        {text: "Группа", align: 'center', sortable: false, value: 'group.name', divider: true, class: "bolt"},
        {text: "Дисциплина", align: 'center', sortable: false, value: 'discipline.name', divider: true, class: "bolt"},
        {text: "Программа", align: 'center', sortable: false, value: 'group.program', divider: true, class: "bolt"},
        {text: "Степень обучения", align: 'center', sortable: false, value: 'group.typesEducation', divider: true, class: "bolt", filterable: false,},
        {text: "Форма обучения", align: 'center', sortable: false, value: 'group.formOfTraining', divider: true, class: "bolt", filterable: false,},
        {text: "Привязка точки", align: 'center', sortable: false, value: 'assignId', divider: true, class: "bolt", filterable: false,},
      ],

      alert: false,
      alertText: null,
      alertType: null,
    }
  },

  methods: {
    translateTypesEducation(item) {
      if(item === 'MAGISTRACY')
        return "Магистратура"
      else if(item === 'BACCALAUREATE')
        return "Бакалавриат"
      else if(item === 'SPO')
        return "СПО"
      else if(item === "ASPIRANT")
        return "Аспирантура"
      else if(item === null)
        return "Не указано"
      else
        return "Неизвестно"
    },
    async getZipArchive(items) {
      this.zipping = true

      let url = "?userId=" + this.$store.getters.selectedUser.id

      items.forEach(s => {
        url += "&sheetId=" + s.id
      })

      await HTTP.get(Settings.API.URL_DEFAULT + "/course-project-sheet/zip-sheet-files-for-user/multiple" + url)
          .then((response) => {
            if (response.status === 200 && response.data !== null) {
              this.alert = true
              this.alertType = "success"
              this.alertText = "Загрузка архива начнётся через несколько секунд.."

              HTTP.get(Settings.API.URL_DEFAULT + "/course-project-sheet/download-zip-for-user" + url, {responseType: "arraybuffer"}).then(response => {
                let blob = new Blob([response.data], { type: 'application/zip' })
                let link = document.createElement('a')
                link.href = window.URL.createObjectURL(blob)
                document.body.appendChild(link);
                link.click()
                document.body.removeChild(link);
              })
              // this.forceFileDownload(response)
            } else if(response.status === 200 && response.data === null) {
              this.alert = true
              this.alertType = "error"
              this.alertText = "Выбраны ведомости, не имеющие привязанных файлов. Архивация отменена"
            } else {
              this.alert = true
              this.alertType = "error"
              this.alertText = "Произошла ошибка при получении ZIP архива"
            }
          }).catch(() => {
          })
          .finally(() => {
            this.loading = false
            this.zipping = false
          })
    },
    downloadFilesToServer(items) {
      this.loading = true
      this.downloading = true

      let url = "?"

      items.forEach(s => {
        url += "sheetId=" + s.id + "&"
      })

      console.log(url)

      url = url.slice(0, -1)

      HTTP.get(Settings.API.URL_DEFAULT + "/course-project-sheet/download-sheet-files-to-server/multiple" + url)
          .then((response) => {
            if(response.status === 200) {
              this.alert = true
              this.alertType = "success"
              this.alertText = "Выгрузка привязанных файлов выполнена успешно"
            }
            else {
              this.alert = true
              this.alertType = "error"
              this.alertText = "Произошла ошибка при выгрузке привязанных файлов"
            }
          }).catch(() => {})
          .finally(() => {
            this.loading = false
            this.downloading = false
          })
    },
    updateStudentList(items) {
      this.loading = true
      this.updating = true

      let url = "?"

      items.forEach(s => {
        url += "sheetId=" + s.id + "&"
      })

      url = url.slice(0, -1)

      HTTP.get(Settings.API.URL_DEFAULT + "/course-project-sheet/update-student-list/multiple" + url)
          .then((response) => {
            console.log(response.data)
            if(!response.data) {
              this.alert = true
              this.alertType = "info"
              this.alertText = "Новых студентов в выделенных группах найдено не было"
            } else {
              this.getSheets()
              this.alert = true
              this.alertType = "success"
              this.alertText = "Список студентов обновлен"
            }
          })
          .catch(() => {
            this.alert = true;
            this.alertType = "error"
            this.alertText = "Не удалось обновить список студентов"
          })
          .finally(() => {
            this.loading = false
            this.updating = false
          });
    },
    getSheets() {
      this.loading = true

      let url = ""
      if(this.selectedDirection)
        url += "&direction=" + this.selectedDirection

      if(this.formOfTraining)
        url += "&formOfTraining=" + this.formOfTraining

      if(this.selectedType)
        url += "&type=" + this.selectedType

      if(this.selectedStatus)
        url += "&status=" + this.selectedStatus

      HTTP.get(Settings.API.URL_DEFAULT + "/course-project-sheet/get-sheets-filtered?halfYearId=" + this.selectedHalfYear.id
          + "&yearId=" + this.selectedYear.id + url)
          .then((response) => {
            this.sheets = response.data
          }).catch(() => {
        this.alert = true
        this.alertType = "error"
        this.alertText = "Произошла ошибка при получении ведомостей"
      })
          .finally(() => {this.loading = false})
      //
    },
    getGroupsByFaculty() {
      let url = ""
      if(this.selectedDirection && !this.formOfTraining)
        url += "?direction=" + this.selectedDirection
      else if(!this.selectedDirection && this.formOfTraining)
        url += "?formOfTraining=" + this.formOfTraining
      else if(this.selectedDirection && this.formOfTraining)
        url += "?formOfTraining=" + this.formOfTraining + "&direction=" + this.selectedDirection
      // else
      //   return

      this.groupLoading = true

      HTTP.get(Settings.API.URL_DEFAULT + "/groups/by-form-of-training-and-faculty" + url)
          .then((response) => {
            this.groups = response.data
          }).catch(() => {
        this.alert = true
        this.alertType = "error"
        this.alertText = "Произошла ошибка при получении групп"
      }).finally(() => {this.groupLoading = false})
    },
    getFaculty() {
      HTTP.get(Settings.API.URL_DEFAULT + "/groups/get-faculties")
          .then((response) => {
            this.faculties = response.data
          })
          .catch(() => {
            this.alert = true
            this.alertType = "error"
            this.alertText = "Произошла ошибка при получении факультетов"
          })
    },
    getDirections() {
      this.directionsLoading = true
      HTTP.get(Settings.API.URL_DEFAULT + "/schedule/all-directions?halfYear=" + this.selectedHalfYear.id + "&yearOfStudy=" + this.selectedYear.id)
          .then((response) => {
            this.directions = response.data
          }).catch(() => {
        this.alert = true
        this.alertType = "error"
        this.alertText = "Произошла ошибка при получении направлений"
      }).finally(() => {this.directionsLoading = false})
    },
    getYear: async function () {
      await HTTP.get(Settings.API.URL_DEFAULT + "/year-of-study")
          .then((response) => {
            this.years = response.data
          })
          .catch(() => {
            this.alert = true
            this.alertType = "error"
            this.alertText = "Произошла ошибка при получении годов обучения"
          })
          .finally(() => {
            for (let i = 0; i < this.years.length; i++) {
              if (this.years[i]?.current == 1) {
                this.selectedYear = this.years[i]
                break
              }
            }
          });
    },
    getHalfYear: async function () {
      await HTTP.get(Settings.API.URL_DEFAULT + "/half-year")
          .then((response) => {
            this.halfYears = response.data
          })
          .catch(() => {
            this.alert = true
            this.alertType = "error"
            this.alertText = "Произошла ошибка при получении полугодий"
          })
          .finally(() => {
            for (let i = 0; i < this.halfYears.length; i++) {
              if (this.halfYears[i].current == 1) {
                this.selectedHalfYear = this.halfYears[i]
                break
              }
            }
          });
    },
    translateGradeSheetType(item) {
      if(item === 'MAIN')
        return "Основная"
      else if(item === 'RETAKE')
        return "Пересдаточная"
      else if(item === 'COMMISSION_RETAKE')
        return "Пересдаточная (комиссия)"
      else if(item === 'INDIVIDUAL_RETAKE')
        return "Индивидуальная пересдача"
    },
  },

  watch: {
    'formOfTraining'() {
      if(this.selectedDirection)
        this.getGroupsByFaculty()
    },
    'selectedFaculty'() {
      this.getGroupsByFaculty()
    },
    'selectedDirection'() {
      this.getGroupsByFaculty()
    },
  },

  async created() {
    await this.getYear()
    await this.getHalfYear()
    // this.getFaculty()
    if(this.selectedHalfYear && this.selectedYear)
      this.getDirections()
    this.getGroupsByFaculty()
  }
}
</script>

<style scoped>

</style>