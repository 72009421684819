<template>
  <div>
    <v-expansion-panels
        multiple
    >
      <v-expansion-panel>
        <v-expansion-panel-header>Параметры сетки расписания</v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-layout justify-center>
            <v-flex xs12>
              <v-card>

                <v-card-text>
                  <v-row>
                    <v-col class="mr-10 ">
                      <v-autocomplete v-model="typesEducation"
                                      :items="typesEducations"
                                      label="Вид образования"
                                      dense
                      >
                      </v-autocomplete>
                    </v-col>
                    <v-col class="mr-10">
                      <v-autocomplete v-model="yearOfStud"
                                      :items="yearOfStuds"
                                      label="Год обучения"
                                      dense
                                      item-value="id"
                                      item-text="name"
                                      return-object
                      >
                      </v-autocomplete>
                    </v-col>
                    <v-col class="mr-10">
                      <v-autocomplete v-model="halfYear"
                                      :items="halfYears"
                                      label="Полугодие"
                                      dense
                                      item-value="id"
                                      item-text="name"
                                      return-object
                      >
                      </v-autocomplete>
                    </v-col>
                    <v-col class="mr-10">
                      <v-autocomplete v-model="course"
                                      :items="courses"
                                      label="Курс"
                                      dense
                      >
                      </v-autocomplete>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col class="mr-10">
                      <v-autocomplete v-model="scheduleType"
                                      :items="scheduleTypes"
                                      label="Тип расписания"
                                      dense
                                      item-value="id"
                                      item-text="name"
                                      return-object
                      >
                      </v-autocomplete>
                    </v-col>
                    <v-col class="mr-10">
                      <v-autocomplete v-model="parity"
                                      :items="parityList"
                                      label="Неделя"
                                      dense
                      >
                      </v-autocomplete>
                    </v-col>
                    <v-col class="mr-10">
                      <v-autocomplete v-model="faculty"
                                      :items="faculties"
                                      label="faculty"
                                      dense
                                      multiple
                      >
                      </v-autocomplete>
                    </v-col>
                    <v-col class="mr-10">
                      <v-autocomplete v-model="direction"
                                      :items="directions"
                                      label="direction"
                                      dense
                      >
                      </v-autocomplete>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col class="mr-10">
                      <v-autocomplete v-if="scheduleType.type === 'BY_DATES'"
                                      v-model="month"
                                      :items="months"
                                      label="Месяц"
                                      dense
                                      clearable
                                      multiple
                      >
                      </v-autocomplete>
                    </v-col>
                    <v-col class="mr-10">
                      <v-autocomplete v-if="scheduleType.type === 'BY_DATES'"
                                      v-model="year"
                                      :items="years"
                                      label="Год"
                                      dense
                      >
                      </v-autocomplete>
                    </v-col>
                    <v-col class="mr-10">
                      <v-autocomplete v-if="scheduleType.type === 'BY_DATES'"
                                      v-model="daysOfWeek"
                                      :items="daysOfWeekSource"
                                      label="День недели"
                                      dense
                                      clearable
                                      multiple
                      >
                      </v-autocomplete>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col class="mr-10 col-2">
                      <v-autocomplete v-model="formOfTraining"
                                      :items="formOfTrainings"
                                      label="Тип"
                                      multiple
                                      dense
                      >
                      </v-autocomplete>
                    </v-col>
                  </v-row>
                  <v-row>
                    <h3>Настройки копирования</h3>
                  </v-row>

                  <v-row>
                    <v-checkbox
                        v-model="isCopyAudience"
                        label="Копировать аудитории"
                    ></v-checkbox>
                  </v-row>
                  <v-row>
                    <v-checkbox
                        v-model="ignoreAllDoubleForCopy"
                        label="Игнорировать при копировании дубль преподавателя и аудитории"
                    ></v-checkbox>
                  </v-row>
                </v-card-text>
                <v-card-actions>
<!--                  @click="downloadJournalGET"-->
                  <v-btn
                      @click="showPrintDialog = true"
                      color="primary"
                  >Печать
                  </v-btn>
                  <v-spacer></v-spacer>
                  <!--                  @click="getJournal"-->
                  <v-btn
                      @click="getJournal"
                      color="primary"
                  >Загрузить
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-flex>
          </v-layout>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>

    <schedule-even-odd v-if="scheduleType.type === 'EVEN_ODD'"
                       scheduleType="EVEN_ODD"
                       :showPrintDialog="showPrintDialog"
                       :selectScheduleType="scheduleType.type"
                       :parity="parity"
                       :course="course"
                       :yearOfStud="yearOfStud"
                       :halfYear="halfYear"
                       :typesEducation="typesEducation"
                       :faculty="faculty"
                       :direction="direction"
                       :month="month"
                       :year="year"
                       :isCopyAudience="isCopyAudience"
                       :ignoreAllDoubleForCopy="ignoreAllDoubleForCopy"
                       :daysOfWeek="daysOfWeek"
                       :formOfTraining="formOfTraining"
                       @closePrintDialog="closePrintDialog"
    ></schedule-even-odd>
    <schedule-even-odd v-if="scheduleType.type === 'BY_DATES'"
                       scheduleType="BY_DATES"
                       :showPrintDialog="showPrintDialog"
                       :selectScheduleType="scheduleType.type"
                       :parity="parity"
                       :course="course"
                       :yearOfStud="yearOfStud"
                       :halfYear="halfYear"
                       :typesEducation="typesEducation"
                       :faculty="faculty"
                       :direction="direction"
                       :month="month"
                       :year="year"
                       :isCopyAudience="isCopyAudience"
                       :ignoreAllDoubleForCopy="ignoreAllDoubleForCopy"
                       :daysOfWeek="daysOfWeek"
                       :formOfTraining="formOfTraining"
                       @closePrintDialog="closePrintDialog"
    ></schedule-even-odd>
  </div>

</template>

<script>
import Settings from "@/settings/settings_url";
import {HTTP} from '@/settings/axios'
import ScheduleEvenOdd from "@/views/ScheduleEvenOdd";
// import ScheduleByDate from "@/views/ScheduleByDate";

export default {
  name: "EditScheduleTable",
  components: {
    'schedule-even-odd': ScheduleEvenOdd,
    // 'schedule-by-date': ScheduleByDate,
  },
  data() {
    return {
      scheduleType: {},
      scheduleTypes: [],
      isCopyAudience: false,
      ignoreAllDoubleForCopy: false,
      showPrintDialog: false,

      formOfTraining: [],
      formOfTrainings:[],

      parity: 1,
      parityList: [
        {
          text: "Нечетная",
          value: 1
        },
        {
          text: "Четная",
          value: 2
        },
      ],
      daysOfWeek:[],
      daysOfWeekSource: [
        {
          text: "Понедельник",
          value: "MONDAY"
        },
        {
          text: "Вторник",
          value: "TUESDAY"
        },
        {
          text: "Среда",
          value: "WEDNESDAY"
        },
        {
          text: "Четверг",
          value: "THURSDAY"
        },
        {
          text: "Пятница",
          value: "FRIDAY"
        },
        {
          text: "Суббота",
          value: "SATURDAY"
        },
      ],
      course: 1,
      courses: [
        {
          text: "1 курс",
          value: 1
        },
        {
          text: "2 курс",
          value: 2
        },
        {
          text: "3 курс",
          value: 3
        },
        {
          text: "4 курс",
          value: 4
        },
        {
          text: "5 курс",
          value: 5
        }, 
        {
          text: "6 курс",
          value: 6
        },
      ],
      yearOfStud: {},
      yearOfStuds: [],
      halfYear: {},
      halfYears: [],
      typesEducation: "BACCALAUREATE",
      typesEducations: [
        {
          text: "СПО",
          value: "SPO"
        },
        {
          text: "Бакалавриат-Специалитет",
          value: "BACCALAUREATE"
        },
        {
          text: "Магистратура",
          value: "MAGISTRACY"
        },
        {
          text: "Аспирантура",
          value: "ASPIRANT"
        },
      ],
      faculty: [],
      faculties: [],
      direction: "",
      directions: [
        "",
      ],
      month: [],
      months: [
        {
          text: "Январь",
          value: "JANUARY"
        },
        {
          text: "Февраль",
          value: "FEBRUARY"
        },
        {
          text: "Март",
          value: "MARCH"
        },
        {
          text: "Апрель",
          value: "APRIL"
        },
        {
          text: "Май",
          value: "MAY"
        },
        {
          text: "Июнь",
          value: "JUNE"
        },
        {
          text: "Июль",
          value: "JULY"
        },
        {
          text: "Август",
          value: "AUGUST"
        },
        {
          text: "Сентябрь",
          value: "SEPTEMBER"
        },
        {
          text: "Октябрь",
          value: "OCTOBER"
        },
        {
          text: "Ноябрь",
          value: "NOVEMBER"
        },
        {
          text: "Декабрь",
          value: "DECEMBER"
        },

      ],
      year: "",
      years: [],
    }
  },
  watch: {
    yearOfStud() {
      this.years = this.yearOfStud.name.split("-")
      this.getDirection()
      this.getFaculty()
    },
    halfYear(){
      this.getDirection()
      this.getFaculty()
    },
    course(){
      this.getDirection()
      this.getFaculty()
    },
    typesEducation(){
      this.getDirection()
      this.getFaculty()
    }
  },
  methods: {
    closePrintDialog(){
      this.showPrintDialog = false
    },
    getJournal() {
      this.$emit("getJournal")
    },
    downloadJournalGET: function () {
      let link = Settings.API.URL_DEFAULT + "/schedule/schedule-file?course=" +
          this.course + "&halfYear=" + this.halfYear.id + "&parity=" +
          this.parity + "&scheduleType=" + this.scheduleType.id + "&yearOfStudy=" +
          this.yearOfStud.id + "&typesEducation=" + this.typesEducation;
      window.open(link);
    },
    onChangeInfoStud: function () {

    },
    getScheduleType: async function () {
      await HTTP.get(Settings.API.URL_DEFAULT + Settings.URL_SCHEDULE_TYPE)
          .then((response) => {

            this.scheduleTypes = response.data
            //     .map(function (item) {
            //   return {
            //     text: item.name,
            //     value: item.id
            //   }
            // })
            // console.log(this.scheduleTypes)
          })
          .catch(() => {
            this.errored = true;
          })
          .finally(() => {
          });
    },
    getDirection: async function () {
      await HTTP.get(Settings.API.URL_DEFAULT + Settings.URL_SCHEDULE + "direction?course=" +
          this.course + "&halfYear=" + this.halfYear.id + "&yearOfStudy=" +
          this.yearOfStud.name + "&typesEducation=" + this.typesEducation)
          .then((response) => {
            this.directions = ["",].concat(response.data)
          })
          .catch(() => {
          })
    },
    getFaculty: async function () {
      await HTTP.get(Settings.API.URL_DEFAULT + Settings.URL_SCHEDULE + "faculty?course=" +
          this.course + "&halfYear=" + this.halfYear.id + "&yearOfStudy=" +
          this.yearOfStud.name + "&typesEducation=" + this.typesEducation)
          .then((response) => {
            this.faculties = response.data  //["",].concat(response.data)
          })
          .catch(() => {
          })
    },
    getFormOfTraining: async function () {
      await HTTP.get(Settings.API.URL_DEFAULT + Settings.URL_SCHEDULE + "form-of-training")
          .then((response) => {
            this.formOfTrainings = response.data // ["",].concat(response.data)
            this.formOfTraining = [...response.data] // ["",].concat(response.data)
          })
          .catch(() => {
          })
    },

    getYear: async function () {
      HTTP.get(Settings.API.URL_DEFAULT + "/year-of-study")
          .then((response) => {
            this.yearOfStuds = response.data
          })
          .catch(() => {

          })
          .finally(() => {
            for (let i = 0; i < this.yearOfStuds.length; i++) {
              if (this.yearOfStuds[i].current == 1) {
                this.yearOfStud = this.yearOfStuds[i]
                break
              }
            }
          });
    },
    getHalfYear: async function () {
      HTTP.get(Settings.API.URL_DEFAULT + "/half-year")
          .then((response) => {
            this.halfYears = response.data
          })
          .catch(() => {

          })
          .finally(() => {
            for (let i = 0; i < this.halfYears.length; i++) {
              if (this.halfYears[i].current == 1) {
                this.halfYear = this.halfYears[i]
                break
              }
            }
          });
    },
  },
  async created() {
    await this.getYear()
    await this.getHalfYear()
    await this.getScheduleType()
    await this.getDirection()
    await this.getFaculty()
    await this.getFormOfTraining()
  },
}
</script>

<style lang="sass">


</style>
