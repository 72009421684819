<template>
  <v-container>
    <v-row>
      <v-col>
        <v-row>
          <v-col cols="4">
            <v-menu
                ref="menu"
                v-model="menu"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                    append-icon="calendar_today"
                    v-model="computedFormattedFromDate"
                    label="Дата от"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                  no-title
                  scrollable
                  v-model="fromDate"
                  locale="ru"
                  :first-day-of-week="1"
                  @input="menu = false"
              />
            </v-menu>
          </v-col>
          <v-col cols="4">
            <v-menu
                ref="menu2"
                v-model="menu2"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                    v-model="computedFormattedToDate"
                    label="Дата по"
                    append-icon="calendar_today"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                  no-title
                  scrollable
                  v-model="toDate"
                  locale="ru"
                  :first-day-of-week="1"
                  @input="menu2 = false"
              />
            </v-menu>
          </v-col>
          <v-col cols="4">
<!--            v-model="group"-->
<!--            :items="groups"-->
<!--            outlined-->
<!--            dense-->
<!--            item-text="name"-->
<!--            item-value="id"-->
<!--            return-object-->
<!--            label="Группа"-->
            <v-autocomplete
                v-model="orderNameFilter"
                :items="orders"
                clearable
                label="Приказ"
                :loading="loading"
                item-text="name"
                item-value="id"
            />
          </v-col>
        </v-row>
        <v-card class="elevation-2">
          <student-to-orion-linking
              ref="students"
              :visible="orderNameFilter != null"
          />
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import moment from "moment";
import StudentToOrionLinking from "@/components/admin/OrionLinking/StudentLinking";
import {HTTP} from "@/settings/axios";
import Settings from "@/settings/settings_url";

export default {
  name: "OrdersWithStudentToOrionLinking",

  components: {
    StudentToOrionLinking,
  },

  data() {
    return {
      fromDate: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      toDate: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      formattedFromDate: this.formatDate(this.fromDate),
      formattedToDate: this.formatDate(this.toDate),
      menu: false,
      menu2: false,
      loading: false,
      pages: 1,
      pageSizes: [5, 10, 15, 20, 30, 40, 50, 60, 70, 80, 90, 100],
      totalItems: 0,
      orders: [],
      pagination: {
        page: 1,
      },
      page: 1,
      pageSize: 10,
      headers: [
        {text: 'Номер', align: 'center', sortable: true, value: 'student', divider: true, class: "bolt"},
        {text: 'Номер', align: 'center', sortable: true, value: 'groups.name', divider: true, class: "bolt"},
        {text: 'Действия', align: 'center', sortable: true, value: 'actions', divider: true, class: "bolt"},
      ],
      isOrderSelected: false,
      currentOrderSelected: null,
      orderNameFilter: null,
      notPairedFilter: true,
    }
  },
  methods: {
    formatDate(value) {
      moment.locale("ru")
      return moment(value).format("DD MMMM YYYY г.")
    },
    getOrders() {
      console.log('getting orders')
      this.loading = true
      console.log(this.fromDate)
      HTTP.get(Settings.API.URL_DEFAULT + "/orders-one-c?startDate=" + this.fromDate + "&endDate=" + this.toDate)
          .then((response) => {
            console.log(response.data)
            this.orders = response.data
            this.orders.forEach(order => {
              order.name = order.id + ", " + order.name
            })
          })
          .catch(() => {

          })
          .finally(() => {
            this.loading = false
          });
    },
    getStudents() {
      this.$refs.students.url = '/students/' + this.orderNameFilter + "/order" // todo update
      this.$refs.students.getStudents()
    },
  },
  watch: {
    'fromDate'() {
      this.getOrders()
    },
    'toDate'() {
      this.getOrders()
    },
    'orderNameFilter'() {
      if(this.orderNameFilter == null)
        this.$refs.students.$refs.cardsList.resetFilters()
      else {
        this.getStudents()
        console.log(this.orderNameFilter)
      }
    },
  },
  computed: {
    computedFormattedFromDate() {
      return this.formatDate(this.fromDate)
    },
    computedFormattedToDate() {
      return this.formatDate(this.toDate)
    },
  },
}
</script>

<style scoped>

</style>