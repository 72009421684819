<template>
  <div>

    <div>
      <v-btn
          color="primary"
          dark
          @click="newItem"
      >
        Добавить
      </v-btn>
    </div>

    <template>
      <v-row justify="center">
        <v-dialog
            v-model="dialog"
            persistent
            max-width="600px"
        >
          <v-card>
            <v-card-title>
              <span class="headline">{{ title_dialog }}</span>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col
                      cols="12"
                      sm="12"
                      md="12"
                  >
                    <v-autocomplete v-model="name"
                                    :items="names"
                                    label="День"
                                    dense
                    >
                    </v-autocomplete>
                    <v-autocomplete v-model="type_day"
                                    :items="type_days"
                                    label="Тип"
                                    dense
                    >
                    </v-autocomplete>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                  color="blue darken-1"
                  text
                  @click="dialog = false"
              >
                Close
              </v-btn>
              <v-btn
                  color="blue darken-1"
                  text
                  @click="save()"
              >
                Save
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
    </template>

    <v-data-table
        :headers="headers"
        :items="desserts"
        :items-per-page="15"
        :hide-default-footer="false"
        class="elevation-2"
        :single-expand="true"
    >
      <template v-slot:item.linked="{ item }">
        <v-btn @click="editItem(item)" icon>
          <v-icon>settings</v-icon>
        </v-btn>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import Settings from "@/settings/settings_url";
import {HTTP} from '@/settings/axios'

export default {
  name: "LessonTypeDays",
  data() {
    return {
      headers: [
        {text: 'id', align: 'center', sortable: true, value: 'id', divider: true, class: "bolt"},
        {text: 'Наименование', align: 'center', value: 'name', divider: true, sortable: true, class: "bolt"},
        {text: 'Номер дня', align: 'center', value: 'numDay', divider: true, sortable: true, class: "bolt"},
        {text: 'Тип', align: 'center', value: 'typeDay', divider: true, sortable: true, class: "bolt"},
        {text: 'Редактировать', value: 'linked', divider: true, align: 'center'}
      ],
      desserts: [],

      dialog: false,
      title_dialog: "",


      id: null,
      name: "",
      names: [
        {"text": "Понедельник", "value": 1},
        {"text": "Вторник", "value": 2},
        {"text": "Среда", "value": 3},
        {"text": "Четверг", "value": 4},
        {"text": "Пятница", "value": 5},
        {"text": "Суббота", "value": 6},
        {"text": "Воскресенье", "value": 7}
      ],
      type_day: null,
      type_days: [
        {"text": "Default", "value": 0},
        {"text": "Чет", "value": 2},
        {"text": "Нечет", "value": 1}
      ]
    };
  },
  methods: {
    clearItem: function () {
      this.name = ""
      this.type_day = null
      this.id = null
    },
    editItem: function (item) {
      this.clearItem()
      this.name = item.numDay
      this.type_day = item.typeDay
      this.id = item.id
      this.dialog = true
      this.title_dialog = "Редактировать тип"
    },
    newItem: function () {
      this.clearItem()
      this.dialog = true
      this.title_dialog = "Добавить тип"
    },
    save: function () {
      this.dialog = false
      if (this.id == null) {
        this.create()
      } else {
        this.update()
      }
    },
    update: async function () {
      await HTTP.put(Settings.API.URL_DEFAULT + Settings.URL_LESSON_TYPE_DAYS, {
        "id": this.id,
        "name": this.getNameDay(),
        "numDay": this.name,
        "typeDay": this.type_day
      })
          .then(() => {
          })

      await this.getType()
    },
    getNameDay: function (){
      let findName = this.name
      return  this.names.find(function (item){
        return item.value === findName
      }).text
    },
    create: async function () {
      await HTTP.post(Settings.API.URL_DEFAULT + Settings.URL_LESSON_TYPE_DAYS, {
        "id": this.id,
        "name": this.getNameDay(),
        "numDay": this.name,
        "typeDay": this.type_day
      })
          .then(() => {
          })

      await this.getType()
    },
    getType: async function () {
      await HTTP.get(Settings.API.URL_DEFAULT + Settings.URL_LESSON_TYPE_DAYS)
          .then((response) => {
            this.desserts = response.data
          })
    }
  },
  created() {
    this.getType()
  }
}
</script>

<style scoped>

</style>