<template>
  <div>
<v-container fluid>
  <v-card>
    <v-spacer><p></p></v-spacer>
    <v-card-title class="justify-center">

      <span class = 'ltr'>Частые Вопросы (ЧаВо) / Frequently Asked Questions (FAQ) </span>

    </v-card-title>
    <v-spacer><p></p></v-spacer>
  </v-card>
  </v-container>

  <v-container fluid>
      <v-card>
    <v-card-text>
      <v-row justify="center">

          <v-expansion-panels
              v-model="panel"
              inset
              multiple
          >
            <v-expansion-panel>
              <v-expansion-panel-header>Panel 1</v-expansion-panel-header>
              <v-expansion-panel-content>
                Some content
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel>
              <v-expansion-panel-header>Panel 2</v-expansion-panel-header>
              <v-expansion-panel-content>
                Some content
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel>
              <v-expansion-panel-header>Panel 3</v-expansion-panel-header>
              <v-expansion-panel-content>
                Some content
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
         </v-row>
    </v-card-text>
  </v-card>
</v-container>
  </div>
</template>

<script>
export default {
name: "FAQ"
}
</script>

<style scoped>
.ltr:hover{
  color: royalblue;
  font-size: 175%;
}
</style>