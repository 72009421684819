<template>
  <div>
      <v-data-table v-if="typeCertificates.length > 0"
          :headers="headers"
          :hide-default-footer="true"
          :items="typeCertificates"
          :items-per-page="250"
          class="elevation-4"
          no-data-text="Нет справок"
          loading-text="Загрузка..."
          :loading="loading"
      >
        <template v-slot:item.edit="{ item }">
          <v-btn icon color="primary"
            @click="requestTypeCertificate(item)"
          >
            <v-icon>add</v-icon>
          </v-btn>
        </template>

      </v-data-table>
  </div>
</template>

<script>
import Settings from "@/settings/settings_url";
import {HTTP} from '@/settings/axios'

export default {
  name: "TypeCertificateList",
  props: {
    groupCertificate: {
      type: Object,
      required: true,
    }
  },
  data() {
    return {
      headers: [
        {text: 'N', align: 'center', sortable: false, value: 'id',},
        {text: 'Название', value: 'title'},
        {text: 'Заказать', value: 'edit', sortable: false},
      ],
      typeCertificates: [],
      loading: false,
    }
  },
  methods:{
    requestTypeCertificate(item){
      this.$emit("requestTypeCertificate", item)
    },
    getTypeCertificate: async function () {
      this.loading = true
      HTTP.get(Settings.API.URL_DEFAULT + "/group-certificate/" + this.groupCertificate.id + "/type-certificate")
          .then((response) => {
            this.typeCertificates = response.data
          })
          .catch(() => {
          })
          .finally(() => {
            this.loading = false
          });
    },
  },
  created() {
    this.getTypeCertificate()
  }
}
</script>

<style scoped>

</style>