<template>

  <v-layout align-start justify-start wrap>


<!--    v-for="(item, i) in item_card" :key="i"-->
    <v-flex v-for="(item, i) in item_card" :key="i">
      <v-hover>
        <template v-slot="{ hover }">

          <div>
            <v-card
                :loading="loading"
                :elevation="hover ? 24 : 6"

                class="my-3 mx-3 card-main-schedule"
            >
              <template slot="progress">
                <v-progress-linear
                    color="deep-purple"
                    height="10"
                    indeterminate
                ></v-progress-linear>
              </template>
              <div>
                <!--   :height="heightTable()"-->
                <v-data-table
                    :headers="scheduleGrid.headers"
                    :items="scheduleGrid.items"
                    :items-per-page="80"
                    :hide-default-footer="true"
                    class="elevation-2"

                >

                  <template v-slot:item="{ item  }">
                    <tr v-for="(subitem, iSub) in item.subs" :key="iSub">
                      <td v-if="iSub === 0" :rowspan="item.subs.length" class="td-date-main-schedule">
                        <div class="content-date-main-schedule">
                          {{ item.date }}
                        </div>
                      </td>
                      <td class="td-sub-main-schedule time">
                        <div class="content-sub-main-schedule">
                          {{ subitem.time }}
                        </div>
                      </td>
                      <td class="td-sub-main-schedule pair">
                        <div class="content-sub-main-schedule discipline">
                          {{ subitem.discipline }}
                        </div>
                        <div class="content-sub-main-schedule type">
                          ({{ subitem.type }})
                        </div>
                        <div class="content-sub-main-schedule">
                          <span v-for="teacher in subitem.teachers" :key="teacher">{{teacher}}, </span>
                        </div>
                        <div class="content-sub-main-schedule">
                          Аудитория: <span v-for="audience in subitem.audiences" :key="audience">{{audience}}, </span>
                        </div>
                      </td>
                    </tr>
                  </template>

                  <!--      <template v-slot:item="{ item  }">-->

                  <!--        <tr>-->
                  <!--          <td-->
                  <!--              v-for="header in scheduleGrid.headers"-->
                  <!--              :key="header.text"-->
                  <!--          >-->
                  <!--            {{ item[header.value] }}-->
                  <!--          </td>-->

                  <!--        </tr>-->

                  <!--      </template>-->

                </v-data-table>
              </div>


            </v-card>

          </div>

        </template>
      </v-hover>
    </v-flex>

  </v-layout>

</template>

<script>
export default {
  name: "ScheduleMain",
  data() {
    return {
      scheduleGrid: {
        headers: [
          {text: 'Дата', align: 'center', sortable: true, value: 'date', divider: true, class: "bolt"},
          {text: 'Время', align: 'center', value: 'subs', divider: true, sortable: true, class: "bolt"},
          {text: 'ПОНБуг11', value: 'subs', divider: true, align: 'center', class: "bolt"}
        ],
        items: [
          {
            date: '12',
            subs: [
              {
                time: '08:30',
                discipline: 'Административно-правовое регулирование миграционных процессов в зарубежных странах',
                type: 'Лекция',
                teachers: ['Петров П.П', 'Иванов И.И', 'Васильев В.В',],
                audiences: ['101E', '125Д', '425E'],
              },
              {
                time: '10:15',
                discipline: 'Административно-правовое регулирование миграционных процессов в зарубежных странах',
                type: 'Лекция',
                teachers: ['Петров П.П', 'Иванов И.И', 'Васильев В.В',],
                audiences: ['101E', '125Д', '425E'],
              },
              {
                time: '12:20',
                discipline: 'Административно-правовое регулирование миграционных процессов в зарубежных странах',
                type: 'Лекция',
                teachers: ['Петров П.П', 'Иванов И.И', 'Васильев В.В',],
                audiences: ['101E', '125Д', '425E'],
              },
              {
                time: '14:05',
                discipline: 'Административно-правовое регулирование миграционных процессов в зарубежных странах',
                type: 'Лекция',
                teachers: ['Петров П.П', 'Иванов И.И', 'Васильев В.В',],
                audiences: ['101E', '125Д', '425E'],
              }
            ]
          },
          {
            date: '13',
            subs: [
              {
                time: '08:30',
                discipline: 'Административно-правовое регулирование миграционных процессов в зарубежных странах',
                type: 'Лекция',
                teachers: ['Петров П.П', 'Иванов И.И', 'Васильев В.В',],
                audiences: ['101E', '125Д', '425E'],
              },
              {
                time: '10:15',
                discipline: 'Административно-правовое регулирование миграционных процессов в зарубежных странах',
                type: 'Лекция',
                teachers: ['Петров П.П', 'Иванов И.И', 'Васильев В.В',],
                audiences: ['101E', '125Д', '425E'],
              },
              {
                time: '12:20',
                discipline: 'Административно-правовое регулирование миграционных процессов в зарубежных странах',
                type: 'Лекция',
                teachers: ['Петров П.П', 'Иванов И.И', 'Васильев В.В',],
                audiences: ['101E', '125Д', '425E'],
              },
              {
                time: '14:05',
                discipline: 'Административно-правовое регулирование миграционных процессов в зарубежных странах',
                type: 'Лекция',
                teachers: ['Петров П.П', 'Иванов И.И', 'Васильев В.В',],
                audiences: ['101E', '125Д', '425E'],
              }

            ]
          },{
            date: '14',
            subs: [
              {
                time: '08:30',
                discipline: 'Административно-правовое регулирование миграционных процессов в зарубежных странах',
                type: 'Лекция',
                teachers: ['Петров П.П', 'Иванов И.И', 'Васильев В.В',],
                audiences: ['101E', '125Д', '425E'],
              },
              {
                time: '10:15',
                discipline: 'Административно-правовое регулирование миграционных процессов в зарубежных странах',
                type: 'Лекция',
                teachers: ['Петров П.П', 'Иванов И.И', 'Васильев В.В',],
                audiences: ['101E', '125Д', '425E'],
              },
              {
                time: '12:20',
                discipline: 'Административно-правовое регулирование миграционных процессов в зарубежных странах',
                type: 'Лекция',
                teachers: ['Петров П.П', 'Иванов И.И', 'Васильев В.В',],
                audiences: ['101E', '125Д', '425E'],
              },
              {
                time: '14:05',
                discipline: 'Административно-правовое регулирование миграционных процессов в зарубежных странах',
                type: 'Лекция',
                teachers: ['Петров П.П', 'Иванов И.И', 'Васильев В.В',],
                audiences: ['101E', '125Д', '425E'],
              }

            ]
          },{
            date: '15',
            subs: [
              {
                time: '08:30',
                discipline: 'Административно-правовое регулирование миграционных процессов в зарубежных странах',
                type: 'Лекция',
                teachers: ['Петров П.П', 'Иванов И.И', 'Васильев В.В',],
                audiences: ['101E', '125Д', '425E'],
              },
              {
                time: '10:15',
                discipline: 'Административно-правовое регулирование миграционных процессов в зарубежных странах',
                type: 'Лекция',
                teachers: ['Петров П.П', 'Иванов И.И', 'Васильев В.В',],
                audiences: ['101E', '125Д', '425E'],
              },
              {
                time: '12:20',
                discipline: 'Административно-правовое регулирование миграционных процессов в зарубежных странах',
                type: 'Лекция',
                teachers: ['Петров П.П', 'Иванов И.И', 'Васильев В.В',],
                audiences: ['101E', '125Д', '425E'],
              },
              {
                time: '14:05',
                discipline: 'Административно-правовое регулирование миграционных процессов в зарубежных странах',
                type: 'Лекция',
                teachers: ['Петров П.П', 'Иванов И.И', 'Васильев В.В',],
                audiences: ['101E', '125Д', '425E'],
              }

            ]
          },{
            date: '16',
            subs: [
              {
                time: '08:30',
                discipline: 'Административно-правовое регулирование миграционных процессов в зарубежных странах',
                type: 'Лекция',
                teachers: ['Петров П.П', 'Иванов И.И', 'Васильев В.В',],
                audiences: ['101E', '125Д', '425E'],
              },
              {
                time: '10:15',
                discipline: 'Административно-правовое регулирование миграционных процессов в зарубежных странах',
                type: 'Лекция',
                teachers: ['Петров П.П', 'Иванов И.И', 'Васильев В.В',],
                audiences: ['101E', '125Д', '425E'],
              },
              {
                time: '12:20',
                discipline: 'Административно-правовое регулирование миграционных процессов в зарубежных странах',
                type: 'Лекция',
                teachers: ['Петров П.П', 'Иванов И.И', 'Васильев В.В',],
                audiences: ['101E', '125Д', '425E'],
              },
              {
                time: '14:05',
                discipline: 'Административно-правовое регулирование миграционных процессов в зарубежных странах',
                type: 'Лекция',
                teachers: ['Петров П.П', 'Иванов И.И', 'Васильев В.В',],
                audiences: ['101E', '125Д', '425E'],
              }

            ]
          },{
            date: '17',
            subs: [
              {
                time: '08:30',
                discipline: 'Административно-правовое регулирование миграционных процессов в зарубежных странах',
                type: 'Лекция',
                teachers: ['Петров П.П', 'Иванов И.И', 'Васильев В.В',],
                audiences: ['101E', '125Д', '425E'],
              },
              {
                time: '10:15',
                discipline: 'Административно-правовое регулирование миграционных процессов в зарубежных странах',
                type: 'Лекция',
                teachers: ['Петров П.П', 'Иванов И.И', 'Васильев В.В',],
                audiences: ['101E', '125Д', '425E'],
              },
              {
                time: '12:20',
                discipline: 'Административно-правовое регулирование миграционных процессов в зарубежных странах',
                type: 'Лекция',
                teachers: ['Петров П.П', 'Иванов И.И', 'Васильев В.В',],
                audiences: ['101E', '125Д', '425E'],
              },
              {
                time: '14:05',
                discipline: 'Административно-правовое регулирование миграционных процессов в зарубежных странах',
                type: 'Лекция',
                teachers: ['Петров П.П', 'Иванов И.И', 'Васильев В.В',],
                audiences: ['101E', '125Д', '425E'],
              }
              ,

            ]
          }
        ],
      },
      item_card: [
          1,2,3,4,5,6,7,8,9,10,12,1,2,3,4,5,6,7,8,9,10,12,1,2,3,4,5,6,7,8,9,10,12,1,2,3,4,5,6,7,8,9,10,12,
      ],
    }
  },
  methods: {
    getRowspan(item) {
      return item.sub.length
    }
  },
}
</script>

<style scoped>
.td-date-main-schedule {
  padding: 0px !important;
  width: 40px !important;
}

.content-date-main-schedule {
  padding: 10px;
  font-weight: bold;
}

.td-sub-main-schedule {
  padding: 0px !important;

}

.td-sub-main-schedule.time {
  width: 40px !important;
  border: thin solid rgba(0,0,0,.12);
  font-weight: bold;
}


.content-sub-main-schedule {
  padding-left: 10px;
  margin: 0px;
}

.content-sub-main-schedule.discipline {
text-align: center;
}

.content-sub-main-schedule.type {
  text-align: center;
}

.card-main-schedule {
  flex-basis: 200px;
  width: 400px;
}



</style>

