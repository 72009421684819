<template>
  <v-row justify="center">
    <v-dialog
        v-model="dialog"
        fullscreen
        hide-overlay
        transition="dialog-bottom-transition"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
            color="primary"
            dark
            v-bind="attrs"
            v-on="on"
        >
          Подробнее
        </v-btn>
      </template>
      <v-card>
        <v-toolbar
            dark
            color="primary"
        >

          <v-spacer></v-spacer>
          <v-toolbar-title>Подробная статистика  за {{date}} группы {{grName}} по дисциплине {{disc}} </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn
              icon
              dark
              @click="dialog = false"
          >
            <v-icon>close</v-icon>
          </v-btn>
<!--          <v-toolbar-items>-->
<!--            <v-btn-->
<!--                dark-->
<!--                text-->
<!--                @click="dialog = false"-->
<!--            >-->
<!--              Сохранить-->
<!--            </v-btn>-->
<!--          </v-toolbar-items>-->
        </v-toolbar>
        <v-list
            three-line
            subheader
        >
          <v-list-item>
            <v-list-item-content>
<!--              <v-list-item class="title font-weight-light">Группа: {{grName}}</v-list-item>-->

<!--              <v-list-item class="title font-weight-light">Дисциплина: {{disc}} Финансовое право</v-list-item>-->

              <v-data-table
                  :headers="headers_tchr"
                  :items="info_tchrs"
                  class="elevation-1"
                  :hide-default-footer="true">
              </v-data-table>
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <v-divider></v-divider>
        <v-list-item>
          <v-list-item-content>

                <v-data-table
                    :headers="headers_stud"
                    :items="info_stud"
                    class="elevation-1"
                    :hide-default-footer="true">
                </v-data-table>

          </v-list-item-content>
        </v-list-item>

      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
name: "StatisticsDetails",
  data () {
    return {
      dialog: false,
      notifications: false,
      sound: true,
      widgets: false,

      grName:'БПСЭ11',
      disc:'Теория государства и права зарубежных стран',
      date: '25-05-2021',
      headers_tchr:[

        { text: 'Преподаватель', align: 'center',  value: 'teacher', divider: true,sortable: false  },
        { text: 'Аудитория', align: 'center', value: 'room' , divider: true,sortable: false },
        { text: 'Присутствие', align: 'center',  value: 'teacher_is', divider: true,sortable: false  },
        { text: 'Замена', align: 'center', value: 'altteacher', divider: true,sortable: false  },
        { text: 'Присутствие',  align: 'center', value: 'altteacher_is', divider: true,sortable: false  },
        { text: 'Аудитория', align: 'center', value: 'altroom' , divider: true,sortable: false },

      ],
      info_tchrs:[
        {

          teacher: 'Иванов Иван Иванович',
          room: '101е',
          teacher_is:'не был',
          altteacher: 'Сидоров Иван Иванович',
          altroom: '103е',
          altteacher_is: 'был',



        },

      ],

      headers_stud: [
        { text: 'Студент', align: 'left',  value: 'stud', divider: true,sortable: false  },
        { text: 'Присутствие', align: 'center',  value: 'stud_is', divider: true,sortable: false  },
        { text: 'Аудитория', value: 'room', divider: true,sortable: false  },



      ],
      info_stud: [
        {
          stud: 'Сергеев Сергей Сергеевич',
          stud_is:'был',
          room: '105e',
        },  {
          stud: 'Петров Сергей Сергеевич',
          stud_is:'не был',
          room: '105e',
        },
      ]
    }
  },
  methods: {
    skipped(ctx, value) {
      ctx.p0.skip || ctx.p1.skip ? value : undefined
    },
  },
}
</script>

<style scoped>

</style>