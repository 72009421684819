<template>
  <v-container>
    <v-row>
      <v-col class="mr-10">
        <v-autocomplete v-model="group"
                        :items="groups"
                        label="Группа"
                        dense
                        item-value="id"
                        item-text="name"
                        return-object
        >
        </v-autocomplete>
      </v-col>
    </v-row>

      <v-data-table
          :headers="headers"
          :items="items"
          :items-per-page="15"
          class="elevation-2"
          :single-expand="true"
      >
        <template v-slot:item.linked="{ item }">
          <v-btn
              target="_blank"
              icon
              @click="editInviteCode(item)"
          >
            <v-icon>settings </v-icon>
          </v-btn>
        </template>
      </v-data-table>


    <template>
      <v-row justify="center">
        <v-dialog
            v-model="show"
            persistent
            max-width="600px"
        >
          <v-card>
            <v-card-title>
              <span class="headline">Код приглашения</span>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col
                      cols="12"
                      sm="12"
                      md="12"
                  >
                    <div>
                      <v-text-field
                          v-model="editItem.invitationCode"
                          label="Код"
                          hide-details="auto"
                      ></v-text-field>
                    </div>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                  color="blue darken-1"
                  text
                  @click="show = false"
              >
                Close
              </v-btn>
              <v-btn
                  color="blue darken-1"
                  text
                  @click="saveWorkPlan"
              >
                Save
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
    </template>

  </v-container>
</template>

<script>
import Settings from "@/settings/settings_url";
import {HTTP} from '@/settings/axios'

export default {
  name: "InviteCodeForGroup",
  data(){
    return{
      show: false,
      headers: [
        {
          text: "Группа",
          value: "group.name"
        },
        {
          text: "Дисциплина",
          value: "discipline.name"
        },
        {
          text: "Тип",
          value: "lessonTypes.name"
        },
        {
          text: "Код приглашения",
          value: "invitationCode"
        },
        {
          text: "",
          value: "linked"
        },
      ],
      items: [],
      editItem:{
        invitationCode: "",
      },

      groups: [],
      group: {},
    }
  },
  watch: {
    group() {
      this.getWorkPlan()
    },
  },
  methods:{
    editInviteCode(item){
      console.log(item)
      this.editItem = {...item}
      this.show = true
    },
    saveWorkPlan(){
      HTTP.post(Settings.API.URL_DEFAULT + "/work-plan", this.editItem)
          .then((response) => {
            console.log(response.data)
            this.getWorkPlan()
            this.show = false
          })
          .catch(() => {

          })
          .finally(() => {
            this.editItem.invitationCode = ""
          });
    },
    getGroups: async function () {
      HTTP.get(Settings.API.URL_DEFAULT + "/groups")
          .then((response) => {
            this.groups = response.data
          })
          .catch(() => {

          })
          .finally(() => {

          });
    },
    getWorkPlan() {
      HTTP.get(Settings.API.URL_DEFAULT + "/groups/"+ this.group?.id + "/current-work-plan")
          .then((response) => {
            this.items = response.data
            //console.log(this.items)

          })
          .catch(() => {

          })
          .finally(() => {
          });
    },
  },
  created() {
    this.getGroups()
  }

}
</script>

<style scoped>

</style>