<template>
  <v-container>
    <v-alert
        v-model="alert"
        dismissible
        :type="alertType"
        transition="fade-transition"
    >
      {{ alertText }}
    </v-alert>
    <v-row>
      <v-col></v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-select
            v-model="yearOfStudy"
            :items="yearsOfStudy"
            dense
            item-text="name"
            item-value="id"
            return-object
            label="Год"
            prepend-icon="event"
            :loading="yearLoading"
        />
      </v-col>
      <v-col>
        <v-select
            v-model="halfYear"
            :items="halfYears"
            dense
            item-text="name"
            item-value="id"
            return-object
            label="Полугодие"
            prepend-icon="event"
            :loading="halfYearLoading"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-autocomplete
            v-model="group"
            :items="groups"
            dense
            item-text="name"
            item-value="id"
            return-object
            label="Группа"
            prepend-icon="groups"
            clearable
            :loading="groupsLoading"
            no-data-text="Нет групп"
            loading-text="Загрузка..."
        />
      </v-col>
      <v-col>
        <v-autocomplete
            :disabled="!group"
            v-model="stud"
            :items="studs"
            dense
            label="Cтудент"
            item-text="student"
            item-value="id"
            return-object
            prepend-icon="person"
            clearable
            :loading="studentLoading"
            no-data-text="Нет студентов"
            :hint="!group ? 'Выберите группу, чтобы выбрать студента' : ''"
            :persistent-hint="!group"
        />
      </v-col>
      <v-col>
        <v-autocomplete
            :disabled="!group"
            v-model="discipline"
            :items="disciplines"
            dense
            label="Дисциплина"
            item-text="name"
            item-value="id"
            return-object
            prepend-icon="school"
            no-data-text="Нет дисциплин"
            :loading="disciplineLoading"
            clearable
            :hint="!group? 'Выберите группу, чтобы выбрать дисциплину' : ''"
            :persistent-hint="!group"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-select
            v-model="comment"
            :items="comments"
            dense
            label="Тип изменения"
            item-text="username"
            return-object
            prepend-icon="chat"
            no-data-text="Нет типов"
            :loading="userLoading"
        />
      </v-col>
      <v-col>
        <v-autocomplete
            v-model="user"
            :items="users"
            dense
            label="Пользователем"
            item-text="username"
            item-value="id"
            return-object
            prepend-icon="admin_panel_settings"
            no-data-text="Нет пользователей"
            :loading="userLoading"
            clearable
        />
      </v-col>
      <v-col>
        <v-autocomplete
            v-model="type"
            :items="types"
            dense
            label="Вид компенсации"
            item-text="name"
            item-value="id"
            return-object
            prepend-icon="list"
            no-data-text="Нет видов компенсации"
            :loading="typeLoading"
            clearable
        />
      </v-col>
    </v-row>
    <v-container v-show="stud !== null">
      <v-row>
        <v-spacer/>
        <v-col>
          <v-btn
              color="primary"
              @click="openAddDialog()"
          >
            <v-icon left>add</v-icon>
            Начислить баллы
          </v-btn>
        </v-col>
        <v-col>
          <v-btn
              color="primary"
              :disabled="!pointsBalance || pointsBalance.points <= 0"
              @click="openTransferDialog()"
          >
            <v-icon left>update</v-icon>
            Распределить баллы
          </v-btn>
        </v-col>
        <v-col>
          <v-btn
              color="primary"
              :disabled="pointsSpent === 0"
              @click="openRestoreDialog()"
          >
            <v-icon left>restore</v-icon>
            Возвратить баллы
          </v-btn>
        </v-col>
        <v-col>
          <v-btn
              color="primary"
              :disabled="!pointsBalance || pointsBalance.points <= 0"
              @click="openRemoveDialog()"
          >
            <v-icon left>remove</v-icon>
            Убрать баллы
          </v-btn>
        </v-col>
        <v-col class="d-flex justify-end align-end">
          <v-alert dense color="white" width="300px"
                   v-if="!loading && pointsBalance !== null && stud !== null">
            <p class="text-right">Нераспределенных баллов {{ pointsBalance.points }} шт.<br/>
              Распределено баллов {{pointsSpent}} шт.<br/>
              <v-divider class="black"/>
              Всего баллов {{ pointsAdded }} шт.</p>
          </v-alert>
        </v-col>
      </v-row>
    </v-container>

    <v-container v-if="stud !== null" v-show="!loading && stud !== null">
    <v-alert type="warning" color="primary"
             v-if="!loading && pointsBalance === null && stud !== null">
      <v-row align="center">
        <v-col class="grow">
          У студента в этом семестре не было начислено баллов!
        </v-col>
        <v-col class="shrink">
          <v-btn
              outlined
              @click="openAddDialog()"
              :disabled="creatingBalance"
              :loading="creatingBalance"
          >
            Начислить баллы
          </v-btn>
        </v-col>
      </v-row>
    </v-alert>
    </v-container>

    <v-container>
      <v-card v-show="halfYear !== null && yearOfStudy !== null">
      <h2 class="text-center">История изменений</h2>
        <v-data-table
          :loading="loading"
          :headers="headers"
          :items="journal"
          class="elevation-1"
          no-data-text="Нет изменений"
          loading-text="Загрузка..."
          hide-default-footer
          :items-per-page="pageSize"
      >
        <template v-slot:item.points="{ item }">
          <span v-if="item.points>0">+</span>{{ item.points }}
        </template>

        <template v-slot:item.balance.student.student="{ item }">
          <v-btn class="text-caption" text small @click="setStudFilterTo(item.balance.student)">{{ item.balance.student.student }}</v-btn>
        </template>

        <template v-slot:item.compensationType.name="{ item }">
          <v-btn class="text-caption" text small @click="type = item.compensationType" v-if="item.compensationType !== null">
            {{ item.compensationType.name }}
          </v-btn>
        </template>

        <template v-slot:item.user.username="{ item }">
          <v-btn class="text-caption" text small @click="user = item.user" v-if="item.user !== null">
            {{ item.user.username }}
          </v-btn>
        </template>

          <template v-slot:item.comment="{ item }">
            <v-btn class="text-caption" text small @click="comment = item.comment" v-if="item.comment !== null">
              {{ item.comment }}
            </v-btn>
          </template>

        <template v-slot:item.assessmentCompensationPoint.compensationPoint.discipline.name="{ item }">
          <v-btn class="text-caption no-text-transform" small text
                 @click="setDisciplineFilterTo(item)" v-if="item.assessmentCompensationPoint !== null">
            <span style="white-space: normal;">{{ item.assessmentCompensationPoint.compensationPoint.discipline.name }}</span>
          </v-btn>
        </template>

        <template v-slot:item.balance.student.groups.name="{ item }">
          <v-btn class="text-caption" text small @click="group = item.balance.student.groups">{{ item.balance.student.groups.name }}</v-btn>
        </template>

        <template v-slot:item.updated="{ item }">
          {{ formatDate(item.updated) }}
        </template>
      </v-data-table>
        <!-- Пагинация -->
        <v-card-text>
        <v-row>
          <v-col
              cols="1"
              sm="1"
              md="1">
          </v-col>
          <v-col
              cols="1"
              sm="1"
              md="1">
            <v-select
                v-model="pageSize"
                :items="pageSizes"
                label="Количество строк "
                color="#36a2eb"
            >
            </v-select>
          </v-col>
          <v-col>
            <v-pagination
                class="my-4"
                v-model="pagination.page"
                :length="pages"
                total-visible="9"
                circle
                color="primary"
            >

            </v-pagination>
          </v-col>
        </v-row>
      </v-card-text>
        <!-- Пагинация-->

        <v-overlay
          absolute
          :value="loading"
        >
          <v-progress-circular color="primary" :value="loading"/>
        </v-overlay>
      </v-card>
    </v-container>
    <div v-if="halfYear !== null && yearOfStudy !== null && stud !== null" >
    <compensation-points-add ref="pointsAdd" :student="stud" :balance="pointsBalance"
                             @updateBalance="updateBalance"
                             @error="showErrorAlert"
                             @success="showSuccessAlert"
                             :half-year-id="halfYear.id"
                             :year-of-study-id="yearOfStudy.id"
    />
    <compensation-points-remove ref="pointsRemove" :balance="pointsBalance"
                                :student="stud"
                                @updateBalance="updateBalance"
                                @error="showErrorAlert"
                                @success="showSuccessAlert"
                                :half-year-id="halfYear.id"
                                :year-of-study-id="yearOfStudy.id"
    />
    <compensation-transfer ref="pointsTransfer" :balance="pointsBalance"
                           @updateBalance="updateBalance"
                           @error="showErrorAlert"
                           @success="showSuccessAlert"
                           :half-year-id="halfYear.id"
                           :year-of-study-id="yearOfStudy.id"
    />
    <compensation-restore ref="pointsRestore" :balance="pointsBalance"
                          @updateBalance="updateBalance"
                          @error="showErrorAlert"
                          @success="showSuccessAlert"
                          :half-year-id="halfYear.id"
                          :year-of-study-id="yearOfStudy.id"
    />
    </div>
  </v-container>
</template>

<script>
import {HTTP} from "@/settings/axios";
import Settings from "@/settings/settings_url";
import moment from "moment";
import CompensationPointsAdd from "@/components/compensation/points/CompensationPointsAdd";
import CompensationPointsRemove from "@/components/compensation/points/CompensationPointsRemove";
import CompensationTransfer from "@/components/compensation/points/CompensationPointsTransfer";
import CompensationRestore from "@/components/compensation/points/CompensationPointsRestore";

export default {
  name: "CompensationPoints",
  components: {
    CompensationPointsAdd,
    CompensationPointsRemove,
    CompensationTransfer,
    CompensationRestore,
  },

  data() {
    return {
      loading: false,
      creatingBalance: false,
      alert: false,
      alertText: '',
      alertType: 'success',

      group: null,
      groups: [],
      groupsLoading: false,

      type: null,
      types: [],
      typeLoading: false,
      pointsBalance: null,
      pointsAdded: null,
      pointsSpent: null,
      dialog: false,
      journal: [],
      headers: [
        {text: 'Студент', align: 'center', sortable: true, value: 'balance.student.student', divider: true, class: "bolt"},
        {text: 'Группа', align: 'center', sortable: true, value: 'balance.student.groups.name', divider: true, class: "bolt"},
        {text: 'Дата', align: 'center', sortable: true, value: 'updated', divider: true, class: "bolt"},
        {text: 'Компенсация', align: 'center', sortable: true, value: 'compensationType.name', divider: true, class: "bolt"},
        {text: 'Комментарий', align: 'center', sortable: true, value: 'comment', divider: true, class: "bolt"},
        {text: 'Дисциплина', align: 'center', sortable: true, value: 'assessmentCompensationPoint.compensationPoint.discipline.name', divider: true, class: "bolt"},
        {text: 'Баллы', align: 'center', sortable: true, value: 'points', divider: true, class: "bolt"},
        {text: 'Пользователем', align: 'center', sortable: true, value: 'user.username', divider: true, class: "bolt"},
      ],

      stud: null,
      studs: [],
      studentLoading: false,

      halfYear: null,
      halfYears: [],
      halfYearLoading: false,

      yearOfStudy: null,
      yearsOfStudy: [],
      yearLoading: false,

      discipline: null,
      disciplines: [],
      disciplineLoading: false,

      user: null,
      users: [],
      userLoading: false,

      comment: "Все",
      comments: [
          "Все", "Баллы добавлены", "Баллы потрачены", "Баллы убраны", "Баллы восстановлены", "Баланс создан",
      ],

      page: 1,
      pageSize: 10,
      totalItems: 0,
      pageSizes: [5, 10, 15, 20, 30, 40, 50, 60, 70, 80, 90, 100],
      pagination: {
        page: 1,
      },
    }
  },
  watch: {
    'pagination.page'() {
      this.getJournal()
    },
    pageSize() {
      this.getJournal()
    },
    async 'group'() {
      this.stud = null
      this.discipline = null

      if (this.group !== null) {
        await this.getStudent()
        this.getDisciplines()
      }

      this.getStatisticsIfFiltersApplied()
    },
    'stud'() {
      this.getStatisticsIfFiltersApplied()
    },
    'discipline'() {
      this.getStatisticsIfFiltersApplied()
    },
    'halfYear'() {
      this.getStatisticsIfFiltersApplied()
    },
    'yearOfStudy'() {
      this.getStatisticsIfFiltersApplied()
    },
    'type'() {
      this.getStatisticsIfFiltersApplied()
    },
    'comment'() {
      this.getStatisticsIfFiltersApplied()
    },
    'user'() {
      this.getStatisticsIfFiltersApplied()
    },
    'alert'() {
      if(this.alert)
        setTimeout(() => {this.alert = false}, 5000)
    },
  },
  methods: {
    async setStudFilterTo(item) {
      await this.setGroupFilterTo(item.groups)
      this.stud = item
    },
    async setGroupFilterTo(item) {
      this.group = item
    },
    async setDisciplineFilterTo(item) {
      await this.setGroupFilterTo(item.student.groups)
      this.discipline = item.assessmentCompensationPoint.compensationPoint.discipline
    },
    getStatisticsIfFiltersApplied() {
      if(this.stud !== null) {
        this.getStatistics()
        return
      }

      this.getJournal()
    },
    // createBalance() {
    //   this.creatingBalance = true
    //   HTTP.post(Settings.API.URL_DEFAULT + "/compensation-points-balance/create-balance", {
    //     "student": this.stud,
    //     "halfYear": this.halfYear,
    //     "yearOfStudy": this.yearOfStudy,
    //   })
    //       .then((response) => {
    //         this.pointsBalance = response.data
    //         this.getJournal()
    //         this.alert = true
    //         this.alertText = 'Баланс успешно создан'
    //         this.alertType = "success"
    //         // this.transferSuccessful = true
    //       })
    //       .catch(() => {
    //         this.showErrorAlert()
    //         this.alertText = 'Ошибка создания баланса'
    //       })
    //       .finally(() => {
    //         this.creatingBalance = false
    //       });
    // },
    showErrorAlert(alertText) {
      this.alert = true
      this.alertText = alertText
      this.alertType = "error"
    },
    getStatistics() {
      this.getBalance()
      this.getJournal()
      this.getAddedPoints()
      this.getSpentPoints()
    },
    showSuccessAlert(alertText) {
      this.alert = true
      this.alertText = alertText
      this.alertType = 'success'
    },
    updateBalance(newBalance) {
      this.pointsBalance = newBalance
      this.getJournal()
      this.getAddedPoints()
      this.getSpentPoints()
    },
    openAddDialog: function() {
      if(this.type !== null)
        this.$refs.pointsAdd.type = this.type
      this.$refs.pointsAdd.dialog = true
    },
    openRemoveDialog() {
      this.$refs.pointsRemove.dialog = true
    },
    openRestoreDialog() {
      this.$refs.pointsRestore.dialog = true
    },
    openTransferDialog() {
      if(this.discipline !== null)
        this.$refs.pointsTransfer.discipline = this.discipline
      this.$refs.pointsTransfer.dialog = true
    },
    getGroups: function () {
      this.groupsLoading = true
      HTTP.get(Settings.API.URL_DEFAULT + "/groups")
          .then((response) => {
            this.groups = response.data
          })
          .catch(() => {

          })
          .finally(() => {
            this.groupsLoading = false
          })
    },
    getStudent: async function () {
      if (this.group.id != undefined) {
        this.studentLoading = true
        await HTTP.get(Settings.API.URL_DEFAULT + "/groups/" + this.group.id + "/students")
            .then((response) => {
              // console.log(response.data)
              this.studs = response.data

            })
            .catch(() => {

            })
            .finally(() => {
              this.studentLoading = false
            });
      }
    },
    getDisciplines: async function () {
      if (this.group.id != undefined) {
        this.disciplineLoading = true
        await HTTP.get(Settings.API.URL_DEFAULT + "/disciplines/by-groupid?groupid=" + this.group.id)
            .then((response) => {
              // console.log(response.data)
              this.disciplines = response.data

            })
            .catch(() => {

            })
            .finally(() => {
              this.disciplineLoading = false
            });
      }
    },
    getUsers: async function () {
      this.userLoading = true
      HTTP.get(Settings.API.URL_DEFAULT + "/compensation-points/journal/users")
          .then((response) => {
            this.users = response.data
          })
          .catch(() => {

          })
          .finally(() => {
            this.userLoading = false
          })
    },
    getTypes: function () {
      this.typeLoading = true
      HTTP.get(Settings.API.URL_DEFAULT + "/compensation-type")
          .then((response) => {
            this.types = response.data
          })
          .catch(() => {

          })
          .finally(() => {
            this.typeLoading = false
          })
    },
    getJournal() {
      this.loading = true
      let url = ""

      if(this.stud !== null)
        url += "&studentId=" + this.stud.id

      if(this.type !== null)
        url += "&typeId=" + this.type.id

      if(this.group !== null)
        url += "&groupId=" + this.group.id

      if(this.discipline !== null)
        url += "&disciplineId=" + this.discipline.id

      if(this.user !== null)
        url += "&userId=" + this.user.id

      if(this.comment !== 'Все')
        url += "&comment=" + this.comment

      HTTP.get(Settings.API.URL_DEFAULT + "/compensation-points/journal/get-journal/paginated?halfYearId=" + this.halfYear.id
          + "&yearOfStudyId=" + this.yearOfStudy.id
          + "&page=" + this.pagination.page
          + "&size=" + this.pageSize
          + url)
          .then((response) => {
            this.journal = response.data.content
            this.totalItems = response.data.totalElements
            this.pages = response.data.totalPages
            if(this.pagination.page > this.pages)
              this.pagination.page = 1
          })
          .catch(() => {

          })
          .finally(() => {
            this.loading = false
          })
    },
    getBalance() {
      this.balanceLoading = true

      let url = ""

      if(this.stud !== null)
        url += "&studentId=" + this.stud.id

      if(this.type !== null)
        url += "&typeId=" + this.type.id

      if(this.group !== null)
        url += "&groupId=" + this.group.id

      HTTP.get(Settings.API.URL_DEFAULT + "/compensation-points-balance/get-balance?halfYearId=" + this.halfYear.id
          + "&yearOfStudyId=" + this.yearOfStudy.id
          + url)
          .then((response) => {
            this.pointsBalance = response.data
            if(response.data === '')
              this.pointsBalance = null
          })
          .catch(() => {

          })
          .finally(() => {
            this.balanceLoading = false
          })
    },
    getAddedPoints() {
      HTTP.get(Settings.API.URL_DEFAULT + "/compensation-points/journal" +
          "/get-all-added-points?studentId=" + this.stud.id +
          "&halfYearId=" + this.halfYear.id +
          "&yearOfStudyId=" + this.yearOfStudy.id)
          .then((response) => {
            this.pointsAdded = response.data
            if(!this.pointsAdded)
              this.pointsAdded = 0
          })
          .catch(() => {

          })
          .finally(() => {

          })
    },
    getSpentPoints() {
      HTTP.get(Settings.API.URL_DEFAULT + "/compensation-points/journal" +
          "/get-all-spent-points?studentId=" + this.stud.id +
          "&halfYearId=" + this.halfYear.id +
          "&yearOfStudyId=" + this.yearOfStudy.id)
          .then((response) => {
            this.pointsSpent = response.data

            if(!this.pointsSpent)
              this.pointsSpent = 0
            else
              this.pointsSpent *= -1
          })
          .catch(() => {

          })
          .finally(() => {

          })
    },
    formatDate(value) {
      moment.locale("ru")
      return moment(value).format("DD MMM YYYY г., HH:mm:ss")
    },
    updateTypes() {
      this.$refs.pointsRemove.getTypes()
      this.$refs.pointsAdd.getTypes()
    },
    getYears: async function () {
      this.yearLoading = true
      HTTP.get(Settings.API.URL_DEFAULT + "/year-of-study")
          .then((response) => {
            this.yearsOfStudy = response.data
          })
          .catch(() => {

          })
          .finally(() => {
            for (let i = 0; i < this.yearsOfStudy.length; i++) {
              if (this.yearsOfStudy[i]?.current == 1) {
                this.yearOfStudy = this.yearsOfStudy[i]
              }
            }
            this.yearLoading = false
          });
    },
    getHalfYears: async function () {
      this.halfYearLoading = true
      HTTP.get(Settings.API.URL_DEFAULT + "/half-year")
          .then((response) => {
            this.halfYears = response.data
          })
          .catch(() => {

          })
          .finally(() => {
            for (let i = 0; i < this.halfYears.length; i++) {
              if (this.halfYears[i].current == 1) {
                this.halfYear = this.halfYears[i]
                break
              }
            }
            this.halfYearLoading = false
          });
    },
  },
  created() {
    this.getGroups()
    this.getYears()
    this.getHalfYears()
    this.getTypes()
    this.getUsers()
  },
}
</script>

<style scoped>

</style>