<template>
  <div>
    <v-container>
      <v-card
          elevation="2"
      >
        <v-card-title>
          Доступ - MsTeams
        </v-card-title>
        <v-card-text>
          <div class="mb-4">
            <b>Логин - </b> {{msTeams.login}}
            <v-btn
                v-if="msTeams.login !== ''"
                icon
                @click="copyText(msTeams.login)"
            >
              <v-icon>content_copy</v-icon>
          </v-btn>
          </div>
          <div class="mb-16">
            <b>Пароль - </b> {{msTeams.password}}
            <v-btn
                v-if="msTeams.password !== ''"
                icon
                @click="copyText(msTeams.password)"
            >
              <v-icon>content_copy</v-icon>
            </v-btn>
          </div>
          <div style="color: #B71C1C">{{ message }}</div>
        </v-card-text>
        <v-card-actions class="pt-0">

          <v-btn
            text
            @click="updateMsTeams()"
            color="primary"
          >
            Сгенерировать новый пароль
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import {HTTP} from '@/settings/axios'
import Settings from "@/settings/settings_url";

export default {
  name: "MsTeamsStudent",
  data(){
    return{
      message:"",
      msTeams:{
        login:"",
        password: "",
      }
    }
  },
  methods:{
    copyText(text){
      var temp = document.createElement("input");
      temp.setAttribute('type', 'text')
      temp.value = text //.select();
      var body = document.body
      body.appendChild(temp)
      temp.select();
      document.execCommand("copy");
      temp.remove();
      // navigator.clipboard.writeText(text)
    },
    updateMsTeams(){
      if (confirm("Сгенерировать новые данные для входа? Новые данные будут активированы в течении 30 минут")){
        HTTP.post(Settings.API.URL_DEFAULT + "/ms-teams-onec/by-current-students")
            .then((response) =>{
              this.msTeams = response.data
              this.message = "Новый пароль активируется в течение 30 минут, повторное обновление будет доступно через час"
              console.log(response)
            }).catch(() =>{
          this.message = "Ошибка обновления, повторное обновление будет доступно через час"
        })
      }
    },
    getMsTeamsInfo(){
      HTTP.get(Settings.API.URL_DEFAULT + "/ms-teams-onec/by-current-students")
      .then((response) =>{
        this.msTeams = response.data
        console.log(response)
      })
    },
  },
  created() {
    this.getMsTeamsInfo()
  }
}
</script>

<style scoped>

</style>
