import Vue from 'vue'
import router from './router'
import store from './store'
import vuetify from '@/plugins/vuetify'
import axios from 'axios'
import VueAxios from 'vue-axios'
import App from  './App.vue'


Vue.config.productionTip = false
Vue.use(VueAxios, axios)
Vue.use(require('vue-shortkey'))

// Vue.prototype.$axios = axios;
//
// const token = localStorage.getItem('AuthorizationToken')
//
// if (token) {
//   Vue.prototype.$axios.defaults.headers.common['Authorization'] = token
// }

new Vue({
  vuetify,
  router,
  store,
  render: h => h(App)

}).$mount('#app')
