<template>
  <tr>
    <td
        class="td-border-right v-data-table__divider"
        v-for="header in headers"
        :key="header.text"
    >
      <div v-if="header.value === 'name'" class="text-center">
        <b>{{ item.item[header.value] }}</b>
      </div>
      <div v-else>
        <div v-if="item.item[header.value].workPlan.discipline != null" >
          <b>{{ item.item[header.value].workPlan.discipline.name }}</b>
        </div>
        <div v-if="item.item[header.value].workPlan.lessonTypes != null" class="mb-4">
          <i >({{ item.item[header.value].workPlan.lessonTypes.name }})</i>
        </div>
        <div v-for="subject in item.item[header.value].subject " :key="subject.name">
          <a v-if="subject.link != ''"
             :href="subject.link"
          >
            <b>{{ subject.name }}</b>
          </a>
          <span v-else>
            <b>{{ subject.name }}</b> {{ getReplacementTeachers(subject.replacementTeachers) }}
          </span>
          <span v-if="subject.audiences != null && subject.audiences.length > 0">
            : Аудитория - <b>{{ getAudiences(subject.audiences, subject.invitationCode).audiences }}</b>
          </span>

          <span v-if="getAudiences(subject.audiences, subject.invitationCode).link">
            : ссылка - <a :href="getAudiences(subject.audiences, subject.invitationCode).link">{{ getAudiences(subject.audiences, subject.invitationCode).link }}</a>
          </span>

          <span v-if="subject.subGroups != null">
            ({{ subject.subGroups.name }})
          </span>

        </div>
      </div>
    </td>
  </tr>
</template>

<script>
export default {
  name: "ScheduleItem",
  props: {
    item: Object,
    headers: Array,
  },
  data() {
    return {}
  },
  methods: {
    getAudiences(audienceList, inviteCode) {
      let audiences = ""
      let link = ""
      if (inviteCode && inviteCode.trim().substring(0,4) === "http"){
        link =  inviteCode
      }
      // console.log(inviteCode)
      if (audienceList != null && audienceList.length > 0) {
        for (let i = 0; i < audienceList.length; i++) {
          audiences += audienceList[i].name
          if (inviteCode != null && inviteCode.trim() != "") {
            if (inviteCode.trim().substring(0,4) !== "http"){
              audiences += "( код доступа: " + inviteCode + " )"
            }

          }
          if (i != audienceList.length - 1) {
            audiences += ", "
          }

        }
      }

      return {
        audiences,
        link
      }
    },
    getReplacementTeachers(teacherList) {
      let teachers = ""
      if (teacherList != null && teacherList.length > 0) {
        teachers += "("
        for (let i = 0; i < teacherList.length; i++) {
          teachers += teacherList[i].fio
          if (i != teacherList.length - 1) {
            teachers += ", "
          }
        }
        teachers += ")"
      }
      return teachers
    },
  }
}
</script>

<style scoped>

</style>