<template>
  <div>
      <v-data-table
          :headers="headers"
          :items="groupCertificates"
          :items-per-page="10"
          class="elevation-4"
          loading-text="Загрузка..."
          no-data-text="Нет информации"
      >
        <template v-slot:item.edit="{ item }">
          <v-btn icon
                 @click="editGroupCertificate(item)"
                 color="primary"
          >
            <v-icon>edit</v-icon>
          </v-btn>
        </template>

      </v-data-table>
  </div>
</template>

<script>
export default {
  name: "GroupCertificateList",
  props: {
    groupCertificates: {
      type: Array,
      required: true,
    }
  },
  data() {
    return {
      headers: [
        {text: 'N', align: 'center', sortable: false, value: 'id', divider: true,},
        {text: 'Название', value: 'title', divider: true,},
        {text: 'Описание', value: 'description', divider: true,},
        {text: 'Редактировать', value: 'edit', divider: true,},
      ],

    }
  },
  methods:{
    editGroupCertificate(item){
      this.$emit("editGroupCertificate", item)
    },
  },
}
</script>

<style scoped>

</style>