<template>
    <v-content fixed  >
    <v-container grid-list-xl class="lef" >
      <v-layout row wrap>

        <v-flex xs6 sm4 md3 xl2 class="lg5-custom">
            <v-hover>
              <template v-slot:default="{ hover }">
          <v-card min-height="100%"  to="/schedule-board/schedule-all" >
            <v-card-text align="center">
              <v-row><v-col></v-col></v-row>
              <v-row>
                <v-col>
                  <img  src="@/assets/pictures/schedule.svg" />
                </v-col>
              </v-row>
            <v-row>
              <v-col ><span class="ltr"><nobr>Расписание </nobr></span></v-col>
            </v-row>
              <v-row><v-col></v-col></v-row>
          </v-card-text>
            <v-fade-transition>
              <v-overlay
                  v-if="hover"
                  absolute
                  color="blue"
                  opacity="0.3"
              >
              </v-overlay>
            </v-fade-transition>
          </v-card>
              </template>
            </v-hover>
        </v-flex>
        <v-flex xs6 sm4 md3 xl2 class="lg5-custom">
          <v-hover>
            <template v-slot:default="{ hover }">
              <v-card min-height="100%"  to="/student-statistics" >
                <v-card-text align="center">
                  <v-row><v-col></v-col></v-row>
                  <v-row>
                    <v-col>
                      <img  src="@/assets/pictures/academ.svg" />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col ><span class="ltr">Успеваемость и посещаемость</span></v-col>
                  </v-row>
                  <v-row><v-col></v-col></v-row>
                </v-card-text>
                <v-fade-transition>
                  <v-overlay
                      v-if="hover"
                      absolute
                      color="blue"
                      opacity="0.3"
                  >
                  </v-overlay>
                </v-fade-transition>
              </v-card>
            </template>
          </v-hover>
        </v-flex>
        <v-flex xs6 sm4 md3 xl2 class="lg5-custom">
            <v-hover>
              <template v-slot:default="{ hover }">
          <v-card min-height="100%"  to="/certificate/certificate">
            <v-card-text align="center">
              <v-row><v-col></v-col></v-row>
              <v-row>
                <v-col>
                  <img  src="@/assets/pictures/usd.svg" />
                </v-col>
              </v-row>
            <v-row>
              <v-col><span class="ltr"><nobr>Сервисы</nobr> <nobr>Единого</nobr> <nobr>студенческого</nobr> <nobr>департамента</nobr></span></v-col>
            </v-row>
              <v-row><v-col></v-col></v-row>
          </v-card-text>
            <v-fade-transition>
              <v-overlay
                  v-if="hover"
                  absolute
                  color="blue"
                  opacity="0.3"
              >
              </v-overlay>
            </v-fade-transition>
          </v-card>
              </template>
            </v-hover>
        </v-flex>
        <v-flex xs6 sm4 md3 xl2 class="lg5-custom">
            <v-hover>
              <template v-slot:default="{ hover }">
                <a href = "https://www.sseu.ru/vneuchebnaya-deyatelnost/vneuchebnaya-deyatelnost">
          <v-card min-height="100%">
            <v-card-text align="center">
              <v-row><v-col></v-col></v-row>
              <v-row>
                <v-col>
                  <img  src="@/assets/pictures/activities.svg" />
                </v-col>
              </v-row>
            <v-row>
              <v-col><span class="ltr"><nobr>Внеучебная</nobr> <nobr>деятельность</nobr></span></v-col>
            </v-row>
              <v-row><v-col></v-col></v-row>
          </v-card-text>
            <v-fade-transition>
              <v-overlay
                  v-if="hover"
                  absolute
                  color="blue"
                  opacity="0.3"
              >
              </v-overlay>
            </v-fade-transition>
          </v-card>
                </a>
              </template>
            </v-hover>
        </v-flex>

        <v-flex xs6 sm4 md3 xl2 class="lg5-custom">
            <v-hover>
              <template v-slot:default="{ hover }">
              <a href = "https://lms2.sseu.ru/my/">
          <v-card min-height="100%" >
            <v-card-text align="center">
              <v-row><v-col></v-col></v-row>
              <v-row>
                <v-col>
                  <img src="@/assets/pictures/courses.svg" />
                </v-col>
              </v-row>
            <v-row>
              <v-col><span class="ltr"><nobr>Текущие</nobr> <nobr>курсы</nobr> и <nobr>образовательная</nobr> <nobr>траектория</nobr></span></v-col>
            </v-row>
              <v-row><v-col></v-col></v-row>
          </v-card-text>
            <v-fade-transition>
              <v-overlay
                  v-if="hover"
                  absolute
                  color="blue"
                  opacity="0.3"
              >
              </v-overlay>
            </v-fade-transition>
          </v-card>
              </a>
              </template>
            </v-hover>
        </v-flex>
        <v-flex xs6 sm4 md3 xl2 class="lg5-custom">
            <v-hover>
              <template v-slot:default="{ hover }">
                <a href = "https://lms2.sseu.ru/blocks/sibportfolio/index.php">
          <v-card min-height="100%">
            <v-card-text align="center">
              <v-row><v-col></v-col></v-row>
              <v-row>
                <v-col>
                  <img src="@/assets/pictures/porfolio.svg" />
                </v-col>
              </v-row>
            <v-row>
              <v-col><span class="ltr">Цифровое портфолио</span></v-col>
            </v-row>
              <v-row><v-col></v-col></v-row>
          </v-card-text>
            <v-fade-transition>
              <v-overlay
                  v-if="hover"
                  absolute
                  color="blue"
                  opacity="0.3"
              >
              </v-overlay>
            </v-fade-transition>
          </v-card>
                </a>
              </template>
            </v-hover>
        </v-flex>
        <v-flex xs6 sm4 md3 xl2 class="lg5-custom">
            <v-hover>
              <template v-slot:default="{ hover }">
                <a href = "https://www.sseu.ru/fakultetyi/fakultet-vtorogo-vyisshego-i-dopolnitelnogo-obrazovaniya">
          <v-card min-height="100%">
            <v-card-text align="center">
              <v-row><v-col></v-col></v-row>
              <v-row>
                <v-col>
                  <img src="@/assets/pictures/forum.svg" />
                </v-col>
              </v-row>
            <v-row>
              <v-col><span class="ltr"><nobr>Дополнительное</nobr> <nobr>образование</nobr></span></v-col>
            </v-row>
              <v-row><v-col></v-col></v-row>
          </v-card-text>
            <v-fade-transition>
              <v-overlay
                  v-if="hover"
                  absolute
                  color="blue"
                  opacity="0.3"
              >
              </v-overlay>
            </v-fade-transition>
          </v-card>
                </a>
              </template>
            </v-hover>
        </v-flex>
        <v-flex xs6 sm4 md3 xl2 class="lg5-custom">
            <v-hover>
              <template v-slot:default="{ hover }">
                <a href = "http://lib1.sseu.ru/MegaPro/Web">
          <v-card min-height="100%">
            <v-card-text align="center">
              <v-row><v-col></v-col></v-row>
              <v-row>
                <v-col>
                  <img src="@/assets/pictures/elibrary.svg" />
                </v-col>
              </v-row>
            <v-row>
              <v-col><span class="ltr"><nobr>Электронная</nobr> <nobr>библиотека</nobr></span></v-col>
            </v-row>
              <v-row><v-col></v-col></v-row>
          </v-card-text>
            <v-fade-transition>
              <v-overlay
                  v-if="hover"
                  absolute
                  color="blue"
                  opacity="0.3"
              >
              </v-overlay>
            </v-fade-transition>
          </v-card>
                </a>
              </template>
            </v-hover>
        </v-flex>



      </v-layout>
    </v-container>
  </v-content>
</template>

<script>

export default {
  name: "Account",

  data: () => ({
    overlay: false,




  }),

}
</script>
<!--                  <div class="test-svg"></div>-->
<!--.test-svg{-->
<!--width: 100px;-->
<!--height: 100px;-->
<!--background: url("../assets/pictures/porfolio.svg") no-repeat center !important;-->
<!--background-size: 100% 100%;-->
<!--}-->
<!--font-family: 'Acme';-->

<style   scoped>
@media (min-width: 1024px) and (max-width: 2048px) {
  .flex.lg5-custom {
    width: 25%;
    max-width: 25%;
    flex-basis: 45%;
  }
  .lef{
    padding-left: 0;
  }
}
.ltr {
  font-size: 115%;
  font-size: 2vmin;
  color: #015188;
  text-transform: uppercase;
  word-wrap: break-word;
  text-align: center;

}

img{
  width:20%;
}
a {
  text-decoration: none; /* Отменяем подчеркивание у ссылки */
}
</style>
