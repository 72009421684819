<template>
  <div>
    <v-container justify-center>
      <div class="text-center">
        <h2>Справки</h2>
      </div>
      <div class="container-menu">
        <div>
          <v-tabs
              v-model="tab"
          >
            <v-tab>
              <v-icon left>
                list
              </v-icon>
              Заявки
            </v-tab>
            <v-tab>
              <v-icon left>
                schedule
              </v-icon>
              В работе
            </v-tab>
            <v-tab>
              <v-icon left>
                done
              </v-icon>
              Готово
            </v-tab>
            <v-tab>
              <v-icon left>
                archive
              </v-icon>
              Архив
            </v-tab>
          </v-tabs>
        </div>
        <div style="margin: 5px 0 0 20px">
          <v-select v-model="edu"
                    :items="eduList"
                    label="Тип образования"
                    prepend-icon="school"
                    clearable
                    dense
          >
          </v-select>
        </div>
      </div>

      <v-tabs-items
          v-model="tab"
          :touchless="true"
      >
        <v-tab-item>
          <OrderCertificateEmployeeList
              class="ma-4"
              :orderCertificates="orderCertificateOrders"
              @inWork="inWork"
              @canceled="canceled"
          ></OrderCertificateEmployeeList>
        </v-tab-item>

        <v-tab-item>
          <OrderCertificateEmployeeList
              class="ma-4"
              :orderCertificates="orderCertificateInWorks"
              @ready="ready"
              @canceled="canceled"
          ></OrderCertificateEmployeeList>
        </v-tab-item>

        <v-tab-item>
          <OrderCertificateEmployeeList
              class="ma-4"
              :orderCertificates="orderCertificateReady"
              @completed="completed"
              @canceled="canceled"
          ></OrderCertificateEmployeeList>
        </v-tab-item>

        <v-tab-item>
          <OrderCertificateEmployeeList
              class="ma-4"
              :orderCertificates="orderCertificateArchive"
          ></OrderCertificateEmployeeList>
        </v-tab-item>

      </v-tabs-items>

    </v-container>
  </div>
</template>

<script>
import Settings from "@/settings/settings_url";
import {HTTP} from '@/settings/axios'

import OrderCertificateEmployeeList from "../../components/certificate/OrderCertificateEmployeeList";

export default {
  name: "OrderCertificate",
  components: {
    OrderCertificateEmployeeList,
  },
  data() {
    return {
      edu: "BACCALAUREATE",
      eduList: [
        {text: "Бакалавриат/Специалитет", value: "BACCALAUREATE"},
        {text: "Аспирантура", value: "ASPIRANT"},
        {text: "СПО", value: "SPO"},
      ],
      tab: 0,
      orderCertificateOrders: [],
      orderCertificateInWorks: [],
      orderCertificateReady: [],
      orderCertificateArchive: [],
    }
  },
  watch: {
    tab() {
      this.getOrderCertificateOrders()
      this.getOrderCertificateInWorks()
      this.getOrderCertificateReady()
      this.getOrderCertificateArchive()
    },
    edu() {
      this.getOrderCertificateOrders()
      this.getOrderCertificateInWorks()
      this.getOrderCertificateReady()
      this.getOrderCertificateArchive()
    },
  },
  methods: {

    inWork(item) {
      item.workStatus = "IN_WORK"
      this.updateOrderCertificate(item)
    },
    ready(item) {
      item.workStatus = "READY"
      this.updateOrderCertificate(item)
    },
    completed(item) {
      item.workStatus = "COMPLETED"
      this.updateOrderCertificate(item)
    },
    canceled(item) {
      item.workStatus = "CANCELED"
      this.updateOrderCertificate(item)
    },
    updateOrderCertificate(item) {
      HTTP.post(Settings.API.URL_DEFAULT + "/order-certificate", item)
          .then(() => {
            this.getOrders()
          })
          .catch(() => {
          })
          .finally(() => {

          });
    },
    getOrderCertificateOrders: async function () {
      let workStatus = ["CREATION"]

      HTTP.post(Settings.API.URL_DEFAULT + "/order-certificate/by-work-status-and-edu?edu=" + this.edu , workStatus)
          .then((response) => {
            this.orderCertificateOrders = response.data
          })
          .catch(() => {
          })
          .finally(() => {

          });
    },
    getOrderCertificateInWorks: async function () {
      let workStatus = ["IN_WORK"]

      HTTP.post(Settings.API.URL_DEFAULT + "/order-certificate/by-work-status-and-edu?edu=" + this.edu, workStatus)
          .then((response) => {
            this.orderCertificateInWorks = response.data
          })
          .catch(() => {
          })
          .finally(() => {

          });
    },
    getOrderCertificateReady: async function () {
      let workStatus = ["READY"]
      HTTP.post(Settings.API.URL_DEFAULT + "/order-certificate/by-work-status-and-edu?edu=" + this.edu, workStatus)
          .then((response) => {
            this.orderCertificateReady = response.data
          })
          .catch(() => {
          })
          .finally(() => {

          });
    },
    getOrderCertificateArchive: async function () {
      let workStatus = ["COMPLETED", "CANCELED"]
      HTTP.post(Settings.API.URL_DEFAULT + "/order-certificate/by-work-status-and-edu?edu=" + this.edu, workStatus)
          .then((response) => {
            this.orderCertificateArchive = response.data
          })
          .catch(() => {
          })
          .finally(() => {

          });
    },
    getOrders() {
      this.getOrderCertificateOrders()
      this.getOrderCertificateInWorks()
      this.getOrderCertificateReady()
      this.getOrderCertificateArchive()
    }
  },
  created() {
    this.getOrders()
  }
}
</script>

<style scoped>
.container-menu {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
</style>
