<template>
  <div id="example-1">
    <v-container justify-center>
      <v-flex xs12>
        <v-card>
          <v-flex xs12 md12>
            <v-card-title class="justify-center">
              Редактирование контрольных точек
            </v-card-title>

          </v-flex>
          <v-card-text>
            <v-layout row wrap>

              <v-flex xs12 md12>

                <v-row>
                  <v-col>
                    <v-autocomplete v-model="group" @change="onChangeGroups"
                                    :items="groups"
                                    label="Группы"
                                    outlined
                                    dense
                                    chips
                                    small-chips
                                    item-text="name"
                                    item-value="id"
                                    return-object
                    >
                    </v-autocomplete>
                  </v-col>

                  <v-col>
                    <v-autocomplete v-model="discipline" @change="onChangeDiscipline"
                                    :items="disciplines"
                                    label="Дисциплина"
                                    outlined
                                    dense
                                    chips
                                    small-chips
                                    item-text="workPlan.discipline.name"
                                    item-value="id"
                                    return-object
                    >
                    </v-autocomplete>
                  </v-col>

                </v-row>

                <v-row>
                  <v-spacer></v-spacer>
                  <v-btn
                      class="mx-2"
                      fab
                      dark
                      color="indigo"
                      :disabled="showNewButton()"
                      @click="newControlPoints"
                  >
                    <v-icon dark>
                      add
                    </v-icon>
                  </v-btn>
                </v-row>

                <v-data-table
                    :headers="headers"
                    :items="controlPoints"
                    :hide-default-footer="true"
                    :items-per-page="2000"
                >
                  <template v-slot:item.edit="{ item }">
                    <v-btn v-if="isEdit(item)" @click="editControlPoints(item)" icon>
                      <v-icon>create</v-icon>
                    </v-btn>
                  </template>
                </v-data-table>
              </v-flex>
            </v-layout>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>

          </v-card-actions>
        </v-card>
      </v-flex>
    </v-container>
    <v-container>
      <template>
        <v-row justify="center">
          <v-dialog
              v-model="dialog"
              persistent
              max-width="600px"
          >
            <v-card>
              <v-card-title>
                <!--                      <span class="headline">{{ title_dialog }}</span>-->
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col
                        cols="12"
                        sm="12"
                        md="12"
                    >

                      <template>
                        <v-row>
                          <v-text-field
                              v-model="controlPoint.num"
                              label="Порядковый номер"
                              :rules="rules.concat(rulesNum)"
                              hide-details="auto"
                          ></v-text-field>
                        </v-row>
                        <v-row class="v-text-field">

                            <v-autocomplete style="margin-top: 10px" v-model="controlPoint.name"
                                            :items="nameControlPoints"
                                            label="Название"
                                            dense
                                            :rules="rules"
                            >
                            </v-autocomplete>

                          <!--                          <v-text-field-->
                          <!--                              v-model="controlPoint.name"-->
                          <!--                              label="Название"-->
                          <!--                              :rules="rules"-->
                          <!--                              hide-details="auto"-->
                          <!--                          ></v-text-field>-->
                        </v-row>
                        <v-row>
                          <v-text-field
                              v-model="controlPoint.numberOfPoints"
                              label="Кол Баллов"
                              v-bind:rules="rules.concat(rulesMaxEval)"
                              hide-details="auto"
                          ></v-text-field>
                        </v-row>
                      </template>
                    </v-col>
                  </v-row>
                </v-container>

              </v-card-text>
              <v-card-actions>
                <v-btn
                    color="red darken-1"
                    text
                    @click="deletedControlPoint"
                >
                  Deleted
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn
                    color="blue darken-1"
                    text
                    @click="dialog = false"
                >
                  Close
                </v-btn>
                <v-btn
                    color="blue darken-1"
                    text
                    @click="saveControlPoints"
                >
                  Save
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-row>
      </template>
    </v-container>


  </div>

</template>

<script>
import Settings from '../settings/settings_url'
import {HTTP} from '@/settings/axios'

export default {
  name: "AdminControlPoints",
  data() {
    return {
      dialog: false,

      group: {},
      groups: [],

      discipline: {},
      disciplines: [],
      controlPoints: [],
      nameControlPoints: [
        'тестирование',
        'эссе',
        'кейс-задания',
        'лабораторные задания',
        'реферат',
        'расчетные задания',
        'творческие задания',
        'коллоквиумы',
        'дискуссии',
        'творческие задания',
      ],

      rulesMaxEval: [v => v <= this.calcMaxValue(v) || 'Max ' + this.calcMaxValue(v) + ' Баллов'],
      rulesNum: [v => v != 99 && v != 88 && v != 77 || 'Запрещено ' + v],

      maxValue: 0,

      headers: [
        {text: 'id', align: 'start', sortable: true, value: 'id', class: "",},
        {text: 'Порядковый номер', value: 'num', sortable: true,},
        {text: 'Название', value: 'name', sortable: true,},
        {text: 'Max. Балл', value: 'numberOfPoints', sortable: true,},
        {text: 'Редактировать', value: 'edit', sortable: false,},
      ],
      rules: [
        value => !!value || 'Required.',
      ],
      halfYear: 2,
      templateControlPoints: {
        dateControl: null,
        id: null,
        name: null,
        num: null,
        numberOfPoints: null,
      },
      controlPoint: {
        dateControl: null,
        id: null,
        name: null,
        num: null,
        numberOfPoints: null,
      },
    };
  },
  methods: {

    isEdit: function (item) {
      let res = false
      this.user.role.forEach(function (role) {
        if (role.name == "ROLE_ADMIN") {
          res = true
        }
      })
      this.user.role.forEach(function (role) {
        if (role.name == "ROLE_TEACHER" && item.num != 77 && item.num != 88 && item.num != 99) {
          res = true
        }
      })
      return res
    },
    calcMaxValue: function (item) {
      if ((parseInt(item) instanceof Number || typeof parseInt(item) === 'number') && !isNaN(parseInt(item))) {
        let currentVal = 0
        this.controlPoints.forEach(function (cp) {
          if (cp.num != 77 && cp.num != 88 && cp.num != 99) {
            currentVal += parseInt(cp.numberOfPoints)
          }
        })

        if (this.controlPoint.id != undefined && this.controlPoint.id != null) {
          let selectCpId = this.controlPoint.id
          let cPoint = {}
          this.controlPoints.forEach(function (cp) {
            if (cp.id == selectCpId) {
              cPoint = {...cp}
            }
          })
          return this.maxValue - currentVal + cPoint.numberOfPoints
        } else {
          return this.maxValue - currentVal
        }
      } else {
        return this.maxValue;
      }
    },
    showNewButton: function () {
      return this.controlPoints.length >= 5 || this.controlPoints.length == 0
    },
    newControlPoints: function () {
      this.dialog = true
      this.controlPoint = {...this.templateControlPoints}
    },
    editControlPoints: function (item) {
      this.dialog = true
      this.controlPoint = {...item}
    },
    getMaxValue: function () {
      HTTP.get(Settings.API.URL_DEFAULT + "/groups/" + this.group.id + "/point-ratings")
          .then((response) => {
            let val = 0
            response.data.forEach(function (item) {
              if (item.pointRatingType.id == 3) {
                val = item.value
              }
            })
            this.maxValue = val
          })
          .catch(() => {

          })
          .finally(() => {

          });
    },
    deletedControlPoint: function () {
      HTTP.delete(Settings.API.URL_DEFAULT + "/control-points/" + this.controlPoint.id)
          .then((response) => {
            console.log(response.data)
            this.getControlPoints()
            this.dialog = false
          })
          .catch(() => {
            alert("Объект не может быть удален, удалите проставленные баллы")
          })
          .finally(() => {

          });
    },
    saveControlPoints: function () {
      HTTP.post(Settings.API.URL_DEFAULT + "/workload/" + this.discipline.id + "/control-points", this.controlPoint)
          .then((response) => {
            console.log(response.data)
            this.getControlPoints()
            this.dialog = false
          })
          .catch(() => {
            alert("Введены недопустимые значения")
          })
          .finally(() => {

          });
    },
    onChangeGroups: function () {
      this.discipline = {}
      this.controlPoints = []
      this.getDiscipline()
      this.getMaxValue()
    },
    onChangeDiscipline: function () {
      this.controlPoints = []
      this.getControlPoints()
    },
    getControlPoints: function () {
      HTTP.get(Settings.API.URL_DEFAULT + "/workload/" + this.discipline.id + "/control-points")
          .then((response) => {
            this.controlPoints = response.data
          })
          .catch(() => {

          })
          .finally(() => {

          });
    },
    getDiscipline: function () {
      if (this.halfYear != undefined && this.halfYear != null) {
        HTTP.get(Settings.API.URL_DEFAULT + "/groups/" + this.group.id + "/lecture-workload-by-user-role?halfyear=" + this.halfYear)
            .then((response) => {
              this.disciplines = response.data
              console.log(this.disciplines)
            })
            .catch(() => {

            })
            .finally(() => {

            });
      }
    },
    getGroups: function () {
      if (this.halfYear != undefined && this.halfYear != null) {
        HTTP.get(Settings.API.URL_DEFAULT + "/groups/by-curriculum")
            .then((response) => {
              this.groups = response.data
              console.log(this.groups)
            })
            .catch(() => {

            })
            .finally(() => {

            });
      }
    },

    getCurrentUser: function () {
      HTTP.get(Settings.API.URL_DEFAULT + "/users/current-user")
          .then((response) => {
            this.user = response.data
          })
          .catch(() => {

          })
          .finally(() => {

          });
    },
  },
  created() {
    this.getGroups()
    this.getCurrentUser()
  },
  mounted() {

  },
}
</script>

