<template>
  <diV>
    <v-card elevation="3">
      <v-simple-table>
        <template v-slot:default>
          <thead class="letr">
          <tr>
            <th></th>
            <th class="text-left">
              <h2>
                Номер заявки
              </h2>
            </th>
            <th class="text-left">
              <h2>
                Окно
              </h2>
            </th>
          </tr>
          </thead>
          <tbody>
          <tr
              v-for="item in items"
              :key="item.num"
              class="text-left"
          >
            <td></td>
            <td><span class="ltr">{{ item.queueTicket.num }} {{ item.queueTicket.queueGroups.letter }}</span></td>
            <td><span class="ltr">{{ item.queueManager.queueWindow.name }}</span></td>

          </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card><!--    <v-data-table-->
    <!--        :headers="headers"-->
    <!--        :items=" ques"-->
    <!--        :items-per-page="5"-->
    <!--        class="elevation-1"-->
    <!--    ></v-data-table>-->
  </diV>
</template>

<script>
import Settings from "@/settings/settings_url";
import {HTTP} from '@/settings/axios'

export default {
  name: "Tablo",
  data: () => ({
    items: [],
  }),
  methods: {
    getQueue() {
      HTTP.get(Settings.API.URL_DEFAULT + "/virtual-queue/queue")
          .then((response) => {
            this.items = response.data
            // console.log(response)
          }).catch(() => {
      })
    },
  },
  created() {
    setInterval(this.getQueue, 5000);
  }

}
</script>

<style scoped>
.letr {
  font-size: 235%;
  color: rgb(1, 81, 136, 0.8);
}

.ltr {
  color: rgb(1, 81, 136);
  font-size: 135%
}
</style>