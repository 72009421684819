<template>
  <v-container v-show="isTeacher()">
    <v-card-title class="d-flex justify-center text-center"><h2>Служебные записки</h2></v-card-title>
    <v-container/>
    <v-divider/>
    <ticket-history
        ref="history"
        history-for="TEACHER"
    />
  </v-container>
</template>

<script>
import TicketHistory from "@/components/visitTicket/TicketHistory";
export default {
  name: "TeacherTicketPage",
  components: {TicketHistory},
  methods: {
    updateHistory() {
      this.$refs.history.getTickets()
    },
    isTeacher() {
      return this.isRole("ROLE_TEACHER")
    },
    isRole(role) {
      if (this.$store.getters.selectedUser?.role != null && this.$store.getters.selectedUser?.role.length > 0){
        for (let i = 0; i < this.$store.getters.selectedUser?.role.length; i++){
          if (
              this.$store.getters.selectedUser?.role[i].name === role
          ){
            return true
          }
        }
      }else {
        return  false
      }
    },
  },

  watch: {
    '$store.getters.selectedUser.teachers'() {
      if (this.$store.getters.selectedUser && this.$store.getters.selectedUser.teachers) {
        this.$refs.history.teacherId = this.$store.getters.selectedUser.teachers.id
      }
    },
  },

  mounted() {
    if (this.$store.getters.selectedUser && this.$store.getters.selectedUser.teachers) {
      this.$refs.history.teacherId = this.$store.getters.selectedUser.teachers.id
    }
  },
}
</script>

<style scoped>

</style>