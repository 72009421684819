<template>
  <div class="doc">
    <div  class="doc__header">
      <div class="doc__title">
        Протокол оценки сформированности компетенций
      </div>
      <div class="doc__sub-title">
        (этапа сформированности компетенций)
      </div>
    </div>
    <p>Обучающийся {{student.student}}</p>
    <p>по образовательной программе {{student.groups.direction}} {{student.groups.program}} </p>
    <p>{{student.groups.course}} {{student.groups.formOfTraining}} форма обучения</p>
    <div class="doc_header-table">
      Результаты оценки сформированности компетенций представлены в таблице:
    </div>
    <table>
      <tr>
        <th>№ п/п</th>
        <th>Наименование дисциплины</th>
        <th>Перечень проверяемых компетенций</th>
        <th>Результаты промежуточной  аттестации</th>
        <th>Результаты комплексного тестирования</th>
      </tr>
      <tr v-if="!comprehensiveTestings || comprehensiveTestings.length === 0">
        <td colspan="5" class="text-center red--text">
          Нет пройденных тестов!
        </td>
      </tr>
      <tr v-for="(item, i) in comprehensiveTestings" :key="item.id">
        <td>{{ i + 1 }}</td>
        <td>{{ item.fullname }}</td>
        <td></td>
        <td style="text-align: center">{{ item.markAndCompetencies.grade }}</td>
        <td style="text-align: center">{{ item | stringGrade }}</td>
<!--        <td>({{ item.grade.toFixed(2) }}/{{ item.maxGrade.toFixed(2) }})</td>-->
      </tr>
    </table>
    <hr style="margin-top: 40px"/>
  </div>
</template>

<script>
import Settings from "@/settings/settings_url";
import {HTTP} from '@/settings/axios'

export default {
  name: "ComprehensiveTestingStudentCard",
  props:{
    student: Object
  },
  data(){
    return {
      comprehensiveTestings: [],
    }
  },
  filters: {
    stringGrade: function (item) {
      let value = item.grade / item.maxGrade

      if (!value) return ''
      if (value < 0.6){
        return 'Неудовлетворительно'
      }else if(value >= 0.6 && value < 0.8){
        return 'Удовлетворительно'
      }else if(value >= 0.8 && value < 1.0){
        return 'Хорошо'
      }else if(value >= 1.0){
        return 'Отлично'
      }
      return ''
    }
  },
  methods:{
    getComprehensiveTesting: function () {
      HTTP.get(Settings.API.URL_DEFAULT + "/comprehensive-test/stats?studentId=" + this.student.id)
          .then((response) => {
            // console.log(response.data)
            this.comprehensiveTestings = response.data

            let disciplines = this.$store.getters.comp_testing_disciplines

            for(let i = 0; i < this.comprehensiveTestings.length; i++) {
              let disciplineId = disciplines.findIndex(element => {
                if (element.name === this.comprehensiveTestings[i].fullname)
                  return true
              })

              if (disciplineId === -1) {
                let discipline = {
                  name: this.comprehensiveTestings[i].fullname,
                  points: this.comprehensiveTestings[i].grade / this.comprehensiveTestings[i].maxGrade,
                  gradedStudentCount: 1,
                  avgGrade: (5 * this.comprehensiveTestings[i].grade / this.comprehensiveTestings[i].maxGrade).toFixed(2),
                  attestationPoints: this.numberGrade(this.comprehensiveTestings[i].markAndCompetencies.grade),
                  attestatedStudentCount: 1,
                  avgAttestationGrade: this.numberGrade(this.comprehensiveTestings[i].markAndCompetencies.grade).toFixed(2)
                }
                if(this.numberGrade(this.comprehensiveTestings[i].markAndCompetencies.grade) === -1) {
                  discipline.attestationPoints = 0
                  discipline.attestatedStudentCount = 0
                  discipline.avgAttestationGrade = 0
                }
                disciplines.push(discipline);
              } else {
                disciplines[disciplineId].points += this.comprehensiveTestings[i].grade / this.comprehensiveTestings[i].maxGrade
                disciplines[disciplineId].gradedStudentCount++
                disciplines[disciplineId].avgGrade = (5 * disciplines[disciplineId].points / disciplines[disciplineId]
                    .gradedStudentCount).toFixed(2)
                if(this.numberGrade(this.comprehensiveTestings[i].markAndCompetencies.grade) !== -1) {
                  disciplines[disciplineId].attestationPoints += this.numberGrade(this.comprehensiveTestings[i].markAndCompetencies.grade)
                  disciplines[disciplineId].attestatedStudentCount++
                  disciplines[disciplineId].avgAttestationGrade = (disciplines[disciplineId].attestationPoints / disciplines[disciplineId].attestatedStudentCount).toFixed(2)
                }
              }
            }
            this.$store.commit("comp_testing_disciplines", disciplines)
          })
          .catch(() => {

          })
          .finally(() => {

          });
    },
    numberGrade: function (item) {
      if (item == 'Неудовлетворительно'){
        return 2
      }else if(item == 'Удовлетворительно'){
        return 3
      }else if(item == 'Хорошо'){
        return 4
      }else if(item == 'Отлично'){
        return 5
      }
      return -1
    },
  },
  mounted() {
    this.getComprehensiveTesting();
  },
  beforeDestroy() {
    let disciplines = this.$store.getters.comp_testing_disciplines
    for(let i = 0; i < this.comprehensiveTestings.length; i++) {
      // this.comprehensiveTestings[i]
      let disciplineId = disciplines.findIndex(element => {
        if (element.name === this.comprehensiveTestings[i].fullname)
          return true
      })

      if (disciplineId !== -1) {
        disciplines[disciplineId].points -= this.comprehensiveTestings[i].grade / this.comprehensiveTestings[i].maxGrade
        disciplines[disciplineId].gradedStudentCount--
        if(this.numberGrade(this.comprehensiveTestings[i].markAndCompetencies.grade) !== -1) {
          disciplines[disciplineId].attestationPoints -= this.numberGrade(this.comprehensiveTestings[i].markAndCompetencies.grade)
          disciplines[disciplineId].attestatedStudentCount--
          disciplines[disciplineId].avgAttestationGrade = (disciplines[disciplineId].attestationPoints / disciplines[disciplineId].attestatedStudentCount).toFixed(2)
        }
        if(disciplines[disciplineId].gradedStudentCount < 1) {
          disciplines[disciplineId].avgGrade = 0
          disciplines[disciplineId].avgAttestationGrade = 0
        } else {
          disciplines[disciplineId].avgGrade = (5 * disciplines[disciplineId].points / disciplines[disciplineId].gradedStudentCount).toFixed(2)
        }
      }
    }
    this.$store.commit("comp_testing_disciplines", disciplines)
  }
}
</script>

<style lang="scss" scoped>

.doc__header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-weight: 900;
  font-size: 1.3em;
.doc__title{
  display: flex;
  justify-content: center;
}
.doc__sub-title{
  display: flex;
  justify-content: center;
  margin: 0 0 40px 0;
}
}

.doc_header-table{
  display: flex;
  justify-content: center;
  margin: 40px 0 10px 0;
  font-size: 1.2em;
}
.doc {
  max-width: 900px;
}
table {
  border: 1px solid black;
  width: 100%;
  border-spacing: 0;
}
td {
  padding: 5px;
  border: 1px solid black;
}
th {
  padding: 5px; /* Поля вокруг текста */
  border: 1px solid black; /* Граница вокруг ячеек */
}
</style>