export default {
    actions: {

    },
    state: {
        isAdmin: false,
        isSdkSpo: false,
        isTeacher: false,
        user: Object,
        users: Array,
        selectedUser: Object,
        students: Array,
        selectedStudent: Object,
    },
    mutations: {
        user(state, user){
            state.user = user;
            for (let i = 0; i < user.role.length; i++){
                if (user.role[i].name === "ROLE_ADMIN"){
                    state.isAdmin = true
                }
                if (user.role[i].name === "ROLE_SDK_SPO"){
                    state.isAdmin = true
                }
                if (user.role[i].name === "ROLE_TEACHER"){
                    state.isTeacher = true
                }
            }
        },
        users(state, users){
            state.users = users;
        },
        selectedUser(state, selectedUser){
            state.selectedUser = selectedUser;
        },
        students(state, students){
            state.students = students;
        },
        selectedStudent(state, selectedStudent){
            state.selectedStudent = selectedStudent;
        },
    },
    getters: {
        user(state){
            return state.user
        },
        isAdmin(state){
            return state.isAdmin
        },
        isTeacher(state){
            return state.isTeacher
        },
        isSdkSpo(state){
            return state.isSdkSpo
        },
        users(state){
            return state.users
        },
        selectedUser(state){
            return state.selectedUser
        },
        students(state){
            return state.students;
        },
        selectedStudent(state){
            return state.selectedStudent;
        }
    }
}