<template>
  <thead class="v-data-table-header">
  <tr>
    <th class="text-start v-data-table__divider" v-for="head in props.headers" :key="head.text"
        @dblclick="showDialogEditSchedule(head.schedule)"
    >
      {{ head.text }}
      <span v-if="head.schedule instanceof Object"> ({{ head.schedule.indexNumber }}) </span>
    </th>
  </tr>

  <index-schedule-edit
      :show="dialogScheduleEdit"
      :schedule="selectedSchedule"
      @success="updateJournal()"
      @closeDialog="closeIndexEdit()"
  ></index-schedule-edit>

  </thead>
</template>

<script>
import IndexScheduleEdit from '@/components/schedule/dialog/IndexScheduleEdit';

export default {
  name: "TableHeader",
  components: {
    IndexScheduleEdit,
  },
  props: {
    props: Object,
  },
  data() {
    return {
      dialogScheduleEdit: false,
      selectedSchedule: {}
    }
  },
  methods: {
    closeIndexEdit(){
      this.dialogScheduleEdit = false
    },
    updateJournal(){
      this.dialogScheduleEdit = false
      this.$emit("updateJournal")
    },
    showDialogEditSchedule(schedule) {
      if (schedule instanceof Object) {
        this.selectedSchedule = schedule
        this.dialogScheduleEdit = true
      }
    },
  }
}
</script>

<style scoped>

</style>