<template>
  <v-container>
    <v-container class="d-flex flex-column text-center ">
      <h2>Анкетирование</h2>
      <template v-if="testingForm !== null">
        <h1>«{{ testingForm.name }}»</h1>
      </template>
    </v-container>
    <div class="text-center" v-if="loading">
      <v-progress-circular
          v-show="loading"
          color="primary"
          :value="loading"
          indeterminate
      /><br/>
      Загружаем анкету...
    </div>

    <div v-if="testingForm !== null && isAllowedToPassTheForm()"
         v-show="(!formIsPassed && !isTeacherSatisfactionForm) || (isTeacherSatisfactionForm && disciplines.length > 0)"
    >
      <v-container class="d-flex flex-column text-center">
        <p>Вопросов: {{ testingForm.questionList.length }} шт.</p>
      </v-container>

      <v-container v-if="isTeacherSatisfactionForm && timesPassedWithinAllowedAttempts">
        <v-row>
          <v-col/>
          <v-col>
            <v-autocomplete v-model="discipline"
                            item-value="id"
                            return-object
                            :item-text="disciplineToTeacherName"
                            :items="disciplines"
                            prepend-icon="group"
                            label="Преподаватель"
                            dense
                            :loading="disciplinesLoading"
                            :disabled="savingAnswers || disciplinesLoading"
                            :hint="discipline == null? 'Выберите преподавателя, чтобы открыть анкету' : ''"
                            :persistent-hint="discipline == null"
            />
          </v-col>
          <v-col/>
        </v-row>
        <v-row align="center">
          <v-col/>
          <v-col class=" text-center">
            <v-btn
                v-show="discipline !== null && discipline.multipleTeachersOnSameDiscipline"
                @click="openWrongTeacherDialog"
                color="primary"
            >
              Данный преподаватель не ведет у меня
            </v-btn>
          </v-col>
          <v-col/>
        </v-row>
      </v-container>

      <v-container v-show="isDisciplineSelectedOrNotTeacherSatisfactionForm()">
        <v-container class="d-flex flex-column text-center">
          <p>{{ testingForm.description }}</p>
        </v-container>
        <v-form v-model="valid" ref="form" lazy-validation>
          <v-container v-for="question in testingForm.questionList" :key="question.id">
            <v-card outlined
                    :style="question.valid ? 'border: 1px solid red;' : 'border: 1px solid black;'"
                    :ref="question.id">
              <testing-form-question
                  ref="question"
                  :question="question"
                  @validate="validate"
                  @reset-validation="resetValidation"
              />
            </v-card>
          </v-container>
        </v-form>

        <v-container class="d-flex justify-end align-end">
          <v-btn color="primary"
                 @click="openSavingDialogIfValid"
                 :disabled="savingAnswers" :loading="savingAnswers"
                 v-show="isDisciplineSelectedOrNotTeacherSatisfactionForm()"
          >
            <v-icon>done</v-icon>
            Сохранить ответы
          </v-btn>

          <v-btn color="primary"
                 @click="openSavingDialogIfValid"
                 :disabled="savingAnswers" :loading="savingAnswers"
                 fab fixed bottom right
                 style="
                 width: fit-content;
                 border-radius: 4px;
                 padding: 16px;
                 "
                 tile
          >
            <v-icon left>done</v-icon>
            <span>Сохранить ответы</span>
          </v-btn>
        </v-container>
      </v-container>

      <v-dialog v-model="confirmingAnswers" max-width="600">
        <v-card>
          <v-toolbar
              dark
              color="primary"
          >
            <v-btn
                icon
                dark
                @click="confirmingAnswers = false"
            >
              <v-icon>close</v-icon>
            </v-btn>
            <v-card-title>Сохранить ответы</v-card-title>
            <v-spacer/>
          </v-toolbar>
          <v-divider/>
            <p>
              Вы уверены?
            </p>
          <v-divider/>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                text
                color="secondary"
                @click="confirmingAnswers = false"
                :disabled="savingAnswers"
            >
              Нет
            </v-btn>
            <v-btn
                text
                color="primary"
                @click="saveAnswers"
                :disabled="savingAnswers" :loading="savingAnswers"
            >
              Да
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>

    <div v-if="!loading && !isAllowedToPassTheForm()">
      <v-alert type="info">
        <v-row align="center">
          <v-col class="grow">
            {{ errorText }}
          </v-col>
          <v-col class="shrink">
            <v-btn text :to="goBackToPreviousLink()">
              <v-icon>arrow_back</v-icon>
              Вернуться на сайт
            </v-btn>
          </v-col>
        </v-row>
      </v-alert>
    </div>

    <div v-if="!loading && !disciplinesLoading && formIsPassed"
         class="d-flex flex-column text-center "
    >
      <v-alert type="info">
        <v-row align="center">
          <v-col class="grow">
            Благодарим за прохождение анкеты. <span v-if="disciplines.length > 0">Осталось пройти анкет: {{ disciplines.length }}</span>
          </v-col>
          <v-col class="shrink">
            <v-btn text :to="goBackToPreviousLink()" v-if="disciplines.length == 0">
              <v-icon>arrow_back</v-icon>
              Вернуться на сайт
            </v-btn>
          </v-col>
        </v-row>
      </v-alert>
    </div>

    <div v-if="isTeacherSatisfactionForm">
      <v-dialog v-model="wrongTeacherDialog" max-width="600">
        <v-card>
          <v-toolbar
              dark
              color="primary"
          >
            <v-btn
                icon
                dark
                @click="wrongTeacherDialog = false"
            >
              <v-icon>close</v-icon>
            </v-btn>
            <v-card-title>Данный преподаватель не ведёт у меня</v-card-title>
            <v-spacer/>
          </v-toolbar>
          <v-divider/>
          <p>
            Вы уверены?
          </p>
          <v-divider/>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                text
                color="secondary"
                @click="wrongTeacherDialog = false"
                :disabled="savingAnswers"
            >
              Нет
            </v-btn>
            <v-btn
                text
                color="primary"
                @click="wrongTeacherInTeacherSatisfaction"
                :disabled="savingAnswers" :loading="savingAnswers"
            >
              Да
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>

  </v-container>
</template>

<script>
import TestingFormQuestion from "@/components/testingForm/TestingFormQuestion";
import {HTTP} from "@/settings/axios";
import Settings from "@/settings/settings_url";
export default {
  name: "TestingForm",
  components: {TestingFormQuestion},

  data() {
    return {
      confirmingAnswers: false,
      loading: false,
      savingAnswers: false,
      testingForm: null,
      currentQuestion: null,
      formId: null,
      previousLink: null,
      valid: false,
      timesPassed: 0,
      errorText: null,

      discipline: null,
      disciplines: [],
      disciplinesLoading: false,

      formIsPassed: false,

      wrongTeacherDialog: false,
    }
  },
  methods: {
    wrongTeacherInTeacherSatisfaction() {
      this.savingAnswers = true
      let teacherSatisfactionAttempt = {}
      let attempt = {}
      attempt.student = this.$store.getters.selectedStudent
      attempt.testingForm = this.testingForm

      teacherSatisfactionAttempt.testingFormAttempt = attempt
      teacherSatisfactionAttempt.discipline = this.discipline.discipline
      teacherSatisfactionAttempt.teacher = this.discipline.teacher
      teacherSatisfactionAttempt.isSkipped = true
      teacherSatisfactionAttempt.student = this.$store.getters.selectedStudent

      console.log(teacherSatisfactionAttempt)

      HTTP.post(Settings.API.URL_DEFAULT + "/testing-form/teacher-satisfaction/save-attempt", teacherSatisfactionAttempt)
          .then(async () => {
            // teacherSatisfactionAttempt = response.data
            this.confirmingAnswers = false
            this.discipline = null
            this.$refs.question.forEach(question => {
              question.resetAnswers()
            })
            await this.getDisciplines()
            this.formIsPassed = true
          })
          .catch(() => {
            // this.errored = true;
          })
          .finally(() => {
            this.savingAnswers = false
            this.wrongTeacherDialog = false
          });
    },
    openWrongTeacherDialog() {
      this.wrongTeacherDialog = true
    },
    disciplineToTeacherName(item) {
      return item.teacher.fio + " (" + item.discipline.name + ")"
    },
    isDisciplineSelectedOrNotTeacherSatisfactionForm() {
      if(!this.isTeacherSatisfactionForm || this.discipline !== null)
        return true;
      return false;
    },
    getTimesPassed() {
      HTTP.get(Settings.API.URL_DEFAULT + "/testing-form/" + this.testingForm.id
          + "/times-passed?studentId=" + this.$store.getters.selectedStudent.id)
          .then((response) => {
            this.timesPassed = response.data
          });
    },
    async getDisciplines() {
      if(this.isTeacherSatisfactionForm && this.timesPassedWithinAllowedAttempts) {
        this.disciplinesLoading = true
        await HTTP.get(Settings.API.URL_DEFAULT + "/testing-form/teacher-satisfaction/disciplines-not-passed/this-semester" +
            "?studentId=" + this.$store.getters.selectedStudent.id)
            .then((response) => {
              console.log(response.data)
              this.disciplines = response.data

              // console.log(this.visits)
            })
            .catch(() => {
            })
            .finally(() => {
              this.disciplinesLoading = false
            });
      }
    },
    async saveAnswersForTeacherSatisfaction() {
      this.savingAnswers = true
      let teacherSatisfactionAttempt = {}
      let attempt = {}
      let studentAnswers = []
      this.$refs.question.forEach(question => {
        studentAnswers = studentAnswers.concat(question.getStudentAnswers())
      })
      attempt.studentAnswerList = studentAnswers
      attempt.student = this.$store.getters.selectedStudent
      attempt.testingForm = this.testingForm

      teacherSatisfactionAttempt.testingFormAttempt = attempt
      teacherSatisfactionAttempt.isSkipped = false
      teacherSatisfactionAttempt.discipline = this.discipline.discipline
      teacherSatisfactionAttempt.teacher = this.discipline.teacher
      teacherSatisfactionAttempt.student = this.$store.getters.selectedStudent

      console.log(teacherSatisfactionAttempt)

      HTTP.post(Settings.API.URL_DEFAULT + "/testing-form/teacher-satisfaction/save-attempt", teacherSatisfactionAttempt)
          .then(async () => {
            // teacherSatisfactionAttempt = response.data
            this.confirmingAnswers = false
            this.discipline = null
            this.$refs.question.forEach(question => {
              question.resetAnswers()
            })
            await this.getDisciplines()
            this.formIsPassed = true
          })
          .catch(() => {
            // this.errored = true;
          })
          .finally(() => (this.savingAnswers = false));
    },
    saveAnswersForNormalForm() {
      this.savingAnswers = true
      let attempt = {}
      let studentAnswers = [];
      this.$refs.question.forEach(question => studentAnswers.push(question.studentAnswer))
      attempt.studentAnswerList = studentAnswers
      attempt.student = this.$store.getters.selectedStudent
      attempt.testingForm = this.testingForm

      HTTP.post(Settings.API.URL_DEFAULT + "/testing-form/save-attempt", attempt)
          .then((response) => {
            attempt = response.data
            this.confirmingAnswers = false
            this.formIsPassed = true
          })
          .catch(() => {
            // this.errored = true;
          })
          .finally(() => (this.savingAnswers = false));
    },
    saveAnswers() {
      if(this.isTeacherSatisfactionForm()) {
        this.saveAnswersForTeacherSatisfaction()
        return
      }

      this.saveAnswersForNormalForm()
    },
    async openSavingDialogIfValid() {
      await this.$refs.form.validate()
      if(this.valid)
        this.confirmingAnswers = true
      else {
        const target = document.querySelector('.error--text')
        console.log(target)
        this.$vuetify.goTo(target.parentNode.parentNode.parentNode.parentNode)
      }
    },
    validate() {
      this.$refs.form.validate()
    },
    resetValidation() {
      this.$refs.form.resetValidation()
    },
    async setFormId(num) {
      this.formId = Number(num)
    },
    getTestingForm() {
      this.loading = true
      HTTP.get(Settings.API.URL_DEFAULT + "/testing-form?id=" + this.formId)
          .then((response) => {
            this.testingForm = response.data
            this.currentQuestion = this.testingForm.questionList[0]
          })
          .catch((e) => {
            this.errorText = e.response.data
          })
          .finally(() => (this.loading = false));
    },
    goBackToPreviousLink() {
      if(this.previousLink === undefined || this.previousLink === null) {
        return {name: "ScheduleAll"}
      }
      return {path: this.previousLink}
    },
    getAmountOfTimesTestWasPassed() {
      HTTP.get(Settings.API.URL_DEFAULT + "/testing-form?id=" + this.formId)
          .then((response) => {
            this.testingForm = response.data
            this.currentQuestion = this.testingForm.questionList[0]
          })
          .catch(() => {
            // this.errored = true;
          })
          .finally(() => (this.loading = false));
    },
    isAllowedToPassTheForm() {
      if(this.testingForm === null) {
        this.errorText = "404. Тест не найден"
        return false;
      } else if(this.testingForm.groupList.length > 0
          && this.testingForm.groupList.every(g => g.id !== this.$store.getters.selectedStudent.groups.id)) {
        this.errorText = "Тест недоступен"
        return false;
      } else if(!this.formIsPassed && this.isTeacherSatisfactionForm() && !this.disciplinesLoading && this.disciplines.length == 0) {
        this.errorText = "Вы уже прошли анкетирование по всем преподавателям"
        return false
      }

      return true
    },
    isTeacherSatisfactionForm() {
      if(this.testingForm === null)
        return false
      else if(this.testingForm.type.startsWith('TEACHER_SATISFACTION'))
        return true

      return false
    },
    timesPassedWithinAllowedAttempts() {
      if(this.timesPassed < this.testingForm.allowedAttempts)
        return true

      return false
    },
  },
  watch: {
    '$store.getters.selectedStudent'() {
      if(this.testingForm != null) {
        this.getTimesPassed()
        this.getDisciplines()
      }
    },
    'testingForm'() {
      if(this.$store.getters.selectedStudent != null) {
        this.getTimesPassed()
        this.getDisciplines()
      }
    }
  },
  async created() {
    await this.setFormId(this.$route.query.id)
    this.previousLink = this.$route.query.from
    await this.getTestingForm()
    if(this.isTeacherSatisfactionForm())
      this.getDisciplines()
    else
      this.getTimesPassed()
  },
}
</script>

<style scoped>

</style>