import Vue from 'vue'
import Vuex from 'vuex'
import data_get_users from "@/store/modules/data_get_users";
import settings_themes from "@/store/modules/settings_themes";
import group_card from "@/store/modules/groupcard";
import schedule_edit from "@/store/modules/schedule_edit";
import users from "@/store/modules/users";
import lesson_time from "@/store/modules/lesson_time";
import comprehensive_testing from "@/store/modules/comprehensive_testing";
import VueLocalStorage from 'vue-localstorage'

Vue.use(Vuex)
Vue.use(VueLocalStorage)

export default new Vuex.Store({
  modules: {
    settings_themes,
    data_get_users,
    group_card,
    schedule_edit,
    users,
    lesson_time,
    comprehensive_testing,
  }
})
