var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"width":"800px"},model:{value:(_vm.commentDialog),callback:function ($$v) {_vm.commentDialog=$$v},expression:"commentDialog"}},[_c('v-card',[_c('v-toolbar',{attrs:{"dark":"","color":"primary"}},[_c('v-card-title',[_vm._v("История изменений")]),_c('v-spacer'),_c('v-btn',{attrs:{"icon":"","dark":""},on:{"click":function($event){_vm.commentDialog = false}}},[_c('v-icon',[_vm._v("close")])],1)],1),(_vm.visitItem != null && _vm.visitItem.journalVisitComments != null)?_c('v-card-text',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.visitItem.journalVisitComments,"items-per-page":10,"item-key":"id","loading":_vm.loading,"loading-text":"Загрузка...","no-data-text":"История пуста!","footer-props":{
            itemsPerPageAllText: 'Все',
            itemsPerPageText: 'Изменений на страницу',
          }},scopedSlots:_vm._u([{key:"item.date",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getDatetime(item.date))+" ")]}},{key:"item.change",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getValComment(item.preVal, item.newVal))+" ")]}},{key:"item.user.username",fn:function(ref){
          var item = ref.item;
return [(item.user.username == 'Systems')?_c('span',[_vm._v("Система")]):_vm._e(),(item.user.username != 'Systems')?_c('span',[_vm._v(_vm._s(item.user.username))]):_vm._e()]}}],null,false,427316183)})],1):_vm._e(),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":function($event){_vm.commentDialog = !_vm.commentDialog}}},[_vm._v(" Закрыть ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }