<template>
  <div>
    <h3 style="display: inline-block">Карта успеваемости:</h3> {{ group.name }} - {{ discipline.name }}

    <div class="block-card">
      <div class="block-card__header">Посещаемость - {{ maxVisitPoints }} баллов</div>
      <div class="block-card__body">
        <!--        <div>Лекции - 0 баллов</div>-->
        <div>Семинары / Практики / Лабораторные - {{ maxVisitPoints }} баллов</div>
      </div>
    </div>
    <div class="block-card">
      <div class="block-card__header">
        <div style="margin-top: 5px">Учебная активность - распределено баллов {{ this.sumPointLearningActivity }} из
          {{ maxSumPointLearningActivity }}
        </div>
        <div>
          <v-btn
              color="primary"
              icon
              @click="showAddDialog()"
          >
            <v-icon>add</v-icon>
          </v-btn>
        </div>
      </div>
      <div class="block-card__body">
        <v-data-table
            :headers="learningActivityHeaders"
            :items="learningActivityList"
            :items-per-page="15"
            :hide-default-footer="true"
            class="elevation-2"
            item-key="code"
        >
          <template v-slot:item.linked="{ item }">
            <v-btn @click="showUpdateDialog(item)" icon>
              <v-icon>settings</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </div>
    </div>

    <div class="block-card">
      <div class="block-card__header">
        <div style="margin-top: 5px">Точки академической активности (ТАА) - распределено баллов {{ sumPointTAA }} из
          {{ maxTAAPoint }} ( допустимое
          количество {{ minTAAAmount }}-{{ maxTAAAmount }} )
        </div>
        <div>
          <v-btn
              color="primary"
              icon
              @click="showAddTAADialog()"
          >
            <v-icon>add</v-icon>
          </v-btn>
        </div>
      </div>
      <div class="block-card__body">
        <v-data-table
            :headers="TAAHeaders"
            :items="TAAList"
            :items-per-page="15"
            :hide-default-footer="true"
            class="elevation-2"
            item-key="code"
        >
          <template v-slot:item.linked="{ item }">
            <v-btn @click="showUpdateTAADialog(item)" icon>
              <v-icon>settings</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </div>
    </div>

    <div class="block-card">
      <div class="block-card__header">
        <div style="margin-top: 5px">Точки текущего контроля (ТТК) - распределено баллов {{ sumPointTTK }} из
          {{ maxTTKPoint }} ( допустимое
          количество {{ minTTKAmount }}-{{ maxTTKAmount }} )
        </div>
        <div>
          <v-btn
              color="primary"
              icon
              @click="showAddTTKDialog()"
          >
            <v-icon>add</v-icon>
          </v-btn>
        </div>
      </div>
      <div class="block-card__body">
        <v-data-table
            :headers="TAAHeaders"
            :items="TTKList"
            :items-per-page="15"
            :hide-default-footer="true"
            class="elevation-2"
            item-key="code"
        >
          <template v-slot:item.linked="{ item }">
            <v-btn @click="showUpdateTTKDialog(item)" icon>
              <v-icon>settings</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </div>
    </div>

    <div class="block-card">
      <div class="block-card__header">Промежуточный контроль - 20 баллов</div>
      <div class="block-card__body">
        <div>Экзамен / Зачет - {{ maxExamPoints }} баллов</div>
      </div>
    </div>

    <div class="block-card">
      <div class="block-card__header">Итого - {{ sumAllPoints }} баллов</div>
    </div>

    <v-row justify="center">
      <v-dialog
          v-model="showAdd"
          persistent
          max-width="600px"
      >
        <v-card>
          <v-card-title>
            <span class="headline">Добавить учебную активность</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col
                    cols="12"
                    sm="12"
                    md="12"
                >
                  <v-form v-model="validLearnActivityForm">
                    <v-text-field
                        label="Название*"
                        required
                        :rules="rulesTextField"
                        v-model="learningActivity.name"
                    ></v-text-field>
                    <v-text-field
                        label="Количество баллов*"
                        required
                        :rules="rulesMaxPintsLearningActivity"
                        v-model="learningActivity.points"
                    ></v-text-field>
                  </v-form>

                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-btn
                color="red darken-1"
                text
                @click="showAdd = false"
            >
              Выход
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn v-if="sumPointLearningActivity != null && validLearnActivityForm"
                   color="blue darken-1"
                   text
                   @click="saveLearningActivity()"
            >
              Сохранить
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>

    <v-row justify="center">
      <v-dialog
          v-model="showUpdate"
          persistent
          max-width="600px"
      >
        <v-card>
          <v-card-title>
            <span class="headline">Редактирование учебной активности</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col
                    cols="12"
                    sm="12"
                    md="12"
                >
                  <v-form v-model="validUpdateLearnActivityForm">
                    <v-text-field
                        label="Название*"
                        required
                        :rules="rulesTextField"
                        v-model="learningActivity.name"
                    ></v-text-field>
                    <v-text-field
                        label="Количество баллов*"
                        required
                        :rules="rulesUpdateMaxPintsLearningActivity"
                        v-model="learningActivity.points"
                    ></v-text-field>
                  </v-form>

                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-btn
                color="darken-1"
                text
                @click="showUpdate = false"
            >
              Выход
            </v-btn>

            <v-spacer></v-spacer>
            <v-btn
                color="red darken-1"
                text
                @click="deleteLearningActivity()"
            >
              удалить
            </v-btn>
            <v-btn v-if="sumPointLearningActivity != null && validUpdateLearnActivityForm"
                   color="blue darken-1"
                   text
                   @click="updateLearningActivity()"
            >
              Сохранить
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>


    <v-row justify="center">
      <v-dialog
          v-model="showAddTAA"
          persistent
          max-width="800px"
      >
        <v-card v-show="!showAddTAAForm">
          <lms-control-points @cp-selected="showAddTAAFormAndSelectCP"/>
          <v-card-actions>
            <v-btn
                color="red darken-1"
                text
                @click="closeShowAddTAA"
            >
              Выход
            </v-btn>
          </v-card-actions>
        </v-card>
        <v-card v-show="showAddTAAForm">
          <v-card-title>
            <span class="headline">Добавить ТАА</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col
                    cols="12"
                    sm="12"
                    md="12"
                >
                  <v-form v-model="validTAAForm">
                    <v-btn text @click="showAddTAAForm = !showAddTAAForm">
                      <v-icon>arrow_back</v-icon>
                      Назад
                    </v-btn>
                    <!--                    <v-autocomplete v-model="TAA"-->
                    <!--                                    :items="controlPointsLms"-->
                    <!--                                    label="TAA"-->
                    <!--                                    dense-->
                    <!--                                    item-text="name"-->
                    <!--                                    return-object-->
                    <!--                    ></v-autocomplete>-->
                    <v-text-field
                        label="Название*"
                        required
                        :rules="rulesTextField"
                        :readonly="true"
                        v-model="TAA.name"
                    ></v-text-field>
                    <v-text-field
                        label="Количество баллов*"
                        required
                        readonly
                        :rules="rulesMaxPintsTAA"
                        v-model="TAA.grade"
                    ></v-text-field>
                  </v-form>

                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-btn
                color="red darken-1"
                text
                @click="closeShowAddTAA()"
            >
              Выход
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn v-if="sumPointTAA != null && validTAAForm"
                   color="blue darken-1"
                   text
                   @click="addTAA()"
            >
              Сохранить
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>

    <v-row justify="center">
      <v-dialog
          v-model="showUpdateTAA"
          persistent
          max-width="600px"
      >
        <v-card>
          <v-card-title>
            <span class="headline">Удалить ТАА</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col
                    cols="12"
                    sm="12"
                    md="12"
                >
                  <v-form>
                    <!--                    <v-autocomplete v-model="TAA"-->
                    <!--                                    :items="controlPointsLms"-->
                    <!--                                    label="TAA"-->
                    <!--                                    dense-->
                    <!--                                    item-value="id"-->
                    <!--                                    item-text="name"-->
                    <!--                                    return-object-->
                    <!--                    ></v-autocomplete>-->
                    <v-text-field
                        label="Название*"
                        required
                        :rules="rulesTextField"
                        :readonly="true"
                        v-model="TAA.name"
                    ></v-text-field>
                    <v-text-field
                        label="Количество баллов*"
                        required
                        :rules="rulesMaxPintsTAA"
                        v-model="TAA.numberOfPoints"
                    ></v-text-field>
                  </v-form>

                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-btn
                color="green darken-1"
                text
                @click="showUpdateTAA = false"
            >
              Выход
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn
                color="red darken-1"
                text
                @click="deleteTAA()"
            >
              Удалить
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>

    <v-row justify="center">
      <v-dialog
          v-model="showAddTTK"
          persistent
          max-width="600px"
      >
        <v-card>
          <v-card-title>
            <span class="headline">Добавить ТTK</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col
                    cols="12"
                    sm="12"
                    md="12"
                >
                  <v-form v-model="validTTKForm">
                    <v-autocomplete v-model="TTK"
                                    :items="controlPointsLms"
                                    label="TAA"
                                    dense
                                    item-text="name"
                                    return-object
                    ></v-autocomplete>
                    <!--                    <v-text-field-->
                    <!--                        label="Название*"-->
                    <!--                        required-->
                    <!--                        :rules="rulesTextField"-->
                    <!--                        :readonly="true"-->
                    <!--                        v-model="TAA.name"-->
                    <!--                    ></v-text-field>-->
                    <v-text-field
                        label="Количество баллов*"
                        required
                        :rules="rulesMaxPintsTTK"
                        v-model="TTK.numberOfPoints"
                    ></v-text-field>
                  </v-form>

                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-btn
                color="red darken-1"
                text
                @click="showAddTTK = false"
            >
              Выход
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn v-if="sumPointTTK != null && validTTKForm"
                   color="blue darken-1"
                   text
                   @click="addTTK()"
            >
              Сохранить
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>

    <v-row justify="center">
      <v-dialog
          v-model="showUpdateTTK"
          persistent
          max-width="600px"
      >
        <v-card>
          <v-card-title>
            <span class="headline">Удалить ТTK</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col
                    cols="12"
                    sm="12"
                    md="12"
                >
                  <v-form v-model="validTTKForm">
                    <!--                    <v-autocomplete v-model="TAA"-->
                    <!--                                    :items="controlPointsLms"-->
                    <!--                                    label="TAA"-->
                    <!--                                    dense-->
                    <!--                                    item-value="id"-->
                    <!--                                    item-text="name"-->
                    <!--                                    return-object-->
                    <!--                    ></v-autocomplete>-->
                    <v-text-field
                        label="Название*"
                        required
                        :rules="rulesTextField"
                        :readonly="true"
                        v-model="TTK.name"
                    ></v-text-field>
                    <v-text-field
                        label="Количество баллов*"
                        required
                        :rules="rulesMaxPintsTTK"
                        v-model="TTK.numberOfPoints"
                    ></v-text-field>
                  </v-form>

                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-btn
                color="green darken-1"
                text
                @click="showUpdateTTK = false"
            >
              Выход
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn
                color="red darken-1"
                text
                @click="deleteTTK()"
            >
              Удалить
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>

  </div>
</template>

<script>
import Settings from "@/settings/settings_url";
import {HTTP} from '@/settings/axios'
import LmsControlPoints from "@/components/lmsViews/LmsControlPoints";

export default {
  name: "LearningActivityEdit",
  components: {LmsControlPoints},
  data() {
    return {
      sumAllPoints: 0,
      maxVisitPoints: 0,
      maxExamPoints: 0,
      minTAAAmount: 0,
      maxTAAAmount: 0,
      minTTKAmount: 0,
      maxTTKAmount: 0,
      validLearnActivityForm: false,
      validTAAForm: false,
      validTTKForm: false,
      validUpdateLearnActivityForm: false,
      learningActivityHeaders: [
        {text: 'id', align: 'center', sortable: true, value: 'id', divider: true, class: "bolt"},
        {text: 'Наименование', align: 'center', value: 'name', divider: true, sortable: true, class: "bolt"},
        {text: 'Балл', align: 'center', value: 'points', divider: true, sortable: true, class: "bolt"},
        {text: '', value: 'linked', divider: true, align: 'center'}
      ],
      TAAHeaders: [
        {text: 'id', align: 'center', sortable: true, value: 'id', divider: true, class: "bolt"},
        {text: 'Наименование', align: 'center', value: 'name', divider: true, sortable: true, class: "bolt"},
        {text: 'Балл', align: 'center', value: 'numberOfPoints', divider: true, sortable: true, class: "bolt"},
        {text: '', value: 'linked', divider: true, align: 'center'}
      ],
      disciplineId: null,
      groupId: null,
      yearId: null,
      halfYearId: null,
      rulesTextField: [
        value => !!value || 'обязательное поле.',
      ],
      rulesMaxPintsLearningActivity: [
        value => (value * 1 + this.sumPointLearningActivity * 1) <= this.maxSumPointLearningActivity || 'максимальное возможное колличество баллов - ' + (this.maxSumPointLearningActivity * 1 - this.sumPointLearningActivity * 1),
      ],
      rulesMaxPintsTAA: [
        value => (this.cpType !== 'TAA' ) || (value * 1 + this.sumPointTAA * 1) <= this.maxTAAPoint || 'максимальное возможное колличество баллов - ' + (this.maxTAAPoint * 1 - this.sumPointTAA * 1),
        value => (this.cpType !== 'TTK' ) || (value * 1 + this.sumPointTTK * 1) <= this.maxTTKPoint || 'максимальное возможное колличество баллов - ' + (this.maxTTKPoint * 1 - this.sumPointTTK * 1),
      ],
      rulesMaxPintsTTK: [
        value => (value * 1 + this.sumPointTTK * 1) <= this.maxTTKPoint || 'максимальное возможное колличество баллов - ' + (this.maxTTKPoint * 1 - this.sumPointTTK * 1),
      ],
      rulesUpdateMaxPintsLearningActivity: [
        value => (value * 1 + (this.sumPointLearningActivity * 1 - this.updateSumPointLearningActivity * 1)) <= this.maxSumPointLearningActivity || 'максимальное возможное колличество баллов - ' + (this.maxSumPointLearningActivity * 1 - (this.sumPointLearningActivity * 1 - this.updateSumPointLearningActivity * 1)),
      ],
      showAdd: false,
      showAddTAA: false,
      showAddTTK: false,
      showUpdate: false,
      showUpdateTAA: false,
      showUpdateTTK: false,
      group: {
        name: ""
      },
      discipline: {
        name: ""
      },
      learningActivityList: [],
      TAAList: [],
      TTKList: [],
      sumPointLearningActivity: null,
      sumPointTAA: null,
      sumPointTTK: null,
      maxSumPointLearningActivity: null,
      maxTAAPoint: null,
      maxTAAPointDefault: null,
      maxTTKPoint: null,
      maxTTKPointDefault: null,
      updateSumPointLearningActivity: null,
      controlPointsLms: [],
      absTAA: {
        id: "",
        num: null,
        name: null,
        dateControl: null,
        lmsId: null,
        disciplines: {},
        categories: null,
        numberOfPoints: null,
        controlPointType: null,
        controlPointSystemType: null,
      },
      TAA: {
        id: "",
        num: null,
        name: null,
        dateControl: null,
        lmsId: null,
        disciplines: {},
        categories: null,
        numberOfPoints: null,
        controlPointType: null,
        controlPointSystemType: null,
      },
      TTK: {
        id: "",
        num: null,
        name: null,
        dateControl: null,
        lmsId: null,
        disciplines: {},
        categories: null,
        numberOfPoints: null,
        controlPointType: null,
        controlPointSystemType: null,
      },
      absLearningActivity: {
        id: null,
        status: null,
        name: null,
        points: null,
        discipline: null,
        group: null,
        year: null,
        halfYear: null
      },
      learningActivity: {
        id: null,
        status: null,
        name: null,
        points: null,
        discipline: null,
        group: null,
        year: null,
        halfYear: null
      },
      showAddTAAForm: false,
      cpType: "",
    }
  },
  methods: {
    closeShowAddTAA() {
      this.showAddTAA = false
      this.showAddTAAForm = false
    },
    showAddTAAFormAndSelectCP(cp) {
      this.showAddTAAForm = true
      this.TAA = cp
      console.log(this.TAA)
    },
    deleteTAA() {
      this.loading = true
      this.showUpdateTAA = false
      HTTP.delete(Settings.API.URL_DEFAULT + "/control-points/" + this.TAA.id).then((response) => {
        console.log(response.data)
      }).catch(() => {
      }).finally(async () => {
        this.loading = false
        await this.getTAA()
        this.calcAllPoints()
      });
    },
    deleteTTK() {
      this.loading = true
      this.showUpdateTTK = false
      HTTP.delete(Settings.API.URL_DEFAULT + "/control-points/" + this.TTK.id).then((response) => {
        console.log(response.data)
      }).catch(() => {
      }).finally(async () => {
        this.loading = false
        await this.getTTK()
        this.calcAllPoints()
      });
    },
    addTAA() {
      // console.log(this.TAA)
      // this.TAA.controlPointSystemType = "TAA"
      this.showAddTAA = false;
      HTTP.post(Settings.API.URL_DEFAULT + "/control-points", {
            numberOfPoints: this.TAA.grade,
            name: this.TAA.name,
            lmsId: this.TAA.id,
            disciplines: this.discipline,
            group: this.group,
            controlPointType: this.TAA.controlPointType,
            controlPointSystemType: this.cpType,
            year: this.yearId,
            halfYear: this.halfYearId,
          }
      ).then((response) => {
        console.log(response.data)
        this.showAddTAA = false;
      }).catch(() => {
      }).finally(async () => {
        this.loading = false
        await this.getTAA()
        await this.getTTK()
        this.calcAllPoints()
      });
    },
    addTTK() {
      this.showAddTTK = false
      this.loading = true
      this.TTK.controlPointSystemType = "TTK"
      HTTP.post(Settings.API.URL_DEFAULT + "/control-points", this.TTK
      ).then((response) => {
        console.log(response.data)
      }).catch(() => {
      }).finally(async () => {
        this.loading = false
        await this.getTTK()
        this.calcAllPoints()
      });
    },
    async getTAA() {
      await HTTP.get(Settings.API.URL_DEFAULT + "/control-points/cp-by-system-type?groupId=" + this.groupId + "&disciplineId=" + this.disciplineId
          + "&yearId=" + this.yearId + "&halfYearId=" + this.halfYearId + "&controlPointSystemType=TAA"
      ).then((response) => {
        console.log(response.data)
        this.TAAList = response.data
        this.calcAllPoints()
      }).catch(() => {
      }).finally(() => {
        this.loading = false
      });
    },
    async getTTK() {
      await HTTP.get(Settings.API.URL_DEFAULT + "/control-points/cp-by-system-type?groupId=" + this.groupId + "&disciplineId=" + this.disciplineId
          + "&yearId=" + this.yearId + "&halfYearId=" + this.halfYearId + "&controlPointSystemType=TTK"
      ).then((response) => {
        console.log(response.data)
        this.TTKList = response.data
        this.calcAllPoints()
      }).catch(() => {
      }).finally(() => {
        this.loading = false
      });
    },
    // getAllLmsControlPoints() {
    //   this.loading = true
    //   HTTP.get(Settings.API.URL_DEFAULT + "/control-points/all-lms?groupId=" + this.groupId + "&disciplineId=" + this.disciplineId
    //       + "&yearId=" + this.yearId + "&halfYearId=" + this.halfYearId
    //   ).then((response) => {
    //     console.log(response.data)
    //     this.controlPointsLms = response.data
    //   }).catch(() => {
    //   }).finally(() => {
    //     this.loading = false
    //   });
    // },
    async deleteLearningActivity() {
      await HTTP.delete(Settings.API.URL_DEFAULT + "/learning-activity/" + this.learningActivity.id)
          .then(async () => {
            this.showUpdate = false
            await this.getLearningActivity(
                this.disciplineId,
                this.groupId,
                this.yearId,
                this.halfYearId
            )
            await this.getSumLearningActivity(
                this.disciplineId,
                this.groupId,
                this.yearId,
                this.halfYearId
            )
            this.calcAllPoints()
          })
          .catch(() => {

          })
    },
    showAddTAADialog() {
      this.showAddTAA = true
      this.cpType = 'TAA';
      this.TAA = {...this.absTAA}
      // this.getAllLmsControlPoints();
    },

    showAddTTKDialog() {
      this.showAddTAA = true
      this.cpType = 'TTK';
      this.TAA = {...this.absTAA}
      // this.showAddTTK = true
      // this.TTK = {...this.absTAA}
      // this.getAllLmsControlPoints();
    },
    showUpdateTAADialog(item) {
      this.showUpdateTAA = true
      this.TAA = {...item}
      // this.getAllLmsControlPoints();
    },
    showUpdateTTKDialog(item) {
      this.showUpdateTTK = true
      this.TTK = {...item}
      // this.getAllLmsControlPoints();
    },
    showAddDialog() {
      this.showAdd = true
      this.learningActivity = {...this.absLearningActivity}
      this.learningActivity.discipline = this.disciplineId
      this.learningActivity.group = this.groupId
      this.learningActivity.year = this.yearId
      this.learningActivity.halfYear = this.halfYearId
      this.learningActivity.status = "ACTIVE"
      this.getSumLearningActivity(
          this.disciplineId,
          this.groupId,
          this.yearId,
          this.halfYearId
      )
    },
    showUpdateDialog(item) {
      this.showUpdate = true
      this.learningActivity = {...item}
      this.updateSumPointLearningActivity = item.points
      this.getSumLearningActivity(
          this.disciplineId,
          this.groupId,
          this.yearId,
          this.halfYearId
      )
    },
    async saveLearningActivity() {
      await HTTP.post(Settings.API.URL_DEFAULT + "/learning-activity",
          this.learningActivity
      )
          .then(async () => {
            this.showAdd = false
            await this.getLearningActivity(
                this.disciplineId,
                this.groupId,
                this.yearId,
                this.halfYearId
            )
            await this.getSumLearningActivity(
                this.disciplineId,
                this.groupId,
                this.yearId,
                this.halfYearId
            )
            this.calcAllPoints()
          })
          .catch(() => {

          })
    },
    async updateLearningActivity() {
      await HTTP.put(Settings.API.URL_DEFAULT + "/learning-activity/" + this.learningActivity.id,
          this.learningActivity
      )
          .then(async () => {
            this.showUpdate = false
            await this.getLearningActivity(
                this.disciplineId,
                this.groupId,
                this.yearId,
                this.halfYearId
            )
            await this.getSumLearningActivity(
                this.disciplineId,
                this.groupId,
                this.yearId,
                this.halfYearId
            )
            this.calcAllPoints()
          })
          .catch(() => {

          })
    },
    getGroup: async function (id) {
      await HTTP.get(Settings.API.URL_DEFAULT + "/groups/" + id)
          .then((response) => {
            this.group = response.data
          })
          .catch(() => {

          })
    },
    getDiscipline: async function (id) {
      await HTTP.get(Settings.API.URL_DEFAULT + "/disciplines/" + id)
          .then((response) => {
            this.discipline = response.data
          })
          .catch(() => {

          })
    },
    getLearningActivity: async function (discipline, group, year, halfYear) {
      await HTTP.get(Settings.API.URL_DEFAULT + "/learning-activity/for-lesson?discipline="
          + discipline + "&group=" + group + "&year=" + year + "&halfYear=" + halfYear
      )
          .then((response) => {
            this.learningActivityList = response.data
            this.calcAllPoints()
          })
          .catch(() => {

          })
    },
    getSumLearningActivity: async function (discipline, group, year, halfYear) {
      await HTTP.get(Settings.API.URL_DEFAULT + "/learning-activity/sum-point-for-lesson?discipline="
          + discipline + "&group=" + group + "&year=" + year + "&halfYear=" + halfYear
      )
          .then((response) => {
            this.sumPointLearningActivity = response.data
            if (this.sumPointLearningActivity === '') {
              this.sumPointLearningActivity = 0
            }
          })
          .catch(() => {

          })
    },
    async calcMaxSumTTKPoints() {
      let sum = 0;
      this.TTKList.forEach(item => {
        sum += item.numberOfPoints
        console.log(item.numberOfPoints)
      })
      this.sumPointTTK = sum
      // if (this.group.typesEducation === "BACCALAUREATE" ) {
      //   this.maxTTKPoint = 22;
      // } else if(this.group.typesEducation === "MAGISTRACY") {
      //   this.maxTTKPoint = 60;
      // }else{
      //   this.maxTTKPoint = 0;
      // }
    },
    async calcMaxSumTAAPoints() {
      let sum = 0;
      this.TAAList.forEach(item => {
        sum += item.numberOfPoints
        console.log(item.numberOfPoints)
      })
      this.sumPointTAA = sum
      console.log(sum)
      // if (this.group.typesEducation === "BACCALAUREATE" || this.group.typesEducation === "MAGISTRACY") {
      //   this.maxTAAPoint = 40;
      // } else {
      //   this.maxTAAPoint = 43;
      // }

      if (this.sumPointLearningActivity > 0) {
        this.maxTAAPoint = this.maxTAAPointDefault - this.sumPointLearningActivity;
      }

      if (this.maxSumPointLearningActivity > (this.maxTAAPointDefault - this.sumPointTAA)) {
        if ((this.maxTAAPointDefault - this.sumPointTAA) > 0) {
          this.maxSumPointLearningActivity = (this.maxTAAPointDefault - this.sumPointTAA)
        } else {
          this.maxSumPointLearningActivity = 0
        }
      }
    },
    // async calcMaxSumPoints() {
    //   if (this.group.typesEducation === "BACCALAUREATE" || this.group.typesEducation === "MAGISTRACY") {
    //     this.maxSumPointLearningActivity = 18;
    //   } else {
    //     this.maxSumPointLearningActivity = 0;
    //   }
    // },
    async calcAllPoints() {
      await this.getPintRating(this.group.id, this.yearId)
      await this.calcMaxSumTAAPoints()
      await this.calcMaxSumTTKPoints()
      this.sumAllPoints = this.maxVisitPoints + this.sumPointTAA + this.sumPointTTK + this.sumPointLearningActivity + 20
    },
    async getPintRating(group, year) {
      await HTTP.get(Settings.API.URL_DEFAULT + "/groups/" + group + "/point-rating?year=" + year)
          .then((response) => {

            for (let i = 0; i < response.data.length; i++) {
              if (response.data[i].pointRatingType.type === 'VISIT') {
                this.maxVisitPoints = response.data[i].value;
              }
              if (response.data[i].pointRatingType.type === 'EXAM_GRADE') {
                this.maxExamPoints = response.data[i].value;
              }
              if (response.data[i].pointRatingType.type === 'TAA') {
                if (this.discipline.name.toLowerCase().includes("иностранный")){
                  this.maxTAAPoint = 100
                  this.maxTAAPointDefault = 100
                }else {
                  this.maxTAAPoint = response.data[i].value;
                  this.maxTAAPointDefault = response.data[i].value;
                }

                this.minTAAAmount = response.data[i].minAmount;
                this.maxTAAAmount = response.data[i].maxAmount;
              }
              if (response.data[i].pointRatingType.type === 'TTK') {
                if (this.discipline.name.toLowerCase().includes("иностранный")){
                  this.maxTTKPoint = 100 // response.data[i].value;
                  this.maxTTKPointDefault = 100 // response.data[i].value;
                }else {
                  this.maxTTKPoint =  response.data[i].value;
                  this.maxTTKPointDefault = response.data[i].value;
                }

                this.minTTKAmount = response.data[i].minAmount;
                this.maxTTKAmount = response.data[i].maxAmount;
              }
              if (response.data[i].pointRatingType.type === 'GRADE') {
                this.maxSumPointLearningActivity = response.data[i].value;
              }
            }

            console.log(response.data)
          })
          .catch(() => {

          })
    }
  },
  async mounted() {
    this.disciplineId = this.$route.query.discipline
    this.groupId = this.$route.query.group
    this.yearId = this.$route.query.year
    this.halfYearId = this.$route.query.halfYear

    await this.getGroup(this.$route.query.group);
    await this.getDiscipline(this.$route.query.discipline)
    await this.getPintRating(this.groupId, this.yearId);
    await this.getLearningActivity(
        this.disciplineId,
        this.groupId,
        this.yearId,
        this.halfYearId,
    );
    await this.getSumLearningActivity(
        this.disciplineId,
        this.groupId,
        this.yearId,
        this.halfYearId,
    )
    await this.getTAA();
    await this.getTTK();

    this.calcAllPoints()
  }
}
</script>

<style scoped>
.block-card {
  display: flex;
  flex-direction: column;
  margin: 20px 20px 20px 20px;
}

.block-card__header {
  display: flex;
  padding: 10px 10px 10px 10px;
  background-color: #d3d3d3;
}

.block-card__body {
  margin: 20px 0 0 20px;
}
</style>