<template>
  <v-row justify="center">
    <v-dialog
        v-model="show"
        persistent
        max-width="600px"
    >
      <v-card>
        <v-card-title>
          <span class="headline">Редактировать</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col
                  cols="12"
                  sm="12"
                  md="12"
              >
                <div>
                  Группа - {{ schedule.name }}
                </div>
                <div>
                  <v-text-field
                      v-model="schedule.indexNumber"
                      label="Порядковый номер"
                      :rules="rules"
                      hide-details="auto"
                  ></v-text-field>
                </div>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="blue darken-1"
              text
              @click="closeDialog()"
          >
            Close
          </v-btn>
          <v-btn
              color="blue darken-1"
              text
              @click="saveSchedule"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import Settings from "@/settings/settings_url";
import {HTTP} from '@/settings/axios'

export default {
  name: "IndexScheduleEdit",
  props:{
    show: Boolean,
    schedule: Object,
  },
  data(){
    return{
      rules: [
        value => !!value || 'Required.',
        value => (Number.isInteger(Number(value))) || ' error value ',
      ],
    }
  },
  methods:{
    closeDialog(){
      this.$emit("closeDialog")
    },
    saveSchedule() {
      this.loading = true
      HTTP.post(Settings.API.URL_DEFAULT + Settings.API.URL_SCHEDULE, this.schedule)
          .then(() => {
            this.$emit("success")
            this.show = false
          })
          .finally(() => (this.loading = false));
    },
  }
}
</script>

<style scoped>

</style>