<template>
  <v-container>
    <v-app id="schedule">
      <v-container justify-center>
        <v-flex xs12>
          <v-card v-show="sheet">
            <v-card-text>
              <v-layout row wrap v-if="!!sheet">
                <v-flex xs12 md12>
                  <v-card-actions class="justify-center">
                    <h1>Практика</h1>
                  </v-card-actions>
                  <v-card-actions class="justify-center">
                    <h1 v-if="this.sheet.discipline">{{ this.sheet.discipline.name }}</h1>
                    <h1 v-else>Дисциплина не указана</h1>
                  </v-card-actions>
                  <v-card-actions class="justify-center">
                    <h1 v-if="this.sheet.group">Группа {{ this.sheet.group.name }}</h1>
                    <h1 v-else>Группа не указана</h1>
                  </v-card-actions>
                </v-flex>
              </v-layout>
            </v-card-text>
          </v-card>
        </v-flex>
      </v-container>

      <v-container>
        <v-alert type="warning" v-if="sheet && !sheet.assignId" transition="fade-transition" prominent>
          <v-row align="center">
            <v-col class="grow">
              <strong>Непривязана контрольная точка из ЭИОС - редактирование недоступно</strong>
            </v-col>
            <v-col class="shrink">
              <v-btn text @click="openSheetAssignDialog">
                <v-icon left>link</v-icon>
                Привязать контрольную точку
              </v-btn>
            </v-col>
          </v-row>
        </v-alert>
      </v-container>

      <v-container>
        <v-alert :type="alertType"
                 v-model="alert"
                 dismissible
                 transition="fade-transition"
        >
          {{ alertText }}
        </v-alert>
      </v-container>
      <v-card-actions v-if="sheet && (isAdmin() || canCurrentUserEditSheet())">
        <v-divider/>
        <v-btn text color="primary" @click="resetLastFileInSheet" :disabled="!sheet || !sheet.assignId">
          <v-icon left>refresh</v-icon>
          Обновить файлы студентов
        </v-btn>
        <v-tooltip bottom color="error" :disabled="!isAtLeastOneEvaluationFileSet()">
          <template v-slot:activator="{ on, attrs }">
            <div class="text-right" v-on="on">
              <v-btn text @click="openSheetAssignDialog" color="primary" :disabled="isAtLeastOneEvaluationFileSet()" v-bind="attrs">
                <v-icon left>link</v-icon>
                Привязать КТ
              </v-btn>
            </div>
          </template>
          <span>Необходимо отвязать файлы от студентов, чтобы изменить контрольную точку</span>
        </v-tooltip>
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
                color="primary"
                v-bind="attrs"
                v-on="on"
                text
                :close-on-content-click="false"
            >
              <v-icon left>more_horiz</v-icon>
              Ещё
            </v-btn>
          </template>
          <v-list>
            <v-list-item v-if="isAdmin()">
              <v-btn text color="primary" @click="openLinkAdminDialog(sheet)">
                <v-icon left>link</v-icon>
                Привязать админа ведомости
              </v-btn>
            </v-list-item>
            <v-list-item v-if="isAdmin() || isSheetAdmin()">
              <v-btn text color="primary" @click="openLinkTeacherDialog(sheet.id)">
                <v-icon left>link</v-icon>
                Привязать преподавателей
              </v-btn>
            </v-list-item>
            <v-list-item>
              <v-btn text @click="goToUrl(assignUrl)" color="primary" :disabled="!sheet || !sheet.assignId">
                <v-icon left>language</v-icon>
                Перейти к контрольной точке
              </v-btn>
            </v-list-item>
            <v-list-item v-show="isAdmin()">
              <v-btn text @click="downloadAllFiles" color="primary" :disabled="downloading || !isAtLeastOneEvaluationFileSet()" :loading="downloading" v-show="isAdmin()">
                <v-icon left>download</v-icon>
                Выгрузить файлы студентов на сервер
              </v-btn>
            </v-list-item>
            <v-list-item>
              <v-btn text color="primary" @click="updateStudentList">
                <v-icon left>refresh</v-icon>
                Обновить список студентов
              </v-btn>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-card-actions>
      <v-container justify-center>
        <v-flex xs12>
          <div v-if="loading" class="d-flex justify-center">
              <v-progress-circular
                  :size="100"
                  :width="10"
                  color="blue"
                  indeterminate
              />
          </div>
          <v-alert prominent v-show="disciplineId && !loading && (!visits || visits.length === 0)" type="info">
            <v-row align="center">
              <v-col class="grow text-center">
                Ведомость отсутствует!
              </v-col>
              <v-col class="shrink" v-if="isAdmin()">
                <v-btn @click="openCreateSheetDialog">
                  <v-icon left>add</v-icon>
                  Создать ведомость
                </v-btn>
              </v-col>
            </v-row>
          </v-alert>
          <v-data-table
              mobile-breakpoint="0"
              v-show="visits && visits.length > 0"
              :headers="headers"
              :items="visits"
              :sort-by.sync="sortBy"
              :items-per-page="80"
              hide-default-footer
              class="elevation-2 table"
              no-data-text="Ведомость отсутствует"
              :loading="loading"
              fixed-header
          >
            <template v-slot:no-data>
              <v-alert :value="true" color="info" icon="info">
                <v-row align="center">
                  <v-col class="grow">
                    Ведомость отсутствует!
                  </v-col>
                </v-row>
              </v-alert>
            </template>

            <template v-slot:item.formalEvaluation="item">
              <span v-if="!canCurrentUserEditSheet()">
                {{ item.item.formalEvaluation }}
              </span>

              <v-tooltip v-if="canCurrentUserEditSheet()" bottom  :disabled="!!item.item.fileUrl || !sheet || !sheet.assignId">
                <template v-slot:activator="{ on, attrs }">
                  <div v-on="on">
                    <v-btn text  block large v-bind="attrs"
                           @click="showDialog(item.item, 'formalEvaluation')"
                           :disabled="!item.item.fileUrl || !sheet || !sheet.assignId"
                    >
                      {{ item.item.formalEvaluation }}
                    </v-btn>
                  </div>
                </template>
                <span>Чтобы редактировать баллы необходимо привязать файл</span>
              </v-tooltip>
            </template>

            <template v-slot:item.contentEvaluation="item">
              <span v-if="!canCurrentUserEditSheet()">
                {{ item.item.contentEvaluation }}
              </span>

              <v-tooltip v-if="canCurrentUserEditSheet()" bottom  :disabled="!!item.item.fileUrl || !sheet || !sheet.assignId">
                <template v-slot:activator="{ on, attrs }">
                  <div v-on="on">
                    <v-btn text  block large v-bind="attrs"
                           @click="showDialog(item.item, 'contentEvaluation')"
                           :disabled="!item.item.fileUrl || !sheet || !sheet.assignId"
                    >
                      {{ item.item.contentEvaluation }}
                    </v-btn>
                  </div>
                </template>
                <span>Чтобы редактировать баллы необходимо привязать файл</span>
              </v-tooltip>
            </template>

            <template v-slot:item.presentationEvaluation="item">
              <span v-if="!canCurrentUserEditSheet()">
                {{ item.item.presentationEvaluation }}
              </span>

              <v-tooltip v-if="canCurrentUserEditSheet()" bottom  :disabled="!!item.item.fileUrl || !sheet || !sheet.assignId">
                <template v-slot:activator="{ on, attrs }">
                  <div v-on="on">
                    <v-btn text  block large v-bind="attrs"
                           @click="showDialog(item.item, 'presentationEvaluation')"
                           :disabled="!item.item.fileUrl || !sheet || !sheet.assignId"
                    >
                      {{ item.item.presentationEvaluation }}
                    </v-btn>
                  </div>
                </template>
                <span>Чтобы редактировать баллы необходимо привязать файл</span>
              </v-tooltip>
            </template>

            <template v-slot:item.totalEvaluation="item">
              <span v-if="!canCurrentUserEditSheet()">
                {{ item.item.visit ? item.item.totalEvaluation : 'Не явился' }}
              </span>

              <v-tooltip v-if="canCurrentUserEditSheet()" bottom  :disabled="!!item.item.fileUrl || !sheet || !sheet.assignId">
                <template v-slot:activator="{ on, attrs }">
                  <div v-on="on">
                    <v-btn text  block large v-bind="attrs"
                           @click="showDialog(item.item, null)"
                           :disabled="!item.item.fileUrl || !sheet || !sheet.assignId"
                    >
                      {{ item.item.visit ? item.item.totalEvaluation : 'Не явился' }}
                    </v-btn>
                  </div>
                </template>
                <span>Чтобы редактировать баллы необходимо привязать файл</span>
              </v-tooltip>
            </template>

            <template v-slot:item.fileUrl="item">
              <div v-if="isAdmin() || canCurrentUserEditSheet()">
                <v-tooltip bottom v-if="item.item.fileUrl" color="primary">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn color="primary" icon v-on="on" v-bind="attrs" @click="goToFileUrl(item.item)" :disabled="!sheet || !sheet.assignId">
                      <v-icon>download</v-icon>
                    </v-btn>
                  </template>
                  <span>Скачать привязанный файл</span>
                </v-tooltip>

                <v-tooltip bottom v-if="item.item.fileUrl" color="primary">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn color="primary" icon v-on="on" v-bind="attrs" @click="changeFileUrl(item.item)" :disabled="!sheet || !sheet.assignId">
                      <v-icon>link</v-icon>
                    </v-btn>
                  </template>
                  <span>Привязать другой файл</span>
                </v-tooltip>

                <v-tooltip bottom v-if="item.item.fileUrl" color="red">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn color="red" icon v-on="on" v-bind="attrs" @click="unlinkFileUrl(item.item)" :disabled="!sheet || !sheet.assignId">
                      <v-icon>link</v-icon>
                    </v-btn>
                  </template>
                  <span>Отвязать файл</span>
                </v-tooltip>

                <v-tooltip bottom v-if="!item.item.fileUrl" color="primary">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn color="primary" icon v-on="on" v-bind="attrs" @click="changeFileUrl(item.item)" :disabled="!sheet || !sheet.assignId">
                      <v-icon>link</v-icon>
                    </v-btn>
                  </template>
                  <span>Привязать файл</span>
                </v-tooltip>
              </div>
            </template>

          </v-data-table>
        </v-flex>
      </v-container>

      <v-card-actions v-if="sheet && (isAdmin() || canCurrentUserEditSheet())">
        <v-divider/>
        <v-btn text color="primary" @click="resetLastFileInSheet" :disabled="!sheet || !sheet.assignId">
        <v-icon left>refresh</v-icon>
          Обновить файлы студентов
        </v-btn>
        <v-btn text @click="openSheetAssignDialog" color="primary" :disabled="isAtLeastOneEvaluationFileSet()">
          <v-icon left>link</v-icon>
          Привязать КТ
        </v-btn>
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
                color="primary"
                v-bind="attrs"
                v-on="on"
                text
                :close-on-content-click="false"
            >
              <v-icon left>more_horiz</v-icon>
              Ещё
            </v-btn>
          </template>
          <v-list>
            <v-list-item v-if="isAdmin()">
              <v-btn text color="primary" @click="openLinkAdminDialog(sheet)">
                <v-icon left>link</v-icon>
                Привязать админа ведомости
              </v-btn>
            </v-list-item>
            <v-list-item v-if="isAdmin() || isSheetAdmin()">
              <v-btn text color="primary" @click="openLinkTeacherDialog(sheet.id)">
                <v-icon left>link</v-icon>
                Привязать преподавателей
              </v-btn>
            </v-list-item>
            <v-list-item>
              <v-btn text @click="goToUrl(assignUrl)" color="primary" :disabled="!sheet || !sheet.assignId">
                <v-icon left>language</v-icon>
                Перейти к контрольной точке
              </v-btn>
            </v-list-item>
            <v-list-item v-show="isAdmin()">
              <v-btn text @click="downloadAllFiles" color="primary" :disabled="downloading || !isAtLeastOneEvaluationFileSet()" :loading="downloading" v-show="isAdmin()">
                <v-icon left>download</v-icon>
                Выгрузить файлы студентов на сервер
              </v-btn>
            </v-list-item>
            <v-list-item>
              <v-btn text color="primary" @click="updateStudentList">
                <v-icon left>refresh</v-icon>
                Обновить список студентов
              </v-btn>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-card-actions>

      <template>
        <v-row justify="center">
          <v-dialog
              v-model="sheetAssignDialog"
              width="600px"
              persistent
          >
            <v-card v-if="sheetAssignDialog">
              <v-toolbar
                  dark
                  color="primary"
              >
                <v-card-title>Указать точку контроля для ведомости</v-card-title>
                <v-spacer/>
                <v-btn
                    icon
                    dark
                    @click="cancelSheetAssignDialog()"
                >
                  <v-icon>close</v-icon>
                </v-btn>
              </v-toolbar>

              <v-card-text>
                <v-card-text>
                  <p>Практика - <b>{{ sheet.discipline.name }}</b> </p>
                  <p>Группа - <b>{{ sheet.group.name }}</b></p>
                  <p><b>{{ sheet.group.formOfTraining }}</b> форма обучения, <b>{{ translateTypesEducation(sheet.group.typesEducation) }}</b></p>
                  <p>Направление - <b>{{ sheet.group.directionName }}</b></p>
                  <p>Программа - <b>{{ sheet.group.program }}</b></p>
                  <p>Дата - <b>{{ formatFullDate(sheet.date) }}</b> </p>
                  <a @click="goToUrl(assignUrl)" v-if="sheet.assignId">Ссылка на нынешнюю точку контроля</a>
                </v-card-text>

                <lms-control-points
                    @cp-selected="setSheetAssign"
                    :show-practice-courses="true"
                />
              </v-card-text>

              <v-card-actions>
                <v-divider/>
                <v-btn
                    color="secondary"
                    text
                    @click="cancelSheetAssignDialog()"
                >
                  Отмена
                </v-btn>
                <v-btn
                    color="primary"
                    text
                    @click="updateSheetAssign"
                    :disabled="!sheet.assignId"
                >
                  Сохранить
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-row>
      </template>

      <template>
        <v-row justify="center">
          <v-dialog
              v-model="dialog"
              width="600px"
              persistent
          >
            <v-card v-if="dialog !== false">
              <v-toolbar
                  dark
                  color="primary"
              >
                <v-card-title>Редактировать оценку</v-card-title>
                <v-spacer/>
                <v-btn
                    icon
                    dark
                    @click="cancelEditEvaluationDialog()"
                >
                  <v-icon>close</v-icon>
                </v-btn>
              </v-toolbar>

              <v-card-text>
                <v-container/>
                <p>Студент - <b> {{ editedEvaluation.student.student }} </b> </p>
                <p>Дисциплина - {{ editedEvaluation.discipline.name }} </p>
<!--                <p>Тип - {{ visitItem.workload.workPlan.lessonTypes.name }} </p>-->
                <p>Дата - {{ getDate(editedEvaluation.date) }} </p>
<!--                <p>Статус - <b> {{ isVisitDialog(visitItem) + "(а)"}} </b> </p>-->
                  <v-form ref="form" v-model="valid">
                    <v-switch
                        v-model="editedEvaluation.visit"
                        :label="`${editedEvaluation.visit? 'Присутствовал': 'Не явился'}`"
                        @change="validateForm()"
                    />
                    <v-row>
                      <v-col cols="3">
                        <v-text-field
                            label="Формальный"
                            v-model="editedEvaluation.formalEvaluation"
                            placeholder="Введите заработанные баллы"
                            :rules="rulesF"
                            persistent-placeholder
                            :autofocus="autofocusInDialog === 'formalEvaluation' ? true : false"
                            suffix="из 30"
                            type="number"
                        />
                      </v-col>
                      <v-col cols="3">
                        <v-text-field
                            label="Содержательный"
                            v-model="editedEvaluation.contentEvaluation"
                            placeholder="Введите заработанные баллы"
                            :rules="rulesC"
                            persistent-placeholder
                            :autofocus="autofocusInDialog === 'contentEvaluation' ? true : false"
                            suffix="из 50"
                            type="number"
                        />
                      </v-col>
                      <v-col cols="3">
                        <v-text-field
                            label="Презентационный"
                            v-model="editedEvaluation.presentationEvaluation"
                            placeholder="Введите заработанные баллы"
                            :rules="rulesP"
                            persistent-placeholder
                            :autofocus="autofocusInDialog === 'presentationEvaluation' ? true : false"
                            suffix="из 20"
                            type="number"
                        />
                      </v-col>
                      <v-col cols="3">
                        <v-text-field
                            readonly
                            label="Итого"
                            :value="getCurrentTotalEvaluation()"
                            suffix="из 100"
                            hint="Это поле только для чтения"
                        />
                      </v-col>
                    </v-row>



                  </v-form>
                <!--                              <p>Оценка - {{ visitItem.evaluation != null ? visitItem.evaluation.toFixed(1): "" }} </p>-->
              </v-card-text>

              <v-card-actions>
                <v-divider/>
                <v-btn
                    color="secondary"
                    text
                    @click="cancelEditEvaluationDialog()"
                >
                  <v-icon left>close</v-icon>
                  Отмена
                </v-btn>
                <v-btn
                    color="primary"
                    text
                    @click="validateAndUpdateEvaluationPractice"
                    :disabled="!valid"
                >
                  <v-icon left>save</v-icon>
                  Сохранить
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-row>
      </template>

      <template>
        <v-row justify="center">
          <v-dialog
              v-model="fileUrlDialog"
              width="600px"
              persistent
          >
            <v-card v-if="fileUrlDialog !== false">
              <v-toolbar
                  dark
                  color="primary"
              >
                <v-card-title>Указать файл практики</v-card-title>
                <v-spacer/>
                <v-btn
                    icon
                    dark
                    @click="cancelUrlDialog()"
                >
                  <v-icon>close</v-icon>
                </v-btn>
              </v-toolbar>

              <v-card-text>
                <v-card-text>
                  <p>Студент - <b>{{ editedEvaluation.student.student }} </b> </p>
                  <p>Практика - <b>{{ sheet.discipline.name }}</b> </p>
                  <p>Дата - <b>{{ formatFullDate(editedEvaluation.date) }}</b> </p>
                  <p v-if="!editedEvaluation.fileUrl"><b>Файл не привязан</b></p>
                <v-form ref="urlForm" v-model="validFileUrl">
                  <v-text-field
                      ref="visitItemUrl"
                      label="URL адрес"
                      v-model="editedEvaluation.fileUrl"
                      placeholder="Воспользуйтесь меню ниже чтобы привязать файл"
                      readonly
                      persistent-placeholder
                      :append-icon="editedEvaluation.fileUrl ? 'content_copy' : ''"
                      :append-outer-icon="editedEvaluation.fileUrl ? 'download' : ''"
                      @click:append="copyText"
                      @click:append-outer="goToFileUrl(editedEvaluation)"
                      :hint="isTextCopied ? 'Ссылка скопирована' : ''"
                      :persistent-hint="isTextCopied ? true : false"
                  />
                </v-form>
                </v-card-text>

                <v-card-title>
                  Файлы студента, оцененные в контрольной точке
                </v-card-title>
                <lms-files-from-control-point
                    ref="lmsFiles"
                    @file-selected="setFileUrl"
                    :selected-student-id="editedEvaluation.student.codeLms"
                    :assign-id="sheet.assignId"
                    :visit-item="editedEvaluation"
                />
              </v-card-text>

              <v-card-actions>
                <v-divider/>
                <v-btn
                    color="secondary"
                    text
                    @click="cancelUrlDialog()"
                >
                  <v-icon left>close</v-icon>
                  Отмена
                </v-btn>
                <v-btn :disabled="!editedEvaluation.fileUrl" text color="error" @click="unlinkFileUrl(editedEvaluation)">
                  <v-icon left>link</v-icon>
                  Отвязать
                </v-btn>
                <v-btn
                    color="primary"
                    text
                    @click="updateFileUrl"
                    :disabled="!editedEvaluation.fileUrl"
                >
                  <v-icon left>save</v-icon>
                  Сохранить
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-row>
      </template>

      <template>
        <v-row justify="center">
          <create-sheet-dialog
              v-if="isAdmin()"
              ref="createDialog"
              :groupId="groupId"
              :disciplineId="disciplineId"
              :yearId="yearId"
              :halfYearId="halfYearId"
              sheet-type="PRACTICE"
              @createdSheet="getJournal"
          />
        </v-row>
      </template>

      <template>
        <v-row justify="center">
          <link-sheet-to-teacher
              v-if="isAdmin() || isSheetAdmin()"
              ref="linkTeacherDialog"
              sheet-type="PRACTICE"
              @updatedSheet="getJournal"
          />
        </v-row>
      </template>

      <template>
        <v-row justify="center">
          <link-admin-to-sheet
              v-if="isAdmin()"
              ref="linkAdminDialog"
              sheet-type="PRACTICE"
              @updatedSheet="getJournal"
          />
        </v-row>
      </template>
    </v-app>
  </v-container>
</template>

<script>

import Settings from "@/settings/settings_url";
import {HTTP} from '@/settings/axios'
import date_utils from "@/settings/date_utils";
import LmsControlPoints from "@/components/lmsViews/LmsControlPoints";
import LmsFilesFromControlPoint from "@/components/lmsViews/LmsFilesFromControlPoint";
import LinkSheetToTeacher from "@/components/gradeSheet/admin/LinkSheetToTeacher";
import CreateSheetDialog from "@/components/gradeSheet/admin/CreateSheetDialog";
import LinkAdminToSheet from "@/components/gradeSheet/admin/LinkAdminToSheet";

export default {
  name: "PracticeVed",
  props:{
    groupId: Number,
    disciplineId: Number,
    yearId: Number,
    halfYearId: Number,
  },
  data() {
    return {
      headers: [
        {text: "Студент", align: 'center', sortable: false, value: 'student.student', divider: true, class: "bolt"},
        {text: "Формальный балл", align: 'center', sortable: false, value: 'formalEvaluation', divider: true, class: "bolt"},
        {text: "Содержательный балл", align: 'center', sortable: false, value: 'contentEvaluation', divider: true, class: "bolt"},
        {text: "Презентационный балл (защита)", align: 'center', sortable: false, value: 'presentationEvaluation', divider: true, class: "bolt"},
        {text: "Итого", align: 'center', sortable: false, value: 'totalEvaluation', divider: true, class: "bolt"},
      ],
      fileUrlHeader: {text: "Файл", align: 'center', sortable: false, value: 'fileUrl', divider: true, class: "bolt"},
      autofocusInDialog: null,
      sortBy: "student.student",
      valid: false,
      loading: false,
      all: true,
      files: [],
      sheet: null,
      visits: [],
      dialog: false,
      maxEvaluation: 0,
      rules: [v => v <= this.maxEvaluation || 'Максимум ' + this.maxEvaluation + ' баллов'],
      rulesF: [
          v => v <= 30 || 'Максимум ' + 30 + ' баллов',
          v => v >= 0 || 'Нельзя поставить отрицательные баллы',
          v => (this.editedEvaluation.visit || (v == 0 && !this.editedEvaluation.visit)) || 'При неявке можно поставить только 0 баллов',
          v => (!this.editedEvaluation.visit || (v > 0 && this.editedEvaluation.visit)) || 'При присутствии нельзя ставить 0 баллов',
      ],
      rulesC: [
          v => v <= 50 || 'Максимум ' + 50 + ' баллов',
          v => v >= 0 || 'Нельзя поставить отрицательные баллы',
          v => (this.editedEvaluation.visit || (v == 0 && !this.editedEvaluation.visit)) || 'При неявке можно поставить только 0 баллов',
          v => (!this.editedEvaluation.visit || (v > 0 && this.editedEvaluation.visit)) || 'При присутствии нельзя ставить 0 баллов',
      ],
      rulesP: [
          v => v <= 20 || 'Максимум ' + 20 + ' баллов',
          v => v >= 0 || 'Нельзя поставить отрицательные баллы',
          v => (this.editedEvaluation.visit || (v == 0 && !this.editedEvaluation.visit)) || 'При неявке можно поставить только 0 баллов',
          v => (!this.editedEvaluation.visit || (v > 0 && this.editedEvaluation.visit)) || 'При присутствии нельзя ставить 0 баллов',
      ],
      editedEvaluation: null,
      fileUrlDialog: false,
      validFileUrl: false,

      alert: false,
      alertText: null,
      alertType: null,
      sheetAssignDialog: false,
      assignUrl: null,
      isTextCopied: false,

      downloading: false,
    }
  },
  mounted() {
    this.alert = false
    if(this.canCurrentUserEditSheet() && !this.headers.includes(this.fileUrlHeader))
      this.headers.push(this.fileUrlHeader);
  },
  watch: {
    '$store.getters.selectedStudent'() {
      if(this.canCurrentUserEditSheet() && !this.headers.includes(this.fileUrlHeader))
        this.headers.push(this.fileUrlHeader);
    },
    all: function () {
      this.getJournal()
    },
    yearId(){
      this.updateTable()
    },
    halfYearId(){
      this.updateTable()
    },
    groupId: function (){
      this.updateTable()
    },
    disciplineId: function (){
      this.updateTable()
    },
    'isTextCopied'() {
      if(this.isTextCopied)
        setTimeout(() => {this.isTextCopied = false}, 5000)
    },
    'sheet'() {
      if(this.sheet && this.sheet.assignId) {
        this.getSheetAssignUrl()
      }
      if(this.sheet && this.canCurrentUserEditSheet() && !this.headers.includes(this.fileUrlHeader))
        this.headers.push(this.fileUrlHeader);
    }
  },
  methods: {
    openLinkAdminDialog(sheet) {
      this.$refs.linkAdminDialog.sheet = sheet
      this.$refs.linkAdminDialog.dialog = true
    },
    openLinkTeacherDialog(sheetId) {
      this.$refs.linkTeacherDialog.sheetId = sheetId
      this.$refs.linkTeacherDialog.dialog = true
    },
    openCreateSheetDialog() {
      this.$refs.createDialog.dialog = true
    },
    downloadAllFiles() {
      this.downloading = true

      HTTP.get(Settings.API.URL_DEFAULT + "/practice-sheet/download-sheet-files-to-server?sheetId=" + this.sheet.id)
          .then(() => {
            this.alert = true
            this.alertType = "success"
            this.alertText = "Файлы успешно выгружены"
          })
          .catch(() => {
            this.alert = true;
            this.alertType = "error"
            this.alertText = "Не удалось выгрузить файлы"
          })
          .finally(() => {
            this.downloading = false
          });
    },
    isAtLeastOneEvaluationFileSet() {
      if(this.visits === undefined)
        return false

      return this.visits.some(function(v) {
        return v.fileUrl !== null && v.fileUrl !== ''
      })
    },
    formatFullDate(date) {
      return date_utils.formatFullDate(date)
    },
    openSheetAssignDialog() {
      if(this.sheet && this.sheet.assignId) {
        this.getSheetAssignUrl()
      }
      this.sheetAssignDialog = true
    },
    copyText () {
      this.isTextCopied = true
      let textToCopy = this.$refs.visitItemUrl.$el.querySelector('input')
      textToCopy.select()
      navigator.clipboard.writeText(textToCopy.value);
    },
    goToFileUrl(item) {
      this.goToUrl(item.fileUrl)
    },
    goToUrl(item) {
      window.open(item, '_blank').focus()
    },
    changeFileUrl(item) {
      this.fileUrlDialog = true;
      this.editedEvaluation = Object.assign({}, item)
    },
    setSheetAssign(cp) {
      this.sheet.assignId = cp.id
    },
    getSheetAssignUrl() {
      HTTP.get(Settings.API.URL_DEFAULT + "/courses/lms/assigns/" + this.sheet.assignId + "/url")
          .then((response) => {
            this.assignUrl = response.data
            // return name
          }).catch(() => {

      })
    },
    updateSheetAssign() {
      this.loading = true
      this.$emit("loading")
      HTTP.post(Settings.API.URL_DEFAULT + "/practice-sheet/update-assign-id", this.sheet)
          .then((response) => {
            this.sheet = response.data
            this.visits = response.data.practiceSheetEvaluationList
            this.alert = true
            this.alertType = "success"
            this.alertText = "Точка контроля привязана успешно. Студентам, имеющим файлы в данной точке, они были привязаны автоматически. "
          }).catch(() => {
            this.alert = true;
            this.alertType = "error";
            this.alertText = "Не удалось привязать точку контроля"
          }).finally(() => {
            this.loading = false
            this.$emit("stop-loading")
            this.cancelSheetAssignDialog()
      })
    },
    resetLastFileInSheet() {
      this.loading = true
      this.$emit("loading")

      HTTP.get(Settings.API.URL_DEFAULT + "/practice-sheet/evaluation/reset-last-file-in-sheet?sheetId=" + this.sheet.id)
          .then(() => {
            this.alert = true
            this.alertType = "success"
            this.alertText = "Файлы успешно обновлены"
            this.getJournal()
          })
          .catch(() => {
            this.alert = true;
            this.alertType = "error"
            this.alertText = "Не удалось обновить файлы"
          })
          .finally(() => {
            this.loading = false
            this.$emit("stop-loading")
          });
    },
    updateStudentList() {
      this.loading = true
      this.$emit("loading")

      HTTP.get(Settings.API.URL_DEFAULT + "/practice-sheet/" + this.sheet.id + "/update-student-list")
          .then((response) => {
            console.log(response.data)
            if(!response.data) {
              this.alert = true
              this.alertType = "info"
              this.alertText = "Новых студентов в группе найдено не было"
            } else {
              this.sheet = response.data
              this.visits = response.data.practiceSheetEvaluationList
              this.alert = true
              this.alertType = "success"
              this.alertText = "Список студентов обновлен"
            }
          })
          .catch(() => {
            this.alert = true;
            this.alertType = "error"
            this.alertText = "Не удалось обновить список студентов"
          })
          .finally(() => {
            this.loading = false
            this.$emit("stop-loading")
          });
    },
    validateForm() {
      this.$refs.form.validate()
    },
    getDate: function (date){
      return date_utils.formatDate(date)
    },
    cancelEditEvaluationDialog() {
      this.dialog = false
    },
    getCurrentTotalEvaluation() {
      return +this.editedEvaluation.presentationEvaluation + +this.editedEvaluation.contentEvaluation + +this.editedEvaluation.formalEvaluation
    },
    updateTable: function () {
      if (this.groupId != undefined && this.groupId != 0 && this.yearId != undefined && this.yearId != 0
          && this.halfYearId != undefined && this.halfYearId != 0
          && this.disciplineId != undefined && this.disciplineId != 0){
        this.getJournal()
      }
    },
    setFileUrl(file) {
      this.editedEvaluation.fileUrl = file.fileUrl
      // this.loading = true
      // console.log(cp)
      // this.visitItem.fileUrl =
      // this.fileUrlDialog = false
      // this.getJournal()
    },
    async updateFileUrl() {
      await this.updateEvaluationPractice()
      this.fileUrlDialog = false
    },
    unlinkFileUrl(item) {
      this.editedEvaluation = item
      this.editedEvaluation.fileUrl = null
      this.updateFileUrl()
    },
    cancelUrlDialog() {
      this.fileUrlDialog = false
    },
    cancelSheetAssignDialog() {
      this.getJournal()
      this.sheetAssignDialog = false
    },
    content: function (item) {
      if (item instanceof Object) {
        let sy = ""
        if (item.visit == 0){
          sy = " Не явился"
        }
        return item.evaluation != null && item.evaluation != 0? item.evaluation + sy: "" + sy
      }
      return item
    },
    showDialog: function (item, evaluation) {
      this.autofocusInDialog = evaluation
      if (this.isAdmin() || this.canCurrentUserEditSheet()) {
          this.getMaxEvaluation(item)
          this.dialog = true
          this.editedEvaluation = Object.assign({}, item)
      }
    },
    getMaxEvaluation: function (item){
      console.log(item)
      this.maxEvaluation = 100
    },
    async validateAndUpdateEvaluationPractice() {
      await this.$refs.form.validate()
      if(!this.valid)
        return

      await this.updateEvaluationPractice()
    },
    async updateEvaluationPractice() {
      this.loading = true
      this.$emit("loading")
      await HTTP.put(Settings.API.URL_DEFAULT + "/practice-sheet/evaluation",
          this.editedEvaluation).then(() => {
            this.getJournal()
            this.alert = true
            this.alertType = "success"
            this.alertText = "Успешно обновлена ведомость студента"
          }).catch(() => {
            this.alert = true
            this.alertType = "error"
            this.alertText = "Ошибка при обновлении ведомости студента"
          }).finally(() => {
            this.editedEvaluation = null
            this.loading = false
            this.$emit("stop-loading")
            this.dialog = false
          });
    },
    getJournal: async function () {
      let groupId = this.groupId
      let disciplineId = this.disciplineId

      if (groupId == 0 || disciplineId == 0){
        groupId = this.$route.query.group
        disciplineId = this.$route.query.discipline
      }

      if (groupId == 0 || disciplineId == 0 || groupId == undefined || disciplineId == undefined ){
        return
      }
      this.loading = true
      this.$emit("loading")
      this.visits = []

      await HTTP.get(Settings.API.URL_DEFAULT +  "/practice-sheet/ved-stats?groupId=" + groupId + "&disciplineId="
          + disciplineId + "&yearId=" + this.yearId + "&halfYearId=" + this.halfYearId)
          .then((response) => {
            this.sheet = response.data
            this.visits = response.data.practiceSheetEvaluationList
            console.log(this.visits)
          })
          .catch(() => {
            this.errored = true;
          })
          .finally(() => {
            this.loading = false
            this.$emit("stop-loading")
          });
    },
    isSheetAdmin() { // удалить после того как 1С приказы начнут формировать ведомости
      if(!this.sheet.userAdmin)
        return false;

      return this.sheet.userAdmin.id === this.$store.getters.selectedUser.id
    },
    isAdmin(){
      return this.isRole("ROLE_ADMIN")
    },
    isTeacher() {
      return this.isRole("ROLE_TEACHER")
    },
    isRole(role) {
      if (this.$store.getters.selectedUser?.role != null && this.$store.getters.selectedUser?.role.length > 0){
        for (let i = 0; i < this.$store.getters.selectedUser?.role.length; i++){
          if (
              this.$store.getters.selectedUser?.role[i].name === role
          ){
            return true
          }
        }
      }else {
        return  false
      }
    },
    canCurrentUserEditSheet() {
      if(!this.sheet || !this.sheet.assignId)
        return false
      else if(this.sheet.usersThatCanEdit.some(u => u.id === this.$store.getters.selectedUser.id))
        return true
      else if(this.isAdmin())
        return true
      else if(this.isSheetAdmin())
        return true

      return false
    },
    translateTypesEducation(item) {
      if(item === 'MAGISTRACY')
        return "Магистратура"
      else if(item === 'BACCALAUREATE')
        return "Бакалавриат"
      else if(item === 'SPO')
        return "СПО"
      else if(item === "ASPIRANT")
        return "Аспирантура"
      else if(item === null)
        return "Не указано"
      else
        return "Неизвестно"
    },

  },
  created() {
    this.alert = false
    this.getJournal()
  },
  components: {
    LinkAdminToSheet,
    CreateSheetDialog,
    LinkSheetToTeacher,
    LmsFilesFromControlPoint,
    LmsControlPoints
  }
}
</script>

<style scoped>
.table{
  max-width: calc(100% - 48px);
  max-height: calc(100vh - 170px);
}
.v-data-table {
  overflow: auto;
}
.v-data-table /deep/ .v-data-table__wrapper {
  overflow: unset;
}
</style>
