<template>
  <div>
     <v-expansion-panels
        v-model="panel"
        :readonly="readonly"
        multiple
    >
       <v-expansion-panel>
         <v-expansion-panel-header class="letter"><p><span class="letter">ВЫБОРКА ДАННЫХ</span></p></v-expansion-panel-header>
       <v-expansion-panel-content>

           <v-card-text>
             <v-layout row wrap>
               <v-flex xs12 md12>
                 <v-row>
                   <v-col></v-col>
                 </v-row>
                 <v-row>
                   <v-col
                       sm="2"
                   >
                     <v-menu
                         ref="menu1"
                         v-model="menu1"
                         :close-on-content-click="false"
                         :return-value.sync="date1"
                         transition="scale-transition"
                         offset-y
                         min-width="auto"

                     >

                       <template v-slot:activator="{ on, attrs }">
                         <!--                  <v-label>От:</v-label>-->
                         <v-text-field
                             :value="computedDate1Formatted"
                             label="Статистика с:"
                             prepend-icon="event"
                             readonly
                             v-bind="attrs"
                             v-on="on"
                             color="#36a2eb"
                         ></v-text-field>
                       </template>
                       <v-date-picker
                           v-model="date1"
                           no-title
                           scrollable
                           show-adjacent-months
                           locale="ru-RU"
                           :first-day-of-week="1"
                           color="#36a2eb"
                       >
                         <v-spacer/>
                         <v-btn
                             text
                             color="#36a2eb"
                             @click="menu1 = false"
                         >
                           Отмена
                         </v-btn>
                         <v-btn

                             text
                             color="#36a2eb"
                             @click="$refs.menu1.save(date1)"
                         >
                           Выбрать
                         </v-btn>

                       </v-date-picker>
                     </v-menu>
                   </v-col>

                   <v-col

                       sm="2"

                   >
                     <v-menu
                         ref="menu2"
                         v-model="menu2"
                         :close-on-content-click="false"
                         :return-value.sync="date2"
                         transition="scale-transition"
                         offset-y
                         min-width="auto"
                     >
                       <template v-slot:activator="{ on, attrs }">
                         <v-text-field
                             :value="computedDate2Formatted"
                             label="Статистика до:"
                             prepend-icon="event"
                             readonly
                             v-bind="attrs"
                             v-on="on"
                             color="#36a2eb"

                         ></v-text-field>
                       </template>
                       <v-date-picker

                           v-model="date2"
                           no-title
                           scrollable
                           allowed-dates
                           color="#36a2eb"
                           locale="ru"

                       >
                         <v-spacer></v-spacer>
                         <v-btn
                             text
                             color="#36a2eb"
                             @click="menu2 = false"
                         >
                           Отмена
                         </v-btn>
                         <v-btn
                             text
                             color="#36a2eb"
                             @click="$refs.menu2.save(date2)"
                         >
                           Выбрать
                         </v-btn>
                       </v-date-picker>
                     </v-menu>
                   </v-col>
<!--                   <v-col-->
<!--                       sm="4"-->
<!--                   ></v-col>-->
<!--                 </v-row>-->
<!--                 <v-row>-->
<!--                   <v-col>-->
<!--                   </v-col>-->
<!--                 </v-row>-->
<!--                 <v-row>-->
<!--                   <v-container class="d-flex align-center justify-center" height="100%">-->
                     <v-col
                         cols="2"
                         sm="2"
                         md="2"
                     >
                       <v-autocomplete
                           v-model="group"
                           :items="groups"
                           chips
                           small-chips
                           clearable
                           item-text="name"
                           item-value="id"
                           label="Группа"
                           color="#36a2eb"
                           prepend-icon="groups"
                       />
                     </v-col>


                     <v-col
                         cols="2"
                         sm="2"
                         md="2"
                     >
                       <v-autocomplete
                           v-model="disc"
                           :items="discs"
                           chips
                           small-chips
                           clearable
                           item-text="name"
                           item-value="id"
                           label="Дисциплина"
                           color="#36a2eb"
                           prepend-icon="school"
                       />

                     </v-col>
                     <v-col
                         cols="2"
                         sm="2"
                         md="2"
                     >
                       <v-autocomplete
                           v-model="tchr"
                           :items="tchrs"
                           chips
                           small-chips
                           clearable
                           item-text="teacher"
                           item-value="id"
                           label="Преподаватель"
                           color="#36a2eb"
                           prepend-icon="person"
                       />
                     </v-col>
                     <v-col
                         cols="2"
                         sm="2"
                         md="2"
                     >
                       <v-autocomplete
                           v-model="faculty"
                           :items="faculties"
                            chips
                           small-chips
                           clearable
                           label="Институт"
                           color="#36a2eb"
                           prepend-icon="apartment"
                       />
                     </v-col>
<!--                   </v-container>-->
                 </v-row>
                 <v-row>
                   <v-container>
                     <v-col>
                     </v-col>
                   </v-container>
                 </v-row>


               </v-flex>
             </v-layout>
           </v-card-text>

            </v-expansion-panel-content>
       </v-expansion-panel>

       <v-expansion-panel>
         <v-expansion-panel-header class="letter"><p><span class="letter">ДИАГРАММА ПОСЕЩАЕМОСТИ</span></p></v-expansion-panel-header>
         <v-expansion-panel-content>
           <v-card elevation="0">
           <line-chart
               ref='chart'
               :chart-data="chartData"
               :options="optionsChart"

           ></line-chart>
           </v-card>
         </v-expansion-panel-content>
       </v-expansion-panel>
     </v-expansion-panels>
       <v-layout justify-center>
         <v-flex xs12>
           <v-card-subtitle elevation="0">
             <v-layout>
               <v-flex>
                 <v-row>
                   <v-col>
                     <detail-statistics
                        :dialog="dialog"
                        :dialogItem="dialogItem"
                        @close="dialog = false"
                     ></detail-statistics>
                   </v-col>
                 </v-row>
               </v-flex>
             </v-layout>
           </v-card-subtitle>
           <v-card  >
           <v-card-subtitle>
             <v-layout>
               <v-flex>
                 <v-row>
                   <v-col>
               <v-data-table
                   :loading="loading"
                   :headers="headers"
                   :items="lessons"
                   class="elevation-1"
                   :pagination.sync="pagination"
                   :items-per-page="pageSize"
                   hide-default-footer
                   item-key="lessons_number"
                   :sort-desc.sync="sortDesc"
                   loading-text="Загрузка..."
                   no-data-text="Нет информации"
               >
                 <!--                  :options.sync="options"-->
                 <!--                  :server-items-length="totalItems"-->
                 <template v-slot:item.date="{ item }">
                   {{ formatDate(item.date) }}
                 </template>

                 <template v-slot:item.teachers="{ item }">
                   <div v-for="i in item.teachers" :key="i">
                     {{ i.teacher }}
                   </div>
                 </template>

                 <template v-slot:item.audiences="{ item }">
                   <div v-for="i in item.audiences" :key="i">
                     {{ i.name }}
                   </div>
                 </template>


                 <template v-slot:item.presentStudents="{ item }">
                   <v-chip :color="getColor(item.presentStudents,item.students  )" white> {{ item.presentStudents }} /
                     {{ item.students }}
                   </v-chip>
                 </template>
                 <template v-slot:item.linked="{ item }">
                   <v-btn

                       @click="detailItem(item)"
                       icon>
                     <v-icon color="rgb(54, 162, 235)">info</v-icon>
                   </v-btn>
                 </template>
                 <template v-slot:item.faculty="{ item }">
                   <div v-for="i in item.faculty" :key="i" >
                     {{ i }}
                   </div>
                 </template>
               </v-data-table>
                   </v-col>
                 </v-row>
               </v-flex>
             </v-layout>
           </v-card-subtitle>
<!--           Пагинация-->
           <v-card-text>
           <v-row>
             <v-col
                 cols="1"
                 sm="1"
                 md="1">
             </v-col>
             <v-col
                 cols="2"
                 sm="2"
                 md="2">
               <v-select
                   v-model="pageSize"
                   :items="pageSizes"
                   label="Количество строк "
                   color="#36a2eb"
               >
               </v-select>
             </v-col>
             <v-col>
               <v-pagination
                   class="my-4"
                   v-model="pagination.page"
                   :length="pages"
                   total-visible="7"
                   circle
                   color="#36a2eb"
               >

               </v-pagination>
             </v-col>
           </v-row>
           </v-card-text>
<!--           Пагинация-->
           </v-card>
         </v-flex>
       </v-layout>
  </div>
</template>


<!--<script src="https://cdnjs.cloudflare.com/ajax/libs/Chart.js/2.7.1/Chart.min.js"></script>-->
<!--<script src="https://unpkg.com/vue-chartjs/dist/vue-chartjs.min.js"></script>-->

<script>
import Settings from "@/settings/settings_url";
import {HTTP} from '@/settings/axios';

import LineChart from '@/components/charts/LineChart';
import DetailStatistics from '@/components/DetailStatistics';
import date_utils from "@/settings/date_utils";

// import 'chartjs-adapter-date-fns';
// import {ru} from 'date-fns/locale';

export default {
  name: "StatisticsReport",
  // extends: Bar,
  components: {
    LineChart,
    DetailStatistics
  },
  data() {
    return {

      dialog: false,

      // grName:'БПСЭ11',
      // discipl:'Теория государства и права зарубежных стран',
      // date: '25-05-2021',




      panel: [0, 1],
      readonly: false,

      chartData: {
        datasets: [
          {
            label: 'Line Chart',
            data: [],
            fill: true,
            borderColor: '#2554FF',
            backgroundColor: '#2554FF',
            borderWidth: 1
          }
        ]
      },
      optionsChart: {
        scales: {
          yAxes: [{
            ticks: {
              beginAtZero: true,
              fontColor: "gray",
            },

            gridLines: {
              display:true,
              zeroLineColor: 'gray'

            },


          }],
          xAxes: [{
            type: 'time',
            // color:'red',

            time: {
              displayFormats: {
                quarter: 'MMM YYYY',
                color: 'gray'
                // locale: 'ru'
              }
            },

            ticks: {
              fontColor: 'gray',
            },
            gridLines: {
             display:true,
              zeroLineColor: 'gray'
            },

          }]
        },
        legend: {
          display: true
        },
        responsive: true,
        maintainAspectRatio: false
      },


      options: {

        responsive: true,
        maintainAspectRatio: false,


      },
      loading: true,
      sortDesc: false,

      dates: [],
      menu: true,

      page: 1,
      pageSize: 10,
      totalItems: 0,
      pageSizes: [5, 10, 15, 20, 30, 40, 50, 60, 70, 80, 90, 100],
      pagination: {
        page: 1,
      },

      pages: 1,

      values: "",

      date1: new Date().toISOString().substr(0, 10),
      date2: new Date().toISOString().substr(0, 10),
      menu1: false,
      menu2: false,

      group: [],
      groups: [],
      tchr: "",
      tchrs: [],
      disc: "",
      discs: [],
      faculty: "",
      faculties: [
      ],

      lessons: [],

      headers: [
        {
          text: "Дата",
          value: "date",
          divider: true,
          align: 'center',
        },
        {
          text: "Группа",
          value: "groupName",
          divider: true,
          align: 'center',
        },

        {
          text: "Дисциплина",
          value: "disciplines.fullName",
          divider: true,
          align: 'center',
        },



        {
          text: "Институт",
          value: "institute",
          divider: true,
          align: 'center',
        },
        {
          text: "Преподаватель",
          value: "teachers",
          divider: true,
          align: 'center',
        },
        {
          text: "Аудитория",
          value: "audiences",
          divider: true,
          align: 'center',
        },

        {
          text: "Посещаемость",
          value: "presentStudents",
          divider: true,
          align: 'center',
        },
        {
          text: "Подробнее",
          value: "linked",
          divider: true,
          align: 'center',
        },

      ],

      dialogItem: {
        disciplines:{
          name:''
        },
      },
    }
  },
  watch: {
    'pagination.page'() {
      this.getData()
    },
    options: {
      handler() {
        this.getData()
        this.getChart()
      },
      deep: true,
    },
    date1() {

      this.getData()
      this.getChart()
    },
    date2() {
      this.getData()
      this.getChart()
    },
    pageSize() {
      this.getData()
    },

    //Преподаватель
    tchr() {
      this.getData()
      this.getChart()
    },
    //Дисциплина
    disc() {
      this.getData()
      this.getChart()
    },
    group() {
      this.getData()
      this.getChart()
    },
    faculty(){
      this.getData()
      this.getChart()
    }


  },
  computed: {
    computedDate1Formatted () {
      return date_utils.formatFullDate(this.date1)
    },
    computedDate2Formatted () {
      return date_utils.formatFullDate(this.date2)
    },
  },
  methods: {
    formatDate(date) {
      return date_utils.formatDate(date)
    },
    detailItem: async function (item) {
      this.dialog = true
      console.log(item)
      this.dialogItem = {...item}
      // await this.getStudentSt()
      // await this.getTeacherSt()
      // await this.getVisitStudentSt()

    },






    getColor(presentStudents, students) {
      if (presentStudents < students * 0.6) return "red";
      if (presentStudents >= students * 0.6 && presentStudents < students * 0.85) return "orange";
      if (presentStudents >= students * 0.85) return "green";
      if (presentStudents == undefined) return 'white';
    },


    getData: function () {
      this.loading = true
      let group = 0;
      let discipline = 0;
      let teacher = 0;
      let faculty = ""

      if (this.group != null && this.group != undefined && this.group != "") {
        group = this.group
      }
      if (this.disc != null && this.disc != undefined && this.disc != "") {
        discipline = this.disc
      }

      if (this.tchr != null && this.tchr != undefined && this.tchr != "") {
        teacher = this.tchr
      }
      if (this.faculty != null && this.faculty != undefined && this.faculty != "") {
        faculty = this.faculty
      }


      let uri = Settings.API.URL_DEFAULT + "/lessons-statistic/range-date?dateend=" + this.date2 +
          "&datestart=" + this.date1 + "&groupId=" + group + "&disciplineId=" + discipline + "&teacherId=" + teacher + "&institute=" +  faculty +
      "&page=" + this.pagination.page + "&size=" + this.pageSize
      console.log(uri)
      HTTP.get(uri)
          .then((response) => {



            this.lessons = response.data.content
            this.totalItems = response.data.totalElements //length//
            this.pages = response.data.totalPages
            // this.date2 = response.data
            console.log(response.data)
          })
          .catch(() => {

          })
          .finally(() => {
            this.loading = false
          });
    },

    getChart: function () {
      let group = 0;
      let discipline = 0;
      let teacher = 0;
      let  faculty = "";

      if (this.group != null && this.group != undefined && this.group != "") {
        group = this.group
      }
      if (this.disc != null && this.disc != undefined && this.disc != "") {
        discipline = this.disc
      }

      if (this.tchr != null && this.tchr != undefined && this.tchr != "") {
        teacher = this.tchr
      }

      if (this.faculty != null && this.faculty != undefined && this.faculty != "") {
        faculty = this.faculty
      }

      let uri = Settings.API.URL_DEFAULT + "/lessons-statistic/chart-percent-visits-range-date?dateend=" + this.date2 +
          "&datestart=" + this.date1 + "&groupId=" + group + "&disciplineId=" + discipline + "&teacherId=" + teacher + "&institute=" +  faculty
      console.log(uri)
      HTTP.get(uri)
          .then((response) => {
            //this.chartData.datasets.data = response.data
            //console.log(response)
            this.chartData = {
              datasets: [
                {
                  label: 'Процент посещамости',
                  data: response.data,
                  fill: true,
                  borderColor: 'rgb(54, 162, 235)',//'#2554FF',
                  backgroundColor: 'rgba(54, 162, 235, 0.5)',// '#2554FF',
                  borderWidth: 1
                }
              ]
            }

          })
          .catch(() => {

          })
          .finally(() => {
          });
    },

    // reverseTаble: function () {
    //   this.lessons = this.lessons.split('').reverse().join('')
    // }},


    getGroup: function () {


      HTTP.get(Settings.API.URL_DEFAULT + "/groups")
          .then((response) => {
            this.groups = response.data
          })
          .catch(() => {

          })
          .finally(() => {

          });

    },

    getDiscipline: function () {
      HTTP.get(Settings.API.URL_DEFAULT + Settings.API.URL_DISCIPLINES)
          .then((response) => {
            this.discs = response.data
          })
          .catch(() => {

          })
          .finally(() => {

          });
    },

    getTeachers: function () {
      HTTP.get(Settings.API.URL_DEFAULT + Settings.API.URL_TEACHERS)
          .then((response) => {
            this.tchrs = response.data
          })
          .catch(() => {

          })
          .finally(() => {

          });
    },

    getFaculty: async function () {
      await HTTP.get(Settings.API.URL_DEFAULT + Settings.URL_SCHEDULE + "all-faculty")
          .then((response) => {
            this.faculties = response.data

          })
          .catch(() => {
          })
    },

  },

  created() {
    this.getData()
    this.getChart()
    this.getGroup()
    this.getDiscipline()
    this.getTeachers()
    this.getFaculty()
    // this.getStudentSt()
    // this.getTeacherSt()
    // this.getVisitStudentSt()
  },
}


</script>

<style>

.letter:hover {

  color: rgb(54, 162, 235); /* Цвет ссылки */
}

</style>