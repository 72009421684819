var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"example-1"}},[(_vm.errored)?_c('section',[_c('p',[_vm._v("Сервер временно не работает.")])]):_c('section',[(_vm.loading)?_c('div',[_vm._v("Информация загружается ....")]):_vm._e(),_c('v-autocomplete',{attrs:{"items":_vm.items,"label":"Группы","dense":""},on:{"change":_vm.onChangeSelectedTask},model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}}),_c('br'),_vm._v(" Выбранная группа: "+_vm._s(_vm.selected)+" ")],1),_c('v-card-text',[_c('v-layout',{attrs:{"row":"","wrap":""}},[_c('v-flex',{attrs:{"xs12":"","md13":""}},[_c('v-card-actions',{staticClass:"justify-center"},[_c('h1',[_vm._v("Сводная таблица")])])],1),_c('v-flex',{attrs:{"xs12":"","md7":""}}),_c('v-flex',{attrs:{"xs12":"","md7":""}})],1)],1),_c('v-layout',{attrs:{"justify-center":""}},[_c('v-flex',{attrs:{"xs12":""}},[_c('v-data-table',{staticClass:"elevation-2",attrs:{"headers":_vm.headers,"items":_vm.desserts,"items-per-page":15,"hide-default-footer":false,"single-expand":true,"item-key":"gradebook","show-expand":""},on:{"item-expanded":_vm.onChangeInfoStud},scopedSlots:_vm._u([{key:"item.linked",fn:function(ref){
var item = ref.item;
return [(item.linked == false)?_c('div',[_c('v-icon',[_vm._v("unpublished")])],1):_c('div',{attrs:{"icon":""}},[_c('v-icon',[_vm._v("verified")])],1)]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{staticClass:"ma-0 pa-0",attrs:{"colspan":headers.length}},[(_vm.stinfo.length > 0)?[_c('v-list',[_vm._l((_vm.stinfo),function(itembrso,i){return [(!itembrso)?_c('v-divider',{key:("divider-" + i)}):_c('v-list-item',{key:("item-" + i),attrs:{"value":itembrso,"active-class":"deep-purple--text text--accent-4"}},[[_c('v-list-item-action',[_c('v-btn',{attrs:{"color":_vm.getColor(itembrso, item),"icon":""},on:{"click":function($event){return _vm.onChangeConnDel(itembrso, item, _vm.selected)}}},[_c('v-icon',[_vm._v("check_circle")])],1)],1),_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(itembrso.surname + ' ' + itembrso.name + ' ' + itembrso.patronymic)}})],1),[_c('div',{staticClass:"text-center"},[_c('v-dialog',{attrs:{"width":"500"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"red lighten-2","dark":""}},'v-btn',attrs,false),on),[_vm._v(" Информация о пользователе ")])]}}],null,true),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline grey lighten-2"},[_vm._v(" Информация о пользователе ")]),_c('v-card-text',[_c('br'),_c('h1',[_vm._v("БРСО")]),_c('p',[_vm._v("ФИО: "+_vm._s(itembrso.surname + ' ' + itembrso.name + ' ' + itembrso.patronymic))]),_vm._v(" ID OREON: "+_vm._s(itembrso.id)),_c('br'),_vm._v(" Дата рождения : "+_vm._s(itembrso.birthDate)+" ")]),_c('v-divider'),_c('v-card-text',[_c('br'),_c('h1',[_vm._v("1C")]),_c('p',[_vm._v("ФИО: "+_vm._s(item.student))]),_c('p',[_vm._v(" Номер зачётки: "+_vm._s(item.gradebook)),_c('br'),_vm._v(" Код студента: "+_vm._s(item.studentCode)),_c('br'),_vm._v(" Код зачётки: "+_vm._s(item.gradebookCode)),_c('br')])]),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":function($event){_vm.dialog = false}}},[_vm._v(" Закрыть ")])],1)],1)],1)],1)]]],2)]})],2)]:_c('div',{staticClass:"ml-10"},[_vm._v(" Пользователь не найден !!! "),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":function($event){return _vm.onChangeConnDel(null, item, _vm.selected, false)}}},[_vm._v(" Добавить без привязке к Орион ")])],1)],2)]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }