<template>

  <v-card>
  <v-card-text>
    <v-text-field
        v-model="search"
        prepend-icon="search"
        label="Поиск"
        single-line
        hide-details
    />

  </v-card-text>

  <v-data-table
      :headers="headers"
      :items="orderCertificates"
      :items-per-page="10"
      class="elevation-1"
      :search="search"
      loading-text="Загрузка..."
      no-data-text="Нет информации"
  >

    <template v-slot:item.workStatus="{ item }">
      <div>
        {{ workStatus(item.workStatus) }}
      </div>
    </template>

    <template v-slot:item.createdDate="{ item }">
      <div>
        {{ formatDate(item.createdDate) }}
      </div>
    </template>

    <template v-slot:item.certificateFile="{ item }">
      <div v-for="certificateFile in item.certificateFile" :key="certificateFile.id">
        <a target="_blank" :href="fileUrl(certificateFile)">{{ certificateFile.originName }}</a>
      </div>
    </template>

    <template v-slot:item.work="{ item }">
      <div v-if="item.workStatus != 'COMPLETED' && item.workStatus != 'CANCELED'">
        <v-btn
            style="color: green"
            icon
            @click="work(item)"
        >
          <v-icon>check_circle</v-icon>
        </v-btn>
        <v-btn
            style="color: red"
            icon
            @click="cancel(item)"
        >
          <v-icon>cancel</v-icon>
        </v-btn>
      </div>
    </template>

  </v-data-table>

  </v-card>

</template>

<script>
import Settings from "@/settings/settings_url";
import date_utils from "@/settings/date_utils";

export default {
  name: "OrderCertificateEmployeeList",
  props:{
    orderCertificates: {
      type: Array,
      required: true
    },

  },
  data() {

    return {
      search: '',
      headers: [

        {text: 'N', align: 'center', sortable: false, value: 'id',},
        {text: 'Название', value: 'typeCertificate.title'},
        {text: 'Дата создания', value: 'createdDate'},
        {text: 'Срок исполнения', value: 'typeCertificate.readinessInDays'},
        {text: 'Студент', value: 'student.student'},
        {text: 'Группа', value: 'student.groups.name'},
        {text: 'Зачетка', value: 'student.gradeBook'},
        {text: 'Файлы', value: 'certificateFile'},
        {text: 'Ответственный', value: 'responsible.username'},
        {text: 'Статус', value: 'workStatus'},
        {text: 'Действия', value: 'work'},
      ],

    }
  },
  methods:{
    formatDate(date) {
      return date_utils.formatDate(date)
    },
    fileUrl(file){
      return Settings.API.URL_DEFAULT +  "/certificate-file/" + file.id
    },

    cancel(item){
     if (confirm("Отказать ?")){
       this.$emit("canceled", item)
     }
    },

    work(item){
      if (item.workStatus === "CREATION"){
        this.$emit("inWork", item)
      }
      if (item.workStatus === "IN_WORK"){
        this.$emit("ready", item)
      }
      if (item.workStatus === "READY"){
        this.$emit("completed", item)
      }
    },
    workStatus(status){
      if (status === "CREATION"){
        return "Создан"
      }
      if (status === "IN_WORK"){
        return "В работе"
      }
      if (status === "READY"){
        return "Готов"
      }
      if (status === "COMPLETED"){
        return "Выдан"
      }
      if (status === "CANCELED"){
        return "Отменен"
      }
    }
  }
}
</script>

<style scoped>

</style>
