<template>
  <v-dialog
      v-model="commentDialog"
      width="800px"
  >
    <v-card>
      <v-toolbar
          dark
          color="primary"
      >
        <v-card-title>История изменений</v-card-title>
        <v-spacer/>
        <v-btn
            icon
            dark
            @click="commentDialog = false"
        >
          <v-icon>close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-card-text v-if="visitItem != null && visitItem.journalVisitComments != null">
        <v-data-table
            :headers="headers"
            :items="visitItem.journalVisitComments"
            :items-per-page="10"
            item-key="id"
            class="elevation-1"
            :loading="loading"
            loading-text="Загрузка..."
            no-data-text="История пуста!"
            :footer-props="{
              itemsPerPageAllText: 'Все',
              itemsPerPageText: 'Изменений на страницу',
            }"
        >
          <template v-slot:item.date="{ item }">
            {{ getDatetime(item.date) }}
          </template>

          <template v-slot:item.change="{ item }">
            {{ getValComment(item.preVal, item.newVal) }}
          </template>

          <template v-slot:item.user.username="{ item }">
            <span v-if="item.user.username == 'Systems'">Система</span>
            <span v-if="item.user.username != 'Systems'">{{ item.user.username }}</span>
          </template>
        </v-data-table>
      </v-card-text>

      <v-divider/>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
            color="primary"
            text
            @click="commentDialog = !commentDialog"
        >
          Закрыть
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {HTTP} from "@/settings/axios";
import Settings from "@/settings/settings_url";
import date_utils from "@/settings/date_utils";

export default {
  name: "History",
  data() {
    return {
      loading: false,
      commentDialog: false,
      visitItem: null,
      headers: [
        {text: 'Дата', sortable: false, divider: true, value: 'date', align: 'center'},
        {text: 'Изменение', sortable: false, divider: true, value: 'change', align: 'center'},
        {text: 'Пользователем', sortable: false, divider: true, value: 'user.username', align: 'center'},
        {text: 'Комментарий', sortable: false, divider: true, value: 'comment', align: 'center'},
      ],
    }
  },
  methods: {
    getComments: async function (item) {
      this.loading = true
      this.visitItem = item

      let url = "/registration-of-student/"

      if(this.showTeachers)
        url = "/registration-of-teacher/"

      await HTTP.get(
          Settings.API.URL_DEFAULT + url + item.id + "/visit-comments" )
          .then((response) => {
            item.journalVisitComments = response.data
            // console.log(response.data)
          })
          .catch(() => {
            this.errored = true;
          })
          .finally(() => {
            this.loading = false
          });
    },
    getDatetime: function (date){
      return date_utils.formatDateTime(date)
    },
    getValComment: function (preVal,  newVal){
      let pval = preVal == 1 ?  "присутствие" : "отсутствие"
      let nVal = newVal == 1 ? "присутствие" : "отсутствие"
      return "Изменено с " +  pval + " -> " + nVal
    },
    getTextComment: function (item){
      if (item.comment != ""){
        return "Комментарий - " + item.comment
      }
    },
  },
  props: {
    showTeachers: {
      default: false,
      type: Boolean,
    }
  }
}
</script>

<style scoped>

</style>