<template>
  <div>
    <v-container justify-center>
      <div class="text-center">
        <h2>Справки</h2>
      </div>
      <v-tabs
          v-model="tab"
      >
        <v-tab>
          <v-icon left>
            add
          </v-icon>
            Заказать
        </v-tab>
        <v-tab>
          <v-icon left>
            list
          </v-icon>
          Список
        </v-tab>
      </v-tabs>
      <v-tabs-items
          v-model="tab"
          :touchless="true"
      >
        <v-tab-item>
          <v-container>
            <group-certificate-user-list
                class="ma-4"
                :groupCertificates="groupCertificates"
                @requestTypeCertificate="requestTypeCertificate"
            ></group-certificate-user-list>
          </v-container>
        </v-tab-item>

        <v-tab-item>
          <v-container>
            <OrderCertificateStudentList
                :orderCertificates="orderCertificates"
            ></OrderCertificateStudentList>
          </v-container>
        </v-tab-item>

      </v-tabs-items>
      <certificate-order-dialog
          :certificate="certificate"
          :show="showCertificateOrderDialog"
          @close="closeCertificateOrderDialog"
          @success="successCertificateOrderDialog"
      ></certificate-order-dialog>
    </v-container>
  </div>
</template>

<script>
import Settings from "@/settings/settings_url";
import {HTTP} from '@/settings/axios'

import GroupCertificateUserList from "../../components/certificate/GroupCertificateUserList";
import CertificateOrderDialog from "../../components/certificate/CertificateOrderDialog";
import OrderCertificateStudentList from "../../components/certificate/OrderCertificateStudentList";

export default {
  name: "Certificate",
  components:{
    GroupCertificateUserList,
    CertificateOrderDialog,
    OrderCertificateStudentList
  },
  data(){
    return{
      tab: 1,
      showCertificateOrderDialog: false,
      groupCertificates: [],
      orderCertificates: [],
      certificate: {}
    }
  },
  methods:{
    closeCertificateOrderDialog(){
      this.showCertificateOrderDialog = false
    },
    successCertificateOrderDialog(){
      this.getOrderCertificates()
      this.showCertificateOrderDialog = false
      this.tab = 1
    },
    requestTypeCertificate(item){
      this.showCertificateOrderDialog = true
      this.certificate = {...item}
    },
    getGroupCertificate: async function () {
      HTTP.get(Settings.API.URL_DEFAULT + "/group-certificate/all-by-edu")
          .then((response) => {
            this.groupCertificates = response.data
          })
          .catch(() => {
          })
          .finally(() => {

          });
    },
    getOrderCertificates: async function () {
      HTTP.get(Settings.API.URL_DEFAULT + "/students/my-order-certificate")
          .then((response) => {
            this.orderCertificates = response.data
          })
          .catch(() => {
          })
          .finally(() => {

          });
    },
  },
  created() {
    this.getGroupCertificate()
    this.getOrderCertificates()
  }
}
</script>

<style scoped>

</style>
