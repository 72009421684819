<template>

  <v-container>
    <v-row>
      <v-col class="mr-10">
        <v-autocomplete v-model="yearOfStud"
                        :items="yearOfStuds"
                        label="Год обучения"
                        dense
                        item-value="id"
                        item-text="name"
                        return-object
        >
        </v-autocomplete>
      </v-col>
      <v-col class="mr-10">
        <v-autocomplete v-model="halfYear"
                        :items="halfYears"
                        label="Полугодие"
                        dense
                        item-value="id"
                        item-text="name"
                        return-object
        >
        </v-autocomplete>
      </v-col>
      <v-col class="mr-10">
        <v-autocomplete v-model="group"
                        :items="groups"
                        label="Группа"
                        dense
                        item-value="id"
                        item-text="name"
                        return-object
        >
        </v-autocomplete>
      </v-col>
    </v-row>
    <v-data-table
        :headers="headers"
        :items="items"
        :items-per-page="15"
        :hide-default-footer="false"
        class="elevation-2"
        :search="search"
        :single-expand="true"
        :loading="loading"
    >
      <template v-slot:item.invitationCode="{ item }">
        <v-btn
            target="_blank"
            text
            @click="editInviteCode(item)"
        >
          {{ item.invitationCode }}
        </v-btn>
      </template>
      <template v-slot:item.numberLessonsPlan="{ item }">
        {{ item.numberLessonsPlan * 2 }}
      </template>
      <template v-slot:item.numberLessonsAdded="{ item }">
        {{ item.numberLessonsAdded * 2 }}
      </template>
      <template v-slot:item.numberLessons="{ item }">
        {{ item.numberLessons * 2 }}
      </template>
      <template v-slot:item.linked="{ item }">
        <v-btn
            :to="getLinkCalendar(item)"
            target="_blank"
            icon
        >
          <v-icon>date_range</v-icon>
        </v-btn>
      </template>
    </v-data-table>

    <template>
      <v-row justify="center">
        <v-dialog
            v-model="show"
            persistent
            max-width="600px"
        >
          <v-card>
            <v-card-title>
              <span class="headline">Код приглашения</span>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col
                      cols="12"
                      sm="12"
                      md="12"
                  >
                    <div>
                      <v-text-field
                          v-model="editItem.invitationCode"
                          label="Код"
                          hide-details="auto"
                      ></v-text-field>
                    </div>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                  color="blue darken-1"
                  text
                  @click="show = false"
              >
                Close
              </v-btn>
              <v-btn
                  color="blue darken-1"
                  text
                  @click="saveWorkload"
              >
                Save
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
    </template>

  </v-container>


</template>

<script>
import Settings from "@/settings/settings_url";
import {HTTP} from '@/settings/axios'

export default {
  name: "ControlAddedLessonsDiff",
  data() {
    return {
      headers: [
        {
          text: "Группа",
          value: "workPlan.group.name"
        },
        {
          text: "под группа",
          value: "subGroup.name"
        },
        {
          text: "MsTeams",
          value: "invitationCode"
        },
        {
          text: "Дисциплина",
          value: "workPlan.discipline.name"
        },
        {
          text: "Тип",
          value: "workPlan.lessonTypes.name"
        },
        {
          text: "Часов по плану",
          value: "numberLessonsPlan"
        },
        {
          text: "Часов добавлено",
          value: "numberLessonsAdded"
        },
        {
          text: "Проведено часов",
          value: "numberLessons"
        },
        {
          text: "Проведено занятий",
          value: "linked"
        },
      ],
      items: [],
      search: "",

      yearOfStud: {},
      yearOfStuds: [],
      halfYear: {},
      halfYears: [],
      groups: [],
      group: {},

      loading: false,
      editItem:{
        invitationCode: "",
      },
      show: false,
    }
  },
  watch: {
    halfYear() {
      this.getWorkload()
    },
    yearOfStud() {
      this.getWorkload()
    },
    group() {
      this.getWorkload()
    },
  },
  methods: {
    editInviteCode(item){
      console.log(item)
      this.editItem = {...item}
      this.show = true
    },
    saveWorkload(){
      console.log("sd")
      HTTP.post(Settings.API.URL_DEFAULT + "/workload/invite-code", this.editItem)
          .then((response) => {
            console.log(response.data)
            this.getWorkload()
            this.show = false
          })
          .catch(() => {

          })
          .finally(() => {
            this.editItem.invitationCode = ""
          });
    },
    getLinkCalendar(item) {
      return "/lessons-calendar?plan=" + item.id + "&year=" + this.yearOfStud.yearStart
    },
    getWorkload() {
      this.loading = true
      HTTP.get(Settings.API.URL_DEFAULT + "/workload/control-added-lessons-diff?yearId=" + this.yearOfStud.id + "&halfYearId=" + this.halfYear.id)
          .then((response) => {
            this.items = response.data
            //console.log(this.items)

          })
          .catch(() => {

          })
          .finally(() => {
            this.loading = false
          });
    },
    getYear: async function () {
      HTTP.get(Settings.API.URL_DEFAULT + "/year-of-study")
          .then((response) => {
            this.yearOfStuds = response.data
          })
          .catch(() => {

          })
          .finally(() => {
            for (let i = 0; i < this.yearOfStuds.length; i++) {
              if (this.yearOfStuds[i].current == 1) {
                this.yearOfStud = this.yearOfStuds[i]
                break
              }
            }
          });
    },

    getHalfYear: async function () {
      HTTP.get(Settings.API.URL_DEFAULT + "/half-year")
          .then((response) => {
            this.halfYears = response.data
          })
          .catch(() => {

          })
          .finally(() => {
            for (let i = 0; i < this.halfYears.length; i++) {
              if (this.halfYears[i].current == 1) {
                this.halfYear = this.halfYears[i]
                break
              }
            }
          });
    },
    getGroups: async function () {
      HTTP.get(Settings.API.URL_DEFAULT + "/groups")
          .then((response) => {
            this.groups = response.data
          })
          .catch(() => {

          })
          .finally(() => {

          });
    },
  },
  created() {
    this.getHalfYear()
    this.getYear()
    this.getGroups()
  }
}
</script>

<style scoped>

</style>