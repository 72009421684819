<template>
  <v-container v-show="visible">
    <v-row>
      <v-col cols="6">
        <v-row>
          <v-col cols="3">
            <v-text-field
                v-model="fioFilter"
                clearable
                label="ФИО студента"
                @keydown.enter="getStudents"
                @click:append-outer="getStudents"
                @click:clear="getStudents"
                @focusout="getStudents"
            >
              <template v-slot:append-outer>
                <v-btn icon @click="getStudents">
                  <v-icon color="primary">search</v-icon>
                </v-btn>
              </template>
            </v-text-field>
          </v-col>
          <v-col cols="2">
            <v-autocomplete
                v-model="groupFilter"
                :items="groups"
                item-text="name"
                item-value="id"
                clearable
                label="Группа"
            />
          </v-col>
          <v-col cols="3">
            <v-select
                v-model="educationTypeFilter"
                :items="educationTypes"
                item-text="ru"
                item-value="en"
                clearable
                label="Тип образования"
            />
          </v-col>
          <v-col cols="2">
            <v-checkbox
                v-model="notLinkedOrionFilter"
                label="Непривяз. пропуска"
            />
          </v-col>
          <v-col cols="2">
            <v-checkbox
                v-model="notLinkedLMSFilter"
                label="Непривяз. LMS"
            />
          </v-col>
        </v-row>
        <v-progress-linear :active="loading" :indeterminate="loading"/>
        <v-data-table
            :headers="headers"
            :items="studs"
            :pagination.sync="pagination"
            :items-per-page="pageSize"
            class="elevation-2"
            hide-default-footer
            loading-text="Загрузка студентов"
        >
          <template v-slot:body="{ items }">
            <tbody>
            <tr :class="backgroundColor(item) ? 'selected-row' : ''" v-for="(item) in items" :key="item.id">
              <td class="text-center v-data-table__divider">{{ item.student }}</td>
              <td class="text-center v-data-table__divider">
                <span v-if="item.groups != null">{{ item.groups.name }}</span>
              </td>
              <td class="text-center v-data-table__divider">
                <v-tooltip bottom v-if="item.codeLms != null && item.codeLms != ''" >
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon color="success" v-on="on" v-bind="attrs">
                      check_circle_outline
                    </v-icon>
                  </template>
                  <span>Привязан</span>
                </v-tooltip>
                <v-tooltip bottom v-if="item.codeLms == null || item.codeLms == ''" >
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon color="error" v-on="on" v-bind="attrs">
                      highlight_off
                    </v-icon>
                  </template>
                  <span>Не привязан</span>
                </v-tooltip>
              </td>
              <td class="text-center v-data-table__divider">
                <v-tooltip bottom v-if="item.orionId != null && item.orionId != ''" >
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon color="success" v-on="on" v-bind="attrs">
                      check_circle_outline
                    </v-icon>
                  </template>
                  <span>Привязан</span>
                </v-tooltip>
                <v-tooltip bottom v-if="item.orionId == null || item.orionId == ''" >
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon color="error" v-on="on" v-bind="attrs">
                      highlight_off
                    </v-icon>
                  </template>
                  <span>Не привязан</span>
                </v-tooltip>
              </td>
              <td class="text-center v-data-table__divider">
                <v-row>
                  <v-col cols="4">
                    <v-tooltip bottom v-if="item != currentSelectedStudent">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            icon
                            color="success"
                            @click="selectStudentToLink(item)"
                            v-bind="attrs"
                            v-on="on"
                        >
                          <v-icon>link</v-icon>
                        </v-btn>
                      </template>
                      <span>Выбрать для связки</span>
                    </v-tooltip>
                    <v-tooltip bottom v-if="item == currentSelectedStudent">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            icon
                            color="white"
                            @click="resetStudentToLink()"
                            v-bind="attrs"
                            v-on="on"
                        >
                          <v-icon>link</v-icon>
                        </v-btn>
                      </template>
                      <span>Отменить выбор</span>
                    </v-tooltip>
                  </v-col>
                  <v-col cols="4">
                    <v-tooltip bottom v-if="item != currentSelectedStudent && item.orionId != null">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            icon
                            color="error"
                            v-bind="attrs"
                            v-on="on"
                            @click="openUnlinkDialog(item)"
                        >
                          <v-icon>credit_card</v-icon>
                        </v-btn>
                      </template>
                      <span>Отвязать пропуск</span>
                    </v-tooltip>
                  </v-col>
                  <v-col cols="4">
                    <v-tooltip bottom v-if="item != currentSelectedStudent && item.codeLms != null">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            icon
                            color="error"
                            v-bind="attrs"
                            v-on="on"
                            @click="openUnlinkLmsDialog(item)"
                        >
                          <v-icon>person</v-icon>
                        </v-btn>
                      </template>
                      <span>Отвязать личный кабинет LMS</span>
                    </v-tooltip>
                  </v-col>
                </v-row>
              </td>
            </tr>
            </tbody>
          </template>
        </v-data-table>
        <v-alert v-if="(studs == null || studs.length == 0) && !loading" :value="true" color="error" icon="warning">
          Студенты не найдены. Попробуйте изменить фильтры
        </v-alert>
        <!-- Пагинация -->
        <v-card-text>
          <v-row>
            <v-col
                cols="1"
                sm="1"
                md="1">
            </v-col>
            <v-col
                cols="2"
                sm="2"
                md="2">
              <v-select
                  v-model="pageSize"
                  :items="pageSizes"
                  label="Количество строк "
                  color="#36a2eb"
              >
              </v-select>
            </v-col>
            <v-col>
              <v-pagination
                  class="my-4"
                  v-model="pagination.page"
                  :length="pages"
                  total-visible="9"
                  circle
                  color="#36a2eb"
              >

              </v-pagination>
            </v-col>
          </v-row>
        </v-card-text>
        <!-- Пагинация-->
      </v-col>
      <v-divider vertical/>
      <v-col cols="6">
        <v-container>
          <v-row>
            <v-switch
              v-model="switch1"
              inset
            >
              <template v-slot:prepend>
                <v-icon @click="switch1 = false" :color="switch1 ? 'gray' : 'primary'">person</v-icon>
              </template>
              <template v-slot:append>
                <v-icon @click="switch1 = true" :color="switch1 ? 'primary' : 'gray'">badge</v-icon>
              </template>
            </v-switch>
           </v-row>
           <v-row>
            <orion-cards-list ref="cardsList" :disabled="!studSelected" v-show="switch1"/>
            <lms-user-list ref="lmsList" :disabled="!studSelected" v-show="!switch1"/>
          </v-row>
        </v-container>
      </v-col>
    </v-row>

    <v-dialog v-if="selectedToUnlinkStudent != null" v-model="unlinkDialog" max-width="800">
      <v-card>
        <v-toolbar
            dark
            color="primary"
        >
          <v-btn
              icon
              dark
              @click="closeUnlinkDialog()"
          >
            <v-icon>close</v-icon>
          </v-btn>
          <v-card-title>Вы уверены?</v-card-title>
        </v-toolbar>
        <v-card-title>
          Студент "<strong>{{ selectedToUnlinkStudent.student }}</strong>" будет отвязан
          от карты по ID "<strong>{{ selectedToUnlinkStudent.orionId }}</strong>"!
        </v-card-title>
        <v-card-actions>
          <v-spacer/>
          <v-btn
              color="secondary"
              @click="closeUnlinkDialog"
              :disabled="unlinking"
          >
            Отменить
          </v-btn>
          <v-btn
              color="primary"
              @click="unlinkStudent"
              :disabled="unlinking"
          >
            Отвязать
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-if="selectedToUnlinkStudent != null" v-model="unlinkLmsDialog" max-width="800">
      <v-card>
        <v-toolbar
            dark
            color="primary"
        >
          <v-btn
              icon
              dark
              @click="closeUnlinkLmsDialog()"
          >
            <v-icon>close</v-icon>
          </v-btn>
          <v-card-title>Вы уверены?</v-card-title>
        </v-toolbar>
        <v-card-title>
          Студент "<strong>{{ selectedToUnlinkStudent.student }}</strong>" будет
          отвязан от личного кабинета LMS по ID: "<strong>{{ selectedToUnlinkStudent.codeLms }}</strong>"!
        </v-card-title>
        <v-card-actions>
          <v-spacer/>
          <v-btn
              color="secondary"
              @click="closeUnlinkLmsDialog"
              :disabled="unlinking"
          >
            Отменить
          </v-btn>
          <v-btn
              color="primary"
              @click="unlinkLmsStudent"
              :disabled="unlinking"
          >
            Отвязать
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import OrionCardsList from "@/components/admin/OrionLinking/OrionCardsList";
import {HTTP} from "@/settings/axios";
import Settings from "@/settings/settings_url";
import LmsUserList from "@/components/admin/LmsLinking/LmsUserList";

export default {
  name: "StudentToOrionLinking",

  components: {
    LmsUserList,
    OrionCardsList,
  },

  data() {
    return {
      switch1: true,
      unlinkDialog: false,
      loading: false,
      fioFilter: null,
      notLinkedOrionFilter: false,
      notLinkedLMSFilter: false,
      groupFilter: null,
      educationTypeFilter: null,
      unlinkLmsDialog: false,
      studs: [],
      pages: 1,
      pageSizes: [5, 10, 15, 20, 30, 40, 50, 60, 70, 80, 90, 100],
      totalItems: 0,
      pagination: {
        page: 1,
      },
      page: 1,
      pageSize: 10,
      headers: [
        {text: 'ФИО', align: 'center', sortable: true, value: 'student', divider: true, class: "bolt"},
        {text: 'Группа', align: 'center', sortable: true, value: 'groups.name', divider: true, class: "bolt"},
        {text: 'Статус привязки ЛК LMS', align: 'center', sortable: true, value: 'isLmsLinked', divider: true, class: "bolt"},
        {text: 'Статус привязки карты', align: 'center', sortable: true, value: 'isOrionLinked', divider: true, class: "bolt"},
        {text: 'Действия', align: 'center', sortable: true, value: 'actions', divider: true, class: "bolt"},
      ],
      studSelected: false,
      currentSelectedStudent: null,
      selectedToUnlinkStudent: null,
      unlinking: false,
      url: '/students',
      educationTypes: [
        {ru: "Магистратура", en: "MAGISTRACY"},
        {ru: "Бакалавриат", en: "BACCALAUREATE"},
        {ru: "Специалитет", en: "SPECIALTY"},
        {ru: "Аспирантура", en: "ASPIRANT"},
        {ru: "СПО", en: "SPO"},
      ],
      groups: [],
      groupsLoading: false,
    }
  },
  methods: {
    getGroups() {
      this.groupsLoading = true
      HTTP.get(Settings.API.URL_DEFAULT + "/groups")
          .then((response) => {
            this.groups = response.data
          })
          .catch(() => {

          })
          .finally(() => {
            this.groupsLoading = false
          });
    },
    unlinkStudent() {
      this.unlinking = true
      this.selectedToUnlinkStudent.orionId = null

      HTTP.post(Settings.API.URL_DEFAULT + this.url + "/update-orion-id", this.selectedToUnlinkStudent)
          .then((response) => {
            this.selectedToUnlinkStudent = response.data
          }).catch((err) => {
        console.log(err)
      }).finally(() => {
        this.unlinking = false
        this.closeUnlinkDialog()
      })
    },
    unlinkLmsStudent() {
      this.unlinking = true
      this.selectedToUnlinkStudent.codeLms = null

      HTTP.post(Settings.API.URL_DEFAULT + this.url + "/update-lms-id", this.selectedToUnlinkStudent)
          .then((response) => {
            this.selectedToUnlinkStudent = response.data
          }).catch((err) => {
        console.log(err)
      }).finally(() => {
        this.unlinking = false
        this.closeUnlinkLmsDialog()
      })
    },
    openUnlinkLmsDialog(item) {
      this.selectedToUnlinkStudent = item
      this.unlinkLmsDialog = true
    },
    closeUnlinkLmsDialog() {
      this.selectedToUnlinkStudent = null
      this.unlinkLmsDialog = false
    },
    openUnlinkDialog(item) {
      this.selectedToUnlinkStudent = item
      this.unlinkDialog = true
    },
    closeUnlinkDialog() {
      this.selectedToUnlinkStudent = null
      this.unlinkDialog = false
    },
    async getStudents() {
      this.loading = true
      let url = ""

      if (this.fioFilter != null)
        url += "&fio=" + this.fioFilter

      if (this.notLinkedOrionFilter != null)
        url += "&notLinked=" + this.notLinkedOrionFilter

      if(this.educationTypeFilter !== null)
        url += "&typeEducation=" + this.educationTypeFilter

      if(this.groupFilter !== null)
        url += "&groupId=" + this.groupFilter

      await HTTP.get(Settings.API.URL_DEFAULT + this.url
          + "/paginated" + "?page="
          + this.pagination.page + "&size=" + this.pageSize
          + "&getNotLinked=" + this.notLinkedOrionFilter
          + "&getNotLinkedLms=" + this.notLinkedLMSFilter
          + url)
          .then((response) => {
            console.log(response.data)
            this.studs = response.data.content
            this.totalItems = response.data.totalElements
            this.pages = response.data.totalPages
            if (this.pagination.page > this.pages)
              this.pagination.page = 1

          })
          .catch(() => {

          })
          .finally(() => {
            this.loading = false
          });
    },
    selectStudentToLink(stud) {
      this.studSelected = true
      this.currentSelectedStudent = stud
      this.$refs.cardsList.userToLink = stud
      this.$refs.cardsList.familyNameFilter = stud.surname
      this.$refs.cardsList.nameFilter = stud.name
      this.$refs.cardsList.patronymicFilter = stud.patronymic
      this.$refs.cardsList.getOrionUsers()

      this.$refs.lmsList.userToLink = stud
      this.$refs.lmsList.familyNameFilter = stud.surname
      this.$refs.lmsList.nameFilter = stud.name
      this.$refs.lmsList.patronymicFilter = stud.patronymic
      this.$refs.lmsList.getLmsUsers()
    },
    backgroundColor(item) {
      if (item === this.currentSelectedStudent)
        return 'selected-row'
    },
    resetStudentToLink() {
      this.studSelected = false
      this.currentSelectedStudent = null
      this.$refs.cardsList.resetFilters()
      this.$refs.lmsList.resetFilters()
    },
  },
  created() {
    this.getGroups()
    this.getStudents()
  },
  watch: {
    'currentStudentSelected'() {
      if (this.currentSelectedStudent === null)
        this.$refs.cardsList.resetFilters()
    },
    'fioFilter'() {
      this.resetStudentToLink()
    },
    'pagination.page'() {
      this.getStudents()
    },
    'pageSize'() {
      this.getStudents()
    },
    'notLinkedOrionFilter'() {
      this.resetStudentToLink()
      this.getStudents()
    },
    'notLinkedLMSFilter'() {
      this.resetStudentToLink()
      this.getStudents()
    },
    'groupFilter'() {
      this.resetStudentToLink()
      this.getStudents()
    },
    'educationTypeFilter'() {
      this.resetStudentToLink()
      this.getStudents()
    },
  },
  props: {
    visible: {
      default: true,
      type: Boolean,
    },
    useTeachers: {
      default: false,
      type: Boolean,
    }
  },
}
</script>

<style scoped>
.selected-row {
  background-color: grey;
}

.selected-row:hover {
  background-color: grey !important;
}
</style>
